import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText, Input } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import { FiChevronLeft } from 'react-icons/fi';

import _ from 'lodash';

import { getUrl, putUrl } from '../helper/ApiAction';
import { storeProfile } from '../actions';

import useNotificationLoading from '../helper/useNotificationLoading';
import ImgHeader from './Layouts/ImgHeader';
import './css/styles.css';

export default function ProfileRank() {
    const styles = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    const history = useHistory();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { fr_id, max_rank, username, id, name, email, mobile, nric, referral_username, max_rank: rank, max_mrank, status} = useSelector(state => state.user);

    const [rankList, setRankList] = useState({});

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`ranks`).then(response => {
            if (response.status) {
                setRankList(response.rank);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, []);

    return (
        <Box >
            <ImgHeader cardDisplay title={t('profile.benefit_level')}/>

            <div className='pos-relative'>
                <div className='p-lr-25 p-t-50 p-b-100'>
                    <Grid container>
                        <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{
                                width: 97,
                                height: 97,
                                borderRadius: 15,
                                backgroundImage: `url('/images/rank_new_logo/rank_100.png')`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                boxShadow: '3px 3px 4px 0px #FFF inset, 2px 2px 4px 0px rgba(0, 0, 0, 0.10)',
                                filter: 'grayscale(0%)',
                                opacity: 1,
                            }}
                            />
                            <Typography style={{ fontSize: 15, textAlign: 'center', padding: '10px 0px', width: 90 }}>{i18n.language === 'cn' ? '会员' : 'Member'}</Typography>
                        </Grid>
                        {
                            _.size(rankList) > 0 &&
                            _.map(rankList, (list, key) => {
                                return (
                                    <Grid item xs={4} key={key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div style={{
                                            width: 97,
                                            height: 97,
                                            borderRadius: 15,
                                            backgroundImage: `url('/images/rank_new_logo/rank_${list.code}.png')`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            boxShadow: '3px 3px 4px 0px #FFF inset, 2px 2px 4px 0px rgba(0, 0, 0, 0.10)',
                                            filter: list.code <= max_rank['code'] ? 'grayscale(0%)' : 'grayscale(100%)',
                                            opacity: list.code <= max_rank['code'] ? 1 : 0.6,
                                        }}
                                        />
                                        <Typography style={{ fontSize: 15, textAlign: 'center', padding: '10px 0px', width: 90 }}>{list.name_display}</Typography>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{
                                width: 97,
                                height: 97,
                                borderRadius: 15,
                                backgroundImage: "url('/images/rank_new_logo/rank_gm.png')",
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                boxShadow: '3px 3px 4px 0px #FFF inset, 2px 2px 4px 0px rgba(0, 0, 0, 0.10)',
                                filter: 'grayscale(100%)',
                                opacity: 0.6,
                            }}
                            />
                            <Typography style={{ fontSize: 15, textAlign: 'center', padding: '10px 0px', width: 90 }}>{i18n.language === 'cn' ? '全球奖金' : 'Global Bonus'}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    textFieldTitle:{
        color:theme.palette.gray.text,
        fontSize:16,
        paddingBottom:5,
        paddingLeft:10
    }
}));
