import React, { useState, useEffect } from 'react';
import { Dialog, Button, AppBar, Typography, Grid, DialogContent, DialogActions, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Chat from '../Chat';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import CloseIcon from '@material-ui/icons/Close';
import ForumIcon from '@material-ui/icons/Forum';


const HIDE_COMPONENT_PAGE = ["chat", "customerservice"];

export default function ChatButton() {

    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const { accessToken } = useSelector(state => state.general);
    const { t, i18n } = useTranslation();
    const [currentPath, setCurrentPath] = useState();
    const location = useLocation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let active = true;

        if (active) {
            const path = _.split(location.pathname, '/');
            if (path) {
                setCurrentPath(path[1]);
            }
        }
        return () => { active = false };
    }, [location.pathname])

    return (
        <div>
            {
                _.includes(HIDE_COMPONENT_PAGE, currentPath) === false ?
                    <>
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <AppBar className={classes.appBarMobile} position="fixed">
                                <Dialog
                                    onClose={handleClose}
                                    open={open}
                                    maxWidth="lg"
                                >
                                    <DialogActions>
                                        <Button autoFocus onClick={handleClose} startIcon={<CloseIcon />}><Typography >{t('button.close')}</Typography></Button>
                                    </DialogActions>
                                    <Chat />
                                </Dialog>
                                <Grid
                                    container
                                    justify="flex-end"
                                    alignItems="flex-end"
                                    item
                                >
                                    {
                                        accessToken ?
                                            open ? null : <Button  className={classes.button} startIcon={<ForumIcon />} onClick={handleClickOpen} >{t('button.chat')}</Button>
                                            : null
                                    }
                                </Grid>
                            </AppBar>
                        </Hidden>
                        <Hidden only={['xs']}>
                            <AppBar className={classes.appBar} position="fixed">
                                <Dialog
                                    onClose={handleClose}
                                    open={open}
                                    maxWidth="lg"
                                >
                                    <DialogActions>
                                        <Button autoFocus onClick={handleClose}  startIcon={<CloseIcon />}><Typography >{t('button.close')}</Typography></Button>
                                    </DialogActions>
                                    <Chat />
                                </Dialog>
                                <Grid
                                    container
                                    justify="flex-end"
                                    alignItems="flex-end"
                                    item
                                >
                                    {
                                        accessToken ?
                                            open ? null : <Button className={classes.button} startIcon={<ForumIcon />} onClick={handleClickOpen} >{t('button.chat')}</Button>
                                            : null
                                    }
                                </Grid>
                            </AppBar>
                        </Hidden>
                    </>
                    : null
            }

        </div>
    );
}



const useStyles = makeStyles((theme) => ({

    appBar: {
        top: 'auto',
        bottom: 0,
        right: 20,
        width: 90,
    },
    appBarMobile: {
        top: 'auto',
        bottom: 56,
        right: 0,
        width: 90,
    },
    button: {
        width: 90,
    }

}));