import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { SELLER_URL } from '../../configs/Config';

import useNotificationLoading from '../../helper/useNotificationLoading';
import _ from 'lodash';

import { makeStyles, useTheme, createMuiTheme } from '@material-ui/core';
import { ThemeProvider, FormControlLabel, FormControl, Radio, Button, Drawer, RadioGroup, Checkbox, Typography, Grid, Divider, Avatar, Link, Box, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import {
    PersonAdd, PersonOutlineRounded, MeetingRoomOutlined, PublicOutlined, ExpandMoreOutlined, MonetizationOn, NotificationsNoneRounded, LinkOutlined, ShoppingBag,
    ChevronRightSharp, CheckCircle, AccountBalanceOutlined, AssignmentInd, ContactMailOutlined, LocalMall, AssignmentRounded, HomeWorkRounded, LinkRounded,
    AccountTreeOutlined, HowToReg, NotificationsActive, SupervisedUserCircleOutlined, ConfirmationNumber, Star, StarRounded, LocationOnRounded, ShoppingCartRounded, ShoppingBasketRounded, AccountBalanceRounded, LockRounded, VpnKeyRounded, PolicyRounded, ContactSupport, LanguageRounded, PersonAddRounded
} from '@material-ui/icons';
import { changeLanguage as reduxChangeLanguage, changeCurrency as reduxChangeCurrency } from '../../actions';
import { getUrl, logoutUser } from '../../helper/ApiAction';

import TitleBar from './TitleBar';
import theme from '../../Theme';
import { MdCurrencyExchange, MdOutlineQrCode2 } from 'react-icons/md';

export default function DrawerBar(props) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const history = useHistory();

    const { state, toggleDrawer, setState } = props;
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken, role, currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    const { fr_id, max_rank, username, id, name, email, mobile, nric, referral_username, max_rank: rank, max_mrank, } = useSelector(state => state.user);

    const [valueLanguage, setLanguage] = useState(i18n.language);
    const [genealogy, setGenealogy] = useState({});
    const [currency2, setcurrency2] = useState(currency);
    const [unreadNotification, setunreadNotification] = useState();
    const [type, setType] = useState('/images/general/card/default.png');
    const [cardFrontColour, setCardFrontColour] = useState('#3a3732');

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`notifications`).then(response => {
            if (response.status) {
                setunreadNotification(response.new_count);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        if (accessToken) {
            getUrl(`genealogy/upline`).then(response => {
                if (response.status) {
                    setGenealogy(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, []);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };
    const changeCurrency = (curr) => {
        setcurrency2(curr);
        dispatch(reduxChangeCurrency(curr));
    };

    return (
        <ThemeProvider theme={accordingStyles}>
            <Drawer open={state} onClose={toggleDrawer(false)} className='mobile-width-fixed' style={{ margin: '0px auto' }}>
                <Box style={{ padding: '40px 30px', width: 336, maxWidth: 336 }}>
                    <Grid container style={{ paddingBottom: 20, display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={4} style={{ width: 70, height: 70, maxWidth: 70, maxHeight: 70, border: '2.5px solid #fff', borderRadius: 200, padding: 5, display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                            {/* <img src={'/images/profile/initial_img.png'} style={{ borderRadius: 200, aspectRatio: 1 / 1, objectFit: 'cover', width: '100%', height: '100%' }} /> */}
                            <Typography style={{ color: '#fff', textTransform: 'uppercase', textAlign: 'center', fontSize: 30, width: '100%' }}>{username?.charAt(0)}</Typography>
                        </Grid>
                        <Grid item xs={7} style={{ color: '#fff', paddingLeft: 20 }}>
                            <Typography style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'lighter' }}>{username}</Typography>
                            <Link underline='none' to="/profile" component={RouterLink} style={{ color: '#fff' }} onClick={toggleDrawer(false)} >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 14, fontWeight: 'lighter' }}>{t('profile.view')}</Typography>
                                    <ChevronRightSharp style={{ marginLeft: 10 }} />
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs={1} style={{ color: '#fff', paddingLeft: 20}}>
                            <Link underline='none' to="/event" component={RouterLink} style={{ color: '#fff' }} onClick={toggleDrawer(false)} >
                                <MdOutlineQrCode2 style={{ fontSize: 35, textAlign:'center'}}/>
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container style={{ padding: 10, textAlign: 'center', borderRadius: 10, boxShadow: '3px 3px 12px 0px rgba(0, 0, 0, 0.15) inset, 3px 3px 4px 0px rgba(255, 255, 255, 0.15)', color: '#fff' }}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 10, textTransform: 'uppercase', paddingBottom: 5, fontWeight: 'lighter' }}>{t('genealogy.downlinesMember')}</Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography style={{ fontSize: 18 }}>{genealogy?.total_downlines || 0}</Typography>
                            <Typography className={styles.textDownlinesStyle}>{t('genealogy.total')}</Typography>
                        </Grid>
                        <hr style={{ border: '1px solid rgba(255, 255, 255, 0.27)' }} />
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: 18 }}>{genealogy?.total_direct_downlines || 0}</Typography>
                            <Typography className={styles.textDownlinesStyle}>{t('genealogy.direct')}</Typography>
                        </Grid>
                        <hr style={{ border: '1px solid rgba(255, 255, 255, 0.27)' }} />
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: 18 }}>{genealogy?.tree_data?.children.length || 0}</Typography>
                            <Typography className={styles.textDownlinesStyle}>{t('genealogy.active')}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container style={{ color: '#fff', padding: '30px 0' }}>
                        <Grid item xs={12} to="/profile-rank" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle} style={{ justifyContent: 'space-between' }}>
                        {/* <Grid item xs={12} className={styles.listItemStyle} style={{ justifyContent: 'space-between' }}>     */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <StarRounded />
                                <Typography className={styles.textListItemStyle}>{t('profile.benefit_level')}</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 14, fontWeight: 'lighter', marginRight: 5 }}>{(rank && _.size(rank) > 0) ? rank.name_display : (i18n.language === 'cn' ? '会员' : 'Member')}</Typography>
                                <ChevronRightSharp />
                            </div>
                        </Grid>

                        <Grid item xs={12} to="/recruit" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <PersonAddRounded />
                            <Typography className={styles.textListItemStyle}>{t('profile.inviteFriends')}</Typography>
                        </Grid>

                        <Grid item xs={12} to="/profile-address" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <LocationOnRounded />
                            <Typography className={styles.textListItemStyle}>{t('profile.address')}</Typography>
                        </Grid>

                        <Grid item xs={12} to="/order?type=new" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <ShoppingBasketRounded />
                            <Typography className={styles.textListItemStyle}>{t('title.order')}</Typography>
                        </Grid>


                        <Grid item xs={12} style={{ padding: '10px 0' }}>
                            <Divider style={{ background: 'rgba(255, 255, 255, 0.27)' }} />
                        </Grid>

                        <Grid item xs={12} to="/profile-bank-info" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <AccountBalanceRounded />
                            <Typography className={styles.textListItemStyle}>{t('profile.editBankInfo')}</Typography>
                        </Grid>
                        <Grid item xs={12} to="/profile-password" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <LockRounded />
                            <Typography className={styles.textListItemStyle}>{t('profile.changePassword')}</Typography>
                        </Grid>
                        <Grid item xs={12} to="/profile-security-password" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <VpnKeyRounded />
                            <Typography className={styles.textListItemStyle}>{t('profile.changeSecurityPassword')}</Typography>
                        </Grid>

                        <Grid item xs={12} style={{ padding: '10px 0' }}>
                            <Divider style={{ background: 'rgba(255, 255, 255, 0.27)' }} />
                        </Grid>

                        <Grid item xs={12} to="/policies" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <PolicyRounded />
                            <Typography className={styles.textListItemStyle}>{t('support.policies')}</Typography>
                        </Grid>
                        <Grid item xs={12} to="/contactUs" component={RouterLink} onClick={toggleDrawer(false)} className={styles.listItemStyle}>
                            <ContactSupport />
                            <Typography className={styles.textListItemStyle}>{t('support.contactUs')}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: 0, width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreOutlined style={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ padding: 0, margin: 0, display: 'flex', alignItems: 'baseline' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
                                        <LanguageRounded />
                                        <Typography className={styles.textListItemStyle}>{t('home.language')}</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '0 40px', color: '#fff' }}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="language" name="language" value={valueLanguage} >
                                            <FormControlLabel
                                                key={`en`}
                                                onChange={() => changeLanguage('en')}
                                                value="en"
                                                control={<Radio checkedIcon={<CheckCircle />} style={{ color: '#fff' }} />}
                                                label={<Typography style={{ fontSize: 14, fontWeight: 'lighter' }}>English</Typography>}
                                            />
                                            <FormControlLabel
                                                key={`cn`}
                                                onChange={() => changeLanguage('cn')}
                                                value="cn"
                                                control={<Radio checkedIcon={<CheckCircle />} style={{ color: '#fff' }} />}
                                                label={<Typography style={{ fontSize: 14, fontWeight: 'lighter' }}>中文</Typography>}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: 0, width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreOutlined style={{ color: '#fff' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ padding: 0, margin: 0, display: 'flex', alignItems: 'baseline' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
                                        <MdCurrencyExchange style={{ fontSize: 24 }} />
                                        <Typography className={styles.textListItemStyle}>{t('home.currency')}</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '0 40px', color: '#fff' }}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="currency" name="currency" value={currency} >
                                            {
                                                _.map(currencydisplay, (currencydisplay_detail, index) => {
                                                    let currency_code = currencydisplay_detail.desc.split('|');
                                                    return (
                                                        <FormControlLabel
                                                            key={`curr-${index}`}
                                                            onChange={() => changeCurrency(`${currencydisplay_detail['code']}`)}
                                                            value={currencydisplay_detail['code']}
                                                            control={<Radio checkedIcon={<CheckCircle />} style={{ color: '#fff' }} />}
                                                            label={<Typography style={{ fontSize: 14, fontWeight: 'lighter' }}>{`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `}</Typography>}
                                                        />
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                variant='outlined'
                                onClick={() => {
                                    setState(false);
                                    logoutUser(dispatch);
                                    history.replace('/login');
                                }}
                                style={{ width: '100%', color: '#fff', padding: '8px 12px', border: '1px solid #fff', borderRadius: 200 }}
                            >
                                {t('button.logout')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </ThemeProvider>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "transparent"
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    iconColor: {
        padding: 5,
        // backgroundColor: theme.palette.profile.user,
        // backgroundColor:'#d7d7d7',
        backgroundColor: '#c9c9c9',
        color: "#fff"
    },
    unreadiconColor: {
        padding: 5,
        width: 20,
        height: 20,
        // backgroundColor: theme.palette.profile.user,
        // backgroundColor:'#d7d7d7',
        backgroundColor: '#ff0000',
        color: "#fff"
    },
    textDownlinesStyle: {
        fontSize: 10,
        textTransform: 'uppercase',
        fontWeight: 'lighter',
    },
    listItemStyle: {
        textDecoration: 'none',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0',
    },
    textListItemStyle: {
        fontSize: 14,
        paddingLeft: 10,
        fontWeight: 'lighter',
    },
}));


const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiAccordionSummary: {
            content: {
                marginTop: 0,
                "&$expanded": {
                    marginTop: 0,
                    marginBottom: 5,
                }
            },
            root: {
                "&$expanded": {
                    minHeight: 'unset',
                }
            },
        },
        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
                padding: 10,
            }
        },
        MuiDrawer: {
            paperAnchorLeft: {
                left: 'none',
                backgroundColor: theme.palette.primary.main,
            }
        },
        MuiPaper: {
            root: {
                margin: '0px auto',
            }
        },
        MuiBackdrop: {
            root: {
                width: 'inherit',
                margin: '0px auto',
            }
        }
    }
});
