import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, BottomNavigationAction, BottomNavigation, Link, Typography, Avatar, Drawer, } from '@material-ui/core';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { changeLanguage as reduxChangeLanguage } from '../../actions';
import { API } from '../../configs/AxiosConfig';
import { getUrl } from '../../helper/ApiAction';

import { GroupAddOutlined, AccountTreeOutlined, AccountBalanceWalletOutlined, NotificationsNone, AttachMoney, ShoppingCartOutlined } from '@material-ui/icons';
import { FiUser, FiHome, FiFileText } from 'react-icons/fi';
import { LuShoppingBag } from 'react-icons/lu';

import theme from '../../Theme';
import DrawerBar from './DrawerBar';
import useNotificationLoading from '../../helper/useNotificationLoading';

import '../css/styles.css';

const HIDE_COMPONENT_PAGE = [
	"cart", "checkout", "payment", "chat", "customerservice", "recruit", "profile-bank-info", "profile-security-password", "profile-password", "profile-address", "profile-address-action", 
	"transfer", "transaction", "withdrawal",'forgot-password', 'login', 'splashscreen', 'register', "item", "order", "review", "edit-recipient", "event"];

export default function Footer() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();

	const { accessToken } = useSelector(state => state.general);
	const { addAlert, setLoading } = useNotificationLoading();
	const { t, i18n } = useTranslation();

	const [value, setValue] = useState(0);
	const [unreadNotification, setunreadNotification] = useState();
	const [currentPath, setCurrentPath] = useState();
	const [state, setState] = useState(false);

	useEffect(() => {
		if (_.includes(['en', 'cn'], i18n.language) === false) {
			changeLanguage('en');
		}
		API.defaults.headers.common['Language'] = i18n.language === 'cn' ? 'zh-CN' : 'en';
		// eslint-disable-next-line
	}, [i18n.language, t]);

	useEffect(() => {
		if (accessToken) {
			getUrl(`notifications`).then(response => {
				if (response.status) {
					setunreadNotification(response.new_count);
				}
			}).catch(error => {
				addAlert(JSON.stringify(error.message));
			});
		} else {
			setunreadNotification('');
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	useEffect(() => {
		let active = true;

		if (active) {
			const path = _.split(location.pathname, '/');
			if (path) {
				setCurrentPath(path[1].toLowerCase());
				if (path[1].toLowerCase() === "home") {
					setValue(0);
				}

				switch (path[1].toLowerCase() || currentPath || value) {
					case 'home' || '':
						setValue(0);
						break;
					case 'notification-category':
						setValue(1);
						break;
					case 'wallet':
						setValue(2);
						break;
					case 'cart':
						setValue(3);
						break;
					default:
						setValue(0);
						break;
				}
			}
		}
		return () => { active = false };
		// eslint-disable-next-line
	}, [location.pathname, currentPath, value, setValue])

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		dispatch(reduxChangeLanguage(lang));
	};

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState(open);
	};

	return (
		<div>
			{
				_.includes(['comingsoon'], currentPath) ?
					null
					:
					_.includes(HIDE_COMPONENT_PAGE, currentPath) === false ?
						<>
							<Box className='mobile-width-fixed' style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '8px 0', bottom: 0, zIndex: 9, position: 'fixed', height: 76, background: '#f6f7fa', background: 'linear-gradient(0deg, rgba(246,247,250,1) 0%, rgba(246,247,250,1) 29%)', backdropFilter: 'blur(5px)', }}>
								{/* <Link underline='none' component={RouterLink} to="/home">
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-home.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.home')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to="/contract">
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-list.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.activity')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to="/community/genealogySponsor">
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-community.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.group')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to={(accessToken ? "/wallet" : "/login")}>
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-wallet.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.wallet')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to={(accessToken ? "/setting" : "/login")}>
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-setting.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.profile')}</Typography>
									</Box>
								</Link> */}

								<ThemeProvider theme={bottomNavStyle}>
									<BottomNavigation
										value={value}
										onChange={(event, newValue) => {
											setValue(newValue);
										}}
										// showLabels
										className={classes.root}
										style={{ position: 'fixed', top: 'auto', bottom: 20, width: 380, borderRadius: 15, maxWidth: '91%', paddingTop: 10, boxShadow: 'rgb(0 0 0 / 8%) 2px -5px 10px 0px' }}
									>
										<BottomNavigationAction
											// to={(accessToken ? "/home" : "/login")}
											to="/home"
											component={RouterLink}
											label={t('title.home')}
											value={0}
											style={{ fontSize: 26 }}
											icon={<FiHome />}
										/>
										<BottomNavigationAction
											to={(accessToken ? "/notification-category" : "/login")}
											component={RouterLink}
											label={t('title.notification')}
											value={1}
											style={{ fontSize: 26 }}
											icon={<NotificationsNone />}
										/>
										{
											unreadNotification > 99 ?
												<Avatar variant="circle" className={classes.unreadiconColor} style={{ marginLeft: 5 }} >
													<Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 10 }}>{"99+"}</Typography>
												</Avatar>
												:
												(
													unreadNotification > 0 ?
														<Avatar variant="rounded" className={classes.unreadiconColor} style={{ marginLeft: 5 }} >
															<Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 10 }}>{unreadNotification}</Typography>
														</Avatar>
														:
														null
												)
										}
										<BottomNavigationAction
											to={(accessToken ? "/wallet" : "/login")}
											// disabled
											component={RouterLink}
											label={t('title.wallet')}
											value={2}
											style={{ fontSize: 26 }}
											icon={<AccountBalanceWalletOutlined />}
										/>
										<BottomNavigationAction
											to={(accessToken ? "/cart" : "/login")}
											// disabled
											component={RouterLink}
											label={t('title.cart')}
											value={3}
											style={{ fontSize: 24 }}
											icon={<LuShoppingBag />}
										/>
										{
											accessToken
												?
												<BottomNavigationAction
													label={t('title.profile')}
													value={4}
													style={{ fontSize: 26 }}
													icon={<FiUser />}
													onClick={toggleDrawer(true)}
												/>
												:
												<BottomNavigationAction
													to={"/login"}
													component={RouterLink}
													label={t('title.profile')}
													value={4}
													style={{ fontSize: 26 }}
													icon={<FiUser />}
												/>
										}
										{/* <BottomNavigationAction
											to={"/setting"}
											component={RouterLink}
											label={t('title.profile')}
											style={{ fontSize: 26 }}
											icon={<FiUser />}
										/> */}
									</BottomNavigation>
									<DrawerBar state={state} toggleDrawer={toggleDrawer} setState={setState} />
								</ThemeProvider>
								{/* </div> */}
							</Box>
						</>
						: null
			}
		</div>
	)
}

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	listItemStyle: {
		padding: '2px 0px 2px 0px',
	},
	footerFont: {
		fontSize: '13px',
		color: '#e53935',
	},
	iconStyle: {
		display: 'flex',
		alignItems: 'center',
		color: 'black'
	},
	appBar: {
		top: 'auto',
		bottom: 0,
	},
	unreadiconColor: {
		position: "absolute",
		[theme.breakpoints.up('ss')]: {
			right: 100,
		},
		[theme.breakpoints.up('s')]: {
			right: 100,
		},
		[theme.breakpoints.up('xs')]: {
			right: 120,
		},
		[theme.breakpoints.up('sm')]: {
			right: 120,
		},
		right: 10,
		padding: 5,
		width: 25,
		height: 20,
		backgroundColor: '#9FD01A',
		color: "#fff"
	},

}));

const bottomNavStyle = createMuiTheme({
	overrides: {
		MuiBottomNavigation: {
			root: {
				backgroundColor: theme.palette.primary.main,
				zIndex: 3,
			},
		},
		MuiButtonBase: {
			root: {
				'&$disabled': {
					color: '#ffffff82'
				},
			},
		},
		MuiBottomNavigationAction: {
			root: {
				minWidth: 65,
				color: "#fff",
				"&$selected": {
					color: '#fff',
					fontSize: '0.75rem'
				},
			}
		},
	},
});