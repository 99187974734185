import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Typography, Box, Link, Button, FormControl, Select, MenuItem, ThemeProvider, Drawer, IconButton, Tabs, Tab } from '@material-ui/core';
import { createMuiTheme, makeStyles, useTheme } from '@material-ui/core/styles';
import { CloseRounded } from '@material-ui/icons';
import { currencyFormat } from '../helper/Tools';
import { getUrl } from '../helper/ApiAction';

import useNotificationLoading from '../helper/useNotificationLoading';
import ImgHeader from './Layouts/ImgHeader';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import theme from '../Theme';

import './css/styles.css';

export default function TransactionNew() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const history = useHistory();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));

    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [walletName, setWalletName] = useState("");
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [transactionList, setTransactionList] = useState([]);
    const [open, setOpen] = useState(false);
    const [drawerInfo, setDrawerInfo] = useState([]);
    const [value, setValue] = useState(0);

    let factor = "";

    // ----------------------------------- API ----------------------------------------------
    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        let walletNameArray = _.split(existWallet.name, '|');
        setWalletName(_.size(walletNameArray) > 1 && i18n.language ==="cn" ? walletNameArray[1] : walletNameArray[0]);
        setUserWalletInfo(existWalletBalance);
        setTransactionList([]);
        setTotalPage(0);
        setPage(1);
    }, [wallet]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        let type = '';

        if (value === 0 ) { type = 'all'; } 
        else if (value === 1) { type = 'earn'; } 
        else if (value === 2) { type = 'spend'; }
        
        if(_.size(walletInfo) > 0) {
            getUrl(`wallets/${walletInfo.id}/history?type=${type}&page=${page}&per_page=10`).then(response => {
                setLoading(false);
                if(response.status && isMountedRef.current) {
                    const { last_page, data } = response.data;
                    setTotalPage(last_page);
                    if (page > 1) {
                        setTransactionList(_.concat(transactionList, data));
                    } else {
                        setTransactionList(data);
                    }
                }
            }).catch(err => {
                setLoading(false);
                addAlert(JSON.stringify(err.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [walletInfo, page, value]);

    // ----------------------------------- FUNCTIONS ----------------------------------------------
    const changeWallet = ({ target }) => {
        history.push(`/transaction/${target.value}`);
    }

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setTransactionList([]);
        setPage(1);
    };

    const openDrawer = drawerInfo => {
        setDrawerInfo(drawerInfo);
        setOpen(true);
    };

    const closeDrawer = () => {
        setDrawerInfo([]);
        setOpen(false);
    }

    return (
        <Box style={{ background: '#fff' }}>
            <ImgHeader cardDisplay title={
                <div style={{ width: '100%', maxWidth: '100%' }}>
                    <Typography style={{ fontSize: '100%' }}><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></Typography>
                    <p className='fs-25'>{(_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-')}</p>
                </div>
            }/>

            <div style={{ padding: '20px 30px 60px' }}>
                {/* <div style={{ width: '100%', paddingBottom: 10 }}>
                    <FormControl variant="outlined" fullWidth className={styles.formControl}>
                        <Select
                            value={wallet}
                            name="wallet"
                            onChange={changeWallet}
                            label={t('transaction.wallet')}
                        >
                            {
                                _.map(walletList, walletItem => (
                                    <MenuItem key={walletItem.code} value={walletItem.code}>{walletItem.wallet_name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div> */}

                <div>
                    <Box style={{ paddingBottom: 10 }}>
                        <ThemeProvider theme={accordingStyles}>
                            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: "#000", color: '#000' } }}>
                                <Tab label={t('transaction.allHistory')} {...a11yProps(0)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                                <Tab label={t('transaction.earning')} {...a11yProps(1)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                                <Tab label={t('transaction.spending')} {...a11yProps(2)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                            </Tabs>
                        </ThemeProvider>
                    </Box>
                    {
                        _.map([0, 1, 2], (tabArray, key) => {
                            return (
                                <CustomTabPanel value={value} index={tabArray} key={key}>
                                    {
                                        _.size(transactionList) > 0 ?
                                            _.map(transactionList, transactionList => {
                                                let operator = transactionList.factor > 0 ? "+" : "-";
                                                return (
                                                    <div key={transactionList.id}>
                                                        <div style={{ padding: '10px 0', cursor: 'pointer' }} onClick={() => openDrawer(transactionList)}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                                <div style={{ fontSize: 14 }}>
                                                                    <Typography>{_.hasIn(transactionList.type_display, i18n.language) ? transactionList.type_display[i18n.language] : '-'}</Typography>
                                                                    <Typography style={{ fontSize: 14, color: theme.palette.gray.text }}>{t('transaction.remark')}: {transactionList.remark}</Typography>
                                                                    <Typography className='txt_truncate2' style={{ fontSize: 14, color: theme.palette.gray.ltext, height: 'unset', lineHeight: 1.2 }}>{transactionList.updated_at ? transactionList.updated_at : '-'}</Typography>
                                                                </div>
                                                                <Typography style={{ fontSize: '100%', color: operator != "+" ? "#DD6666" : "green", wordWrap: 'break-word', width: '50%', textAlign: 'end' }}><b>{operator}{<NumberFormat value={parseFloat(transactionList.amount).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} />}</b></Typography>
                                                            </div>
                                                        </div>
                                                        <div style={{ background: '#fff', boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.10) inset', height: 4 }} />
                                                    </div>
                                                )
                                            })
                                            : <Typography style={{ fontSize: 14, textAlign: 'center', color: theme.palette.gray.text }}>{t('transaction.noTransaction')}</Typography>
                                    }
                                    {
                                        _.size(transactionList) > 0 && page < totalPage
                                            ? <Button variant="contained" onClick={loadTransaction} fullWidth style={{ marginTop: 10, padding: '8px 9px', borderRadius: 48, background: theme.palette.primary.main, color: '#fff', textTransform: 'capitalize' }}>{t('transaction.loadMore')}</Button>
                                            : _.size(transactionList) > 0 && <Typography style={{ textAlign: 'center', color: theme.palette.gray.ltext }}>{t('transaction.endOfList')}</Typography>
                                    }
                                </CustomTabPanel>
                            )
                        })
                    }
                </div>
            </div>

            <ThemeProvider theme={accordingStyles}>
                <Drawer anchor={'bottom'} open={open} onClose={closeDrawer} className='mobile-width-fixed' style={{ margin: '0px auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <IconButton onClick={closeDrawer}>
                            <CloseRounded style={{ color: '#BABABA' }} />
                        </IconButton>
                    </div>
                    <div>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Typography style={{ textTransform: 'uppercase', fontSize: 14 }}><b>{t('transaction.amount')}</b></Typography>
                            <Typography style={{ fontWeight: 'bold', fontSize: 24, color: drawerInfo.factor > 0 ? "green" : "#DD6666", marginLeft: '-15px' }}>{`${drawerInfo.factor > 0 ? "+" : "-"} ${currencyFormat(drawerInfo.amount || 0)}`} {walletName}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('transaction.transaction')}</Typography>
                            <Typography style={{ fontSize: 14 }}>{_.hasIn(drawerInfo.type_display, i18n.language) ? drawerInfo.type_display[i18n.language] : '-'}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('transaction.remark')}</Typography>
                            <Typography style={{ fontSize: 14 }}>{drawerInfo.remark}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Box display="flex" width="100%">
                                <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.dateTime')}</Typography>
                                <Typography style={{ fontSize: 14, flex: 2 }}>{drawerInfo.created_at}</Typography>
                            </Box>
                            <Box display="flex" width="100%">
                                <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.idNo')}</Typography>
                                <Typography style={{ fontSize: 14, flex: 2 }}>{_.size(drawerInfo.transaction_details) > 0 ? drawerInfo.transaction_details[0].transaction_id : '-'}</Typography>
                            </Box>
                        </Box>
                    </div>
                </Drawer>
            </ThemeProvider>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    bonusCard:{
        position: 'absolute',
        top: 0,
        padding: 25, 
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            top: '-1vw'
        },
        [theme.breakpoints.down('sm')]: {
            top: '-1vw'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-1vw'
        },
      
    },
    walletButtonStyle: {
        width: 300, 
        height: 55,
        borderRadius: 7,
        border: '1px solid rgba(255, 255, 255, 0.34)',
        background: 'rgba(255, 255, 255, 0.50)',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.13)',
        backdropFilter: 'blur(6px)',
        position: 'absolute',
        top: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    walletButtonTextStyle: {
        fontSize: 10,
    },
    slideListStyle: {
        '& .slick-list': {
            height: 240,
            padding: '20px 0',
        }
    },
}));

const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiAccordionSummary: {
            content: {
                marginTop: 0,
                "&$expanded": {
                    marginTop: 0,
                    marginBottom: 5,
                }
            },
            root: {
                "&$expanded": {
                    minHeight: 'unset',
                }
            },
        },
        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
                padding: 10,
            }
        },
        MuiDrawer: {
            paperAnchorBottom: {
                left: 'auto',
                right: 'auto',
                width: "inherit",
                height: 400,
                margin: '0px auto',
                borderRadius: '40px 40px 0 0',
                padding: '30px 35px 10px',
                backgroundColor: '#fff',
            }
        },
        MuiPaper: {
            root: {
                margin: '0px auto',
            }
        },
        MuiBackdrop: {
            root: {
                width: 'inherit',
                margin: '0px auto',
            }
        },
        MuiOutlinedInput: {
            root: {
                justifyContent: 'flex-start',
                fontSize: 15,
                fontWeight: 400,
                textTransform: 'capitalize',
                borderRadius: 48,
                boxShadow: 'inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff'
            }
        },
        MuiTab: {
            root: {
                minWidth: 120,
                '@media (min-width: 600px)': {
                    minWidth: 120
                },
                "&.Mui-selected": {
                    color: '#000',
                }
            },
        },
    }
});

// ----------------------------------------------- UI DESIGN ---------------------------------------------
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}