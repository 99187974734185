import React, { useState, useEffect, useRef , createRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { 
    TextField, Button, Typography, Grid, Box, Divider, List, Stepper, Step, StepLabel, Switch, Drawer, Hidden, ListItem, Chip, 
    Dialog, DialogActions, DialogContentText, Snackbar, Card, CardContent, DialogContent, DialogTitle, FormControl, Select, 
    FormHelperText, Avatar, IconButton, MenuList, MenuItem
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider, MuiThemeProvider, styled } from '@material-ui/core/styles';
import { getUrl, postUrl, putUrl, deleteUrl } from '../helper/ApiAction';

import { ArtTrackOutlined,AspectRatioOutlined, CloseRounded } from '@material-ui/icons';

import useNotificationLoading from '../helper/useNotificationLoading';
import ImgHeader from './Layouts/ImgHeader';
import _ from 'lodash';

import './css/styles.css';
import theme from '../Theme';

const INITIAL_ADDRESS_STATE = { name: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "", default: false };

export default function ProfileAddressAction() {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const icFrontRef = useRef();
    const icBackRef = useRef();

    let { action, id } = useParams();
    const { t ,i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        name: "",
        mobile: "",
        email: "",
        identity_no: "",
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
        icFront: "",
        icBack: "",
    })
    const [shouldUploadIC, setShouldUploadIC] = useState(false);
    const [addresses, setAddress] = useState([]);
    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [defaultAddress, setDefaultAddress] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    let steps = [
        { label: t('address.state'), name: 'state', item: addressState },
        { label: t('address.city'), name: 'city', item: cities },
    ];

    // ---------------------- API -------------------------------------
    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            setLoading(false);
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            if (isMountedRef.current) {
                setCountry(countryList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country]);

    useEffect(() => {
        isMountedRef.current = true;
        if(addressFormState.country) {
            if(_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if(isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
    
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.state) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, (addressFormState.state).toString())) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.city) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

    useEffect(() => {
        if(addressFormState.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressFormState.country])

    const getUserAddress = () => {
        setLoading(true);
        getUrl(`user/address/${id}`).then(addressList => {
            if(isMountedRef.current) {
                setLoading(false);
                setAddress(addressList.data);
                const { name, mobile, email, identity_no, address, address2, city, state, zip, country, icFront, icBack } = addressList.data;
                let newAddressFormState = {
                    name: name || "",
                    mobile: mobile || "",
                    email: email || "",
                    identity_no: identity_no || "",
                    address,
                    address2,
                    city,
                    state,
                    zip,
                    country,
                    icFront,
                    icBack,
                    default: addressList.data.default,
                };
                if (_.size(icFront) > 0) {
                    newAddressFormState['icFront'] = icFront.file_name;
                }
                if (_.size(icBack) > 0) {
                    newAddressFormState['icBack'] = icBack.file_name;
                }

                setDefaultAddress(addressList.data.default);
                setAddressFormState(newAddressFormState);
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        if (id !== undefined) {
            getUserAddress();
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    // ----------------------- FUNCTION -----------------------------------
    const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setOpen(open);
        handleReset();
	};

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleAddressChange = async (event) => {
        const name = event.target.name || event.target.getAttribute('name');
        let value = event.target.value;

        if(name === 'icFront') {
            if(icFrontRef.current.files[0]) {
                const a = await toBase64(icFrontRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        } else if(name === 'icBack') {
            if(icBackRef.current.files[0]) {
                const a = await toBase64(icBackRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        }
        if (name === "state" || name === 'city') {
            value = value.toString();
        }
        setAddressFormState({ ...addressFormState, [name]: value });
        
        if (activeStep !== (steps.length - 1)) {
            handleNext();
        } else if (_.size(steps[activeStep].item) > 0) {
            setOpen(false);
        }

        if(name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };

    const switchDefaultAddress = () => {
        setDefaultAddress(!defaultAddress);
        setAddressFormState(addressFormState => ({ ...addressFormState, default: !defaultAddress }));
    }

    const makeDefaultAddress = (addressId) => {
        const { name, mobile, address, address2, city, state, zip, country, email, identity_no } = addressFormState;
        const goDefaultAddress = {
            name, mobile, address, address2, city, state, zip, country, default: defaultAddress ? 1 : 0, email,identity_no
        }
        putUrl(`user/address/${addressId}`, goDefaultAddress).then(result => {
            if(result.error) {
                let errMsg = "";
                _.map(result.error, (errorItem) => {
                    errMsg += errorItem + " ";
                })
                addAlert(errMsg);
            }else{
                if (id) {
                    addAlert(result.message, 'success');
                    getUserAddress();
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const submitAddress = () => {
        setLoading(true);
        if (action === 'add') {
            postUrl('user/address', addressFormState).then(result => {
                setLoading(false);
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    addAlert(t('profile.fillUpRequiredAddress'));
                } else {
                    if (defaultAddress) {
                        makeDefaultAddress(result.data.id);
                    }
                    addAlert(result.message, 'success');
                    history.goBack();
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        } else {
            putUrl(`user/address/${id}`, addressFormState).then(result => {
                setLoading(false);
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    addAlert(t('profile.fillUpRequiredAddress'));
                } else {
                    if (defaultAddress) {
                        makeDefaultAddress(id);
                    }
                    setAddressFormState(INITIAL_ADDRESS_STATE);
                    addAlert(result.message, 'success');
                    getUserAddress();
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    return (
        <Box>
            <ImgHeader imageDisplay={false} normalDisplay />
            <div className='p-lr-25 p-t-40 p-b-90'>
                <div className='p-b-20'>
                    <Typography variant='h6' style={{ paddingTop: 30, paddingBottom: 20 }}><b>{action === 'add' ? t('button.addAddress') : t('button.changeAddress')}</b></Typography>

                    <Typography>{t('profile.contact')}</Typography>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        {/* <Typography>{t('address.recipient')}</Typography> */}
                        <TextField
                            onChange={handleAddressChange}
                            variant="outlined"
                            type="text"
                            placeholder={t('address.recipient')}
                            inputProps={{ name: 'name' }}
                            value={addressFormState.name}
                            error={errorAddressState.name ? true : false}
                            helperText={errorAddressState.name}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        {/* <Typography>{t('address.mobile')}</Typography> */}
                        <TextField
                            onChange={handleAddressChange}
                            variant="outlined"
                            type="text"
                            placeholder={t('address.mobile')}
                            inputProps={{ name: 'mobile' }}
                            value={addressFormState.mobile}
                            error={errorAddressState.mobile ? true : false}
                            helperText={errorAddressState.mobile}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        {/* <Typography>{t('address.email')}</Typography> */}
                        <TextField
                            onChange={handleAddressChange}
                            variant="outlined"
                            type="text"
                            placeholder={t('address.email')}
                            inputProps={{ name: 'email' }}
                            value={addressFormState.email}
                            error={errorAddressState.email ? true : false}
                            helperText={errorAddressState.email}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        <Select
                            native
                            value={addressFormState.country}
                            onChange={handleAddressChange}
                            inputProps={{ name: 'country' }}
                            placeholder={t('address.country')}
                            error={errorAddressState.country ? true : false}
                        >
                            <option value="">{t('address.pleaseSelect')}{t('address.country')}</option>
                            {
                                _.map(countries, countryItem => {
                                    return (
                                        <option key={countryItem.code} value={countryItem.code}>{countryItem.name_display}</option>
                                    )
                                })
                            }
                        </Select>
                        <FormHelperText>{errorAddressState.country}</FormHelperText>
                    </FormControl>
                    {
                        shouldUploadIC &&
                        <>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                {/* <Typography>{t('address.identity_no')}</Typography> */}
                                <TextField
                                    onChange={handleAddressChange}
                                    variant="outlined"
                                    type="text"
                                    placeholder={t('address.identity_no')}
                                    inputProps={{ name: 'identity_no' }}
                                    value={addressFormState.identity_no}
                                    error={errorAddressState.identity_no ? true : false}
                                    helperText={errorAddressState.identity_no}
                                />
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <Typography variant="body2">{t('checkout.chinaRecipientNeedIC')}</Typography>
                                <Box display="flex" flexDirection="row">
                                    <input hidden accept="image/*" type="file" ref={icFrontRef} name="icFront" onChange={handleAddressChange} />
                                    <input hidden accept="image/*" type="file" ref={icBackRef} name="icBack" onChange={handleAddressChange} />
                                    <Button
                                        className={styles.uploadButtonContainer}
                                        onClick={() => icFrontRef.current.click()}
                                        variant="outlined"
                                        // startIcon={addressFormState.icFront ? <Avatar src={addressFormState.icFront} /> : <ArtTrackOutlined />}
                                    >
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
                                            {
                                                addressFormState.icFront
                                                    ? <img src={addressFormState.icFront} alt="ic front" />
                                                    : <ArtTrackOutlined fontSize="large" />
                                            }
                                            <Typography variant="body2" style={{ fontSize: 14, textTransform: 'capitalize' }}>{t('checkout.icFront')}</Typography>
                                        </Box>
                                    </Button>
                                    <Button
                                        className={styles.uploadButtonContainer}
                                        onClick={() => icBackRef.current.click()}
                                        variant="outlined"
                                        // startIcon={addressFormState.icBack ? <Avatar src={addressFormState.icBack} /> : <AspectRatioOutlined />}
                                    >
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
                                            {
                                                addressFormState.icBack
                                                    ? <img src={addressFormState.icBack} alt="ic back" />
                                                    : <AspectRatioOutlined fontSize="large" />
                                            }
                                            <Typography variant="body2" style={{ fontSize: 14, textTransform: 'capitalize' }}>{t('checkout.icBack')}</Typography>
                                        </Box>
                                    </Button>
                                </Box>
                                <Typography variant="caption" color="error">{errorAddressState.icFront}</Typography>
                                <Typography variant="caption" color="error">{errorAddressState.icBack}</Typography>
                            </FormControl>
                        </>
                    }
                </div>

                <div className='p-b-20'>
                    <Typography>{t('address.address')}</Typography>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        <TextField
                            onChange={handleAddressChange}
                            variant="outlined"
                            type="text"
                            placeholder={t('address.address')}
                            inputProps={{ name: 'address' }}
                            value={addressFormState.address}
                            error={errorAddressState.address ? true : false}
                            helperText={errorAddressState.address}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        <Button
                            fullWidth
                            onClick={toggleDrawer(true)}
                            disabled={_.includes(addressGroup.stateGroup, addressFormState.country) > 0 ? false : true}
                            style={{
                                justifyContent: 'flex-start',
                                color: addressFormState.state && addressFormState.city ? '#000' : '#b8b8b8',
                                fontSize: 15,
                                fontWeight: 400,
                                textTransform: 'capitalize',
                                padding: '15px 14px',
                                borderRadius: 48,
                                boxShadow: 'inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff'
                            }}
                        >
                            {
                                addressFormState.state ?
                                    _.map(addressState, stateItem => {
                                        if (stateItem.id === parseInt(addressFormState.state)) {
                                            return (stateItem.name_display)
                                        }

                                    })
                                    : t('address.state')
                            },&nbsp;
                            {
                                addressFormState.city ?
                                    _.size(cities) > 0 ?
                                        _.map(cities, cityItem => {
                                            if (cityItem.id === parseInt(addressFormState.city)) {
                                                return (cityItem.name_display)
                                            }
                                        })
                                        : addressFormState.city
                                    : t('address.city')
                            }
                        </Button>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        {
                            _.size(district) > 0 ?
                                <>
                                    <Select
                                        native
                                        value={addressFormState.address2}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'address2' }}
                                        error={errorAddressState.address2 ? true : false}
                                    >
                                        <option value="">{t('address.pleaseSelect')}{t('address.address2')}</option>
                                        {
                                            _.map(district, districtItem => {
                                                return (
                                                    <option key={districtItem.id} value={districtItem.id}>{districtItem.name_display}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.address2}</FormHelperText>
                                </>
                                :
                                <TextField
                                    onChange={handleAddressChange}
                                    variant="outlined"
                                    type="text"
                                    placeholder={t('address.address2')}
                                    inputProps={{ name: 'address2' }}
                                    value={addressFormState.address2}
                                    error={errorAddressState.address2 ? true : false}
                                    helperText={errorAddressState.address2}
                                />
                        }
                    </FormControl>
                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                        <TextField
                            inputProps={{ name: 'zip' }}
                            onChange={handleAddressChange}
                            placeholder={t('address.zip')}
                            variant="outlined"
                            type="text"
                            value={addressFormState.zip}
                            error={errorAddressState.zip ? true : false}
                            helperText={errorAddressState.zip}
                        />
                    </FormControl>
                </div>

                <div className='p-b-20' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography className='fs-18'>{t('button.setDefault')}</Typography>
                    <IOSSwitch sx={{ m: 1 }} onClick={switchDefaultAddress} checked={defaultAddress} />
                </div>
            </div>

            <Box className='flex-c-m p-t-30 p-lr-25 button-fixed' style={{ bottom: 20, background: '#fff2', backdropFilter: 'blur(5px)' }}>
                <Button variant="contained" onClick={submitAddress} style={{ width: '100%', padding: '8px 9px', borderRadius: 48, background: theme.palette.primary.main, color: '#fff' }} >
                    {action === 'add' ? t('button.addAddress') : t('button.changeAddress')}
                </Button>
            </Box>

            <ThemeProvider theme={accordingStyles}>
                <Drawer anchor={'bottom'} open={open} onClose={toggleDrawer(false)} className='mobile-width-fixed' style={{ margin: '0px auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography className='fs-20' style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{t('address.pleaseRegion')}</Typography>
                        <IconButton onClick={toggleDrawer(false)}>
                            <CloseRounded style={{ color: '#BABABA' }} />
                        </IconButton>
                    </div>
                    <div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {
                                _.map(steps, (list, index) => {
                                    return (
                                        <Step key={index}>
                                            <StepLabel style={{ textTransform: 'uppercase' }}>
                                                {list.label}
                                            </StepLabel>
                                        </Step>
                                    )
                                })
                            }
                        </Stepper>
                    </div>
                    <Divider style={{ marginBottom: 20 }} />
                    {
                        _.size(steps[activeStep].item) > 0 ?
                            <div>
                                <MenuList style={{ overflow: 'auto' }}>
                                    {
                                        _.map(steps[activeStep].item, itemDetail => {
                                            return (
                                                <MenuItem key={itemDetail.id} name={steps[activeStep].name} value={itemDetail.id} onClick={handleAddressChange} style={{ justifyContent: 'flex-start', padding: '5px 0', fontSize: 16 }}>
                                                    {itemDetail.name_display}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </MenuList>
                            </div>
                            :
                            <FormControl fullWidth variant="outlined" className={styles.formControl} >
                                <Typography>{t('address.pleaseEnter') + t(`address.${steps[activeStep].name}`)}</Typography>
                                <TextField
                                    onChange={handleAddressChange}
                                    variant="outlined"
                                    type="text"
                                    inputProps={{ name: steps[activeStep].name }}
                                    value={addressFormState[steps[activeStep].name]}
                                    error={errorAddressState[steps[activeStep].name] ? true : false}
                                    helperText={errorAddressState[steps[activeStep].name]}
                                />
                            </FormControl>
                    }
                    
                    {
                        activeStep === (steps.length - 1) && _.size(steps[activeStep].item) <= 0 &&
                        <Button variant="contained" onClick={() => setOpen(false)} style={{ marginTop: 10, width: '100%', padding: '8px 9px', borderRadius: 48, background: theme.palette.primary.main, color: '#fff' }} >
                            {t('button.done')}
                        </Button>
                    }
                </Drawer>
            </ThemeProvider>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15, 
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    paddingVerticalZero: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    chipLabel: {
        fontSize: 15
    },
    formControl: {
        padding: 5
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    inputFile: {
        display: 'none',
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    }
}));

const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiAccordionSummary: {
            content: {
                marginTop: 0,
                "&$expanded": {
                    marginTop: 0,
                    marginBottom: 5,
                }
            },
            root: {
                "&$expanded": {
                    minHeight: 'unset',
                }
            },
        },
        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
                padding: 10,
            }
        },
        MuiDrawer: {
            paperAnchorBottom: {
                left: 'auto',
                right: 'auto',
                width: "inherit",
                height: 543,
                margin: '0px auto',
                borderRadius: '40px 40px 0 0',
                padding: '30px 35px 10px',
                backgroundColor: '#fff',
            }
        },
        MuiPaper: {
            root: {
                margin: '0px auto',
            }
        },
        MuiBackdrop: {
            root: {
                width: 'inherit',
                margin: '0px auto',
            }
        },
        MuiOutlinedInput: {
            root: {
                justifyContent: 'flex-start',
                fontSize: 15,
                fontWeight: 400,
                textTransform: 'capitalize',
                borderRadius: 48,
                boxShadow: 'inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff'
            }
        }
    }
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 60,
    height: 30,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        backgroundImage: `linear-gradient(to bottom right, rgba(0, 150, 25, 0), rgba(46, 82, 60, 0))`,
        '&.Mui-checked': {
            transform: 'translateX(30px)',
            color: theme.palette.primary.light,
            '& + .MuiSwitch-track': {
                background: '#ededed',
                boxShadow: 'inset 2px 2px 6px 0 #00961926, 3px 4px 3px 0 #fff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 26,
        height: 26,
    },
    '& .MuiSwitch-track': {
        borderRadius: 30 / 2,
        background: '#ededed',
        boxShadow: 'inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff',
        border: 0,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));