import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import _ from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, Typography, Container, Grid, Link, CardMedia, InputAdornment, TextField, useTheme, Hidden, Tooltip } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { getUrl } from '../helper/ApiAction';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';
import {Search } from 'react-feather';
import WebpImg from './Layouts/WebpImg';

export default function Categories() {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [options] = useState([]);
    let history = useHistory();

    const [categoryProducts, setCategoryProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [loading, setLoading] = useState(true);

    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();

    let { keyword } = useParams();
    let query = useQuery();
    const pageNumber = query.get("page");

    const { t, i18n } = useTranslation();
    const styles = useStyles();

    const [keywords, setKeywords] = useState('');

    useEffect(() => {
        isMountedRef.current = true;
        if (keyword) {
        getUrl(`search/${keyword}?page=${pageNumber ? pageNumber : 1}`)
            .then(response => {
                if (response) {
                    if (isMountedRef.current) {
                        let { last_page } = response.data.meta;
                        setTotal(last_page);
                        setCategoryProducts(response.data.data);
                        setLoading(false);
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        } else {
            getUrl('products').then(productList => {
                setTotal(1);
                setCategoryProducts(productList.data);
                setLoading(false);
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [keyword, pageNumber, addAlert]);


    const handleChange = (event, value) => {
        setPage(value);
        getUrl(`search/${keyword}?page=${value}`)
        .then(response => {
            if (response) {
                let { last_page } = response.data.meta;
                setCategoryProducts(response.data.data);
                setTotal(last_page);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    };

    return (
        <Box>
            {/* <Container fixed className={ styles.breadcrumbRoot }>
                <Grid container spacing={1} justify="center">
                    <Grid item xs={10}>
                        <List>
                            <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <Breadcrumbs separator="›" aria-label="breadcrumb" style={{ fontSize: 12 }}>
                                    {
                                        _.map(breadcrumb, (breadcrumbItem) => {
                                            return (
                                                <Link key={breadcrumbItem.id} underline='none' color="secondary" href={`/category/${breadcrumbItem.id}`} >
                                                    { breadcrumbItem.name }
                                                </Link>
                                            )
                                        })
                                    }
                                </Breadcrumbs>
                            </ListItem>
                            <Divider />
                            {
                                _.size(categoryChilds) > 0 ?
                                    <Box>
                                        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <Grid container spacing={2}>
                                                { _.map(categoryChilds, value => {
                                                        let title_translate = _.split(value.cat_name, '|');
                                                    return (
                                                        <Grid item key={value.id}>
                                                            <Link underline='none' color="secondary" href={`/category/${value.id}`}  style={{ fontSize: 12 }}>                                        
                                                                { i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] }                                        
                                                            </Link>
                                                        </Grid>
                                                    )
                                                }) }
                                            </Grid>
                                        </ListItem>
                                        <Divider />
                                    </Box>
                                :
                                null
                            }
                        </List>
                    </Grid>
                </Grid>
            </Container> */}

            <Box style={{ padding: '30px 0' }}>

                <Hidden smUp>
                    <Container>
                        <Typography style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold', paddingBottom: '10px' }}>{t('general.search')}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Autocomplete
                                style={{ width: '100%', maxWidth: '100%' }}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                options={options}
                                loading={loading}
                                loadingText={`${t('general.loading')}...`}
                                onInputChange={(event, newInputValue) => {
                                    setKeywords(newInputValue)
                                }}
                                onChange={(event, value) => {
                                    if (value && value.id) {
                                        setOpen(false);
                                        history.push(`/item/${value.id}`);
                                    }
                                }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={t('general.search')}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start"><Search style={{ color: theme.palette.gray.main }} /></InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </Fragment>
                                                ),
                                                style: {
                                                    height: 60,
                                                    padding: '0 14px',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '10px',
                                                }
                                            }}
                                            onKeyPress={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    ev.preventDefault();
                                                    setOpen(false);
                                                    history.push(`/search/${keywords}`);
                                                }
                                            }}
                                        />
                                    )
                                }}
                                renderOption={(option, state) => (
                                    // <Box display="flex" style={{ flex: 1 }}>
                                    <Link underline='none' to={`/item/${option.id}`} component={RouterLink} style={{ flex: 1 }}>
                                        {option.name}
                                    </Link>
                                    // </Box>
                                )}
                            />
                        </div>
                    </Container>
                </Hidden>
            </Box>
            <Container fixed className={styles.root}>
                <Grid container spacing={1} justify="center">
                    {loading ?
                        <h1>Loading</h1>
                        : categoryProducts.length > 0 ?
                            <Grid item xs={12} sm={10} style={{padding:'0 10px'}}>
                                <Typography>{t('home.searchResult')}{keyword}</Typography>
                                <Grid container spacing={1}>
                                    {_.map(categoryProducts, (product) => {
                                        const title_translate = _.split(product.title, '|');

                                        let showRetail = false;
                                        let discountPercent = 0;
                                        if (parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(product.sell_price)) {
                                            showRetail = true;
                                            discountPercent = ((parseFloat(product.retail_price) - parseFloat(product.sell_price)) / parseFloat(product.retail_price)) * 100;
                                            discountPercent = Math.round(discountPercent);
                                        }

                                        return (
                                            <Grid item xs={6} sm={4} md={2} key={product.id} className={styles.cardContentRootStyle}>
                                                <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                                                    <Card variant="outlined" style={{ borderRadius: '15px', position: 'relative' }}>
                                                        <CardMedia style={{ padding: 10 }}>
                                                            <WebpImg src={product ? product.images_array[0] : ''} style={{ height: 150 }} alt={product[`title_${i18n.language}`]} />
                                                        </CardMedia>
                                                        <CardContent>
                                                            <Tooltip title={i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}>
                                                                <Typography variant="body2" color="textSecondary" noWrap>
                                                                    {i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}
                                                                </Typography>
                                                            </Tooltip>
                                                            {
                                                                showRetail ?
                                                                    <Typography align="center" variant="body2" style={{ fontSize: 10, fontWeight: 'bold', textDecoration: 'line-through' }}>
                                                                        <NumberFormat value={product.retail_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en} `} />
                                                                        {/* {`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } ${product.retail_price}`} */}
                                                                    </Typography>
                                                                    : <Typography style={{ height: 18 }}></Typography>
                                                            }
                                                            <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                                <NumberFormat value={product.sell_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en} `} />
                                                                {/* {`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } ${product.sell_price}`} */}
                                                            </Typography>
                                                        </CardContent>
                                                        {
                                                            showRetail ?
                                                                <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                    <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                                </Box>
                                                                : null
                                                        }
                                                        <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor:'#f75757' }}>
                                                        {
                                                            _.map(product.ipoint, (idata, key) => {
                                                                if(idata.warehouse_id === 0){
                                                                    return (
                                                                        <Typography key={key} style={{ color: '#fff', fontSize:16,  }} variant="body2"><NumberFormat value={ parseFloat(idata.ipoint) } decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        </Box>
                                                    </Card>
                                                </Link>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                <Grid container spacing={1} justify="center" >
                                    <Pagination count={total} shape="rounded" page={page}
                                        onChange={handleChange}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                component={RouterLink}
                                                to={`/search/${keyword}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <h1>{t('product.noResult')}</h1>
                    }
                </Grid>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
}));