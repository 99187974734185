import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';
import { SELLER_URL } from '../configs/Config';
import _ from 'lodash';

import { makeStyles, useTheme, createMuiTheme, ThemeProvider, FormControlLabel, FormControl, Radio, RadioGroup, Checkbox, Typography, Avatar, Link, Box, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { PersonAdd, PersonOutlineRounded, MeetingRoomOutlined, PublicOutlined, ExpandMoreOutlined, NotificationsNoneRounded, LinkOutlined, ChevronRightSharp, CheckCircle, AccountBalanceOutlined, AssignmentInd, ContactMailOutlined, LocalMall, AssignmentRounded, HomeWorkRounded, LinkRounded, AccountTreeOutlined, HowToReg, NotificationsActive, SupervisedUserCircleOutlined,ConfirmationNumber } from '@material-ui/icons';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { FiChevronRight, FiKey, FiPhone } from 'react-icons/fi';
import { FaTruck, FaUserEdit, FaDoorOpen, FaBoxOpen } from 'react-icons/fa';
import { BsInfoLg, BsFillFileTextFill, BsTicketPerforatedFill } from 'react-icons/bs';
// import { IoTicket } from 'react-icons/io';
import { AiFillFileText, AiOutlineFileText } from 'react-icons/ai';
import { MdCall, MdLocationOn } from 'react-icons/md';

import { changeLanguage as reduxChangeLanguage, changeCurrency as reduxChangeCurrency } from '../actions';
import { getUrl, logoutUser } from '../helper/ApiAction';
import TitleBar from './Layouts/TitleBar';
import theme from '../Theme';

import { MdQrCode } from "react-icons/io";
import { IoQrCode, IoQrCodeSharp } from "react-icons/io5";

export default function Setting() {
    const { t, i18n } = useTranslation();
    const [valueLanguage, setLanguage] = useState(i18n.language);

    const { accessToken, role, currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    const [currency2, setcurrency2] = useState(currency);
    const { fr_id, max_rank, username, id, name, email, mobile, nric, referral_username, max_rank: rank, max_mrank, } = useSelector(state => state.user);
    const [unreadNotification, setunreadNotification] = useState();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const history = useHistory();

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };
    const changeCurrency = (curr) => {
        setcurrency2(curr);
        dispatch(reduxChangeCurrency(curr));
    };
    const goToIshopping = () => {
        if (accessToken) {
            getUrl(`/login-to/ishopping`).then(response => {
                setLoading(false);
                if (response.status === 1 && response.url) {
                    window.open(response.url, '_blank').focus();
                } else {
                    addAlert(t('general.loginRedirectError'));
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }
    }
    useEffect(() => {
        getUrl(`notifications`).then(response => {
            if (response.status) {
                setunreadNotification(response.new_count);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

    }, []);
    const[type,setType]= useState('/images/general/card/default.png');
    const[cardFrontColour,setCardFrontColour]= useState('#3a3732');
    useEffect(() => {
        if (accessToken && rank){
            switch (rank.name_display){
                case 'Classic':
                    setCardFrontColour('#ffffffb8')
                    setType('/images/general/card/purple.png') 
                    break;
                case 'Bronze':
                    setCardFrontColour('#ffffffb8')
                    setType('/images/general/card/copper.png')
                    break;
                case 'Silver':
                    setType('/images/general/card/silver.png')
                    break;
                case 'Gold':
                    setType('/images/general/card/gold.png')
                    break;
                case 'Platinum':
                    setCardFrontColour('#ffffffb8')
                    setType('/images/general/card/platinum.png')
                    break;
                case 'Basic':
                    setCardFrontColour('#ffffffb8')
                    setType('/images/general/card/basic.png')
                    break;
                case 'Advance':
                    setCardFrontColour('#ffffffb8')
                    setType('/images/general/card/advanced.png')
                    break;
                default :
                    setType('/images/general/card/default.png') 
                    break;
            }
        }
    }, []);


    return (

        <Box style={{ background: theme.palette.white.mobileBkg, paddingBottom: 80, minHeight: '100%' }}>{/* className={styles.root} style={{ padding: '0 0 80px',}} */}
            <TitleBar height={70} title={t('title.setting')} displayInfo displayQr currencyButton displayCart linkMall logoIcon />
            <div className='fixed-header-padding'>
                {accessToken ?
                    <div style={{ padding: 15 }}>
                        <div style={{ position: 'relative' }}>
                            <img src={type} alt="card" style={{ width: '100%', borderRadius: 15 }} />
                            {/* <div style={{ position: 'absolute', right: 16, top: 14 }}>
                                <IoQrCodeSharp style={{ fontSize: 35 }} />
                            </div> */}
                            <div style={{ position: 'absolute', top: 0, right: 0, width: '60%', height: '100%', paddingTop: 25, color:cardFrontColour }}>
                                <div style={{ paddingTop: 13, paddingRight:15, textAlign:'right' }}>
                                    <p style={{ fontSize: 23, margin: 0, width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }}><b>{username}</b></p>
                                    <p style={{ margin: 0 }}>{(max_mrank && _.size(max_mrank) > 0) ? max_mrank.name_display : ''}</p>
                                </div>
                                <div style={{ paddingTop: 10, paddingRight:15, textAlign:'right' }}>
                                    <p style={{ margin: 0 }}>{mobile}</p>
                                    <p style={{ margin: 0 }}>{email}</p>
                                </div>
                            </div>
                            <p style={{ textTransform: 'uppercase', fontSize: 40, width: '70%', textAlign:'right', fontWeight: 'bold', opacity: 0.2, margin: 0, position: 'absolute', right: 18, bottom: 10, color:cardFrontColour }}>{(rank && _.size(rank) > 0) ? rank.name_display : 'Guest'}</p>
                        </div>
                    </div>
                    :
                    null
                }

                {/* //Account */}
                {accessToken ?
                    <Box style={{ padding: '18px 15px 0' }}>
                        <div className='setting-title-bar' style={{ backgroundColor: theme.palette.primary.main, marginBottom: 16 }}>
                            <Typography>{t('title.account')}</Typography>
                        </div>

                        <Link underline='none' to="/profile" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <FaUserEdit fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('profile.editProfile')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                        {
                            // _.size(max_rank) > 0 &&
                            <Link underline='none' to="/recruit" component={RouterLink} >
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                            <PersonAdd fontSize="default" />
                                        </Avatar>
                                        <Typography style={{ color: theme.palette.silver.text }}>{t('profile.recruit')}</Typography>
                                    </div>
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                                </Box>
                            </Link>
                        }

                        <Link underline='none' to="/order" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        {/* <AssignmentRounded fontSize="default" /> */}
                                        <AssignmentRounded fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('title.order')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                        {/* <Link underline='none' to="/myPackage" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <ConfirmationNumber fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('title.myPackage')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link> */}
                        
                        {/* {_.size(_.intersection(role, ['merchant', 'super-merchant'])) == 0 ?
                            <Link underline='none' onClick={() => window.open(`${SELLER_URL}seller/register?username=${username}`)} >
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                            <HowToReg fontSize="default" />
                                        </Avatar>
                                        <Typography style={{ color: theme.palette.silver.text }}>{t('profile.becomeSeller')}</Typography>
                                    </div>
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                                </Box>
                            </Link> : null}
                        {_.size(_.intersection(role, ['merchant', 'super-merchant'])) > 0 ?
                            <Link underline='none' onClick={() => window.open(`${SELLER_URL}login`)} >
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                            <HowToReg fontSize="default" />
                                        </Avatar>
                                        <Typography style={{ color: theme.palette.silver.text }}>{t('profile.sellerSite')}</Typography>
                                    </div>
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                                </Box>
                            </Link>
                            : null} */}

                    </Box> : null}

                {/* //Profile */}
                {accessToken ?
                    <Box style={{ padding: '18px 15px 0', color: theme.palette.silver.text }}>
                        <div className='setting-title-bar' style={{ backgroundColor: theme.palette.primary.main, marginBottom: 16 }}>
                            <Typography>{t('profile.profile')}</Typography>
                        </div>
                        {/* <Link underline='none' to="/community/genealogySponsor" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <SupervisedUserCircleOutlined fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('profile.genealogy')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                        <Link underline='none' to="/placement-setting" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <AccountTreeOutlined fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('profile.placementSetting')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link> */}
                        <Link underline='none' to="/profile-bank-info" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <AccountBalanceOutlined fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('profile.editBankInfo')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                        <Link underline='none' to="/profile-password" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <FiKey fontSize="default" style={{ width: 21 }} />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('profile.changePassword')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                        <Link underline='none' to="/profile-security-password" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <FiKey fontSize="default" style={{ width: 21 }} />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('profile.changeSecurityPassword')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                        <Link underline='none' to="/profile-address" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        {/* <HomeWorkRounded fontSize="default" /> */}
                                        <MdLocationOn fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('profile.address')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                    </Box>
                    : null}


                {/* //Support */}
                <Box style={{ padding: '18px 15px 0', color: theme.palette.silver.text }}>
                    <div className='setting-title-bar' style={{ backgroundColor: theme.palette.primary.main, marginBottom: 16 }}>
                        <Typography>{t('support.support')}</Typography>
                    </div>
                    <Link underline='none' to="/policies" component={RouterLink} >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <BsFillFileTextFill fontSize="default" />
                                </Avatar>
                                <Typography style={{ color: theme.palette.silver.text }}>{t('support.policies')}</Typography>
                            </div>
                            <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                        </Box>
                    </Link>


                    <Link underline='none' to="/contactUs" component={RouterLink} >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <MdCall fontSize="default" />
                                </Avatar>
                                <Typography style={{ color: theme.palette.silver.text }}>{t('support.contactUs')}</Typography>
                            </div>
                            <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                        </Box>
                    </Link>

                </Box>

                {/* //Setting */}
                <Box style={{ padding: '18px 15px 0', color: theme.palette.silver.text }}>
                    <div className='setting-title-bar' style={{ backgroundColor: theme.palette.primary.main, marginBottom: 16 }}>
                        <Typography>{t('profile.settings')}</Typography>
                    </div>
                    <ThemeProvider theme={accordingStyles}>
                        <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ padding: 0, margin: 0 }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <PublicOutlined fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('home.language')}</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '8px 55px 16px', }}>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="language" name="language" value={valueLanguage}>
                                        {/* <FormControlLabel
                                            value="end"
                                            label="English"
                                            control={<Checkbox color="primary" />}
                                            labelPlacement="end"
                                            onClick={() => changeLanguage('en')}
                                        />
                                        <FormControlLabel
                                            value="end"
                                            label="中文"
                                            control={<Checkbox color="primary" />}
                                            labelPlacement="end"
                                            onClick={() => changeLanguage('cn')}
                                        /> */}
                                        <FormControlLabel
                                            key={`en`}
                                            onChange={() => changeLanguage('en')}
                                            value="en"
                                            control={<Radio checkedIcon={<CheckCircle />} />}
                                            label="English"
                                        />
                                        <FormControlLabel
                                            key={`cn`}
                                            onChange={() => changeLanguage('cn')}
                                            value="cn"
                                            control={<Radio checkedIcon={<CheckCircle />} />}
                                            label="中文"
                                        />
                                    </RadioGroup>

                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </ThemeProvider>
                    <ThemeProvider theme={accordingStyles}>
                        <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ padding: 0, margin: 0 }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <MonetizationOnIcon fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.silver.text }}>{t('home.currency')}</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '8px 55px 16px', }}>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="currency" name="currency" value={currency}>

                                        {/* <FormControlLabel
                                            value="end"
                                            label="English"
                                            control={<Checkbox color="primary" />}
                                            labelPlacement="end"
                                            onClick={() => changeLanguage('en')}
                                        />
                                        <FormControlLabel
                                            value="end"
                                            label="中文"
                                            control={<Checkbox color="primary" />}
                                            labelPlacement="end"
                                            onClick={() => changeLanguage('cn')}
                                        /> */}
                                        {_.map(currencydisplay, (currencydisplay_detail, index) => {
                                            let currency_code = currencydisplay_detail.desc.split('|');
                                            return (<FormControlLabel
                                                key={`curr-${index}`}
                                                onChange={() => changeCurrency(`${currencydisplay_detail['code']}`)}
                                                value={currencydisplay_detail['code']}
                                                control={<Radio checkedIcon={<CheckCircle />} />}
                                                label={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `}
                                            />)
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </ThemeProvider>


                    {
                        accessToken ?
                            <Link underline='none' component="button" onClick={() => {
                                logoutUser(dispatch);
                                // handleMenuClose();
                                history.replace('/login');
                            }}
                            >
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', }}>
                                        <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                            {/* <MeetingRoomOutlined fontSize="default" /> */}
                                            <FaDoorOpen fontSize="default" />

                                        </Avatar>
                                        <Typography style={{ color: theme.palette.silver.text }}>{t('button.logout')}</Typography>
                                    </Box>
                                    {/* <FiChevronRight style={{color:theme.palette.secondary.main, marginLeft:15}}/> */}
                                </Box>
                            </Link>
                            :
                            <Link underline='none' to="/login" component={RouterLink} >
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', }}>
                                        <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                            <MeetingRoomOutlined fontSize="default" />
                                        </Avatar>
                                        <Typography style={{ color: theme.palette.silver.text }}>{t('button.login')}</Typography>
                                    </Box>
                                    {/* <FiChevronRight style={{color:theme.palette.secondary.main, marginLeft:15}}/> */}
                                </Box>
                            </Link>
                    }
                    {/*{
                            <Link underline='none' to="/notification" component={RouterLink} >
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                            <NotificationsActive fontSize="default" />
                                        </Avatar>
                                        <Typography style={{color:theme.palette.silver.text}}>{t('profile.myNotification')}</Typography>
                                        {unreadNotification >99?
                                            <Avatar variant="circle" className={styles.unreadiconColor} style={{ marginLeft: 5 }} >
                                            <Typography style={{color:'#FFFFFF',fontWeight: 600,fontSize : 10 }}>{"99+"}</Typography>
                                            </Avatar>
                                        :
                                        (unreadNotification >0?
                                            <Avatar variant="circle" className={styles.unreadiconColor} style={{ marginLeft: 5 }} >
                                            <Typography style={{color:'#FFFFFF',fontWeight: 600,fontSize : 10 }}>{unreadNotification}</Typography>
                                            </Avatar>
                                            :
                                            null
                                        )
                                        }

                                    </div>
                                    
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                                </Box>
                            </Link>
                        }*/}
                    {/* <Box onClick={() => goToIshopping()} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <LocalMall fontSize="default" />
                                    </Avatar>
                                    <Typography style={{color:theme.palette.silver.text}}>{t('profile.goToIshopping')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Box> */}
                    {/* <Link underline='none' to="/profile-linking" component={RouterLink} >
                            <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                                <div style={{display:'flex', alignItems:'center',}}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <LinkRounded fontSize="default" />
                                    </Avatar>
                                    <Typography style={{color:theme.palette.silver.text}}>{t('profile.accountLink')}</Typography>
                                </div>
                                <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                            </Box>
                        </Link> */}
                    {/* {
                        fr_id !=null && fr_id?
                        null:
                        <Link underline='none' to="/bindSubAccount" component={RouterLink} >
                             <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                                <div style={{display:'flex', alignItems:'center',}}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <LinkOutlined fontSize="default" />
                                    </Avatar>
                                    <Typography style={{color:theme.palette.silver.text}}>{t('profile.bindSubAccount')}</Typography>
                                </div>
                                <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                            </Box>
                        </Link>
                    } */}
                    {/* <Link underline='none' to="/kyc" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AssignmentInd fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.kyc')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* //Annoucements */}
                    {/* <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('title.announcements')}</Typography>

                   
                    <Link underline='none' to="/announcement" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <NotificationsNoneRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.announcements')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                  
                </Box> */}
                    {/* <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.tof')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.refundAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.privacyAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.merchantServicesAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* <Link underline='none' to="/contactUs" component={RouterLink} >
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                <ContactMailOutlined fontSize="default" />
                            </Avatar>
                            <Typography style={{ color: theme.palette.silver.text }}>{t('footer.contactUs')}</Typography>
                        </div>
                        <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                    </Box>
                </Link> */}
                </Box>
            </div>
        </Box>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',

    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "transparent"
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    iconColor: {
        padding: 5,
        // backgroundColor: theme.palette.profile.user,
        // backgroundColor:'#d7d7d7',
        backgroundColor: '#c9c9c9',
        color: "#fff"
    },
    unreadiconColor: {
        padding: 5,
        width: 20,
        height: 20,
        // backgroundColor: theme.palette.profile.user,
        // backgroundColor:'#d7d7d7',
        backgroundColor: '#ff0000',
        color: "#fff"
    },


}));


const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before': {
                    backgroundColor: 'transparent'
                }

            }
        },
        MuiAccordionSummary: {
            content: {
                marginTop: 0,
                "&$expanded": {
                    marginTop: 0,
                    marginBottom: 5,
                }
            },
            root: {
                "&$expanded": {
                    minHeight: 'unset',
                }
            },
        },
        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
            }
        },
    }
});

