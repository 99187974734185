import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, useTheme, Typography, Box, Link, Button } from '@material-ui/core';
import { FiArrowUp } from "react-icons/fi";
import { RiFileList3Line } from "react-icons/ri";
import { BiTransfer } from "react-icons/bi";
import { TiInfoLarge } from "react-icons/ti";
import { currencyFormat } from '../helper/Tools';
import { getUrl } from '../helper/ApiAction';
import { storeWalletBalance } from '../actions';

import useNotificationLoading from '../helper/useNotificationLoading';
import ImgHeader from './Layouts/ImgHeader';
import Slider from "react-slick";
import NumberFormat from 'react-number-format';

import './css/styles.css';

export default function WalletNew() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { walletBalance, walletList, accessToken } = useSelector(state => ({
        walletBalance: state.user.walletBalance,
        walletList: state.wallet.walletList,
        accessToken: state.general.accessToken
    }));

    const [type, setType] = useState();
    const [transactionList, setTransactionList] = useState({});
    const [currentpage, setCurrentPages] = useState(1);
    const [commissionSummary, setCommissionSummary] = useState([]);

    // ----------------------------------- API ----------------------------------------------
    useEffect(() => {
        setType(walletBalance[currentpage] ? walletBalance[currentpage].code != 'MP' ? walletBalance[currentpage].code : 'bonues' : 'bonues');
    }, [currentpage])

    useEffect(() => {
        let params = { 'code': type }
        getUrl('transaction/list', params).then(response => {
            setTransactionList(response.data);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
        // eslint-disable-next-line
    }, [type])

    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            getUrl('wallets/balance').then(response => {
                if (response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            getUrl('commissions/summary').then(response => {
                if (response.status && isMountedRef.current) {
                    setCommissionSummary(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [dispatch, addAlert, accessToken])

    return (
        <Box style={{ background: '#fff' }}>
            <ImgHeader cardDisplay title={t('title.wallet')}/>

            <div className='p-lr-20' style={{ marginTop: -80 }}>
                <Slider
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay={false}
                    pauseOnHover={true}
                    initialSlide={1}
                    arrows={false}
                    dots={true}
                    dotsClass={"dot_style"}
                    responsive={[
                        { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 } },
                        { breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 } },
                        { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 } },
                    ]}
                    afterChange={(current) => setCurrentPages(current)}
                    className={ styles.slideListStyle }
                >
                    {
                        _.size(walletBalance) > 0
                            ? _.map(walletBalance, walletItem => {
                                if (walletItem.code === 'MP' && walletItem.id === 4 && walletItem.balance == 0) {
                                    // do nothing 
                                } else {
                                    const existWallet = _.find(walletList, { id: walletItem.id });
                                    const balance = currencyFormat((walletItem.balance * 100) / 100);
                                    return (
                                        <div className='p-lr-20' style={{ marginTop: -60 }} key={walletItem.id}>
                                            <div className='pos-relative flex-c-m'>
                                                <div style={{ width: '100%', height: 170, borderRadius: 15, background: 'linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%)', transform: 'rotate(0deg)' }} />
                                                <div className='pos-absolute card-transparent flex-col-sb flex-col card-text txt-upper' style={{ height: 170, padding: '20px 30px' }}>
                                                    <div className='flex-r w-full'>
                                                        {/* <div style={{ width: 26, height: 26, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, background: 'rgba(0, 0, 0, 0.10)', boxShadow: '2px 2px 5px 0px rgba(255, 255, 255, 0.30), 1px 1px 2px 0px rgba(0, 0, 0, 0.15) inset' }}>
                                                            <TiInfoLarge style={{ fontSize: 20 }} />
                                                        </div> */}
                                                        <img src={`/images/wallet/${walletItem.code}.png`} />
                                                    </div>
                                                    <div className='p-t-10'>
                                                        <p style={{ textTransform: 'capitalize' }}>{walletItem.wallet_name} {t('wallet.currentBalance')}</p>
                                                        <p className='fs-21'><b>{balance}</b></p>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div className={styles.walletButtonStyle}>
                                                            <Button disabled={!existWallet.allow_transfer} to={`/transfer/${walletItem.code}`} component={RouterLink} style={{ width: 30 }}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <BiTransfer style={{ fontSize: 25, color: existWallet.allow_transfer ? '#207455' : theme.palette.gray.main, transform: 'rotate(-45deg)' }} />
                                                                    <Typography className={styles.walletButtonTextStyle} style={{ color: existWallet.allow_transfer ? '#207455' : theme.palette.gray.main }}>{t('wallet.transfer')}</Typography>
                                                                </div>
                                                            </Button>
                                                            <Button disabled={!existWallet.allow_withdraw} to={`/withdrawal/${walletItem.code}`} component={RouterLink} style={{ width: 30 }}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <FiArrowUp style={{ fontSize: 25, color: existWallet.allow_withdraw ? '#207455' : theme.palette.gray.main, transform: 'rotate(45deg)' }} />
                                                                    <Typography className={styles.walletButtonTextStyle} style={{ color: existWallet.allow_withdraw ? '#207455' : theme.palette.gray.main }}>{t('wallet.withdrawal')}</Typography>
                                                                </div>
                                                            </Button>
                                                            {/* <Button disabled={!existWallet.allow_withdraw} to={`/withdrawal-history/${walletItem.code}`} component={RouterLink} style={{ width: 30 }}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <RiFileList3Line style={{ fontSize: 25, color: theme.palette.gray.main, transform: 'rotate(0deg)' }} />
                                                                    <Typography className={styles.walletButtonTextStyle} style={{ color: theme.palette.gray.main }}>{t('title.withdrawalHistory')}</Typography>
                                                                </div>
                                                            </Button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            : loading
                    }
                    {/* {
                        _.size(commissionSummary) &&
                        <div className='p-lr-20' style={{ marginTop: -60 }}>
                            <div className='pos-relative flex-c-m'>
                                <div style={{ width: '100%', height: 170, borderRadius: 15, background: 'linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%)', transform: 'rotate(0deg)' }} />
                                <div className='pos-absolute card-transparent flex-col-sb flex-col card-text txt-upper' style={{ height: 170, padding: '20px 30px' }}>
                                    
                                        {
                                            _.map(commissionSummary.bonus, bonusItem => {
                                                let bonusName = _.split(bonusItem.name, '|');
                                                bonusName = i18n.language === "cn" && bonusName[1] ? bonusName[1] : bonusName[0];
                                                let summary = _.find(commissionSummary.summary, { bonus_id: bonusItem.id });
                                                let amount = currencyFormat((0 * 100) / 100);;
                                                if (summary) amount = Number(summary.amount).toFixed(2);
                                                return (
                                                    <div className='p-t-10' key={bonusItem.id}>
                                                        <p style={{ textTransform: 'capitalize' }}>{bonusName}</p>
                                                        <p className='fs-21'><b>{amount}</b></p>
                                                    </div>
                                                )
                                            })
                                        }
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className={styles.walletButtonStyle}>
                                            <Button disabled={true}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <BiTransfer style={{ fontSize: 25, color: theme.palette.gray.main, transform: 'rotate(-45deg)' }} />
                                                    <Typography className={styles.walletButtonTextStyle} style={{ color: theme.palette.gray.main }}>{t('wallet.transfer')}</Typography>
                                                </div>
                                            </Button>
                                            <Button disabled={true}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <FiArrowUp style={{ fontSize: 25, color: theme.palette.gray.main, transform: 'rotate(45deg)' }} />
                                                    <Typography className={styles.walletButtonTextStyle} style={{ color: theme.palette.gray.main }}>{t('wallet.withdrawal')}</Typography>
                                                </div>
                                            </Button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                </Slider>
            </div>

            <div style={{ padding: '20px 30px 100px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontSize: 18, color: theme.palette.gray.text, paddingBottom: 10 }}>
                        <b>{t('title.transaction')}</b>
                    </Typography>
                    <Link underline='none' to={type == 'bonues' ? `/bonus` : `/transaction/${type}`} component={RouterLink} >
                        <Typography style={{ fontSize: 14, color: theme.palette.gray.text, paddingBottom: 10 }}>
                            {t('item.viewAll')}
                        </Typography>
                    </Link>
                </div>
                {
                    _.size(transactionList) > 0 
                    ? _.map(transactionList, (transactionList, index) => {
                        let operator = transactionList.transaction.factor > 0 ? "+" : "-";
                        return (
                            <div key={index}>
                                <div style={{ padding: '10px 0' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <div style={{ fontSize: 14 }}>
                                            <Typography>{_.hasIn(transactionList.transaction.type_display, i18n.language) ? transactionList.transaction.type_display[i18n.language] : '-'}</Typography>
                                            <Typography className='txt_truncate2' style={{ fontSize: 14, color: theme.palette.gray.ltext, height: 'unset', lineHeight: 1.2 }}>{transactionList.updated_at ? transactionList.updated_at : '-'}</Typography>
                                        </div>
                                        <Typography style={{ color: operator != "+" ? "#DD6666" : "green", wordWrap: 'break-word', width: '50%', textAlign: 'end' }}><b>{operator}{<NumberFormat value={parseFloat(transactionList.amount).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} />}</b></Typography>
                                    </div>
                                </div>
                                <div style={{ background: '#fff', boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.10) inset', height: 4 }} />
                            </div>
                        )
                    })
                    : <Typography style={{ fontSize: 14, textAlign: 'center', color: theme.palette.gray.text }}>{t('transaction.noTransaction')}</Typography>
                }
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    bonusCard:{
        position: 'absolute',
        top: 0,
        padding: 25,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            top: '-1vw'
        },
        [theme.breakpoints.down('sm')]: {
            top: '-1vw'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-1vw'
        },
    },
    walletButtonStyle: {
        width: 280, 
        height: 55,
        borderRadius: 7,
        border: '1px solid rgba(255, 255, 255, 0.34)',
        background: 'rgba(255, 255, 255, 0.50)',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.13)',
        backdropFilter: 'blur(6px)',
        position: 'absolute',
        top: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    walletButtonTextStyle: {
        fontSize: 10,
    },
    slideListStyle: {
        '& .slick-list': {
            height: 240,
            padding: '35px 0',
        },
    }
}));