import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pagination, PaginationItem } from '@material-ui/lab';

import { Box, Typography, List, ListItem, ListSubheader, Divider, ListItemIcon, ListItemText, TextField, Button,Grid,Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import WebpImg from './Layouts/WebpImg';

import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Question({product,category,lastIndex}) {
    const styles = useStyles();
    const { t, i18n} = useTranslation();
    const theme = useTheme();

    const { accessToken } = useSelector(state => state.general);


    return (
        <Box maxWidth='100%' paddingBottom={1} style={{ backgroundColor: '#fff' }}>
            <Box style={{ backgroundColor: '#fff'}}>
                <Box style={{ paddingBottom: 20 }}>
                    <Typography style={{ marginBottom: 10, fontSize: 15, fontWeight: '600', color: '#4E4E4E' }}>{t('product.specsPackaging')}</Typography>
                    <Grid container className={styles.paddingVertical2}>
                        <Typography variant="body2" style={{ color: theme.palette.gray.main, paddingRight: 10,fontSize:12,paddingBottom:10 }}>{t('item.category')}: </Typography>
                        <Box display="flex" flexDirection="row">
                            {
                                _.map(category, (categoryItem, categoryIndex) => {
                                    return (
                                        <Box key={categoryItem.id} display="flex" flexDirection="row">
                                            {/* <Link underline='none' color="primary" href={`/category/${categoryItem.id}`} > */}
                                                <Typography style={{ color: theme.palette.gray.main, fontSize: 12 }} variant="body2">
                                                    {categoryItem[`name_${i18n.language}`]}
                                                </Typography>
                                            {/* </Link> */}
                                            {
                                                categoryIndex === lastIndex ?
                                                    null
                                                    :
                                                    <Typography variant="body2" style={{ paddingLeft: 5, paddingRight: 5, fontSize: 12 }}>{' > '}</Typography>
                                            }
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                    <Grid container className={styles.paddingVertical2}>
                        <Typography variant="body2" style={{ color: theme.palette.gray.main, minWidth: '50px' ,fontSize:12}}>{t('item.brand')}: </Typography>
                        <Typography style={{ paddingLeft: '10px', color: theme.palette.gray.main, fontSize: 12 }} variant="body2"> {product.goods_brand ? product.goods_brand : t('item.noBrand')}</Typography>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    
}));