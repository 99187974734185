import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Tabs, Tab, Grid, Card, CardContent, CardMedia, Box, Typography, Button, Dialog, DialogContent, Slide, TextField, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import useNotificationLoading from '../helper/useNotificationLoading';
import NumberFormat from 'react-number-format';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import RedeemIcon from '@material-ui/icons/Redeem';
import SendIcon from '@material-ui/icons/Send';

import TabPanel from './Layouts/TabPanel';
import WebpImg from './Layouts/WebpImg';

import { useQuery } from '../helper/Tools';
import { getUrl, postUrl } from '../helper/ApiAction';

export default function Voucher(props) {
    const { t } = props;

    let history = useHistory();
    const query = useQuery();
    const tabType = query.get('type') ? query.get('type') : 'purchased';
    const isMountedRef = useRef(null);

    const { addAlert } = useNotificationLoading();

    const [tabIndex, setTabIndex] = useState(0);
    const [voucherList, setVoucherList] = useState({
        purchased: [],
        gift: [],
        redeemed: [],
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState([]);
    const [voucherType, setVoucherType] = useState(null); // "redeem", "gift"

    const tab = [
        { tabKey: "purchased", displayName: t(`voucher.purchased`) }, 
        { tabKey: "gift", displayName: t(`voucher.gift`) }, 
        { tabKey: "redeemed", displayName: t(`voucher.redeemed`) }
    ];

    useEffect(() => {
        console.log("rerender");
        isMountedRef.current = true;
        if(tabType) {
            const tabExist = _.findIndex(tab, {'tabKey': tabType });

            if(tabExist >= 0) {
                setTabIndex(tabExist);

                getUrl(`vouchers?type=${tabType}`).then(response => {
                    console.log("response", response);
                    if (isMountedRef.current) {
                        setVoucherList({ 
                            [tabType]: response.data
                        });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }

        return () => { isMountedRef.current = false };
    }, [tabType]);

    const changeTab = (index) => {
        setTabIndex(index);
        history.push(`/account/vouchers?type=${tab[index].tabKey}`);
    }

    const updateVoucherList = (updateType, voucherData) => {
        let voucherToFind = voucherList[updateType];
        const voucherIndexToChange = _.findIndex(voucherToFind, { "voucher_code": voucherData.voucher_code });
        voucherToFind[voucherIndexToChange] = voucherData;

        setVoucherList({ [updateType]: voucherToFind });
        console.log("done");
        console.log(voucherList)
    }

    const toggleVoucherModal = (voucherProps, voucherType) => {
        if(_.size(voucherType) > 0) {
            setVoucherType(voucherType);
        } else {
            setVoucherType(null);
        }

        if(_.size(voucherProps) > 0) {
            setSelectedVoucher(voucherProps);
        }

        setIsModalOpen(!isModalOpen);
    }

    return (
        <React.Fragment>
            <VoucherTab tab={tab} currentTab={tabIndex} changeTab={changeTab} />
            <VoucherContent type={tabType} t={t} tab={tab} currentTab={tabIndex} changeTab={changeTab} voucherList={voucherList} toggleModal={toggleVoucherModal}/>
            <VoucherModal type={tabType} voucherType={voucherType} t={t} open={isModalOpen} voucher={selectedVoucher} toggleModal={toggleVoucherModal} updateVoucherList={updateVoucherList} />
        </React.Fragment>
    )
}

const VoucherTab = (props) => {
    const { currentTab, changeTab, tab } = props;

    return (
        <AppBar position="static" color="inherit" elevation={0} style={{ backgroundColor: "transparent", paddingLeft: "24px", paddingRight: "24px" }}>
            <Tabs
                value={currentTab}
                onChange={ (e, index) => changeTab(index) }
                variant="fullWidth"
                scrollButtons="on"
                indicatorColor="secondary"
                textColor="secondary"
            >
                { _.map(tab, tabItem => {
                    return (
                        <Tab key={tabItem.tabKey} style={{ fontSize: '13px' }} label={tabItem.displayName} />
                    )
                }) }
            </Tabs>
        </AppBar>
    )
}

const VoucherContent = (props) => {
    const { t, tab, type, voucherList, currentTab, changeTab, toggleModal } = props;

    return (
        <SwipeableViews
            axis='x'
            index={currentTab}
            onChangeIndex={ index => changeTab(index) }
        >
            { _.map(tab, (tabItem, tabIndex) => {
                return (
                    <TabPanel key={tabItem.tabKey} value={currentTab} index={tabIndex} style={{ backgroundColor: 'transparent' }}>
                        <Grid container spacing={1} justify="center" style={{ minHeight: 450 }}>
                            <Grid item xs={12}>
                                {
                                    _.size(voucherList[tabItem.tabKey]) > 0 ?
                                    <TabItem type={type} t={t} voucherData={voucherList[tabItem.tabKey]} toggleModal={toggleModal} />
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </TabPanel>
                ) })
            }
        </SwipeableViews>
    )
}

const TabItem = (props) => {
    const { t, voucherData, toggleModal, type } = props;
    // const { data } = voucherData;
    console.log("tabitem voucherList", voucherData);
    return (
        <>
            { _.size(voucherData) > 0 ?
                <Box bgcolor="transparent">
                    { _.map(voucherData, (voucherItem, voucherIndex) => {
                        return <VoucherItem type={type} t={t} key={voucherIndex} data={voucherItem} toggleModal={toggleModal} />
                    }) }
                </Box>
            :
            <Card style={{ borderRadius: 0 }} elevation={0}>
                <CardContent style={{ height: 400, borderRadius: 0 }}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <WebpImg src="/images/no_data/noorder.png" style={{ width: 180 }} alt="" />
                        <Typography variant="h5" style={{ textAlign: "center", paddingLeft: 44 }}>{t('voucher.noVoucher')}</Typography>
                    </Box>
                </CardContent>
            </Card> }
        </>
    )
}

const VoucherItem = (props) => {
    const { t, data, toggleModal, displayOnly = false, type="purchased" } = props;
    const { voucher_header } = data;
    const styles = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();

    console.log("voucher item rerender");

    const openVoucher = type => {
        toggleModal(data, type);
    }
    
    const showOriPrice = parseFloat(voucher_header.product.retail_price) > 0 && (parseFloat(voucher_header.product.retail_price) > parseFloat(voucher_header.product.sell_price)) ? true : false;

    const renderActionSection = () => {
        if(type === 'purchased') {
            if(!displayOnly) {
                return (
                    <Button variant="contained" size='small' color="primary" onClick={() => openVoucher("redeem")} style={{ color: theme.palette.white.main }}>
                        { t('voucher.claim') }!
                    </Button>
                )
            }
        } else if(type === 'gift') {
            if(!displayOnly) {
                return (
                    <Button variant="contained" size='small' color="primary" onClick={() => openVoucher("redeem")} style={{ color: theme.palette.white.main }}>
                        { t('voucher.viewDetails') }!
                    </Button>
                )
            }
        } else if(type === 'redeemed') {
            const isRedeemed = data.status === 2 ? true : false;
            return (
                <div className={ styles.redeemedButtonStyle }>
                    <Button variant="outlined" size='small' color="primary" disabled={true}>
                        { isRedeemed ? t('voucher.redeemed') : t('voucher.expired') }!
                    </Button>
                </div>
            )
        }

        return null;
    }

    return (
        <Card className={styles.root} variant="outlined" raised={displayOnly ? true : false}>
            <div className={displayOnly ? styles.cardMainDisplay : styles.cardMain}>
                <Grid container>
                    <Grid item xs={4} md={4}>
                        <CardMedia
                            style={{ height: '100%' }}
                            image={ _.size(voucher_header.product.images_array) > 0 ? voucher_header.product.images_array[0] : '/images/no-image.png' }
                            title={ voucher_header.product.title }
                        />
                    </Grid>
                    <Grid item xs={4} md={5}>
                        <Box display="flex" flexDirection="column" paddingX={2} justifyContent="space-around">
                            <Typography component='span' variant='h6'>
                                { voucher_header.product.title }
                            </Typography>
                            {
                                !displayOnly && <Typography variant="caption" color="textSecondary">
                                    { i18n.language === 'cn' ? voucher_header.product.descr_cn : voucher_header.product.descr_en }
                                </Typography>
                            }
                            <Typography variant="caption" color="textSecondary">
                                { `${t('voucher.validTill')} ${voucher_header.redeem_edate}` }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <CardContent className={styles.priceSmall}>
                            <Typography component='span' variant='caption' style={{ color: displayOnly ? theme.palette.primary.main : theme.palette.green.main }}>
                                <NumberFormat 
                                    value={parseFloat(voucher_header.product.sell_price).toFixed(2)} 
                                    decimalScale={2} 
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'RM'} 
                                />
                            </Typography>
                            { showOriPrice ?
                                <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through' }}>
                                    <NumberFormat 
                                        value={parseFloat(voucher_header.product.retail_price).toFixed(2)} 
                                        decimalScale={2} 
                                        displayType={'text'} 
                                        thousandSeparator={true} 
                                        prefix={'RM'} 
                                    />
                                </Typography> : null }
                            { renderActionSection() }
                        </CardContent>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            type === 'purchased' && !displayOnly ?
                            <div className={ styles.bottomCard }>
                                {
                                    _.size(data.receiver_email) > 0 ?
                                    <Button 
                                        variant="text" 
                                        style={{ color: theme.palette.primary.main }}
                                        startIcon={<SendIcon />}
                                        disabled
                                    >
                                        <Typography variant="body2" style={{ color: theme.palette.primary.main }}>{ `${t('voucher.sendTo')} ${data.receiver_email}` }</Typography>
                                    </Button>
                                    :
                                    <Button 
                                        variant="text" 
                                        style={{ color: theme.palette.primary.main }}
                                        endIcon={<RedeemIcon />}
                                        onClick={() => openVoucher("gift")}
                                    >
                                        { t('voucher.sendAsGift') }!
                                    </Button>
                                }
                            </div>
                            : null
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            type === 'gift' && !displayOnly ?
                            <div className={ styles.bottomCard }>
                                {
                                    _.size(data.email) > 0 ?
                                    <Button 
                                        variant="text" 
                                        style={{ color: theme.palette.primary.main }}
                                        startIcon={<SendIcon />}
                                        disabled
                                    >
                                        <Typography variant="caption" style={{ color: theme.palette.primary.main }}>{ `${t('voucher.receivedFrom', { 'username': data.email })}` }</Typography>
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            : null
                        }
                    </Grid>
                </Grid>
            </div>
            {
                !displayOnly ?
                <img src="/images/general/voucher_side.png" alt="" />
                : null
            }
        </Card>
    )
}

const VoucherModal = (props) => {
    const { open, voucherType, toggleModal } = props;

    const handleClose = () => {
        toggleModal();
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                { voucherType === 'gift' && <GiftVoucherModalContent {...props} /> }
                { voucherType === 'redeem' && <RedeemVoucherModalContent {...props} /> }
            </DialogContent>
        </Dialog>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RedeemVoucherModalContent = (props) => {
    const { t, voucher, toggleModal, type } = props;
    const theme = useTheme();
    const styles = useStyles();

    const redeemNow = () => {
        // const newWindow = window.open(voucher.redeem_url, '_blank', 'noopener,noreferrer')
        const newWindow = window.open(`/voucher_redemption?voucher_code=${voucher.voucher_code}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    console.log("RedeemVoucherModalContent voucher", voucher);
    return (
        <>
            <Typography variant="h5" className={styles.content}>{ t('voucher.redeemVoucher') }</Typography>
            <Box marginTop={5}>
                { _.size(voucher) > 0 && <VoucherItem type={type} t={t} data={voucher} toggleModal={toggleModal} displayOnly={true} /> }
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} className={styles.content}>
                    <div className={styles.qrDiv}>
                        <QRCode 
                            value={voucher.redeem_url}
                            renderAs="svg"
                            fgColor={theme.palette.primary.main}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={styles.content}>
                    <div className={styles.voucherModalAction}>
                        <div className={ styles.modalCover }>
                            <div className={ styles.coverLeft }>
                                <Typography>{ t('voucher.voucherCode') }:</Typography>
                            </div>
                            <div className={ styles.coverRight }>
                                <Typography>{ voucher.voucher_code }</Typography>
                            </div>
                        </div>
                        {/* <div className={ styles.modalCover }>
                            <div className={ styles.coverLeft }>
                                <Typography>{ t('voucher.validationCode') }:</Typography>
                            </div>
                            <div className={ styles.coverRight }>
                                <Typography>{ voucher.validation_code ? voucher.validation_code : '-' }</Typography>
                            </div>
                        </div> */}
                        {
                            type === 'purchased' &&
                            <Button variant="text" onClick={redeemNow} color="primary" style={{ color: theme.palette.primary.main }}>
                                { t('voucher.redeemNow') } !
                            </Button>
                        }
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const GiftVoucherModalContent = (props) => {
    const { t, voucher, toggleModal, type, updateVoucherList } = props;
    const theme = useTheme();
    const styles = useStyles();
    const { addAlert } = useNotificationLoading();

    console.log("GiftVoucherModalContent voucher", voucher);

    const [receiver, setReceiver] = useState("");
    const [message, setMessage] = useState("");
    const [sendGiftStatus, setSendGiftStatus] = useState(null); // pending, success, fail

    useEffect(() => {
        setSendGiftStatus(null);
    }, [])

    const sendGift = () => {
        // send 
        setSendGiftStatus("pending");
        const giftVoucherData = {
            'email': receiver,
            'message': message
        };

        postUrl(`vouchers/${voucher.id}`, giftVoucherData).then(response => {
            console.log("gift voucher response", response)
            if (response.status === 1) {
                setSendGiftStatus("success");
                let newVoucher = voucher;
                newVoucher['receiver_email'] = receiver;
                newVoucher['message'] = message;
                updateVoucherList("purchased", newVoucher);
            } else {
                setSendGiftStatus(null);
                let newErrorField = {};
                let displayErrorMsg = [];
                _.map(response.error, (errorItem, errorIndex) => {
                    newErrorField[errorIndex] = errorItem[0]
                    displayErrorMsg.push(errorItem[0]);
                })
                addAlert(response.message + ' ' + _.map(displayErrorMsg).join(' '));
            }
        }).catch(error => {
            setSendGiftStatus("fail");
            addAlert(JSON.stringify(error.message));
        });
    }

    const renderStatus = () => {
        if(sendGiftStatus === 'pending') {
            return (
                <div className={styles.modelStatusStyle}>
                    <CircularProgress fontSize="large" />
                </div>
            );
        } else if(sendGiftStatus === 'fail') {
            return (
                <div className={styles.modelStatusStyle}>
                    <span style={{ fontSize: 52 }}><RedeemIcon fontSize="inherit" color="error" /></span>
                    <Typography variant="h4" color="error">{ t('voucher.sendFailed') }!</Typography>
                </div>
            );
        } else if(sendGiftStatus === 'success') {
            return (
                <div className={styles.modelStatusStyle}>
                    <span style={{ fontSize: 52 }}><RedeemIcon fontSize="inherit" style={{ color: theme.palette.green.main }}/></span>
                    <Typography variant="h4" style={{ color: theme.palette.green.main }}>{ t('voucher.sendSuccessful') }!</Typography>
                    <Typography variant="body1">{ receiver }</Typography>
                </div>
            );
        }

        return null;
    }

    const renderContent = () => {
        if(sendGiftStatus === 'pending') {
            return null;
        } else if(sendGiftStatus === 'fail') {
            return (
                <Grid container>
                    <Grid item xs={12} sm={12} className={styles.content}>
                        <Button variant="contained" onClick={toggleModal} color="primary" style={{ color: theme.palette.white.main }}>
                            { t('voucher.confirm') }!
                        </Button>
                    </Grid>
                </Grid>
            )
        } else if(sendGiftStatus === 'success') {
            return (
                <Grid container>
                    <Grid item xs={12} sm={12} className={styles.content}>
                        <Button variant="contained" onClick={toggleModal} color="primary" style={{ color: theme.palette.white.main }}>
                            { t('voucher.done') }!
                        </Button>
                    </Grid>
                </Grid>
            )
        }

        return (
            <Grid container>
                <Grid item xs={12} sm={12} className={styles.content}>
                    <TextField
                        label={t('voucher.to')}
                        style={{ margin: 8 }}
                        placeholder="example@email.com"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={receiver}
                        type="email"
                        onChange={({ target }) => setReceiver(target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} className={styles.content}>
                    <TextField
                        id="standard-full-width"
                        label={t('voucher.message')}
                        style={{ margin: 8 }}
                        helperText={t('voucher.maxCharacter')}
                        fullWidth
                        margin="normal"
                        multiline
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        rows={6}
                        rowsMax={6}
                        value={message}
                        onChange={({ target }) => setMessage(target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} className={styles.content} style={{ marginBottom: 10 }}>
                    <Button variant="contained" onClick={sendGift} color="primary" style={{ color: theme.palette.white.main }}>
                        { t('voucher.sendNow') }!
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Typography variant="h5" className={styles.content}>{ t('voucher.sendVoucherGift') }</Typography>
            { renderStatus() }
            {
                _.size(voucher) > 0 && <VoucherItem type={type} t={t} data={voucher} toggleModal={toggleModal} displayOnly={true} />
            }
            { renderContent() }
        </>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        margin: '0px 0px 20px 0px',
        border: '0px',
        background: 'transparent',
    },
    cardMain: {
        display: 'flex',
        padding: '20px 20px 0px 20px',
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
    cardMainDisplay: {
        display: 'flex',
        padding: 0,
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
    priceSmall: {
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '1px dashed',
        borderColor: theme.palette.gray.border,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    content: {
        textAlign: 'center'
    },
    modalCover: {
        display: 'flex',
    },
    coverLeft: {
        flex: 1,
        textAlign: 'right',
    },
    coverRight: {
        flex: 2,
        textAlign: 'center',
    },
    bottomCard: {
        marginTop: 5,
        marginBottom: 5,
    },
    qrDiv: {
        marginTop: 10,
        marginBottom: 10,
    },
    voucherModalAction: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%'
    },
    redeemedButtonStyle: {
        marginTop: 10,
        marginBottom: 10,
        transform: `rotate(-5deg)`,
    },
    modelStatusStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: '200px',
        flexDirection: 'column',
    }
}));