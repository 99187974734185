import React, { useState, useEffect , useRef , useLayoutEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { postUrl, getUrl, logoutUser } from '../helper/ApiAction';
import { CardHeader, CardContent, TextField, InputAdornment, IconButton, Button, Typography, Container, Grid, Link, Box, Divider, Card } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { BACKEND_URL, CLIENT_SECRET, EXTERNAL_LOGIN, FACEBOOK_APPID, LOGIN_SECRET_KEY, ISHOPPING_LOGIN } from '../configs/Config';
import { authSuccess, authFail, storeCodeState ,changeLanguage as reduxChangeLanguage} from '../actions';
import { generateRandomString, useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import { useLastLocation } from 'react-router-last-location';
import { Base64 } from 'js-base64';
import sha256 from 'crypto-js/sha256';

import FacebookIcon from '@material-ui/icons/Facebook';
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import WebpImg from './Layouts/WebpImg';

export default function SplashScreen() {
    const dispatch = useDispatch();
    const { t , i18n} = useTranslation();
    const { accessToken, shareLinkProductId } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { name } = useSelector(state => state.user);

    let history = useHistory();

    const lastLocation = useLastLocation();

    const styles = useStyles();
    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });
    const [userLogin, setUserLogin] = useState(false);
    const [continueDialogOpen, setContinueDialogOpen] = useState(false);

    const query = useQuery();
    const source = query.get('src');

    useEffect(() => {
        if(accessToken) {
            if(source){
                if(userLogin){
                    // user login, redirect to third party page
                    setLoading(true);
                    getUrl(`/login-to/${source}`).then(response => {
                        setLoading(false);
                        if(response.status === 1 && response.url){
                            window.location.href = response.url; 
                        }else{
                            addAlert(t('general.loginRedirectError'));
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }else{
                    // user already login, ask continue as XXX
                    setContinueDialogOpen(true);
                }
            }else{
                let previousUrl = "";
                if(document.referrer) previousUrl = new URL(document.referrer);
                if(shareLinkProductId){
                    history.replace({ pathname: `item/${shareLinkProductId}` });
                }else if(!previousUrl || (previousUrl && window.location.host != previousUrl['host'])){
                    history.replace({ pathname: "/" });
                }else{
                    history.goBack();
                }
            }
        }
    }, [accessToken, history, shareLinkProductId, source]);

    const loginUser = () => {
        setLoading(true);
        setUserLogin(true);

        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        }
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, loginState)
        .then((response) => {
            // setLoading(false);
            setLoading(false);
            if(response.status === 200) {
                // dispatch(authSuccess(response.data));
                // history.goBack();
                const decoded_jwt = jwt_decode(response.data.access_token);
                const userRole = decoded_jwt ? decoded_jwt.role : [];
                if(_.size(_.intersection(userRole, ['member']))){
                    dispatch(authSuccess(response.data));
                }else{
                    addAlert(t('general.incorrectUsernamePassword'));
                }
            }
        }).catch((error) => {
            console.log("error", error);
            setLoading(false);
            if (error.response && error.response.status === 400) {
                addAlert(t('general.incorrectUsernamePassword'));
                // console.log('The username or password is incorrect');
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                addAlert(errorResponse.message);
            }else{
                addAlert(JSON.stringify(error.message));
                // console.log('Please try again later or contact Technical Support if problem persist.');
            }            
            dispatch(authFail());
            // setLoading(false);            
        }); 
    }

    const dontContinueAs = () => {
        setContinueDialogOpen(false);
        logoutUser();
    }
    
    const continueAs = () => {
        setContinueDialogOpen(false);
        setLoading(true);
        getUrl(`/login-to/${source}`).then(response => {
            setLoading(false);
            if(response.status === 1 && response.url){
                window.location.href = response.url; 
            }else{
                addAlert(t('general.loginRedirectError'));
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    return (
        <Box className={styles.backgroundScreen}>
            
            <div className={styles.topImage}>
                <WebpImg src="/images/logo-whitered-07.png" style={{ width: '160px' }} alt="logo" />
                
                <div style={{ padding: '0px 50px', width: '100%' }}>
                    <Typography style={{ fontWeight: '700', fontSize: '35px', color: '#FFF', fontStyle: 'normal', lineHeight: 'normal', width: '100%' , textAlign: 'center'}}>Your Wellness</Typography>
                    <Typography style={{ fontWeight: '700', fontSize: '35px', color: '#FFF', fontStyle: 'normal', lineHeight: 'normal', width: '100%' , textAlign: 'center'}}>Our Mission</Typography>
                    {/* <Typography style={{ fontWeight: '400', fontSize: '15px', color: '#FFF', fontStyle: 'normal', lineHeight: 'normal', width: '100%' }}>Our Mission</Typography> */}
                    <Button fullWidth className={styles.loginButton} to="/login" component={RouterLink}>
                        {t('button.login')}
                    </Button>
                    <Button fullWidth className={styles.registerButton} to="/register" component={RouterLink}>
                        {t('button.register')}
                    </Button>
                    <div className={styles.languageContainer}>
                        <Typography onClick={() => changeLanguage('en')} className={styles.languageLink} style={{ marginRight: '10px' }}>
                            ENG
                        </Typography>
                        <span className={styles.languageSeparator}>|</span>
                        <Typography onClick={() => changeLanguage('cn')} className={styles.languageLink} style={{ marginLeft: '10px' }}>
                            中文
                        </Typography>
                    </div>
                </div>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    topImage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: 'inherit',
    },
    backgroundScreen:{
        backgroundImage: `linear-gradient(180deg, #000 -6%, rgba(0, 0, 0, 0.00) 72.16%), url('/images/background/background_image.png')`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat-y',
        width: 'auto',
    },
    loginButton: {
        height: 56, 
        flexShrink: '0', 
        borderRadius: 48, 
        background: '#FFF', 
        boxShadow: '4px 4px 6px 0 rgba(0, 0, 0, 0.42)',
        marginBottom: 13, 
        marginTop: 26,
        color: '#1D472E', 
        '&:hover': {
            background: '#D9D9D9',
        }
    },
    registerButton: {
        height: 56, 
        flexShrink: '0',
        borderRadius: 48, 
        borderTop: '2px solid rgba(255, 255, 255, 0.23)',
        borderLeft: '2px solid rgba(255, 255, 255, 0.23)',
        background: 'rgba(255, 255, 255, 0.1)', 
        boxShadow: '4px 4px 8px 0 rgba(0, 0, 0, 0.42)',
        backdropFilter: 'blur(3px)', 
        marginBottom: 58, 
        marginTop: 13,
        color: '#FFF', 
        '&:hover': { 
            color: '#FFF', 
        }
    },
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    containerStyle: {
        padding: 20,
    },
    languageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    languageLink: {
        // marginRight: 10,
        cursor: 'pointer',
        color: '#FFF',
        fontSize: 16,
        fontWeight: 600,
        transition: 'color 0.3s ease-in-out',
        '&:hover': {
            color: theme.palette.primary.main, // or any other color you prefer
        },
    },
    languageSeparator: {
        color: '#FFF',
        fontSize: 16,
        fontWeight: 600,
    },
}));