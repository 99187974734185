import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Grid, Box, Typography, TextField, Button, FormControl, ThemeProvider, Drawer, IconButton } from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme } from '@material-ui/core/styles';
import { CheckCircle, CloseRounded } from '@material-ui/icons';
import { getUrl, postUrl } from '../helper/ApiAction';
import { currencyFormat } from '../helper/Tools';
import { storeWalletBalance } from '../actions';

import useNotificationLoading from '../helper/useNotificationLoading';
import ImgHeader from './Layouts/ImgHeader';
import theme from '../Theme';

import './css/styles.css';

const DEFAULT_ERROR_FIELD = { amount: "", security_password: "" };

export default function WithdrawalNew() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));

    const [withdrawRules, setWithdrawRules] = useState({
        "min": null,
        "max": null,
        "charges": null,
        "charges_type": null,
        "charges_value": null,
    });
    const [state, setState] = useState({ 
        amount: "", 
        security_password: "", 
        dateTime: "", 
        id: "" 
    });
    const [walletInfo, setWalletInfo] = useState([]);
    const [walletName, setWalletName] = useState("");
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [withdrawalStatus, setWithdrawalStatus] = useState("insert"); // insert -> review -> result
    const [open, setOpen] = useState(false);

    // ----------------------------------- API ----------------------------------------------
    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        let walletNameArray = _.split(existWallet.name, '|');
        setWalletName(_.size(walletNameArray) > 1 && i18n.language ==="cn" ? walletNameArray[1] : walletNameArray[0]);
        setUserWalletInfo(existWalletBalance);
        if(!existWallet) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo)) {
            getUrl('withdrawal_rules').then(response => {
                if(response.data && isMountedRef.current) {
                    if(response.status) {
                        if(parseInt(response?.data['wallet_id']) !== walletInfo.id) {
                            history.push('/wallet');
                        } else {
                            setWithdrawRules(response.data)
                            // setMaxWithdraw(response.data?.max);
                            // setMinWithdraw(response.data?.min);
                        }
                    } else {
                        history.push('/wallet');
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [addAlert, walletInfo]);

    // ------------------------------------ FUNCTIONS ----------------------------------------
    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const getChargesValue = () => {
        const { charges_type, charges_value } = withdrawRules;

        if(charges_type === 'percent') {
            return (parseFloat(state.amount) * charges_value) / 100;
        } else {
            return parseFloat(charges_value);
        }
    }

    const reviewWithdrawal = () => {
        let error = false;
        let newErrorField = errorField;
        _.map(["amount", "security_password"], field => {
            if(_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('withdrawal.isRequired', { field: t(`withdrawal.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        });

        if(withdrawRules.min > 0) {
            if(parseFloat(state.amount) < parseFloat(withdrawRules.min)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountLesserThan', { amount: withdrawRules.min });
            }
        }
        if(withdrawRules.max > 0) {
            if(parseFloat(state.amount) > parseFloat(withdrawRules.max)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountGreaterThan', { amount: withdrawRules.max });
            }
        }

        setErrorField(errorField => ({ ...errorField, newErrorField }));
        if(!error) {
            setWithdrawalStatus('review')
            setOpen(true);
        } else {
            setWithdrawalStatus('insert')
        }
    }

    const refetchWallet = () => {
        getUrl('wallets/balance').then(response => {
            if(response.status) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const withdrawWallet = () => {
        const { amount, security_password } = state;
        const withdrawalData = {
            amount,
            security_password,
        };

        postUrl(`withdrawal`, withdrawalData).then(response => {
            console.log("response", response)
            if(response.status) {
                setWithdrawalStatus('result');
                setOpen(false);
                setErrorField(DEFAULT_ERROR_FIELD);
                refetchWallet();
            } else {
                if(response.errors) {
                    let newErrorField = errorField;
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if(response.data) {
                    addAlert(response.data);
                }
                setWithdrawalStatus('insert');
                setOpen(false);
            }
        }).catch(error => {
            setWithdrawalStatus('insert');
            setOpen(false);
            addAlert(JSON.stringify(error.message));
        })
    }

    const openDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setWithdrawalStatus('insert');
        setOpen(false);
    }

    return (
        <Box style={{ background: '#fff' }}>
            <ImgHeader cardDisplay title={walletName + " " + t('title.walletWithdrawal')}/>

            <div className='p-lr-40' style={{ marginTop: -60 }}>
                <div className='pos-relative flex-c-m'>
                    <div style={{ width: '100%', height: 170, borderRadius: 15, background: 'linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%)', transform: 'rotate(355deg)' }} />
                    <div className='pos-absolute card-transparent flex-col-sb flex-col card-text txt-upper' style={{ height: 170, padding: '20px 30px' }}>
                        <div className='flex-sb w-full'>
                            <div>
                                <Typography>{(_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-') + " " + t('title.wallet')}</Typography>
                            </div>
                            <div>
                                <img src={`/images/wallet/${wallet}.png`} />
                            </div>
                        </div>
                        <div className='p-t-10'>
                            <p className='fs-21'><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
                            <p style={{ textTransform: 'capitalize' }}>{t('withdrawal.withdrawableBalance')}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='p-lr-25 p-t-40 p-b-60'>
                {
                    withdrawalStatus === 'result'
                        ? <div className='p-b-20'>
                            <div className={classes.boxStyle}>
                                <div style={{ textAlign: 'center' }}>
                                    <CheckCircle style={{ fontSize: 50, color: theme.palette.green.main }} />
                                </div>
                                <Typography variant="h6" style={{ color: theme.palette.green.main, textAlign: 'center' }}>{t('withdrawal.successfullyWithdraw')}</Typography>
                                <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.green.main }}>
                                    {`${currencyFormat((state.amount * 100) / 100)} ${_.size(walletInfo) ? walletInfo.wallet_name : ''}`}
                                </Typography>
                                <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                                    <Box flex={1}>
                                        <Typography variant="h6" style={{ fontSize: 12 }}>{t('withdrawal.feeCharges')}</Typography>
                                    </Box>
                                    <Box flex={2}>
                                        <Typography variant="h6" style={{ fontSize: 12 }}>{currencyFormat(getChargesValue())}</Typography>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                        : <div className='p-b-20'>
                            {
                                withdrawRules.bypass == 'false' &&
                                <div>
                                    <Typography style={{ fontSize: 14 }}>{t('withdrawal.minWithdraw', { amount: currencyFormat((withdrawRules.min * 100) / 100 || 0) })} {walletName}</Typography>
                                    <Typography style={{ fontSize: 14 }}>{t('withdrawal.maxWithdraw', { amount: currencyFormat((withdrawRules.max * 100) / 100 || 0) })} {walletName}</Typography>
                                    <Typography style={{ fontSize: 14 }}>{`${t('withdrawal.chargesFee', { amount: currencyFormat(withdrawRules.charges_value || 0) })}${withdrawRules.charges_type === 'percent' ? "%" : ` ${walletName}`}`}</Typography>
                                </div>
                            }
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={state.amount}
                                    name="amount"
                                    onChange={handleChange}
                                    classes={{ root: classes.textFieldRoot }}
                                    InputProps={{ readOnly: withdrawalStatus === 'review' ? true : false }}
                                    label={t('withdrawal.amount')}
                                    fullWidth
                                    error={_.size(errorField.amount) ? true : false}
                                    helperText={errorField.amount}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />
                                {
                                    withdrawalStatus === 'review' ?
                                        <TextField
                                            type="text"
                                            disabled
                                            variant="outlined"
                                            value={getChargesValue()}
                                            onChange={handleChange}
                                            classes={{ root: classes.textFieldRoot }}
                                            label={t('withdrawal.feeCharges')}
                                            fullWidth
                                        />
                                        : null
                                }
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <TextField
                                    type="password"
                                    variant="outlined"
                                    value={state.security_password}
                                    name="security_password"
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.textFieldRoot,
                                    }}
                                    InputProps={{
                                        readOnly: withdrawalStatus === 'review' ? true : false,
                                    }}
                                    label={t('withdrawal.security_password')}
                                    error={_.size(errorField.security_password) ? true : false}
                                    helperText={errorField.security_password}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    fullWidth
                                />
                            </FormControl>
                        </div>
                }

                <Box className={classes.boxContainer}>
                    {
                        withdrawalStatus === 'insert' &&
                        <Button variant="contained" fullWidth onClick={reviewWithdrawal} style={{ width: '100%', padding: '8px 9px', borderRadius: 48, background: theme.palette.primary.main, color: '#fff', textTransform:'capitalize' }} >
                            {t('withdrawal.withdrawNow')}
                        </Button>
                    }
                    {
                        // withdrawalStatus === 'review' &&
                        // <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} >
                        //     <Button variant="contained" onClick={() => setWithdrawalStatus('insert')} style={{ marginRight: 10, width: '100%', padding: 8, borderRadius: 48, background: theme.palette.primary.main, color: '#fff', textTransform:'capitalize' }}>
                        //         {t('withdrawal.changeDetail')}
                        //     </Button>
                        //     <Button variant="contained" onClick={withdrawWallet} style={{ marginLeft: 10, width: '100%', padding: 8, borderRadius: 48, background: theme.palette.green.main, color: '#fff', textTransform:'capitalize' }}>
                        //         {t('withdrawal.confirmWithdraw')}
                        //     </Button>
                        // </Box>
                    }
                    {
                        withdrawalStatus === 'result' &&
                        <Button variant="contained" fullWidth onClick={() => history.push('/wallet')} style={{ width: '100%', padding: '8px 9px', borderRadius: 48, background: theme.palette.primary.main, color: '#fff', textTransform:'capitalize' }}>
                            {t('withdrawal.complete')}
                        </Button>
                    }
                </Box>

                <ThemeProvider theme={accordingStyles}>
                    <Drawer anchor={'bottom'} open={open} onClose={closeDrawer} className='mobile-width-fixed' style={{ margin: '0px auto', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography style={{ fontSize: 20, color: theme.palette.primary.main }}><b>{t('withdrawal.amount')}</b></Typography>
                            <IconButton onClick={closeDrawer}>
                                <CloseRounded style={{ color: '#BABABA' }} />
                            </IconButton>
                        </div>
                        <div style={{ paddingTop: 20, paddingBottom: 20, height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div>
                                {
                                    withdrawRules.charges == 'add'?
                                        <>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ padding: '5px 0' }}>
                                                <Typography style={{ fontSize: 14 }}>{t('withdrawal.amount')}</Typography>
                                                <Typography style={{ fontSize: 14, color: theme.palette.gray.text }}>{`${currencyFormat((state.amount * 100) / 100 || 0)}`} {wallet}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ padding: '5px 0' }}>
                                                <Typography style={{ fontSize: 14 }}>{t('withdrawal.feeCharges')} {withdrawRules.charges_type === 'percent' ? `(${withdrawRules.charges_value}%)` : null}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{`${currencyFormat((getChargesValue() * 100) / 100 || 0)}`} {wallet}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ padding: '5px 0' }}>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('withdrawal.totalAmount')}</Typography>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{`${currencyFormat(((parseFloat(state.amount) + getChargesValue()) * 100) / 100 || 0)}`} {wallet}</Typography>
                                            </Box>
                                        </>
                                    :
                                        <>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ padding: '5px 0' }}>
                                                <Typography style={{ fontSize: 14 }}>{t('withdrawal.amount')}</Typography>
                                                <Typography style={{ fontSize: 14, color: theme.palette.gray.text }}>{`${currencyFormat(((parseFloat(state.amount) - getChargesValue()) * 100) / 100 || 0)}`} {wallet}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ padding: '5px 0' }}>
                                                <Typography style={{ fontSize: 14 }}>{t('withdrawal.feeCharges')} {withdrawRules.charges_type === 'percent' ? `(${withdrawRules.charges_value}%)` : null}</Typography>
                                                <Typography style={{ fontSize: 14 }}>{`${currencyFormat((getChargesValue() * 100) / 100 || 0)}`} {wallet}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ padding: '5px 0' }}>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('withdrawal.totalAmount')}</Typography>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{`${currencyFormat(((parseFloat(state.amount) ) * 100) / 100 || 0)}`} {wallet}</Typography>
                                            </Box>
                                        </>
                                }
                                
                            </div>
                            {
                                withdrawalStatus === 'review' &&
                                <Button variant="contained" fullWidth onClick={withdrawWallet} style={{ padding: 8, borderRadius: 10, background: theme.palette.primary.main, color: '#fff', textTransform: 'capitalize' }}>
                                    {t('withdrawal.confirmWithdraw')}
                                </Button>
                            }
                        </div>
                    </Drawer>
                </ThemeProvider>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -58,
        padding: 10,
        borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    formControl: {
        padding: 5
    },
    textFieldRoot: {
        marginTop: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15, 
        marginBottom: 30
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    balanceStyle: {
        fontSize: 30, 
        color: "#fff",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: '#fff', 
        fontSize: 11, 
        letterSpacing: 1 
    },
    boxStyle: {
        padding: 20,
        borderRadius: 30,
        background: '#EFEFEF',
        boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.09) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.93)',
    }
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 36, 
        fontWeight: 'bold', 
    }
}

const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiAccordionSummary: {
            content: {
                marginTop: 0,
                "&$expanded": {
                    marginTop: 0,
                    marginBottom: 5,
                }
            },
            root: {
                "&$expanded": {
                    minHeight: 'unset',
                }
            },
        },
        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
                padding: 10,
            }
        },
        MuiDrawer: {
            paperAnchorBottom: {
                left: 'auto',
                right: 'auto',
                width: "inherit",
                height: 300,
                margin: '0px auto',
                borderRadius: '40px 40px 0 0',
                padding: '30px 35px 10px',
                backgroundColor: '#fff',
            }
        },
        MuiPaper: {
            root: {
                margin: '0px auto',
            }
        },
        MuiBackdrop: {
            root: {
                width: 'inherit',
                margin: '0px auto',
            }
        },
        MuiOutlinedInput: {
            root: {
                justifyContent: 'flex-start',
                fontSize: 15,
                fontWeight: 400,
                textTransform: 'capitalize',
                borderRadius: 48,
                boxShadow: 'inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff'
            }
        }
    }
});