import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import NumberFormat from 'react-number-format';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';

import { Avatar, Grid, Button, Typography, Link, Box, Divider, List, ListItem, Chip, Card, CardContent, AppBar, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, TextField, Switch } from '@material-ui/core';
import { ThemeProvider, makeStyles, useTheme, createMuiTheme } from '@material-ui/core/styles';
import { Stars, StarBorder, AssignmentTurnedIn, Storefront, Payment, MoveToInbox, Cancel, LocalShipping, FiberNew, SentimentVeryDissatisfied, SentimentSatisfied, SentimentVerySatisfied, ChevronLeftRounded, ChevronRightRounded, StarBorderRounded } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { FaTruckFast } from "react-icons/fa6";
import { LuPackageCheck } from "react-icons/lu";

import { useQuery } from '../helper/Tools';
import { getUrl, putUrl, postUrl } from '../helper/ApiAction';

import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import SwipeableViews from 'react-swipeable-views';

import WebpImg from './Layouts/WebpImg';
import PropTypes from 'prop-types';
import TabPanel from './Layouts/TabPanel';
import theme from '../Theme';

const tabTypeToIndex = {
    new: 0,
    paid: 1,
    delivered: 2,
    completed: 3,
    refunded: 4
};

const trackButtonEn =
    `        
        <input type="text" id="TrackNo" maxLength="50" style="
        border-radius: 5px;
        box-shadow: 3px 3px 6px 0px rgb(0 0 0 / 24%) inset, 7px 5px 4px 0 #fff;
        width: calc(100% - 80px);
        border: none;
        padding: 10px 15px;
        border-bottom: 1px solid #0001;
        border-right: 1px solid #0001
        " placeholder="Enter tracking no..." >
        <input type="button" value="TRACK" onClick="inputTrack()" style="
        padding: 9px 7px;
        background-color: ${theme.palette.primary.main};
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    " >
    `
const trackButtonCn =
    `        
        <input type="text" id="TrackNo" maxLength="50" style="style="
        border-radius: 5px;
        box-shadow: 3px 3px 6px 0px rgb(0 0 0 / 24%) inset, 7px 5px 4px 0 #fff;
        width: calc(100% - 80px);
        border: none;
        padding: 10px 15px;
        border-bottom: 1px solid #0001;
        border-right: 1px solid #0001
        " placeholder="输入追踪号码..." >
        <input type="button" value="追踪" onClick="inputTrack()" style="
        padding: 7px;
        background-color: ${theme.palette.primary.main};
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    " >
    `

export default function OrderNew(props) {
    const { t, i18n } = props;

    const styles = useStyles();
    const theme = useTheme();
    const query = useQuery();
    const isMountedRef = useRef(null);
    const tabType = query.get('type');
    const location = useLocation();
    let history = useHistory();

    const { walletList: wallets } = useSelector(state => state.wallet);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);

    const [toPay, setToPay] = useState([]);
    const [paid, setPaid] = useState([]);
    const [delivered, setDelivered] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [refunded, setRefunded] = useState([]);
    const [state, setState] = useState({
        tabIndex: tabTypeToIndex[tabType] ? tabTypeToIndex[tabType] : 0,
        rateDialog: false,
        rateOrderId: null,
        page: 1,
        totalPage: 0,
        total: 0
    });
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order_no: '',
        remark: ''
    });
    const [orderStatus, setOrderStatus] = useState([]);
    const [currencyData, setCurrencyData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [rateOrder, setRateOrder] = useState([]);
    const [shopRate, setShopRate] = useState(0);
    const [shopReview, setShopReview] = useState();
    const [shopInfo, setShopInfo] = useState({});
    const [anonymous, setAnonymous] = useState(false);
    const [rate, setRate] = useState();
    const [review, setReview] = useState();
    const [rateError, setRateError] = useState();
    const [reviewError, setReviewError] = useState();
    const [shippingWalletId, setShippingWalletId] = useState([]);

    let currency_code = [];
    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // --------------------------------- API -----------------------------------------------
    useEffect(() => {
        if (location.state && location.state.error_msg) {
            addAlert(location.state.error_msg);
            history.replace({ ...history, state: [] });
        }

        isMountedRef.current = true;
        let type = '';

        if (state.tabIndex === 0 || tabType === 'new') {
            type = 'new';
        } else if (state.tabIndex === 1 || tabType === 'paid') {
            type = 'paid';
        } else if (state.tabIndex === 2 || tabType === 'delivered') {
            type = 'delivered';
        } else if (state.tabIndex === 3 || tabType === 'completed') {
            type = 'completed';
        } else if (state.tabIndex === 4 || tabType === 'refunded') {
            type = 'refunded';
        }

        getUrl(`orders?type=${type}`)
            .then(orderList => {
                if (isMountedRef.current) {
                    setCurrencyData(orderList.currency_data);
                    if (orderList.data) {
                        if (state.tabIndex === 0) {
                            setToPay(orderList.data.data);
                        } else if (state.tabIndex === 1) {
                            setPaid(orderList.data.data);
                        } else if (state.tabIndex === 2) {
                            setDelivered(orderList.data.data);
                        } else if (state.tabIndex === 3) {
                            setCompleted(orderList.data.data);
                        } else if (state.tabIndex === 4) {
                            setRefunded(orderList.data.data);
                        }
                    }
                    setState(state => ({ ...state, totalPage: orderList.data.last_page, total: orderList.data.total }));
                    setOrderStatus(orderList.order_status);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [state.tabIndex, addAlert, refreshData]);

    // --------------------------------- FUNCTION ------------------------------------------
    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });

        let type = "new";
        if (newValue === 1) {
            type = 'paid';
        } else if (newValue === 2) {
            type = 'delivered';
        } else if (newValue === 3) {
            type = 'completed';
        } else if (newValue === 4) {
            type = 'refunded';
        }
        history.push(`/order?type=${type}`);
    };

    const handlePaginationChange = (event, value) => {
        setLoading(true);
        getUrl(`orders?type=${tabType}&page=${value}`).then(orderList => {
            if (isMountedRef.current) {
                setCurrencyData(orderList.currency_data);
                if (orderList.data) {
                    if (state.tabIndex === 0) {
                        setToPay(orderList.data.data);
                    } else if (state.tabIndex === 1) {
                        setPaid(orderList.data.data);
                    } else if (state.tabIndex === 2) {
                        setDelivered(orderList.data.data);
                    } else if (state.tabIndex === 3) {
                        setCompleted(orderList.data.data);
                    } else if (state.tabIndex === 4) {
                        setRefunded(orderList.data.data);
                    }
                }
                setLoading(false);
                setOrderStatus(orderList.order_status);
                setState(state => ({ ...state, page: value, totalPage: orderList.data.last_page, total: orderList.data.total }));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    };

    const handleCancelOrderOpen = (orderNo) => {
        setCancelOrderDialog({
            open: true,
            order_no: orderNo
        });
    }

    const handleCancelOrderClose = () => {
        setCancelOrderDialog({
            open: false,
            order_no: '',
            remark: ''
        });
    }

    const cancelOrder = () => {
        setLoading(true);
        setCancelOrderDialog({ ...cancelOrderDialog, open: false });
        const updateData = {
            remark: cancelOrderDialog.remark
        };

        postUrl(`orders/${cancelOrderDialog.order_no}/cancel_order`, updateData)
            .then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert(t('order.cancelSuccess'), 'success');
                    setRefreshData(!refreshData);
                    handleCancelOrderClose();
                } else {
                    setCancelOrderDialog({ ...cancelOrderDialog, open: true });
                    addAlert(response.data);
                }
            }).catch(error => {
                setLoading(false);
                setCancelOrderDialog({ ...cancelOrderDialog, open: true });
                addAlert(JSON.stringify(error.message));
            });
    }

    // -------------------------------- DESIGN FUNCTION -------------------------------------
    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if (paymentIndex === 'multi_wallet') {
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if (multiPricing) {
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
            } else {
                prefix = "Other wallets";
            }
        } else
            if (paymentIndex !== 'cash' && !isNaN(paymentIndex)) {
                _.map(wallets, wallet => {
                    if (parseInt(wallet.id) === parseInt(paymentIndex)) {
                        let name = _.split(wallet.name, '|');
                        prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                        // decimal = wallet.decimal;
                    }
                })
            } else if (paymentIndex === 'cash') {
                paymentItem = paymentItem * currencyRate[`${currency}`]
                prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
            } else {
                prefix = paymentIndex;
            }
        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix + ' ' : ''} />
        )
    }

    const generateListing = passOrder => {
        if (_.size(passOrder) > 0) {
            let toPayOrderList = [];
            let orderList = '';
            
            if (_.size(toPay) > 0 && tabType === 'new') {
                _.map(passOrder, passOrderDetail => {
                    _.map(passOrderDetail, passDetail => {
                        toPayOrderList.push(passDetail)
                    })
                })

                orderList = _.groupBy(toPayOrderList, "purchase_no");
            } else {
                orderList = _.groupBy(passOrder, "order_no");
            }

            return (
                <Grid container style={{ padding: '10px 20px' }}>
                    {
                        _.map(orderList, (order, key) => (
                            <Grid item xs={12} key={key} className={styles.productDetailStyle}>
                                <Box style={{ padding: '10px 0px' }}>
                                    {
                                        tabType === 'new'
                                            ? <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                <Payment style={{ marginLeft: 20 }} />
                                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 18, marginLeft: 5 }}>
                                                    {key}
                                                </Typography>
                                            </div>
                                            : <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                <Storefront style={{ marginLeft: 20 }} />
                                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 18, marginLeft: 5 }}>
                                                    {order[0]?.merchant_company?.[`shop_name_${i18n.language}`]}
                                                </Typography>
                                            </div>
                                    }
                                </Box>

                                {
                                    _.map(order, (orderItem, orderIndex) => {
                                        let totalPrice = {};
                                        let showOrder = true;
                                        let rateDone = false;

                                        // hide payment failed order
                                        if (_.size(toPay) > 0) {
                                            if (_.size(orderItem?.order_details) > 0) {
                                                _.map(orderItem?.order_details, (orderItemDetail) => {
                                                    if (_.size(orderItemDetail.payments) > 0 && orderItemDetail.status) {
                                                        _.map(orderItemDetail.payments, paymentItem => {
                                                            if (paymentItem.status === parseInt(orderStatus['returned']) || paymentItem.status === parseInt(orderStatus['new'])) {
                                                                showOrder = false
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }

                                        // check shipping
                                        if (_.size(orderItem?.order_shippings) > 0) {
                                            _.map(orderItem?.order_shippings, shippingValue => {
                                                let countShipping = false;
                                                if (orderItem.status === 60) {
                                                    countShipping = true;
                                                } else {
                                                    _.map(shippingValue.order_shipping_details, shipingDetail => {
                                                        _.map(orderItem?.order_details, orderDetails => {
                                                            if (orderDetails.id === shipingDetail.order_detail_id) {
                                                                if (orderDetails.status) {
                                                                    countShipping = true;
                                                                }
                                                            }
                                                        })
                                                    })
                                                }
                                                if (countShipping) {
                                                    if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                                        let wid = shippingWalletId;
                                                        if (shippingValue.wallet_id > 0) {
                                                            wid = shippingValue.wallet_id;
                                                        }
                                                        if (_.isUndefined(totalPrice[wid])) {
                                                            totalPrice[wid] = 0;
                                                        }
                                                        totalPrice[wid] += parseFloat(shippingValue.fee);
                                                    }
                                                }
                                            })
                                        }

                                        // check rate/review
                                        _.map(orderItem?.order_details, (orderDetailsItem) => {
                                            if (_.size(orderDetailsItem.product_review) > 0) {
                                                rateDone = true;
                                            }
                                        });

                                        return (
                                            <div key={orderIndex}>
                                                {
                                                    showOrder &&
                                                    _.map(orderItem?.order_details, (item, index) => {
                                                        let title_translate = _.split(item.title, '|');
                                                        return (
                                                            <div key={index}>
                                                                <Box style={{ padding: '5px 20px' }}>
                                                                    {
                                                                        tabType !== 'paid' ?
                                                                            <>
                                                                                <Typography variant="caption" style={{ marginBottom: 10 }}>{tabType === 'new' ? t('order.merchant') : t('order.id')} : {tabType === 'new' ? order[orderIndex]?.merchant_company?.[`shop_name_${i18n.language}`] : orderItem?.order_no}</Typography>
                                                                                <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                                                    <Grid container spacing={2}>
                                                                                        <Grid item xs={4}>
                                                                                            <WebpImg style={{ width: 93, borderRadius: 10, height: 93, objectFit: 'cover' }} alt={item.id} src={item.image ? item.image : '/images/logo.png'} />
                                                                                        </Grid>
                                                                                        <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '15px 10px 15px 0px' }}>
                                                                                            <Typography variant="caption" style={{ paddingBottom: 5, color: "#4E4E4E", overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{i18n.language === 'cn' ? title_translate[1] : title_translate[0]}</Typography>
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                <div className={styles.productVariantStyle}>
                                                                                                    {
                                                                                                        _.size(item.product_attribute_display) > 0 &&
                                                                                                        _.map(item.product_attribute_display, (variantText, i) => {
                                                                                                            if (_.includes(['color', 'size'], i)) {
                                                                                                                return (
                                                                                                                    <Typography key={i} variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}&nbsp;</Typography>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                                <Typography variant="body2" style={{ fontSize: 14 }}>x {item.quantity}</Typography>
                                                                                            </div>

                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                {
                                                                                                    _.size(item.payment) > 0
                                                                                                        ?
                                                                                                        _.map(item.payment, (paymentItem, paymentIndex) => {
                                                                                                            if (parseFloat(paymentItem) > 0) {
                                                                                                                return (
                                                                                                                    <Box key={paymentIndex}>
                                                                                                                        <Typography variant="caption" style={{ fontSize: 14 }}>
                                                                                                                            {priceDisplay(paymentItem, paymentIndex, item)}
                                                                                                                        </Typography>
                                                                                                                    </Box>
                                                                                                                );
                                                                                                            }
                                                                                                        })
                                                                                                        :
                                                                                                        <Typography variant="caption">
                                                                                                            <NumberFormat style={{ fontSize: 14 }} value={parseFloat(item.price) * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                                        </Typography>
                                                                                                }
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </>
                                                                        :
                                                                            <Link underline='none' to={`/order/${orderItem?.order_no}`} component={RouterLink}>
                                                                                <Typography variant="caption" style={{ marginBottom: 10 }}>{tabType === 'new' ? t('order.merchant') : t('order.id')} : {tabType === 'new' ? order[orderIndex]?.merchant_company?.[`shop_name_${i18n.language}`] : orderItem?.order_no}</Typography>
                                                                                <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                                                    <Grid container spacing={2}>
                                                                                        <Grid item xs={4}>
                                                                                            <WebpImg style={{ width: 93, borderRadius: 10, height: 93, objectFit: 'cover' }} alt={item.id} src={item.image ? item.image : '/images/logo.png'} />
                                                                                        </Grid>
                                                                                        <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '15px 10px 15px 0px' }}>
                                                                                            <Typography variant="caption" style={{ paddingBottom: 5, color: "#4E4E4E", overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{i18n.language === 'cn' ? title_translate[1] : title_translate[0]}</Typography>
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                <div className={styles.productVariantStyle}>
                                                                                                    {
                                                                                                        _.size(item.product_attribute_display) > 0 &&
                                                                                                        _.map(item.product_attribute_display, (variantText, i) => {
                                                                                                            if (_.includes(['color', 'size'], i)) {
                                                                                                                return (
                                                                                                                    <Typography key={i} variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}&nbsp;</Typography>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                                <Typography variant="body2" style={{ fontSize: 14 }}>x {item.quantity}</Typography>
                                                                                            </div>

                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                                {
                                                                                                    _.size(item.payment) > 0
                                                                                                        ?
                                                                                                        _.map(item.payment, (paymentItem, paymentIndex) => {
                                                                                                            if (parseFloat(paymentItem) > 0) {
                                                                                                                return (
                                                                                                                    <Box key={paymentIndex}>
                                                                                                                        <Typography variant="caption" style={{ fontSize: 14 }}>
                                                                                                                            {priceDisplay(paymentItem, paymentIndex, item)}
                                                                                                                        </Typography>
                                                                                                                    </Box>
                                                                                                                );
                                                                                                            }
                                                                                                        })
                                                                                                        :
                                                                                                        <Typography variant="caption">
                                                                                                            <NumberFormat style={{ fontSize: 14 }} value={parseFloat(item.price) * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                                        </Typography>
                                                                                                }
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Link>
                                                                    }
                                                                    
                                                                </Box>
                                                                {
                                                                    orderIndex !== (_.size(order) - 1) && tabType === 'paid' &&
                                                                    <Divider style={{ height: 4, background: '#fff', margin: '5px 10px', boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.10) inset' }} />
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    tabType !== 'paid' &&
                                                    <div style={{ padding: '0px 20px 10px' }}>
                                                        {
                                                            tabType === 'delivered' &&
                                                                <>
                                                                    <Divider style={{ height: 4, background: '#fff', margin: '5px 0px', boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.10) inset' }} />
                                                                    <Link key={key} underline={'none'} to={`/order/${orderItem?.order_no}`} component={RouterLink}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5 }} className={styles.linkOrderDetailStyle}>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <FaTruckFast style={{ fontSize: 20, color: theme.palette.primary.main }} />
                                                                                {/* <Typography style={{ fontSize: 12, paddingLeft: 10 }}>Your parcel has cleared the import customs</Typography> */}
                                                                                <Typography style={{ fontSize: 12, paddingLeft: 10 }}>{t('order.status')}: {orderItem?.status_display}</Typography>
                                                                            </div>
                                                                            <ChevronRightRounded />
                                                                        </div>
                                                                    </Link>
                                                                </>
                                                        }
                                                        {
                                                            tabType === 'completed' &&
                                                                <>
                                                                    <Divider style={{ height: 4, background: '#fff', margin: '5px 0px', boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.10) inset' }} />
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0px' }}>
                                                                        {
                                                                            rateDone ?
                                                                                <>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <StarBorderRounded style={{ fontSize: 20, color: theme.palette.primary.main }} />
                                                                                        {/* <Typography style={{ fontSize: 12, paddingLeft: 10 }}>Delivered</Typography> */}
                                                                                        <Typography style={{ fontSize: 12, paddingLeft: 10 }}>{t('order.rated')}</Typography>
                                                                                    </div>
                                                                                </>
                                                                                : <>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <LuPackageCheck style={{ fontSize: 20, color: theme.palette.primary.main }} />
                                                                                        {/* <Typography style={{ fontSize: 12, paddingLeft: 10 }}>Delivered</Typography> */}
                                                                                        <Typography style={{ fontSize: 12, paddingLeft: 10 }}>{orderItem?.status_display}</Typography>
                                                                                    </div>
                                                                                    <Button className={styles.buttonDisplayStyle} to={`/review/${orderItem?.order_no}`} component={RouterLink}>
                                                                                        {t('order.review')}
                                                                                    </Button>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }

                                {
                                    tabType === 'new' &&
                                        <div style={{ padding: '0px 20px 10px' }}>
                                            <Divider style={{ height: 4, background: '#fff', margin: '5px 0px', boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.10) inset' }} />
                                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', padding: '5px 0px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Payment style={{ fontSize: 20, color: theme.palette.primary.main }} />
                                                    <Typography style={{ fontSize: 12, paddingLeft: 10 }}>{t('order.payFirst')}</Typography>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                    <Button className={styles.buttonDisplayStyle} style={{ marginBottom: 10 }} variant='contained' to={`/payment/${encodeURIComponent(key)}`} component={RouterLink}>
                                                        {t('button.payNow')}
                                                    </Button>
                                                    {
                                                        // orderItem?.status === 20
                                                        //     ? <Button className={styles.buttonOutlinedDisplayStyle} variant='outlined' style={{ color: '#000' }} onClick={() => handleCancelOrderOpen(orderItem?.order_no)}>
                                                        //         {t('order.cancelOrder')}
                                                        //     </Button>
                                                        //     : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                }
                            </Grid>
                        ))
                    }
                    {
                        state.total > 0 &&
                        <Grid container spacing={1} justify="center" style={{ paddingTop: 10 }} >
                            <Pagination count={state.totalPage} shape="rounded" page={state.page}
                                onChange={handlePaginationChange}
                                renderItem={(item) => (
                                    <PaginationItem {...item} />
                                )}
                            />
                        </Grid>
                    }
                </Grid>
            )
        } else {
            return (
                <Card style={{ borderRadius: 0 }} elevation={0}>
                    <CardContent style={{ height: 400, borderRadius: 0, background: '#F4F4F4' }}>
                        {
                            _.size(passOrder) > 0 ? loading
                                : <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <WebpImg src="/images/no_data/noorder.png" style={{ width: 180 }} alt="" />
                                    <Typography variant="h5" style={{ textAlign: "center" }}>{t('order.noOrder')}</Typography>
                                </Box>
                        }
                    </CardContent>
                </Card>
            )
        }
    }

    return (
        <div style={{ paddingTop: 20 }}>
            <ThemeProvider theme={accordingStyles}>
                <Tabs value={state.tabIndex} onChange={handleChange} variant="scrollable" scrollButtons="on" TabIndicatorProps={{ style: { backgroundColor: "#000", color: '#000' } }}>
                    <Tab label={t('order.toPay')} {...a11yProps(0)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                    <Tab label={t('order.paid')} {...a11yProps(1)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                    <Tab label={t('order.delivered')} {...a11yProps(2)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                    <Tab label={t('order.completed')} {...a11yProps(2)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                    <Tab label={t('order.refunded')} {...a11yProps(2)} style={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
                </Tabs>
            </ThemeProvider>

            <div>
                <SwipeableViews axis='x' index={state.tabIndex} onChangeIndex={handleChange}>
                    <CustomTabPanel value={state.tabIndex} index={0}>
                        {generateListing(toPay)}
                    </CustomTabPanel>
                    <CustomTabPanel value={state.tabIndex} index={1}>
                        <Grid container style={{ padding: '10px 20px 0px' }}>
                            <Grid item xs={12}>
                                {
                                    i18n.language === 'cn' ?
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} style={{ float: "right" }} />
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} style={{ float: "right", width:'100%' }} className='flex-sb-m'/>
                                } 
                            </Grid>
                        </Grid>
                        {generateListing(paid)}
                    </CustomTabPanel>
                    <CustomTabPanel value={state.tabIndex} index={2}>
                        <Grid container style={{ padding: '10px 20px 0px' }}>
                            <Grid item xs={12}>
                                {
                                    i18n.language === 'cn' ?
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} style={{ float: "right" }} />
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} style={{ float: "right" }} />
                                }
                            </Grid>
                        </Grid>
                        {generateListing(delivered)}
                    </CustomTabPanel>
                    <CustomTabPanel value={state.tabIndex} index={3}>
                        {generateListing(completed)}
                    </CustomTabPanel>
                    <CustomTabPanel value={state.tabIndex} index={4}>
                        {generateListing(refunded)}
                    </CustomTabPanel>
                </SwipeableViews>
            </div>

            <Dialog open={cancelOrderDialog.open} onClose={handleCancelOrderClose} >
                <DialogTitle>
                    {t('order.cancelOrder')}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center" >
                        <Grid item xs={12}>
                            {t('order.cancelOrderReminder')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label={t('order.cancelOrderRemark')}
                                multiline
                                value={cancelOrderDialog.remark}
                                onChange={(event) => { setCancelOrderDialog({ ...cancelOrderDialog, remark: event.target.value }) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" className={styles.buttonStyle} onClick={() => cancelOrder()} >
                        <Typography variant="overline">{t('order.confirmCancelOrder')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Helmet>
                <script src="//www.tracking.my/track-button.js" async></script>
                <script type="text/javascript">
                    {`  function inputTrack() {
                            var num = document.getElementById("TrackNo").value;
                            if(num===""){
                            alert("Please enter tracking number");
                            return;
                            }
                            TrackButton.track({
                                tracking_no: num
                             });
                         }
                    `}
                </script>
            </Helmet>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    merchantBoxStyle: {
        width: "100%",
        padding: '20px 28px',
        display: 'flex',
        alignItems: 'center',
    },
    productBoxStyle: {
        width: "100%",
        padding: '15px 28px',
        display: 'flex',
        alignItems: 'center',
    },
    productDetailStyle: {
        width: "100%",
        borderRadius: 15,
        margin: '10px 0px',
        background: '#F4F4F4',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF',
    },
    productVariantStyle: {
        borderRadius: 5,
        background: '#EDEDED',
        boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 3px 0px #FFF',
        padding: '0px 10px',
        width: 180,
    },
    inputStyle: {
        textAlign: 'center',
        width: 20,
        height: 16,
        fontSize: 14,
        padding: 5,
    },
    buttonOutlinedDisplayStyle: {
        width: 110,
        textTransform: 'capitalize',
        color: '#fff',
        fontSize: 12,
        borderRadius: 4,
        '&:hover': {
            color: '#000'
        }
    },
    buttonDisplayStyle: {
        width: 110,
        textTransform: 'capitalize',
        background: theme.palette.primary.main,
        color: '#fff',
        fontSize: 12,
        borderRadius: 4,
        '&:hover': {
            color: '#000'
        }
    },
    buttonDisabledDisplayStyle: {
        width: 80,
        height: 36.5,
        background: theme.palette.secondary.main,
        textTransform: 'capitalize',
        borderRadius: 4,
        color: '#fff',
        padding: '6px 8px',
        textAlign: 'center',
    },
    linkOrderDetailStyle: {
        '&:hover': {
            background: '#fff',
        }
    }
}));

const accordingStyles = createMuiTheme({
    overrides: {
        MuiTab: {
            root: {
                minWidth: 100,
                '@media (min-width: 600px)': {
                    minWidth: 100
                },
                "&.Mui-selected": {
                    color: '#000',
                }
            },
        },
    }
});

// ----------------------------------------------- UI DESIGN ---------------------------------------------
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}