import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUrl } from '../helper/ApiAction';

import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid, Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary   } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useNotificationLoading from '../helper/useNotificationLoading';

import 'react-awesome-slider/dist/styles.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from 'react';

export default function Faq() {
    const [faqs, setFaq] = useState([]);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        isMountedRef.current = true;
            getUrl('faq')
            .then(faqList => {
                if(isMountedRef.current) {
                    setFaq(faqList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    return(
        <Box>
            <Container fixed className={ styles.root }>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} sm={10}>
                    <Typography color="secondary" variant="h6" style={{ paddingLeft: 10 }}>{ t('title.faq') }</Typography>
                </Grid>
                { _.map(faqs, (faq) => {
                    const question_translate = _.split(faq.question, '|');
                    const answer_translate = _.split(faq.answer, '|');
                    return(
                    <Grid item xs={12} sm={10}>
                    <ExpansionPanel container spacing={1} xs={12} sm={10} expanded={expanded === `${faq.id}-panel`} 
                    onChange={handleChange(`${faq.id}-panel`)}>
                    <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${faq.id}-content`}
                    id={`${faq.id}-header`}
                    >
                        <Typography className={styles.heading}>
                        { i18n.language === 'cn' ? (question_translate[1] ? question_translate[1] : question_translate[0]) : question_translate[0] }
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                        { i18n.language === 'cn' ? (answer_translate[1] ? answer_translate[1] : answer_translate[0]) : answer_translate[0] }
                        </Typography>
                    </ExpansionPanelDetails>
                    </ExpansionPanel>
                    </Grid>
                    )
                })}
            </Grid>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
      },
}));