import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link as RouterLink, useHistory,useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import _, { includes, isNumber, values } from 'lodash';

import { makeStyles, withStyles, Radio, RadioGroup, FormControlLabel, CircularProgress, DialogTitle, DialogContent, DialogActions, TextField, Dialog, FormControl, Select, Card, CardContent, IconButton, Hidden, CardActionArea, Stepper, Step, StepLabel, Chip, Button, Typography, Grid, Breadcrumbs, Link, Box, List, ListItem, useTheme, CardHeader, FormHelperText, MenuItem, MuiDialogTitle, MuiDialogContent, MuiDialogActions, Input, InputAdornment, Divider, Switch } from '@material-ui/core';
import { Edit, Plus, Edit3 } from 'react-feather';
import { CloseOutlined, StorefrontOutlined, ArtTrackOutlined, AspectRatioOutlined, CheckCircle, CodeSharp } from '@material-ui/icons';
import { FiChevronLeft } from 'react-icons/fi';
import WebpImg from './Layouts/WebpImg';

import { updateCartTotal } from '../actions';
import { getUrl, postUrl, putUrl, deleteUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import TitleBar from './Layouts/TitleBar';
import './css/styles.css';
import ImgHeader from './Layouts/ImgHeader';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import CloseIcon from '@material-ui/icons/Close';
import PaymentMethod from './Layouts/PaymentMethod';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { parse } from 'crypto-js/enc-base64';
import { useQuery } from '../helper/Tools';

const INITIAL_ADDRESS_STATE = { name: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "" };


////// dialog - change address /////////////


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


///////////// dialog end - change address ///////////

export default function Checkout(props) {
    const { name, email, mobile, username, cartTotal, walletBalance: userwallets } = useSelector(state => state.user);
    const location = useLocation();
    const [recipient, setRecipient]  = useState(location.state?location.state.recipient:'');
    const [buyerInfo, setBuyerInfo] = useState({
        name: name,
        email: email ? email : '',
        mobile: mobile ? mobile : '',
    });
    const [recipientInfo, setRecipientInfo] = useState({
        name: name,
        email: email ? email : '',
        mobile: mobile ? mobile : '',
    });
    const [userDialog, setUserDialog] = useState({
        type: '',
        open: false,
        name: '',
        email: '',
        mobile: '',
    });
    const [state, setState] = useState({
        open: false,
        error: false,
        message: '',
        modalShow: false,
        paymentId: null,
        addressId: null,
        totalPrice: 0,
        totalSelected: null,
        totalWalletPrice: {},
        totalShippingPrice: 0,
        walletBalance: {},
        selectAddressShow: false,
        orderSuccess: false,
        mode: null,
        editAddressId: null,
    });
    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        name: "",
        mobile: "",
        email: "",
        identity_no: "",
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
        icFront: "",
        icBack: "",
    })
    const [deleteId, setDeleteId] = useState({
        selectedeId: [],
        originaQuantity: 0,
        merchantId: 0,
        dialog: false,
    })
    const [errorField, setErrorField] = useState({
        cart: []
    });
    const [cartErrors, setCartError] = useState([]);
    const [cart, setCart] = useState([]);
    const [updateCart, setUpdateCart] = useState(false);
    const [currencyData, setCurrencyData] = useState(null);
    const [addresses, setAddress] = useState(null);
    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [remark, setRemark] = useState({});
    const [mallShippingRefresh, setMallShippingRefresh] = useState(false);
    const [mallShipping, setMallShipping] = useState([]);
    const [mallTotalShipping, setMallTotalShipping] = useState(0);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [fetchingMallShipping, setFetchingMallShipping] = useState(false);
    const [paymentMethodPopUp, setPaymentMethodPopUp] = useState(false);
    const [shippingFeeOpen, setShippingFeeOpen] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [multiWalletBalance, setMultiWalletBalance] = useState([]);
    const [downlineInfo, setDownlineInfo] = useState({
        username: username ? username : '',
        source: 'local',
    });
    const [shippingWallet, setShippingWallet] = useState({
        id: 0,
        prefix: '',
        decimal: 2,
    });
    const [totalPayment, setTotalPayment] = useState({
        cash: 0,
        wallet_list: {}
    });
    const [shouldUploadIC, setShouldUploadIC] = useState(false);
    const [redeemWallet, setRedeemWallet] = useState([]);
    const [redeemCash, setRedeemCash] = useState(0);
    const [isMalaysiaShipping, setIsMalaysiaShipping] = useState(false);
    const [malaysiaShippingArea, setMalaysiaShippingArea] = useState("");
    const [selfPickUpInfo, setSelfPickupInfo] = useState({});
    const [mallFee, setMallFee] = useState({});
    const [pickup, SetPickUp] = useState(0);
    // const [oid, SetOid]=useState(0);
    const [walletPoint, setWalletPoint] = useState([]);
    const [walletPointVersion2, setWalletPointVersion2] = useState([]);
    //new version 
    const [paymentMethod, setPaymentMethod] = useState('');
    const [allowedWallet, setAllowedWallet] = useState({});
    const [rpRedeem, setRpRedeem] = useState(false);
    const [amountRpRedeem, setAmountRpRedeem] = useState(0);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    const icFrontRef = useRef();
    const icBackRef = useRef();

    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }

    });

    useEffect(() => {
        if (_.size(addresses) > 0 && state.addressId > 0) {
            const selectedAddress = _.find(addresses, { id: state.addressId });
            if (_.size(selectedAddress) && selectedAddress.country === 'MY') {
                setIsMalaysiaShipping(true);
                getUrl(`countries/${selectedAddress.country}`).then(response => {
                    // console.log('countries', response);
                    if (response.status) {
                        _.map(response.data, (regionState, region) => {
                            const existState = _.find(regionState, { id: parseInt(selectedAddress.state) });
                            if (_.size(existState)) {
                                setMalaysiaShippingArea(region);
                            }
                        })
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                setIsMalaysiaShipping(false);
            }
        }
    }, [state.addressId, addresses, cart]);

    useEffect(() => {
        calculateTotal(cart ,rpRedeem);
    }, [malaysiaShippingArea, cart, selfPickUpInfo]);

    function callApi() {
        getUrl('carts')
            .then(cartList => {
                if (isMountedRef.current) {
                    let rpRedeemAmount = 0 ;
                    // console.log("cartList", cartList);
                    setCurrencyData(cartList.currency_data);
                    setCart(cartList.data);
                    // calculateTotal(cartList.data , rpRedeem);
                    calculateRpRedeem(cartList.data , rpRedeem);
                    setupWalletPoint(cartList.data);
                    // setIsSelfPickUp(cartList.data.selfPickUp ==1? true:false);
                    _.map(cartList.data, (merchantItem, merchantIndex) => {
                        if (_.size(selfPickUpInfo) <= 0) {
                            setSelfPickupInfo(prevState => ({
                                ...prevState,
                                [merchantIndex]: {
                                    id: merchantIndex,
                                    channel: 'delivery',
                                    self_pickup: false,
                                    outlet_id: 0,
                                }
                            }))
                        }
                        _.map(merchantItem.items, (cartDetail) => {
                            _.map(cartDetail, (cartItem) => {
                                if (cartItem.selected) {
                                    rpRedeemAmount = parseFloat(cartItem.quantity * cartItem.max_rp_redeem) + rpRedeemAmount
                                    if(rpRedeemAmount >0 ){
                                        setAmountRpRedeem(rpRedeemAmount)
                                    }else{
                                        setAmountRpRedeem(0)
                                    }
                                }
                            })
                        })
                        // }
                    })
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    }

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            callApi();
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, updateCart, wallets, mallFee]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('wallets/balance').then(response => {
                if (isMountedRef.current && response.status === 1) {
                    setWallets(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('user/address')
                .then(addressList => {
                    if (isMountedRef.current) {
                        setAddress(addressList.data);
                        // console.log("addressList.data", addressList.data);
                        let defaultAddress = _.find(addressList.data, { 'default': true });
                        if (_.size(defaultAddress) > 0) {
                            // setState(state => ({ ...state, addressId: defaultAddress.id, changedAddressId: defaultAddress.id }))
                            setState(state => ({ ...state, addressId: defaultAddress.id }))
                        } else {
                            if (_.size(addressList.data)) {
                                const firstAddress = addressList.data[0];
                                // setState(state => ({ ...state, addressId: firstAddress.id, changedAddressId: firstAddress.id }))
                                setState(state => ({ ...state, addressId: firstAddress.id }))
                            }
                        }
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [addAlert, t]);

    useEffect(() => {
        if (addressFormState.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressFormState.country])

    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            // console.log(countryList)
            if (isMountedRef.current) {
                setCountry(countryList.data);
                // const filteredCountryList = _.filter(countryList.data, countryItem => countryItem.code === 'CN' || countryItem.code === 'MY');
                // setCountry(filteredCountryList);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [i18n.language, addAlert, t, addressFormState.country]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.country) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if (isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.state) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.city) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (state.addressId !== null) {
            setDisabledSubmit(true);
            setFetchingMallShipping(true);
            const selectedAddress = _.find(addresses, { 'id': state.addressId });
            const updateData = {
                user_zip: selectedAddress ? selectedAddress.zip : '',
                user_state: selectedAddress ? selectedAddress.state : '',
                user_country: selectedAddress ? selectedAddress.country : '',
            }
            putUrl('mall/shipping_fee', updateData).then(response => {
                setFetchingMallShipping(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setMallShipping(response.data);
                        // setDisabledSubmit(response.error);
                        let checkDisableSubmit = response.error;
                        _.map(response.data, (merchantItem, merchantIndex) => {
                            let merchant_channel = _.find(selfPickUpInfo, { 'id': merchantIndex });
                            if (_.size(merchant_channel) > 0) {
                                if (merchant_channel.self_pickup) {
                                    checkDisableSubmit = false;
                                }
                            }
                        })
                        setMallFee(0);
                        setDisabledSubmit(checkDisableSubmit);
                        setMallTotalShipping(response.total_shipping ? response.total_shipping : 0);
                    } else {
                        addAlert(JSON.stringify(response.data));
                        setDisabledSubmit(true);
                    }
                }
            }).catch(error => {
                setFetchingMallShipping(false);
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [addAlert, addresses, state.addressId, mallShippingRefresh]);

    useEffect(() => {
        getUrl('shipping_fee/wallet').then(response => {
            if (response.status) {
                let shippingFeePrefix = '';
                if (currencyData) {
                    shippingFeePrefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
                }
                if (_.size(response.data) > 0) {
                    let name = _.split(response.data.name, '|');
                    shippingFeePrefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                    setShippingWallet({ id: response.data.id, prefix: shippingFeePrefix, decimal: response.data.decimal });
                } else {
                    setShippingWallet({ id: 0, prefix: shippingFeePrefix, decimal: 2 });
                }
            } else {
                addAlert(JSON.stringify(response.data));
                setDisabledSubmit(true);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return;
    }, [i18n.language, addAlert, currencyData]);

    useEffect(() => {
        let totalCashAmount = 0;
        let totalCashLeft = 0;
        let tempRedeemWallet = {};

        if (shippingWallet.id > 0) {
            totalCashAmount = totalCashLeft = parseFloat(state.totalPrice).toFixed(2);
        } else {
            totalCashAmount = totalCashLeft = parseFloat(parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)).toFixed(2);
        }
        totalCashAmount = totalCashLeft = 0; //dont auto fill
        _.map(wallets, wallet => {
            if (wallet.is_cash === 1) {
                let name = _.split(wallet.name, '|');
                let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                // default redeem full amount
                let rate = 1;
                if (wallet.cash_rate > 0) {
                    rate = wallet.cash_rate;
                }
                let cashBalance = wallet.balance * rate;
                if (totalCashLeft > cashBalance) {
                    tempRedeemWallet[display_name] = parseFloat(wallet.balance).toFixed(2); //wallet.decimal
                    totalCashLeft -= cashBalance;
                } else {
                    tempRedeemWallet[display_name] = parseFloat(totalCashLeft / rate).toFixed(2); //wallet.decimal
                    totalCashLeft = 0;
                }
            }
        })
        setRedeemWallet(tempRedeemWallet);
        setRedeemCash(parseFloat(parseFloat(totalCashAmount) - parseFloat(totalCashLeft)).toFixed(2));
    }, [wallets, state.totalPrice, state.totalShippingPrice, shippingWallet, mallTotalShipping, pickup]);

    const calculateRpRedeem = (usecart , status) => {
        if (status === true) {
            let tempWalletPoint = {};
            let maxRpRdeem = 0;
            let rpWallet = _.find(userwallets, { 'code': 'RP' });
            let rpBalance  = rpWallet.balance;
            _.map(usecart, (merchantItem, merchantId) => {
                _.map(merchantItem.items, (cartDetails) => {
                    _.map(cartDetails, (cartItem) => {
                        if (cartItem.selected) {
                            if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                                // cache max rp redeem 
                                if(parseFloat(rpBalance) >= parseFloat(cartItem.max_rp_redeem)){
                                    maxRpRdeem = parseFloat(cartItem.max_rp_redeem) * parseFloat(cartItem.quantity);
                                    rpBalance = parseFloat(rpBalance) - (parseFloat(cartItem.max_rp_redeem) * parseFloat(cartItem.quantity));
                                }else{
                                    maxRpRdeem = rpBalance;
                                    rpBalance = 0;
                                }
                                // find total price
                                let productPrice = cartItem.product.sell_price;
                                if (_.size(cartItem.product.options) > 0) {
                                    _.map(cartItem.product.options, (optionItem) => {
                                        _.map(optionItem.pricing, (pricingOption) => {
                                            if (pricingOption.id === cartItem.product_warehouse_id) {
                                                productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                            }
                                        })
                                    })
                                }
                                let totalPrice = cartItem.quantity * productPrice;
                                let sumTotal = 0;
                                let isSetCash = false;
                                // set cash wallet to productPrice
                                let productWallet = {};
                                const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                                _.map(selectedPricing.wallet_settings, setting => {
                                    _.map(setting.wallet_ids, wid => {
                                        if (wid != rpWallet.id) {
                                            if (setting.type == 'percent') {
                                                productWallet[wid] = ((cartItem.quantity * parseFloat(((productPrice) * setting.max_amount) / 100)) - maxRpRdeem);
                                            } else {
                                                productWallet[wid] = ((cartItem.quantity  * parseFloat(setting.max_amount)) - maxRpRdeem);
                                            }
                                            if (wid != 'cash') {
                                                sumTotal += parseFloat(productWallet[wid]);
                                            } else {
                                                if (setting.type == 'percent') {
                                                    totalPrice = (cartItem.quantity * parseFloat((productPrice * setting.max_amount) / 100)) - maxRpRdeem;
                                                } else {
                                                    totalPrice = (cartItem.quantity * (setting.max_amount)) - maxRpRdeem;
                                                }
                                            }

                                        } else {
                                            if (setting.type == 'percent') {
                                                productWallet[wid] = ((cartItem.quantity * parseFloat((productPrice * setting.max_amount) / 100))  - maxRpRdeem);
                                            } else {
                                                productWallet[wid] = ((cartItem.quantity * parseFloat(setting.max_amount)) - maxRpRdeem);
                                            }
                                            if (wid != 'cash') {
                                                sumTotal += parseFloat(productWallet[wid]);
                                            } else {
                                                if (setting.type == 'percent') {
                                                    totalPrice = (cartItem.quantity * parseFloat((productPrice * setting.max_amount) / 100))- maxRpRdeem;
                                                } else {
                                                    totalPrice = (cartItem.quantity * setting.max_amount)- maxRpRdeem;
                                                }
                                            }
                                        }
                                    })
                                })
                                if (maxRpRdeem > 0) {
                                    productWallet[rpWallet.id] = maxRpRdeem;
                                }
                                if (isSetCash) {
                                    let cash = totalPrice - sumTotal;
                                    productWallet['cash'] = cash > 0 ? cash : 0;
                                }
                                // set wallet point
                                tempWalletPoint[cartItem.id] = productWallet;
                            }else{
                                // cache max rp redeem 
                                if(parseFloat(rpBalance) >= parseFloat(cartItem.max_rp_redeem)){
                                    maxRpRdeem = cartItem.max_rp_redeem;
                                    rpBalance = parseFloat(rpBalance) - parseFloat(cartItem.max_rp_redeem);
                                }else{
                                    maxRpRdeem = rpBalance;
                                    rpBalance = 0;
                                }
                                let productPrice = cartItem.product.sell_price;
                                if (_.size(cartItem.product.options) > 0) {
                                    _.map(cartItem.product.options, (optionItem) => {
                                        _.map(optionItem.pricing, (pricingOption) => {
                                            if (pricingOption.id === cartItem.product_warehouse_id) {
                                                productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                            }
                                        })
                                    })
                                }
                                // set cash wallet to productPrice
                                let productWallet = {};
                                productWallet['cash'] = productPrice > 0 ? ((productPrice )* cartItem.quantity)- maxRpRdeem : 0;

                                if (maxRpRdeem > 0) {
                                    productWallet[rpWallet.id] = maxRpRdeem;
                                }
                                // set wallet point
                                tempWalletPoint[cartItem.id] = productWallet;
                            }
                        }
                    })
                })
            });
            setWalletPoint(tempWalletPoint);
        } else {
            let tempWalletPoint = {};
            _.map(usecart, (merchantItem, merchantId) => {
                _.map(merchantItem.items, (cartDetails) => {
                    _.map(cartDetails, (cartItem) => {
                        if (cartItem.selected) {
                            if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                                // find total price
                                let productPrice = cartItem.product.sell_price;
                                if (_.size(cartItem.product.options) > 0) {
                                    _.map(cartItem.product.options, (optionItem) => {
                                        _.map(optionItem.pricing, (pricingOption) => {
                                            if (pricingOption.id === cartItem.product_warehouse_id) {
                                                productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                            }
                                        })
                                    })
                                }
                                let totalPrice = cartItem.quantity * productPrice;
                                let sumTotal = 0;
                                let isSetCash = false;
                                // set cash wallet to productPrice
                                let productWallet = {};
                                const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                                _.map(selectedPricing.wallet_settings, setting => {
                                    _.map(setting.wallet_ids, wid => {
                                        if (setting.type == 'percent') {
                                            productWallet[wid] = (cartItem.quantity * parseFloat((productPrice * setting.max_amount) / 100));
                                        } else {
                                            productWallet[wid] = (cartItem.quantity * parseFloat(setting.max_amount));
                                        }
                                        if (wid != 'cash') {
                                            sumTotal += parseFloat(productWallet[wid]);
                                        } else {
                                            if (setting.type == 'percent') {
                                                totalPrice = cartItem.quantity * parseFloat((productPrice * setting.max_amount) / 100);
                                            } else {
                                                totalPrice = cartItem.quantity * setting.max_amount;
                                            }
                                        }
                                    })
                                })
                                if(!selectedPricing){ 
                                    productWallet['cash'] = productPrice > 0 ? productPrice : 0;
                                }
                                if (isSetCash) {
                                    let cash = totalPrice - sumTotal;
                                    productWallet['cash'] = cash > 0 ? cash : 0;
                                }
                                // set wallet point
                                tempWalletPoint[cartItem.id] = productWallet;
                            }else{
                                let productPrice = cartItem.product.sell_price;
                                if (_.size(cartItem.product.options) > 0) {
                                    _.map(cartItem.product.options, (optionItem) => {
                                        _.map(optionItem.pricing, (pricingOption) => {
                                            if (pricingOption.id === cartItem.product_warehouse_id) {
                                                productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                            }
                                        })
                                    })
                                }
                                // set cash wallet to productPrice
                                let productWallet = {};
                                productWallet['cash'] = productPrice > 0 ? (productPrice )* cartItem.quantity : 0;
                                // set wallet point
                                tempWalletPoint[cartItem.id] = productWallet;
                            }
                        }
                    })
                })
            });
            setWalletPoint(tempWalletPoint);
        }
    }
    
    const closeUserModal = () => {
        setUserDialog({ ...userDialog, open: false });
    }
  
    const calculateTotal = (userCart , rp = false) => {
        let totalPrice = 0;
        let totalSelected = 0;
        let totalWalletPrice = {};
        let forMultiWalletBalance = {};
        let totalShippingPrice = 0;
        let maxRpRdeem = 0;

        _.map(userCart, (merchantItem, merchantId) => {
            let merchant_channel = _.find(selfPickUpInfo, { 'id': merchantId });
            if (merchant_channel && merchant_channel.channel == 'pickup') {
                totalShippingPrice += 0;
            } else {
                if (isMalaysiaShipping && malaysiaShippingArea === 'east') {
                    totalShippingPrice += merchantItem.shipping_fee2 > 0 ? parseFloat((merchantItem.shipping_fee2).toFixed(2)) : 0;
                } else {
                    totalShippingPrice += merchantItem.shipping_fee > 0 ? parseFloat((merchantItem.shipping_fee).toFixed(2)) : 0;
                }
            }
            _.map(merchantItem.items, (cartDetails) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        maxRpRdeem = cartItem.max_rp_redeem;
                        totalSelected += parseInt(cartItem.quantity);
                        let itemPrice = parseFloat(cartItem.product.sell_price);
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (option) => {
                                _.map(option.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        if(rp === true){
                                            itemPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) - maxRpRdeem : cartItem.product.sell_price - maxRpRdeem
                                        }else{
                                            itemPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : cartItem.product.sell_price
                                        }
                                    }
                                })
                            })
                        }
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            let wallet_settings  = _.find(cartItem.product.multi_pricings, {'id': cartItem.multi_pricing_id})?.wallet_settings;
                            _.map(wallet_settings , (setting,index) =>{
                                if(setting.type == 'percent'){
                                    totalPrice += parseFloat(((itemPrice*(setting.max_amount/100))* cartItem.quantity).toFixed(2));
                                }else{ 
                                    if(rp === true){
                                        totalPrice += parseFloat(((setting.max_amount)* cartItem.quantity).toFixed(2));
                                    }else{
                                        totalPrice += parseFloat((setting.max_amount * cartItem.quantity).toFixed(2));
                                    }
                                }
                            })
                        } else {
                            if(rp === true){
                                totalPrice += parseFloat(((itemPrice) * cartItem.quantity).toFixed(2));
                            }else{
                                totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                            }
                        }
                    }
                })
            })
        });
        setMultiWalletBalance(forMultiWalletBalance);
        setState(state => ({ ...state, totalPrice, totalSelected, totalWalletPrice, totalShippingPrice }));
    };

    const setupWalletPoint = userCart => {
        let tempWalletPoint = {};
        _.map(userCart, (merchantItem, merchantId) => {
            _.map(merchantItem.items, (cartDetails) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            // find total price
                            let productPrice = cartItem.product.sell_price;
                            if (_.size(cartItem.product.options) > 0) {
                                _.map(cartItem.product.options, (optionItem) => {
                                    _.map(optionItem.pricing, (pricingOption) => {
                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                            productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                        }
                                    })
                                })
                            }
                            let totalPrice = cartItem.quantity * productPrice;
                            let sumTotal = 0;
                            let isSetCash = false;
                            // set cash wallet to productPrice
                            let productWallet = {};
                            const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                            _.map(selectedPricing.wallet_settings, setting => {
                                _.map(setting.wallet_ids, wid => {
                                    if(setting.type == 'percent'){
                                        productWallet[wid] = (walletPoint[cartItem.id] && walletPoint[cartItem.id][wid]) ? parseFloat(walletPoint[cartItem.id][wid]) : (cartItem.quantity * parseFloat((productPrice*setting.max_amount)/100));
                                    }else{
                                        productWallet[wid] = (walletPoint[cartItem.id] && walletPoint[cartItem.id][wid]) ? parseFloat(walletPoint[cartItem.id][wid]) : (cartItem.quantity * parseFloat(setting.max_amount));
                                    }
                                    if (wid != 'cash') {
                                        sumTotal += parseFloat(productWallet[wid]);
                                    } else {
                                        isSetCash = true;
                                        if(setting.type == 'percent'){
                                            totalPrice = cartItem.quantity *  parseFloat((productPrice*setting.max_amount)/100);
                                        }else{
                                            totalPrice = cartItem.quantity * setting.max_amount;
                                        }
                                    }
                                })
                            })
                            if (isSetCash) {
                                let cash = totalPrice - sumTotal;
                                productWallet['cash'] = cash > 0 ? cash : 0;
                            } 
                            // set wallet point
                            tempWalletPoint[cartItem.id] = productWallet;
                        }else{
                            let productPrice = cartItem.product.sell_price;
                            if (_.size(cartItem.product.options) > 0) {
                                _.map(cartItem.product.options, (optionItem) => {
                                    _.map(optionItem.pricing, (pricingOption) => {
                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                            productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                        }
                                    })
                                })
                            }
                            let totalPrice = cartItem.quantity * productPrice;
                            let productWallet = {};
                            
                            productWallet['cash'] = productPrice > 0 ? (productPrice )* cartItem.quantity : 0;
                            tempWalletPoint[cartItem.id] = productWallet;
                        }
                    }
                })
            })
        });
        setWalletPoint(tempWalletPoint);
    }

    const requestPayment = async (type, oid) => {
        const data = { payType: type, purchaseNo: oid };
        return postUrl(`payment`, data)
            .then(result => {
                if (result.status == 0 && result.error && result.error.validate_error) {
                    window.location.reload();
                } else {
                    return result.status;
                }

            }).catch(error => {
                addAlert(JSON.stringify(error.message));
                return false;
            });
    }

    const handleRedeemRp = (event) => {
        let status  = event.target.checked ? true : false;
        setRpRedeem(status);
        calculateRpRedeem(cart ,status);
        calculateTotal(cart , status);
    };

    const submitOrder = async () => {
        calculateRpRedeem(cart);
        setCartError([]);
        let onlySelfPickUp = true;
        _.map(selfPickUpInfo, (merchant, merchantIndex) => {
            if (merchant.self_pickup === false) {
                onlySelfPickUp = false;
            }
        });
        if (!onlySelfPickUp && !state.addressId) {
            // show error to select address
            addAlert(t('checkout.noAddressSelected'));
        }
        else if (!paymentMethod) {
            // show error to select address
            addAlert(t('checkout.noPaymentMethod'));
        }
        // if(state.addressId) {
        else {
            setLoading(true);
            const selectedAddress = _.find(addresses, { 'id': state.addressId });
            // let redeemPoint = [];
            // _.map(redeemWallet, (wAmount, wType) => {
            //     if(wAmount > 0){
            //         _.map(wallets, wallet => {
            //             let name = _.split(wallet.name, '|');
            //             let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
            //             if(display_name === wType){
            //                 redeemPoint = redeemPoint.concat({'wallet_id': wallet.id, 'wallet_amount': wAmount});
            //             }
            //         })
            //     }
            // })
            let orderField = {
                buyer_name: selectedAddress.name,
                buyer_email: selectedAddress.email,
                buyer_mobile: selectedAddress.mobile,
                //recipient: selectedAddress.name ,
                //recipient_email: buyerInfo.email,
                //mobile: selectedAddress.mobile ,
                //country: selectedAddress.country ,
                //zip: selectedAddress.zip ,
                //state: selectedAddress.state ,
                //city: selectedAddress.city ,
                //address: selectedAddress.address,
                //address2: selectedAddress.address2 ,
                remark,
                mall_shipping: mallShipping,
                recipient_source: downlineInfo.source,
                recipient_username: recipient!=''?recipient:downlineInfo.username,
                address_id: state.addressId ? state.addressId : 0,
                // redeem_point: redeemPoint,
                self_pickup: selfPickUpInfo,
                wallet_point: walletPoint,
                cart: cart,
                payment_method: paymentMethod,
            }
            if (selectedAddress) {
                let orderField2 = {
                    recipient: selectedAddress.name,
                    recipient_email: buyerInfo.email,
                    mobile: selectedAddress.mobile !== null ? selectedAddress.mobile : buyerInfo?.mobile,
                    country: selectedAddress.country,
                    zip: selectedAddress.zip,
                    state: selectedAddress.state,
                    city: selectedAddress.city,
                    address: selectedAddress.address,
                    address2: selectedAddress.address2,
                }
                orderField = { ...orderField, ...orderField2 }
            }
            postUrl('orders', orderField)
                .then(async response => {
                    // console.log("response", response);
                    if (response.status === 1) {
                        // redirect to payment page
                        dispatch(updateCartTotal(0));
                        if (response.order_cancelled) {
                            history.push({ pathname: `account/order`, state: { error_msg: t('checkout.orderCancelled') } });
                        } else {
                            if (response.data[0]['purchase_no']) {
                                // SetOid(response.data[0]['purchase_no']);
                                // setPaymentMethodPopUp(true)
                                let oid = encodeURIComponent(response.data[0]['purchase_no']);
                                if (_.includes(['revenuemonster'], paymentMethod)) {
                                    const paymentStatus = await requestPayment(paymentMethod, response.data[0]['purchase_no']);
                                    if (paymentStatus) {
                                        if (_.includes(['revenuemonster'], paymentMethod)) {
                                            getUrl(`${paymentMethod}/generate/${oid}`)
                                                .then(async response => {
                                                    if (response.status === 1) {
                                                        setLoading(false);
                                                        if (paymentMethod === 'revenuemonster') {
                                                            const { code, item } = response.data;

                                                            if (code === 'SUCCESS' && item.url) {
                                                                window.location.href = item.url;
                                                            } else {
                                                                addAlert(response.message);
                                                            }
                                                        }
                                                    } else {
                                                        setLoading(false);
                                                        addAlert(response.message);
                                                    }
                                                })
                                                .catch(error => {
                                                    setLoading(false);
                                                    addAlert(JSON.stringify(error.message));
                                                });
                                        }
                                    }
                                } else {
                                    let apiParams = {
                                        paymentMethod: paymentMethod
                                    }
                                    postUrl(`wallet/${oid}`, apiParams).then((response) => {
                                        setLoading(false);
                                        if (response.status === 1) {
                                            history.push(`/paymentresult/${oid}?source=wallet`);
                                        } else {
                                            addAlert(response.message);
                                        }
                                    })
                                        .catch(error => {
                                            setLoading(false);
                                            addAlert(JSON.stringify(error.message));
                                        });
                                }
                            }
                            // history.push(`/payment/${encodeURIComponent(response.data[0]['purchase_no'])}`);
                        }
                    } else {
                        // setState({ ...state, open: true, error: true, message: response.message });
                        setUpdateCart(!updateCart);
                        setLoading(false);
                        if (response.error) {
                            let newErrorField = {};
                            let displayErrorMsg = [];
                            _.map(response.error, (errorItem, errorIndex) => {
                                setCartError(response.error);
                                newErrorField[errorIndex] = errorItem[0]
                                displayErrorMsg.push(errorItem[0]);
                            })
                            setErrorField(newErrorField);
                            addAlert(_.map(displayErrorMsg).join(' '));
                        } else if (response.message) {
                            addAlert(JSON.stringify(response.message));
                        }
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
        }
    }

    const sendRequest = useCallback((cart) => {
        setLoading(true);
        setRpRedeem(false);
        let apiData = {
            cart: cart,
        }
        // update api 
        postUrl('updateQuantity', apiData)
            .then(response => {
                if (response.status == 1) {
                    setLoading(false);
                } else {
                    callApi();
                    addAlert(response.message)
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }, []);

    const debouncedSendRequest = useMemo(() => {
        return _.debounce(sendRequest, 300);
    }, [sendRequest]);

    const changeQuantity = (condition, merchantId, id, value = 0) => {
        if (condition === 'deduct') {
            let tempCartData = cart[merchantId]
            let index = tempCartData.items.self.findIndex(item => item.id == id);
            let tempData = tempCartData?.items?.self;
            const originaQuantity = tempData[index].quantity;
            tempData[index].quantity = tempData[index].quantity - 1;
            if (tempData[index].quantity === 0) {
                setDeleteId({ dialog: true, selectedeId: [id], originaQuantity: originaQuantity, merchantId: merchantId })
            } else {
                debouncedSendRequest(cart)
            }
            setCart({ ...cart, [merchantId]: tempCartData });
        } else if (condition === 'add') {
            let tempCartData = cart[merchantId]
            let index = tempCartData.items.self.findIndex(item => item.id == id);
            let tempData = tempCartData?.items?.self;
            tempData[index].quantity = tempData[index].quantity + 1;
            setCart({ ...cart, [merchantId]: tempCartData });
            debouncedSendRequest(cart)
        } else if (condition === 'value') {
            let tempCartData = cart[merchantId]
            let index = tempCartData.items.self.findIndex(item => item.id == id);
            let tempData = tempCartData?.items?.self;
            const originaQuantity = tempData[index].quantity;
            tempData[index].quantity = value;
            if (tempData[index].quantity === 0) {
                setDeleteId({ dialog: true, selectedeId: [id], originaQuantity: originaQuantity, merchantId: merchantId });
            } else {
                debouncedSendRequest(cart)
            }
            setCart({ ...cart, [merchantId]: tempCartData });
        } else if (condition === 'restore') {
            let tempCartData = cart[merchantId]
            let index = tempCartData.items.self.findIndex(item => item.id == id);
            let tempData = tempCartData?.items?.self;
            tempData[index].quantity = deleteId.originaQuantity;
            setDeleteId({ dialog: false, selectedeId: [], originaQuantity: 0, merchantId: 0 });
            setCart({ ...cart, [merchantId]: tempCartData });
        }
    }
    const handleDelete = () => {
        const cartIdString = _.join(deleteId.selectedeId, ',');
        deleteUrl(`carts/${cartIdString}`).then(response => {
            if (response.status === 0) {

            } else {
                const newCart = cart;
                _.map(newCart, (merchantItem, merchantIndex) => {
                    let merchantProduct = false;
                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                        newCart[merchantIndex]['items'][shippingChannel] = _.remove(cartDetails, cartItem => {
                            if (!_.includes(deleteId.selectedeId, cartItem.id)) {
                                return cartItem;
                            }
                        })
                        if (_.size(newCart[merchantIndex]['items'][shippingChannel]) !== 0) {
                            merchantProduct = true;
                        }
                    })
                    if (!merchantProduct) {
                        delete newCart[merchantIndex];
                    }
                })
                dispatch(updateCartTotal(cartTotal - 1));
                setCart(newCart);
                setDeleteId({ selectedeId: '', dialog: false });
                calculateTotal(newCart , rpRedeem);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }
    const footerPriceDiplay = (cart, type) => {
        let total_cash_amount = 0;
        let total_wallet_amount = {};
        let display_ap = true;
        let rpRedeemAmount = 0 ;
        _.map(cart, (merchantItem, merchantId) => {
            _.map(merchantItem.items, (cartDetail) => {
                _.map(cartDetail, (cartItem) => {
                    if (cartItem.selected) {
                        rpRedeemAmount = parseFloat(cartItem.quantity * cartItem.max_rp_redeem) + rpRedeemAmount
                        if (cartItem.total_cash > 0) {
                            total_cash_amount = total_cash_amount + (cartItem.total_cash * cartItem.quantity);
                        }
                        if (cartItem.total_wallet == "Not Supoorted") {
                            //do nthing
                            display_ap = false
                            let walletRp = _.find(wallets, { code: 'RP' });
                            if(rpRedeemAmount > 0 && _.size(walletRp) > 0){
                                total_wallet_amount = {
                                    ...total_wallet_amount, [walletRp.id]: total_wallet_amount[walletRp.id] ? total_wallet_amount[walletRp.id] + (rpRedeemAmount) : (rpRedeemAmount)
                                };
                            }
                        } else {
                            let walletAp = _.find(wallets, { code: 'AP' });
                            let walletRp = _.find(wallets, { code: 'RP' });
                            _.map(cartItem.total_wallet, (amount, wid) => {
                                if (walletAp && walletAp.id == wid) {
                                    total_wallet_amount = {
                                        ...total_wallet_amount, [wid]: total_wallet_amount[wid] ? total_wallet_amount[wid] + (amount * cartItem.quantity) : (amount * cartItem.quantity)
                                    };
                                }
                                if(rpRedeemAmount > 0 && _.size(walletRp) > 0){
                                    total_wallet_amount = {
                                        ...total_wallet_amount, [walletRp.id]: total_wallet_amount[walletRp.id] ? total_wallet_amount[walletRp.id] + (rpRedeemAmount) : (rpRedeemAmount)
                                    };
                                }
                            })
                        }
                        
                    }
                })

            })
        })

        if (type == 'default') {
            return (
                <div>
                    {
                        total_cash_amount > 0 || _.size(total_wallet_amount) > 0 ?
                            <Typography style={{ textAlign: 'left', color: '#4E4E4E', fontSize: '12px', textTransform: 'uppercase' }}>{t('checkout.total')}</Typography>
                            :
                            null
                    }
                    {
                        total_cash_amount > 0 &&
                        <Typography style={{ textAlign: 'left', color: '#4E4E4E', fontSize: '17px', fontWeight: '700', textTransform: 'capitalize' }}>
                            <NumberFormat value={(parseFloat(total_cash_amount) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                        </Typography>
                    }
                    {
                        _.size(total_wallet_amount) > 0 && display_ap &&
                        _.map(total_wallet_amount, (amount, wallet_id) => {
                            let wallet_ap = _.find(wallets, { code: 'AP' });
                            let wallet_selected = wallet_ap?.name.split('|');
                            if (wallet_id == wallet_ap.id) {
                                return (
                                    <Typography key={wallet_id} style={{ textAlign: 'left', color: '#ADADAD', fontSize: '14px', textTransform: 'capitalize' }}>
                                        <NumberFormat value={(parseFloat(amount)) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup) * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={`≈ ${i18n.language === 'cn' ? (wallet_selected[1] ? wallet_selected[1] : wallet_selected[0]) : wallet_selected[0]}`} fixedDecimalScale />
                                    </Typography>
                                )

                            }
                        })

                    }
                </div>
            )
        } else if (type == 'wallet') {
            return (
                <div>
                    {
                        display_ap && _.size(total_wallet_amount) > 0 ?
                            <Typography style={{ textAlign: 'left', color: '#4E4E4E', fontSize: '12px', textTransform: 'uppercase' }}>{t('checkout.total')}</Typography>
                            :
                            null
                    }
                    {
                        _.size(total_wallet_amount) > 0 && display_ap &&
                        _.map(total_wallet_amount, (amount, wallet_id) => {
                            let wallet_ap = _.find(wallets, { code: 'AP' });
                            let wallet_selected = wallet_ap?.name.split('|');
                            if (wallet_id == wallet_ap.id) {
                                return (
                                    <Typography key={wallet_id} style={{ textAlign: 'left', color: '#4E4E4E', fontSize: '17px', fontWeight: '700', textTransform: 'capitalize' }}>
                                        <NumberFormat value={((parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={` ${i18n.language === 'cn' ? (wallet_selected[1] ? wallet_selected[1] : wallet_selected[0]) : wallet_selected[0]}`} fixedDecimalScale />
                                    </Typography>
                                )

                            }
                        })
                    }
                    {
                        rpRedeemAmount > 0  && rpRedeem == true &&
                        _.map(total_wallet_amount, (amount, walletId) => {
                            let walletRP = _.find(wallets, { code: 'RP' });
                            let walletSelected = walletRP?.name.split('|');
                            if (walletId == walletRP.id) {
                                return (
                                    <Typography key={walletId} style={{ textAlign: 'left', color: '#ADADAD', fontSize: '14px', textTransform: 'capitalize' }}>
                                        <NumberFormat value={(parseFloat(amount)  * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={`${i18n.language === 'cn' ? (walletSelected[1] ? walletSelected[1] : walletSelected[0]) : walletSelected[0]}`} fixedDecimalScale />
                                    </Typography>
                                )

                            }
                        })

                    }
                </div>
            )
        } else {
            return (
                <div>
                    {
                        total_cash_amount > 0 || _.size(total_wallet_amount) > 0 ?
                            <Typography style={{ textAlign: 'left', color: '#4E4E4E', fontSize: '12px', textTransform: 'uppercase' }}>{t('checkout.total')}</Typography>
                            :
                            null
                    }
                    {
                        total_cash_amount > 0 &&
                        <Typography style={{ textAlign: 'left', color: '#4E4E4E', fontSize: '17px', fontWeight: '700', textTransform: 'capitalize' }}>
                            <NumberFormat value={((parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                        </Typography>
                    }   
                    
                    {
                        rpRedeemAmount > 0  && rpRedeem == true &&
                        _.map(total_wallet_amount, (amount, walletId) => {
                            let walletRP = _.find(wallets, { code: 'RP' });
                            let walletSelected = walletRP?.name.split('|');
                            if (walletId == walletRP.id) {
                                return (
                                    <Typography key={walletId} style={{ textAlign: 'left', color: '#ADADAD', fontSize: '14px', textTransform: 'capitalize' }}>
                                        <NumberFormat value={(parseFloat(rpRedeemAmount)  * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={`${i18n.language === 'cn' ? (walletSelected[1] ? walletSelected[1] : walletSelected[0]) : walletSelected[0]}`} fixedDecimalScale />
                                    </Typography>
                                )

                            }
                        })

                    }
                </div>
            )
        }
    }

    const priceDisplay = (cartItem, merchantId) => {
        let productPrice = cartItem.product.sell_price;
        if (_.size(cartItem.product.options) > 0) {
            _.map(cartItem.product.options, (optionItem) => {
                _.map(optionItem.pricing, (pricingOption) => {
                    if (pricingOption.id === cartItem.product_warehouse_id) {
                        productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                    }
                })
            })
        }
        return (
            <>
                {
                    (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) ?
                        _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
                            if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
                                let pricingText = [];
                                _.map(pricingItem.wallet_settings, (setting, key) => {
                                    let minAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.min_amount / 100) : setting.min_amount);
                                    let maxAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.max_amount / 100) : setting.max_amount);
                                    pricingText[key] = { 'prefix': `${setting.pricing_name} `, 'min_amount': parseFloat(minAmount * cartItem.quantity).toFixed(2), 'max_amount': parseFloat(maxAmount * cartItem.quantity).toFixed(2) };
                                })
                                return (
                                    <Box key={pricingIndex} display="flex" flexDirection="column">
                                        <Grid container spacing={2} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Grid item xs={6} md={6}>
                                                <Typography style={{ color: "#666", fontSize: '13px' }}>
                                                    <NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <FormControl>
                                                    <Input
                                                        type="text"
                                                        value={cartItem.quantity}
                                                        disableUnderline={true}
                                                        fullWidth={true}
                                                        style={{ color: "#666", fontSize: '13px' }}
                                                        // classes={{ input: styles.inputStyle }}
                                                        onChange={({ target }) => {
                                                            const newQuantity = parseInt(target.value) ? parseInt(target.value) : 0;
                                                            changeQuantity('value', merchantId, cartItem.id, newQuantity)
                                                            // setState({ ...cartItem, quantity: newQuantity });
                                                        }}
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <IconButton
                                                                    style={{ color: 'black', backgroundColor: '#FFBF42', borderRadius: 5, width: 12, height: 12 }}
                                                                    onClick={() => changeQuantity('deduct', merchantId, cartItem.id)}
                                                                >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    style={{ color: 'black', backgroundColor: '#FFBF42', borderRadius: 5, width: 12, height: 12 }}
                                                                    onClick={() => changeQuantity('add', merchantId, cartItem.id)}
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {errorField && _.map(errorField, (errorMsg, key) => {
                                                    if (key === 'cart.' + [cartItem.id]) {
                                                        return (
                                                            <Grid key={errorMsg + key} item xs={12} sm={12}>
                                                                <Typography style={{ fontSize: "8px" }} color="error">{errorMsg}</Typography>
                                                            </Grid>
                                                        );
                                                    }

                                                })}
                                            </Grid>
                                        </Grid>

                                        {/* {
                                _.map(pricingText, (data, key) => {
                                    return (
                                        <Typography variant="overline" key={key} style={{lineHeight:1.3}}>
                                            <NumberFormat value={data.min_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={data.prefix} />
                                            {
                                                data.min_amount !== data.max_amount
                                                ?
                                                <NumberFormat value={data.max_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={' - '} />
                                                : null
                                            }
                                        </Typography>
                                    )
                                })
                            } */}
                                    </Box>
                                )
                            } else {
                                return null;
                            }
                        })
                        :
                        <Grid container spacing={2} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item xs={6} md={6}>
                                <Typography style={{ color: "#666", fontSize: '13px' }}>
                                    <NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControl>
                                    <Input
                                        type="text"
                                        value={cartItem.quantity}
                                        disableUnderline={true}
                                        fullWidth={true}
                                        style={{ color: "#666", fontSize: '13px' }}
                                        // classes={{ input: styles.inputStyle }}
                                        onChange={({ target }) => {
                                            const newQuantity = parseInt(target.value) ? parseInt(target.value) : 0;
                                            changeQuantity('value', merchantId, cartItem.id, newQuantity)
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconButton
                                                    style={{ color: 'black', backgroundColor: '#FFBF42', borderRadius: 5, width: 12, height: 12 }}
                                                    onClick={() => { changeQuantity('deduct', merchantId, cartItem.id) }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: 'black', backgroundColor: '#FFBF42', borderRadius: 5, width: 12, height: 12 }}
                                                    onClick={() => changeQuantity('add', merchantId, cartItem.id)}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                }
            </>
        )
    }

    const handlePaymentMethodOpen = () =>{
        getUrl('payment_method  ')
            .then(response => {
                setLoading(false)
                if (response.status == 1) {
                    callApi();
                    setPaymentMethodPopUp(true);
                    setAllowedWallet(response.data)
                } else {
                    setPaymentMethod('')
                    setPaymentMethodPopUp(false)
                    addAlert(t('checkout.changePaymentFail'))
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const handleChangePaymentMethod = (value) => {
        // let totalPrice = cartItem.quantity * productPrice;
        setLoading(true)
        setPaymentMethod(value);
        let apiData = {
            cart: cart,
            paymentMethod: value
        }
        postUrl('update_multipricing', apiData)
            .then(response => {
                if (response.status == 1) {
                    callApi();
                    setRpRedeem(false);
                    setLoading(false);
                } else {
                    setPaymentMethod('')
                    addAlert(t('checkout.changePaymentFail'));
                    setLoading(false);
                    setRpRedeem(false);
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }


    ////////dialog - change address////////
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className={styles.root2} style={{ background: "#E8E8E8" }}>
            <ImgHeader cardDisplay border={false} title={t('title.checkout')} />
            <Grid container spacing={2} style={{ maxWidth: '100%', width: '100%', margin: '0 auto', position: 'relative', top: -25, backgroundColor: '#F4F4F4', borderRadius: '30px 30px 0px 0px' }}>
                <Grid item xs={12}  style={{zIndex:2}}>
                    {/* Address Card  */}
                    <div className='p-lr-20' style={{ marginTop: -60 }}>
                        <div className='pos-relative flex-c-m'>
                            <div style={{ width: '100%', height: 170, borderRadius: 15 }} />
                            <div className='pos-absolute card-color p-all-20 flex-sb flex-col card-text txt-upper'>
                                <>
                                    {
                                        _.size(addresses) > 0 ?
                                            _.map(addresses, (addressItem) => {
                                                if (state.addressId == addressItem.id) {
                                                    return (
                                                        <div className='flex-sb-m w-full' key={addressItem.id}>
                                                            <div>
                                                                <Typography>{addressItem.name ? addressItem.name : '- -'}</Typography>
                                                                <Typography>{addressItem.mobile ? addressItem.mobile : '- -'}</Typography>
                                                            </div>
                                                            <div >
                                                                <IconButton aria-label="settings" style={{ zIndex: 1 }} disabled={fetchingMallShipping} to="/profile-address" component={RouterLink}>
                                                                    <Edit size={24} color={'white'} />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                            :
                                            <div className='flex-sb-m w-full'>
                                                <div>
                                                    <Typography>{'- -'}</Typography>
                                                    <Typography>{'- -'}</Typography>
                                                </div>
                                                <div >
                                                    <IconButton aria-label="settings" style={{ zIndex: 1 }} disabled={fetchingMallShipping} to="/profile-address" component={RouterLink}>
                                                        <Edit size={24} color={'white'} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                    }

                                </>

                                <div className='p-t-40'>
                                    {
                                        _.size(addresses) > 0 ?
                                            _.map(addresses, (addressItem) => {
                                                if (state.addressId == addressItem.id) {
                                                    return (
                                                        <Typography style={{ paddingBottom: 10 }}>{addressItem.address}</Typography>
                                                    )
                                                }
                                            })
                                            :
                                            <Typography style={{ paddingBottom: 10 }}>{t("checkout.noAddressSelected")}</Typography>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} style={{width:'100%',marginTop:10}}>
                    {
                        recipient == '' || recipient == username ?
                            <div style={{marginLeft:'30px',marginRight:'30px'}}>
                                  <Button
                                    variant='outlined'
                                    fullWidth
                                    style={{ borderRadius: 10 }}
                                    onClick={() => { history.push('/edit-recipient') }}
                                    disabled={state.totalSelected > 0 ? (disabledSubmit ? true : false) : true}
                                >
                                    {t('checkout.buyForSomeone')}
                                </Button>

                            </div>
                          
                        :
                            <>
                                <div style={{ display: 'flex', display: 'flex', justifyContent: 'space-between', width: '100%',zIndex: 1,paddingLeft:'40px',paddingRight:'40px' }}>
                                    <div className='card-color2' style={{  marginTop: '-30px', marginLeft: '20px', maxWidth: '18px', maxHeight: '10x' }}>

                                    </div>
                                    <div className='card-color3' style={{ marginTop: '-30px', marginRight: '20px', maxWidth: '18px', maxHeight: '30px' }}>

                                    </div>
                                </div>
                                <div className='card-color-recipient' style={{ marginTop: '-10px', zIndex: 2, marginLeft: '20px', marginRight: '20px', width:'auto', display:'flex', alignItems:'center', paddingLeft:'20px', paddingRight:'20px', flexDirection:'row',justifyContent:'space-between'}}>
                                    <div>
                                        <Typography className={styles.recipientText}>{t('checkout.buyFor',{recipient:recipient})}</Typography>
                                    </div>
                                    <div >
                                        <IconButton aria-label="settings" style={{ zIndex: 1 }} disabled={fetchingMallShipping} onClick={()=>history.push('/edit-recipient', {recipient})} disabled={state.totalSelected > 0 ? (disabledSubmit ? true : false) : true}>
                                            <Edit size={24} color={'white'} />
                                        </IconButton>
                                    </div>
                                </div>
                            </>
                    }
                    
                </Grid>
                
                {
                    state.totalSelected && state.totalSelected != null ?
                        _.map(cart, (merchantItem, merchantId) => {
                            if (merchantId == 'allow_bank') {
                                return null
                            }
                            else {
                                let showMerchant = false;
                                let selfShippingFee = false;
                                let mallShippingFee = false;
                                let shippingFeePrefix = shippingWallet.prefix;
                                let outlet_list = [];
                                let merchant_channel_list = _.find(selfPickUpInfo, { 'id': merchantId });

                                if (_.size(merchantItem.outlets) > 0) {
                                    outlet_list = merchantItem.outlets;
                                }

                                _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                    _.map(cartDetails, cartItem => {
                                        if (cartItem.selected) {
                                            showMerchant = true;
                                            if (shippingChannel === 'self') {
                                                selfShippingFee = true;
                                            }
                                        }
                                    })
                                })
                                _.map(mallShipping, (value, merId) => {
                                    if (merchantId === merId) {
                                        mallShippingFee = true;
                                    }
                                })

                                if (showMerchant) {
                                    return (
                                        <>
                                            <Grid item xs={12} style={{ margin: '0px 30px 0px 30px', display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <StorefrontOutlined fontSize="small" />
                                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 10 }}>{merchantItem.name}</Typography>
                                            </Grid>
                                            {
                                                _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                                    return (
                                                        <Box key={shippingChannel} style={{ paddingBottom: 0 }}>
                                                            {
                                                                _.map(cartDetails, (cartItem, cartIndex) => {
                                                                    if (cartItem.selected) {
                                                                        const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                                        let variant_translate = [];
                                                                        _.map(cartItem.product.options, (option) => {
                                                                            _.map(option.pricing, (pricingOption) => {
                                                                                if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                    if (option.color) {
                                                                                        variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                                    }
                                                                                }
                                                                            })
                                                                        });
                                                                        return (
                                                                            <Box key={cartItem.id} >
                                                                                <ListItem key={cartItem.id} style={{ backgroundColor: 'transparent' }}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={12} sm={12} md={12} >
                                                                                            <Box style={{ display: 'flex', alignItems: 'flex-start', backgroundColor: '#F4F4F4', borderRadius: '15px', margin: '0 20px', boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF' }}>
                                                                                                <Box borderColor="#dee2e6" style={{ width: 80, minWidth: 80, borderRadius: 15, margin: '10px ' }}>
                                                                                                    <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} style={{ display: 'flex', alignItems: 'center', }} component={RouterLink}>
                                                                                                        <WebpImg style={{ height: 80, width: 80, minWidth: 80, borderRadius: 15, objectFit: 'cover' }} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                                    </Link>
                                                                                                </Box>
                                                                                                <Box style={{ paddingLeft: 15, paddingRight: 15, width: '100%' }}>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography className='txt_truncate2 typo-gray' style={{ fontSize: 11, lineHeight: 1.2, margin: '10px 0', height: 'unset', fontWeight: '500' }} >{title_translate}</Typography></Link>
                                                                                                    </Grid>
                                                                                                    {
                                                                                                        _.size(variant_translate) > 0 ?
                                                                                                            <Grid item xs={12}>
                                                                                                                {/* <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography> */}
                                                                                                                {
                                                                                                                    _.map(variant_translate, variantText => {
                                                                                                                        return (
                                                                                                                            <div style={{ borderRadius: 5, boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 3px 0px #FFF', maxWidth: '154px', paddingLeft: '10px', marginBottom: '10px', height: '21px' }}>
                                                                                                                                <Typography variant="caption" className='txt_truncate1' style={{ fontSize: 10, color: theme.palette.gray.chip, fontWeight: '500', maxWidth: '140px', maxHeight: '21px', paddingTop: '3px' }}>{variantText}</Typography>
                                                                                                                            </div>
                                                                                                                            // <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip, fontWeight: '500' }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                                                                        {/* <Typography style={{ color: theme.palette.gray.text }}>x{cartItem.quantity}</Typography> */}
                                                                                                        {priceDisplay(cartItem, merchantId)}
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ListItem>
                                                                            </Box>
                                                                        )
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })
                                                            }
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                                return null;
                            }
                        })
                        :
                        <ListItem>
                            <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                {
                                    state.totalSelected === null ?
                                        <>
                                            <CircularProgress disableShrink />
                                            <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                        </>
                                        :
                                        <>
                                            <Typography variant="caption">{t('checkout.noItem')}</Typography>
                                            <Link underline='none' to={`/cart`} component={RouterLink}>
                                                <Button variant="contained" color="secondary">
                                                    {t('button.goCart')}
                                                </Button>
                                            </Link>
                                        </>
                                }
                            </Box>
                        </ListItem>
                }
            </Grid>
            <Grid container className={styles.paymentContainer} style={{ marginTop: -20 }}>
                <Grid item xs={6} style={{ paddingTop: 20 }}>
                    <Typography className='subtotal-tapo'>{t('checkout.productPayment', { cartCount: state.totalSelected })}：</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', paddingTop: 20 }}>
                    {
                        fetchingMallShipping
                            ?
                            <CircularProgress disableShrink size="1.5rem" />
                            :
                            parseFloat(state.totalPrice).toFixed(2) > 0
                                ?
                                <Typography className='subtotal-tapo'><NumberFormat value={(parseFloat(state.totalPrice) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></Typography>
                                : null
                    }
                </Grid>
                <Grid item xs={6}>
                    <Typography className='subtotal-tapo' >{t('checkout.shippingFee')}：</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {
                        fetchingMallShipping
                            ?
                            <CircularProgress disableShrink size="1.5rem" />
                            :
                            <p className='subtotal-tapo' ><NumberFormat value={((parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></p>
                    }
                </Grid>
                <Grid item xs={6} style={{ paddingBottom: 20 }}>
                    <p className='subtotal-tapo'>{t('checkout.totalPayment')}：</p>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', paddingBottom: 20 }}>
                    {
                        fetchingMallShipping
                            ?
                            <CircularProgress disableShrink size="1.5rem" />
                            :
                            parseFloat(state.totalPrice).toFixed(2) > 0 ?
                                <Typography className='subtotal-tapo'><NumberFormat value={((parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></Typography>
                                : null
                    }
                </Grid>
                <Grid item xs={12} md={12}>
                    <Divider />
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 20, display: 'flex', alignItems: 'center' }}>
                    <Typography className='subtotal-tapo'>{'Payment Method'}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 20 }}>
                    <Typography style={{ color: "#717171", fontSize: '13px', textAlign: 'right', paddingRight: 5 }}>
                        {paymentMethod}
                    </Typography>
                    <IconButton aria-label="paymentMethod" style={{ zIndex: 1, padding: 0 }} disabled={fetchingMallShipping} onClick={() => handlePaymentMethodOpen ()}>
                        <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                    </IconButton>
                </Grid>
                {
                    paymentMethod && amountRpRedeem >0 ?
                    <>
                        <Grid item xs={6} style={{ marginBottom: 120, marginTop: 10 }}>
                            <Typography className='subtotal-tapo'>{t('checkout.rpRedeem',{'amount':amountRpRedeem})}</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', marginBottom: 120 }}>
                            <Switch
                                checked={rpRedeem}
                                onChange={(event) => handleRedeemRp(event)}
                                name="status"
                                color="primary"
                            />
                            {/* {
                                    parseFloat(state.totalPrice).toFixed(2) > 0
                                        ?
                                        <Typography className='subtotal-tapo'><NumberFormat value={(parseFloat(state.totalPrice) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></Typography>
                                        : null
                                } */}

                        </Grid>
                    </>
                    :
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', marginBottom: 120 }}>

                    </Grid>
                }
            </Grid>
            {/* Footer  */}
            <Box className={styles.stickyFooter} style={{ textAlign: 'right', minHeight: 0, height: 100, zIndex:3 }}>
                <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0 20px 0 50px', height: '100%' }}>
                    {
                        paymentMethod ?
                            (_.includes(['revenuemonster'], paymentMethod) ?
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                                    {footerPriceDiplay(cart, 'cash')}
                                </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                                    {footerPriceDiplay(cart, 'wallet')}
                                </div>)
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                                {footerPriceDiplay(cart, 'default')}
                            </div>
                    }

                    <Button variant="contained" size="large" style={{ backgroundColor: state.totalSelected > 0 ? (disabledSubmit ? '#e0e2e2' : theme.palette.primary.main) : '#9FD01A', color: state.totalSelected > 0 ? (disabledSubmit ? '#8e8d8d' : '#F4F4F4') : '#fff' }} onClick={submitOrder} disabled={state.totalSelected > 0 ? (disabledSubmit ? true : false) : true}>
                        {t('button.placeOrder')}
                    </Button>
                </Box>
            </Box>
            <PaymentMethod open={paymentMethodPopUp} onClose={() => setPaymentMethodPopUp(false)} title={'Select Payment Method'} wallets={wallets} total_price={parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)} setPaymentMethod={handleChangePaymentMethod} allow={allowedWallet} ></PaymentMethod>
            <Dialog open={deleteId.dialog} fullWidth onClose={closeUserModal}>
                <DialogTitle onClose={closeUserModal}>
                    <Typography style={{ marginTop: '10px' }}>{t('checkout.deleteCartItemItem')}</Typography>
                </DialogTitle>
                <DialogContent className={styles.dialogContentRoot}>
                    <Grid container spacing={3} direction="column">
                        <Grid item xs={12}>
                            <Typography style={{ padding: '0 10px' }}>{t('checkout.deleteCartItemDialog')}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={styles.dialogActionRoot}>
                    <Button autoFocus onClick={handleDelete} color="primary">
                        {t('button.confirm')}
                    </Button>
                    <Button autoFocus onClick={() => changeQuantity('restore', deleteId.merchantId, deleteId.selectedeId)} color="primary">
                        {t('button.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const DialogTitleWrapper = (props) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();

    return (
        <DialogTitle disableTypography className={classes.dialogTitleWrapperRoot} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseOutlined style={{ color: '#fff' }} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '30px 0 10px 0',
    },
    icon: {
        color: `${theme.palette.button.step} !important `,
    },
    priceStyle: {
        fontSize: 15,
        color: theme.palette.price.main,
        fontWeight: 'bold'
    },
    paymentContainer: {
        paddingLeft: 30,
        paddingRight: 30,
        backgroundColor: '#F4F4F4'
    },
    whiteText: {
        fill: '#fff'
    },
    stepperStyle: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    stickyFooter: {
        width: '100%',
        maxWidth: 420,
        position: 'fixed',
        // Top: 'calc(5% + 60px)',
        bottom: 0,
        backgroundColor: '#f2f2f2',
        boxShadow: '0 -5px 11px 0 #0000001a',
        zIndex: 1,
        minHeight: 70,
    },
    itemBoxStyle: {
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
    firstItemBoxStyle: {
        marginBottom: 20,
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
    formControl: {
        padding: 5
    },
    dialogContentRoot: {
        padding: theme.spacing(2),
    },
    dialogActionRoot: {
        margin: 0,
        padding: theme.spacing(1),
    },
    dialogTitleWrapperRoot: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    imgStyle: {
        height: '100%',
        width: '100%'
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
    container: {
        padding: '0 15px'
    },
    recipientText: {
        color: '#FFF',
        textShadow:'1px 1px 3px rgba(0, 0, 0, 0.10)',
        fontSize:'15px',
        textTransform:'capitalize'
    },
    shippingLine: {
        height: 3,
        width: '100%',
        backgroundPositionX: '-30px',
        backgroundSize: '116px 3px',
        backgroundImage: 'repeating-linear-gradient(45deg,#0f386b,#5388c9 33px,transparent 0,transparent 41px,#e91d0e 0,#f15b50 74px,transparent 0,transparent 82px)',

    }
}));