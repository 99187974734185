import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import { Grid, Typography, Divider, Button, Dialog, DialogTitle, DialogContent, TextField, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import _ from 'lodash';

import { getUrl, postUrl } from '../helper/ApiAction'; 
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Wallets(props) {
    const { t, i18n } = props;
    const styles = useStyles();
    const { source, email, mobile, username } = useSelector(state => state.user);

    const isMountedRef = useRef(null);
    const [inputErrors, setInputErrors] = useState([]);
    const [bonusList, setBonusList] = useState([]);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState([]);
    const [walletList, setWalletList] = useState([]);
    const [walletHistory, setWalletHistory] = useState([]);
    const [walletTransfer, setWalletTransfer] = useState({
        source: '',
        loginMethod: '',
        amount: '',
        walletId: '',
        remark: ''
    });
    const [walletDialog, setWalletDialog] = useState({
        open: false,
        action: '',
        id: 0,
        name: '',
        balance: 0,
        decimal: ''
    });
    const [walletRules, setWalletRules] = useState([]);

    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`wallets/balance`).then(response => {
            setLoading(false);
            if(isMountedRef.current){
                if(response.status === 1){
                    setWalletBalance(response.data);
                }else{
                    let msg = response.data;
                    addAlert(msg, 'error');
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
        getUrl(`wallets`).then(response => {
            setLoading(false);
            if(isMountedRef.current){
                if(response.status === 1){
                    setWalletList(response.data);
                }else{
                    let msg = response.data;
                    addAlert(msg, 'error');
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
        getUrl(`bonus_list`).then(response => {
            if(isMountedRef.current){
                if(response.status === 1){
                    setBonusList(response.data);
                }else{
                    let msg = response.data;
                    addAlert(msg, 'error');
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
        getUrl(`wallets/rules`).then(response => {
            if(isMountedRef.current){
                if(response.status === 1){
                    setWalletRules(response.data);
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const handleWalletDialogOpen = (wallet) => {
        setWalletDialog({
            open: true,
            action: wallet.action,
            id: wallet.id,
            name: wallet.name,
            balance: wallet.balance,
            decimal: wallet.decimal
        });
        if(wallet.action === 'history'){
            setDialogLoading(true);
            getUrl(`wallets/${wallet.id}/history`).then(response => {
                setDialogLoading(false);
                if(response.status === 1){
                    setWalletHistory(response.data);
                }else{
                    let msg = response.data;
                    addAlert(msg, 'error');
                }
            }).catch((error) => {
                setDialogLoading(false);
                let msg = error+"\n"+t('error.contactSupport');
                addAlert(msg, 'error');
            });
        }else{
            setWalletTransfer({
                source: '',
                loginMethod: '',
                amount: '',
                walletId: '',
                remark: ''
            });
        }
    }
    const handleWalletDialogClose = () => {
        setWalletDialog({
            open: false,
            action: '',
            id: 0,
            name: '',
            balance: 0,
            decimal: ''
        });
        setInputErrors([]);
        setWalletHistory([]);
        setWalletTransfer({
            source: '',
            loginMethod: '',
            amount: '',
            walletId: '',
            remark: ''
        });
    }

    const walletDialogTitle = () => {
        let name = _.split(walletDialog.name, '|');
        switch(walletDialog.action){
            case('history'): return <DialogTitle>
                                        {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ' ' + t('wallet.history')}
                                    </DialogTitle>
            case('transferOther'): return   <DialogTitle>
                                                {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ' ' + t('wallet.transferOther')}
                                            </DialogTitle>
            case('transferSelf'): return    <DialogTitle>
                                                {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ' ' + t('wallet.transferSelf')}
                                            </DialogTitle>
            default: return null;
        }
    }
    const walletDialogContent = () => {
        switch(walletDialog.action){
            case('history'): return wallet_history();
            case('transferOther'): return wallet_transfer_other();
            case('transferSelf'): return wallet_transfer_self();
            default: return null;
        }
    }

    const wallet_history = () => {
        return (
            <DialogContent>
                <MaterialTable
                    title=""
                    columns={[
                        { title: t('wallet.type'), field: 'type', render: rowData => <>
                                                                                        {
                                                                                            rowData.type === 'bonus-distributed'
                                                                                            ?
                                                                                            <>
                                                                                            {
                                                                                                _.map(bonusList, bonus => {
                                                                                                    if(bonus.id === rowData.type_id){
                                                                                                        let name = _.split(bonus.name, '|');
                                                                                                        return(
                                                                                                            <Box>
                                                                                                                {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0])}
                                                                                                            </Box>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                            </>
                                                                                            :
                                                                                            <Box>{t('transactionType.'+rowData.type)}</Box>
                                                                                        }
                                                                                    </> },
                        { title: t('wallet.amount'), field: 'amount', render: rowData => <NumberFormat value={rowData.factor*rowData.amount} decimalScale={walletDialog.decimal} fixedDecimalScale={walletDialog.decimal} displayType={'text'} thousandSeparator={true} style={{color: (rowData.factor>0?"green":"red")}} /> },
                        { title: t('wallet.remark'), field: 'remark', render: rowData => rowData.remark?rowData.remark:'-' },
                        { title: t('wallet.createdAt'), field: 'created_at' },
                    ]}
                    data={walletHistory}
                    options={{
                        pageSize: 10,
                    }}
                    style={{ padding: 20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
            </DialogContent>
        )
    }

    const wallet_transfer_other = () => {
        return (
            <DialogContent>
                <Grid container spacing={1} style={{marginBottom: 20}}>
                    {/* <Grid item xs={12} md={6}>
                        <TextField 
                            id="source" 
                            label={t('wallet.source')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.source}
                            helperText={inputErrors.source ? inputErrors.source :''}
                            error={inputErrors.source ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, source: target.value }) }
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField 
                            id="login_method" 
                            label={t('profile.username')+"/"+t('profile.email')+"/"+t('profile.mobile')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.loginMethod}
                            helperText={inputErrors.login_method ? inputErrors.login_method :''}
                            error={inputErrors.login_method ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, loginMethod: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="wallet_id" 
                            label={t('wallet.walletType')}
                            variant="outlined" 
                            fullWidth 
                            select
                            // disabled
                            value={walletTransfer.walletId}
                            helperText={inputErrors.wallet_id ? inputErrors.wallet_id :''}
                            error={inputErrors.wallet_id ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, walletId: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('general.pleaseSelect')}
                        </option>
                        {walletList.map((value, key) => {
                            let name = _.split(value.name, '|');
                            if(walletRules['other'][walletDialog.id].includes(value.id)){
                                return(
                                    <option key={key} value={value.id}>
                                        { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                    </option>
                                )
                            }
                        })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="amount" 
                            label={t('wallet.amount')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.amount}
                            helperText={inputErrors.amount ? inputErrors.amount :''}
                            error={inputErrors.amount ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, amount: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="remark" 
                            label={t('wallet.remark')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.remark}
                            helperText={inputErrors.remark ? inputErrors.remark :''}
                            error={inputErrors.remark ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, remark: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" onClick={transfer_submit}>{t('button.confirm')}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        )
    }

    const wallet_transfer_self = () => {
        return (
            <DialogContent>
                <Grid container spacing={1} style={{marginBottom: 20}}>
                    {/* <Grid item xs={12} md={6}>
                        <TextField 
                            id="source" 
                            label={t('wallet.source')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.source}
                            helperText={inputErrors.source ? inputErrors.source :''}
                            error={inputErrors.source ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, source: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="login_method" 
                            label={t('profile.username')+"/"+t('profile.email')+"/"+t('profile.mobile')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.loginMethod}
                            helperText={inputErrors.login_method ? inputErrors.login_method :''}
                            error={inputErrors.login_method ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, loginMethod: target.value }) }
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="wallet_id" 
                            label={t('wallet.walletType')}
                            variant="outlined" 
                            fullWidth 
                            select
                            // disabled
                            value={walletTransfer.walletId}
                            helperText={inputErrors.wallet_id ? inputErrors.wallet_id :''}
                            error={inputErrors.wallet_id ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, walletId: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('general.pleaseSelect')}
                        </option>
                        {walletList.map((value, key) => {
                            let name = _.split(value.name, '|');
                            if(walletRules['self'][walletDialog.id].includes(value.id)){
                                return(
                                    <option key={key} value={value.id}>
                                        { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                    </option>
                                )
                            }
                        })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="amount" 
                            label={t('wallet.amount')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.amount}
                            helperText={inputErrors.amount ? inputErrors.amount :''}
                            error={inputErrors.amount ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, amount: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="remark" 
                            label={t('wallet.remark')}
                            variant="outlined" 
                            fullWidth 
                            value={walletTransfer.remark}
                            helperText={inputErrors.remark ? inputErrors.remark :''}
                            error={inputErrors.remark ? true : false}
                            onChange={({ target }) => setWalletTransfer({ ...walletTransfer, remark: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" onClick={transfer_submit}>{t('button.confirm')}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        )
    }

    const transfer_submit = () => {
        setLoading(true);
        setInputErrors([]);
        let updateData = []
        if(walletDialog.action == 'transferSelf'){
            updateData = {
                source: source,
                login_method: email?email:(mobile?mobile:(username?username:null)),
                amount: walletTransfer.amount,
                to_wallet_id: walletTransfer.walletId,
                remark: walletTransfer.remark,
            };
        }else if(walletDialog.action == 'transferOther'){
            updateData = {
                // source: walletTransfer.source,
                source: 'local',
                login_method: walletTransfer.loginMethod,
                amount: walletTransfer.amount,
                to_wallet_id: walletTransfer.walletId,
                remark: walletTransfer.remark,
            };
        }
        postUrl(`wallets/${walletDialog.id}/transfer`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1){
                let msg = response.data;
                addAlert(msg, 'success');
                handleWalletDialogClose();
                getUrl(`wallets/balance`).then(response => {
                    if(isMountedRef.current){
                        if(response.status === 1){
                            setWalletBalance(response.data);
                        }else{
                            let msg = response.data;
                            addAlert(msg, 'error');
                        }
                    }
                }).catch((error) => {
                    let msg = error+"\n"+t('error.contactSupport');
                    addAlert(msg, 'error');
                });
            }else{
                let msg = response.data;
                addAlert(msg, 'error');
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
    }

    return (
        <Grid container spacing={1} className={ styles.backgroundBox }>
            <Grid item xs={12}>
                <Typography variant="h6">{t('wallet.myWallet')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {
                walletBalance
                ?
                _.map(walletBalance, (wallet, key) => {
                    let name = _.split(wallet.name, '|');
                    return(
                        <Grid item xs={12} sm={5} key={key} className={ styles.walletBox }>
                            <Grid container style={{ padding: 5 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': '}
                                        <NumberFormat value={wallet.balance} decimalScale={wallet.decimal} fixedDecimalScale={wallet.decimal} displayType={'text'} thousandSeparator={true} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={() => handleWalletDialogOpen({id: wallet.id, action: 'history', name: wallet.name, balance: wallet.balance, decimal: wallet.decimal})}>
                                        {t('wallet.history')} 
                                    </Button>
                                    {
                                        walletRules['other']
                                        ?
                                        _.map(walletRules['other'], (value, key) => {
                                            if(parseInt(key) === parseInt(wallet.id)){
                                                return(
                                                    <>
                                                        |
                                                        <Button onClick={() => handleWalletDialogOpen({id: wallet.id, action: 'transferOther', name: wallet.name, balance: wallet.balance, decimal: wallet.decimal})}>
                                                            {t('wallet.transferOther')} 
                                                        </Button>
                                                    </>
                                                )
                                            }
                                        })
                                        : null
                                    }
                                    {
                                        walletRules['self']
                                        ?
                                        _.map(walletRules['self'], (value, key) => {
                                            if(parseInt(key) === parseInt(wallet.id)){
                                                return(
                                                    <>
                                                        |
                                                        <Button onClick={() => handleWalletDialogOpen({id: wallet.id, action: 'transferSelf', name: wallet.name, balance: wallet.balance, decimal: wallet.decimal})}>
                                                            {t('wallet.transferSelf')} 
                                                        </Button>
                                                    </>
                                                )
                                            }
                                        })
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })
                :
                t('wallet.noWallet')
            }
            <Dialog open={ walletDialog.open } onClose={ handleWalletDialogClose } >
                {walletDialogTitle()}
                {
                    dialogLoading
                    ?
                    <DialogContent>
                        <Box justifyContent="center" display="flex" alignItems="center" style={{padding: 100}}>
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                    :
                    walletDialogContent()
                }
            </Dialog>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    backgroundBox: {
        backgroundColor: "#fff",
        boxShadow: '0 0 6px #bbbbbb', 
        borderRadius: 15,
        padding: "10px",
        margin: "10px",
        width: "95%"
    },
    walletBox: {
        border: "1px solid gray",
        borderRadius: 10,
        textAlign: "center",
        margin: "10px",
        boxShadow: '0 0 2px #bbbbbb'
    }
}));