import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Card, CardContent, Link,Dialog, DialogActions, DialogContent, DialogTitle,Button,Badge,Divider, Container } from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import WebpImg from './Layouts/WebpImg';

import TitleBar from './Layouts/TitleBar';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { FiChevronLeft } from 'react-icons/fi';


export default function Announcement(props) {

    const {id} = useParams();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let history = useHistory();

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [announcements, setAnnouncements] = useState([]);
    const [viewAnnouncement, setViewAnnouncement] = useState("");
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);

    const getAnnouncements = () => {
        getUrl('member_announcements').then(res => {
            if (isMountedRef.current) {
                setAnnouncements(res.data);
                setUnreadAnnouncementCount(res.new_count);
                setLoading(false);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getAnnouncements();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (viewAnnouncement !== "") {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }

            if (_.size(announcements[viewAnnouncement]['users']) === 0) {
                getUrl(`member_announcements/${announcements[viewAnnouncement]['id']}`).then(result => {
                    if (result.status === 1 && isMountedRef.current) {
                        getAnnouncements();
                    }
                }).catch((error) => {
                });
            }
        }
    }, [viewAnnouncement]);

    const handleChange = (event, value) => {
        setPage(value);
    };
    
    const closeAnnouncement = () => {
        setViewAnnouncement("")
        window.location.reload();
    }

    return (
        <Box className={styles.root2} >
            {/*<Box style={{ height: 50, backgroundColor:theme.palette.primary.main, display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', paddingTop:10}}>
                <Link underline='none' onClick={() => history.goBack()} component="button" style={{zIndex:3}} >
                    <FiChevronLeft style={{color:theme.palette.white.main, marginLeft:15}}/>
                </Link>
                <Typography style={{ textAlign: 'center', color: theme.palette.white.main, width:'100%', marginLeft:-34 }}>{t('title.announcements')}</Typography>
            </Box>
            */}
            <TitleBar height={70} title={t('title.announcements')} displayInfo displayQr currencyButton displayCart linkMall back backtext />
            
            <div className='fixed-header-padding'>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{ padding: 15 }}>
                        <Typography style={{ fontSize: 21, color: theme.palette.primary.main }}><b>{t('title.announcements')}</b></Typography>
                    </div>
                    {
                        _.size(announcements) > 0 ?
                    <div>
                        {
                            _.size(announcements) > 0 ?
                                <Grid container spacing={1} justify="center" >
                                    <Pagination count={totalPage} shape="rounded" page={page}
                                        onChange={handleChange}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                component={RouterLink}
                                                to={`/announcement?page=${item.page}`}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Grid>
                                :
                                null
                        }
                    </div>
                    :
                    null
                    }
                </div>
                <div>
                    {
                        _.size(announcements) > 0 ?
                            _.map(announcements, (announcementsItem, annoucementIndex) => {
                                if (id == announcementsItem.id) {
                                    return (
                                        <Card style={{ boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 7px', border:0, borderRadius:0, backgroundColor: _.size(announcementsItem['users']) === 0 ? '#edf1f5' : "white" }} variant="outlined" key={annoucementIndex}>
                                            <CardContent style={{ borderRadius: 0 }}>
                                                <Grid container onClick={() => setViewAnnouncement(annoucementIndex)} style={{ cursor: 'pointer' }}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">{announcementsItem[`title_${i18n.language}`]}</Typography>
                                                        <Typography variant="caption" display="block">{new Date(announcementsItem.updated_at).toLocaleDateString()}</Typography>
                                                        <Typography classes={{ body2: styles.txtTruncate }} variant="body2" component="span"><div dangerouslySetInnerHTML={{ __html: announcementsItem['content'][`${i18n.language}`] }} /></Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    )
                                } else if (id == null) {
                                    return (
                                        <>
                                        <Card style={{ boxShadow: 'rgb(0 0 0 / 7%) 0px -9px 7px', border:0, borderRadius: 0, backgroundColor: _.size(announcementsItem['users']) === 0 ? '#edf1f5' : "white",}} variant="outlined" key={annoucementIndex}>
                                            <CardContent style={{ borderRadius: 0 }}>
                                                <Grid container onClick={() => setViewAnnouncement(annoucementIndex)} style={{ cursor: 'pointer' }}>
                                                    <Grid item xs={12}>
                                                        <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                            <Typography variant="h6" style={{whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden', paddingRight:15}}>{announcementsItem[`title_${i18n.language}`]}</Typography>
                                                            <Typography variant="caption" style={{whiteSpace:'nowrap', fontSize:14}} display="block">{new Date(announcementsItem.updated_at).toLocaleDateString()}</Typography>
                                                        </div>
                                                        <Typography classes={{ body2: styles.txtTruncate }} variant="body2" component="span"><div dangerouslySetInnerHTML={{ __html: announcementsItem['content'][`${i18n.language}`] }} /></Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{height:1, width:'30%', borderBottom:'1px dashed #bbbbbb'}}/>
                                         <p style={{textAlign: 'center', textTransform: 'capitalize', padding:'0 15px', color:'#bbbbbb'}}>{t('transaction.endOfList')}</p>
                                         <div style={{height:1, width:'30%', borderBottom:'1px dashed #bbbbbb'}}/>
                                        </div>
                                        </>
                                    )
                                }
                            })
                            :
                            <Box>
                                <CardContent style={{ height: 350, borderRadius: 0 }}>
                                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
                                        <WebpImg src="/images/general/nodata3.png" alt="nodata" style={{ width: 150 }} />
                                        <Typography variant="h5" style={{color:theme.palette.gray.ltext, fontSize:16}}>
                                            {t('announcement.noAnnouncement')}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                    }

                </div>
            <MuiThemeProvider theme={DialogStyle}>
            <Dialog
                open={viewAnnouncement !== "" ? true : false}
                onClose={() => closeAnnouncement()}
                scroll="body"
                aria-labelledby="view Announcement"
                aria-describedby="view Announcement detail"
            >
                <DialogTitle id="scroll-dialog-title" style={{wordBreak:'break-word'}} >{viewAnnouncement !== "" && announcements ? announcements[viewAnnouncement][`title_${i18n.language}`] : ""}</DialogTitle>
                <DialogContent dividers={false}>
                    <div
                        id="viewAnnouncement"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {viewAnnouncement !== "" && announcements && announcements[viewAnnouncement]['image'] ?
                            // <img src={announcements[viewAnnouncement]['image']['file_name']} style={{ maxWidth: "80vw"}}></img>
                            <WebpImg containerStyle={{ maxWidth: "80vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={announcements[viewAnnouncement]['image']['file_name']} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', maxWidth: "80vw" }} alt="announcement" />
                            : null
                        }
                        {viewAnnouncement !== "" && announcements ?
                            <div dangerouslySetInnerHTML={{ __html: announcements[viewAnnouncement]['content'][`${i18n.language}`] }} style={{wordBreak:'break-word'}} />
                            : null
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() =>  closeAnnouncement()} color="primary">{t("button.close")}</Button>
                </DialogActions>
            </Dialog>
            </MuiThemeProvider>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    txtTruncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        minHeight: '2.86em',
        maxHeight: '4.86rem'
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
}));

const DialogStyle = createMuiTheme({
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                maxWidth:350
            }
        }
    }
});