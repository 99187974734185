import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import { createMuiTheme, MuiThemeProvider, makeStyles, useTheme } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Divider, TextField, Button, Grid, Box, Typography, Link, MenuItem, FormControlLabel, Checkbox, FormControl, FormLabel, Radio, RadioGroup, FormHelperText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { postUrl, getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';

import TitleBarTransparent from './Layouts/TitleBarTransparent';

import './css/styles.css';

function useInput({ type, label, helperText, val }) {
    const [value, setValue] = useState(val ? val : "");
    const [error, setErrorValue] = useState("");
    const input =
        <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
                <TextField fullWidth label={label} error={error ? true : false} helperText={error} variant="outlined" style={{ borderColor: 'transparent' }} value={value} onChange={e => setValue(e.target.value)} type={type} />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Grid>
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

function useSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
                <TextField
                    select
                    label={label}
                    value={value}
                    onChange={({ target }) => setValue(target.value)}
                    variant="outlined"
                    fullWidth
                    error={error ? true : false}
                    helperText={error}
                    style={{ borderColor: 'transparent' }}
                >
                    {data.map((option, key) => (
                        <MenuItem key={key} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    return [value, select, setErrorValue, setValue];
}

function useCheckbox({ label, val, note = '' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" style={{ color: "#808080" }}>{note}</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

export default function Recruit() {

    const styles = useStyles();
    const theme = useTheme();
    const { username } = useSelector(state => state.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    const query = useQuery();

    const [state, setState] = useState({
        referral: username,
        referralErr: '',
    });

    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('register.name') });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('register.email') });
    const [mobile, mobileInput, mobileValidate] = useInput({ type: "text", label: t('register.mobile') });
    const [usernameField, usernameInput, usernameValidate] = useInput({ type: "text", label: t('register.username') });
    const [referral, referralInput, referralValidate, setReferral] = useInput({ type: "text", label: t('register.referral') , val: username });
    const [password, passwordInput, passwordValidate] = useInput({ type: "password", label: t('register.password'), helperText: t('profile.passwordValidation') });
    const [passwordConfirmation, passwordConfirmationInput, passwordConfirmationValidate] = useInput({ type: "password", label: t('register.passwordConfirmation') });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validate = { 
        name: nameValidate, 
        email: emailValidate, 
        mobile: mobileValidate, 
        password: passwordValidate, 
        password_confirmation: passwordConfirmationValidate, 
        username: usernameValidate,
        referral: referralValidate
    };

    const handleSubmit = (evt) => {
        let postData = {};
        setLoading(true);
        evt.preventDefault();

        setErrorMessage("");
        _.map(validate, (valid) => {
            valid("");
        })
        setState({ ...state, referralErr: '' });

        postData = {
            name,
            email,
            mobile,
            referral,
            username: usernameField,
            password,
            password_confirmation: passwordConfirmation,
            checked: true
        }
        
        postUrl(`register`, postData)
            .then((response) => {
                let { status, message, error } = response;
                if (status === 0) {
                    setLoading(false);
                    setErrorMessage(message);
                    _.map(error, (value, key) => {
                        validate[key](value[0]);
                    })
                } else {
                    setSuccessMessage(message);
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    return (
        <Box className={styles.root} >
            <TitleBarTransparent height={70} title={t('profile.recruit')} displayInfo back backtext />
            <div className='fixed-header-padding'>
                <div className='p-all-20'>
                    <Box className='p-b-15 txt-gray'>
                        <Typography variant="h6">
                            {t('profile.recruit')}
                        </Typography>
                    </Box>
                    <div>
                        {
                            successMessage ?
                                <Grid container spacing={2} justify="center" align="center">
                                    <Grid item xs={12}>
                                        <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize="large" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Typography variant="h4" style={{ textAlign: 'center' }}>{successMessage}</Typography> */}
                                        <Typography variant="h4" style={{ textAlign: 'center' }}>{ t('register.recruitSuccessMsg') }</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Box>
                                    <Typography style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
                                    {/* <MuiThemeProvider theme={fieldStyle}> */}
                                    <form onSubmit={handleSubmit} className='pos-relative' >
                                        {usernameInput}
                                        {nameInput}
                                        {emailInput}
                                        {mobileInput}
                                        {referralInput}
                                        {passwordInput}
                                        {passwordConfirmationInput}

                                        <div className='flex-c p-t-25 w-full' style={{ bottom: 20, width: 380, maxWidth: '89vw'}}>
                                            <Button type="submit" style={{ padding: '10px 9px', maxWidth: '100%', borderRadius: 48, }} className=" button-theme txt-upper w-full" >{t('button.register')}</Button>
                                        </div>
                                    </form>
                                    {/* </MuiThemeProvider> */}
                                </Box>
                        }
                    </div>
                </div>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.white.mobileBkg,
        height: 'auto',
        minHeight: '100vh',
    },
}));
