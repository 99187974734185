import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Rating, Pagination, PaginationItem } from '@material-ui/lab';

import StarIcon from '@material-ui/icons/Star';
import PersonIcon from '@material-ui/icons/Person';

import _ from 'lodash';
import WebpImg from './Layouts/WebpImg';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Button, Box, Divider, Avatar } from '@material-ui/core';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Review(productId) {
    const [review, setReview] = useState({});
    const [reviewSort, setReviewSort] = useState('all');
    const [productReviewSummary, setProductReviewSummary] = useState({
        all_count: 0,
        average_rating: 0,
        comment_count: 0,
        total_sold: 0,
        star_count: {
            1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
        }
    });
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);

    let { id } = useParams();
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    id = id ? id : productId;

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`reviews/${id}/summary`)
            .then(reviewSummary => {
                setProductReviewSummary(reviewSummary.data);
            })
            .catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
    }, [id, setLoading, addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`reviews/${id}?sort=${reviewSort}&page=${page}`)
            .then(reviewData => {
                console.log("reviewData", reviewData)
                if (isMountedRef.current) {
                    setLoading(false);
                    setReview(reviewData.data.data);
                    setLastPage(reviewData.data.last_page);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
    }, [id, reviewSort, page, setLoading, addAlert])

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    return (
        <Box maxWidth='100%' margin='0 auto' padding="0 15px" style={{ backgroundColor: '#fff' }}>
            <Box padding={1} display="flex" justifyContent="space-between" alignItems='flex-start'>
                <Typography className={styles.title}>{t('item.allReview',{'reviewCount': productReviewSummary.all_count})}</Typography>
                {/* <Box display="flex" alignItems="center" paddingBottom={1}>
                    <Box display="flex" alignItems='center' >
                        <Button variant='contained' style={{ color: '#EAEAEA', borderRadius: 10 }} disableElevation={true}>
                            <Typography style={{ fontSize: '15px' , textAlign:'center' , color:'#4E4E4E'}}>
                                {t('item.rating')}
                            </Typography>
                            <ExpandMoreIcon style={{ color: '#4E4E4E' }}></ExpandMoreIcon>
                        </Button>
                    </Box>
                </Box> */}
            </Box>
            {
                _.size(review) > 0 ?
                    <Box padding={2}>
                        {
                            _.map(review, (reviewItem,index) => {
                                if(index == review.length -1) {
                                    return (
                                        <Box key={reviewItem.id} >
                                            <Box display="flex" alignItems="flex-start" >
                                                <Avatar style={{ height: 56, width: 56, marginRight: 10 }}>
                                                    <PersonIcon fontSize="default" />
                                                </Avatar>
                                                <Box style={{ width: '100%' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Typography style={{ margin: 0, color: '#4E4E4E', fontSize: 14, fontWeight: 'bold' }}>{reviewItem.anonymous ? `${reviewItem.user.name[0]}*****` : reviewItem.user.name}</Typography>
                                                        <Box style={{alignItems:'center' , display:'flex' , backgroundColor:'#EDEDED' , padding:'0 5px', borderRadius:5}}>
                                                            <StarIcon style={{fontSize:'15px', color:'#FFBF42', paddingRight: 5 }}></StarIcon>
                                                            <Typography style={{ color: '#9F9F9F', fontSize: 13, fontWeight: 400 }}>{(reviewItem.rating).toFixed(1)}</Typography>
                                                        </Box>
                                                    </div>
                                                    <Typography style={{ fontSize: 12, paddingBottom: 10 }}>
                                                        {reviewItem.comment}
                                                    </Typography>
                                                    {/* <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                        <Typography style={{ fontSize: 11, color: '#989898' }}>{reviewItem.created_date_display}</Typography>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography style={{ fontSize: 11, color: '#989898' }}>{t("item.sellerRespond")}</Typography>
                                                            <ExpandMoreIcon></ExpandMoreIcon>
                                                        </div>
                                                    </div> */}
                                                </Box>
                                            </Box>
                                            <div className={styles.container}>
                                                <div className={styles.border} />
                                                <span className={styles.content}>{'END'}</span>
                                                <div className={styles.border} />
                                            </div>
                                        </Box>
                                    )
                                }
                                return (
                                    <Box key={reviewItem.id}>
                                        <Box display="flex" alignItems="flex-start" >
                                            <Avatar style={{ height: 56, width: 56, marginRight: 10 }}>
                                                <PersonIcon fontSize="default" />
                                            </Avatar>
                                            <Box style={{ width: '100%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Typography style={{ margin: 0, color: '#4E4E4E', fontSize: 14, fontWeight: 'bold' }}>{reviewItem.anonymous ? `${reviewItem.user.name[0]}*****` : reviewItem.user.name}</Typography>
                                                    <Box style={{ alignItems: 'center', display: 'flex', backgroundColor: '#EDEDED', padding: '0 5px', borderRadius: 5 }}>
                                                        <StarIcon style={{ fontSize: '15px', color: '#FFBF42', paddingRight: 5 }}></StarIcon>
                                                        <Typography style={{ color: '#9F9F9F', fontSize: 13, fontWeight: 400 }}>{(reviewItem.rating).toFixed(1)}</Typography>
                                                    </Box>
                                                </div>
                                                <Typography style={{ fontSize: 12, paddingBottom: 10 }}>
                                                    {reviewItem.comment}
                                                </Typography>
                                                {/* <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                    <Typography style={{ fontSize: 11, color: '#989898' }}>{reviewItem.created_date_display}</Typography>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography style={{ fontSize: 11, color: '#989898' }}>{t("item.sellerRespond")}</Typography>
                                                        <ExpandMoreIcon></ExpandMoreIcon>
                                                    </div>
                                                </div> */}
                                            </Box>
                                        </Box>
                                        <div className={styles.container}>
                                            <div className={styles.border} />
                                            <div className={styles.border} />
                                        </div>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    :
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                        <WebpImg src="/images/general/nodata3.png" alt="noimage" style={{ width: '150px', maxWidth: '100%' }} />
                        <Typography variant="body2">{t('item.noRatingReview')}</Typography>
                    </Box>

            }
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        color: '#525252',
        margin: 0,
        paddingRight: 7,
        fontSize: 14,
        lineHeight: 2,
        textTransform: 'uppercase'
    },
    subTitle: {
        margin: 0,
        paddingRight: 7,
        fontSize: 18,
        lineHeight: 1.5
    },
    seperator: {
        margin: '0 10px',
        fontSize: 18,
        lineHeight: 1.5,
        color: '#cecece'
    },
    sortingTitle: {
        margin: 0,
        textAlign: 'center',
        fontSize: 11,
        whiteSpace: 'nowrap'
    },
    buttonStyle: {
        width: '90%',
        backgroundColor: '#efefef',
        margin: '0px 10px',
        padding: '5px 0',
        borderRadius: 5,
        border: '1px solid'
    },
    merchantReplyTitle: {
        fontSize: 13,
        margin: 0,
        fontWeight: 'bold'
    },
    container: {
        display: "flex",
        alignItems: "center",
        marginTop:20,
      },
      border: {
        borderBottom: "2px solid lightgray",
        width: "100%"
      },
      content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontSize: 11,
        color: "#9F9F9F"
      }
}));