import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '../helper/Tools';
import { PROJECT_TYPE } from '../configs/Config';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import _ from 'lodash';

import Profile from './Profile';
import Voucher from './Voucher';
import Order from './Order';
import OrderDetail from './OrderDetail';
import Notification from './Notification';
import Integration from './Integration';
import Wallets from './Wallets';
// import Refund from './Refund';
import Wishlist from './Wishlist';
import AdminChat from './AdminChat';
import Chat from './Chat';
import GenealogySponsor from './GenealogySponsor';
import GenealogyPlacement from './GenealogyPlacement';
import Recruit from './Recruit';
import BindSubAccount from './BindSubAccount';
import AccountLink from './AccountLink';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Breadcrumbs, Button, Typography, Container, Grid, Link, Box, Divider, List, ListItem,Collapse, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ChatIcon from '@material-ui/icons/Chat';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export default function Account() {

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    let { page, oid } = useParams();
    let history = useHistory();
    const isMountedRef = useRef(null);
    const query = useQuery();

    const tab = query.get('tab');

    const [state, setState] = useState({
        subPage: 'profile'
    });
    const { name } = useSelector(state => state.user);

    const [iconMedia , setIconMedia]=useState();
    const { addAlert } = useNotificationLoading();
    const [open, setOpen]=useState();

    const handleClick=()=>{
        setOpen(!open);
    }

    const displaySubPage = () => {
        if(page === 'order') {
            if(oid) {
                return <OrderDetail t={t} i18n={i18n} oid={oid} language={i18n.language} />
            } else {
                return <Order t={t} i18n={i18n} language={i18n.language} defaultTab={tab ? tab : null} />
            }
        } else if(page === 'notification') {
            return <Notification t={t} language={i18n.language} />
        } else if(page === 'profile') {
            return <Profile t={t} language={i18n.language} />
        } else if(page === 'integration') {
            return <Integration t={t} language={i18n.language} />
        } else if(page === 'wallets') {
            return <Wallets t={t} i18n={i18n} language={i18n.language} />
        // } else if(page === 'refund') {
        //     return <Refund t={t} i18n={i18n} language={i18n.language} oid={oid}/>
        } else if(page === 'likes') {
            return <Wishlist t={t} i18n={i18n} language={i18n.language} />
        } else if(page ==='customerService'){
            return <AdminChat/>
        } else if(page ==='sellerChat'){
            return <Chat/>
        } else if(page === 'vouchers') {
            return <Voucher t={t} i18n={i18n} language={i18n.language} />
        }else if(page === "sponsor-genealogy"){
            return <GenealogySponsor />
        }else if(page ==="placement-genealogy"){
            return <GenealogyPlacement />
        }else if(page ==="recruit"){
            return <Recruit />
        }else if (page ==="bindSubAccount"){
            return <BindSubAccount/>
        }else if (page ==="accountLink"){
            return <AccountLink/>
        }
    }

    const changePath = path => {
        history.push(`/account/${path}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        // if(_.includes(['profile', 'order', 'notification', 'integration', 'wallets', 'refund'], page)) {
        if(_.includes(['profile', 'order', 'notification', 'integration', 'wallets', 'likes', 'vouchers', 'customerService', 'sellerChat', 'sponsor-genealogy','placement-genealogy', 'recruit','bindSubAccount', 'accountLink'], page)) {
            if(isMountedRef.current) {
                setState(state => ({ ...state, subPage: page }));
            }
        } else {
            if(isMountedRef.current) {
                history.push('/account/profile');
            }
        }

        return () => { isMountedRef.current = false };
    }, [page, history]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`user/landingicon`).then(iconImg => {
            if (isMountedRef.current) {
                setIconMedia(iconImg.data);
            }

        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert]);

    return (
        <Box>
            <Container fixed className={ styles.breadcrumbRoot }>
                <Grid container spacing={1} justify="center">
                    <Grid item xs={10}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <Link underline='none' color="secondary" href="/">
                                { t('title.home') }
                            </Link>
                            <Link underline='none' color="secondary" href="/account/profile">
                                { t('title.account') }
                            </Link>
                            <Typography style={{ fontSize: 12, color: 'light' }}>{ t(`profile.${state.subPage}`) }</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>
            {/* <Box bgcolor="white"> */}
                <Container fixed className={ styles.root }>
                    <Grid container spacing={1} justify="center">
                        <Grid item xs={12} md={2}>
                            <List>
                                <ListItem style={{ paddingBottom: 20 }}>
                                    {_.size(iconMedia) ?
                                        <Avatar variant="rounded" className={styles.rounded} src={iconMedia.file_name} />
                                        :
                                        <Avatar variant="rounded" className={styles.rounded}>
                                            <PersonIcon fontSize="default" />
                                        </Avatar>
                                    }
                                    {/* <Avatar variant="rounded" className={styles.rounded}>
                                            <PersonIcon fontSize="default" />
                                        </Avatar> */}
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle2" style={{ marginLeft: 5 }}>
                                            { name ? name : '-' }
                                        </Typography>
                                        <Button size="small">
                                            <CreateRoundedIcon fontSize="inherit" color="disabled" />
                                            <Typography variant="caption" color="textSecondary">
                                                { t('profile.editProfile') }
                                            </Typography>
                                        </Button>
                                    </Box>
                                </ListItem>
                                <Divider variant="middle" light />
                                <ListItem button onClick={() => changePath('profile')} style={{ marginTop: 20, backgroundColor: state.subPage === 'profile' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <PersonOutlineRoundedIcon fontSize="default" className={ styles.user } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.profile') }
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => changePath('bindSubAccount')} style={{ backgroundColor: state.subPage === 'bindSubAccount' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AccountTreeIcon fontSize="default" className={ styles.bindSubAccount } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.bindSubAccount') }
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => changePath('accountLink')} style={{ backgroundColor: state.subPage === 'accountLink' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AccountTreeIcon fontSize="default" className={ styles.accountLink } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.accountLink') }
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => changePath('likes')}style={{ backgroundColor: state.subPage === 'likes' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <FavoriteBorderIcon fontSize="default" className={ styles.likes } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.likes') }
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => changePath('vouchers')} style={{ backgroundColor: state.subPage === 'voucher' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AssignmentRoundedIcon fontSize="default" className={ styles.voucher } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.vouchers') }
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => changePath('order')} style={{ backgroundColor: state.subPage === 'order' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AssignmentRoundedIcon fontSize="default" className={ styles.order } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.order') }
                                    </Typography>
                                </ListItem>
                                {/* <ListItem button onClick={() => changePath('wallets')}style={{ backgroundColor: state.subPage === 'wallets' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AccountBalanceWalletIcon fontSize="default" className={ styles.wallets } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('wallet.myWallet') }
                                    </Typography>
                                </ListItem> */}
                                <ListItem button onClick={() => changePath('notification')}style={{ backgroundColor: state.subPage === 'notification' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <NotificationsNoneRoundedIcon fontSize="default" className={ styles.notification } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.notification') }
                                    </Typography>
                                </ListItem>
                                {/* <ListItem button onClick={() => changePath('wallets')}style={{ backgroundColor: state.subPage === 'wallets' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AccountBalanceWalletIcon fontSize="default" className={ styles.wallets } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.wallets') }
                                    </Typography>
                                </ListItem> */}
                                <ListItem button onClick={handleClick}>

                                    <Avatar variant="rounded" className={styles.small}>
                                        <ChatIcon fontSize="default" className={styles.chat} />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        {t('profile.chatHistory')}
                                    </Typography>

                                    {open ? <ExpandLess /> : <ExpandMore />}

                                </ListItem>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={styles.nested}>
                                            {/* <ListItemText onClick={() => changePath('customerService')} style={{ backgroundColor: state.subPage === 'customerService' ? '#e0e0e0' : '' }}><Typography variant="caption" style={{ fontWeight: 'bold' }}>{t('profile.customerService')}</Typography></ListItemText> */}
                                            <ListItemText onClick={() => history.push(`/customerservice`)} ><Typography variant="caption" style={{ fontWeight: 'bold' }}>{t('profile.customerService')}</Typography></ListItemText>
                                        </ListItem>
                                        <ListItem button className={styles.nested}>
                                            {/* <ListItemText onClick={() => changePath('sellerChat')} style={{ backgroundColor: state.subPage === 'sellerChat' ? '#e0e0e0' : '' }}><Typography variant="caption" style={{ fontWeight: 'bold' }}>{t('profile.sellerChat')}</Typography></ListItemText> */}
                                            <ListItemText onClick={() => history.push(`/chat`)} ><Typography variant="caption" style={{ fontWeight: 'bold' }}>{t('profile.sellerChat')}</Typography></ListItemText>
                                        </ListItem>
                                    </List>
                                </Collapse>
                                <ListItem button onClick={() => changePath('sponsor-genealogy')} style={{ backgroundColor: state.subPage === 'sponsor-genealogy' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AccountTreeIcon fontSize="default" className={ styles.sponsor } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.sponsorGenealogy') }
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => changePath('placement-genealogy')} style={{ backgroundColor: state.subPage === 'placement-genealogy' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <AccountTreeIcon fontSize="default" className={ styles.placement } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.placementGenealogy') }
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => changePath('recruit')} style={{ backgroundColor: state.subPage === 'recruit' ? '#e0e0e0' : '' }}>
                                    <Avatar variant="rounded" className={styles.small}>
                                        <PersonAddIcon fontSize="default" className={ styles.recruit } />
                                    </Avatar>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        { t('profile.recruit') }
                                    </Typography>
                                </ListItem>
                                {PROJECT_TYPE === "catalog" ?
                                    <ListItem button onClick={() => changePath('integration')}style={{ backgroundColor: state.subPage === 'integration' ? '#e0e0e0' : '' }}>
                                        <Avatar variant="rounded" className={styles.small}>
                                            <StorefrontRoundedIcon fontSize="default" className={ styles.integration } />
                                        </Avatar>
                                        <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                            { t('profile.integration') }
                                        </Typography>
                                    </ListItem>
                                    : null
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            { displaySubPage() }
                        </Grid>
                    </Grid>
                </Container>
            {/* </Box> */}
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'white',
    },
    rounded: {
        color: '#fff',
        borderRadius: 30,
        marginRight: 5,
    },
    small: {
        borderRadius: 30,
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginLeft: 5,
        marginRight: 5,
    },
    user: {
        padding: 5,
        backgroundColor: theme.palette.profile.user,
        color:"#fff"
    },
    order: {
        padding: 5,
        backgroundColor: theme.palette.profile.order,
        color:"#fff"
    },
    voucher: {
        padding: 5,
        backgroundColor: theme.palette.profile.voucher,
        color:"#fff"
    },
    notification: {
        padding: 5,
        backgroundColor: theme.palette.profile.notification,
        color:"#fff"
    },
    wallets: {
        padding: 5,
        backgroundColor: theme.palette.profile.wallets,
        color:"#fff"
    },
    likes: {
        padding: 5,
        backgroundColor: theme.palette.profile.likes,
        color:"#fff"
    },
    integration: {
        padding: 5,
        backgroundColor: theme.palette.profile.integration,
        color:"#fff"
    },
    chat:{
        padding:5,
        backgroundColor:theme.palette.profile.chat,
        color:"#fff"
    },
    nested:{
        paddingLeft:theme.spacing(6),
    },
    sponsor: {
        padding: 5,
        backgroundColor: theme.palette.profile.sponsor,
        color:"#fff"
    },
    placement:{
        padding: 5,
        backgroundColor: theme.palette.profile.placement,
        color:"#fff" 
    },
    recruit:{
        padding: 5,
        backgroundColor: theme.palette.profile.recruit,
        color:"#fff" 
    },
    bindSubAccount:{
        padding: 5,
        backgroundColor: theme.palette.profile.bindSubAccount,
        color:"#fff" 
    },
    accountLink:{
        padding: 5,
        backgroundColor: theme.palette.profile.accountLink,
        color:"#fff" 
    },
}));