import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateCartTotal } from '../actions';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { getUrl, deleteUrl, postUrl } from '../helper/ApiAction';
import _ from 'lodash';
import { PROJECT_TYPE } from '../configs/Config';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Breadcrumbs, TextField, Chip, Button, InputAdornment, Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Box, Hidden, Divider, List, ListItem, LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';

import DeleteIcon from '@material-ui/icons/Delete';
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';
import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css';
import './css/dropdownTreeStyle.css';


export default function MyStore() {

    const { accessToken } = useSelector(state => state.general);
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    const theme = useTheme();

    const [state, setState] = useState({
        dialogShow: false,
        removeCartId: [],
        syncCartIndex: '',
        storeIndex: '',
        marginRate: '',
    });
    const [cart, setCart] = useState(null);
    const [currencyData, setCurrencyData] = useState(null);
    const [allCategories, setAllCategories] = useState({});
    const [categories, setCategories] = useState([]);
    const [userStores, setUserStores] = useState(null);
    const [errorField, setErrorField] = useState({});
    const [selectedCategory, setSelectedCategory] = useState({});
    const [page, setPage] = useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
        getUrl('mystore/products?page=' + value).then(cartList => {
            setCart(cartList.data);
            dispatch(updateCartTotal(cartList.data.total));
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    };

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (isMountedRef.current) {
            setLoading(false);
            getUrl('mystore/products').then(cartList => {
                setCurrencyData(cartList.currency_data);
                setCart(cartList.data);
                setUserStores(cartList.stores);
                dispatch(updateCartTotal(cartList.data.total));
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isMountedRef.current && state.storeIndex !== "") {
            if (_.size(allCategories[userStores[state.storeIndex].platform_code]) === 0) {
                setCategories([]);
                setLoading(true);
                getUrl(`mystore/categories/${userStores[state.storeIndex].platform_code}`).then(response => {
                    setLoading(false);
                    if (response) {
                        setAllCategories({ ...allCategories, [userStores[state.storeIndex].platform_code]: response.data });
                        setCategories(response.data);
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                setCategories(allCategories[userStores[state.storeIndex].platform_code]);
            }
        }
        // eslint-disable-next-line
    }, [state.storeIndex]);

    const removeCart = async () => {
        const { removeCartId } = state;
        if (removeCartId) {
            const cartIdString = _.join(removeCartId, ',');
            deleteUrl(`carts/${cartIdString}`).then(response => {
                if (response.status === 0) {

                } else {
                    handleChangePage(null, page);
                    setState({ ...state, dialogShow: false, removeCartId: [] });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const syncProductToMall = () => {
        let apiData = {
            store_id: userStores[state.storeIndex].id,
            margin_rate: state.marginRate,
            category_id: selectedCategory.id,
            cart_id: cart.data[state.syncCartIndex].id
        };

        postUrl('sync_product', apiData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                setCart({ ...cart, data: { ...cart.data, [state.syncCartIndex]: response.data } });
                setState({ ...state, syncCartIndex: '' });
                addAlert(response.message, 'success');

            } else {
                if (response.error) {
                    let newErrorField = {};
                    let displayErrorMsg = [];
                    _.map(response.error, (errorItem, errorIndex) => {
                        newErrorField[errorIndex] = errorItem[0]
                        displayErrorMsg.push(errorItem[0]);
                    })
                    setErrorField(newErrorField);
                    addAlert(response.message + ' ' + _.map(displayErrorMsg).join(' '));
                } else {
                    addAlert(response.message);
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const dropdownTreeOnChange = (currentNode, selectedNodes) => {
        setSelectedCategory(currentNode);
    }

    const DropdownTreeComponent = useMemo(() =>
        <DropdownTreeSelect
            data={categories}
            mode="radioSelect"
            className="mdl-demo"
            onChange={dropdownTreeOnChange}
            keepTreeOnSearch
            keepChildrenOnSearch
            keepOpenOnSelect
            texts={{ placeholder: t('myStore.category') }}
        />
        , [categories]);

    const syncProductTitle = () => {
        if (state.syncCartIndex !== "") {
            let cart_product = cart.data[state.syncCartIndex].product;
            let titles = _.split(cart_product.title, '|');
            let title_translate = i18n.language === 'cn' ? (titles[1] ? titles[1] : titles[0]) : titles[0];
            let store = userStores[state.storeIndex];
            let platformName = store.platform.name;
            return (
                <Grid container>
                    <Grid item xs={12} md={3} style={{ padding: 15 }}>
                        <WebpImg className={styles.imgStyle} alt={cart_product.id} src={cart_product.images_array[0] ? cart_product.images_array[0] : '/images/logo.png'} />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>{title_translate}</Typography>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: theme.palette.price.main }}><NumberFormat value={parseFloat(cart_product.sell_price).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} /></Typography>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" style={{ marginTop: '20px', marginBottom: '5px' }}>{t('myStore.selectPlatformCategory', { platform: platformName })}</Typography>
                            {_.size(categories) > 0 ?
                                <>{DropdownTreeComponent}</>
                                : <LinearProgress />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" style={{ marginTop: '20px', marginBottom: '5px' }}>{t('myStore.setMarginRate')}</Typography>
                            <TextField id="margin_rate" label={t('myStore.marginRate')} variant="outlined" placeholder="10"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onChange={({ target }) => setState({ ...state, marginRate: target.value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return '';
    }

    return (
        <Box style={{ paddingTop: '2%' }}>
            <Grid container justify="center" className={styles.breadcrumbRoot} style={{ margin: '0', maxWidth: '100%' }}>
                <Grid item xs={10} md={10} className={styles.breadcrumbRoot} style={{ maxWidth: '100%' }}>
                    <Grid container style={{ marginBottom: '10px' }}>
                        <Breadcrumbs separator="›" maxItems={2} aria-label="breadcrumb" style={{ fontSize: 12, padding: '0 10px' }}>
                            <Link underline='none' color="secondary" href="/">
                                {t('title.home')}
                            </Link>
                            <Typography style={{ fontSize: 12, color: 'light' }}>{PROJECT_TYPE === "catalog" ? t('title.myStore') : t('title.cart')}</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>

            <Box className={styles.root}>
                <Grid container spacing={1} justify="center">
                    {accessToken ? null :
                        <Grid item xs={12} sm={10}>
                            <Alert
                                severity="warning"
                                action={
                                    <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                                        <Button variant="contained" size="small" color="secondary">
                                            <Typography variant="overline">{t('cart.loginNow')}</Typography>
                                        </Button>
                                    </Link>
                                }
                            >
                                {t('cart.noLogin')}
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} sm={10}>
                        <Hidden smDown>
                            <List>
                                {_.size(cart) > 0 && cart !== null && _.size(cart.data) > 0 ?
                                    _.map(cart.data, (cartItem, cartIndex) => {
                                        const titles = _.split(cartItem.product.title, '|');
                                        const title_translate = i18n.language === 'cn' ? (titles[1] ? titles[1] : titles[0]) : titles[0];
                                        let variant_translate = [];
                                        let productAvailable = cartItem.product.status === 1 && cartItem.product.merchant_company_status === 1 ? 1 : 0;

                                        return (
                                            <Box border={1} key={cartItem.id} classes={{ root: styles.itemBoxStyle }}>
                                                <ListItem style={{ backgroundColor: 'white' }}>
                                                    <Grid container style={{ alignItems: 'center' }}>
                                                        <Grid item xs={1} style={{ padding: 10 }}>
                                                            <Box border={1} borderColor={theme.palette.gray.border}>
                                                                <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}>
                                                                    <WebpImg className={styles.imgStyle} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                </Link>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4} style={{ padding: 10 }}>
                                                            <Grid item xs={12}>
                                                                <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography variant="caption" style={{ fontWeight: 'bold' }}>{title_translate}</Typography></Link>
                                                            </Grid>
                                                            {
                                                                _.size(variant_translate) > 0 ?
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                        {
                                                                            _.map(variant_translate, variantText => {
                                                                                return (
                                                                                    <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                    : null
                                                            }
                                                        </Grid>
                                                        <Grid item xs={2} style={{ padding: 10 }}>
                                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('cart.price')}</Typography>
                                                                {_.size(cartItem.product.multi_pricings) > 0 ?
                                                                    _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
                                                                        if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
                                                                            return (
                                                                                <Box key={pricingIndex} display="flex" flexDirection="column">
                                                                                    <Typography variant="overline">
                                                                                        <NumberFormat value={(pricingItem.cash_amount).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
                                                                                    </Typography>
                                                                                    {pricingItem.wallet_type && parseFloat(pricingItem.wallet_amount) > 0 ?
                                                                                        <Typography variant="overline">
                                                                                            <NumberFormat value={(parseFloat(pricingItem.wallet_amount)).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${pricingItem.wallet_type} `} />
                                                                                        </Typography>
                                                                                        : null
                                                                                    }
                                                                                </Box>
                                                                            )
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                    :
                                                                    _.size(cartItem.product.options) > 0 ?
                                                                        _.map(cartItem.product.options, (optionItem) => {
                                                                            return _.map(optionItem.pricing, (pricingOption) => {
                                                                                let productPrice = cartItem.product.sell_price;
                                                                                if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                    productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                                                                    return (
                                                                                        <Typography key={pricingOption.id} variant="h6" style={{ color: theme.palette.price.main }}>
                                                                                            <NumberFormat value={(productPrice).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
                                                                                        </Typography>
                                                                                    )
                                                                                }

                                                                            })
                                                                        })
                                                                        :
                                                                        <Typography variant="h6" style={{ color: theme.palette.price.main }}>
                                                                            <NumberFormat value={(cartItem.product.sell_price).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
                                                                        </Typography>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4} style={{ padding: 10 }}>
                                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('myStore.syncButtons')}</Typography>
                                                                {_.size(userStores) > 0 ?
                                                                    _.map(userStores, (store, storeIndex) => {
                                                                        if (_.includes(cartItem.sync_malls_array, store.id)) {
                                                                            return (
                                                                                <Button key={store.id} variant="contained" size="small" style={{ fontSize: '10px', marginBottom: '5px', cursor: 'not-allowed', marginRight: '5px' }} onClick={() => addAlert(t('myStore.synced'), 'info')} color="primary">{store.store_name} ({store.platform.name})</Button>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <Button key={store.id} variant="contained" size="small" style={{ fontSize: '10px', marginBottom: '5px', color: "gray", marginRight: '5px' }} onClick={() => setState({ ...state, syncCartIndex: cartIndex, storeIndex: storeIndex })}>{store.store_name} ({store.platform.name})</Button>
                                                                            )
                                                                        }
                                                                    })
                                                                    : 
                                                                    <Link underline='none' color="inherit" to="/account/integration" component={RouterLink}>
                                                                        <Button variant="contained" size="small" color="secondary">
                                                                            <Typography variant="overline">{t('myStore.connectMall')}</Typography>
                                                                        </Button>
                                                                    </Link>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={1} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                            <Button
                                                                variant="text"
                                                                onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                color="secondary"
                                                                fullWidth
                                                            >
                                                                <DeleteIcon />
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                            <Typography variant="caption" color="error">{t('myStore.productStatus')}: {t('myStore.productStatusCode.' + productAvailable)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </Box>
                                        )
                                    })
                                    :
                                    <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                        {cart === null ?
                                            <>
                                                <CircularProgress disableShrink />
                                                <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                            </>
                                            :
                                            <Box>
                                                <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/general/empty-cart2.png'} style={{ width: '150px', maxWidth: '50%' }} />
                                                <Typography style={{ textAlign: 'center', paddingLeft: '12px' }}>{t('general.noProduct')}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </List>
                        </Hidden>
                        <Hidden mdUp>
                            <List>
                                <Divider />
                                {_.size(cart) > 0 && cart !== null && _.size(cart.data) > 0 ?
                                    _.map(cart.data, (cartItem, cartIndex) => {
                                        const titles = _.split(cartItem.product.title, '|');
                                        const title_translate = i18n.language === 'cn' ? (titles[1] ? titles[1] : titles[0]) : titles[0];
                                        let variant_translate = [];
                                        let productAvailable = cartItem.product.status === 1 && cartItem.product.merchant_company_status === 1 ? 1 : 0;

                                        return (
                                            <Box key={cartItem.id} style={{ backgroundColor: '#fff' }}>
                                                <ListItem style={{ padding: '0' }}>
                                                    <Grid container style={{ padding: '5px' }}>
                                                        <Grid item xs={4} style={{ padding: 10 }}>
                                                            <Box border={1} borderColor={theme.palette.gray.border}>
                                                                <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}>
                                                                    <WebpImg className={styles.imgStyle} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                </Link>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={8} classes={{ root: styles.itemContainerStyle }}>
                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                                                                <Grid>
                                                                    <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography variant="caption">{title_translate}</Typography></Link>
                                                                </Grid>

                                                                <Button
                                                                    style={{ minWidth: 'unset', width: 'unset' }}
                                                                    variant="text"
                                                                    onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                    color="secondary"
                                                                    fullWidth
                                                                >
                                                                    <DeleteIcon />
                                                                    {/* <Typography variant="overline">{ t('cart.remove') }</Typography> */}
                                                                </Button>
                                                            </Box>
                                                            {_.size(variant_translate) > 0 ?
                                                                <Grid item xs={12}>
                                                                    <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                    {
                                                                        _.map(variant_translate, variantText => {
                                                                            return (
                                                                                <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                                : null
                                                            }
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" style={{ color: theme.palette.price.main, fontSize: '15px' }}>
                                                                    <NumberFormat value={parseFloat(cartItem.product.sell_price).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {_.size(userStores) > 0 ?
                                                                    _.map(userStores, (store, storeIndex) => {
                                                                        if (_.includes(cartItem.sync_malls_array, store.id)) {
                                                                            return (
                                                                                <Button key={store.id} variant="contained" size="small" style={{ fontSize: '10px', marginBottom: '5px', cursor: 'not-allowed', marginRight: '5px' }} onClick={() => addAlert(t('myStore.synced'), 'info')} color="primary">{store.store_name} ({store.platform.name})</Button>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <Button key={store.id} variant="contained" size="small" style={{ fontSize: '10px', marginBottom: '5px', color: "gray", marginRight: '5px' }} onClick={() => setState({ ...state, syncCartIndex: cartIndex, storeIndex: storeIndex })}>{store.store_name} ({store.platform.name})</Button>
                                                                            )
                                                                        }
                                                                    })
                                                                    :
                                                                    <Link underline='none' color="inherit" to="/account/integration" component={RouterLink}>
                                                                        <Button variant="contained" size="small" color="secondary">
                                                                            <Typography variant="overline">{t('myStore.connectMall')}</Typography>
                                                                        </Button>
                                                                    </Link>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                <Typography variant="caption" color="error">{t('myStore.productStatus')}: {t('myStore.productStatusCode.' + productAvailable)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <Divider />
                                            </Box>
                                        )
                                    })
                                    :
                                    <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                        {cart === null ?
                                            <>
                                                <CircularProgress disableShrink />
                                                <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                            </>
                                            :
                                            <Box>
                                                <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/general/empty-cart2.png'} style={{ width: '150px', maxWidth: '50%' }} />
                                                <Typography style={{ textAlign: 'center', paddingLeft: '12px' }}>{t('general.noProduct')}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </List>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Dialog
                            open={state.dialogShow}
                            onClose={() => { setState({ ...state, dialogShow: false, marginRate: '' }); setSelectedCategory({}) }}
                            aria-labelledby="draggable-dialog-title"
                        >
                            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                {t('myStore.removeTitle')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {t('myStore.removeContent')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            dialogShow: false,
                                            removeCartId: [],
                                            marginRate: ''
                                        }); setSelectedCategory({})
                                    }}
                                    color="default"
                                >
                                    <Typography variant="overline">{t('button.cancel')}</Typography>
                                </Button>
                                <Button onClick={removeCart} color="secondary">
                                    <Typography variant="overline">{t('button.confirm')}</Typography>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
                <Dialog
                    open={state.syncCartIndex !== "" ? true : false}
                    onClose={() => { setState({ ...state, syncCartIndex: '', marginRate: '' }); setSelectedCategory({}) }}
                    aria-labelledby="dialog-sync-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle id="sync_title">
                        {state.storeIndex !== "" ?
                            t('myStore.syncMall', { 'mall': userStores[state.storeIndex].store_name + ' - ' + userStores[state.storeIndex].platform.name })
                            : null
                        }
                    </DialogTitle>
                    <DialogContent>
                        {syncProductTitle()}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => { setState({ ...state, syncCartIndex: '', marginRate: '' }); setSelectedCategory({}) }} color="default">
                            <Typography variant="overline">{t('button.cancel')}</Typography>
                        </Button>
                        <Button onClick={syncProductToMall} color="secondary">
                            <Typography variant="overline">{t('button.sync')}</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
                {_.size(cart) > 0 && cart !== null && _.size(cart.data) > 0 ?
                    <Grid container spacing={1} justify="center">
                        <Pagination count={cart.last_page} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />
                    </Grid>
                    : null
                }
            </Box>
        </Box >
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
    },
    itemContainerStyle: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    imgStyle: {
        height: '100%',
        width: '100%'
    },
    itemBoxStyle: {
        marginBottom: 10,
        borderRadius: 20,
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
}));