import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react'; //,{ useState, useEffect, useRef, createRef } 
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Link, Menu, MenuItem, makeStyles, useTheme } from '@material-ui/core';
import { Language } from '@material-ui/icons';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { FiChevronLeft } from 'react-icons/fi';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { changeCurrency as reduxChangeCurrency, changeLanguage as reduxChangeLanguage } from '../../actions';
import { LOCAL_URL } from '../../configs/Config';
import useNotificationLoading from '../../helper/useNotificationLoading';


export default function TitleBar(props) {
    const { title, buttonText, imageDisplay=true, imageLongDisplay=false, src=null, height = 150, back = false, backUrl, children, displayQr = false, normalDisplay = false, cardDisplay = false, menuButton = false, displayInfo = false, logoIcon = false, pagetitle = false, backtext = false, displayUsername = false, currencyButton = false,border = false, likeButton = false, likeFunction, languageButton = true } = props;

    const [showQr, setShowQr] = useState(false);
    const [qrUrl, setQrUrl] = useState("");

    const theme = useTheme();
    const classes = useStyles();
    const { id, username, landing_icon, max_rank: rank, max_mrank, contract, expiry_date, account_link } = useSelector(state => state.user);
    const { cartTotal, accessToken, currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    const history = useHistory();
    const [contractNameDisplay, setContractNameDisplay] = useState('');
    const shareLink = useRef(null);
    const { t,i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const [showIshoppingLink, setShowIshoppingLink] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [languageEI, setLanguageEI] = React.useState(null);
    const Lopen = Boolean(languageEI);

    useEffect(() => {
        let name = '-';
        if (contract) {
            _.map(contract, (corder) => {
                if (corder.status === 20) {
                    name = corder.contract_name_display;
                }
            })
        }
        setContractNameDisplay(name);
    }, [contract]);

    const showQR = useCallback(() => {
        setQrUrl(`${LOCAL_URL}/register?r=${btoa(id)}`);
        setShowQr(true);
    }, [id]);

    const copyShareLink = useCallback((e) => {
        shareLink.current.select();
        document.execCommand('copy');
        e.target.focus();

        addAlert(t('profile.copySuccess'), "success");
    }, [id]);

    const daysLeft = expiry_date => {
        var today = new Date();

        expiry_date = expiry_date.substring(0, 10);
        var expiry = new Date(expiry_date);

        if (expiry <= today) return 0;

        var diffInTime = expiry.getTime() - today.getTime();
        return Math.ceil((diffInTime / (1000 * 3600 * 24)));
    }

    const formatDate = date => {
        date = date.substring(0, 10);
        let theDate = new Date(date);
        let offset = theDate.getTimezoneOffset();
        theDate = new Date(theDate.getTime() - (offset * 60 * 1000));
        return theDate.toISOString().substring(0, 10);
    }

    const changeCurrency = (event) => {
        dispatch(reduxChangeCurrency(event.target.value));
    };
    const handleLanguageClick = (event) => {
        setLanguageEI(event.currentTarget);

    };
    const handleLanguageClose = () => {
        setLanguageEI(null);
    };

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>
            <div className='pos-relative flex-m flex-col w-full'>
                {
                    imageDisplay &&
                    <img src={src !== null ? src : '/images/background/header.png'} alt="header-bkg" className='w-full' style ={{borderRadius: border?'0 0 60px 60px':null}}/>
                }
                {
                    imageLongDisplay &&
                    <img src={src !== null ? src : '/images/background/header.png'} alt="header-bkg" className='w-full' style ={{borderRadius: border?'0 0 60px 60px':null, height: 300, objectFit: 'cover'}}/>
                }
                {/*  style={{ borderRadius: '0 0 60px 60px' }} /> */}
                <div className='clwhite pos-absolute w-full p-lr-25 p-t-20'>
                    <div className='w-full flex-sb-m'>
                        {
                            backUrl
                                ?
                                <Link underline={"none"} to={backUrl} component={RouterLink} className={classes.backIconStyle}>
                                    <FiChevronLeft className={"clwhite fs-28"} />
                                </Link>
                                :
                                <div onClick={() => history.goBack()} className={classes.backIconStyle}>
                                    <FiChevronLeft className={imageDisplay ? "clwhite fs-28" : "clgrey fs-28"} />
                                </div>
                        }
                        {/* {menuButton &&
                    
                    } */}
                        <div className='flex-sb-m'>
                            {
                                languageButton &&
                                <div onClick={handleLanguageClick} style={{ cursor: 'pointer' }}>
                                    {/* <IconButton aria-label="settings" onClick={handleLanguageClick}> */}
                                    <Language style={{ width: 28, height: 28, color: 'white' }} />
                                    {/* </IconButton> */}
                                </div>

                            }
                            {
                                menuButton &&
                                <div onClick={children} style={{ cursor: 'pointer' , marginLeft: 10}}>
                                    <p className='clwhite txt-title' style={{ fontSize: 18, fontWeight: 'lighter' }}>{buttonText}</p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* {
                        <Button
                        sx={{ borderRadius: 10 }}
                        onClick={handleLanguageClick}
                        variant="text"
                        startIcon={<Language style={{ width: 28, height: 28, color: '#0000005e' }} />}
                        style={{ width: '130px', maxHeight: '70px' }}
                    >
                    </Button>
                    } */}
                   
                    {
                        normalDisplay &&
                        <div className='fs-21 txt-center p-t-30'>
                            <b>{title}</b>
                        </div>
                    }
                    {
                        cardDisplay &&
                        <div className='fs-21 txt-center p-t-20'>
                            <b>{title}</b>
                        </div>
                    }
                    {
                        likeButton &&
                        <div onClick={likeFunction} style={{ paddingLeft: 0, cursor: 'pointer' }}>
                            <FavoriteBorderIcon style={{ fontSize: '10px' }} />
                        </div>
                    }
                    {
                        likeButton &&
                        <div onClick={likeFunction} style={{ paddingLeft: 0, cursor: 'pointer' }}>
                            <FavoriteBorderIcon style={{ fontSize: '10px' }} />
                        </div>
                    }
                </div>
                <Menu
                    anchorEl={languageEI}
                    id="account-menu"
                    open={Lopen}
                    onClose={handleLanguageClose}
                    onClick={handleLanguageClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    disableScrollLock={true}
                >
                    <MenuItem onClick={() => changeLanguage('en')}>{'ENG'}</MenuItem>
                    <MenuItem onClick={() => changeLanguage('cn')}>{'中文'}</MenuItem>
                </Menu>
            </div>
       
        </>
    )
}

const useStyles = makeStyles(theme => ({
    titleContainerWrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center', 
        padding: 5,  
        borderRadius: 48, 
        width: 35, 
        height: 35,
        aspectRatio: 1/1,
    },
    titleWrapperStyle: {
        flexGrow: 1
    },
    titleStyle: {
        textAlign: 'center',
        color: theme.palette.white.main
    },
    rankWrapperStyle: {
        // border: '3px solid',
        // borderColor: theme.palette.secondary.main,
        padding: 3,
        height: 60,
        width: 60,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: 'rgb(168,120,33)',
        // background: 'linear-gradient(90deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)'
    },

    rankTitleStyle: {
        // color: theme.palette.secondary.main,
        fontSize: 36,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: '#152a43',
        height: 52,
        width: 52,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '-webkit-linear-gradient(135deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)',
        WebkitBackgroundClip: ' text',
        WebkitTextFillColor: 'transparent',
    },
    dividerStyle: {
        padding: '0 10px'
    },
    usernameStyle: {
        fontSize: 15,
        color: theme.palette.white.main,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    infoStyle: {
        color: theme.palette.white.main,
        fontSize: 11,
        letterSpacing: 1
    },
    large: {
        width: 56,
        height: 55,
    },
    badgeStyle: {
        backgroundColor: '#9FD01A',
        color: "#fff"
    }
}));