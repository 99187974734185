import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateCartTotal } from '../actions';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { getUrl, deleteUrl, putUrl, postUrl } from '../helper/ApiAction';
import _ from 'lodash';

import { makeStyles, useTheme, createMuiTheme } from '@material-ui/core/styles';
import { CircularProgress, Stepper, Step, StepLabel, Breadcrumbs, Checkbox, FormControl, Input, InputAdornment, Container, Chip, Button, IconButton, Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Box, Hidden, Divider, List, ListItem, FormControlLabel, ThemeProvider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Remove, Add, Delete, Storefront, FavoriteBorderRounded, FavoriteRounded, DeleteOutlineRounded, FavoriteOutlined } from '@material-ui/icons';
import { Circle, CheckCircle } from 'react-feather';

import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';
import ImgHeader from './Layouts/ImgHeader';

export default function CartNew() {
    const styles = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    let history = useHistory();

    const { t, i18n } = useTranslation();
    const { accessToken, cartTotal } = useSelector(state => state.general);
    const { rank: user_rank } = useSelector(state => state.user);
    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const [state, setState] = useState({
        checkAll: false,
        dialogShow: false,
        removeCartId: [],
        moveToMyLikes: [],
        totalSelected: 0,
        totalPrice: 0,
        totalWalletPrice: {},
        open: false,
        error: false,
        message: '',
    });
    const [cart, setCart] = useState(null);
    const [currencyData, setCurrencyData] = useState(null);
    const [wallets, setWallets] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const [current, setCurrent] = useState("manage");

    let currency_code = [];
    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    let cartItemArray = [];

    // ----------------------------- API ----------------------------------------
    useEffect(() => {
        getUrl('wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        if (accessToken && isMountedRef.current) {
            setLoading(false);
            callApi();
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [wallets, addAlert]);

    useEffect(() => {
        _.map(cart, detail => (
            _.map(detail?.items?.self, pdt => {
                if (current !== 'manage') {
                    if (pdt.selected === 1) {
                        cartItemArray.push(pdt.id);
                    } else if (pdt.selected === 0) {
                        cartItemArray.splice(pdt.id, 1);
                    }
                }
            })
        ))
        if (current !== 'manage') {
            setState({ ...state, removeCartId: cartItemArray, moveToMyLikes: cartItemArray });
        } else {
            setState({ ...state, removeCartId: [], moveToMyLikes: cartItemArray });
        }
    }, [current, cart]);

    // -------------------------------- FUNCTIONS -------------------------------------
    const callApi = () => {
        getUrl('carts').then(cartList => {
            setCurrencyData(cartList.currency_data);
            setCart(cartList.data);
            setWishlist(cartList.wish);
            calculateTotal(cartList.data);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const calculateTotal = userCart => {
        let totalPrice = 0;
        let totalSelected = 0;
        let totalWalletPrice = {};
        let checkAll = true;

        _.map(userCart, merchantItem => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        totalSelected += parseInt(cartItem.quantity);
                        let itemPrice = cartItem.product.sell_price;
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (option) => {
                                _.map(option.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        let priceMod = pricingOption.price_mod;
                                        itemPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : itemPrice;
                                    }
                                })
                            })
                        }
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        } else {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        }
                    } else if (shippingChannel !== 'unlisted') {
                        checkAll = false;
                    }
                })
            });
        })
        setState(state => ({ ...state, totalPrice, totalSelected, checkAll, totalWalletPrice }));
    };

    const priceDisplay = (cartItem, unlisted, display) => {
        let productPrice = cartItem.product.sell_price;
        let priceStyle = {};
        let noMultiPricing = true;
        if (_.size(cartItem.product.options) > 0) {
            _.map(cartItem.product.options, (optionItem) => {
                _.map(optionItem.pricing, (pricingOption) => {
                    if (pricingOption.id === cartItem.product_warehouse_id) {
                        let priceMod = pricingOption.price_mod;
                        productPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : productPrice;
                    }
                })
            })
        }
        if (display === 'mobile' && unlisted === false) {
            priceStyle = { color: theme.palette.price.main, fontSize: '15px' };
        } else if (display === 'mobile' && unlisted === true) {
            priceStyle = { color: "#808080", fontSize: '15px' };
        } else if (display === 'web' && unlisted === false) {
            priceStyle = { color: theme.palette.price.main };
        } else if (display === 'web' && unlisted === true) {
            priceStyle = { color: "#808080" };
        }
        return (
            <>
                {
                    (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) ?
                        _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
                            if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
                                noMultiPricing = false;
                                let pricingText = [];
                                _.map(pricingItem.wallet_settings, (setting, key) => {
                                    let minAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.min_amount / 100) : setting.min_amount);
                                    let maxAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.max_amount / 100) : setting.max_amount);
                                    pricingText[key] = { 'prefix': `${setting.pricing_name} `, 'min_amount': parseFloat(minAmount * cartItem.quantity).toFixed(pricingItem.decimal), 'max_amount': parseFloat(maxAmount * cartItem.quantity).toFixed(pricingItem.decimal) };
                                })
                                return (
                                    <Box key={pricingIndex} className={styles.setFlex}  style={unlisted === true ? { color: "#808080" } : {}}>
                                        <Typography variant="h6" style={{ fontSize: 13 }}>
                                            <NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                        </Typography>
                                    </Box>
                                )
                            }
                        })
                        :
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            <NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                        </Typography>
                }
                {
                    (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) && noMultiPricing ?
                        <Typography variant="h6" style={{ color: "#808080", fontSize: 15 }}>
                            {t('cart.paymentInvalid')}
                        </Typography>
                        : null
                }
            </>
        )
    }

    const removeCart = async (removeId, condition) => {
        const { removeCartId } = state;
        let cartIdString = [];

        if (removeCartId && condition === 'multiple') {
            cartIdString = _.join(removeCartId, ',');
        } else if (removeId && condition === 'single') {
            cartIdString.push(removeId);
        }

        setLoading(true);
        deleteUrl(`carts/${cartIdString}`).then(response => {
            if (response.status !== 0) {
                setLoading(false);
                const newCart = cart;
                _.map(newCart, (merchantItem, merchantIndex) => {
                    let merchantProduct = false;
                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                        newCart[merchantIndex]['items'][shippingChannel] = _.remove(cartDetails, cartItem => {
                            if (_.size(removeCartId) > 0) {
                                if (!_.includes(removeCartId, cartItem.id)) {
                                    return cartItem;
                                }
                            } else {
                                if (removeId !== cartItem.id) {
                                    return cartItem;
                                }
                            }
                        })
                        console.log('test newCart', newCart)

                        if (_.size(newCart[merchantIndex]['items'][shippingChannel]) !== 0) {
                            merchantProduct = true;
                        }
                    })
                    if (!merchantProduct) {
                        delete newCart[merchantIndex];
                    }
                })
                dispatch(updateCartTotal(cartTotal - 1));
                setCart(newCart);
                setState({ ...state, dialogShow: false, removeCartId: [], moveToMyLikes: [] });
                calculateTotal(newCart);
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const updateCart = (cartId, inputQuantity, reverseSelected) => {
        let selectedCartItem = {};
        _.map(cart, merchantItem => {
            _.map(merchantItem.items, cartDetails => {
                const matchItem = _.find(cartDetails, ['id', cartId]);
                if (_.size(matchItem) > 0) {
                    selectedCartItem = matchItem;
                }
            })
        })
        let newInputQuantity = selectedCartItem.quantity;
        let newInputSelected = selectedCartItem.selected;
        if (inputQuantity) {
            newInputQuantity = parseInt(inputQuantity) > 0 ? parseInt(inputQuantity) : 1;
        }
        if (reverseSelected) {
            newInputSelected = newInputSelected ? false : true;
        }

        let newCart = cart;
        putUrl(`carts/${cartId}`, { selected: newInputSelected ? 1 : 0, quantity: newInputQuantity }).then(response => {
            if (response.status === 0) {
                addAlert(_.map(response.error.cart).join(' '));
            } else {
                _.map(newCart, (merchantItem, merchantIndex) => {
                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                        _.map(cartDetails, (cartItem, cartIndex) => {
                            if (cartItem.id === cartId) {
                                newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                                    ...cartItem, quantity: newInputQuantity, selected: newInputSelected
                                }
                            }
                        })
                    });
                })
                setCart(newCart);
                calculateTotal(newCart);
                if (current !== 'manage') {
                    if (newInputSelected) {
                        setState({ ...state, removeCartId: [...state.removeCartId, cartId], moveToMyLikes: [...state.moveToMyLikes, cartId] });
                    } else {
                        let tempArrRemove = state.removeCartId.filter((temp) => { return temp !== cartId });
                        let tempArrMove = state.moveToMyLikes.filter((temp) => { return temp !== cartId });
                        setState({ ...state, removeCartId: tempArrRemove, moveToMyLikes: tempArrMove });
                    }
                }
                callApi();
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const checkoutCart = () => {
        if (!accessToken) {
            return addAlert(t('cart.loginToProceed'));
        }
        let proceedCheckout = true;
        if (_.size(cart) > 0) {
            _.map(cart, merchantItem => {
                _.map(merchantItem.items, cartDetails => {
                    _.map(cartDetails, cartItem => {
                        if (cartItem.selected) {
                            let stockAvailable = 0;
                            if (_.size(cartItem.product.options) > 0) {
                                _.map(cartItem.product.options, (option) => {
                                    _.map(option.pricing, (pricingOption) => {
                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                            stockAvailable = pricingOption.quantity;
                                        }
                                    })
                                });
                            } else {
                                stockAvailable = cartItem.product.quantity;
                            }

                            if (cartItem.quantity > stockAvailable) {
                                proceedCheckout = false;
                                addAlert(t('general.stockExceedError'));
                            }
                        }
                    })
                })
            })
        }

        if (proceedCheckout) {
            return history.push("/checkout");
        }
    }

    const checkAll = () => {
        const newCheckAll = state.checkAll ? false : true;
        const newCart = cart;

        _.map(newCart, (merchantItem, merchantIndex) => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem, cartIndex) => {
                    if (shippingChannel !== 'unlisted') {
                        if (Boolean(cartItem.selected) !== Boolean(newCheckAll)) {
                            putUrl(`carts/${cartItem.id}`, {
                                selected: newCheckAll ? 1 : 0,
                                quantity: cartItem.quantity,
                            }).catch(error => {
                                addAlert(JSON.stringify(error.message));
                            });
                        }

                        if (current !== 'manage') {
                            if (Boolean(cartItem.selected) !== Boolean(newCheckAll)) {
                                if (newCheckAll) {
                                    cartItemArray.push(cartItem.id);
                                } else {
                                    cartItemArray.splice(cartItem.id, 1);
                                }
                            }
                        }

                        newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                            ...cartItem, quantity: cartItem.quantity, selected: newCheckAll ? 1 : 0
                        }
                    }
                })
            })
        })
        setCart(newCart);
        setState({ ...state, checkAll: newCheckAll, removeCartId: cartItemArray, moveToMyLikes: cartItemArray });
        calculateTotal(newCart);
    }

    const setCheckOption = (cartShop) => {
        let checkStatus = false;

        _.map(cartShop?.items?.self , (item) => {
            // false
            if(item?.selected == 0 || item?.selected == false){
                checkStatus = true;
            } 
        })
        if (checkStatus){ return false; }
        else { return true; }
    }

    const setIndeterminate = (cartShop) => {
        let checkStatus_False = false;
        let checkStatus_True = false;
        let checkStatus_Loop = false;
        _.map(cartShop?.items?.self , (item) => {
            // false
            if(item?.selected == 0 || item?.selected == false){
                checkStatus_False = true;
            } 
            // // true
            if(item?.selected == 1 || item?.selected == true){
                checkStatus_True = true;
            }
            if (checkStatus_False == true){
                if(item?.selected == 1 || item?.selected == true){
                    checkStatus_Loop = true;
                }
            }
            if (checkStatus_True == true){
                if(item?.selected == 0 || item?.selected == false){
                    checkStatus_Loop = true;
                }
            }
        })
        if (checkStatus_Loop){ return true; }
        if (checkStatus_False || checkStatus_True){ return false; }
    }

    const changeStatus = (cartShop) => {
        let checkStatus_False = false;
        let checkStatus_True = false;
        let checkStatus_Loop = false;
        let setAllStatus = 1;
        let ids = [];

        _.map(cartShop?.items?.self , (item) => {
            // false
            if(item?.selected == 0 || item?.selected == false){
                checkStatus_False = true;
            } 
            // // true
            if(item?.selected == 1 || item?.selected == true){
                checkStatus_True = true;
            }
            if (checkStatus_False == true){
                if(item?.selected == 1 || item?.selected == true){
                    checkStatus_Loop = true;
                }
            }
            if (checkStatus_True == true){
                if(item?.selected == 0 || item?.selected == false){
                    checkStatus_Loop = true;
                }
            }
            ids.push(item?.id);

            if (current !== 'manage') {
                if (checkStatus_Loop || checkStatus_False) {
                    cartItemArray.push(item?.id);
                } else if (checkStatus_True) {
                    cartItemArray.splice(item?.id, 1);
                }
            }
        })

        if (checkStatus_Loop || checkStatus_False){ setAllStatus = 1; } 
        else if (checkStatus_True){ setAllStatus = 0; }

        if (current !== 'manage') {
            if (checkStatus_Loop || checkStatus_False) {
                setState({ ...state, removeCartId: cartItemArray, moveToMyLikes: cartItemArray });
            } else if (checkStatus_True) {
                setState({ ...state, removeCartId: [], moveToMyLikes: [] });
            }
        }

        let apiData = {
            ids: ids,
            status: setAllStatus,
        };
        let newCart = cart;

        putUrl('changeStatus', apiData).then(response => {
            if (response.status){
                _.map(newCart, (merchantItem, merchantIndex) => {
                    if (merchantItem?.name === cartShop?.name){
                        _.map(merchantItem?.items?.self, (productDetails, productId) => {
                            newCart[merchantIndex]['items']['self'][productId] = {
                                ...productDetails, selected: setAllStatus
                            }
                        })
                    }
                })
                setCart(newCart);
                calculateTotal(newCart);
            }else{
                addAlert(_.map(response.error.cart).join(' '));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const likeProduct = (selectedId, condition) => {
        if (accessToken) {
            if(condition === 'single') {
                postUrl(`wishlist/product`, { product_id: selectedId }).then(response => {
                    if (response.status === 1) {
                        setState({ ...state, removeCartId: [], moveToMyLikes: [] });
                        callApi();
                        addAlert('', 'success', t('item.productLiked'), '');
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                const { moveToMyLikes } = state;
                let cartIdString = [];
                cartIdString = _.join(moveToMyLikes, ',');

                postUrl(`wishlist/${cartIdString}`).then(response => {
                    if (response.status === 1) {
                        setState({ ...state, removeCartId: [], moveToMyLikes: [] });
                        callApi();
                        addAlert('', 'success', t('item.productLiked'), '');
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
    }

    const dislikeProduct = (selectedId) => {
        if (accessToken) {
            deleteUrl(`wishlist/product/${selectedId}`).then(response => {
                if (response) {
                    if (isMountedRef.current) {
                        setState({ ...state, removeCartId: [], moveToMyLikes: [] });
                        callApi();
                        addAlert('', 'success', t('item.productDislike'), '');
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    };

    // --------------------------------- DISPLAY DESIGN FUNCTIONS ---------------------------------------------------
    const checkoutBottomNav = () => {
        if(_.size(cart) > 0 && cart !== null){
            return (
                <Box className={styles.checkoutBoxStyle}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div style={{ paddingRight: 20 }}>
                            <FormControlLabel
                                labelPlacement="bottom"
                                style={{ margin: 0 }}
                                label={<Typography variant="subtitle2" style={{ color: '#4E4E4E', fontSize: 10, textTransform: 'uppercase' }}>{t('cart.all')}</Typography>}
                                control={<Checkbox style={{ padding: 0, color: state.checkAll ? "#2E523C" : "D9D9D9" }} checked={state.checkAll} onChange={checkAll} />}
                            />
                        </div>
                        <div>
                            <Typography style={{ fontSize: 10, textTransform: 'uppercase' }}>{t('cart.total')}</Typography>
                            <NumberFormat style={{ whiteSpace: 'nowrap', fontSize: 15, color: theme.palette.primary.main, fontWeight: 'bold' }} value={(state.totalPrice > 0 ? (state.totalPrice * currencyRate[`${currency}`]) : 0).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                            {
                                _.size(state.totalWalletPrice) > 0 ?
                                    _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                        return (
                                            <Typography style={{ fontSize: 12, color: '#ADADAD' }}>
                                                ≈ <NumberFormat key={walletType} style={{ whiteSpace: 'nowrap', fontSize: 12, color: '#ADADAD' }} value={(walletTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={`${walletType} `} />
                                            </Typography>
                                        )
                                    })
                                    : null
                            }
                        </div>
                    </div>

                    <div>
                        <Button variant="contained" size='large' disabled={state.totalSelected === 0 ? true : false} className={styles.buttonDisplayStyle} onClick={checkoutCart}>
                            {t('cart.checkout')}
                        </Button>
                    </div>
                </Box>
            )
        }
    }

    const manageBottomNav = () => {
        return (
            <Box className={styles.manageBoxStyle}>
                <div style={{ paddingRight: 20 }}>
                    <FormControlLabel
                        labelPlacement="bottom"
                        style={{ margin: 0 }}
                        label={<Typography variant="subtitle2" style={{ color: '#4E4E4E', fontSize: 10, textTransform: 'uppercase' }}>{t('cart.all')}</Typography>}
                        control={<Checkbox style={{ padding: 0, color: state.checkAll ? "#2E523C" : "D9D9D9" }} checked={state.checkAll} onChange={checkAll} />}
                    />
                </div>

                <div>
                    <Button variant="contained" size='large' disabled={_.size(state.moveToMyLikes) > 0 ? false : true} className={styles.buttonDisplayStyle} style={{ marginRight: 10 }} onClick={() => likeProduct('multiple')}>
                        {t('button.moveToLikes')}
                    </Button>
                    <Button variant="contained" size='large' disabled={_.size(state.removeCartId) > 0 ? false : true} className={styles.buttonDisplayStyle} onClick={() => removeCart(0, 'multiple')}>
                        {t('button.delete')}
                    </Button>
                </div>
            </Box>
        )
    }

    return (
        <Box>
            <ImgHeader 
                normalDisplay 
                title={t('title.mycart')} 
                buttonText={t(`button.${current}`)} 
                menuButton={true}
                children={() => {
                    if (current === 'done') {
                        setCurrent(current === 'manage' ? 'done' : 'manage');
                    } else {
                        setCurrent(current === 'manage' ? 'done' : 'manage');
                    }
                }} 
            />

            <Box className={styles.cartPage}>
                <Grid container>
                    {
                        _.size(cart) > 0 ?
                            _.map(cart, (merchantItem, index) => {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <div style={{ paddingBottom: 20 }}>
                                            <Box className={styles.merchantBoxStyle}>
                                                <FormControlLabel
                                                    label={
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                            <Storefront style={{ marginLeft: 20 }} />
                                                            <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 18, marginLeft: 5 }}>{merchantItem.name}</Typography>
                                                        </div>
                                                    }
                                                    control={
                                                        <Checkbox
                                                            style={{ width: 35, padding: 0, color: "#2E523C", fontSize: 21 }}
                                                            checked={setCheckOption(merchantItem)}
                                                            indeterminate={setIndeterminate(merchantItem)}
                                                            onClick={() => changeStatus(merchantItem)}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            {
                                                _.map(merchantItem.items, (pdtDetail, shippingChannel) => (
                                                    _.map(pdtDetail, (cartItem, key) => {
                                                        const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                        let variant_translate = [];
                                                        let stockAvailable = 0;
                                                        if (_.size(cartItem.product.options) > 0) {
                                                            _.map(cartItem.product.options, (option) => {
                                                                _.map(option.pricing, (pricingOption) => {
                                                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                        if (option.color) {
                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                        }
                                                                        if (option.size) {
                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                        }
                                                                        if (option.attribute_value) {
                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                        }
                                                                        stockAvailable = pricingOption.quantity;
                                                                    }
                                                                })
                                                            });
                                                        } else {
                                                            stockAvailable = cartItem.product.quantity;
                                                        }

                                                        let likeList = _.filter(wishlist, {'wishlistable_id': cartItem.product.id});

                                                        return (
                                                            <Box key={key} className={styles.productBoxStyle}>
                                                                <ThemeProvider theme={muiTheme}>
                                                                    <FormControlLabel
                                                                        style={{ marginRight: 0, width: '-webkit-fill-available', maxWidth: '100%' }}
                                                                        control={
                                                                            <Checkbox
                                                                                style={{ width: 35, color: cartItem.selected ? "#2E523C" : (shippingChannel === 'unlisted' ? "#D9D9D9" : "#2E523C"), fontSize: 21 }}
                                                                                checked={Boolean(cartItem.selected)}
                                                                                onChange={() => { updateCart(cartItem.id, null, true); }}
                                                                                disabled={(shippingChannel === 'unlisted') ? true : false}
                                                                            />
                                                                        }
                                                                        label={
                                                                            <div style={{ width: '-webkit-fill-available', display: "flex", justifyContent: "start", alignItems: 'center', paddingLeft: 10 }}>
                                                                                <Grid container spacing={2} className={styles.productDetailStyle}>
                                                                                    <Grid item xs={4}>
                                                                                        <WebpImg style={{ width: "100%", height: 93, borderRadius: 10, objectFit: 'cover' }} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                    </Grid>
                                                                                    <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                                        <Typography variant="caption" style={{ paddingBottom: 5, color: "#4E4E4E", overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{title_translate}</Typography>
                                                                                        <div className={styles.productVariantStyle}>
                                                                                            {
                                                                                                _.size(variant_translate) > 0 &&
                                                                                                _.map(variant_translate, (variantText, i) => {
                                                                                                    if (i < (_.size(variant_translate) - 1)) {
                                                                                                        return (
                                                                                                            <Typography key={i} variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText},&nbsp;</Typography>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <Typography key={i} variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </div>

                                                                                        {
                                                                                            cartItem.quantity > stockAvailable &&
                                                                                            <div style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                            </div>
                                                                                        }

                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                            {priceDisplay(cartItem, true, 'mobile')}
                                                                                            {
                                                                                                current === 'manage' &&
                                                                                                <div>
                                                                                                    <FormControl style={{ margin: '5px 0' }}>
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            value={cartItem.quantity}
                                                                                                            disableUnderline={true}
                                                                                                            fullWidth={true}
                                                                                                            classes={{ input: styles.inputStyle }}
                                                                                                            style={{ background: '#EDEDED', borderRadius: 5 }}
                                                                                                            disabled
                                                                                                            startAdornment={
                                                                                                                <InputAdornment position="start">
                                                                                                                    <IconButton style={{ margin: 0, padding: 3, background: '#FFBF42', borderRadius: 5 }} onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) - 1)}>
                                                                                                                        <Remove style={{ fontSize: 20 }} />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                            endAdornment={
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton style={{ margin: 0, padding: 3, background: '#FFBF42', borderRadius: 5 }} onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) + 1)}>
                                                                                                                        <Add style={{ fontSize: 20 }} />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </Grid>
                                                                                    {
                                                                                        current !== 'manage' &&
                                                                                        <div style={{ width: '100%', padding: '0px 10px' }}>
                                                                                            <Divider style={{ height: 4, background: '#FFF', boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.10) inset' }} />
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                                                                {
                                                                                                    _.size(likeList) > 0
                                                                                                        ? likeList?.[0]?.wishlistable_id !== cartItem.product_id
                                                                                                            ? <Button style={{ textTransform: 'capitalize', fontSize: 11 }} onClick={() => likeProduct(cartItem.product_id, 'single')}>
                                                                                                                <FavoriteBorderRounded style={{ paddingRight: 10, fontSize: 30 }} />
                                                                                                                {t('button.moveToLikes')}
                                                                                                            </Button>
                                                                                                            : <Button style={{ textTransform: 'capitalize', fontSize: 11 }} onClick={() => dislikeProduct(cartItem.product_id)}>
                                                                                                                <FavoriteRounded style={{ paddingRight: 10, fontSize: 30 }} />
                                                                                                                {t('button.removeFromLikes')}
                                                                                                            </Button>
                                                                                                        : <Button style={{ textTransform: 'capitalize', fontSize: 11 }} onClick={() => likeProduct(cartItem.product_id, 'single')}>
                                                                                                            <FavoriteBorderRounded style={{ paddingRight: 10, fontSize: 30 }} />
                                                                                                            {t('button.moveToLikes')}
                                                                                                        </Button>
                                                                                                }
                                                                                                <Button style={{ textTransform: 'capitalize', fontSize: 11 }} onClick={() => removeCart(cartItem.id, 'single')}>
                                                                                                    <DeleteOutlineRounded style={{ paddingRight: 10, fontSize: 30 }} />
                                                                                                    {t('button.deleteItem')}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </Grid>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </ThemeProvider>
                                                            </Box>
                                                        )
                                                    })
                                                ))
                                            } 
                                        </div>
                                        <Divider style={{ background: '#E8E8E8', height: 8 }} />
                                    </Grid>
                                )
                            })
                            :
                            <Box style={{ minHeight: 100, width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    // cart === null ?
                                    //     <>
                                    //         <CircularProgress disableShrink />
                                    //         <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                    //     </>
                                    //     :
                                    //     <Box>
                                    //         <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/general/empty-cart2.png'} style={{ width: '150px', maxWidth: '50%' }} />
                                    //         <Typography style={{ textAlign: 'center', paddingLeft: '15px' }}>{t('general.noProduct')}</Typography>
                                    //     </Box>
                                }
                                <Box>
                                    <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/general/empty-cart2.png'} style={{ width: '150px', maxWidth: '50%' }} />
                                    <Typography style={{ textAlign: 'center', paddingLeft: '15px' }}>{t('general.noProduct')}</Typography>
                                </Box>
                            </Box>
                    }
                </Grid>

                { current === 'manage' ? checkoutBottomNav() : manageBottomNav() }
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    cartPage: {
        borderRadius: '30px 30px 0px 0px',
        background: '#F4F4F4',
        position: 'absolute',
        top: 150,
        width: '100%',
        paddingBottom: 130,
    },
    merchantBoxStyle: {
        width: "100%",
        padding: '20px 28px',
        display: 'flex',
        alignItems: 'center',
    },
    productBoxStyle: {
        width: "100%",
        padding: '10px 0px 10px 28px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    productDetailStyle: {
        width: "100%",
        borderRadius: 15,
        background: '#F4F4F4',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF',
    },
    productVariantStyle: {
        borderRadius: 5,
        background: '#EDEDED',
        boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 3px 0px #FFF',
        padding: '0px 10px',
        width: 180,
    },
    inputStyle: {
        textAlign: 'center',
        width: 20,
        height: 16,
        fontSize: 14,
        padding: 5,
    },
    checkoutBoxStyle: {
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        padding: 25, 
        position: 'fixed', 
        bottom: -7, 
        width: 420, 
        boxShadow: '0 -5px 11px 0 #0000001a', 
        zIndex: 10,
        maxWidth: '100%', 
        minHeight: 80, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    manageBoxStyle: {
        width: '100%', 
        background: 'linear-gradient(180deg, rgba(244, 244, 244, 0.43) 0%, rgba(244, 244, 244, 0.70) 100%)', 
        padding: 25, 
        position: 'fixed', 
        bottom: -7, 
        width: 420, 
        boxShadow: '0 -5px 11px 0 #0000001a', 
        zIndex: 10,
        maxWidth: '100%', 
        minHeight: 80, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        backdropFilter: 'blur(2.5px)',
    },
    buttonDisplayStyle: {
        background: theme.palette.primary.main, 
        textTransform: 'capitalize', 
        color: '#fff',
        borderRadius: 10,
        '&:hover': {
            color: '#000'
        }
    }
}));

const muiTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            root: {
                width: '100%'
            }
        }
    }
});