// GENERAL
export const ADD_ERROR = 'add_error';
export const CHANGE_LANGUAGE = 'change_language';
export const CHANGE_CURRENCY = 'change_currency';
export const CHANGE_CURRENCY_RATE = 'change_currency_rate';
export const STORE_UUID = 'store_uuid';
export const REMOVE_UUID = 'remove_uuid';
export const CART_TOTAL = 'cart_total';
export const CODE_STATE = 'code_state';
export const RESET_CODE_STATE = 'reset_code_state';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_FAIL = 'auth_fail';
export const AVAILABLE_EVENT2 = 'available_event2'
export const LINK_REFERRAL_CODE = 'link_referral_code';
export const SHARE_LINK_PRODUCT = 'share_link_product';

// USER
export const STORE_PROFILE = 'store_profile';
export const RESET_PROFILE = 'reset_profile';
export const STORE_WALLET_BALANCE = 'store_wallet_balance';

// WALLET
export const STORE_WALLETS = 'store_wallets';