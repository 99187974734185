import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { CardHeader, CardContent, TextField, InputAdornment,Hidden, IconButton, Button,  Typography, Container, Grid, Link, Box, Divider, Card } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { BACKEND_URL, CLIENT_SECRET, EXTERNAL_LOGIN } from '../configs/Config';
import { authSuccess, authFail, storeCodeState } from '../actions';
import { generateRandomString } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import WebpImg from './Layouts/WebpImg';
import i18next from 'i18next';



export default function Login() {
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();

    let history = useHistory();

    const styles = useStyles();
    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });

    useEffect(() => {
        if(accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]);

    const loginUser = () => {
        setLoading(true);
        console.log(state);

        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        }
        console.log("loginstate", loginState);
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, loginState)
        .then((response) => {
            // setLoading(false);
            setLoading(false);
            console.log("result", response);
            if(response.status === 200) {
                dispatch(authSuccess(response.data));
                history.goBack();
            }            
        }).catch((error) => {
            console.log("error", error);
            setLoading(false);
            if (error.response && error.response.status === 400) {
                addAlert(t('general.incorrectUsernamePassword'));
                // console.log('The username or password is incorrect');
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                addAlert(errorResponse.message);
            }else{
                addAlert(JSON.stringify(error.message));
                // console.log('Please try again later or contact Technical Support if problem persist.');
            }            
            dispatch(authFail());
            // setLoading(false);            
        }); 
    }

    const loginUserThirdParty = platform => {
        const generatedLoginState = generateRandomString(40);
        const generatedCodeVerifier = generateRandomString(128);

        dispatch(storeCodeState(generatedCodeVerifier, generatedLoginState));

        /* if(platform === 'bigo') {
            window.location.href = EXTERNAL_LOGIN; 
        } */
    }

    return (
        <Box style={{height:'100vh'}}>
            <Hidden only={'xs'}>
            <Box style={{height:'100%', backgroundImage:`url(${'../images/comingsoon-general.jpg'})` , backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Box>
            </Hidden>
            <Hidden smUp>
            <Box style={{height:'100%', backgroundImage:`url(${'../images/comingsoon-mobile.jpg'})` , backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></Box>
            </Hidden>
            <WebpImg src="/images/logo.png" containerStyle={{ position:'absolute', top:'8%', display:'flex', justifyContent:'center', alignItem:'center', width:'100%'}} style={{width:80}} alt="logo" />
            {/* <Box style={{height:'100%', backgroundImage:`url(${'../images/comingsoon-general.jpg'})` , backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}> */}
            {/* <Box style={{background:'#feb500', height:'100%'}}>
            <Hidden only={'xs'}>
            <center><WebpImg src="/images/comingsoon-general.png" style={{ width: 1600 }} alt="comingsoon" /></center>
            </Hidden>
            </Box> */}
            {/* padding:'3% 0', background: '#7EC3FC', background: 'linear-gradient(360deg, #D9ECFF 22.97%, #6DC9FC 37.3%, #7EC3FC 64%, #3794FC 100%)', */}
            {/* <Box>
                <Hidden only={'xs'}>
            <WebpImg src="/images/comingsoon-general.jpg" style={{ width: '100%', maxWidth: '100%' }} alt="loginGraphic" />
            </Hidden>
            <Hidden smUp>
            <WebpImg src="/images/comingsoon-mobile.jpg" style={{ width: '100%', maxWidth: '100%' }} alt="loginGraphic" />
            </Hidden>
            </Box> */}
        </Box>

    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    containerStyle: {
        padding: 20,
    }
}));