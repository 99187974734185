import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import _ from 'lodash';

import { useQuery } from '../helper/Tools';
import { postUrl, getUrl } from '../helper/ApiAction';
import { authSuccess, authFail, updateShareLinkRef, changeLanguage as reduxChangeLanguage } from '../actions';
import { BACKEND_URL, CLIENT_SECRET } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';

import {
    Card, CardHeader, CardContent, Divider, TextField, Button, Container, Grid, Box, Typography, Link, MenuItem, FormControlLabel,
    Checkbox, FormHelperText, BottomNavigationAction, Modal, Backdrop, Fade, IconButton
} from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, ThemeProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { CheckCircleOutline, CloseRounded } from '@material-ui/icons';
import { FiChevronLeft, FiArrowLeft } from 'react-icons/fi';

import WebpImg from './Layouts/WebpImg';
import ImgHeader from './Layouts/ImgHeader';
import PrivacyPolicy from './Layouts/PrivacyPolicy';

export default function Register() {
    const styles = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const query = useQuery();
    const encoded_ref_id = query.get('r');
    const isMountedRef = useRef(null);
    let history = useHistory();

    const { t, i18n } = useTranslation();
    const { accessToken, linkReferralCode } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();

    const queryName = query.get("name") ? query.get("name") : "";
    const queryUsername = query.get("username") ? query.get("username") : "";
    const queryMobile = query.get("mobile") ? query.get("mobile") : "";
    const queryEmail = query.get("email") ? query.get("email") : "";
    const queryReferral = query.get("referral") ? query.get("referral") : "";

    // const queryPlacement = query.get('placement') ? query.get('placement') : "";
    // const queryPosition = query.get('position') ? query.get('position') : 0;
    // const queryNric = query.get("nric") ? query.get("nric") : "";
    // const source = query.get("src") ? query.get("src") : "";
    // const sourceUserId = query.get("uid") ? query.get("uid") : "";

    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('register.name'), val: queryName });
    const [username, usernameInput, usernameValidate] = useInput({ type: "text", label: t('register.username'), val: queryUsername });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('register.email'), val: queryEmail });
    const [mobile, mobileInput, mobileValidate] = useInput({ type: "text", label: t('register.mobile'), val: queryMobile });
    const [referral, referralInput, referralValidate, setReferral] = useInput({ type: "text", label: t('register.referral'), val: queryReferral });
    const [password, passwordInput, passwordValidate] = useInput({ type: "password", label: t('register.password'), helperText: t('profile.passwordValidation') });
    const [passwordConfirmation, passwordConfirmationInput, passwordConfirmationValidate] = useInput({ type: "password", label: t('register.passwordConfirmation') });
    const [isChecked, setIsChecked] = useState(false);

    // const placementPosition = [{value: 0, label: t("register.placementPosition.no")}, {value: 1, label: t("register.placementPosition.left")}, {value: 2, label: t("register.placementPosition.right")}];
    // const [nric, nricInput, nricValidate] = useInput({ type: "text", label: t('register.nric_passport'), val: queryNric});
    // const [placement, placementInput, placementValidate] = useInput({ type: "text", label: t('register.placement'), val: queryPlacement, disabled: true });
    // const [position, positionInput, positionValidate] = useSelect({ data: placementPosition, label: t('register.position'), val: queryPosition, disabled: true });
    // const [autoPlacement, autoPlacementInput, autoPlacementValidate] = useCheckbox({ label: t('register.autoPlacement'), val: queryPlacement ? false : true });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [refId, setRefId] = useState("");
    const [open, setOpen] = useState(false);

    // const validate = { name: nameValidate, email: emailValidate, mobile: mobileValidate, nric: nricValidate, password: passwordValidate, password_confirmation: passwordConfirmationValidate, username: usernameValidate, referral: referralValidate, placement: placementValidate, position: positionValidate, autoPlacement: autoPlacementValidate };
    const validate = { email: emailValidate, mobile: mobileValidate, password: passwordValidate, password_confirmation: passwordConfirmationValidate, name: nameValidate, username: usernameValidate, referral: referralValidate };

    useEffect(() => {
        if (accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]);

    useEffect(() => {
        isMountedRef.current = true;
        if (encoded_ref_id) {
            getUrl(`ref_username/${encoded_ref_id}`).then(response => {
                if (isMountedRef.current) {
                    if (response.data.ref_id) {
                        setRefId(response.data.ref_id);
                        setReferral(response.data.ref_username);
                    }
                }
            }).catch(error => {
                // addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
    }, [encoded_ref_id]);

    useEffect(() => {
        if (!encoded_ref_id && linkReferralCode) {
            history.replace(`register?r=${linkReferralCode}`);
        }
        if (encoded_ref_id == linkReferralCode) {
            dispatch(updateShareLinkRef(""));
        }
        // eslint-disable-next-line
    }, [encoded_ref_id, linkReferralCode]);

    function useInput({ type, label, val, helperText, disabled = false }) {
        const [value, setValue] = useState(val ? val : "");
        const [error, setErrorValue] = useState("");
        const input =
            <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                    <MuiThemeProvider theme={fieldStyle}>
                        <TextField
                            fullWidth
                            label={label}
                            error={error ? true : false}
                            helperText={error}
                            variant="outlined"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            type={type}
                            InputProps={{
                                className: styles.textFieldBox,
                                readOnly: disabled,
                            }}
                            InputLabelProps={{
                                className: styles.textFieldText,
                            }}
                        />

                        {helperText && <FormHelperText className={styles.helperText}>{helperText}</FormHelperText>}
                    </MuiThemeProvider>
                </Grid>
            </Grid>;
        return [value, input, setErrorValue, setValue];
    }

    // function useSelect({ data, label, val, disabled=false }) {
    //     const [value, setValue] = useState(val);
    //     const [error, setErrorValue] = useState("");
    //     const select =
    //         <Grid container spacing={3} justify="center">

    //             <Grid item xs={12}>
    //             <MuiThemeProvider theme={fieldStyle}>
    //                 <TextField
    //                     select
    //                     label={label}
    //                     value={value}
    //                     onChange={({ target }) => setValue(target.value)}
    //                     variant="outlined"
    //                     fullWidth
    //                     error={error ? true : false}
    //                     helperText={error}
    //                     disabled={disabled}
    //                 >
    //                     {data.map((option, key) => (
    //                         <MenuItem key={key} value={option.value} style={{color:'#222'}}>
    //                             {option.label}
    //                         </MenuItem>
    //                     ))}
    //                 </TextField>
    //                 </MuiThemeProvider>
    //             </Grid>
    //         </Grid>
    //     return [value, select, setErrorValue, setValue];
    // }

    // function useCheckbox({ label, val, note='' }) {
    //     const [value, setValue] = useState(val);
    //     const [error, setErrorValue] = useState("");
    //     const checkbox =
    //         <Grid item xs={12} sm={6}>
    //             <FormControlLabel
    //                 control={
    //                     <Checkbox
    //                         checked={value}
    //                         onChange={e => setValue(e.target.checked)}
    //                         color="primary"
    //                     />
    //                 }
    //                 label={label}
    //             />
    //             <Typography variant="body2" >{ note }</Typography>
    //         </Grid>;
    //     return [value, checkbox, setErrorValue];
    // }

    // const loginUser = () => {
    //     setLoading(true);
    //     const loginState = {
    //         grant_type: 'password',
    //         client_id: '4',
    //         client_secret: CLIENT_SECRET,
    //         username: username,
    //         password: password,
    //         scope: '*',
    //     }
    //     axios.defaults.headers.common['Accept'] = 'application/json';
    //     axios.post(`${BACKEND_URL}/oauth/token`, loginState)
    //     .then((response) => {
    //         setLoading(false);
    //         if(response.status === 200) {
    //             const decoded_jwt = jwt_decode(response.data.access_token);
    //             const userRole = decoded_jwt ? decoded_jwt.role : [];
    //             if(_.size(_.intersection(userRole, ['member']))){
    //                 dispatch(authSuccess(response.data));
    //             }else{
    //                 addAlert(t('general.incorrectUsernamePassword'));
    //             }
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         if (error.response && error.response.status === 400) {
    //             addAlert(t('general.incorrectUsernamePassword'));
    //         }else if (error.response && error.response.status === 401) {
    //             let errorResponse = JSON.parse(error.request.response);
    //             addAlert(errorResponse.message);
    //         }else{
    //             addAlert(JSON.stringify(error.message));
    //         }            
    //         dispatch(authFail());         
    //     }); 
    // }

    // const linkUser = () => {
    //     setLoading(true);
    //     let params = {
    //         username: username,
    //         source: source,
    //         source_user_id: sourceUserId,
    //         source_username: queryUsername,
    //     }
    //     postUrl('account-link/register', params).then(response => {
    //         setLoading(false);
    //         if(response.status){
    //             loginUser();
    //         }else{
    //             if(response.error){
    //                 addAlert(JSON.stringify(_.map(response.error).join(' ')));
    //             }else{
    //                 addAlert(JSON.stringify(response.message));
    //             }
    //         }
    //     }).catch(error => {
    //         setLoading(false);
    //         addAlert(JSON.stringify(error.message));
    //     });
    // }

    const handleSubmit = (evt) => {
        setLoading(true);
        evt.preventDefault();

        setErrorMessage("");
        _.map(validate, (valid) => {
            valid("");
        })
        let postData = {
            name,
            email,
            username,
            mobile,
            // nric,
            referral,
            // placement: autoPlacement?'':placement,
            // position:autoPlacement?null:(position>0?position:null),
            // auto_placement: autoPlacement?1:0,
            password,
            password_confirmation: passwordConfirmation,
            checked: isChecked,
        }

        // if(refId){
        //     postData.ref_id = refId;
        // }else{
        //     postData.referral = referral;
        // }

        postUrl(`register`, postData).then((response) => {
            setLoading(false);
            let { status, message, error } = response;
            if (status == 0) {
                setErrorMessage(message);
                _.map(error, (value, key) => {
                    validate[key](value[0]);
                })
            } else {
                let msg = t('register.recruitSuccessMsg');
                addAlert(msg, 'success');
                history.push(`/login`);
                // setSuccessMessage(message);
                // linkUser();
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box className={styles.background}>
            <ImgHeader imageDisplay={false} title={t('title.home')} backUrl={"/splashscreen"} languageButton={false} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ textAlign: 'center', margin: 50 }}>
                    <WebpImg src="/images/Logo-White-text.png" style={{ width: '160px', height: 'auto' }} alt="logo" />
                </div>

                <div className={styles.registerBox}>
                    <Typography className={styles.registerText}>{t('title.register')}</Typography>

                    <div style={{ padding: 0, marginTop: 30 }}>
                        {
                            // successMessage ?
                            //     <Grid container spacing={2} justify="center" align="center">
                            //         <Grid item xs={12}>
                            //             <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize="large" />
                            //         </Grid>
                            //         <Grid item xs={12}>
                            //             <Typography variant="h4" style={{ textAlign: 'center' }}>{successMessage}</Typography>
                            //         </Grid>
                            //         <Grid item xs={12} sm={6}>
                            //             <Link style={{ textDecoration: 'none' }} to="/login" component={RouterLink} >
                            //                 <Button /* onClick={() => loginUser(dispatch)} */ fullWidth variant="contained" color="#fff" size="large">{t('button.login')}</Button>
                            //             </Link>
                            //         </Grid>
                            //     </Grid>
                            //     :
                            <Box>
                                <Typography style={{ fontSize: 12, color: 'red', textAlign: 'center', paddingBottom: 20 }}>{errorMessage}</Typography>

                                <form onSubmit={handleSubmit}>
                                    {usernameInput}
                                    {nameInput}
                                    {emailInput}
                                    {mobileInput}
                                    {!refId ? referralInput
                                        :
                                        <Grid container spacing={3} justify="center">
                                            <Grid item xs={12}>
                                                <MuiThemeProvider theme={fieldStyle}>
                                                    <TextField style={{ color: '#fff', borderColor: '#fff' }} fullWidth label={t('register.referral')} variant="filled" value={referral} type="text" InputProps={{ readOnly: true }} />
                                                </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                    }
                                    {/* {
                                            autoPlacement ? null :
                                                <>
                                                    {placementInput}
                                                    {positionInput}
                                                </>
                                        } */}
                                    {passwordInput}
                                    {passwordConfirmationInput}

                                    <div style={{ paddingTop: 10, display: 'flex', }}>
                                        <Checkbox style={{ padding: '0px 10px 0px 0px' }} onClick={() => setIsChecked(!isChecked)} />
                                        <div>
                                            <Typography style={{ fontSize: 12 }}>
                                                By clicking here, I state that I have read and understood the&nbsp;
                                                <Link onClick={handleOpen} style={{ fontSize: 12, cursor: 'pointer' }}><b>Privacy Policy</b></Link>
                                                &nbsp;as set out by the user agreement.
                                            </Typography>
                                        </div>
                                    </div>

                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotprops={{
                                            backdrop: {
                                                timeout: 500,
                                            },
                                        }}
                                        
                                    >
                                        <Fade in={open} >
                                            <Box className={styles.modalBoxDesign} >
                                                <div style={{ padding: '0px 0px 5px 0px', width: '100%', textAlign: 'end' }}>
                                                    <IconButton style={{  padding: 0 }} onClick={() => setOpen(false)}><CloseRounded /></IconButton>
                                                </div>
                                                <div style={{ height: '90%', overflowY: 'auto', overflowX: 'hidden' }}>
                                                    <PrivacyPolicy />
                                                </div>
                                            </Box>
                                        </Fade>
                                    </Modal>

                                    <Grid container spacing={3} justify="center" style={{ padding: '0 15px' }}>
                                        <Button type="submit" fullWidth variant="contained" className={styles.submitButton} size='large'>{t('button.register')}</Button>
                                    </Grid>
                                </form>
                            </Box>
                        }
                    </div>
                </div>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    backgroundLine: {
        background: 'linear-gradient(180deg, #000 -6%, rgba(0, 0, 0, 0.00) 72.16%)',
        height: '100%',
    },
    background: {
        backgroundImage: `linear-gradient(180deg, #000 -6%, rgba(0, 0, 0, 0.00) 72.16%), url('/images/background/background_image.png')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat-y',
        margin: '0',
        padding: '0',
        height: 'inherit',
        width: 'auto',
    },
    registerText: {
        color: '#858585',
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        textTransform: 'capitalize',
    },
    iconCss: {
        color: 'white',
        position: 'absolute',
        left: 31,
        top: 26,
    },
    registerBox: {
        backgroundColor: '#F4F4F4',
        borderRadius: '50px 50px 0px 0px',
        padding: '30px 40px',
        height: '100%',
    },
    textFieldBox: {
        // color: "#595959",
        background: '#EFEFEF',
        borderRadius: '48px',
        boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.09) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.93)',
    },
    textFieldText: {
        color: '#595959',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    helperText: {
        color: '#595959',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform: 'capitalize',
    },
    submitButton: {
        borderRadius: '48px',
        background: '#2E523C',
        boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.25)',
        marginTop: 36,
        color: '#F4F4F4',
        textAlign: 'center',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        height: 54,
        fontSize: 22,
        textTransform: 'capitalize',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    modalBoxDesign: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        height: 500,
        background: '#fff',
        borderRadius: 15,
        border: '0px solid #fff',
        padding: '10px 15px',
        outline: "none"
    }
}));

const textFieldStyle = createMuiTheme();
const fieldStyle = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: "#595959",
                underline: 'none',
                content: 'none',
                "&$focused": {
                    color: '#595959'
                },
                '&.$Mui-disabled': {
                    color: '#595959'
                }
            },

        },
        MuiFormControl: {
            root: {
                // backgroundColor:'#fff5',
                // borderRadius:'8px 8px 0 0',
                // "&:hover": {
                //     backgroundColor:'#595959',
                // },
                // "&$focused": {
                //     backgroundColor:'#595959',
                // },
            },
        },
        MuiFormLabel: {
            root: {
                color: '#595959',
                "&$focused": {
                    color: '#595959'
                },
                '&.$Mui-disabled': {
                    color: '#595959'
                }
            }
        },
        MuiFilledInput: {
            underline: {
                '&:after': {
                    borderBottomColor: '#ffdd39',
                },
            }
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "transparent",
                    backgroundColor: '#transparent'
                },
                "&:hover $notchedOutline": {
                    borderColor: 'transparent'
                },
                "&$focused $notchedOutline": {
                    borderColor: 'transparent'
                }
            }
        },
    },
});