import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { Container, Grid, Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// import Order from './Order';
// import OrderDetail from './OrderDetail';
import OrderNew from './OrderNew';
import OrderDetailNew from './OrderDetailNew';

import ImgHeader from './Layouts/ImgHeader';

import './css/styles.css';

export default function OrderHistoryNew() {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    let history = useHistory();

    const { t, i18n } = useTranslation();
    let { oid } = useParams();

    // ---------------------------- API ----------------------------------------
    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
    }, []);

    // ------------------------- FUNCTIONS -------------------------------------
    const displaySubPage = () => {
        if(oid) {
            return <OrderDetailNew t={t} i18n={i18n} oid={oid} language={i18n.language} />
        } else {
            return <OrderNew t={t} i18n={i18n} language={i18n.language} />
        }
    }

    return (
        <Box>
            <ImgHeader title={oid ? t('order.details') : t('title.order')} menuButton={false} normalDisplay imageDisplay={false} imageLongDisplay={true} backUrl={oid ? '/order?type=new' : '/home'} />

            <Box className={styles.orderHistoryPage}>
                { displaySubPage() }
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    orderHistoryPage: {
        borderRadius: '30px 30px 0px 0px',
        background: '#f3f3f3',
        position: 'absolute',
        top: 200,
        width: '100%',
        paddingBottom: 100,
    }
}));