import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import TextLoop from "react-text-loop";
import _ from 'lodash';

import { getUrl } from '../helper/ApiAction';
import { storeWalletBalance , changeLanguage as reduxChangeLanguage} from '../actions';
import { ENV } from '../configs/Config';
import NumberFormat from 'react-number-format';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Card, CardContent, Button, Typography, Container, Grid, IconButton, Link, Box, Hidden, CardMedia, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Avatar, Menu, MenuItem } from '@material-ui/core';
import { Favorite, ChatBubble, RssFeedOutlined, Language, ArrowDropDown } from '@material-ui/icons';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import WebpImg from './Layouts/WebpImg';
import TitleBar from './Layouts/TitleBar';

import './css/styles.css';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Hidden only='xs'>
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
                onClick={onClick}
            />
        </Hidden>
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Hidden only='xs'>
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
                onClick={onClick}
            />
        </Hidden>
    );
}

export default function Home() {
    const theme = useTheme();
    const styles = useStyles();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const isMountedRef = useRef(null);
    const dispatch = useDispatch();
    const descriptionElementRef = useRef(null);
    let history = useHistory();

    const { t, i18n } = useTranslation();
    const { accessToken, walletBalance } = useSelector(state => ({
        accessToken: state.general.accessToken,
        walletBalance: state.user.walletBalance,
    }));
    const { name, id, username, email, mobile, rank } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();
    const {currency,currencyRate,currencydisplay} = useSelector(state => state.general);

    const [recommend, setRecommend] = useState(null);
    const [collections, setCollections] = useState(null);
    const [slideBanner, setSlideBanner] = useState(null);
    const [announcements, setAnnouncements] = useState([]);
    const [viewAnnouncement, setViewAnnouncement] = useState({});
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);
    const [uniqueid, setUniqueid] = useState();
    // const [categories, setCategories] = useState(null);
    // const [suggestion, setSuggestion] = useState(null);
    // const [advBanner, setAdvBanner] = useState(null);
    // const [iconImg , setIconImg ]= useState();
    // const [walletBalance, setWalletBalance] = useState({});
    // const [topSellers, setTopSellers] = useState(null);
    // const [premiumStores,setPremiumStores] = useState(null);
    // const [sideImg, setSideImg] = useState({
    //     category: '',
    //     wishlist: '',
    //     order: '',
    // });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    let currency_rate = currencyRate;
    let currency_code = [];

    _.map(currencydisplay,(currency_detail,index)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
    });

    useEffect(() => {
        isMountedRef.current = true;

        getUrl('products', { exclude: 'collections' }).then(productList => {
            if (isMountedRef.current) {
                setRecommend(productList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        getUrl('collections').then(collectionList => {
            if (isMountedRef.current) {
                setCollections(collectionList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })

        getUrl('sliderbanners').then(slideBannerList => {
            if (isMountedRef.current) {
                setSlideBanner(slideBannerList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        if(accessToken){
            getUrl('wallets/balance').then(response => {
                if(response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                    // setWalletBalance(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
            
            getUrl('member_announcements').then(res => {
                if (isMountedRef.current && res.status) {
                    setAnnouncements(res.data);
                    setUnreadAnnouncementCount(res.new_count);
                    let show  = { 0 :false};
                    let today = new Date();
                    
                    _.map(res.data, (row, index)=>{
                        if(row.popup && _.size(row['users']) === 0 && today >= new Date(row.popup_sdate) && today <= new Date(row.popup_edate) ) show[index] = true;
                    });
                    setViewAnnouncement({...viewAnnouncement, ...show});
                }
            }).catch(error => {
                // addAlert(JSON.stringify(error.message));
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        if (viewAnnouncement !== "") {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [viewAnnouncement]);

    useEffect(() => {
        let date = new Date();
        var  unid = date.getHours()+""+date.getMinutes()+""+date.getSeconds()+"id"+id;
        setUniqueid(unid);
        // eslint-disable-next-line
    }, []);

    const closeAnnouncement = (index) => {
        setViewAnnouncement({ ...viewAnnouncement, [index]: false });
        getUrl(`member_announcements/${announcements[index]['id']}`).then(result => {
            if (result.status === 1) {
                setUnreadAnnouncementCount(unreadCount - 1);
                window.location.reload();
            }
        }).catch((error) => {
        });
    }

    const productItemCard = (product) => {
        let bvDisplay = product.bv;
        let priceDisplay = product.sell_price;
        let showRetail = false;
        let discountPercent = 0;

        if(parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(priceDisplay)) {
            showRetail = true;
            discountPercent = ((parseFloat(product.retail_price) - parseFloat(priceDisplay)) / parseFloat(product.retail_price)) * 100;
            discountPercent = Math.round(discountPercent);
        }

        return(
            <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                <Card variant="outlined" className={styles.productHover} >
                    <CardMedia style={{ textAlign: "center" }}>
                        <WebpImg
                            containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            src={product ? product.images_array[0] : ''}
                            style={{ width: '100%', padding: '0', display: 'block',  objectFit: 'cover', height: 195, maxHeight:150, margin: "auto" }}
                            alt={product[`title_${i18n.language}`]}
                        />
                    </CardMedia>
                    <CardContent >
                        <div style={{ textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <Tooltip title={product[`title_${i18n.language}`]}>
                                    <Typography variant="body2" color="textSecondary" className={styles.productTitleText}>
                                        {product[`title_${i18n.language}`]}
                                    </Typography>
                                </Tooltip>
                            </div>

                            <Grid container style={{ paddingTop: 10 }}>
                                <Grid item xs={8}>
                                    {
                                        showRetail
                                            ?
                                            <Typography align="center" variant="body2" style={{ fontSize: 11, height: 18, fontWeight: 'bold', textDecoration: 'line-through', textAlign: 'left' }}>
                                                <NumberFormat value={(product.retail_price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                            </Typography>
                                            : <Typography style={{ height: 18 }} />
                                    }
                                    <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}>
                                        <NumberFormat value={(priceDisplay * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
                                    <Typography style={{ height: 18 }} />
                                    <Typography align="center" variant="body1" style={{ fontSize: 10, fontWeight: '400px', textAlign: 'right', color: "#646464" }}>
                                        <NumberFormat value={product?.purchased_quantity} decimalScale={2} displayType={'text'} thousandSeparator={true} />&nbsp; {t(`item.sold`)}
                                    </Typography>

                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                    {
                        showRetail ?
                            <Box className={styles.showRetailBox}>
                                <Box className={styles.showRetailText}>
                                    {`${discountPercent}% `}
                                    {`${t('general.discount')}`}
                                </Box>
                            </Box>
                            : null
                    }
                </Card>
            </Link>
        )
    }

    const shopProductCard = (product) => {
        let bvDisplay = product.bv;
        let priceDisplay = product.sell_price;
        let showRetail = false;
        let discountPercent = 0;

        if(parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(priceDisplay)) {
            showRetail = true;
            discountPercent = ((parseFloat(product.retail_price) - parseFloat(priceDisplay)) / parseFloat(product.retail_price)) * 100;
            discountPercent = Math.round(discountPercent);
        }

        return(
            <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                <Box className={styles.collectionBox}>
                    <WebpImg
                        containerStyle={{ overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        src={product ? product.images_array[0] : ''}
                        className={styles.collectionImage}
                        // style={{ width: '100%', padding: '0', display: 'block',  objectFit: 'cover', height: 195, maxHeight:150, margin: "auto" }}
                        alt={product[`title_${i18n.language}`]}
                    />
                    {
                        <Typography align="center" variant="body1" color="secondary" className={styles.collectionText} >
                            <NumberFormat value={(priceDisplay*currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                        </Typography>
                    }
                    {
                        showRetail ?
                            <Box className={styles.showRetailBox}>
                                <Box className={styles.showRetailText}>
                                    {`${discountPercent}% `}
                                    {`${t('general.discount')}`}
                                </Box>
                            </Box>
                            :
                            null
                    }
                </Box>
            </Link>
        )
    }

    const affiliatedBanner = () =>{
        return (
            <Box>
                <Grid container style={{ marginTop: 30, marginBottom: 15 }}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{t('home.affiliatedProgram')}</Typography>
                            <Link underline='none' color="inherit" to={`/affiliant-partner`} component={RouterLink} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                            <Typography color="primary" style={{ fontSize: 14, flex: 1 }}>{ `${t('shop.seeAll')} >` }</Typography>
                            </Link>
                    </div>
                </Grid>
                <Grid container spacing={1} justify="center" style={{padding:0, boxShadow:'0px 6px 11px 0 #00000026', borderRadius:15}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AutoplaySlider
                            play={true}
                            cancelOnInteraction={false}
                            interval={3000}
                            bullets={false}
                            className={'partner'}
                            organicArrows={false}
                            >
                                {/*Lazada campaign*/}
                                <div className="awssld__content">
                                    <a href={`https://accesstra.de/000lmx001xkn?pbid=${id}`}><img src="/images/campaigns/lazada.jpg" alt="campaigns" className='partnerbanner'/></a>
                                </div>
                                {/*Shopee campaign*/}
                                <div className="awssld__content">
                                    <a href={`https://accesstra.de/0029wi001xkn?pbid=${id}`}><img src="/images/campaigns/shopee.png" alt="campaigns" className='partnerbanner' /></a>
                                </div>
                                {/*Haloje campaign*/}
                                {
                                    ENV == 'liv'?
                                    <div className="awssld__content">
                                    <a href={`https://halo.express/home/express?ref=mstore&uniqueid=${uniqueid}`}><img src="/images/haloje/haloje.png" alt="campaigns" style={{ objectFit: 'contain', display: 'block', width: '100%', }}/></a>
                                    </div>
                                    :
                                    <div className="awssld__content">
                                    <a href={`https://stagingweb.halo.express?ref=mstore&uniqueid=${uniqueid}`}><img src="/images/haloje/haloje.png" alt="campaigns" style={{ objectFit: 'contain', display: 'block', width: '100%', }}/></a>
                                    </div>
                                }
                                {/*Zalora campaign*/}
                                <div className="awssld__content">
                                    <a href={`https://accesstra.de/000ltn001xkn?pbid=${id}`}><img src="/images/campaigns/Zalora.jpg" alt="campaigns" className='partnerbanner' /></a>
                                </div>
                                {/*CK campaign*/}
                                <div className="awssld__content">
                                    <a href={`https://accesstra.de/0075dm001xkn?pbid=${id}`}><img src="/images/campaigns/ck.jpg" alt="campaigns" className='partnerbanner'/></a>
                                </div>
                                {/*JD Sport campaign*/}
                                <div className="awssld__content">
                                    <a href={`https://accesstra.de/003t9d001xkn?pbid=${id}`}><img src="/images/campaigns/bigBrand-17.jpg" alt="campaigns" className='partnerbanner' /></a>
                                </div>
                                {/*Lotus campaign*/}
                                <div className="awssld__content">
                                    <a href={`https://accesstra.de/008s1j001xkn?pbid=${id}`}><img src="/images/campaigns/image-427408.jpg" alt="campaigns" style={{ objectFit: 'contain', display: 'block', width: '100%', }}/></a>
                                </div>
                                {/*Watson campaign*/}
                                <div className="awssld__content">
                                    <a href={`https://accesstra.de/000toz001xkn?pbid=${id}`}><img src="/images/campaigns/bigBrand-13.png" alt="campaigns" className='partnerbanner' /></a>
                                </div>
                            </AutoplaySlider>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box id='homepage' style={{ background: '#fff', paddingBottom: 85 }}>

            <div className='fixed-header-padding' style={{paddingTop: 0}}>
                <Container fixed className={styles.root} style={{ padding: '20px 20px 0px 20px' }}>
                    <Grid container spacing={1} style={{padding: '0px 10px', position: 'relative', height: '100%'}}>
                        <Grid item xs={8}>
                            {/* <Typography className={styles.titletext}>
                                {t(`general.welcome`)}, &nbsp;
                                <br />
                                <span style={{ textTransform: 'uppercase' }}  >
                                    {accessToken ? name : "Guest"}
                                </span>
                            </Typography> */}
                            {/* <img src="images/logo-h2u.png" style={{ objectFit: 'cover', aspectRatio: 1/1 }}/> */}
                            <WebpImg src="/images/logo-h2u.png" style={{width:'30%'}} alt="logo" containerStyle={{display: 'flex', alignItems: 'center'}} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ position: 'absolute', top: '0px', width: '100%', display:'flex', justifyContent:'flex-end', right:-20}}>
                            <Button
                                sx={{ borderRadius: 10 }}
                                onClick={handleClick}
                                variant="text"
                                startIcon={<Language style={{ width: 28, height: 28 ,color: '#0000005e'}}/>}
                                endIcon={<ArrowDropDown style={{ width: 28, height: 28 ,color: '#0000005e'}} />}
                                style={{width:'130px',maxHeight:'70px'}}
                            >
                                <Typography style={{width:'90px' , height:"20px"}}>{t('language.title')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ position: 'absolute', top: '50%', width: '100%', right: -20 }}>
                            <hr className={styles.line} style={{background: '#A8CF45'}} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} justify="center" style={{ padding: 0, boxShadow: '0px 6px 11px 0 #00000026', borderRadius: 15 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                autoplay={true}
                                bullets={false}
                                className='home'
                                // media={[
                                //     { source: '/images/slides/slide1.png', },
                                // ]}
                                // style={{ width: '100%' }}
                                organicArrows={false}
                                style={{height: 195}}
                            >
                                {
                                    _.size(slideBanner) > 0 && slideBanner !== null ?
                                    _.map(slideBanner, (slideBannerLists) => {

                                        return (
                                            <div className="awssld__content" key={slideBannerLists.id} style={{ objectFit: 'cover', width: '100%' }} >
                                                <WebpImg src={slideBannerLists.file_name} alt="slide" style={{objectFit: 'cover', objectPosition: 'center', width: '100%'}} />
                                            </div>
                                        )
                                    }) 
                                    : 
                                    null
                                }
                                {/* <div className="awssld__content">
                                <WebpImg src={`/images/cny/cnybanner-${i18n.language}.png`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%' }} />
                            </div> */}
                            </AutoplaySlider>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{paddingLeft: 28, paddingTop: 28, position: 'relative', height: '100%', marginTop: 28}}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{position: 'absolute', top: 10, width: '100%', left: -20}}>
                            <hr className={styles.line} style={{background: '#28844B'}} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{padding: '0px 11px', marginTop: 28}} >
                        <Grid item xs={3}>
                            <Link to="/event" component={RouterLink} underline='none'>
                                <Box className={styles.iconMenu}>
                                    {/* <WebpImg src="images/icon/1004, Call, Hospital Call, Medical, Message.png" style={{ objectFit: 'cover' }}/> */}
                                    <img src="images/icon/1004, Call, Hospital Call, Medical, Message.png" style={{ objectFit: 'cover', aspectRatio: 1/1 }}/>
                                </Box>
                                <Typography className={`${styles.textCss}, ${styles.iconText}`}>{t(`home.invite`)}</Typography>
                            </Link>
                        </Grid>

                        <Grid item xs={3}>
                            <Link to={accessToken? "/collection/1":"/"} component={RouterLink} underline='none'>
                                <Box className={styles.iconMenu}>
                                    {/* <WebpImg src="images/icon/Group 209.png" style={{ objectFit: 'cover' }}/> */}
                                    <img src="images/icon/Group 209.png" style={{ objectFit: 'cover', aspectRatio: 1/1 }}/>
                                </Box>
                                <Typography className={`${styles.textCss}, ${styles.iconText}`}>{t(`home.marketer`)}</Typography>
                                
                            </Link>
                        </Grid>

                        <Grid item xs={3}>
                            {/* <Link to={accessToken? "/consultant/all":"/"} component={RouterLink} underline='none'> */}
                                <Box className={styles.iconMenu}>
                                    {/* <WebpImg src="images/icon/1024, Health Care, Heart Care, Heart Disease, Insurance.png" style={{ objectFit: 'cover' }}/> */}
                                    <img src="images/icon/1024, Health Care, Heart Care, Heart Disease, Insurance.png" style={{ objectFit: 'cover', aspectRatio: 1/1 }}/>
                                </Box>
                                <Typography className={`${styles.textCss}, ${styles.iconText}`}>{t(`home.health`)}</Typography>
                            {/* </Link> */}
                        </Grid>

                        <Grid item xs={3}>
                            <Link to="/" component={RouterLink} underline='none'>
                                <Box className={styles.iconMenu}>
                                    {/* <WebpImg src="images/icon/1043, Medical, Monitor, Sign.png" style={{ objectFit: 'cover' }}/> */}
                                    <img src="images/icon/1043, Medical, Monitor, Sign.png" style={{ objectFit: 'cover', aspectRatio: 1/1 }}/>
                                </Box>
                                <Typography className={`${styles.textCss}, ${styles.iconText}`}>{t(`home.live`)}</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                    {/* <Box>
                        {affiliatedBanner()}
                    </Box> */}
                </Container>

                {
                    _.size(collections) > 0 ?
                        _.map(collections, collectionItem => {
                            if (_.size(collectionItem.product_ids_array) > 0 && collectionItem.id!==1) {
                                return (
                                    <Grid key={collectionItem.id} container spacing={0} justify="center" style={{ marginTop: 46 }}>
                                        <Grid item xs={12} style={{ padding: "0px 40px", textAlign: 'center' }}>
                                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                                    {/* <img src='images/cny/title-icon.png' style={{width:48, marginRight:0}}/> */}
                                                    <Typography variant="h6" style={{ fontWeight: 'bold' }} className={styles.titleText}>{collectionItem[`title_${i18n.language}`]}</Typography>
                                                </Box>
                                                <Link underline='none' color="inherit" to={`/collection/${collectionItem.id}`} component={RouterLink}>
                                                    <Typography color="primary" style={{ fontSize: 14, flex: 1 }}>{`${t('shop.seeAll')} >`}</Typography>
                                                </Link>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >
                                            {/* <Slider
                                                    dots= {true}
                                                    infinite= {true}
                                                    speed= {500}
                                                    slidesToShow= {1}
                                                    slidesToScroll= {1}

                                                >

                                                </Slider> */}

                                            <Slider
                                                infinite={true}
                                                speed={500}
                                                slidesToShow={1.5}
                                                slidesToScroll={1}
                                                centerPadding={50}
                                                autoplay={true}
                                                pauseOnHover={true}
                                                arrows={false}
                                                dots={true}
                                                centerMode={true}
                                                responsive={[
                                                    { breakpoint: 1024, settings: { slidesToShow: 1.5, slidesToScroll: 1, initialSlide: 1, infinite: true } },
                                                    { breakpoint: 992, settings: { slidesToShow: 1.5, slidesToScroll: 1, initialSlide: 1, infinite: true } },
                                                    { breakpoint: 600, settings: { slidesToShow: 1.5, slidesToScroll: 1, initialSlide: 1, infinite: true } },
                                                    // { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
                                                ]}
                                            // nextArrow={<NextArrow />}
                                            // prevArrow={<PrevArrow />}
                                            // style={{ marginLeft: -5 }}
                                            >
                                                {
                                                    _.map(_.slice(collectionItem.product_ids_array, 0, 6), (product) => {
                                                        return (
                                                            <Grid item xs={12} key={product.id} className={styles.cardContentRootStyle}>
                                                                {shopProductCard(product)}
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        })
                        :
                        null
                }

                <Container fixed className={styles.root} style={{ padding: '0px 20px 20px 20px' }}>
                    {/* <Box style={{backgroundColor:'transparent', height:15, width:'100%', height: 9, width: '100%', marginBottom:10, boxShadow:'0px 7px 15px 0 #22222226'}} /> */}
                    <Box style={{ padding: '0 15px' ,marginTop: 46}}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">

                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                {/* <img src='images/cny/title-icon.png' style={{ width: 48, marginRight: 0 }} /> */}
                                <Typography variant="h6" className={styles.titleText}>{t('home.userRecommend')}</Typography>
                            </Box>
                            <Link underline='none' color="inherit" to={`/category/all`} component={RouterLink}>
                                <Typography color="primary" style={{ fontSize: 14, flex: 1 }}>{`${t('shop.seeAll')} >`}</Typography>
                            </Link>
                        </Box>
                    </Box>
                    <Grid container spacing={1} justify="flex-start">
                        {/* <Hidden smDown>
                        <Grid item xs={6} sm={4} md={2} className={styles.cardContentRootStyle}>
                            <Box style={{ padding: '5px 0', height: '100%' }}>
                                <Box style={{ height: '100%', background: '#fecc3e', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px' }}>
                                    <Card style={{ marginBottom: 10, borderRadius: 15, maxWidth: '93%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                        <CardMedia style={{ height: '100%' }}>
                                            <WebpImg src={`/images/side_image/daily-${i18n.language}.png`} />
                                        </CardMedia>
                                    </Card>
                                </Box>
                            </Box>
                        </Grid>
                    </Hidden> */}
                        {/* <Grid component={Box}  width={1}> px={{ xs: 0, sm: 4 }} */}
                        {
                            _.map(recommend, (product) => {
                                return (
                                    <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                        {productItemCard(product)}
                                    </Grid>
                                )
                            })
                        }
                        {/* </Grid> */}
                    </Grid>
                    {
                        _.size(announcements) ?
                            _.map(announcements, (row, index) => (
                                row.popup && new Date() >= new Date(row.popup_sdate) && new Date() <= new Date(row.popup_edate) ?
                                    <Dialog
                                        open={viewAnnouncement[index] ? true : false}
                                        onClose={() => closeAnnouncement(index)}
                                        scroll="body"
                                        aria-labelledby="view Announcement"
                                        aria-describedby="view Announcement detail"
                                        key={index}
                                    >
                                        <DialogTitle id={`scroll-dialog-title${index}`}>{row[`title_${i18n.language}`]}</DialogTitle>
                                        <DialogContent dividers={false}>
                                            <div
                                                id="viewAnnouncement"
                                                ref={descriptionElementRef}
                                                tabIndex={-1}

                                            >
                                                {row['image'] ?
                                                    // <img src={announcements[viewAnnouncement]['image']['file_name']} style={{ maxWidth: "80vw"}}></img>
                                                    <WebpImg containerStyle={{ maxWidth: "80vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={row['image']['file_name']} style={{ width: '100%', padding: '0', display: 'block', borderRadius:15, objectFit: 'cover', maxWidth: "80vw" }} alt="announcement" />
                                                    : null
                                                }
                                                <div dangerouslySetInnerHTML={{ __html: row['content'][`${i18n.language}`] }} />
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => closeAnnouncement(index)} color="primary">{t("button.close")}</Button>
                                        </DialogActions>
                                    </Dialog>
                                    : null
                            ))
                            : null
                    }
                </Container>
            </div>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                disableScrollLock={true}
            >
                <MenuItem onClick={() => changeLanguage('en')}>{'ENG'}</MenuItem>
                <MenuItem onClick={() => changeLanguage('cn')}>{'中文'}</MenuItem>
            </Menu>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
        maxWidth:'100%'
    },
    textCss: {
        color: '#858585',
        fontStyle: 'normal',
        lineHeight: 'normal',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    cardRootStyle: {
        margin: '0px 1px 0px 1px'
    },
    buttonFont: {
        fontSize: '10px',
    },
    buttonStyle: {
        margin: '5px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    listItemStyle: {
        justifyContent: 'center',
        padding: '5px 0px 5px 0px',
    },
    cardStyle: {
        // minHeight: 380,
        borderRadius: 0
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
    },
        loopText: {
        width: '85vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    titletext: {
        color: '#858585',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        textTransform: 'capitalize',
    },
    line: {
        height: 9,
    },
    iconMenu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        background: '#F4F4F4',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF',
        padding: 12,
        width: '100%',
        height: '74%',
        aspectRatio: 1/1,
        transition: '0.5s',
        '-webkit-transition': '0.5s',
        '-o-transition': '0.5s',
        '&:hover': {
            boxShadow: '2px 2px 8px 0px #cfcfcf, -2px -2px 4px 0px #fff',
        }
    },
    productHover:{
        borderRadius: '0px !important',
        border: 'none !important',
        height: '100%',
        position: 'relative',
        top: 0,
        '-webkit-transition': '0.5s',
        '-o-transition': '0.5s',
        top: 0,
        transition: '0.5s',
        "&:hover":{
            boxShadow:'0 0 19px 0 #00000029',
            borderRadius: '0 0 0 0',
            top: -5,
        }
    },
    productTitleText: {
        wordWrap: 'break-word', 
        textOverflow: 'ellipsis', 
        display: '-webkit-box', 
        textAlign: 'start', 
        overflow: 'hidden', 
        width: 150, 
        height: 30, 
        lineHeight: '15px', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 2
    },
    collectionBox: {
        position: 'relative',
    },
    collectionImage: {
        borderRadius: 10,
        objectFit: 'cover',
        height: 206,
        width: 154,
    },
    collectionText: {
        color: '#989898',
        textAlign: 'center',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginTop: 8,
    },
    showRetailText: {
        color: '#FFF',
        textAlign: 'center',
        fontSize: 9,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        lineHeight: 1,
        padding: 1,
        width: 29,
    },
    showRetailBox: {
        position:"absolute" ,
        width: 38,
        height: '100%',
        top:0, 
        right:0,
        backgroundImage: 'url("/images/Ribbon.png")',
        backgroundRepeat: "no-repeat",
        paddingTop: 6,
    },
    iconText: {
        color: '#858585',
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'uppercase',
        marginTop: 8,
        textAlign: 'center',
    }
}));