import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from '../helper/Tools';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { APP_NAME } from '../configs/Config';
import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, List, ListItem, Typography, Box, Button, Avatar, InputAdornment, IconButton, TextField, Divider } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import TitleBarWhite from './Layouts/TitleBarWhite';

export default function AccountLink() {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { id, username, email, mobile } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const [refreshLinkSource, setRefreshLinkSource] = useState(false);
    const [linkSource, setLinkSource] = useState([]);
    const [unlinkState, setUnlinkState] = useState({
        open: false,
        source: '',
        source_username: '',
        password: '',
        showPassword: false
    });

    const query = useQuery();
    const qStatus = query.get('status');
    const qSource = query.get('source');

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`account-link/user`).then(response => {
            if (isMountedRef.current && response.status) {
                setLinkSource(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        if(parseInt(qStatus) === 1){
            addAlert('', 'success', t('accountLink.linkSuccess', {'source': qSource}), '');
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, refreshLinkSource, qStatus, qSource]);

    const accountLink = (source, type) => {
        setLoading(true);
        let params = {
            url_type: 'verify_linking',
            source: source,
            type: type,
            user_id: id,
            username: username?username:id
        }
        getUrl(`account-link/get-url`, params).then(response => {
            setLoading(false);
            if(response.status === 1){
                // console.log('window.location.href');
                window.location.href = response.data;
            }else{
                addAlert(response.data);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    const handleUnlinkOpen = (source, source_username) => {
        setUnlinkState({
            open: true,
            source: source,
            source_username: source_username,
            password: '',
            showPassword: false
        });
    }
    const handleUnlinkClose = () => {
        setUnlinkState({
            open: false,
            source: '',
            source_username: '',
            password: '',
            showPassword: false
        });
    }
    const accountUnlink = () => {
        setLoading(true);
        setUnlinkState({...unlinkState, open: false});
        let params = {
            username: username?username:(email?email:mobile),
            password: unlinkState.password,
            source: unlinkState.source,
        }
        postUrl(`account-link/request-unlink`, params).then(response => {
            setLoading(false);
            if(response.status){
                setRefreshLinkSource(!refreshLinkSource);
                addAlert('', 'success', t('accountLink.unlinkSuccess', {'source': unlinkState.source}), '');
                handleUnlinkClose();
            }else{
                setUnlinkState({...unlinkState, open: true});
                if(response.error){
                    addAlert(JSON.stringify(_.map(response.error).join(' ')));
                }else{
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            setLoading(false);
            setUnlinkState({...unlinkState, open: true});
            addAlert(JSON.stringify(error.message));
        });
    }

    const goToIshopping = () => {
        getUrl(`/login-to/ishopping`).then(response => {
            setLoading(false);
            if(response.status === 1 && response.url){
                window.open(response.url, '_blank').focus();
            }else{
                addAlert(t('general.loginRedirectError'));
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box className={styles.root} style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%' }}>
            <div style={{position:'fixed', width:450, maxWidth:'100%', zIndex:2}}>
                <TitleBarWhite height={0} title={t('accountLink.title')} displayInfo={false} back />
            </div>
            <Grid container spacing={1} justify="center" style={{padding:'40px 15px 0'}}>
                <Grid item xs={12} className={styles.root} >
                    <List className={styles.listStyle} style={{ boxShadow:'none', backgroundColor:'transparent' }}>
                        <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                            <Box style={{color:theme.palette.primary.main}}>
                                <Typography variant="h6">
                                    {t('accountLink.title')}
                                </Typography>
                            </Box>
                        </ListItem>
                        <Divider variant="middle" light />
                        <ListItem className={styles.listPaddingVertical}>
                            {
                                _.size(linkSource) > 0 ?
                                <Grid container spacing={2} alignItems="center">
                                    {
                                        _.map(linkSource, (sourceUser, source) => {
                                            return(
                                                <Grid item xs={12} key={source}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <Avatar>{source.charAt(0)}</Avatar>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1">{source}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body1">
                                                                { sourceUser ? sourceUser : t('accountLink.noLinkUser') }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            {
                                                                sourceUser
                                                                ?
                                                                <>
                                                                {
                                                                    source === 'ishopping'
                                                                    ?
                                                                    <Button variant="contained" color="secondary" onClick={() => goToIshopping()}>{t('button.login')}</Button>
                                                                    :
                                                                    null
                                                                }
                                                                </>
                                                                // <Button variant="contained" color="secondary" onClick={() => handleUnlinkOpen(source, sourceUser)}>{t('accountLink.unlink')}</Button>
                                                                :
                                                                <Button variant="contained" color="secondary" onClick={() => accountLink(source, 'link')}>{t('accountLink.link')}</Button>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                : 
                                <Box>
                                    <Typography variant="body1">
                                        {t('accountLink.noLinkSource')}
                                    </Typography>
                                </Box>
                            }
                        </ListItem>
                    </List>
                </Grid>
                <Dialog open={unlinkState.open} onClose={() => handleUnlinkClose()}>
                    <DialogTitle id="scroll-dialog-title">{t('accountLink.linkAccountTitle', {'type': t('accountLink.unlink'), 'source': unlinkState.source, 'source_user_id': unlinkState.source_username})}</DialogTitle>
                    <DialogContent dividers={false}>
                        <TextField
                            label={ APP_NAME+'\'s '+t('register.password') }
                            type={unlinkState.showPassword ? 'text' : 'password'}
                            value={unlinkState.password}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>,
                                endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => setUnlinkState({ ...unlinkState, showPassword: !unlinkState.showPassword })} onMouseDown={(event) => event.preventDefault() } edge="end">
                                            {unlinkState.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            variant="outlined"
                            color='primary'
                            fullWidth={true}
                            className={ styles.paddingVertical10 }
                            onChange={({ target }) => setUnlinkState({ ...unlinkState, password: target.value }) }
                        />
                    </DialogContent>
                    <DialogActions style={{justifyContent: "center"}}>
                        {/* <Button variant="contained" color="primary" size="large" style={{width: "30%"}} onClick={accountUnlink}>
                            {t('accountLink.unlink')}
                        </Button> */}
                        <Button variant="contained" size="large" style={{width: "30%"}} onClick={() => handleUnlinkClose()}>
                            {t('accountLink.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
}));