import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { storeProfile } from '../actions';

import { TextField, Button, Typography, Grid, Box, Breadcrumbs, Link, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { getUrl, postUrl, putUrl } from '../helper/ApiAction';

import useNotificationLoading from '../helper/useNotificationLoading';


export default function Kyc() {
    const { t, i18n } = useTranslation();
    const { name, email, verified } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        name: "",
        email: "",
        mobile: "",
        nric: "",
        country: "",
        zip: "",
        city: "",
        state: "",
        address2: "",
        address: ""
    });
    const [inputErrors, setInputErrors] = useState([]);

    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [kycData, setKycData] = useState({ remark: "" });

    const styles = useStyles();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                    stateList: addressList.state_list,
                    cityList: addressList.city_list,
                    districtList: addressList.district_list,
                    countryList: addressList.country_list
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        getUrl(`user/kyc`).then(res => {
            if (isMountedRef.current) {
                let { status, data } = res;
                if(status){
                    setKycData({...kycData, ...data});
                    if(_.size(data) > 0){
                        setState({
                            ...state,
                            name: data.name,
                            email: data.email,
                            mobile: data.mobile,
                            nric: data.nric,
                            country: data.address.country,
                            zip: data.address.zip,
                            city: data.address.city,
                            state: data.address.state,
                            address2: data.address.address2,
                            address: data.address.address,
                        })
                    }else{
                        setState({
                            ...state,
                            name: name,
                            email: email,
                        })
                    }
                }
            }

        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCity([]);
        setDistrict([]);

        if (state.country) {
            if (_.includes(addressGroup.stateGroup, state.country)) {
                let newList = [];
                addressGroup['stateList'].map((option) => {
                    if(option.country_code === state.country){
                        newList.push(option);
                    }
                    return newList;
                })
                setAddressState(newList);
            }else{
                setAddressState([]);
            }
        }
        // eslint-disable-next-line
    }, [state.country]);

    useEffect(() => {
        setDistrict([]);
        if (state.state) {
            if (_.includes(addressGroup.stateGroup, state.country) && _.includes(addressGroup.cityGroup, state.state+'')) {
                let newList = [];
                addressGroup['cityList'].map((option) => {
                    if(option.state_id === state.state+''){
                        newList.push(option);
                    }
                    return newList;
                })
                setCity(newList);
            }else{
                setCity([]);
            }
        }
        // eslint-disable-next-line
    }, [state.state]);

    useEffect(() => {
        if (state.city) {
            if (_.includes(addressGroup.stateGroup, state.country) && _.includes(addressGroup.cityGroup, state.state+'') && _.includes(addressGroup.districtGroup, state.city+'')) {
                let newList = [];
                addressGroup['districtList'].map((option) => {
                    if(option.city_id === state.city+''){
                        newList.push(option);
                    }
                    return newList;
                })
                setDistrict(newList);
            }else{
                setDistrict([]);
            }
        }
        // eslint-disable-next-line
    }, [state.city]);

    const handleInputChange = (event) => {
        const name = event.target.name;
        const type = event.target.type;
        if(type !== "text"){
            if (name === "country") {
                setState({ ...state, [name]: event.target.value, state: "", city: "", address2: "" });
            }
            else if (name === "state")  {
                setState({ ...state, [name]: event.target.value, city: "", address2: "" });
            }
            else if (name === "city")  {
                setState({ ...state, [name]: event.target.value, address2: "" });
            }else{
                setState({ ...state, [name]: event.target.value });
            }
        }else{
            setState({ ...state, [name]: event.target.value });
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const fileOnChange = async event => {
        let name = event.target.name;
        if(event.target.files[0]){
            let fileBase64 = await toBase64(event.target.files[0]);
            setState({...state, [name]: fileBase64});
        }else{
            setState({...state, [name]: null});
        }
    };

    const submitForm = () => {
        setInputErrors([]);
        setLoading(true);

        let apiData = {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
            nric: state.nric,
            country: state.country,
            zip: state.zip,
            city: state.city,
            state: state.state,
            address2: state.address2,
            address: state.address,
            upload_nric: state.upload_nric,
            upload_address: state.upload_address,
            upload_selfie: state.upload_selfie,
        };

        let apiMethod = postUrl;
        if(verified > 0) apiMethod = putUrl;

        apiMethod(`user/kyc`, apiData).then(response => {
            setLoading(false);
            let { status, message, error, data } = response;
            if (status === 1) {
                addAlert(message, 'success', '', '');
                setKycData({...kycData, ...data.kyc});
                dispatch(storeProfile(data.profile));
            } else {
                if (_.size(error) > 0) {
                    _.map(error, (value, key) => {
                        message += "\n" + value[0];
                    })
                    setInputErrors(error);
                }
                addAlert(message, 'error', '', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box style={{ paddingTop: '2%' }}>
            <Grid container justify="center" className={styles.breadcrumbRoot} style={{ margin: '0', maxWidth: '100%' }}>
                <Grid item xs={10} md={10} className={styles.breadcrumbRoot} style={{ maxWidth: '100%' }}>
                    <Grid container style={{ marginBottom: '10px' }}>
                        <Breadcrumbs separator="›" maxItems={2} aria-label="breadcrumb" style={{ fontSize: 12, padding: '0 10px' }}>
                            <Link underline='none' color="secondary" href="/">
                                {t('title.home')}
                            </Link>
                            <Typography style={{ fontSize: 12, color: 'light' }}>{t('title.kyc')}</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} justify="center" className={styles.root}>
                <Grid container spacing={2} item xs={12} sm={10} style={{ boxShadow: '0 0 6px #bbbbbb', borderRadius: 15, backgroundColor: '#fff', marginBottom: 20, padding: 15 }}>
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Typography variant="subtitle2">{t(`kyc.status`)}: {t(`kyc.statuses.${verified}`)}</Typography>
                        { kycData.remark && verified === 2 ?
                            <Typography variant="body2">{t(`kyc.remark`)}: {kycData.remark}</Typography>
                            : null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('kyc.name')}
                            variant="outlined"
                            fullWidth
                            inputProps={{ name: 'name' }}
                            onChange={handleInputChange}
                            variant="outlined"
                            type="text"
                            value={state.name}
                            error={inputErrors.name ? true : false}
                            helperText={inputErrors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('kyc.email')}
                            variant="outlined"
                            fullWidth
                            inputProps={{ name: 'email' }}
                            onChange={handleInputChange}
                            variant="outlined"
                            type="text"
                            value={state.email}
                            error={inputErrors.email ? true : false}
                            helperText={inputErrors.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('kyc.mobile')}
                            variant="outlined"
                            fullWidth
                            inputProps={{ name: 'mobile' }}
                            onChange={handleInputChange}
                            variant="outlined"
                            type="text"
                            value={state.mobile}
                            error={inputErrors.mobile ? true : false}
                            helperText={inputErrors.mobile}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('kyc.nric')}
                            variant="outlined"
                            fullWidth
                            inputProps={{ name: 'nric' }}
                            onChange={handleInputChange}
                            variant="outlined"
                            type="text"
                            value={state.nric}
                            error={inputErrors.nric ? true : false}
                            helperText={inputErrors.nric}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('address.country')}
                            fullWidth
                            variant="outlined"
                            select
                            value={state.country}
                            onChange={handleInputChange}
                            inputProps={{ name: 'country' }}
                            error={inputErrors.country ? true : false}
                            helperText={inputErrors.country}
                        >
                            <MenuItem value="">{t('address.pleaseSelect')}{t('address.country')}</MenuItem>
                            {
                                _.map(addressGroup.countryList, countryItem => {
                                    return (
                                        <MenuItem key={countryItem.code} value={countryItem.code}>{countryItem[`name_${i18n.language}`]}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('address.zip')}
                            variant="outlined"
                            fullWidth
                            inputProps={{ name: 'zip' }}
                            onChange={handleInputChange}
                            variant="outlined"
                            type="text"
                            value={state.zip}
                            error={inputErrors.zip ? true : false}
                            helperText={inputErrors.zip}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('address.state')}
                            fullWidth
                            variant="outlined"
                            value={state.state}
                            onChange={handleInputChange}
                            select={_.includes(addressGroup.stateGroup, state.country) && _.size(addressState) > 0 ? true : false}
                            inputProps={{ name: 'state' }}
                            error={inputErrors.state ? true : false}
                            helperText={inputErrors.state}
                        >
                            <MenuItem value="">{t('address.pleaseSelect')}{t('address.state')}</MenuItem>
                            {
                                _.map(addressState, stateItem => {
                                    return (
                                        <MenuItem key={stateItem.id} value={stateItem.id}>{stateItem[`name_${i18n.language}`]}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('address.city')}
                            variant="outlined"
                            select={_.size(cities) > 0 ? true : false}
                            fullWidth
                            value={state.city}
                            onChange={handleInputChange}
                            inputProps={{ name: 'city' }}
                            error={inputErrors.city ? true : false}
                            helperText={inputErrors.city}
                        >
                            <MenuItem value="">{t('address.pleaseSelect')}{t('address.city')}</MenuItem>
                            {
                                _.map(cities, cityItem => {
                                    return (
                                        <MenuItem key={cityItem.id} value={cityItem.id}>{cityItem[`name_${i18n.language}`]}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="address2"
                            label={t('address.address2')}
                            select={_.size(district) > 0 ? true : false}
                            fullWidth
                            variant="outlined"
                            value={state.address2}
                            onChange={handleInputChange}
                            inputProps={{ name: 'address2' }}
                            error={inputErrors.address2 ? true : false}
                            helperText={inputErrors.address2}
                        >
                            <MenuItem value="">{t('address.pleaseSelect')}{t('address.address2')}</MenuItem>
                            {
                                _.map(district, districtItem => {
                                    return (
                                        <MenuItem key={districtItem.id} value={districtItem.id}>{districtItem[`name_${i18n.language}`]}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('address.address')}
                            fullWidth
                            onChange={handleInputChange}
                            variant="outlined"
                            type="text"
                            inputProps={{ name: 'address' }}
                            value={state.address}
                            error={inputErrors.address ? true : false}
                            helperText={inputErrors.address}
                            multiline
                            rows={3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('kyc.attachment_nric')}
                            fullWidth
                            onChange={fileOnChange}
                            variant="outlined"
                            type="file"
                            inputProps={{ name: 'upload_nric', accept: "image/*, .pdf" }}
                            InputLabelProps={{ shrink: true }}
                            error={inputErrors.upload_nric ? true : false}
                            helperText={inputErrors.upload_nric}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('kyc.attachment_address')}
                            fullWidth
                            onChange={fileOnChange}
                            variant="outlined"
                            type="file"
                            inputProps={{ name: 'upload_address', accept: "image/*, .pdf" }}
                            InputLabelProps={{ shrink: true }}
                            error={inputErrors.upload_address ? true : false}
                            helperText={inputErrors.upload_address}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('kyc.attachment_selfie')}
                            fullWidth
                            onChange={fileOnChange}
                            variant="outlined"
                            type="file"
                            inputProps={{ name: 'upload_selfie', accept: "image/*, .pdf" }}
                            InputLabelProps={{ shrink: true }}
                            error={inputErrors.upload_selfie ? true : false}
                            helperText={inputErrors.upload_selfie}
                        />
                    </Grid>
                    {_.size(kycData.upload_nric) > 0 &&
                        <Grid item xs={12} sm={6}>
                            <Typography>{t('kyc.uploadedAttachment')}</Typography>
                            <div><a href={kycData.upload_nric.file_name} target="_blank">{t('kyc.attachment_nric')}</a></div>
                            <div><a href={kycData.upload_address.file_name} target="_blank">{t('kyc.attachment_address')}</a></div>
                            <div><a href={kycData.upload_selfie.file_name} target="_blank">{t('kyc.attachment_selfie')}</a></div>
                        </Grid>
                    }
                    {verified < 3 &&
                        <Grid item xs={12}>
                            <Button onClick={submitForm} variant="contained" color="secondary">{t('button.submit')}</Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
    },
}));