import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink,useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Card, CardContent, Typography, Container, Grid, Link, CardMedia, Tooltip } from '@material-ui/core';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';
import { FiChevronLeft } from 'react-icons/fi';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { RepeatRounded } from '@material-ui/icons';
import { ENV } from '../configs/Config';


import TitleBar from './Layouts/TitleBar';

export default function VPCollection() {
    const history = useHistory();
    let query = useQuery();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const { id } = useSelector(state => state.user);
    const [uniqueid, setUniqueid]= useState();
    useEffect(() => {
        let date = new Date();
        var  unid = date.getHours()+""+date.getMinutes()+""+date.getSeconds()+"id"+id;
        setUniqueid(unid);
        // eslint-disable-next-line
    }, []);
    

    const collection = [
        { code: 'my', title: {'link': `/collection/6`, 'imag': '/images/campaigns/lazada.jpg'}},
        { code: 'id', title: {'link': `/collection/7`, 'imag': '/images/campaigns/shopee.png'}},
        { code: 'th', title: {'link': `/collection/8`, 'imag': '/images/campaigns/bigBrand-13.png'}},
        ];

    const collectionBanner = (title,link,imag) => {
        return(
            <Grid item xs={12} sm ={12} style ={{marginTop:30}} >  
                <a  className='productHover' style={{ textDecoration: 'none' }} href={`${link}`}>
                    <Card variant="outlined" style={{ maxHeight :'150px',borderRadius: '15px', position: 'relative',boxShadow: '5px 5px 10px grey' }}>
                        <CardMedia>
                            <WebpImg  containerStyle={{  overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={`${imag}`} style={{ width: '100%', padding: '0', display: 'block', objectFit: 'contain',borderTopLeftRadius: '15px',borderTopRightRadius: '15px'}} alt="regions" />
                        </CardMedia>
                    </Card>
                </a>
            </Grid>
        
        )
        
    }
    return (
        <Box className={styles.root2} >         
            <TitleBar height={70} title={t('home.affiliatedProgram')} displayInfo currencyButton displayCart back pagetitle />
            <div className='fixed-header-padding'>
            <Container maxWidth='lg' >
                <Box style={{ color: theme.palette.primary.main, paddingBottom:5 }}>
                    <Typography variant="h6">
                        {t('home.vpCollection')}
                    </Typography>
                </Box>
                <Grid container spacing={1}  >
                    {
                        _.map(collection,collectionList =>{
                            return(
                                collectionBanner(collectionList["code"],collectionList.title.link,collectionList.title.imag)
                            )
                            
                        })
                    }
                </Grid>
            </Container>
            </div>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    Tooltip:{
        fontSize:21
    },
    root2: {
        background: theme.palette.white.mobileBkg,
        minHeight: '100%'
    },
    
}));

const tooltipStyle = createMuiTheme();
const muiTheme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "15px",
                color: "#fff",
                backgroundColor: "#000000bf"
            }
        }
    }
});
