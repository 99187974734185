import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';
import QRCode from 'qrcode.react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Button, Grid, Box, Typography, Link, Modal, Backdrop, Fade, } from '@material-ui/core';

import TitleBar from './Layouts/TitleBar';
import WebpImg from './Layouts/WebpImg';

import ImgHeader from './Layouts/ImgHeader';

import { FiCopy, FiSettings } from 'react-icons/fi';
import './css/styles.css';


export default function Recruit() {

    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const shareLink = useRef(null);
    const { id, max_rank , username} = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const copyShareLink = () => {
        if(max_rank.code>0 || true){
            var textField = document.createElement('textarea');
            textField.innerText = `${window.location.origin}/register?r=${btoa(id)}`;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
    
            addAlert(t('profile.copySuccess'), "success");
        }
    };

    return (
        <Box className={styles.root} >
            <ImgHeader cardDisplay title={t('title.eventTitle')}/>

            <div className='p-lr-20' style={{ marginTop: -60 }}>
                <div className='pos-relative flex-c-m'>
                    <div style={{ width: '100%', height: 170, borderRadius: 15, background: 'linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%)', transform: 'rotate(355deg)' }} />
                        <div className='pos-absolute card-transparent p-all-20 flex-sb flex-col card-text txt-upper'>
                            
                            <div className='flex-c-str w-full'>
                                <p>
                                    <b>
                                        {t('eventPromo.inviteFriends')}
                                    </b>
                                </p>
                            </div>

                            <div className='flex-sb-m w-full p-lr-10'>
                                <p className='txt-center fs-12'>
                                    {t('eventPromo.inviteFriendsText')}
                                </p>
                            </div>

                            <div style={{ backgroundColor: '#FFFFFF',color: '#7B7B7B', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0px 10px 0',  borderRadius: 15, boxShadow:'inset 3px 3px 4px 0 rgba(0, 0, 0, 0.10)', width:'100%', }}>

                                <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', marginLeft: '15px'}} ref={shareLink} >
                                    {(max_rank.code > 0 || true) ? `${window.location.origin}/register?r=${btoa(id)}` : t('eventPromo.activeUserOnly')}
                                </Typography>

                                <FiCopy style={{ fontSize: 28 ,padding: '3px', backgroundImage: 'linear-gradient(#00B09B, #96C93D)', borderRadius: '50%', marginRight:'15px' , color: '#FFF'}} onClick={copyShareLink} />

                            </div>

                        </div>
                </div>
            </div>

            <Typography style={{ textAlign: 'center', marginTop: 15, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold'}} ref={shareLink} >
                {username}
            </Typography>

            <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3}>
                <QRCode size='250' style={{ padding: 10,  borderRadius: 5, boxShadow: '2px 2px 4px 0px rgba(1, 1, 1, 0.13), -3px -3px 4px 0px #FFF'}} value={`${window.location.origin}/register?r=${btoa(id)}`} renderAs="svg" fgColor={theme.palette.primary.main} />
            </Box>

            {/* <div className={styles.shareButtonPosition}> */}
            <div style={{ backgroundImage: 'linear-gradient(#00B09B, #96C93D)', padding: '10px 15px', width: '75%', maxWidth: '75%', margin: '0 auto', cursor: 'pointer', borderRadius: '48px' , boxShadow: '2px 2px 4px 0px rgba(1, 1, 1, 0.13), -3px -3px 4px 0px #FFF'}} className='' onClick={copyShareLink} >
                <Typography style={{ textAlign: 'center', fontSize: 18 , color: '#FFF'}}><b>{t('eventPromo.inviteNow')}</b></Typography>
            </div>
            {/* </div> */}
            {/* <TitleBar height={70} title={t('profile.recruit')} displayInfo   currencyButton displayCart logoIcon /> */}
            {/* <div className='fixed-header-padding'>
                <div className={styles.letterPosition}>
                    <div style={{position:'relative'}}>
                        <img src="/images/general/invite/invitation-full.png" alt="invite" style={{ width:'100%', position: 'absolute', bottom:-500 }}/>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', position: 'absolute', bottom: -480 }}>
                            <div className={styles.paperStyle}>
                                <Typography className={styles.inviteTitle}>{t('eventPromo.inviteFriends')}</Typography>
                                    <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3}>
                                        <QRCode value={`${window.location.origin}/register?r=${btoa(id)}`} renderAs="svg" fgColor={theme.palette.primary.main} />
                                    </Box>
                                <div style={{ color: theme.palette.text.dtext, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 15,  borderRadius: 15, boxShadow:'0 0 6px 0 #0003', width:'100%', }}>
                                    <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }} ref={shareLink} >{(max_rank.code > 0 || true) ? `${window.location.origin}/register?r=${btoa(id)}` : t('eventPromo.activeUserOnly')}</Typography>
                                    <FiCopy style={{ fontSize: 20 }} onClick={copyShareLink} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src="/images/general/invite/invitation-front2.png" alt="invite" style={{ width: '100%', position: 'absolute', bottom:-500  }} />
                        </div>
                        <div className={styles.shareButtonPosition}>
                            <div style={{ padding: '10px 15px', width: 150, maxWidth: '75%', margin: '0 auto'}} className='red-button-style' onClick={copyShareLink} >
                                <Typography style={{ textAlign: 'center', fontSize: 18 }}><b>{t('eventPromo.inviteNow')}</b></Typography>
                            </div>
                        </div>
                    </div>
                    <Modal
                        className={styles.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={styles.paper}>
                                <div style={{ textAlign: "center" }}>
                                    <h2 style={{ marginBottom: 0 }}>{t('eventPromo.tncTitle')}</h2>
                                    <p style={{ marginTop: 5 }}>
                                        <b>{t('eventPromo.tncDes')}</b>
                                    </p>
                                </div>
                                <p>{t('eventPromo.tncDes1')}</p>
                                <p>{t('eventPromo.tncDes2')}</p>
                                <p>{t('eventPromo.tncDes3')}</p>
                                <p>{t('eventPromo.tncDes4')}</p>
                                <p><i><i style={{ paddingTop: 5 }}>{t('eventPromo.tncDes5')}</i></i></p>
                            </div>
                        </Fade>
                    </Modal>
                </div>
            </div> */}
        
        </Box>

    )
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paperStyle: {
        width: 324,
        maxWidth: '80%',
        height: 500,
        borderRadius: 15,
        boxShadow: 'rgba(0, 0, 0, 0.2) 3px 6px 9px 0px',
        padding:20,
        background: '#fafcff',
        borderTop: '2px solid #ffffff',
        borderLeft: '2px solid #ffffff',
        [theme.breakpoints.down('xs')]: {
            height: 307,
        },
      },
      inviteTitle:{
        fontSize:21,
        color:theme.palette.gray.dtext,
        textAlign:'center',
        fontWeight:'bold',
        paddingBottom:15
      },
      settingIcon:{
        borderRadius: 15,
        boxShadow: '0 0 6px 0 #0003',
        padding: 10,
        width: 60,
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color:theme.palette.primary.main
    },
    shareButtonPosition:{
        position:'absolute',
        bottom:-350,
        width:'100%',
        [theme.breakpoints.down('xs')]: {
            bottom: 130,
        },
    },
    letterPosition:{
        [theme.breakpoints.down('xs')]: {
            padding: 35,
        },
        [theme.breakpoints.up('sm')]: {
            padding: '77px 50px 0',
        },
    },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius:15,
        boxShadow: theme.shadows[5],
        padding: 20,
        width: 480,
        maxWidth: '80%',
        margin: '0px auto',
      },

    root: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg, 
        padding:0,
        minHeight: '100%',
        paddingBottom: 35
    },

    containerPadding: {
        
        [theme.breakpoints.down('xs')]: {
            padding:'80px 20px 0'
        },
        [theme.breakpoints.up('sm')]: {
            padding:'80px 40px 0'
        },    
    },

    // prizePosition: {
    //     position: 'absolute',
    //     left: 0,
    //     width:'100%',    
    // },
    titleFontSize:{
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
    descriptionFont:{
        fontSize: 14,
        maxWidth: '83%',
        margin: '0px auto',
        color: '#dedede',
        width: 350,
    },
}));