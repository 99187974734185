import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { Grid, Typography, Box, Card, CardContent, Link, CardMedia, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import FavoriteIcon from '@material-ui/icons/Favorite';
import WebpImg from './Layouts/WebpImg';

import { getUrl, deleteUrl } from '../helper/ApiAction';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Wishlist(props) {

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    let query = useQuery();
    const pageNumber = query.get("page");

    const [wishlistProducts, setWishlistProducts] = useState([]);
    const [page, setPage] = useState(pageNumber ? parseInt(pageNumber) : 1);
    const [totalPage, setTotalPage] = useState(1);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    

    const getData = () => {
        getUrl(`wishlist/product?page=${page ? page : 1}`).then(response => {
            if (response) {
                if (isMountedRef.current) {
                    let { current_page, last_page } = response.data.meta;
                    setWishlistProducts(response.data.data);
                    setTotalPage(last_page);
                    setPage(current_page ? current_page : 1);
                    setLoading(false);
                    if(current_page > last_page && current_page != 1){
                        setPage(1);
                    }
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getData();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, addAlert]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const dislikeProduct = (event, value) => {
        event.preventDefault();
        let newList = [...wishlistProducts];
        deleteUrl(`wishlist/product/${newList[value]['id']}`).then(response => {
            if (response) {
                if (isMountedRef.current) {
                    newList[value] = {...wishlistProducts[value], user_wishlist: false };
                    setWishlistProducts(newList);
                    addAlert(t('item.productDislike'));
                    getData();
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });       
    };

    return (
        <Grid container spacing={1} justify="center" style={{ minHeight: 450, }}>
            <Grid xs={12} md={10} lg={10} item container spacing={1} justify="center">
                {wishlistProducts.length > 0 ?
                    <Grid item xs={12} sm={12} style={{ padding: '0 20px' }}>
                        <Grid container spacing={1}>
                            {_.map(wishlistProducts, (product, index) => {
                                let showRetail = false;
                                let discountPercent = 0;
                                if(parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(product.sell_price)) {
                                    showRetail = true;
                                    discountPercent = ((parseFloat(product.retail_price) - parseFloat(product.sell_price)) / parseFloat(product.retail_price)) * 100;
                                    discountPercent = Math.round(discountPercent);
                                }
                                const title_translate = _.split(product.title, '|');
                                return (
                                    <Grid item xs={6} sm={4} md={4} lg={3} key={product.id} className={styles.cardContentRootStyle}>
                                        <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                                            <Card variant="outlined" style={{ borderRadius: '15px', position: 'relative' }}>
                                                <CardMedia style={{ padding: 10 }}>
                                                    {/* <WebpImg containerStyle={{height:150, maxHeight:150, overflow:'hidden'}} src={product ? product.images_array[0] : ''} style={{ height: 150 }} /> */}
                                                    <WebpImg containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={product ? product.images_array[0] : ''} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 195, maxHeight:150 }} alt="product" />
                                                    {
                                                        product.user_wishlist ? 
                                                        <Tooltip title={t('wishlist.dislikeThis')}>
                                                            <FavoriteIcon fontSize="small" style={{ color: 'red', position: 'absolute', right: 10 }} onClick={(e) => dislikeProduct(e, index)}/> 
                                                        </Tooltip>
                                                        : null                                                                
                                                    }
                                                </CardMedia>
                                                {/* <CardMedia 
                                                    style={{ height: '150px' }}
                                                    image={product ? product.images_array[0] : ''}
                                                /> */}
                                                <CardContent style={{paddingBottom:24}}>
                                                    <Tooltip title={i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}>
                                                        <Typography variant="body2" color="textSecondary" className='txt_truncate2'>
                                                            {i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}
                                                        </Typography>
                                                    </Tooltip>
                                                    {
                                                        showRetail ?
                                                            <Typography align="center" variant="body2" style={{ fontSize: 10, fontWeight: 'bold', height:18, textDecoration: 'line-through' }}>
                                                            <NumberFormat value={ product.retail_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } `} />
                                                            {/* {`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } ${product.retail_price}`} */}
                                                        </Typography>
                                                        :  <Typography style={{ height: 18 }}></Typography>
                                                    }
                                                    <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                        <NumberFormat value={ product.sell_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } `} />
                                                        {/* {`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } ${product.sell_price}`} */}
                                                    </Typography>
                                                </CardContent>
                                                {
                                                    showRetail ?
                                                    <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                        <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                    </Box>
                                                    : null
                                                }
                                            </Card>
                                        </Link>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid container spacing={1} justify="center" >
                            <Pagination count={totalPage} shape="rounded" page={page}
                                onChange={handleChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                        component={RouterLink}
                                        to={`/account/likes${item.page === 1 ? '' : `?page=${item.page}`}`}
                                        {...item}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid item xs={12} sm={12} style={{ padding: '20px', textAlign: 'center' }}>
                        <h1>{t('wishlist.noResult')}</h1>
                        <Link underline='none' to={`/`} component={RouterLink}>
                            <Button variant="contained" color="secondary" size="large">
                                <Typography variant="button">{ t('wishlist.goShopping') }</Typography>
                            </Button>
                        </Link>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
      },
}));