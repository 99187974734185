import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles,  useTheme, Typography, AppBar, Tabs, Tab, Box, Link, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import TitleBar from './Layouts/TitleBar';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';


export default function Contract() {
    const [value, setValue] = useState(0);
    const [nextBatch, setNextBatch] = useState('-');
    const [autoRenewContract, setAutoRenewContract] = useState(false);
    const [contract, setContract] = useState();
    const [lotEntries, setLotEntries] = useState();
    const [subAccount, setSubAccount] = useState();
    const [totalActiveLot, setTotalActiveLot] = useState(0);
    const [renewAmount, setRenewAmount] = useState(0);
    
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('contract_orders').then(response => {
            console.log("response", response);
            if(response.status && isMountedRef.current) {
                setContract(response.data[0]);
                if(_.size(response.data) > 0) {
                    setAutoRenewContract(response.data[0].auto_renew > 0 ? true : false);
                }
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })

        getUrl('current_batch').then(response => {
            console.log("current_batch response", response);
            if(response.status && isMountedRef.current) {
                if(_.size(response.data)) {
                    const possibleNextBatch = response.data.batch_no + 1;
                    const newNextBatch = possibleNextBatch < 18 ? possibleNextBatch : '-';
                    setNextBatch(newNextBatch)
                }
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })

        // get renew amount for next batch
        getUrl('renew_amount').then(result => {
            if(result.status && isMountedRef.current) {
                setRenewAmount(result.data);
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })

        return () => isMountedRef.current = false;
    }, [addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        if(value === 0) {
            getUrl('lot_entries').then(response => {
                console.log("lot_entries response", response);
                if(response.status && isMountedRef.current) {
                    setLotEntries(response.data);
                    const sumActiveLot = _.sumBy(response.data, (o) => {
                        return o.status === 20 ? o.lot_units : 0
                    })
                    setTotalActiveLot(sumActiveLot);
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            })
        } else if(value === 1) {
            getUrl('bind_account').then(response => {
                console.log("bind_account response", response);
                if(response.status && isMountedRef.current) {
                    setSubAccount(response.data);
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            })
        }

        return () => isMountedRef.current = false;
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const changeContractAutoRenew = () => {
        putUrl(`contract_orders/${contract.id}`, { auto_renew: !autoRenewContract }).then(response => {
            if(response.status) {
                setAutoRenewContract(!autoRenewContract)
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })
    }

    return (
        <Box style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}} >{/* className={styles.root} style={{ padding: '0 0 80px'}} */}
            <TitleBar title={ t('title.contract') } displayQr />

            
            <Box style={{ padding: '0 15px', marginTop:'-58px' }}>
                <Box className={classes.goldFrame}>
                <Box className={classes.frameBkg} style={{ padding:15 }}>
                    <Box display="flex" flexDirection="column" paddingBottom={2} borderBottom='1px dashed' borderColor={theme.palette.secondary.brown} alignItems="center">
                        <Typography component='span' style={{ fontSize: 18, fontWeight: 'bold', color:theme.palette.secondary.brown }}>{ t('contract.currentContract', { contractName: contract ? contract.contract_name_display : '-' }) }</Typography>
                        <Typography component='span' style={{ fontSize: 12, color:theme.palette.secondary.brown }}>{ `${t('contract.joinedAt')}: ${contract ? contract.created_at : '-'}` }</Typography>
                    </Box>
                    <Box paddingTop={2} display="flex" justifyContent="space-around">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography component='span' style={{ fontSize: 21, fontWeight: 'bold', color:theme.palette.secondary.brown }}>{ contract ? contract.contract.lot : '-' }</Typography>
                            <Typography component='span' style={{ fontSize: 12, color:theme.palette.secondary.brown }}>{ t('contract.totalLot') }</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography component='span' style={{ fontSize: 21, fontWeight: 'bold', color:theme.palette.secondary.brown }}>{ totalActiveLot || '-' }</Typography>
                            <Typography component='span' style={{ fontSize: 12, color:theme.palette.secondary.brown }}>{ t('contract.activeLot') }</Typography>
                        </Box>
                    </Box>
                </Box>
                </Box>
            </Box>

            { _.size(contract) > 0 &&
                <Box style={{ padding: '0 15px', margin:'20px 0' }}>
                    <Box className={classes.goldFrame}>
                        <Box className={classes.frameBkg}>
                            <Box paddingY={2} paddingX={3} display="flex" flexDirection="column">
                                <Box display="flex" flexDirection="row" justifyContent="space-between" color={theme.palette.secondary.brown}>
                                    <Typography>{t('contract.nextBatch')}</Typography>
                                    <Typography>{nextBatch}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" color={theme.palette.secondary.brown}>
                                    <Typography>{t('home.nextBatchRenewAmount')}</Typography>
                                    <Typography>RP {renewAmount}</Typography>
                                </Box>
                                <Box display="flex" paddingTop={1} flexDirection="row" justifyContent="space-between"  color={theme.palette.secondary.brown}>
                                    <Typography>{t('contract.autoRenew')}</Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            classes={{ root: classes.formControlLabelRoot }}
                                            control={<Switch edge="end" checked={autoRenewContract} onChange={changeContractAutoRenew} />}
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
            <>
                <AppBar position="static" color="default" style={{backgroundColor:'transparent', boxShadow:'none'}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label={ t('lotEntry.title') } />
                        <Tab label={ t('contract.subAccount') } />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {/* /////////  Contract /////////*/}
                    <TabPanel value={value} index={0} dir={theme.direction} style={{padding:'24px 0'}}>  
                        { _.map(lotEntries, lotEntryItem => {
                            if(contract && (contract.id === lotEntryItem.contract_order_id)){
                                return <LotEntry key={lotEntryItem.id} data={lotEntryItem} contract={contract} />
                            }
                        }) }                  
                    </TabPanel>

                    {/* /////////  Sub Account /////////*/}
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        { _.map(subAccount, subAccountItem => {
                            return <SubAccount key={subAccountItem.id} data={subAccountItem} />
                        })}
                    </TabPanel>
                </SwipeableViews>
            </>
        </Box>
    );
}

const SubAccount = (props) => {
    const { data } = props;

    const theme = useTheme();
    const { t } = useTranslation();

    let corder = [];
    if(data && data.contract){
        _.map(data.contract, c => {
            if(c.status === 20){
                corder = c;
            }
        });
    }

    return (
        <Box style={{ borderRadius: 15, backgroundColor: theme.palette.primary.white, boxShadow: '0 0 11px 0 #d2d2d2', padding: '15px 5%', marginBottom:50 }}>
            <Box style={{borderBottom:'1px solid #d8d8d8'}}>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingBottom:15 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ backgroundColor: theme.palette.secondary.main, borderRadius: '50%', width: 60, height: 60, padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: 30, color: theme.palette.primary.white, fontWeight: 'bold' }}>{ data ? data.username[0] : '-' }</span>
                        </div>
                    </div>
                    <div style={{ color: theme.palette.silver.text, paddingLeft:20 }}>
                        <Typography style={{ color:theme.palette.secondary.main, fontWeight: 'bold' }}>{ data ? data.username : '-' }</Typography>
                        <Typography style={{ fontSize: 13 }}>{ data ? data.mobile || '-' : '-' }
                        {/* <span style={{padding:'0 10px'}}>|</span>MALAYSIA */}
                        </Typography>
                        <Typography style={{ fontSize: 13 }}>{t('contract.contract')} : { data ? corder ? corder.contract_name_display : '-' : '-' }</Typography>
                    </div>
                </Box>
            </Box>
            <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingTop:10}}>
                <Link underline='none' to={`/contract/${data.username}`} component={RouterLink}>
                    <Typography style={{whiteSpace:'nowrap', fontSize:14, color: theme.palette.secondary.main}}>{ t('contract.viewDetail') }</Typography>
                </Link>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <Typography style={{ fontSize: 12, color: theme.palette.silver.text, textTransform: 'uppercase' }}>{ t('contract.joinedAt') }</Typography>
                    <Typography style={{ fontSize: 12, color: theme.palette.silver.text }}>{ data ? corder ? corder.created_at : '-' : '-'}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

const LotEntry = (props) => {
    const { data, contract } = props;
    const [lotWorth, setLotWorth] = useState(0);

    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if(contract && data) {
            const lotEntryWorth = (parseFloat(contract.amount) / contract.contract.lot) * data.lot_units;
            setLotWorth(lotEntryWorth);
        }
    }, [data, contract]);

    return (
        <Box style={{ borderRadius: 15, backgroundColor: theme.palette.primary.white, boxShadow: '0 0 6px 0 #dadada', padding: '15px 5%', marginBottom:50 }}>
            <Box style={{borderBottom:'1px solid #d8d8d8'}}>
                <Box style={{ backgroundColor: theme.palette.secondary.main, width: '80%', margin: '0 auto', borderRadius: 48, marginTop: '-29px' }}>
                    <Typography style={{ textAlign: 'center', padding: '4px 15px', color: theme.palette.primary.white, fontSize: 18, letterSpacing: 1, fontWeight: 'bold' }}>{ data ? t(`lotEntry.status.${data.status}`) : '-' }</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '15px 0' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ backgroundColor: theme.palette.secondary.main, borderRadius: '50%', width: 45, height: 45, padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 25, color: theme.palette.primary.white, fontWeight: 'bold' }}>{ data.level }</Typography>
                        </div>
                        <Typography style={{ textAlign: 'center', paddingTop: 5, fontSize: 13, color: theme.palette.silver.text }}>{ t('lotEntry.level') }</Typography>
                    </div>
                    <div style={{ color: theme.palette.silver.text, paddingLeft:20 }}>
                        <Typography style={{ fontSize: 15, color:theme.palette.secondary.main }}>{ t('lotEntry.contractAmount', { amount: lotWorth.toFixed(2) }) }</Typography>
                        {
                            data && data.joined_at_batch > 0
                            ?
                            <Typography style={{ fontSize: 13 }}>{ t('lotEntry.batchQueueAmount', { 'amount' : data ? data.joined_at_batch : '-' }) }</Typography>
                            :
                            <Typography style={{ fontSize: 13 }}>{ t('lotEntry.estimatedBatchQueueAmount', { 'amount' : data ? data.estimated_joined_at_batch : '-' }) }</Typography>
                        }
                        <Typography style={{ fontSize: 13 }}>{ t('lotEntry.activeLotAmount', { 'amount' : data ? data.lot_units : '-' }) }</Typography>
                    </div>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop={2}>
                { data.status === 30 && <Link to={`/renew-package/${data.id}`} underline="none" component={RouterLink}>
                    <Typography component='span' style={{whiteSpace:'nowrap', fontSize:14, color: theme.palette.secondary.main}}>{ t('lotEntry.renewNow') }</Typography>
                </Link> }
                <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%">
                    <Typography component='span' style={{ fontSize: 12, color: theme.palette.silver.text }}>{ t('lotEntry.joinedAt') }</Typography>
                    <Typography component='span' style={{ fontSize: 12, color: theme.palette.silver.text }}>{ data ? data.joined_at || '-' : '-' }</Typography>
                </Box>
            </Box>
        </Box>
    )
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    { children }
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',       
    },
    formControlLabelRoot: {
        margin: 0
    },
    goldFrame:{
        boxShadow:'rgb(0 0 0 / 32%) 0 0 5px 0',
        padding: 5,
        borderRadius: 15,
        background:'#fff',
        // background: 'rgb(168,120,33)',
        // background: 'linear-gradient(90deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)'
    },
    frameBkg:{
        // background: 'background: rgb(209,170,105)',
        // background: 'linear-gradient(180deg, rgba(209,170,105,1) 0.81%, rgba(234,205,158,1) 21.88%, rgba(233,204,155,1) 77.03%, rgba(225,185,110,1) 100%)',
        // borderRadius: 11,
    }
}));