import React, { useLayoutEffect, useRef, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { OrgChart } from 'd3-org-chart';
import '../css/tree.css';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';

const MemberButtonDrawer = (props) => {
    const { children, onClose , open} = props;
    const styles = useStyles();
    const theme = useTheme();
    return (
        <MuiThemeProvider theme={fieldStyle}>
            <Drawer anchor={'bottom'} open={open} onClose={onClose} disableScrollLock={true}>
                {children}
            </Drawer>
        </MuiThemeProvider>
    )
};

const fieldStyle = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paperAnchorBottom: {
                top: 'auto',
                left: 0,
                right: 0,
                bottom: 0,
                height: 'auto',
                maxHeight: '100%',
                maxWidth: '420px',
                overflow: 'hidden',
                margin:'0 auto',
                borderRadius:'30px 30px 0px 0px'
            }
        },
    },
});

const useStyles = makeStyles(theme => ({

}));

export default MemberButtonDrawer