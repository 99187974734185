import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { updateCartTotal } from '../actions';

import _ from 'lodash';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField, Dialog, FormControl, Select, Card, CardContent, IconButton, Hidden, CardActionArea, Stepper, Step, StepLabel, Chip, Button, Typography, Grid, Link, Box, List, ListItem, useTheme, CardHeader, FormHelperText, Divider, CardMedia } from '@material-ui/core';
import { Collapse, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { getUrl, postUrl, putUrl } from '../helper/ApiAction';
import { Edit, Plus, Edit3 } from 'react-feather';
import useNotificationLoading from '../helper/useNotificationLoading';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';


// dialog
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import StorefrontIcon from '@material-ui/icons/Storefront';
import WebpImg from './Layouts/WebpImg';


// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const INITIAL_ADDRESS_STATE = { address: '', address2: '', city: '', state: '', zip: '', country: '' };

export default function VoucherCheckout() {

    const { name, email, mobile } = useSelector(state => state.user);
    const [buyerInfo, setBuyerInfo] = useState({
        name: name,
        email: email ? email : '',
        mobile: mobile ? mobile : '',
    });
    const [recipientInfo, setRecipientInfo] = useState({
        name: name,
        email: email ? email : '',
        mobile: mobile ? mobile : '',
    });
    const [userDialog, setUserDialog] = useState({
        type: '',
        open: false,
        name: '',
        email: '',
        mobile: '',
    });
    const [state, setState] = useState({
        open: false,
        error: false,
        message: '',
        modalShow: false,
        paymentId: null,
        addressId: null,
        totalPrice: 0,
        totalSelected: null,
        totalWalletPrice: {},
        totalShippingPrice: 0,
        walletBalance: {},
        selectAddressShow: false,
        orderSuccess: false,
        mode: null,
        editAddressId: null,
    });
    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
    })
    const [errorField, setErrorField] = useState({
        cart: []
    });
    const [cartErrors, setCartError] = useState([]);
    const [cart, setCart] = useState([]);
    const [updateCart, setUpdateCart] = useState(false);
    const [currencyData, setCurrencyData] = useState(null);
    const [addresses, setAddress] = useState(null);
    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [remark, setRemark] = useState({});
    const [mallShippingRefresh, setMallShippingRefresh] = useState(false);
    const [mallShipping, setMallShipping] = useState([]);
    const [mallTotalShipping, setMallTotalShipping] = useState(0);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [fetchingMallShipping, setFetchingMallShipping] = useState(false);
    const [shippingFeeOpen, setShippingFeeOpen] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [wallets, setWallets] = useState([]);



    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();


    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('carts')
                .then(cartList => {
                    if (isMountedRef.current) {
                        // console.log("cartList", cartList);
                        setCurrencyData(cartList.currency_data);
                        setCart(cartList.data);
                        calculateTotal(cartList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, updateCart]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('wallets/balance').then(response => {
                if (isMountedRef.current && response.status === 1) {
                    setWallets(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('user/address')
                .then(addressList => {
                    if (isMountedRef.current) {
                        setAddress(addressList.data);
                        // console.log("addressList.data", addressList.data);
                        let defaultAddress = _.find(addressList.data, { 'default': true });
                        if (_.size(defaultAddress) > 0) {
                            // setState(state => ({ ...state, addressId: defaultAddress.id, changedAddressId: defaultAddress.id }))
                            setState(state => ({ ...state, addressId: defaultAddress.id }))
                        } else {
                            if (_.size(addressList.data)) {
                                const firstAddress = addressList.data[0];
                                // setState(state => ({ ...state, addressId: firstAddress.id, changedAddressId: firstAddress.id }))
                                setState(state => ({ ...state, addressId: firstAddress.id }))
                            }
                        }
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [addAlert, t]);

    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            // console.log(countryList)
            if (isMountedRef.current) {
                setCountry(countryList.data);
                // const filteredCountryList = _.filter(countryList.data, countryItem => countryItem.code === 'CN' || countryItem.code === 'MY');
                // setCountry(filteredCountryList);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [i18n.language, addAlert, t, addressFormState.country]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.country) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if (isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.state) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.city) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (state.addressId !== null) {
            setDisabledSubmit(true);
            setFetchingMallShipping(true);
            const selectedAddress = _.find(addresses, { 'id': state.addressId });
            const updateData = {
                user_zip: selectedAddress ? selectedAddress.zip : '',
                user_state: selectedAddress ? selectedAddress.state : '',
                user_country: selectedAddress ? selectedAddress.country : '',
            }
            putUrl('mall/shipping_fee', updateData).then(response => {
                setFetchingMallShipping(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setMallShipping(response.data);
                        setDisabledSubmit(response.error);
                        setMallTotalShipping(response.total_shipping ? response.total_shipping : 0);
                    } else {
                        addAlert(JSON.stringify(response.data));
                        setDisabledSubmit(true);
                    }
                }
            }).catch(error => {
                setFetchingMallShipping(false);
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [addAlert, addresses, state.addressId, mallShippingRefresh]);

    const handleAddressChange = (event) => {
        const name = event.target.name;
        setAddressFormState({ ...addressFormState, [name]: event.target.value });
        if (name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };
    const openAddAddress = () => {
        setErrorAddressState({});
        setState(state => ({ ...state, mode: 'add', modalShow: true }));
    };
    const openEditAddress = selectedAddressId => {
        setErrorAddressState({});
        const editedAddress = _.find(addresses, { 'id': selectedAddressId });
        if (editedAddress) {
            const { address, address2, city, state, zip, country } = editedAddress;
            // console.log("editedAddress", editedAddress);

            setAddressFormState({ address, address2, city, state, zip, country });
            // setState({ ...state, mode: 'edit', modalShow: true, 'editAddressId': selectedAddressId });
            setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: selectedAddressId }));
            // console.log("state", state);
        }
    }
    const closeModal = () => {
        // setState({ ...state, modalShow: false, mode: null, 'editAddressId': null });
        setState(state => ({ ...state, modalShow: false, mode: null, 'editAddressId': null }));
        setAddressFormState(INITIAL_ADDRESS_STATE);
    };

    // const handleRemarkChange = (event, merchantId) => {
    //     setRemark({ ...remark, [merchantId]: event.target.value });
    //     // console.log('remark', remark);
    // }

    const handleShippingFeeClick = (merchantId) => {
        setShippingFeeOpen({ ...shippingFeeOpen, [merchantId]: !shippingFeeOpen[merchantId] });
    }
    const handleMallShippingFee = (event, merchantId) => {
        let newValue = parseInt(event.target.value);
        setMallShipping({
            ...mallShipping, [merchantId]: {
                ...mallShipping[merchantId], selected_rate: {
                    key: newValue,
                    shipping_fee: mallShipping[merchantId].rate_list[newValue].price,
                    courier_id: mallShipping[merchantId].rate_list[newValue].courier_id,
                    service_id: mallShipping[merchantId].rate_list[newValue].service_id,
                    courier_name: mallShipping[merchantId].rate_list[newValue].courier_name,
                }
            }
        });
        let tempMall = mallShipping;
        tempMall[merchantId].selected_rate = {
            key: newValue,
            shipping_fee: mallShipping[merchantId].rate_list[newValue].price,
            courier_id: mallShipping[merchantId].rate_list[newValue].courier_id,
            service_id: mallShipping[merchantId].rate_list[newValue].service_id,
            courier_name: mallShipping[merchantId].rate_list[newValue].courier_name,
        };
        let totalShippingFee = 0;
        _.map(tempMall, (value) => {
            if (!value.error && value.selected_rate) {
                totalShippingFee += parseFloat(value.selected_rate.shipping_fee);
            }
        })
        setMallTotalShipping(totalShippingFee);
    }

    const openUserDialog = (info) => {
        setUserDialog({

            open: true,
            name: info.name,
            email: info.email,
            mobile: info.mobile,
        });
    }
    const closeUserModal = () => {
        setUserDialog({ ...userDialog, open: false });
    }
    const saveUserModal = () => {

        setBuyerInfo({
            name: userDialog.name,
            email: userDialog.email,
            mobile: userDialog.mobile,
        })

        setRecipientInfo({
            name: userDialog.name,
            email: userDialog.email,
            mobile: userDialog.mobile,
        })

        setUserDialog({ ...userDialog, open: false });
    }

    const calculateTotal = userCart => {
        let totalPrice = 0;
        let totalSelected = 0;
        let totalWalletPrice = {};

        _.map(userCart, merchantItem => {
            setState(state => ({ ...state, totalShippingPrice: (parseFloat(state.totalShippingPrice) + parseFloat(merchantItem.shipping_fee)).toFixed(2) }));
            // totalWalletPrice['cash'] += parseFloat(merchantItem.shipping_fee);
            _.map(merchantItem.items, (cartDetails) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        totalSelected += parseInt(cartItem.quantity);
                        let itemPrice = parseFloat(cartItem.product.sell_price);
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (option) => {
                                _.map(option.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        itemPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : cartItem.product.sell_price
                                    }
                                })
                            })
                        }
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });

                            totalPrice += parseFloat((itemPrice * selectedPricing.cash_scale * cartItem.quantity).toFixed(2));
                            if (selectedPricing.wallet_id && parseFloat(selectedPricing.wallet_amount) > 0) {
                                let name = _.split(selectedPricing.wallet_name, '|');
                                let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                const old_amount = parseFloat(totalWalletPrice[display_name] ? totalWalletPrice[display_name] : 0);
                                totalWalletPrice[display_name] = (old_amount + parseFloat(selectedPricing.wallet_amount) * cartItem.quantity).toFixed(selectedPricing.decimal);
                            }
                        } else {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        }
                    }
                })
            })
        });
        setState(state => ({ ...state, totalPrice, totalSelected, totalWalletPrice }));
    };

    const submitOrder = () => {
        setCartError([]);
        if (state.addressId) {
            setLoading(true);

            const selectedAddress = _.find(addresses, { 'id': state.addressId });
            // console.log(selectedAddress);
            const orderField = {
                buyer_name: buyerInfo.name,
                buyer_email: buyerInfo.email,
                buyer_mobile: buyerInfo.mobile,
                recipient: recipientInfo.name,
                recipient_email: recipientInfo.email,
                mobile: recipientInfo.mobile,
                country: selectedAddress.country,
                zip: selectedAddress.zip,
                state: selectedAddress.state,
                city: selectedAddress.city,
                address: selectedAddress.address,
                address2: selectedAddress.address2,
                // remark,
                mall_shipping: mallShipping,
            }
            // console.log("orderField", orderField);

            postUrl('orders', orderField)
                .then(response => {
                    setLoading(false);
                    // console.log("response", response);
                    if (response.status === 1) {
                        // redirect to payment page
                        dispatch(updateCartTotal(0));
                        if (response.order_cancelled) {
                            history.push({ pathname: `account/order`, state: { error_msg: t('checkout.orderCancelled') } });
                        } else {
                            history.push(`/payment/${encodeURIComponent(response.data[0]['purchase_no'])}`);
                        }
                    } else {
                        // setState({ ...state, open: true, error: true, message: response.message });
                        setUpdateCart(!updateCart);
                        if (response.error) {
                            let newErrorField = {};
                            let displayErrorMsg = [];
                            _.map(response.error, (errorItem, errorIndex) => {
                                setCartError(response.error);
                                newErrorField[errorIndex] = errorItem[0]
                                displayErrorMsg.push(errorItem[0]);
                            })
                            setErrorField(newErrorField);
                            addAlert(_.map(displayErrorMsg).join(' '));
                        }
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                   
                });
        } else {
            // show error to select address
            addAlert(t('checkout.noAddressSelected'));
            // setState({ ...state, open: true, error: true, message: t('checkout.selectAddress') });
        }

    }


    const submitAddress = () => {
        if (state.mode === 'add') {
            postUrl('user/address', addressFormState)
                .then(result => {
                    if (result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        addAlert(t('profile.fillUpRequiredAddress'));
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        addAlert(result.message, 'success');
                        setState(state => ({ ...state, modalShow: false })); // , snackbarShow: true, error: false, message: result.message
                        getUrl('user/address').then(addressList => {
                            setAddress(addressList.data);
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        } else {
            putUrl(`user/address/${state.editAddressId}`, addressFormState)
                .then(result => {
                    if (result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        addAlert(t('profile.fillUpRequiredAddress'));
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        const refreshMall = (state.editAddressId === state.addressId);
                        setState(state => ({ ...state, modalShow: false, mode: null, editAddressId: null })); // , snackbarShow: true, error: false, message: result.message
                        addAlert(result.message, 'success');
                        getUrl('user/address').then(addressList => {
                            setAddress(addressList.data);
                            if (refreshMall) {
                                setMallShippingRefresh(!mallShippingRefresh);
                            }
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
    }

    // const priceDisplay = (cartItem) => {
    //     let productPrice = cartItem.product.sell_price;
    //     if (_.size(cartItem.product.options) > 0) {
    //         _.map(cartItem.product.options, (optionItem) => {
    //             _.map(optionItem.pricing, (pricingOption) => {
    //                 if (pricingOption.id === cartItem.product_warehouse_id) {
    //                     productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
    //                 }
    //             })
    //         })
    //     }
    //     return (
    //         <>
    //             {
    //                 (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) ?
    //                     _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
    //                         // const multiPricingId = cartItem.multi_pricing_id;
    //                         if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
    //                             let name = _.split(pricingItem.wallet_name, '|');
    //                             return (
    //                                 <Box key={pricingIndex} display="flex" flexDirection="column">
    //                                     {
    //                                         parseFloat(productPrice * pricingItem.cash_scale * cartItem.quantity) > 0 ?
    //                                             <Typography variant="overline">
    //                                                 <NumberFormat value={(productPrice * pricingItem.cash_scale * cartItem.quantity)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
    //                                             </Typography>
    //                                             : null
    //                                     }
    //                                     {
    //                                         pricingItem.wallet_id && parseFloat(pricingItem.wallet_amount) > 0 ?
    //                                             <Typography variant="overline">
    //                                                 <NumberFormat value={(parseFloat(pricingItem.wallet_amount) * cartItem.quantity)} decimalScale={pricingItem.decimal} displayType={'text'} thousandSeparator={true} prefix={` ${i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]} `} />
    //                                             </Typography>
    //                                             : null
    //                                     }
    //                                 </Box>
    //                             )
    //                         } else {
    //                             return null;
    //                         }
    //                     })
    //                     :
    //                     <Typography variant="h6" style={{ color: theme.palette.price.main }}>
    //                         <NumberFormat value={(cartItem.quantity * productPrice)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
    //                     </Typography>
    //             }
    //         </>
    //     )
    // }

    return (
        <Box>
            {/* <Grid container spacing={1} justify="center" className={ styles.breadcrumbRoot }>
                <Grid item xs={10} md={5} style={{ alignSelf: 'flex-end' }}>
                    <Link color="primary" underline='none'  href="/" style={{ color: theme.palette.gray.main }}>
                        <Typography variant="subtitle2">{ t('title.home') }</Typography>
                    </Link>
                    <Typography variant="h5" >{ t('title.checkout') }</Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                    <Stepper activeStep={1} alternativeLabel className={styles.stepperStyle}>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { root: styles.icon } }}>
                                { t('checkout.myCart') }
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { root: styles.icon } }}>
                                { t('checkout.fillInfo') }
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { text: styles.whiteText } }}>
                                { t('checkout.submitOrder') }
                            </StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
            </Grid> */}
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} >
                    <Card style={{ color: 'black', backgroundColor: 'white', height: '100%' }} variant="outlined">

                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 15px' }}>
                            <Typography variant="h6" style={{ color: '#adacac' }}>{t('checkout.buyerDetails')}</Typography>
                            <IconButton aria-label="settings" style={{ zIndex: 1, padding: 0 }} onClick={() => openUserDialog(buyerInfo)}>
                                <EditOutlinedIcon size={14} style={{ color: '#EE3B7D' }} />
                            </IconButton>

                        </Box>
                        <Divider variant="middle" />
                        <CardContent style={{ paddingTop: 0 }}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="subtitle2" style={{ padding: 5 }}>{buyerInfo.name} |  {buyerInfo.mobile}</Typography>
                                <Typography variant="subtitle2" style={{ padding: 5 }}>{buyerInfo.email}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {
                            errorField ?
                                _.map(errorField, (errorMsg, key) => {
                                    return (
                                       

                                                    <Grid key={errorMsg + key} item xs={12} sm={6} >
                                                        {
                                                            key === 'buyer_name' || key === 'buyer_mobile' || key === 'buyer_email' ?
                                                            <Typography variant="body1" color="error"style={{paddingLeft:5}}>{errorMsg}</Typography>
                                                            :null

                                                        }
                                                        
                                                    </Grid> 

                                    )
                                })
                                : null
                        }
                    </Grid>
                </Grid>
            </Grid>



            <Grid container spacing={1} style={{ marginTop: 30 }} direction="column" >
                <Grid item xs={12} >
                    <Box style={{ display: 'flex', alignItems: 'center', paddingRight: 10 }} justifyContent="flex-end">
                        <Button variant="contained" onClick={openAddAddress} disabled={fetchingMallShipping} style={{ display: 'flex', alignItems: 'center', width: '32px', height: '32px', minWidth: '32px', backgroundColor: '#EE3B7D' }} >
                            <Box style={{ lineHeight: '0' }}>
                                <Plus style={{ color: '#fff', lineHeight: '0' }} size={27} />
                            </Box>
                        </Button>
                        <Hidden only={['xs']}>
                            <Typography style={{ whiteSpace: 'nowrap', fontSize: '14px', marginLeft: '8px', color: '#EE3B7D' }}>{t('button.addAddress')}</Typography>
                        </Hidden>
                    </Box>
                </Grid>

                {
                    _.size(addresses) > 0 && addresses != null ?
                        <Grid item xs={12} >
                            <Card style={{ color: 'black', backgroundColor: 'white', height: '100%' }} variant="outlined">

                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 15px' }}>
                                    <Typography variant="h6" style={{ color: '#adacac' }}>{t('checkout.billingAddress')}</Typography>
                                </Box>
                                <Divider variant="middle" />
                                <CardContent style={{ paddingTop: 10 }}>
                                    <Grid container spacing={1}>
                                        {
                                            _.map(addresses, (addressItem) => {
                                                return (
                                                    <Grid item xs={6} sm={3} key={addressItem.id}>
                                                        <Card style={{ color: addressItem.id === state.addressId ? 'white' : 'black', backgroundColor: addressItem.id === state.addressId ? '#EE3B7D' : '#EFEFEF', height: '100%' }}>
                                                            <CardHeader
                                                                action={
                                                                    <IconButton aria-label="settings" style={{ zIndex: 1 }} onClick={() => openEditAddress(addressItem.id)} disabled={fetchingMallShipping} >
                                                                        <Edit size={14} color={addressItem.id === state.addressId ? 'white' : 'black'} />
                                                                    </IconButton>
                                                                }
                                                                style={{ paddingBottom: 0 }}
                                                            />
                                                            <CardActionArea onClick={() => setState({ ...state, addressId: addressItem.id })} style={{ height: '100%', marginTop: -46, paddingTop: 20, paddingBottom: 20 }} disabled={fetchingMallShipping} >
                                                                <CardContent style={{ paddingTop: 0 }}>

                                                                    <Typography variant="body2" style={{ textAlign: 'justify', paddingTop: 20 }}>
                                                                        {` ${addressItem.address}, ${addressItem.address2_display}, ${addressItem.city_display}, ${addressItem.state_display}, ${addressItem.zip}, ${addressItem.country_display}`}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        :
                        <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                            {
                                addresses === null ?
                                    <>
                                        <CircularProgress disableShrink style={{ color: '#EE3B7D' }} />
                                        <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                    </>
                                    :
                                    <Typography variant="caption">{t('checkout.noAddress')}</Typography>
                            }
                        </Box>
                }

            </Grid>

            <Grid container spacing={1} style={{ marginTop: 30 }} direction="column">
                <Grid item xs={12} sm={12}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" style={{ paddingRight: 10 }}>

                        <Link underline='none' color="inherit" to="/voucher_cart" component={RouterLink} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button variant="contained" style={{ display: 'flex', alignItems: 'center', width: '32px', height: '32px', minWidth: '32px', backgroundColor: '#EE3B7D' }} >
                                <Box style={{ lineHeight: '0' }}>
                                    <Edit3 style={{ color: '#fff', lineHeight: '0' }} size={27} />
                                </Box>
                            </Button>
                            <Hidden only={['xs']}>
                                <Typography style={{ whiteSpace: 'nowrap', fontSize: '14px', marginLeft: '8px', color: '#EE3B7D' }}>{t('checkout.backToCart')}</Typography>
                            </Hidden>
                        </Link>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <List>
                        {
                            state.totalSelected && state.totalSelected != null ?
                                _.map(cart, (merchantItem, merchantId) => {
                                    // console.log("cart", cart);
                                    let showMerchant = false;
                                    // let selfShippingFee = false;
                                    // let mallShippingFee = false;
                                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                        _.map(cartDetails, cartItem => {
                                            // console.log("cartItem", cartItem)
                                            if (cartItem.selected) {
                                                showMerchant = true;
                                                // if (shippingChannel === 'self') {
                                                //     selfShippingFee = true;
                                                // }
                                            }
                                        })
                                    })
                                    // _.map(mallShipping, (value, merId) => {
                                    //     if (merchantId === merId) {
                                    //         mallShippingFee = true;
                                    //     }
                                    // })
                                    if (showMerchant) {
                                        return (
                                            <Box key={merchantId} classes={{ root: styles.firstItemBoxStyle }}>
                                                <Box border={1} borderColor={theme.palette.gray.border} style={{ padding: '15px 20px', backgroundColor: 'white' }}>
                                                    <Box display="flex" flexDirection="row" alignItems="center">
                                                        <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
                                                            <StorefrontIcon fontSize="small" />
                                                            <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>{merchantItem.name}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                {
                                                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                                        return (
                                                            <Box key={shippingChannel}>
                                                                {
                                                                    _.map(cartDetails, (cartItem, cartIndex) => {
                                                                        if (cartItem.selected) {
                                                                            const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                                            let variant_translate = [];
                                                                            _.map(cartItem.product.options, (option) => {
                                                                                _.map(option.pricing, (pricingOption) => {
                                                                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                        if (option.color) {
                                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                                        }
                                                                                        if (option.size) {
                                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                                        }
                                                                                        if (option.attribute_value) {
                                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                                        }
                                                                                    }
                                                                                })
                                                                            });
                                                                            return (
                                                                                <Box border={1} key={cartItem.id} classes={{ root: styles.itemBoxStyle }}>
                                                                                    <ListItem style={{ backgroundColor: 'white' }}>
                                                                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>

                                                                                                <Box style={{ width: 100, minWidth: 100, maxWidth: 100, height: 'fit-content' }} border={1} borderColor={theme.palette.gray.border}>
                                                                                                    <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}>
                                                                                                        <CardMedia
                                                                                                            style={{ height: 75, width: 100 }}
                                                                                                            image={_.size(cartItem.product.images_array) > 0 ? cartItem.product.images_array[0] : '/images/no-image.png'}
                                                                                                            title={cartItem.product.title}
                                                                                                        /></Link>
                                                                                                </Box>

                                                                                                <Box style={{ paddingLeft: 10 }}>
                                                                                                    <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                                                                                                        <Grid item xs={4}>
                                                                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'flex-start' }}>
                                                                                                                <Grid>
                                                                                                                    <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography variant="caption">{title_translate}</Typography></Link>
                                                                                                                </Grid>
                                                                                                            </Box>
                                                                                                        </Grid>
                                                                                                        {
                                                                                                            _.size(variant_translate) > 0 ?
                                                                                                                <Grid item xs={6}>
                                                                                                                    <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                                                                    {
                                                                                                                        _.map(variant_translate, variantText => {
                                                                                                                            return (
                                                                                                                                <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                        }
                                                                                                        <Grid item xs={4} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                                                                            <Typography component='span' variant='caption' style={{ color: '#EE3B7D' }}>
                                                                                                                <NumberFormat
                                                                                                                    value={parseFloat(cartItem.product.sell_price).toFixed(2)}
                                                                                                                    decimalScale={2}
                                                                                                                    displayType={'text'}
                                                                                                                    thousandSeparator={true}
                                                                                                                    prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                                />
                                                                                                            </Typography><br />
                                                                                                            <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through', paddingLeft: 5 }}>
                                                                                                                <NumberFormat
                                                                                                                    value={parseFloat(cartItem.product.retail_price).toFixed(2)}
                                                                                                                    decimalScale={2}
                                                                                                                    displayType={'text'}
                                                                                                                    thousandSeparator={true}
                                                                                                                    prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                                />
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                                                                                                        <Grid item xs={4}>
                                                                                                            <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('cart.quantity')}</Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={4} style={{ textAlign: 'right', alignSelf: 'center' }}>

                                                                                                            <Typography variant="h6" style={{ color: 'black' }}>x{cartItem.quantity}</Typography>
                                                                                                        </Grid>
                                                                                                        {_.size(cartErrors[`cart.${cartItem.id}`]) ?
                                                                                                            <Grid item xs={12} style={{ padding: 10, alignSelf: 'center' }}>
                                                                                                                <Typography variant="caption" style={{ color: 'red' }}>{cartErrors[`cart.${cartItem.id}`][0]}</Typography>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                        }
                                                                                                    </Grid>

                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Hidden>
                                                                                        <Hidden only={['xs']}>
                                                                                            <Grid container>
                                                                                                <Grid item xs={4} sm={2} md={1} style={{ padding: 10 }}>
                                                                                                    <Box border={1} borderColor="#dee2e6">
                                                                                                        <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}>
                                                                                                            <CardMedia
                                                                                                                style={{ height: 75, width: 100 }}
                                                                                                                image={_.size(cartItem.product.images_array) > 0 ? cartItem.product.images_array[0] : '/images/no-image.png'}
                                                                                                                title={cartItem.product.title}
                                                                                                            />
                                                                                                        </Link>
                                                                                                    </Box>
                                                                                                </Grid>
                                                                                                <Grid item xs={8} sm={10} md={5} style={{ padding: 10, maxWidth: '33%', textAlign: 'left', alignSelf: 'center' }}>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography variant="caption" style={{ fontWeight: 'bold' }}>{title_translate}</Typography></Link>
                                                                                                    </Grid>
                                                                                                    {
                                                                                                        _.size(variant_translate) > 0 ?
                                                                                                            <Grid item xs={12}>
                                                                                                                <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                                                                {
                                                                                                                    _.map(variant_translate, variantText => {
                                                                                                                        return (
                                                                                                                            <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    {/* {
                                                                                                        cartItem.product.shipping_channel === 'mall' && mallShippingFee && mallShipping[merchantId].error && mallShipping[merchantId].error_msg
                                                                                                            ?
                                                                                                            <Grid item xs={12}>
                                                                                                                <Typography variant="caption" style={{ color: 'red' }}>{mallShipping[merchantId].error_msg}</Typography>
                                                                                                            </Grid>
                                                                                                            :
                                                                                                            null
                                                                                                    } */}
                                                                                                </Grid>
                                                                                                <Grid item xs={6} sm={6} md={3} style={{ padding: 10, textAlign: 'right', alignSelf: 'center', maxWidth: '25%' }}>

                                                                                                    <Typography component='span' variant='caption' style={{ color: '#EE3B7D' }}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.sell_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography><br />
                                                                                                    <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through', paddingLeft: 5 }}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.retail_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography>

                                                                                                </Grid>
                                                                                                <Grid item xs={6} sm={6} md={3} style={{ padding: 10, textAlign: 'right', alignSelf: 'center', maxWidth: '25%' }}>
                                                                                                    <Typography>{t('checkout.quantity')}</Typography>
                                                                                                    <Typography variant="h6" style={{ color: theme.palette.gray.main }}>x{cartItem.quantity}</Typography>
                                                                                                </Grid>
                                                                                                {_.size(cartErrors[`cart.${cartItem.id}`]) ?
                                                                                                    <Grid item xs={12} style={{ padding: 10, alignSelf: 'center' }}>
                                                                                                        <Typography variant="caption" style={{ color: 'red' }}>{cartErrors[`cart.${cartItem.id}`][0]}</Typography>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                                }
                                                                                            </Grid></Hidden>
                                                                                    </ListItem>
                                                                                </Box>
                                                                            )
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                }
                                                            </Box>
                                                        )
                                                    })
                                                }

                                            </Box>
                                        )
                                    }
                                    return null;

                                })
                                :
                                <ListItem>
                                    <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                        {
                                            state.totalSelected === null ?
                                                <>
                                                    <CircularProgress disableShrink style={{ color: '#EE3B7D' }} />
                                                    <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography variant="caption">{t('checkout.noItem')}</Typography>
                                                    <Link underline='none' to={`/cart`} component={RouterLink}>
                                                        <Button variant="contained" color="secondary">
                                                            {t('button.goCart')}
                                                        </Button>
                                                    </Link>
                                                </>
                                        }
                                    </Box>
                                </ListItem>
                        }
                    </List>
                </Grid>
            </Grid>

            <Box>
                <Grid container spacing={1} justify="center" style={{ backgroundColor: '#ffffff' }}>
                    <Grid container className={styles.paymentContainer} style={{ backgroundColor: '#ffffff', padding: '5px 10px' }}>
                        <Grid item xs={6}>
                            <Typography variant="h6" >{t('checkout.totalItem', { 'totalSelected': state.totalSelected })}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={styles.paymentContainer} style={{ backgroundColor: '#ffffff', padding: '5px 10px' }}>
                        <Grid item xs={6}>
                            <Typography variant="h6">{t('checkout.totalPayment')}：</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography variant="caption">
                                {
                                    fetchingMallShipping
                                        ?
                                        <CircularProgress disableShrink size="1.5rem" style={{ color: '#EE3B7D' }} />
                                        :
                                        (parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2) > 0
                                            ?
                                            <NumberFormat className={styles.priceStyle} value={(parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} />
                                            : null
                                }
                            </Typography>
                            {
                                _.size(state.totalWalletPrice) > 0 ?
                                    _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                        let walletBalance = 0;
                                        let decimal = 2;
                                        _.map(wallets, (details) => {
                                            let name = _.split(details.name, "|");
                                            let type = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                            if (walletType === type) {
                                                walletBalance = details.balance;
                                                decimal = details.decimal;
                                            }
                                        })
                                        return (
                                            <Box key={walletType}>
                                                <Typography variant="caption">
                                                    <Hidden only={['xs']}>
                                                        <NumberFormat value={walletBalance} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={t('wallet.balance') + ' '} style={{ border: "1px solid #808080", borderRadius: "5px", padding: "3px", margin: "5px" }} />
                                                    </Hidden>
                                                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                        <Box>
                                                            <NumberFormat value={walletBalance} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={t('wallet.balance') + ' '} style={{ border: "1px solid #808080", borderRadius: "5px", padding: "3px", margin: "5px" }} />
                                                        </Box>
                                                    </Hidden>
                                                    <NumberFormat className={styles.priceStyle} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={`${walletType} `} />
                                                </Typography>
                                            </Box>
                                        )
                                    })
                                    : null
                            }
                        </Grid>

                    </Grid>
                </Grid>
                <img src="/images/general/voucher_side.png" />
            </Box>
            {/* <Box className={styles.root}>
                <Grid container spacing={1} justify="center">
                    <Grid item xs={10} sm={10}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="h6" >{t('voucher.recipientDetails')}</Typography>
                            <IconButton aria-label="settings" style={{ zIndex: 1, padding: 0 }} onClick={() => openUserDialog('recipients', recipientInfo)}>
                                            <EditOutlinedIcon size={14} style={{ color: '#EE3B7D' }} />
                                        </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={10} sm={10}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                <Card style={{ color: 'black', backgroundColor: 'white', height: '100%' }}>

                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 15px' }}>
                                        <Typography variant="h6" style={{ color: '#adacac' }}>{t('voucher.recipientDetails')}</Typography>
                                        <IconButton aria-label="settings" style={{ zIndex: 1, padding: 0 }} onClick={() => openUserDialog('recipients', recipientInfo)}>
                                            <EditOutlinedIcon size={14} style={{ color: '#EE3B7D' }} />
                                        </IconButton>

                                    </Box>
                                    <Divider variant="middle" />
                                    <CardContent style={{ paddingTop: 0 }}>
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="subtitle2" style={{ padding: 5 }}>{t('profile.name')}: {recipientInfo.name}</Typography>
                                            <Typography variant="subtitle2" style={{ padding: 5 }}>{t('profile.mobile')}: {recipientInfo.mobile}</Typography>
                                            <Typography variant="subtitle2" style={{ padding: 5 }}>{t('profile.email')}: {recipientInfo.email}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    {
                                        errorField ?
                                            _.map(errorField, (errorMsg, key) => {
                                                return (
                                                    <Grid key={errorMsg + key} item xs={12} sm={6}>
                                                        <Typography variant="body1" color="error">{errorMsg}</Typography>
                                                    </Grid>
                                                )
                                            })
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} sm={10} style={{ marginTop: 30 }}>
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">

                            <Link underline='none' color="inherit" to="/cart" component={RouterLink} style={{ display: 'flex', alignItems: 'center' }}>
                                <Button variant="contained" style={{ display: 'flex', alignItems: 'center', width: '32px', height: '32px', minWidth: '32px' }} color="secondary">
                                    <Box style={{ lineHeight: '0' }}>
                                        <Edit3 style={{ color: '#fff', lineHeight: '0' }} size={27} />
                                    </Box>
                                </Button>
                                <Hidden only={['xs']}>
                                    <Typography style={{ whiteSpace: 'nowrap', fontSize: '14px', marginLeft: '8px', color: '#f72b60' }}>{t('checkout.backToCart')}</Typography>
                                </Hidden>
                            </Link>

                        </Box>
                    </Grid>
                    
                    <Grid item xs={10} sm={10} style={{ color: theme.palette.gray.main , backgroundColor:'#ffffff'}}>
                        <Grid container className={styles.paymentContainer}>
                            <Grid item xs={6}>
                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14 }}>{state.totalSelected}{t('checkout.productPayment')}：</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {
                                    parseFloat(state.totalPrice).toFixed(2) > 0
                                        ?
                                        <Typography variant="caption"><NumberFormat className={styles.priceStyle} value={parseFloat(state.totalPrice).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} /></Typography>
                                        : null
                                }
                                {
                                    _.size(state.totalWalletPrice) > 0 ?
                                        _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                            return (
                                                <Box key={walletType}>
                                                    <Typography variant="caption" className={styles.priceStyle}>
                                                        <NumberFormat className={styles.priceStyle} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={` ${walletType} `} />
                                                    </Typography>
                                                </Box>
                                            )
                                        })
                                        : null
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={styles.paymentContainer}>
                            <Grid item xs={6}>
                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14 }}>{t('checkout.shippingFee')}：</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {
                                    fetchingMallShipping
                                        ?
                                        <CircularProgress disableShrink size="1.5rem" />
                                        :
                                        <Typography variant="caption"><NumberFormat className={styles.priceStyle} value={(parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} /></Typography>
                                }
                            </Grid>
                        </Grid>
                   
                    </Grid>
                    <Grid item xs={10} sm={10} style={{ paddingBottom: 20 }}>
                        <Grid container className={styles.paymentContainer} style={{ backgroundColor: '#ffffff', padding: '5px 10px' }}>
                            <Grid item xs={6}>
                                <Typography variant="h6" >{t('voucher.totalItem', { 'totalSelected': state.totalSelected })}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={styles.paymentContainer} style={{ backgroundColor: '#ffffff', padding: '5px 10px' }}>
                            <Grid item xs={6}>
                                <Typography variant="h6">{t('checkout.totalPayment')}：</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Typography variant="caption">
                                    {
                                        fetchingMallShipping
                                            ?
                                            <CircularProgress disableShrink size="1.5rem" />
                                            :
                                            (parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2) > 0
                                                ?
                                                <NumberFormat className={styles.priceStyle} value={(parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} />
                                                : null
                                    }
                                </Typography>
                                {
                                    _.size(state.totalWalletPrice) > 0 ?
                                        _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                            let walletBalance = 0;
                                            let decimal = 2;
                                            _.map(wallets, (details) => {
                                                let name = _.split(details.name, "|");
                                                let type = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                                if (walletType === type) {
                                                    walletBalance = details.balance;
                                                    decimal = details.decimal;
                                                }
                                            })
                                            return (
                                                <Box key={walletType}>
                                                    <Typography variant="caption">
                                                        <Hidden only={['xs']}>
                                                            <NumberFormat value={walletBalance} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={t('wallet.balance') + ' '} style={{ border: "1px solid #808080", borderRadius: "5px", padding: "3px", margin: "5px" }} />
                                                        </Hidden>
                                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                            <Box>
                                                                <NumberFormat value={walletBalance} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={t('wallet.balance') + ' '} style={{ border: "1px solid #808080", borderRadius: "5px", padding: "3px", margin: "5px" }} />
                                                            </Box>
                                                        </Hidden>
                                                        <NumberFormat className={styles.priceStyle} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={`${walletType} `} />
                                                    </Typography>
                                                </Box>
                                            )
                                        })
                                        : null
                                }
                            </Grid>

                        </Grid>
                        <img src="/images/general/voucher_side.png" />
                    </Grid>
                </Grid>
                <Snackbar 
                    open={state.open} 
                    autoHideDuration={3000} 
                    onClose={() => setState({ ...state, open: false, error: false }) }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{ state.message }</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
            </Box> */}

            <Box style={{ backgroundColor: '#EE3B7D', padding: '10 5', marginTop: 10 }}>
                <Button style={{ color: '#ffffff' }} fullWidth onClick={submitOrder} disabled={ state.totalSelected > 0 ? (disabledSubmit ? true : false) : true }><Typography variant="button" style={{ fontSize: 16 }}>{t('checkout.payNow')}
                    {
                        (parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2) > 0
                            ?
                            <>-
                            <NumberFormat className={styles.priceStyle} style={{ color: 'white' }} value={(parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} /></>
                            : null
                    }
                </Typography></Button>

            </Box>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {/* <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '0 20px', height: '100%' }}>
                        <Box style={{ textAlign: 'right' }}>
                            <Box style={{ paddingRight: '15px' }}>
                                <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: '1.0' }}>
                                    {t('checkout.total')} <br />
                                </Typography>
                                <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: '1.0' }}>
                                    {
                                        fetchingMallShipping
                                            ?
                                            <CircularProgress disableShrink size="1.5rem" />
                                            :
                                            (parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2) > 0
                                                ?
                                                <NumberFormat className={styles.priceStyle} style={{ color: 'white' }} value={(parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} />
                                                : null
                                    }
                                </Typography>
                                {
                                    _.size(state.totalWalletPrice) > 0 ?
                                        _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                            return (
                                                <Box key={walletType}>
                                                    <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: '1.0' }}>
                                                        <NumberFormat className={styles.priceStyle} style={{ color: 'white' }} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={` ${walletType} `} />
                                                    </Typography>
                                                </Box>
                                            )
                                        })
                                        : null
                                }
                            </Box>
                        </Box>
                        <Button variant="contained" size="large" style={{ backgroundColor: 'white' }} onClick={submitOrder} disabled={state.totalSelected > 0 ? (disabledSubmit ? true : false) : true}>
                            {t('button.placeOrder')}
                        </Button>
                    </Box> */}
                <Dialog open={state.modalShow} fullWidth onClose={closeModal} style={{ height: '90%' }}>
                    <DialogTitle id="form-dialog-title" onClose={closeModal}>
                        {
                            state.mode === 'add' ?
                                t('address.addNewAddress')
                                : t('address.editAddress')
                        }
                    </DialogTitle>
                    <DialogContent dividers style={{ padding: 30 }}>
                        <Grid container spacing={3} direction="column">
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <Typography>{t('address.country')}</Typography>
                                <Select
                                    native
                                    value={addressFormState.country}
                                    onChange={handleAddressChange}
                                    inputProps={{ name: 'country' }}
                                    error={errorAddressState.country ? true : false}
                                >
                                    <option value="">{t('address.pleaseSelect')}{t('address.country')}</option>
                                    {
                                        _.map(countries, countryItem => {
                                            return (
                                                <option key={countryItem.code} value={countryItem.code}>{countryItem.name_display}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormHelperText>{errorAddressState.country}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <Typography>{t('address.zip')}</Typography>
                                <TextField
                                    inputProps={{ name: 'zip' }}
                                    onChange={handleAddressChange}
                                    type="text"
                                    variant="outlined"
                                    value={addressFormState.zip}
                                    error={errorAddressState.zip ? true : false}
                                    helperText={errorAddressState.zip}
                                />
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <Typography>{t('address.state')}</Typography>
                                {
                                    _.includes(addressGroup.stateGroup, addressFormState.country) ?
                                        <>
                                            <Select
                                                native
                                                value={addressFormState.state}
                                                onChange={handleAddressChange}
                                                inputProps={{ name: 'state' }}
                                                error={errorAddressState.state ? true : false}
                                            >
                                                <option value="">{t('address.pleaseSelect')}{t('address.state')}</option>
                                                {
                                                    _.map(addressState, stateItem => {
                                                        return (
                                                            <option key={stateItem.id} value={stateItem.id}>{stateItem.name_display}</option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <FormHelperText>{errorAddressState.state}</FormHelperText>
                                        </>
                                        :
                                        <TextField
                                            onChange={handleAddressChange}
                                            type="text"
                                            variant="outlined"
                                            inputProps={{ name: 'state' }}
                                            value={addressFormState.state}
                                            error={errorAddressState.state ? true : false}
                                            helperText={errorAddressState.state}
                                        />
                                }
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <Typography>{t('address.city')}</Typography>
                                {
                                    _.size(cities) > 0 ?
                                        <>
                                            <Select
                                                native
                                                value={addressFormState.city}
                                                onChange={handleAddressChange}
                                                inputProps={{ name: 'city' }}
                                                error={errorAddressState.city ? true : false}
                                            >
                                                <option value="">{t('address.pleaseSelect')}{t('address.city')}</option>
                                                {
                                                    _.map(cities, cityItem => {
                                                        return (
                                                            <option key={cityItem.id} value={cityItem.id}>{cityItem.name_display}</option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <FormHelperText>{errorAddressState.city}</FormHelperText>
                                        </>
                                        :
                                        <TextField
                                            onChange={handleAddressChange}
                                            type="text"
                                            variant="outlined"
                                            inputProps={{ name: 'city' }}
                                            value={addressFormState.city}
                                            error={errorAddressState.city ? true : false}
                                            helperText={errorAddressState.city}
                                        />
                                }
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <Typography>{t('address.address2')}</Typography>
                                {
                                    _.size(district) > 0 ?
                                        <>
                                            <Select
                                                native
                                                value={addressFormState.address2}
                                                onChange={handleAddressChange}
                                                inputProps={{ name: 'address2' }}
                                                error={errorAddressState.address2 ? true : false}
                                            >
                                                <option value="">{t('address.pleaseSelect')}{t('address.address2')}</option>
                                                {
                                                    _.map(district, districtItem => {
                                                        return (
                                                            <option key={districtItem.id} value={districtItem.id}>{districtItem.name_display}</option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <FormHelperText>{errorAddressState.address2}</FormHelperText>
                                        </>
                                        :
                                        <TextField
                                            onChange={handleAddressChange}
                                            type="text"
                                            variant="outlined"
                                            inputProps={{ name: 'address2' }}
                                            value={addressFormState.address2}
                                            error={errorAddressState.address2 ? true : false}
                                            helperText={errorAddressState.address2}
                                        />
                                }
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <Typography>{t('address.address')}</Typography>
                                <TextField
                                    onChange={handleAddressChange}
                                    type="text"
                                    variant="outlined"
                                    inputProps={{ name: 'address' }}
                                    value={addressFormState.address}
                                    error={errorAddressState.address ? true : false}
                                    helperText={errorAddressState.address}
                                    multiline
                                    rows={3}
                                />
                            </FormControl>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={submitAddress} style={{ backgroundColor: '#EE3B7D', color: '#ffffff' }}>
                            {state.mode === 'add' ? t('button.add') : t('button.update')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={userDialog.open} fullWidth onClose={closeUserModal} >
                    <DialogTitle onClose={closeUserModal}>
                        {/* {
                            userDialog.type === 'buyer'
                                ?
                                t('checkout.editBuyerInfo')
                                :
                                t('checkout.editRecipientInfo')
                        } */}
                        {t('checkout.editBuyerInfo')}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={3} direction="column">
                            <Grid item>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>
                                        {t('checkout.buyer')} {t('profile.name')}
                                    </Typography>
                                    <TextField
                                        onChange={({ target }) => setUserDialog({ ...userDialog, name: target.value })}
                                        type="text"
                                        value={userDialog.name}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>
                                        {t('checkout.buyer')} {t('profile.mobile')}
                                    </Typography>
                                    <TextField
                                        onChange={({ target }) => setUserDialog({ ...userDialog, mobile: target.value })}
                                        type="text"
                                        value={userDialog.mobile}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>
                                        {t('checkout.buyer')} {t('profile.email')}
                                    </Typography>
                                    <TextField
                                        onChange={({ target }) => setUserDialog({ ...userDialog, email: target.value })}
                                        type="text"
                                        value={userDialog.email}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={saveUserModal} style={{ color: '#EE3B7D' }}>
                            {t('button.confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

            </Hidden>

            <Hidden only={['xs']}>
                <Box>
                    {/* <Grid item xs={10} container style={{ padding: '5px', alignItems: 'center', margin: '0 auto' }}>
                        <Grid item xs={7} sm={10} style={{ textAlign: 'right' }}>
                            <Grid container style={{ paddingRight: '15px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: 'unset' }}>
                                        {t('checkout.total')}<br />
                                        {
                                            fetchingMallShipping
                                                ?
                                                <CircularProgress disableShrink size="1.5rem" style={{color:'#EE3B7D'}} />
                                                :
                                                (parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2) > 0
                                                    ?
                                                    <NumberFormat className={styles.priceStyle} style={{ color: 'white' }} value={(parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} />
                                                    :
                                                    null
                                        }
                                    </Typography>
                                </Grid>
                                {
                                    _.size(state.totalWalletPrice) > 0 ?
                                        _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                            return (
                                                <Grid item xs={12} key={walletType}>
                                                    <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: 'unset' }}>
                                                        <NumberFormat className={styles.priceStyle} style={{ color: 'white' }} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={`${walletType} `} />
                                                    </Typography>
                                                </Grid>
                                            )
                                        })
                                        : null
                                }
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={5} sm={2} style={{ textAlign: 'right' }}>
                            <Button variant="contained" size="large" style={{ backgroundColor: state.totalSelected > 0 ? (disabledSubmit ? '#e0e2e2' : '#0e386b') : '#0e386b', color: state.totalSelected > 0 ? (disabledSubmit ? '#8e8d8d' : '#fff') : '#fff' }} onClick={submitOrder} disabled={state.totalSelected > 0 ? (disabledSubmit ? true : false) : true}>
                                <Typography variant="caption">{t('button.placeOrder')}</Typography>
                            </Button>
                        </Grid>
                    </Grid> */}
                    <Dialog open={state.modalShow} fullWidth onClose={closeModal}>
                        <DialogTitle id="form-dialog-title" onClose={closeModal}>
                            {
                                state.mode === 'add' ?
                                    t('address.addNewAddress')
                                    : t('address.editAddress')
                            }
                        </DialogTitle>
                        <DialogContent dividers style={{ padding: 30 }}>
                            <Grid container spacing={3} direction="column">
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{t('address.country')}</Typography>
                                    <Select
                                        native
                                        value={addressFormState.country}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'country' }}
                                        error={errorAddressState.country ? true : false}
                                    >
                                        <option value="">{t('address.pleaseSelect')}{t('address.country')}</option>
                                        {
                                            _.map(countries, countryItem => {
                                                return (
                                                    <option key={countryItem.code} value={countryItem.code}>{countryItem.name_display}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.country}</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{t('address.zip')}</Typography>
                                    <TextField
                                        inputProps={{ name: 'zip' }}
                                        onChange={handleAddressChange}
                                        type="text"
                                        variant="outlined"
                                        value={addressFormState.zip}
                                        error={errorAddressState.zip ? true : false}
                                        helperText={errorAddressState.zip}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{t('address.state')}</Typography>
                                    {
                                        _.includes(addressGroup.stateGroup, addressFormState.country) ?
                                            <>
                                                <Select
                                                    native
                                                    value={addressFormState.state}
                                                    onChange={handleAddressChange}
                                                    inputProps={{ name: 'state' }}
                                                    error={errorAddressState.state ? true : false}
                                                >
                                                    <option value="">{t('address.pleaseSelect')}{t('address.state')}</option>
                                                    {
                                                        _.map(addressState, stateItem => {
                                                            return (
                                                                <option key={stateItem.id} value={stateItem.id}>{stateItem.name_display}</option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{errorAddressState.state}</FormHelperText>
                                            </>
                                            :
                                            <TextField
                                                onChange={handleAddressChange}
                                                type="text"
                                                variant="outlined"
                                                inputProps={{ name: 'state' }}
                                                value={addressFormState.state}
                                                error={errorAddressState.state ? true : false}
                                                helperText={errorAddressState.state}
                                            />
                                    }
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{t('address.city')}</Typography>
                                    {
                                        _.size(cities) > 0 ?
                                            <>
                                                <Select
                                                    native
                                                    value={addressFormState.city}
                                                    onChange={handleAddressChange}
                                                    inputProps={{ name: 'city' }}
                                                    error={errorAddressState.city ? true : false}
                                                >
                                                    <option value="">{t('address.pleaseSelect')}{t('address.city')}</option>
                                                    {
                                                        _.map(cities, cityItem => {
                                                            return (
                                                                <option key={cityItem.id} value={cityItem.id}>{cityItem.name_display}</option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{errorAddressState.city}</FormHelperText>
                                            </>
                                            :
                                            <TextField
                                                onChange={handleAddressChange}
                                                type="text"
                                                variant="outlined"
                                                inputProps={{ name: 'city' }}
                                                value={addressFormState.city}
                                                error={errorAddressState.city ? true : false}
                                                helperText={errorAddressState.city}
                                            />
                                    }
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{t('address.address2')}</Typography>
                                    {
                                        _.size(district) > 0 ?
                                            <>
                                                <Select
                                                    native
                                                    value={addressFormState.address2}
                                                    onChange={handleAddressChange}
                                                    inputProps={{ name: 'address2' }}
                                                    error={errorAddressState.address2 ? true : false}
                                                >
                                                    <option value="">{t('address.pleaseSelect')}{t('address.address2')}</option>
                                                    {
                                                        _.map(district, districtItem => {
                                                            return (
                                                                <option key={districtItem.id} value={districtItem.id}>{districtItem.name_display}</option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{errorAddressState.address2}</FormHelperText>
                                            </>
                                            :
                                            <TextField
                                                onChange={handleAddressChange}
                                                type="text"
                                                variant="outlined"
                                                inputProps={{ name: 'address2' }}
                                                value={addressFormState.address2}
                                                error={errorAddressState.address2 ? true : false}
                                                helperText={errorAddressState.address2}
                                            />
                                    }
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{t('address.address')}</Typography>
                                    <TextField
                                        onChange={handleAddressChange}
                                        type="text"
                                        variant="outlined"
                                        inputProps={{ name: 'address' }}
                                        value={addressFormState.address}
                                        error={errorAddressState.address ? true : false}
                                        helperText={errorAddressState.address}
                                        multiline
                                        rows={3}
                                    />
                                </FormControl>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={submitAddress} style={{ backgroundColor: '#EE3B7D', color: '#ffffff' }}>
                                {state.mode === 'add' ? t('button.add') : t('button.update')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={userDialog.open} fullWidth onClose={closeUserModal} >
                        <DialogTitle onClose={closeUserModal}>
                            {/* {
                                userDialog.type === 'buyer'
                                    ?
                                    t('checkout.editBuyerInfo')
                                    :
                                    t('checkout.editRecipientInfo')
                            } */}
                            {t('checkout.editBuyerInfo')}
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={3} direction="column">
                                <Grid item>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>
                                            {t(`checkout.buyer`)} {t('profile.name')}
                                        </Typography>
                                        <TextField
                                            onChange={({ target }) => setUserDialog({ ...userDialog, name: target.value })}
                                            type="text"
                                            value={userDialog.name}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>
                                            {t(`checkout.buyer`)} {t('profile.mobile')}
                                        </Typography>
                                        <TextField
                                            onChange={({ target }) => setUserDialog({ ...userDialog, mobile: target.value })}
                                            type="text"
                                            value={userDialog.mobile}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>
                                            {t(`checkout.buyer`)} {t('profile.email')}
                                        </Typography>
                                        <TextField
                                            onChange={({ target }) => setUserDialog({ ...userDialog, email: target.value })}
                                            type="text"
                                            value={userDialog.email}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={saveUserModal} style={{ color: '#EE3B7D' }}>
                                {t('button.confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>

            </Hidden>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '30px 0 10px 0',
    },
    icon: {
        color: `${theme.palette.button.step} !important `,
    },
    listItemStyle: {
        padding: 10
    },
    buttonStyle: {
        borderRadius: 0,
        fontSize: 12,
        margin: 5
    },
    priceStyle: {
        fontSize: 16,
        color: '#EE3B7D',
        fontWeight: 'bold'
    },
    paymentContainer: {
        paddingLeft: 5,
        paddingRight: 5,
    },
    totalPriceStyle: {
        backgroundColor: theme.palette.background.main,
    },
    whiteText: {
        fill: '#fff'
    },
    stepperStyle: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    stickyFooter: {
        width: '100%',
        position: 'fixed',
        Top: 'calc(5% + 60px)',
        bottom: 0,
        // background: 'linear-gradient(to right, #6aacdd, #5e95ce)',
        backgroundColor: '#f7952a',
        zIndex: 1,
        minHeight: 70,
    },
    itemBoxStyle: {
        // marginBottom: 20, 
        // borderRadius: 20, 
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
    firstItemBoxStyle: {
        marginBottom: 20,
        // borderBottomLeftRadius: 20,
        // borderBottomRightRadius: 20,
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
    formControl: {
        padding: 5
    }
}));