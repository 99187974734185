import React, { useState, useEffect, useRef , createRef, useMemo, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { 
    TextField, Button, Typography, Grid, Box, Divider, List, Stepper, Step, StepLabel, Switch, Drawer, Hidden, ListItem, Chip, 
    Dialog, DialogActions, DialogContentText, Snackbar, Card, CardContent, DialogContent, DialogTitle, FormControl, Select, 
    FormHelperText, Avatar, IconButton, MenuList, MenuItem, Input,InputAdornment,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider, MuiThemeProvider, styled } from '@material-ui/core/styles';
import { getUrl, postUrl, putUrl, deleteUrl } from '../helper/ApiAction';

import { ArtTrackOutlined,AspectRatioOutlined, CloseRounded } from '@material-ui/icons';

import useNotificationLoading from '../helper/useNotificationLoading';
import ImgHeader from './Layouts/ImgHeader';
import _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import './css/styles.css';
import theme from '../Theme';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function ProfileAddressAction() {
    const location = useLocation();
    const { username:ownerUsername } = useSelector(state => state.user);
    const [oldUsername, setOldUserName] = useState(location.state?location.state.recipient:ownerUsername);
    const styles = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { t ,i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [username, setUserNmae] = useState(oldUsername);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [error, setError] = useState({
        username:''
    });

    useEffect(() => {
        setLoading(true)
        let apiData = {
            username: oldUsername,
        }
        // search api 
        getUrl('searchUsername', apiData)
            .then(response => {
                setLoading(false)
                if (response.status == 1) {
                    setError({
                        username:''
                    })
                    setDisableSubmit(false);
                } else {
                    setDisableSubmit(true);
                    // callApi();
                    setError(response.errors);
                    // addAlert(response.message)
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    },[])
    
    const searchRequest = useCallback((value) => {
        setLoading(true)
        let apiData = {
            username: value,
        }
        // search api 
        getUrl('searchUsername', apiData)
            .then(response => {
                setLoading(false)
                if (response.status == 1) {
                    setError({
                        username:''
                    })
                    setDisableSubmit(false);
                } else {
                    setDisableSubmit(true);
                    // callApi();
                    setError(response.errors);
                    // addAlert(response.message)
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    }, []);

    const debounceSearchUsername = useMemo(() => {
        return _.debounce(searchRequest, 500);
    }, [searchRequest]);

    const handleSearchingUsername = (value) => {
        setUserNmae(value);
        debounceSearchUsername(value);
    }
    const handleRemove = () => {
        setLoading(true)
        setUserNmae(ownerUsername);
        setDisableSubmit(false)
        setError({
            username:''
        })
        setLoading(false)
    }
    const handleConfirm = (value) => {
        setLoading(true)
        history.push(`/checkout`,{recipient:value})
        setLoading(false)
    }

    return (
        <Box >
            <ImgHeader imageDisplay={false} normalDisplay />
            <div className='p-lr-30 p-t-50 p-b-90'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                       <Typography className={styles.header}>{t('buyForSomeone.title')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                       <Typography className={styles.header2}>{t('buyForSomeone.username')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            error={error.username ? true : false}
                            helperText={error.username}
                            variant="outlined"
                            className={styles.input}
                            style={{height:'54px'}}
                            value={username}
                            onChange={e => handleSearchingUsername(e.target.value)}
                            InputProps={{
                                className: styles.textFieldBox,
                                endAdornment:(
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="search button"
                                        // onClick={redirectSearchUsername}
                                        >
                                            {error.username ? <ReportProblemOutlinedIcon style={{ color: '#FF1744' }}/> : <SearchIcon />}
                                            
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                className: styles.textFieldText,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
            <Box className={styles.stickyFooter} style={{ textAlign: 'center', minHeight: 0, height: 100, paddingLeft:30 , paddingRight:30}}>
                <Button disabled={disableSubmit} variant="contained" size="large" color='primary' className={styles.button} onClick={() => handleConfirm(username)}>
                    {t('button.continue')}
                </Button>
                <Button variant="text" onClick={() => handleRemove()}>
                    <Typography className={styles.removeText}>
                        {t('buyForSomeone.remove')}
                    </Typography>
                </Button> 
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    backgroundScreen:{
        backgroundImage: `linear-gradient(180deg, #000 -6%, rgba(0, 0, 0, 0.00) 72.16%), url('/images/background/background_image.png')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat-y',
        margin: '0',
        padding: '0',
        height: '100vh',
        width: 'auto',
    },
    input: {
       boxShadow:'2px 2px 4px 0px rgba(0, 0, 0, 0.09) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.93)',
       borderRadius:'48px',
       backgroundColor:'#EFEFEF'
    },
    header: {
        color:'#858585',
        fontSize: '21px',
        fontWeight: '700',
        textTransform: 'capitalize',
        marginTop:'20px',
    },
    header2: {
        color:'#858585',
        fontSize: '15px',
        fontWeight: '400',
        textTransform: 'capitalize',
    },
    removeText: {
        color:'#959595',
        fontSize: '15px',
        fontWeight: '400',
        textTransform: 'capitalize',
        textAlign:'center',
    },
    helperText: {
        color: '#595959',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform: 'capitalize',
    },
    stickyFooter: {
        width: '100%',
        maxWidth: 420,
        position: 'fixed',
        // Top: 'calc(5% + 60px)',
        bottom: 0,
        zIndex: 1,
        minHeight: 70,
    },
    button: {
        boxShadow:'4px 4px 10px 0px rgba(0, 0, 0, 0.25)',
        width:'100%',
        borderRadius:'48px'
    }

}));