import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';
import TitleBarTransparent from './Layouts/TitleBarTransparent';

import { FiChevronLeft } from 'react-icons/fi';

const INITIAL_SECURITY_PASSWORD_STATE = { old_password: '', security_password: '', security_password_confirmation: '' };

export default function ProfileSecurityPassword() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [securityPasswordFormState, setSecurityPasswordFormState] = useState(INITIAL_SECURITY_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '',
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    const handleSecurityPasswordChange = (event) => {
        const name = event.target.name;
        setSecurityPasswordFormState({ ...securityPasswordFormState, [name]: event.target.value });
    }

    const updateSecurityPassword = () => {
        const { old_password, security_password, security_password_confirmation } = securityPasswordFormState;
        if(!old_password || !security_password || !security_password_confirmation) {
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/security_password', securityPasswordFormState).then(result => {
                if(result.error) {
                    setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setState( state => ({ ...state, snackbarShow: true, error: false, message: result.message }) );
                    setSecurityPasswordFormState(INITIAL_SECURITY_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    return (
        <Box className={styles.root} style={{ background: theme.palette.white.mobileBkg, padding: '0 0 80px', minHeight: '100%' }}>
            <TitleBarTransparent height={70} title={t('profile.changeSecurityPassword')} displayInfo back currencyButton displayCart />
            <div className='fixed-header-padding'>
                <Grid container spacing={1} justify="center" style={{ padding: '20px 15px 0' }}>
                    <Grid item xs={12}>
                        <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                            <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                                <Box style={{ color: theme.palette.secondary.main }}>
                                    <Typography variant="h6">
                                        {t('profile.changeSecurityPassword')}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {t('profile.securityPasswordSubtitle')}
                                    </Typography>
                                </Box>
                            </ListItem>
                            <Divider variant="middle" light />
                            <div style={{ padding: '20px 15px' }}>
                                <div>
                                    <Typography className={styles.textFieldTitle}>{t('profile.currentSecurityPassword')}</Typography>
                                    <TextField
                                        error={errorFormState.old_password ? true : false}
                                        helperText={errorFormState.old_password}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        inputProps={{ name: 'old_password' }}
                                        onChange={handleSecurityPasswordChange}
                                        value={securityPasswordFormState.old_password}
                                    />
                                    <FormHelperText style={{ paddingLeft: 10, paddingTop: 5 }}>{t('profile.currentSecurityPasswordInfo')}</FormHelperText>
                                </div>

                                <div style={{ paddingTop: 25 }}>
                                    <Typography className={styles.textFieldTitle}>{t('profile.newSecurityPassword')}</Typography>
                                    <TextField
                                        error={errorFormState.security_password ? true : false}
                                        helperText={errorFormState.security_password}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        inputProps={{ name: 'security_password' }}
                                        onChange={handleSecurityPasswordChange}
                                        value={securityPasswordFormState.security_password}
                                    />
                                </div>

                                <div style={{ paddingTop: 25 }}>
                                    <Typography className={styles.textFieldTitle}>{t('profile.confirmSecurityPassword')}</Typography>
                                    <TextField
                                        error={errorFormState.security_password_confirmation ? true : false}
                                        helperText={errorFormState.security_password_confirmation}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        inputProps={{ name: 'security_password_confirmation' }}
                                        onChange={handleSecurityPasswordChange}
                                        value={securityPasswordFormState.security_password_confirmation}
                                    />
                                </div>
                            </div>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                                <Button variant="contained" onClick={updateSecurityPassword} style={{ width: 500, maxWidth: '80%', borderRadius: 48 }} color="secondary">{t('button.confirm')}</Button>
                            </Box>
                        </List>
                        <Snackbar
                            open={state.snackbarShow}
                            autoHideDuration={2000}
                            onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                                <CardContent style={{ padding: 10 }}>
                                    <Typography style={{ color: 'white' }}>{state.message}</Typography>
                                </CardContent>
                            </Card>
                        </Snackbar>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    textFieldTitle:{
        color:theme.palette.gray.text,
        fontSize:16,
        paddingBottom:5,
        paddingLeft:10
    }
}));