import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateCartTotal } from '../actions';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { getUrl, deleteUrl, putUrl } from '../helper/ApiAction';
import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Checkbox, FormControl, Input, InputAdornment, Chip, Button, IconButton, Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Box, Hidden, Divider, List, ListItem, FormControlLabel, ListItemText, ListItemSecondaryAction, CardMedia } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


import StorefrontIcon from '@material-ui/icons/Storefront';
import CancelIcon from '@material-ui/icons/Cancel';
import { Circle, CheckCircle } from 'react-feather';
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

export default function VoucherCart() {

    const { accessToken } = useSelector(state => state.general);
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    let history = useHistory();
    const theme = useTheme();

    const [state, setState] = useState({
        checkAll: false,
        dialogShow: false,
        removeCartId: [],
        totalSelected: 0,
        totalPrice: 0,
        totalWalletPrice: {},
        open: false,
        error: false,
        message: '',
    });
    const [cart, setCart] = useState(null);
    const [currencyData, setCurrencyData] = useState(null);


    const calculateTotal = userCart => {
        let totalPrice = 0;
        let totalSelected = 0;
        let totalWalletPrice = {};

        let checkAll = true;
        _.map(userCart, merchantItem => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        totalSelected += parseInt(cartItem.quantity);
                        let itemPrice = cartItem.product.sell_price;
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (option) => {
                                _.map(option.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        itemPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : cartItem.product.sell_price
                                    }
                                })
                            })
                        }
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                            totalPrice += parseFloat((itemPrice * selectedPricing.cash_scale * cartItem.quantity).toFixed(2));
                            if (selectedPricing.wallet_id && parseFloat(selectedPricing.wallet_amount) > 0) {
                                let name = _.split(selectedPricing.wallet_name, '|');
                                let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                const old_amount = parseFloat(totalWalletPrice[display_name] ? totalWalletPrice[display_name] : 0);
                                totalWalletPrice[display_name] = (old_amount + parseFloat(selectedPricing.wallet_amount) * cartItem.quantity).toFixed(selectedPricing.decimal);
                            }
                        } else {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        }
                    } else if (shippingChannel !== 'unlisted') {
                        checkAll = false;
                    }
                })
            });
        })
        setState(state => ({ ...state, totalPrice, totalSelected, checkAll, totalWalletPrice }));
    };

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (isMountedRef.current) {
            setLoading(false);
            getUrl('carts').then(cartList => {
                setCurrencyData(cartList.currency_data);
                setCart(cartList.data);
                calculateTotal(cartList.data);
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const checkAll = () => {
        const newCheckAll = state.checkAll ? false : true;
        const newCart = cart;
        _.map(newCart, (merchantItem, merchantIndex) => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem, cartIndex) => {
                    if (shippingChannel !== 'unlisted') {
                        if (Boolean(cartItem.selected) !== Boolean(newCheckAll)) {
                            putUrl(`carts/${cartItem.id}`, {
                                selected: newCheckAll ? 1 : 0,
                                quantity: cartItem.quantity,
                            }).catch(error => {
                                addAlert(JSON.stringify(error.message));
                            });
                        }

                        newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                            ...cartItem, quantity: cartItem.quantity, selected: newCheckAll ? 1 : 0
                        }
                    }
                })
            })
        })
        setCart(newCart);
        setState({ ...state, checkAll: newCheckAll });
        calculateTotal(newCart);
    }

    const removeCart = async () => {
        const { removeCartId } = state;
        console.log(removeCartId);
        if (removeCartId) {
            const cartIdString = _.join(removeCartId, ',');
            deleteUrl(`carts/${cartIdString}`).then(response => {
                if (response.status === 0) {

                } else {
                    const newCart = cart;
                    let cartTotal = 0;

                    _.map(newCart, (merchantItem, merchantIndex) => {
                        let merchantProduct = false;
                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                            cartTotal += _.size(cartDetails);
                            newCart[merchantIndex]['items'][shippingChannel] = _.remove(cartDetails, cartItem => {
                                if (!_.includes(removeCartId, cartItem.id)) {
                                    return cartItem;
                                }
                            })
                            if (_.size(newCart[merchantIndex]['items'][shippingChannel]) !== 0) {
                                merchantProduct = true;
                            }
                        })
                        if (!merchantProduct) {
                            delete newCart[merchantIndex];
                        }
                    })
                    setCart(newCart);
                    setState({ ...state, dialogShow: false, removeCartId: [] });
                    calculateTotal(newCart);
                    dispatch(updateCartTotal(cartTotal));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const updateCart = (cartId, inputQuantity, reverseSelected) => {
        let selectedCartItem = {};

        _.map(cart, merchantItem => {
            _.map(merchantItem.items, cartDetails => {
                const matchItem = _.find(cartDetails, ['id', cartId]);
                if (_.size(matchItem) > 0) {
                    selectedCartItem = matchItem;
                }
            })
        })
        let newInputQuantity = selectedCartItem.quantity;
        let newInputSelected = selectedCartItem.selected;

        if (inputQuantity) {
            newInputQuantity = parseInt(inputQuantity) > 0 ? parseInt(inputQuantity) : 1;
        }

        if (reverseSelected) {
            newInputSelected = newInputSelected ? false : true;
        }

        let newCart = cart;

        putUrl(`carts/${cartId}`, {
            selected: newInputSelected ? 1 : 0,
            quantity: newInputQuantity,
        }).then(response => {
            if (response.status === 0) {
                // setState(state => ({ ...state, open: true, error: true, message: response.error.cart[0] }));
                addAlert(_.map(response.error.cart).join(' '));
            } else {
                _.map(newCart, (merchantItem, merchantIndex) => {
                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                        _.map(cartDetails, (cartItem, cartIndex) => {
                            if (cartItem.id === cartId) {
                                newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                                    ...cartItem, quantity: newInputQuantity, selected: newInputSelected
                                }
                            }
                        })
                    });
                })
                setCart(newCart);
                calculateTotal(newCart);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    // const handlePricingChange = (event) => {
    //     event.persist();
    //     const cartId = event.target.name;

    //     const newCart = _.map(cart, (cartItem) => {
    //         if (cartItem.id === parseInt(cartId)) {
    //             return { ...cartItem, multi_pricing_id: event.target.value };
    //         } else {
    //             return cartItem;
    //         }
    //     });

    //     putUrl(`carts/${cartId}`, {
    //         multi_pricing_id: event.target.value ? event.target.value : null,
    //     }).then(result => {
    //         setCart(newCart);
    //         calculateTotal(newCart);
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     });
    // }

    const checkoutCart = () => {
        if (!accessToken) {
            // return setState(state => ({ ...state, open: true, error: true, message: t('cart.loginToProceed') }));
            return addAlert(t('cart.loginToProceed'));
        }
        let proceedCheckout = true;
        if (_.size(cart) > 0) {
            _.map(cart, merchantItem => {
                _.map(merchantItem.items, cartDetails => {
                    _.map(cartDetails, cartItem => {
                        if (cartItem.selected) {
                            let stockAvailable = 0;
                            if (_.size(cartItem.product.options) > 0) {
                                _.map(cartItem.product.options, (option) => {
                                    _.map(option.pricing, (pricingOption) => {
                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                            stockAvailable = pricingOption.quantity;
                                        }
                                    })
                                });
                            } else {
                                stockAvailable = cartItem.product.quantity;
                            }

                            if (cartItem.quantity > stockAvailable) {
                                proceedCheckout = false;
                                addAlert(t('general.stockExceedError'));
                            }
                        }
                    })
                })
            })
        }

        if (proceedCheckout) {
            return history.push("/voucher_checkout");
        }
    }

    // const priceDisplay = (cartItem, unlisted, display) => {
    //     // unlisted: true/false
    //     // display: web/mobile
    //     let productPrice = cartItem.product.sell_price;
    //     let priceStyle = {};
    //     let noMultiPricing = true;
    //     if (_.size(cartItem.product.options) > 0) {
    //         _.map(cartItem.product.options, (optionItem) => {
    //             _.map(optionItem.pricing, (pricingOption) => {
    //                 if (pricingOption.id === cartItem.product_warehouse_id) {
    //                     productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
    //                 }
    //             })
    //         })
    //     }
    //     if (display === 'mobile' && unlisted === false) {
    //         priceStyle = { color: theme.palette.price.main, fontSize: '15px' };
    //     } else if (display === 'mobile' && unlisted === true) {
    //         priceStyle = { color: "#808080", fontSize: '15px' };
    //     } else if (display === 'web' && unlisted === false) {
    //         priceStyle = { color: theme.palette.price.main };
    //     } else if (display === 'web' && unlisted === true) {
    //         priceStyle = { color: "#808080" };
    //     }
    //     return (
    //         <>
    //             {
    //                 (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) ?
    //                     _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
    //                         // const multiPricingId = cartItem.multi_pricing_id;
    //                         if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
    //                             let name = _.split(pricingItem.wallet_name, '|');
    //                             noMultiPricing = false;
    //                             return (
    //                                 <Box key={pricingIndex} display="flex" flexDirection="column" style={unlisted === true ? { color: "#808080" } : {}}>
    //                                     {
    //                                         parseFloat(productPrice * pricingItem.cash_scale * cartItem.quantity) > 0 ?
    //                                             <Typography variant="overline">
    //                                                 <NumberFormat value={(productPrice * pricingItem.cash_scale * cartItem.quantity).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
    //                                             </Typography>
    //                                             : null
    //                                     }
    //                                     {
    //                                         pricingItem.wallet_id && parseFloat(pricingItem.wallet_amount) > 0 ?
    //                                             <Typography variant="overline">
    //                                                 <NumberFormat value={(parseFloat(pricingItem.wallet_amount) * cartItem.quantity).toFixed(pricingItem.decimal)} decimalScale={pricingItem.decimal} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]} `} />
    //                                             </Typography>
    //                                             : null
    //                                     }
    //                                 </Box>
    //                             )
    //                         }
    //                     })
    //                     :
    //                     <Typography variant="h6" style={priceStyle}>
    //                         <NumberFormat value={(cartItem.quantity * productPrice).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `} />
    //                     </Typography>
    //             }
    //             {
    //                 (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) && noMultiPricing ?
    //                     <Typography variant="h6" style={{ color: "#808080", fontSize: '15px' }}>
    //                         {t('cart.paymentInvalid')}
    //                     </Typography>
    //                     : null
    //             }
    //         </>
    //     )
    // }

    return (
        <Box style={{ paddingTop: '2%' }}>
            {/* <Grid container justify="center" className={styles.breadcrumbRoot} style={{ margin: '0', maxWidth: '100%' }}>
                <Grid item xs={10} md={5} className={styles.breadcrumbRoot} style={{ maxWidth: '100%' }}>
                    <Grid container style={{ marginBottom: '10px' }}>
                        <Breadcrumbs separator="›" maxItems={2} aria-label="breadcrumb" style={{ fontSize: 12, padding: '0 10px' }}>
                            <Link underline='none' color="secondary" href="/">
                                {t('title.home')}
                            </Link>
                            <Typography style={{ fontSize: 12, color: 'light' }}>{t('title.cart')}</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid item xs={10} md={5}>
                    <Stepper activeStep={0} alternativeLabel className={styles.stepperStyle}>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { root: styles.icon } }}>
                                {t('checkout.myCart')}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { text: styles.whiteText } }}>
                                {t('checkout.fillInfo')}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { text: styles.whiteText } }}>
                                {t('checkout.submitOrder')}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
            </Grid> */}

            <Box className={styles.root}>
                <Grid container spacing={1} justify="center">
                    {
                        accessToken ? null :
                            <Grid item xs={12} sm={10}>
                                <Alert
                                    severity="warning"
                                    action={
                                        <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                                            <Button variant="contained" size="small" color="secondary">
                                                <Typography variant="overline">{t('cart.loginNow')}</Typography>
                                            </Button>
                                        </Link>
                                    }
                                >
                                    {t('cart.noLogin')}
                                </Alert>
                            </Grid>
                    }
                    <Grid item xs={12} sm={10}>
                        <Hidden smDown>
                            <List>
                                {
                                    _.size(cart) > 0 && cart !== null ?
                                        _.map(cart, (merchantItem, merchantId) => {
                                            return (
                                                <Box key={merchantId} style={{ paddingBottom: 20 }}>
                                                    <Box border={1} display="flex" flexDirection="row" alignItems="center" borderColor={theme.palette.gray.border} style={{ padding: 10, backgroundColor: '#ED3A80', color: '#fff' }}>
                                                        <StorefrontIcon fontSize="small" style={{ marginLeft: 20 }} />
                                                        <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>{merchantItem.name}</Typography>
                                                    </Box>
                                                    {
                                                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                                            return (
                                                                _.map(cartDetails, (cartItem, cartIndex) => {
                                                                    const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                                    let variant_translate = [];
                                                                    let stockAvailable = 0;
                                                                    if (_.size(cartItem.product.options) > 0) {
                                                                        _.map(cartItem.product.options, (option) => {
                                                                            _.map(option.pricing, (pricingOption) => {
                                                                                if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                    if (option.color) {
                                                                                        variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                                    }
                                                                                    if (option.size) {
                                                                                        variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                                    }
                                                                                    if (option.attribute_value) {
                                                                                        variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                                    }
                                                                                    stockAvailable = pricingOption.quantity;
                                                                                }
                                                                            })
                                                                        });
                                                                    } else {
                                                                        stockAvailable = cartItem.product.quantity;
                                                                    }
                                                                    return (
                                                                        <Box border={1} key={cartItem.id} style={{ borderColor: 'white' }}>
                                                                            <ListItem style={{ backgroundColor: 'white' }}>
                                                                                {
                                                                                    shippingChannel === 'unlisted'
                                                                                        ?
                                                                                        <Grid container style={{ alignItems: 'center' }}>
                                                                                            <Grid item style={{ padding: 10, alignSelf: 'center' }}>
                                                                                                <Checkbox
                                                                                                    checked={Boolean(cartItem.selected)}
                                                                                                    icon={<Circle />}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={1} style={{ padding: 10 }}>
                                                                                                <Box border={1} borderColor={theme.palette.gray.border}>
                                                                                                    <CardMedia
                                                                                                        style={{ height: 75, width: 80 }}
                                                                                                        image={_.size(cartItem.product.images_array) > 0 ? cartItem.product.images_array[0] : '/images/no-image.png'}
                                                                                                        title={cartItem.product.title}
                                                                                                        children={<div> <Button
                                                                                                            style={{ minWidth: 'unset', width: 'unset', padding: 0, top: -10, left: -10, backgroundColor: '#ffffff', borderBottomRightRadius: 10,color:'#ED3A80' }}
                                                                                                            variant="text"
                                                                                                            onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                                                          
                                                                                                            fullWidth
                                                                                                        >
                                                                                                            <CancelIcon />
                                                                                                        </Button></div>}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Grid>
                                                                                            <Grid item xs={4} style={{ padding: 10 }}>
                                                                                                <Grid item xs={12}>
                                                                                                    <Chip size="small" style={{ margin: 5 }} label={<Typography variant="caption" style={{ color: "#808080" }}>{t('product.unlistedProduct')}</Typography>} variant="outlined" color="default" />
                                                                                                </Grid>
                                                                                                <Grid item xs={12}>
                                                                                                    <Typography variant="caption" style={{ fontWeight: 'bold', color: "#808080" }}>{title_translate}</Typography>
                                                                                                </Grid>
                                                                                                {
                                                                                                    _.size(variant_translate) > 0 ?
                                                                                                        <Grid item xs={12}>
                                                                                                            <Typography variant="caption" style={{ fontSize: 10, color: "#808080" }}>{t('cart.variations')}: </Typography>
                                                                                                            {
                                                                                                                _.map(variant_translate, variantText => {
                                                                                                                    return (
                                                                                                                        <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: "#808080" }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        : null
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={2} style={{ padding: 10 }}>
                                                                                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                                                    <Typography variant="subtitle2" style={{ color: "#808080" }}>{t('cart.price')}</Typography>
                                                                                                    <Typography component='span' variant='caption' style={{ color: '#ED3A80'}}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.sell_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography>
                                                                                                    <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through', paddingLeft: 5 }}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.retail_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography>

                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Grid item xs={3} style={{ padding: 10 }}>
                                                                                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                                                    <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('cart.quantity')}</Typography>
                                                                                                    <FormControl>
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            value={cartItem.quantity}
                                                                                                            disableUnderline={true}
                                                                                                            fullWidth={true}
                                                                                                            classes={{ input: styles.inputStyle }}
                                                                                                            disabled
                                                                                                            startAdornment={
                                                                                                                <InputAdornment position="start">
                                                                                                                    <IconButton
                                                                                                                        disabled
                                                                                                                        style={{ color: "#ED3A80", padding: '7px',  borderRadius: 0 }}
                                                                                                                    >
                                                                                                                        <RemoveCircleOutlineOutlinedIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                            endAdornment={
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton
                                                                                                                        disabled
                                                                                                                        style={{ color: "#ED3A80", padding: '7px',  borderRadius: 0 }}
                                                                                                                    >
                                                                                                                        <AddCircleOutlineOutlinedIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            {
                                                                                                cartItem.quantity > stockAvailable ?
                                                                                                    <Grid item xs={12} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                        <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                            }
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid container style={{ alignItems: 'center' }}>
                                                                                            <Grid item style={{ padding: 10, alignSelf: 'center' }}>
                                                                                                <Checkbox
                                                                                                    checked={Boolean(cartItem.selected)}
                                                                                                    icon={<Circle />}
                                                                                                    checkedIcon={<CheckCircle />}
                                                                                                    onChange={() => updateCart(cartItem.id, null, true)}
                                                                                                    style={{ color:'#ED3A80'}}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={1} style={{ padding: 10 }}>
                                                                                                <Box border={1} borderColor={theme.palette.gray.border}>
                                                                                                    <CardMedia
                                                                                                        style={{ height: 75, width: 80 }}
                                                                                                        image={_.size(cartItem.product.images_array) > 0 ? cartItem.product.images_array[0] : '/images/no-image.png'}
                                                                                                        title={cartItem.product.title}
                                                                                                        children={<div> <Button
                                                                                                            style={{ minWidth: 'unset', width: 'unset', padding: 0, top: -10, left: -10, backgroundColor: '#ffffff', borderBottomRightRadius: 10,color:'#ED3A80' }}
                                                                                                            variant="text"
                                                                                                            onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                                                            
                                                                                                            fullWidth
                                                                                                        >
                                                                                                            <CancelIcon />
                                                                                                        </Button></div>}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Grid>
                                                                                            <Grid item xs={4} style={{ padding: 10 }}>
                                                                                                <Grid item xs={12}>
                                                                                                    <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography variant="caption" style={{ fontWeight: 'bold' }}>{title_translate}</Typography></Link>
                                                                                                </Grid>
                                                                                                {
                                                                                                    _.size(variant_translate) > 0 ?
                                                                                                        <Grid item xs={12}>
                                                                                                            <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                                                            {
                                                                                                                _.map(variant_translate, variantText => {
                                                                                                                    return (
                                                                                                                        <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        : null
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={2} style={{ padding: 10 }}>
                                                                                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                                                    <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('cart.price')}</Typography>
                                                                                                    <Typography component='span' variant='caption' style={{ color: '#ED3A80'}}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.sell_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography>
                                                                                                    <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through', paddingLeft: 5 }}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.retail_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Grid item xs={3} style={{ padding: 10 }}>
                                                                                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                                                    <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('cart.quantity')}</Typography>
                                                                                                    <FormControl>
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            value={cartItem.quantity}
                                                                                                            disableUnderline={true}
                                                                                                            fullWidth={true}
                                                                                                            classes={{ input: styles.inputStyle }}
                                                                                                            onChange={({ target }) => updateCart(cartItem.id, target.value)}
                                                                                                            startAdornment={
                                                                                                                <InputAdornment position="start">
                                                                                                                    <IconButton
                                                                                                                        color="secondary"
                                                                                                                        onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) - 1)}
                                                                                                                        style={{ color: 'black', padding: '7px',  borderRadius: 0 }}
                                                                                                                    >
                                                                                                                        <RemoveCircleOutlineOutlinedIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                            endAdornment={
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton
                                                                                                                        color="secondary"
                                                                                                                        onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) + 1)}
                                                                                                                        style={{ color: 'black', padding: '7px',  borderRadius: 0 }}
                                                                                                                    >
                                                                                                                        <AddCircleOutlineOutlinedIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            {
                                                                                                cartItem.quantity > stockAvailable ?
                                                                                                    <Grid item xs={12} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                        <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                            }
                                                                                        </Grid>
                                                                                }
                                                                            </ListItem>
                                                                            <Divider />
                                                                        </Box>

                                                                    )
                                                                })
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            )
                                        })
                                        :
                                        <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                            {
                                                cart === null ?
                                                    <>
                                                        <CircularProgress disableShrink />
                                                        <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                                    </>
                                                    :
                                                    <Box>
                                                        <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/general/empty-cart2.png'} style={{ width: '150px', maxWidth: '50%' }} />
                                                        <Typography style={{ textAlign: 'center', paddingLeft: '12px' }}>{t('general.noProduct')}</Typography>
                                                    </Box>
                                            }
                                        </Box>
                                }
                            </List>
                        </Hidden>
                        <Hidden mdUp>
                            <List>
                                <Divider />
                                {
                                    _.size(cart) > 0 && cart !== null ?
                                        _.map(cart, (merchantItem, merchantId) => {
                                            return (
                                                <Box key={merchantId} style={{ marginBottom: '20px' }}>
                                                    <Box border={1} display="flex" flexDirection="row" alignItems="center" borderColor={theme.palette.gray.border} style={{ padding: 10, backgroundColor: '#ED3A80', color: '#fff' }}>
                                                        <StorefrontIcon fontSize="small" style={{ marginLeft: 20 }} />
                                                        <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>{merchantItem.name}</Typography>
                                                    </Box>
                                                    {
                                                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                                            return (
                                                                _.map(cartDetails, (cartItem) => {
                                                                    const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                                    let variant_translate = [];
                                                                    let stockAvailable = 0;
                                                                    if (_.size(cartItem.product.options) > 0) {
                                                                        _.map(cartItem.product.options, (option) => {
                                                                            _.map(option.pricing, (pricingOption) => {
                                                                                if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                    if (option.color) {
                                                                                        variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                                    }
                                                                                    if (option.size) {
                                                                                        variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                                    }
                                                                                    if (option.attribute_value) {
                                                                                        variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                                    }
                                                                                    stockAvailable = pricingOption.quantity;
                                                                                }
                                                                            })
                                                                        });
                                                                    } else {
                                                                        stockAvailable = cartItem.product.quantity;
                                                                    }
                                                                    return (
                                                                        <Box key={cartItem.id} style={{ backgroundColor: '#fff' }}>
                                                                            <ListItem style={{ padding: '0' }}>
                                                                                {
                                                                                    shippingChannel === 'unlisted'
                                                                                        ?
                                                                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                                                                                            <Checkbox style={{ width: 35, padding: 0 }}
                                                                                                checked={Boolean(cartItem.selected)}
                                                                                                icon={<Circle />}
                                                                                                disabled
                                                                                            />
                                                                                            <Box style={{ width: 100, minWidth: 100, maxWidth: 100, height: 'fit-content' }} border={1} borderColor={theme.palette.gray.border}>
                                                                                                <CardMedia
                                                                                                    style={{ height: 75, width: 100 }}
                                                                                                    image={_.size(cartItem.product.images_array) > 0 ? cartItem.product.images_array[0] : '/images/no-image.png'}
                                                                                                    title={cartItem.product.title}
                                                                                                    children={<div> <Button
                                                                                                        style={{ minWidth: 'unset', width: 'unset', padding: 0, top: -10, left: -10, backgroundColor: '#ffffff', borderBottomRightRadius: 10 ,color:'#ED3A80'}}
                                                                                                        variant="text"
                                                                                                        onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                                                        
                                                                                                        fullWidth
                                                                                                    >
                                                                                                        <CancelIcon />
                                                                                                    </Button></div>}
                                                                                                />
                                                                                            </Box>

                                                                                            <Box style={{ paddingLeft: 10 }}>
                                                                                                <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                                                                                                    <Grid item xs={4}>
                                                                                                        <Box style={{ marginTop: 10 }}>
                                                                                                            <Chip size="small" style={{ margin: 5 }} label={<Typography variant="caption" style={{ color: "#808080" }}>{t('product.unlistedProduct')}</Typography>} variant="outlined" color="default" />
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                    {
                                                                                                        _.size(variant_translate) > 0 ?
                                                                                                            <Grid item xs={4}>
                                                                                                                <Typography variant="caption" style={{ fontSize: 10, color: "#808080" }}>{t('cart.variations')}: </Typography>
                                                                                                                {
                                                                                                                    _.map(variant_translate, variantText => {
                                                                                                                        return (
                                                                                                                            <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    <Grid item xs={4}>
                                                                                                        <Box>
                                                                                                            <Typography component='span' variant='caption' style={{ color: '#ED3A80' }}>
                                                                                                                <NumberFormat
                                                                                                                    value={parseFloat(cartItem.product.sell_price).toFixed(2)}
                                                                                                                    decimalScale={2}
                                                                                                                    displayType={'text'}
                                                                                                                    thousandSeparator={true}
                                                                                                                    prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                                />
                                                                                                            </Typography><br />
                                                                                                            <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through', paddingLeft: 5 }}>
                                                                                                                <NumberFormat
                                                                                                                    value={parseFloat(cartItem.product.retail_price).toFixed(2)}
                                                                                                                    decimalScale={2}
                                                                                                                    displayType={'text'}
                                                                                                                    thousandSeparator={true}
                                                                                                                    prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                                />
                                                                                                            </Typography>


                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('cart.quantity')}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>

                                                                                                        <FormControl style={{ margin: '5px 0' }}>
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                value={cartItem.quantity}
                                                                                                                disableUnderline={true}
                                                                                                                fullWidth={true}
                                                                                                                classes={{ input: styles.inputStyle }}
                                                                                                                disabled
                                                                                                                startAdornment={
                                                                                                                    <InputAdornment position="start">
                                                                                                                        <IconButton
                                                                                                                            style={{ padding: '0' }}
                                                                                                                            disabled
                                                                                                                        >
                                                                                                                            <RemoveCircleOutlineOutlinedIcon />
                                                                                                                        </IconButton>
                                                                                                                    </InputAdornment>
                                                                                                                }
                                                                                                                endAdornment={
                                                                                                                    <InputAdornment position="end">
                                                                                                                        <IconButton
                                                                                                                            style={{ padding: '0' }}
                                                                                                                            disabled
                                                                                                                        >
                                                                                                                            <AddCircleOutlineOutlinedIcon />
                                                                                                                        </IconButton>
                                                                                                                    </InputAdornment>
                                                                                                                }
                                                                                                            />
                                                                                                        </FormControl>
                                                                                                    </Grid>
                                                                                                    <Box>
                                                                                                        {
                                                                                                            cartItem.quantity > stockAvailable ?
                                                                                                                <Grid item xs={4} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                                    <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                        }
                                                                                                    </Box>
                                                                                                </Grid>
                                                                                            </Box>
                                                                                        </Box>
                                                                                        :
                                                                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                                                                                            <Checkbox style={{ width: 35, padding: 0 }}
                                                                                                checked={Boolean(cartItem.selected)}
                                                                                                icon={<Circle />}
                                                                                                checkedIcon={<CheckCircle />}
                                                                                                onChange={() => updateCart(cartItem.id, null, true)}
                                                                                                style={{ color:'#ED3A80'}}
                                                                                            />
                                                                                            <Box style={{ width: 100, minWidth: 100, maxWidth: 100, height: 'fit-content' }} border={1} borderColor={theme.palette.gray.border}>

                                                                                                <CardMedia
                                                                                                    style={{ height: 75, width: 100 }}
                                                                                                    image={_.size(cartItem.product.images_array) > 0 ? cartItem.product.images_array[0] : '/images/no-image.png'}
                                                                                                    title={cartItem.product.title}
                                                                                                    children={<div> <Button
                                                                                                        style={{ minWidth: 'unset', width: 'unset', padding: 0, top: -10, left: -10, backgroundColor: '#ffffff', borderBottomRightRadius: 10,color:'#ED3A80' }}
                                                                                                        variant="text"
                                                                                                        onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                                                        
                                                                                                        fullWidth
                                                                                                    >
                                                                                                        <CancelIcon />
                                                                                                    </Button></div>}
                                                                                                />
                                                                                            </Box>

                                                                                            <Box style={{ paddingLeft: 10 }}>
                                                                                                <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                                                                                                    <Grid item xs={4}>
                                                                                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'flex-start' }}>
                                                                                                            <Grid>
                                                                                                                <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography variant="caption">{title_translate}</Typography></Link>
                                                                                                            </Grid>
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                    {
                                                                                                        _.size(variant_translate) > 0 ?
                                                                                                            <Grid item xs={6}>
                                                                                                                <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                                                                {
                                                                                                                    _.map(variant_translate, variantText => {
                                                                                                                        return (
                                                                                                                            <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography component='span' variant='caption' style={{ color: '#ED3A80' }}>
                                                                                                            <NumberFormat
                                                                                                                value={parseFloat(cartItem.product.sell_price).toFixed(2)}
                                                                                                                decimalScale={2}
                                                                                                                displayType={'text'}
                                                                                                                thousandSeparator={true}
                                                                                                                prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                            />
                                                                                                        </Typography><br />
                                                                                                        <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through', paddingLeft: 5 }}>
                                                                                                            <NumberFormat
                                                                                                                value={parseFloat(cartItem.product.retail_price).toFixed(2)}
                                                                                                                decimalScale={2}
                                                                                                                displayType={'text'}
                                                                                                                thousandSeparator={true}
                                                                                                                prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                            />
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>


                                                                                                {/* <Box>
                                                                                                    {
                                                                                                        _.size(variant_translate) > 0 ?
                                                                                                            <Grid item xs={12}>
                                                                                                                <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                                                                {
                                                                                                                    _.map(variant_translate, variantText => {
                                                                                                                        return (
                                                                                                                            <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                </Box> */}
                                                                                                {/* <Box>
                                                                                                    <Typography component='span' variant='caption' style={{ color: theme.palette.primary.main }}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.sell_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography>
                                                                                                    <Typography variant='caption' color='textSecondary' style={{ textDecoration: 'line-through', paddingLeft: 5 }}>
                                                                                                        <NumberFormat
                                                                                                            value={parseFloat(cartItem.product.retail_price).toFixed(2)}
                                                                                                            decimalScale={2}
                                                                                                            displayType={'text'}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `}
                                                                                                        />
                                                                                                    </Typography>
                                                                                                </Box> */}
                                                                                                <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography variant="subtitle2" style={{ color: theme.palette.gray.main }}>{t('cart.quantity')}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <FormControl style={{ margin: '5px 0' }}>
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                value={cartItem.quantity}
                                                                                                                disableUnderline={true}
                                                                                                                fullWidth={true}
                                                                                                                classes={{ input: styles.inputStyle }}
                                                                                                                onChange={({ target }) => updateCart(cartItem.id, target.value)}
                                                                                                                startAdornment={
                                                                                                                    <InputAdornment position="start">
                                                                                                                        <IconButton
                                                                                                                            style={{ padding: '0', color: 'black' }}

                                                                                                                            onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) - 1)}
                                                                                                                        >
                                                                                                                            <RemoveCircleOutlineOutlinedIcon />
                                                                                                                        </IconButton>
                                                                                                                    </InputAdornment>
                                                                                                                }
                                                                                                                endAdornment={
                                                                                                                    <InputAdornment position="end">
                                                                                                                        <IconButton
                                                                                                                            style={{ padding: '0', color: 'black' }}

                                                                                                                            onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) + 1)}
                                                                                                                        >
                                                                                                                            <AddCircleOutlineOutlinedIcon />
                                                                                                                        </IconButton>
                                                                                                                    </InputAdornment>
                                                                                                                }
                                                                                                            />
                                                                                                        </FormControl></Grid>
                                                                                                    <Box>
                                                                                                        {
                                                                                                            cartItem.quantity > stockAvailable ?
                                                                                                                <Grid item xs={4} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                                    <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                        }
                                                                                                    </Box>
                                                                                                </Grid>

                                                                                            </Box>
                                                                                        </Box>
                                                                                }
                                                                            </ListItem>
                                                                            <Divider />
                                                                        </Box>
                                                                    )
                                                                })
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            )
                                        })
                                        :
                                        <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                            {
                                                cart === null ?
                                                    <>
                                                        <CircularProgress disableShrink />
                                                        <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                                    </>
                                                    :
                                                    <Box>
                                                        <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/general/empty-cart2.png'} style={{ width: '150px', maxWidth: '50%' }} />
                                                        <Typography style={{ textAlign: 'center', paddingLeft: '12px' }}>{t('general.noProduct')}</Typography>
                                                    </Box>
                                            }
                                        </Box>
                                }
                            </List>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Dialog
                            open={state.dialogShow}
                            onClose={() => setState({ ...state, dialogShow: false })}
                            aria-labelledby="draggable-dialog-title"
                        >
                            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                {t('cart.removeTitle')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {t('cart.removeContent')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={() => setState({
                                        ...state,
                                        dialogShow: false,
                                        removeCartId: []
                                    })}
                                    color="default"
                                >
                                    <Typography variant="overline">{t('button.cancel')}</Typography>
                                </Button>
                                <Button onClick={removeCart} color="secondary">
                                    <Typography variant="overline">{t('button.confirm')}</Typography>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
                {/* <Snackbar 
                    open={state.open} 
                    autoHideDuration={3000} 
                    onClose={() => setState({ ...state, open: false, error: false }) }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: theme.palette.error.main }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{ state.message }</Typography>
                        </CardContent>
                    </Card>
                </Snackbar> */}
            </Box>

            {/* className={styles.stickyFooter}   */}
            {
                _.size(cart) > 0 && cart !== null ?
                    <>
                        <Hidden only={['sm', 'xs']}>
                            <Box style={{ width: '100%', backgroundColor: '#ffffff', padding: '10px 15px' }}>
                                <Grid item xs={12} sm={10} style={{ margin: '0 auto' }}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ textAlign: 'start' }} >

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.checkAll}
                                                        icon={<Circle />}
                                                        checkedIcon={<CheckCircle />}
                                                        onChange={checkAll}
                                                        size='small'
                                                        style={{color:'#ED3A80'}}
                                                    />
                                                }
                                                label={
                                                    <Box>
                                                        <Typography variant="subtitle2" style={{ color: '#383838' }}>{t('cart.all')}</Typography>
                                                    </Box>
                                                }
                                            />
                                        </Box>

                                        <Box style={{ textAlign: 'center' }}>
                                            <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold' }}>
                                                {t('cart.selectedProduct')}: {state.totalSelected}
                                            </Typography>
                                        </Box>

                                        <Box classes={{ root: styles.tableCellStyle }} >
                                            <Box>
                                                <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }}>
                                                    {t('cart.subTotal')}: <br />
                                                    {
                                                        state.totalPrice > 0
                                                            ?
                                                            <NumberFormat style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#0f386b' }} className={styles.priceStyle} value={state.totalPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} />
                                                            :
                                                            null
                                                    }
                                                </Typography>
                                            </Box>
                                            {
                                                _.size(state.totalWalletPrice) > 0 ?
                                                    _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                                        return (
                                                            <Box key={walletType}>
                                                                <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }} className={styles.priceStyle}>
                                                                    <NumberFormat className={styles.priceStyle} style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#0f386b' }} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={`${walletType} `} />
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Box>

                                        <Box classes={{ root: styles.tableCellStyle }} style={{ marginLeft: '5px' }} >
                                            {/* style={{ width: '25%' }} */}

                                            <Button variant="contained" size="large" style={{ backgroundColor: '#ED3A80', color: '#fff', padding: '5px 12px' }} disabled={state.totalSelected > 0 ? false : true} onClick={checkoutCart}>
                                                <Typography variant="overline">{t('cart.checkout')}</Typography>
                                            </Button>
                                        </Box>


                                    </Box>
                                </Grid>
                            </Box>
                            <img src="/images/general/voucher_side.png" />

                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Box style={{ width: '100%', backgroundColor: '#ffffff', padding: '10px 15px' }}>
                                <Grid container spacing={1} direction="column">
                                    <Grid item xs={12} >
                                        <List>
                                            <ListItemText>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state.checkAll}
                                                            icon={<Circle />}
                                                            checkedIcon={<CheckCircle />}
                                                            onChange={checkAll}
                                                            size='small'
                                                        />
                                                    }
                                                    label={
                                                        <Box>
                                                            <Typography variant="subtitle2" style={{ color: '#383838' }}>{t('cart.all')}</Typography>
                                                        </Box>
                                                    }
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold' }}>
                                                    {t('cart.selectedProduct')}: {state.totalSelected}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </List>

                                        {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.checkAll}
                                                    icon={<Circle />}
                                                    checkedIcon={<CheckCircle />}
                                                    onChange={checkAll}
                                                    size='small'
                                                />
                                            }
                                            label={
                                                <Box>
                                                    <Typography variant="subtitle2" style={{ color: '#383838' }}>{t('cart.all')}</Typography>
                                                </Box>
                                            }
                                        />

                                        <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold' }}>
                                            {t('cart.selectedProduct')}: {state.totalSelected}
                                        </Typography> */}

                                    </Grid>
                                    <Grid item xs={12}>
                                        <List>
                                            {/* <ListItem>
                                                <ListItemText>
                                                    <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold' }}>
                                                        {t('cart.selectedProduct')}
                                                    </Typography>
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold' }}>
                                                        {state.totalSelected}
                                                    </Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem> */}
                                            <ListItem>
                                                <ListItemText>
                                                    <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }}>{t('cart.subTotal')}</Typography>
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Typography variant="overline" style={{ color: '#383838', fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }}>
                                                        {
                                                            state.totalPrice > 0
                                                                ?
                                                                <NumberFormat style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#0f386b' }} className={styles.priceStyle} value={state.totalPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${_.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : ''} `} />
                                                                :
                                                                null
                                                        }
                                                    </Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>

                                            {
                                                _.size(state.totalWalletPrice) > 0 ?
                                                    _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                                        return (
                                                            <ListItem>
                                                                <ListItemSecondaryAction>
                                                                    <Box key={walletType}>
                                                                        <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }} className={styles.priceStyle}>
                                                                            <NumberFormat className={styles.priceStyle} style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#0f386b' }} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={`${walletType} `} />
                                                                        </Typography>
                                                                    </Box></ListItemSecondaryAction>
                                                            </ListItem>
                                                        )
                                                    })
                                                    : null
                                            }

                                        </List>
                                    </Grid>
                                </Grid>
                            </Box>
                            <img src="/images/general/voucher_side.png" />
                            <Box style={{ backgroundColor: '#ED3A80', padding: '10 5' }}>
                                <Button style={{ color: '#ffffff' }} onClick={checkoutCart} fullWidth>{t('cart.checkout')}</Button>
                            </Box>
                        </Hidden>
                    </>
                    :
                    null
            }


        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
    },
    inputStyle: {
        textAlign: 'center',
        width: 10,
        fontSize: 16,
        padding: 5,
    },
    priceStyle: {
        fontSize: 14,
        // color: theme.palette.secondary.main,
        color: 'white',
    },
    icon: {
        color: `${theme.palette.button.step} !important `,
    },
    whiteText: {
        fill: '#fff'
    },
    stepperStyle: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    containerStyle: {
        borderWidth: 20
    },
    stickyFooter: {
        position: 'fixed',
        marginTop: 'calc(5% + 60px)',
        bottom: 0,
        background: 'linear-gradient(to right, #6aacdd, #5e95ce)',

    },
    tableCellStyle: {
        borderBottom: 0,
        textAlign: 'end'
    },
    tableContainerStyle: {
        borderRadius: 0,
        zIndex: 2
    },
    itemContainerStyle: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    imgStyle: {
        height: '100%',
        width: '100%'
    },
    itemBoxStyle: {
        marginBottom: 10,
        borderRadius: 20,
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
    firstItemBoxStyle: {
        marginBottom: 10,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
}));