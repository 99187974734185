import React, { Suspense, Fragment,useState, useRef, useEffect} from "react";
import { Switch, BrowserRouter as Router, Route, Redirect , useHistory} from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import ScrollToTop from '../helper/ScrollToTop';
import { LastLocationProvider } from 'react-router-last-location';
import useNotificationLoading from '../helper/useNotificationLoading';
import { getUrl, deleteUrl, putUrl } from '../helper/ApiAction';

import NotificationLoadingProvider from '../providers/NotificationLoadingProvider';
import NotificationLoading from '../components/Layouts/NotificationLoading';

import Header from './Layouts/Header';
import Footer from './Layouts/Footer';
import ChatButton from './Layouts/ChatButton';
import BackToTop from "./Layouts/BackToTop";

import Account from './Account';
import AffiliantPartner from './AffiliantProgram';
import Announcement from './Announcement';
import AccountLink from './AccountLink';
import AccountLinkVerify from './AccountLinkVerify';
import AdminChat from './AdminChat';

import Bonus from "./Bonus";
// import BindSubAccount from './BindSubAccount';

import Callback from './Callback';
import CategoryList from './CategoryList';
// import Categories from './Categories';
import CategoriesNew from "./CategoriesNew";
// import Cart from './Cart';
// import Cart from './CartV2';
import CartNew from "./CartNew";
import Chat from './Chat';
import ChatHome from './ChatHome';
import Chatting from './Chatting';
import Checkout from './Checkout';
import Collections from './Collections';
import ContactUs from './ContactUs';
import ContactUsForm from './ContactUsForm';
import ComingSoon from './ComingSoon';
import Contract from './Contract';
import ContractDetails from './ContractDetails';
import Convert from "./Convert";
import EditRecipient from "./EditRecipient";

import Faq from './Faq';

// import GenealogySponsor from './GenealogySponsor';
// import Genealogy from './Genealogy';

// import Home from './Home';
import Home from './HomeV2';

import Item from './Item';

import Kyc from './Kyc';

// import Login from './Login';
import Login from './LoginV2';
import SplashScreen from './SplashScreen';

import MerchantServiceAgreement from './MerchantServiceAgreement';
// import Migrate from './Migrate';
// import MyPackage from './MyPackage';
import MyStore from './MyStore';

import Notification from "./Notification";
import NotificationCategory from "./NotificationCategory";
import NotificationDetails from "./NotificationDetails";

import OrderHistory from "./OrderHistory";
import OrderHistoryNew from "./OrderHistoryNew";

import PasswordForgot from './PasswordForgot';
import PasswordReset from './PasswordReset';
import Payment from './PaymentNew';
//import Payment from './Payment';
import PaymentCallback from './PaymentCallback';
import PlacementSetting from "./PlacementSetting";

import Policies from './Policies';
import PrivacyAgreement from './PrivacyAgreement';
import Profile from './Profile';
import ProfileRank from "./Rank";
import ProfileBankInfo from './ProfileBankInfo';
import ProfilePassword from './ProfilePassword';
import ProfileSecurityPassword from './ProfileSecurityPassword';
import ProfileAddress from "./ProfileAddress";
import ProfileAddressAction from "./ProfileAddressAction";

import Recruit from './Recruit';
import RefundAndReturnAgreement from './RefundAndReturnAgreement';
// import Register from './Register';
import Register from './RegisterV2';
// import Review from './Review';
import ReviewNew from "./ReviewNew";

import Search from './Search';
import Setting from './Setting';
import Shop from './Shop';

import TermOfUse from './TermOfUse';
import Transaction from "./Transaction";
import Transfer from "./Transfer";
import TransferNew from "./TransferNew";
import TransactionNew from "./TransactionNew";

import VoucherItem from './VoucherItem';
import VoucherRedemption from './VoucherRedemption';
import VoucherCart from './VoucherCart';
import VoucherCheckout from './VoucherCheckout';
import VPCollection from './VPCollection';

import Wallet from './Wallet';
import WalletNew from "./WalletNew";
import Withdrawal from "./Withdrawal";
import WithdrawalNew from "./WithdrawalNew";
import WithdrawalHistory from "./WithdrawalHistory";

import Event from './Event';

import { BASE_URL, STRIPE_KEY, PROJECT_TYPE } from '../configs/Config';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import './css/styles.css';
import './css/util.css';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


const promise = loadStripe(STRIPE_KEY);

// function GenericNotFound() {
//     return (
//         <div>Not Found</div>
//     );
// }
const NewPayment = () => {
    return (
        <Elements stripe={promise}>
            <Payment />
        </Elements>
    )
}

const Loading = () => {
    return (
        <div style={{ position: "fixed", top: '50%', left: '50%' }}>
            <CircularProgress disableShrink />
        </div>
    );
};


const routes = [
    {
        path: "/home",
        component: <Home />,
        exact: true
    },
    {
        path: "/checkout",
        component: <Checkout />,
        exact: true
    },
    {
        path: "/contract/:username",
        component: <ContractDetails />,
        exact: true
    },
    {
        path: "/contract",
        component: <Contract />,
        exact: true
    },
    {
        path: "/payment",
        component: <NewPayment />,
        exact: true
    },  
    {
        path: "/payment/:oid",
        component: <NewPayment />,
        exact: true
    },
    {
        path: "/account/:page/:oid",
        component: <Account />,
        exact: true
    },
    {
        path: "/account/:page",
        component: <Account />,
        exact: true
    },
    {
        path: "/account",
        component: <Account />,
        exact: true
    },
    {
        path: "/chat",
        component: <Chat />,
        exact: true
    },
    {
        path: "/chatHome",
        component: <ChatHome />,
        exact: true
    },
    {
        path: "/chatting",
        component: <Chatting />,
        exact: true
    },
    /* {
        path:"/bindSubAccount",
        component: <BindSubAccount />,
        exact: true
    },
    {
        path:"/genealogySponsor",
        component:<GenealogySponsor />,
        exact: true,
    },
    {
        path: "/community/:page",
        component: <Genealogy/>,
        exact: true
    }, */
    {
        path:"/profile",
        component: <Profile />,
        exact: true
    },
    {
        path:"/profile-rank",
        component: <ProfileRank />,
        exact: true
    },
    {
        path:"/profile-bank-info",
        component: <ProfileBankInfo />,
        exact: true
    },
    {
        path:"/profile-password",
        component: <ProfilePassword />,
        exact: true
    },
    {
        path:"/profile-security-password",
        component: <ProfileSecurityPassword />,
        exact: true
    },
    {
        path:"/profile-address",
        component: <ProfileAddress />,
        exact: true
    },
    {
        path:"/profile-address-action/:action/:id",
        component: <ProfileAddressAction />,
        exact: true
    },
    {
        path:"/edit-recipient",
        component: <EditRecipient />,
        exact: true
    },
    {
        path:"/profile-address-action/:action",
        component: <ProfileAddressAction />,
        exact: true
    },
    {
        path:"/recruit",
        component: <Recruit />,
        exact: true
    },
    {
        path:"/customerservice",
        component:<AdminChat />,
        exact:true,
    },
    {
        path:"/voucher_checkout",
        component: <VoucherCheckout />,
        exact: true
    },
    /* {
        path: "/myPackage",
        component: <MyPackage />,
        exact: true
    },
    {
        path: "/cap-convert",
        component: <CapConvert />,
        exact: true
    }, */
    // {
    //     path: "/wallet",
    //     component: <Wallet />,
    //     exact: true
    // },
    {
        path: "/wallet",
        component: <WalletNew />,
        exact: true
    },
    // {
    //     path: "/transfer/:wallet",
    //     component: <Transfer />,
    //     exact: true
    // },
    {
        path: "/transfer/:wallet",
        component: <TransferNew />,
        exact: true
    },
    {
        path: "/convert/:wallet",
        component: <Convert />,
        exact: true
    },
    // {
    //     path:"/transaction/:wallet",
    //     component: <Transaction />,
    //     exact: true
    // },
    {
        path:"/transaction/:wallet",
        component: <TransactionNew />,
        exact: true
    },
    // {
    //     path:"/withdrawal/:wallet",
    //     component: <Withdrawal />,
    //     exact: true
    // },
    {
        path:"/withdrawal/:wallet",
        component: <WithdrawalNew />,
        exact: true
    },
    {
        path:"/withdrawal-history/:wallet",
        component: <WithdrawalHistory />,
        exact: true
    },
    {
        path:"/announcement",
        component: <Announcement />,
        exact: true
    },
    {
        path:"/announcement/:id",
        component: <Announcement />,
        exact: true
    },
    // {
    //     path: "/order",
    //     component: <OrderHistory />,
    //     exact: true
    // },
    // {
    //     path: "/order/:oid",
    //     component: <OrderHistory />,
    //     exact: true
    // },
    {
        path: "/order",
        component: <OrderHistoryNew />,
        exact: true
    },
    {
        path: "/order/:oid",
        component: <OrderHistoryNew />,
        exact: true
    },
    {
        path: "/review/:oid",
        component: <ReviewNew />,
        exact: true
    },
    {
        path:"/bonus/:bonus",
        component: <Bonus />,
        exact: true
    },
    {
        path:"/bonus",
        component: <Bonus />,
        exact: true
    },
    {
        path:"/profile-linking",
        component: <AccountLink />,
        exact: true
    },
    {
        path:"/placement-setting",
        component: <PlacementSetting />,
        exact: true
    },
    {
        path:"/notification",
        component: <Notification />,
        exact: true
    },
    {
        path:"/notification-category",
        component: <NotificationCategory />,
        exact: true
    },
    {
        path:"/event",
        component: <Event />,
        exact: true
    },
    /* {
         path:"/migrate",
         component: <Migrate />,
         exact: true
    }, */
];
    
function PrivateRoute({ children, ...rest }) {
    const { accessToken } = useSelector(state => state.general);
    const { kycRequired, verified } = useSelector(state => state.user);
    const { id } = useSelector(state => state.user);
    /* const  [migrateuser,setmigrateuser] = useState([])
    getUrl(`migrate/${id}`).then(result => {
        setmigrateuser(result.downline)
    }).catch(error => {
    }); */
    return (
        <Route
            {...rest}
            render={({ location }) =>
                accessToken && kycRequired === true && verified < 3 ?
                    <Redirect
                        to={{
                            pathname: "/kyc",
                            state: { from: location }
                        }}
                    />
                    :
                    accessToken? (
                        /* migrateuser === "true" && location.pathname !=='/migrate'?
                            <Redirect
                                to={{
                                 pathname: "/migrate",
                                state: { from: location }
                                 }}
                            /> */
                        (children)
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

const PageContents = () => {
    const { accessToken } = useSelector(state => state.general);
    const { verified, kycRequired, max_rank } = useSelector(state => state.user);
    
    if (accessToken)
    {
        return(
            <Switch>
                <Route path="/" exact component={Home}></Route>

                <Route path="/affiliant-partner" component={AffiliantPartner} />

                <Route path="/contactUsForm" component={ContactUsForm} />
                <Route path="/contactUs" component={ContactUs} />
                <Route path="/comingSoon" component={ComingSoon} />
                <Route path="/categorylist" component={CategoryList} />
                <Route path="/category/:id" component={CategoriesNew} />
                <Route path="/collection/:id" component={Collections} />
                <Route path="/callback" component={Callback} />

                <Route path="/faq" exact component={Faq} />

                <Route path="/item/:id" component={Item} />

                <Route path="/kyc">{accessToken && kycRequired === true ?  <NotificationLoadingProvider><Kyc /><NotificationLoading /></NotificationLoadingProvider> : <Redirect to={{ pathname: "/" }} /> }</Route>

                <Route path="/merchantServiceAgreement" component={MerchantServiceAgreement} />

                <Route path="/notification_detail/:id" component={NotificationDetails} />

                <Route path="/termOfUse" component={TermOfUse} />

                <Route path="/policies" component={Policies} />
                <Route path="/privacyAgreement" component={PrivacyAgreement} />
                <Route path="/paymentresult/:oid" component={PaymentCallback} />
                <Route path="/paymentresult/" component={PaymentCallback} />

                <Route path="/search/:keyword" component={Search} />
                <Route path="/search" component={Search} />
                <Route path="/shop/:shopSlug" component={Shop} />
                <Route path="/setting" component={Setting} />

                <Route path="/revenuemonster/:oid/callback" render={(props) => (<Redirect to={{ pathname: `/paymentresult/${props.match.params.oid}`, search: '?source=revenuemonster' }} /> )}></Route>
                <Route path="/review/:id" component={ReviewNew} />
                <Route path="/refundAndReturnAgreement" component={RefundAndReturnAgreement} />
                
                <Route path="/voucher_redemption" component={VoucherRedemption}/>
                <Route path="/voucher_cart" component={VoucherCart}/>
                <Route path="/voucher/:id" component={VoucherItem} />

                {
                    PROJECT_TYPE === "catalog" ? 
                        <Route path="/mystore" component={MyStore} />
                        :
                        <Route path="/cart" component={CartNew} />
                }

                {
                    _.map(routes, route => (
                        // route.path == "/recruit" && _.size(max_rank) == 0 ? 
                        // <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}<Redirect to={{ pathname: "/" }} /></PrivateRoute>
                        // :
                        <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}</PrivateRoute>
                        
                    ))
                }
                
                {/* 
                <Route path="/account-link" component={AccountLinkVerify}/>
                <Route path="/checkout" component={Checkout} />
                <Route path="/payment/:oid" component={Payment} />
                <Route path="/payment" component={Payment} />
                <Route path="/account/:page/:oid" component={Account} />
                <Route path="/account/:page" component={Account} />
                <Route path="/account" component={Account} />
                <Route component={GenericNotFound} />
                <Route path="/callback" component={Callback} />  
                */}
                <Redirect from="*" to="/" />
            </Switch>
        )
    }
    else
    {
        return(
            <Switch>

                <Route path="/forgot-password" component={PasswordForgot} />

                <Route path="/register" component={Register} />

                <Route path="/login" component={Login} />

                <Route path="/splashscreen" component={SplashScreen} />

                <Route path="/reset-password/:token" component={PasswordReset}>{accessToken ? <Redirect to={{ pathname: "/" }} /> : <NotificationLoadingProvider><PasswordReset /><NotificationLoading /></NotificationLoadingProvider> }</Route>

                <Redirect from="*" to="/splashscreen" />

            </Switch>
        )
    }
}

export default function App() {
    const { accessToken } = useSelector(state => state.general);
    const { verified, kycRequired, max_rank } = useSelector(state => state.user);
    const history = useHistory();

    return (
        <NotificationLoadingProvider>
            <Suspense fallback={<Loading />}>
                <Router basename={BASE_URL}>
                <LastLocationProvider>
                    <Fragment>
                        <ScrollToTop />
                        <Header />
                        {/* <div id='chatStyle' style={{backgroundColor:'#f6f7fa', width:420, margin:'0 auto', maxWidth:'100%', boxShadow:'0 0px 6px 8px #00000003' }}> */}
                        <div id='chatStyle' style={{backgroundColor:'#f6f7fa', width:420, margin:'0', maxWidth:'100%', position:'relative', height:'auto'}}>
                            {/* 6 is July. */}
                            {new Date() >= new Date(2020, 6, 8, 0, 0, 0) && new Date() < new Date(2020, 6, 8, 10, 31, 0) && false?
                                <Switch>
                                    <Route path='' component={() => {
                                    window.location.href = 'https://about.healthcare2u.asia';
                                    return null;
                                }} />
                                </Switch>
                                :
                                <PageContents/>
                                // <Switch>
                                //     <Route path="/search/:keyword" component={Search} />
                                //     <Route path="/search" component={Search} />
                                //     {
                                //         PROJECT_TYPE === "catalog" ? 
                                //             <Route path="/mystore" component={MyStore} />
                                //             :
                                //             <Route path="/cart" component={CartNew} />
                                //     }
                                //     <Route path="/setting" component={Setting} />
                                //     <Route path="/policies" component={Policies} />
                                //     <Route path="/merchantServiceAgreement" component={MerchantServiceAgreement} />
                                //     <Route path="/privacyAgreement" component={PrivacyAgreement} />
                                //     <Route path="/refundAndReturnAgreement" component={RefundAndReturnAgreement} />
                                //     <Route path="/termOfUse" component={TermOfUse} />
                                //     <Route path="/contactUsForm" component={ContactUsForm} />
                                //     <Route path="/contactUs" component={ContactUs} />
                                //     <Route path="/comingSoon" component={ComingSoon} />
                                //     <Route path="/item/:id" component={Item} />
                                //     <Route path="/notification_detail/:id" component={NotificationDetails} />
                                //     <Route path="/voucher/:id" component={VoucherItem} />


                                //     <Route path="/categorylist" component={CategoryList} />
                                //     <Route path="/category/:id" component={Categories} />
                                //     <Route path="/affiliant-partner" component={AffiliantPartner} />
                                //     <Route path="/collection/:id" component={Collections} />
                                //     <Route path="/faq" exact component={Faq} />
                                //     <Route path="/" exact component={Home}></Route>
                                //     <Route path="/callback" component={Callback} />
                                //     <Route path="/revenuemonster/:oid/callback" render={(props) => (<Redirect to={{ pathname: `/paymentresult/${props.match.params.oid}`, search: '?source=revenuemonster' }} /> )}></Route>
                                //     <Route path="/paymentresult/:oid" component={PaymentCallback} />
                                //     <Route path="/paymentresult/" component={PaymentCallback} />
                                //     <Route path="/shop/:shopSlug" component={Shop} />
                                //     {/* <Route path="/review/:id" component={Review} /> */}
                                //     <Route path="/forgot-password" component={PasswordForgot} />
                                //     <Route path="/voucher_redemption" component={VoucherRedemption}/>
                                //     <Route path="/voucher_cart" component={VoucherCart}/>
                                //     <Route path="/reset-password/:token" component={PasswordReset}>{accessToken ? <Redirect to={{ pathname: "/" }} /> : <NotificationLoadingProvider><PasswordReset /><NotificationLoading /></NotificationLoadingProvider> }</Route>
                                //     <Route path="/kyc">{accessToken && kycRequired === true ?  <NotificationLoadingProvider><Kyc /><NotificationLoading /></NotificationLoadingProvider> : <Redirect to={{ pathname: "/" }} /> }</Route>
                                //     {
                                //         _.map(routes, route => (
                                //             // route.path == "/recruit" && _.size(max_rank) == 0 ? 
                                //             // <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}<Redirect to={{ pathname: "/" }} /></PrivateRoute>
                                //             // :
                                //             <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}</PrivateRoute>
                                            
                                //         ))
                                //     }

                                //     {/* 
                                //     <Route path="/account-link" component={AccountLinkVerify}/>
                                //     <Route path="/checkout" component={Checkout} />
                                //     <Route path="/payment/:oid" component={Payment} />
                                //     <Route path="/payment" component={Payment} />
                                //     <Route path="/account/:page/:oid" component={Account} />
                                //     <Route path="/account/:page" component={Account} />
                                //     <Route path="/account" component={Account} />
                                //     <Route component={GenericNotFound} />
                                //     <Route path="/callback" component={Callback} />  
                                //     */}
                                //     <Route path="/register" component={Register} />
                                //     <Route path="/login" component={Login} />
                                //     <Route path="/splashscreen" component={SplashScreen} />
                                // </Switch>
                                
                                
                            }
                            
                        </div>
                        {/* <ChatButton /> */}
                        <Footer/>
                    </Fragment>
                </LastLocationProvider>
                </Router>
            </Suspense>
            <NotificationLoading />
        </NotificationLoadingProvider>
    );
};