import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Grid, Button, Box, Typography, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import MemberButtonDrawer from '../components/MemberButtonDrawer'
import { MuiThemeProvider, createMuiTheme, makeStyles, useTheme } from '@material-ui/core/styles';
import useNotificationLoading from '../../helper/useNotificationLoading';
import '../css/tree.css';
import { BiFontSize } from 'react-icons/bi';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

const REVENUEMONSTER_AVAILABLE = true;

const PaymentMethod = (props) => {
    const { onClose, open, title, oid, postUrl, wallets, total_price , setPaymentMethod, allow , val } = props;
    const styles = useStyles();
    const theme = useTheme();
    const [selectList, setSelectList] = useState(false)
    const [validBalance, setValidBalance] = useState(true);
    const [disableAP, setDisableAP] = useState(true);
    const [value, setValue] = useState(val?val:'');
    const { addAlert, setLoading } = useNotificationLoading();
    const { i18n, t } = useTranslation();


    useEffect(()=>{
        if(wallets){
            let ap_Wallet = _.find(wallets,{'code': 'AP'});
            if(ap_Wallet ){
                if(parseFloat(ap_Wallet.balance) >= parseFloat(total_price)){
                    setDisableAP(false)
                }else{
                    setDisableAP(true)
                }
            }
        }
    },[wallets,total_price])

    const handleChange = (event) => {
        setValue(event.target.value)
    }
    const handleOnClose = () => {
        setPaymentMethod(value);
        onClose();
    }

    return (
        <MemberButtonDrawer open={open} onClose={onClose}>
            <Grid container style={{ padding: '30px 40px', maxWidth: 420, alignItems: 'center' }}>
                <Grid item xs={11} md={11} >
                    <Typography className={styles.title}>{title}</Typography>
                </Grid>
                <Grid item xs={1} md={1} style={{ paddingRight: 20 }}>
                    <Button onClick={onClose}>
                        <CloseIcon style={{ color: '#ADADAD' }}></CloseIcon>
                    </Button>
                </Grid>
                <Grid>
                    {
                        validBalance ?
                            //&& ((parseFloat(totalPayment['cash'])).toFixed(2) > 0)
                            <Grid container style={{width:'350px'}}>
                                {true ?
                                    <>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <Grid item xs={12} style={{ textAlign: 'right', zIndex: 1299}}>
                                                <Grid container style={{ paddingRight: '15px', paddingTop: '10px',  width: '350px'}}>
                                                    {REVENUEMONSTER_AVAILABLE ?
                                                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <FormControlLabel value="revenuemonster" control={<Radio />} label="REVENUEMONSTER" disabled={!allow.cash}/>
                                                        </Grid>
                                                    : null}
                                                    {
                                                        wallets ?
                                                            _.map(wallets, (wallet_detail, index) => {
                                                                let wallet_name = wallet_detail.name.split("|");
                                                                
                                                                return (
                                                                    allow[wallet_detail.id] ?
                                                                    <>
                                                                        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <FormControlLabel value={wallet_detail.code} control={<Radio />} label={t('PaymentMethod.walletPaymentTitle', { 'walletName': i18n.language == 'cn' ? wallet_name[1] : wallet_name[0] })} />
                                                                        </Grid>
                                                                        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                                            <Typography key={index} style={{ color:  '#717171' }}>{t('checkout.currentBalance', { 'balance': (Math.floor(wallet_detail.balance * 100) / 100).toFixed(2) })}</Typography>
                                                                        </Grid>

                                                                    </>
                                                                    :
                                                                    <></>
                                                                    
                                                                )
                                                                
                                                            })
                                                            :
                                                            null

                                                    }
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>

                                    </>
                                    : null}
                            </Grid>
                            :
                            null
                    }
                </Grid>
                <Grid item xs={12} md={12} style={{ margin: '0 auto' }}>
                    <Button variant='contained' onClick={handleOnClose} style={{ backgroundColor: theme.palette.primary.main, width: '100%', boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.25)', marginTop:40 }} >
                        <Typography className={styles.confirmText}>Confirm</Typography>
                    </Button>
                </Grid>


            </Grid>

        </MemberButtonDrawer >
    )
};


const useStyles = makeStyles(theme => ({
    title: {
        color: '#2E523C',
        fontSize: '18px',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    confirmText: {
        color: '#F4F4F4',
        fontSize: '18px',
        fontWeight: 700,
    }

}));

export default PaymentMethod