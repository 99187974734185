import React, { memo, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, Box, Link, List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Category() {
    const [mainCategories, setMainCategories] = useState([]);
    const [selectedMainCategory, setSelectedMainCategory] = useState();

    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;

        getUrl('categories?level=1').then(response => {
            if (isMountedRef.current) {
                setMainCategories(response.data);
                if(_.size(response.data) > 0) {
                    setSelectedMainCategory(response.data[0].id);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
    }, []);

    return (
        <div style={{ backgroundColor:'#efefef' }}>
            <CategoryHeader />
            <Box display="flex" flexDirection="row" borderTop={1} style={{ borderColor: 'rgba(0, 0, 0, 0.12)' }}>
                <Box flex={1} borderRight={1} style={{ borderColor: 'rgba(0, 0, 0, 0.12)' }}>
                    <MainCategory selectedMainCategory={selectedMainCategory} setSelectedMainCategory={setSelectedMainCategory} data={mainCategories} />
                </Box>
                <Box flex={3} style={{ backgroundColor: '#FFF' }} paddingX={1}>
                    <SubCategory selectedMainCategory={selectedMainCategory} />
                </Box>
            </Box>
        </div>
    )
};

const CategoryHeader = memo(() => {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="row" paddingTop={1}>
            <Box flex={1}>
                <ListItem>
                    <ListItemText primary={ t('title.categories') } />
                </ListItem>
            </Box>
        </Box>
    )
})

const MainCategory = memo((props) => {
    const { selectedMainCategory, setSelectedMainCategory, data } = props;

    const { i18n } = useTranslation();
    const styles = useStyles();

    return (
        <List component="nav" disablePadding style={{ maxHeight: 600, overflow: 'auto' }}>
            { _.map(data, item => (
                <ListItem
                    key={item.id}
                    button
                    selected={selectedMainCategory === item.id}
                    onClick={() => setSelectedMainCategory(item.id)}
                    divider
                    classes={{ selected: styles.listItemSelected }}
                >
                    <ListItemText 
                        primary={ item[`name_${i18n.language}`] } 
                        primaryTypographyProps={{ 
                            style: { 
                                textAlign: 'center', 
                                fontWeight: selectedMainCategory === item.id ? 'bold' : 'normal',
                                color: selectedMainCategory === item.id ? '#000' : 'gray',
                            } 
                        }} 
                    />
                </ListItem>
            )) }
        </List>
    )
})

const SubCategory = memo((props) => {
    const { selectedMainCategory } = props;

    const [categories, setCategories] = useState();
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);
    const [loading, setLoading] = useState();
    const [selectedMainCategoryName, setSelectedMainCategoryName] = useState("");

    const isMountedRef = useRef();
    const { addAlert } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl('categories?all=1').then(response => {
            if (isMountedRef.current && response) {
                setCategories(response.data);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
    }, []);

    useEffect(() => {
        let subCategory = [];
        if(selectedMainCategory) {
            const selectedMain = _.find(categories, { id: selectedMainCategory });
            if(selectedMain) {
                subCategory = selectedMain.children;
                setSelectedMainCategoryName(selectedMain[`name_${i18n.language}`])
            }
        }
        setSelectedSubCategory(subCategory);
    }, [selectedMainCategory, categories, i18n.language]);

    return (
        <Box display="flex" flexDirection="column" maxHeight={600} overflow="auto">
            { loading ?
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
                :
                <>
                    { _.map(selectedSubCategory, secondCategory => (
                        <SubCategoryPanel key={secondCategory.id} data={secondCategory} selectedMainCategory={selectedMainCategory} selectedMainCategoryName={selectedMainCategoryName} />
                    )) }
                    <Box display="flex" alignItems="center" justifyContent="center" flex={1} padding={8}>
                        <Link underline='none' color="primary" to={`/category/${selectedMainCategory}`} component={RouterLink}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={120} margin={2} padding={2} border={1}>
                                <span>{ t('home.showAll', { 'categoryName': selectedMainCategoryName }) }</span>
                            </Box>
                        </Link>
                    </Box>
                </>
            }
        </Box>
    )
})

const SubCategoryPanel = memo((props) => {
    const { data, selectedMainCategory, selectedMainCategoryName } = props;

    const { i18n } = useTranslation();
    const { t } = useTranslation();

    return (
        <List>
            <ListItem>
                <Link underline='none' color="primary" to={`/category/${data.id}`} component={RouterLink}>
                    <ListItemText 
                        primary={data[`name_${i18n.language}`]} 
                        primaryTypographyProps={{ style: { color: 'gray' } }} 
                    />
                </Link>
            </ListItem>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
                { _.size(data.children) > 0 ?
                    <>
                    { _.map(data.children, thirdCategory => (
                        <Box key={thirdCategory.id} display="flex" flexDirection="row" flexWrap="wrap">
                            <SubCategoryItem data={thirdCategory} />
                            { _.size(thirdCategory.children) > 0 ?
                                _.map(thirdCategory.children, fourthCategory => (
                                    <SubCategoryItem key={fourthCategory.id} data={fourthCategory} />
                                ))
                            : null }
                        </Box>
                    )) }
                    <Link underline='none' color="primary" to={`/category/${selectedMainCategory}`} component={RouterLink}>
                        <Box display="flex" alignItems="center" justifyContent="center" width={120} margin={2} padding={2} border={1}>
                            <span>{ t('home.showAll', { 'categoryName': selectedMainCategoryName }) }</span>
                        </Box>
                    </Link>
                    </>
                : null }
            </Box>
        </List>
    )
})

const SubCategoryItem = memo((props) => {
    const { data } = props;

    const { i18n } = useTranslation();

    return (
        <Link underline='none' color="primary" to={`/category/${data.id}`} component={RouterLink}>
            <Box display="flex" alignItems="center" justifyContent="center" width={120} margin={2} padding={2} border={1}>
                <span>{ data[`name_${i18n.language}`] }</span>
            </Box>
        </Link>
    )
})

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    Tooltip:{
        fontSize: 21
    },
    listItemSelected: {
        backgroundColor: 'white !important'
    }
}));