import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Grid, Typography, Link, Box, Divider, List, ListItem, Chip, Table, TableBody, TableCell, TableRow, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import StarsIcon from '@material-ui/icons/Stars';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import _ from 'lodash';

import { getUrl, putUrl, postUrl } from '../helper/ApiAction'; 
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Rating } from '@material-ui/lab';


export default function OrderDetail(props) {
    const { t, i18n, oid } = props;
    const styles = useStyles();
    const { walletList:wallets, username } = useSelector(state => ({
        walletList: state.wallet.walletList,
        username: state.user.username,
    }));

    const [order, setOrder] = useState([]);
    const isMountedRef = useRef(null);
    const [currencyData, setCurrencyData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [trackingData, setTrackingData] = useState([]);
    const [orderDetailsNoTracking, setOrderDetailsNoTracking] = useState([]);
    const [orderDetailsCancel, setOrderDetailsCancel] = useState([]);
    const [trackingDetailsOpen, setTrackingDetailsOpen] = useState([]);
    const [showRating, setShowRating] = useState(false);
    // const [wallets, setWallets] = useState([]);
    const [shippingWalletId, setShippingWalletId] = useState([]);
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order_no: '',
        remark: ''
    });
    let history = useHistory();

    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();

    const {currency,currencyRate,currencydisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencydisplay,(currency_detail,index)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
        
    });

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`logistic/update_tracking_payment_parcel_status/${oid}`).then(response => {
            // no need show update error for member
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
        getUrl(`orders/${oid}`)
        .then(orderList => {
            if(isMountedRef.current) {
                if(orderList.status === 1 && _.size(orderList.data) > 0) {
                    setCurrencyData(orderList.currency_data);
                    setOrder(orderList.data);
                    setTrackingData(orderList.order_tracking);
                    setOrderDetailsNoTracking(orderList.order_details_no_tracking);
                    setOrderDetailsCancel(orderList.order_details_cancelled);
                    let totalPrice = 0;
                    // if(!_.isUndefined(orderList.data) && !_.isNull(orderList.data)) {
                        _.map(orderList.data.order_details, order => {
                            totalPrice += parseFloat(order.price);
                        });
                        setOrder(order => ({ ...order, order_total: totalPrice }));
                    // }
                    getUrl('shipping_fee/wallet').then(response => {
                        if(response.status){
                            if(_.size(response.data) > 0){
                                setShippingWalletId(response.data.id);
                            }else{
                                setShippingWalletId('cash');
                            }
                        }else{
                            addAlert(JSON.stringify(response.data));
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                } else {
                    addAlert(t('order.invalidOrder'));
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        // getUrl('wallets').then(response => {
        //     if (isMountedRef.current && response.status === 1) {
        //         setWallets(response.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [oid, addAlert, refreshData]);

    const handleTrackingDetailsClick = (trackingId) => {
        setTrackingDetailsOpen({ ...trackingDetailsOpen, [trackingId]: !trackingDetailsOpen[trackingId]});
    }

    const confirmCompleted = (orderNo) => {
        setLoading(true);
        putUrl(`orders/${orderNo}`)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert(t('order.confirmCompletedSuccess'), 'success');
                setRefreshData(!refreshData);
            }else{
                addAlert(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const handleCancelOrderOpen = (orderNo) => {
        setCancelOrderDialog({
            open: true,
            order_no: orderNo
        });
    }

    const handleCancelOrderClose = () => {
        setCancelOrderDialog({
            open: false,
            order_no: '',
            remark: ''
        });
    }

    const chatWithMerchant = merchantId => {
        if (merchantId) {
            postUrl(`chats`, {
                'merchant_id': merchantId,
            }).then(response => {
                if (response.status === 1) {
                    history.push('/chat');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const chatWithAdmin = () => {
        postUrl('adminchats').then(response => {
            // console.log("adminchats",response);
            if (response.status) {
                history.push(`/customerservice`);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    // const orderRefund = () => {
    //     history.push("/account/refund/" + oid);
    // }

    const cancelOrder = () => {
        setLoading(true);
        setCancelOrderDialog({ ...cancelOrderDialog, open: false });
        const updateData = {
            remark: cancelOrderDialog.remark
        };
        postUrl(`orders/${cancelOrderDialog.order_no}/cancel_order`, updateData)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert(t('order.cancelSuccess'), 'success');
                setRefreshData(!refreshData);
                handleCancelOrderClose();
            }else{
                setCancelOrderDialog({ ...cancelOrderDialog, open: true });
                addAlert(response.data);
            }
        }).catch(error => {
            setLoading(false);
            setCancelOrderDialog({ ...cancelOrderDialog, open: true });
            addAlert(JSON.stringify(error.message));
        });
    }

    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if(paymentIndex === 'multi_wallet'){
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if(multiPricing){
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
            }else{
                prefix = "Other wallets";
            }
        }else
        if(paymentIndex !== 'cash' && !isNaN(paymentIndex)){
            _.map(wallets, wallet => {
                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                    let name = _.split(wallet.name, '|');
                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                    // decimal = wallet.decimal;
                }
            })
        }else if(paymentIndex === 'cash'){
            paymentItem = paymentItem*currencyRate[`${currency}`]
            prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
        }else{
            prefix = paymentIndex;
        }
        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix+' ' : ''} />
        )
    }

    let totalPrice = {};
    let totalShipping = {};
    let subtotalPrice = {};
    let rateDone = false;

    const orderDetailsDisplay = (orderItem) => {
        if((order.status === 60 || orderItem.status) && _.size(orderItem.payment) > 0) {
            _.map(orderItem.payment, (paymentItem, paymentIndex) => {
                if(paymentIndex ==="multi_wallet"){
                    let multiP = JSON.parse(orderItem.multi_pricing);
                    let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                    paymentIndex = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                }
                if(_.isUndefined(totalPrice[paymentIndex])) {
                    totalPrice[paymentIndex] = 0;
                }
                if(_.isUndefined(subtotalPrice[paymentIndex])) {
                    subtotalPrice[paymentIndex] = 0;
                }
                totalPrice[paymentIndex] += parseFloat(paymentItem);
                subtotalPrice[paymentIndex] += parseFloat(paymentItem);
            })
        }
        return (
            <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                <Box>
                    <Link underline='none' color="inherit" to={`/item/${orderItem.product_id}`} component={RouterLink}>
                        <WebpImg style={{ height: 100, width: 100, objectFit:'cover' }} alt="cart product" src={orderItem.image} />
                    </Link>
                </Box>
                <Box style={{width:'100%', paddingLeft:15}}>
                    <Link underline='none' color="inherit" to={`/item/${orderItem.product_id}`} component={RouterLink}>
                        <Typography variant="caption">{orderItem.title_display}</Typography>
                    </Link>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div>
                            {
                                _.map(orderItem.product_attribute_display, (variantItem, variantIndex) => {
                                    if (_.includes(['color', 'size'], variantIndex)) {
                                        return (
                                            <Chip key={variantItem} size="small" style={{ margin: 5 }} label={<Typography variant="caption" style={{ fontSize: 10 }}>{variantItem}</Typography>} variant="outlined" color="secondary" />
                                        )
                                    }
                                })
                            }
                        </div>
                        <Typography style={{ paddingLeft: 10 }} variant="caption">x{orderItem.quantity}</Typography>

                    </Box>

                    
                    <Box >
                    {
                        _.size(orderItem.payment) > 0 ?
                            _.map(orderItem.payment, (paymentItem, paymentIndex) => {
                                if (parseFloat(paymentItem) > 0) {
                                    return (
                                        <Box key={paymentIndex} style={{textAlign:'right'}}>
                                            <Typography variant="caption" className={styles.priceStyle}>
                                                {priceDisplay(paymentItem, paymentIndex, orderItem)}
                                            </Typography>
                                        </Box>
                                    );
                                }
                            })
                            :
                            <Typography variant="caption">
                                <NumberFormat className={styles.priceStyle} value={orderItem.price*currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                            </Typography>
                    }
                    {/* <Typography variant="caption">
                        <NumberFormat className={ styles.priceStyle } value={ orderItem.price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                    </Typography> */}
                    {
                        orderItem.status
                            ?
                            null
                            :
                            <Typography variant="caption" style={{ padding: "5px", borderRadius: "10px", border: "1px solid grey", color: "grey" }}>
                                {t('order.productCancelled')}
                            </Typography>
                    }
                </Box>
                <Box style={{paddingTop:15}}>
                        {
                            showRating ?
                                _.size(orderItem.product_review) > 0 ?
                                    <Box>
                                        <Box display="flex" flexDirection="row" style={{paddingBottom:5}}>
                                            <Rating
                                                defaultValue={orderItem.product_review.rating}
                                                precision={1}
                                                readOnly
                                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                            />
                                        </Box>
                                        {
                                            _.size(orderItem.product_review.comment) > 0 ?
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="caption"><b>{`${t('order.review')}: `}</b> {orderItem.product_review.comment}</Typography>
                                                </Box>
                                                : null
                                        }
                                    </Box>
                                    : null
                                : null
                        }
                    </Box>
                </Box>
               
            </Box>
        )
    }

    return (
        <Grid container justify="center"  style={{ minHeight: 450, marginTop:'-7px' }}>
            {
                    order ? 
                    <Box style={{padding:0, width:'100%'}}>
                        {
                            _.size(order.order_shippings) > 0 ?
                                _.map(order.order_shippings, shippingValue => {
                                    let countShipping = false;
                                    if(order.status === 60){
                                        countShipping = true;
                                    }else{
                                        _.map(shippingValue.order_shipping_details, shipingDetail => {
                                            _.map(order.order_details, orderDetails => {
                                                if(orderDetails.id === shipingDetail.order_detail_id){
                                                    if(orderDetails.status){
                                                        countShipping = true; 
                                                    }
                                                }
                                            })
                                        })
                                    }
                                    if(countShipping){
                                        if(shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                            let wid = shippingWalletId;
                                            if(shippingValue.wallet_id > 0){
                                                wid = shippingValue.wallet_id;
                                            }
                                            if(_.isUndefined(totalPrice[wid])) {
                                                totalPrice[wid] = 0;
                                            }
                                            if(_.isUndefined(totalShipping[wid])) {
                                                totalShipping[wid] = 0;
                                            }
                                            totalPrice[wid] += parseFloat(shippingValue.fee);
                                            totalShipping[wid] += parseFloat(shippingValue.fee);
                                        }
                                    }
                                })
                            : null
                        }
                        <Box style={{ backgroundColor: theme.palette.primary.main, padding:'30px 5% 80px', }}>
                            {/* <Grid item xs={12} sm={6}>
                                    <Link underline='none' color="inherit" to={history.back()} component={RouterLink}>
                                        <Box display="flex" alignItems="center" onClick={ () => history.goBack() } component={Button}>
                                            <KeyboardArrowLeftIcon fontSize="default" style={{ padding: 5 }} />
                                            <Typography variant="caption">
                                            { t('button.back') }
                                            </Typography>
                                        </Box>
                                    </Link>
                                </Grid> */}
                            <Box >
                                <Typography style={{ color: '#fff', fontSize: 25 }}>{`${order.status_display ? order.status_display : '-'}`}</Typography>
                                <Typography style={{ color: '#fff', fontSize: 15 }}>
                                    {t('order.id')}: {order.order_no}
                                </Typography>
                                {order.recipient_username && order.recipient_username !== username ?
                                    <Typography style={{ color: '#fff', fontSize: 15 }}>
                                        {t('order.bvBeneficiary')}: {order.recipient_username}
                                    </Typography>
                                    :null
                                }
                            </Box>
                        </Box>
                       
                        {/* <Divider />
                        <ListItem className={ styles.listPaddingVertical }>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="secondary">{ t('order.details') }</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">{ `${t('order.status')}: ${order.status_display ? order.status_display : '-'}` }</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider /> */}
                        <Box className={styles.whiteBox} style={{marginTop: '-56px'}}>
                            <Typography variant="h6" style={{ fontWeight:'bold', paddingBottom:10}}>{t('order.deliveryAddress')}</Typography>
                            
                            <Box style={{ color: theme.palette.gray.text, }}>
                                <Typography >{order.recipient}</Typography>
                                <Typography >{order.mobile}</Typography>
                                <Typography style={{ paddingTop: 10 }}>{order.full_address ? order.full_address : ''}</Typography>
                            </Box>
                            {/* <Typography variant="caption">{ order.address ? (`${order.address}, ${order.state}, ${order.city}, ${order.zip}, ${order.country}`) : '' }</Typography> */}
                        </Box>

                        {
                            _.map(order.order_details,(outletItem,outletIndex)=>{
                                let showOutlet=false;
                                if(_.size(outletItem.outlet)>0){
                                    showOutlet=true
                                }
                                return (
                                    <>
                                        {
                                            showOutlet ?
                                                <Box className={styles.whiteBox} key={outletIndex}>
                                                    <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 10 }}>{t('order.outletDetail')}</Typography>

                                                    <Box style={{ color: theme.palette.gray.text, }}>
                                                        <Typography >{outletItem.outlet.pic_name}</Typography>
                                                        <Typography >{outletItem.outlet.pic_mobile}</Typography>
                                                        <Typography variant="subtitle2"  style={{ paddingTop: 10 }}>{outletItem.outlet.outlet_name}</Typography>
                                                        <Typography >{outletItem.outlet.address}</Typography>
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                    </>
                                )
                            })
                        }

                        {
                                trackingData ?
                                _.map(trackingData, (trackingDetails) => {
                                    return(
                                        <Box key={trackingDetails.tracking_data.id}>
                                            <ListItem className={ styles.listPaddingVertical }>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" color="secondary">{t('order.trackingNo')}: { trackingDetails.tracking_data.tracking_no }</Typography>
                                                    </Grid>
                                                    {
                                                        trackingDetails.tracking_data.logistic_parcel_status ?
                                                            <Grid item xs={12}>
                                                                <List
                                                                    component="nav"
                                                                    disablePadding
                                                                >
                                                                    <ListItem disableGutters button onClick={() => handleTrackingDetailsClick(trackingDetails.tracking_data.id)}>
                                                                        {/* <ListItemText primary={t('order.trackingStatus') + ': ' + trackingDetails.logistic_parcel_status} /> */}
                                                                        <Typography variant="caption">{ t('order.trackingStatus') + ': ' + trackingDetails.tracking_data.logistic_parcel_status }</Typography>
                                                                        {trackingDetailsOpen[trackingDetails.tracking_data.id] ? <ExpandLess /> : <ExpandMore />}
                                                                    </ListItem>
                                                                    <Collapse in={trackingDetailsOpen[trackingDetails.tracking_data.id]} timeout="auto" unmountOnExit>
                                                                        <List component="div" disablePadding>
                                                                            {Object.values(trackingDetails.tracking_data.tracking_details).map((row, key) => {
                                                                                return (
                                                                                    <ListItem key={key}>
                                                                                        <ListItemIcon>
                                                                                            <LocalShippingIcon fontSize="small" />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText primary={row.status} secondary={row.event_date + ' ' + row.event_time} />
                                                                                    </ListItem>
                                                                                )
                                                                            })}
                                                                        </List>
                                                                    </Collapse>
                                                                </List>
                                                            </Grid>
                                                        :
                                                        (
                                                            trackingDetails.tracking_data.status_display ?
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption">{ `${t('order.trackingStatus')}: ${trackingDetails.tracking_data.status_display}` }</Typography>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption">{ `${t('order.trackingStatus')}: -` }</Typography>
                                                            </Grid>
                                                        )
                                                    }
                                                    <Grid item xs={12}>
                                                    {
                                                        _.map(trackingDetails.data_list, (tdetails) => {
                                                            return(
                                                                <Box key={tdetails.id} style={{margin: 10}}>
                                                                    { orderDetailsDisplay(tdetails) }
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </Box >
                                    )
                                })
                                :
                                null
                            }

                        {/* <Box className={styles.whiteBox}>
                            {
                                orderDetailsNoTracking ?
                                <Grid container>
                                    <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 10 }}>{t('order.processingItem')}</Typography>
                                    <Grid item xs={12}>
                                        {
                                            _.map(orderDetailsNoTracking, (orderItem) => {
                                                return (
                                                    <Box key={orderItem.id}>
                                                        {orderDetailsDisplay(orderItem)}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                            {
                                orderDetailsCancel ?
                                <Box>
                                    <ListItem>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" color="secondary">{t('order.cancelItem')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                            {
                                                _.map(orderDetailsCancel, (orderItem) => {
                                                    return(
                                                        <Box key={orderItem.id} style={{margin: 10}}>
                                                            { orderDetailsDisplay(orderItem) }
                                                        </Box>
                                                    )
                                                })
                                            }
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </Box>
                                :
                                null
                            }

                            <Box>
                                {
                                    _.map(order.order_details, (orderItem) => {
                                        if (_.size(orderItem.product_review) > 0) {
                                            rateDone = true;
                                        }
                                    })
                                }
                                {
                                    rateDone ?
                                        <Button variant="text" color="secondary" variant="outlined" style={{width:'100%', marginTop:5}} startIcon={<StarsIcon fontSize="inherit" />} onClick={() => setShowRating(!showRating)}>
                                            <Typography style={{ fontSize: 12 }}>{showRating ? t('order.hideRating') : t('order.showRating')}</Typography>
                                        </Button> : null
                                }
                            </Box>
                        </Box> */}

                        {
                           orderDetailsNoTracking || orderDetailsCancel || rateDone ?
                                <Box className={styles.whiteBox}>
                                    {
                                        orderDetailsNoTracking ?
                                            <Grid container>
                                                <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 10 }}>{t('order.processingItem')}</Typography>
                                                <Grid item xs={12}>
                                                    {
                                                        _.map(orderDetailsNoTracking, (orderItem) => {
                                                            return (
                                                                <Box key={orderItem.id}>
                                                                    {orderDetailsDisplay(orderItem)}
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                            :
                                            null
                                    }
                                    {
                                        _.size(orderDetailsCancel) ?
                                            <Box>
                                                <ListItem>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" color="secondary">{t('order.cancelItem')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {
                                                                _.map(orderDetailsCancel, (orderItem) => {
                                                                    return (
                                                                        <Box key={orderItem.id} style={{ margin: 10 }}>
                                                                            {orderDetailsDisplay(orderItem)}
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </Box>
                                            :
                                            null
                                    }

                                    <Box>
                                        {
                                            _.map(order.order_details, (orderItem) => {
                                                if (_.size(orderItem.product_review) > 0) {
                                                    rateDone = true;
                                                }
                                            })
                                        }
                                        {
                                            rateDone ?
                                                <Button  color="secondary" variant="outlined" style={{ width: '100%', marginTop: 5 }} startIcon={<StarsIcon fontSize="inherit" />} onClick={() => setShowRating(!showRating)}>
                                                    <Typography style={{ fontSize: 12 }}>{showRating ? t('order.hideRating') : t('order.showRating')}</Typography>
                                                </Button> : null
                                        }
                                    </Box>
                                </Box>
                                : null  
                        }

                        
                       


                        <Box className={styles.whiteBox}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 10 }}>{t('checkout.remark')}</Typography>
                            <Typography variant="caption">{order.remark ? order.remark : '-'}</Typography>
                        </Box>

                        <Table className={styles.table} aria-label="simple table">
                                <TableBody>
                                    <TableRow >
                                        <TableCell align="right" style={{ width: '70%'}}>{ t('order.subtotal') }</TableCell>
                                        <TableCell align="right">
                                            {
                                                _.size(totalPrice) ? 
                                                    _.map(subtotalPrice, (priceItem, priceIndex) => {
                                                        if(parseFloat(priceItem) > 0){
                                                            return (
                                                                <Box key={priceIndex} className={styles.subTotalStyle}>
                                                                    {priceDisplay(priceItem, priceIndex)}
                                                                </Box>
                                                            )
                                                        }
                                                    })
                                                : null
                                            }
                                            {/* <Typography variant="caption">
                                                <NumberFormat className={ styles.subTotalStyle } value={ order.order_total } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                                            </Typography>     */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" style={{ width: '70%'}}>{ t('order.shippingFee') }</TableCell>
                                        <TableCell align="right">
                                            <Typography variant="caption">
                                                {/* {
                                                    _.size(order.order_shippings) > 0 ?
                                                    _.map(order.order_shippings, (shippingValue, shippingIndex) => {
                                                        return (
                                                            <NumberFormat key={shippingIndex} className={ styles.subTotalStyle } value={ shippingValue.fee } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                                        )
                                                    })
                                                    :
                                                    <NumberFormat className={ styles.subTotalStyle } value={ `0.00` } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                                } */}
                                                {
                                                    _.size(totalShipping) ? 
                                                        _.map(totalShipping, (shippingValue, shippingIndex) => {
                                                            return (
                                                                <Box key={shippingIndex} className={styles.subTotalStyle}>
                                                                    {priceDisplay(shippingValue, shippingIndex)}
                                                                </Box>
                                                            )
                                                        })
                                                    :
                                                    <Box className={styles.subTotalStyle}>
                                                        {t('order.freeShipping')}
                                                    </Box>
                                                }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" style={{ width: '70%'}}>{ t('order.orderTotal') }</TableCell>
                                        <TableCell align="right">
                                            {
                                                _.size(totalPrice) ? 
                                                    _.map(totalPrice, (priceItem, priceIndex) => {
                                                        if(parseFloat(priceItem) > 0){
                                                            return (
                                                                <Box key={priceIndex} className={styles.totalStyle}>
                                                                    {priceDisplay(priceItem, priceIndex)}
                                                                </Box>
                                                            )
                                                        }
                                                    })
                                                : null
                                            }
                                            {/* <Typography variant="caption">
                                                <NumberFormat className={ styles.totalStyle } value={ parseFloat(order.order_total) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                                            </Typography> */}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        <Box style={{ textAlign: 'end', paddingTop:20 }}>
                            {/* <Button size="large" variant="contained" color="secondary" className={styles.buttonStyle} style={{ marginRight: 20 }} onClick={() => chatWithAdmin()} >
                                <Typography variant="caption">
                                    {t('order.contactAdmin')}
                                </Typography>
                            </Button>
                            <Button size="large" variant="contained" color="secondary" className={styles.buttonStyle} style={{ marginRight: 20 }} onClick={() => chatWithMerchant(order.merchant_company.id)} >
                                <Typography variant="caption">
                                    {t('order.contactSeller')}
                                </Typography>
                            </Button> */}
                        {
                            (order.status === 35 || order.status === 40)
                            ?
                            // <Grid item xs={12} style={{ textAlign: 'end' }}>
                                <Button size="large" variant="contained" color="secondary" className={ styles.buttonStyle } style={{marginRight: 20}} onClick={ () => confirmCompleted(order.order_no)}>
                                    <Typography variant="caption">
                                        { t('order.confirmCompleted') }
                                    </Typography>
                                </Button>
                            // </Grid>
                            :
                            null
                        }
                        {/* {
                            (order.status === 35 || order.status === 40)
                            ?
                            // <Grid item xs={12} style={{ textAlign: 'end' }}>
                            <Button size="large" variant="contained" color="secondary" className={ styles.buttonStyle } style={{marginRight: 20}} onClick={ () => orderRefund() }>
                                <Typography variant="caption">
                                    { t('order.requestRefund') }
                                </Typography>
                            </Button>
                            // </Grid>
                            :
                            null
                        } */}
                        {
                            order.status === 20
                            ?
                            // <Grid item xs={12} style={{ textAlign: 'end' }}>
                                <Button size="large" variant="contained" color="secondary" style={{marginRight: "20px"}} className={ styles.buttonStyle } onClick={ () => handleCancelOrderOpen(order.order_no) }>
                                    <Typography variant="caption">
                                        { t('order.cancelOrder') }
                                    </Typography>
                                </Button>
                            // </Grid>
                            :
                            null
                        }
                        </Box>
                    </Box>
                    : null
                }


            {/* <Grid item xs={12}>
                {
                    order && _.size(order.refund) > 0
                    ?
                    <Grid container style={{padding: 20, backgroundColor: '#fff'}}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">{t('refund.refund')}</Typography>
                        </Grid>
                        {
                            _.map(order.refund, refundDetail => {
                                let returnItem = '';
                                {
                                    _.map(refundDetail.order_detail_array, od => {
                                        _.map(order.order_details, details => {
                                            if(parseInt(details.id) === parseInt(od)){
                                                returnItem += returnItem?(' , ' + details.title_display):details.title_display;
                                            }
                                        })
                                    })
                                }
                                return (
                                    <Box key={refundDetail.id}>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.applyDate')}:</Typography>
                                            <Typography>{refundDetail.created_at}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.applyStatus')}:</Typography>
                                            <Typography>{refundDetail.status_display}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.reason')}:</Typography>
                                            <Typography>{refundDetail.reason}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.remark')}:</Typography>
                                            <Typography>{refundDetail.remark?refundDetail.remark:'-'}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.items')}:</Typography>
                                            <Typography>{returnItem}</Typography>
                                        </Grid>
                                    </Box>
                                )
                            })
                        }
                    </Grid>
                    :
                    null
                }
            </Grid> */}
            <Dialog open={ cancelOrderDialog.open } onClose={ handleCancelOrderClose } >
                <DialogTitle>
                    { t('order.cancelOrder') }
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center" >
                        <Grid item xs={12}>
                            { t('order.cancelOrderReminder') }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('order.cancelOrderRemark')}
                                multiline
                                value={ cancelOrderDialog.remark }
                                onChange={ (event) => { setCancelOrderDialog({ ...cancelOrderDialog , remark: event.target.value }) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" className={ styles.buttonStyle } onClick={ () => cancelOrder()} >
                        <Typography variant="overline">{ t('order.confirmCancelOrder') }</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding:0
    },
    listPaddingVertical: {
        paddingTop: 20, 
        paddingBottom: 20
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    icon: {
        color: `${theme.palette.green.main} !important `,
    },
    whiteText: {
        fill: '#fff'
    },
    small: {
        borderRadius: 30,
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginLeft: 5,
        marginRight: 5,
    },
    user: {
        padding: 5, 
        backgroundColor: theme.palette.secondary.main, 
        color:"#fff"
    },
    priceStyle: {
        fontSize: 14,
        color: theme.palette.secondary.main,
    },
    subTotalStyle: {
        fontSize: 16,
    },
    totalStyle: {
        fontSize: 18,
        color: theme.palette.secondary.main,
        fontWeight: 800
    },
    table: {
        border: 0
    },
    whiteBox: {
        borderRadius:10,
        backgroundColor: theme.palette.white.main,
        padding:15,
        width: '90%',
        margin: '0 auto',
        boxShadow: '0 0 12px 0 #0000001f',
        marginBottom: 20
    }
}));