import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeProfile } from '../actions';


import { Link as RouterLink, useHistory } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText, Input } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { FiChevronLeft } from 'react-icons/fi';

import ImgHeader from './Layouts/ImgHeader';
import './css/styles.css';

export default function ProfileBankInfo() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const { bank_account, bank_holder_name, bank_name, bank_swift, nric} = useSelector(state => state.user);
    const [profileFormState, setProfileFormState] = useState({ bank_account, bank_holder_name, bank_name, bank_swift, nric });
    const [errorFormState, setErrorFormState] = useState({
        bank_account: '',bank_info: '', bank_swift: '', bank_holder_name: '', nric: ''
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    const [disabledState, setDisabledState] = useState({
        bank_holder_name: false
    });

    const {  setLoading } = useNotificationLoading();

    useEffect(() => {
        if(bank_holder_name){
            setDisabledState({ ...disabledState, bank_holder_name: true });
        }
        // eslint-disable-next-line
    }, [bank_holder_name]);

    const handleInfoChange = (event) => {
        const name = event.target.name;
        setProfileFormState({ ...profileFormState, [name]: event.target.value });
    }

    const updateInfo = () => {
        setLoading(true);
        putUrl('user/bank', profileFormState).then(result => {
            if(result.error) {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                let allErrorField = [];
                _.map(result.error, (errorItem, errorField) => {
                    allErrorField[errorField] = errorItem[0];
                })
                setErrorFormState(allErrorField);
            } else {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: false, message: result.message }) );
                dispatch(storeProfile(result.data));
                setErrorFormState({});
            }
        })
    }

    return (
        <Box >
            <ImgHeader cardDisplay  title={t('profile.bankInfo')}/>
            <div className='p-lr-20' style={{ marginTop: -60 }}>
                <div className='pos-relative flex-c-m'>
                    <div style={{ width: '100%', height: 170, borderRadius: 15, background: 'linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%)', transform: 'rotate(355deg)' }} />
                    <div className='pos-absolute card-transparent p-all-20 flex-sb flex-col card-text txt-upper'>
                    <div className='flex-sb-m w-full'>
                                <p>{profileFormState.bank_name ? profileFormState.bank_name: t('profile.bankName')}</p>
                                <p>{profileFormState.bank_swift ? profileFormState.bank_swift: t('profile.bankSwift')}</p>
                            </div>
                            <div className='p-t-40'>
                                <p className='fs-21'><b>
                                {profileFormState.bank_account ? profileFormState.bank_account: "0000 0000 0000"}
                                </b></p>
                                <p>{profileFormState.bank_holder_name ? profileFormState.bank_holder_name: t('profile.bankHolderName')}</p>
                            </div>
                    </div>
                </div>
            </div>
            
            <div className='pos-relative'>
                <div className='p-lr-25 p-t-50'>
                    <div>
                        {/* <Typography className={styles.textFieldTitle}>{t('profile.bankName')}</Typography> */}
                        <TextField
                            placeholder={t('profile.bankName')}
                            error={errorFormState.bank_name ? true : false}
                            helperText={errorFormState.bank_name}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'bank_name' }}
                            onChange={handleInfoChange}
                            value={profileFormState.bank_name ?? ""}
                        />
                    </div>
                    <div style={{ paddingTop: 25 }}>
                        {/* <Typography className={styles.textFieldTitle}>{t('profile.bankHolderName')}</Typography> */}
                        <TextField
                            placeholder={t('profile.bankHolderName')}
                            error={errorFormState.bank_holder_name ? true : false}
                            helperText={errorFormState.bank_holder_name}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'bank_holder_name', readOnly: disabledState.bank_holder_name }}
                            onChange={handleInfoChange}
                            value={profileFormState.bank_holder_name ?? ""}
                        />
                    </div>
                    <div style={{ paddingTop: 25 }}>
                        {/* <Typography className={styles.textFieldTitle}>{t('profile.bankAccount')}</Typography> */}
                        <TextField
                            placeholder={t('profile.bankAccount')}
                            error={errorFormState.bank_account ? true : false}
                            helperText={errorFormState.bank_account}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'bank_account' }}
                            onChange={handleInfoChange}
                            value={profileFormState.bank_account ?? ""}
                        />
                    </div>
                    <div style={{ paddingTop: 25 }}>
                        {/* <Typography className={styles.textFieldTitle}>{t('profile.bankSwift')}</Typography> */}
                        <TextField
                            placeholder={t('profile.bankSwift')}
                            error={errorFormState.bank_swift ? true : false}
                            helperText={errorFormState.bank_swift}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'bank_swift' }}
                            onChange={handleInfoChange}
                            value={profileFormState.bank_swift ?? ""}
                        />
                    </div>
                    <div style={{ paddingTop: 25 }}>
                        {/* <Typography className={styles.textFieldTitle}>{t('profile.bankSwift')}</Typography> */}
                        <TextField
                            placeholder={t('profile.nric')}
                            error={errorFormState.nric ? true : false}
                            helperText={errorFormState.nric}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'nric' }}
                            onChange={handleInfoChange}
                            value={profileFormState.nric ?? ""}
                        />
                    </div>
                </div>
                <Box className='flex-c-m p-t-30 p-lr-25' style={{bottom:20, background:'#fff2', backdropFilter:'blur(5px)' }}>
                    <Button variant="contained" onClick={updateInfo} style={{ width: '100%', padding: '8px 9px', borderRadius: 48, background:theme.palette.primary.main, color:'#fff' }} >{t('button.update')}</Button>
                </Box>
                
                <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    textFieldTitle:{
        color:theme.palette.gray.text,
        fontSize:16,
        paddingBottom:5,
        paddingLeft:10
    }
}));
