import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getUrl, postUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Storefront } from '@material-ui/icons';

import WebpImg from './Layouts/WebpImg';

import ImgHeader from './Layouts/ImgHeader';


const REVENUEMONSTER_AVAILABLE = true;

export default function Payment() {
    const { i18n, t } = useTranslation();
    let { oid } = useParams();
    const { id, name, email, mobile } = useSelector(state => state.user);
    const [eoid, setEoid] = useState('')

    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let history = useHistory();

    const { addAlert, setLoading } = useNotificationLoading();
    const { walletList: wallets, username } = useSelector(state => ({
        walletList: state.wallet.walletList,
        username: state.user.username,
    }));
    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);


    const [order, setOrder] = useState([]);
    const [currencyData, setCurrencyData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);

    const [shippingWalletId, setShippingWalletId] = useState([]);

    let currency_code = [];
    let totalPrice = {};
    let totalShipping = {};
    let subtotalPrice = {};

    const [paymentInput, setPaymentInput] = useState({
        'MERCHANT_IDENTIFIER': "",
        'AMOUNT': "",
        'TXN_DESC': "",
        'CALLBACK_URL': "",
        'CUSTOMER_ID': id,
        'ORDER_ID': "",
        'INSTALLMENT': 0,
        'CUSTOMER_NAME': name,
        'CUSTOMER_EMAIL': email,
        'CUSTOMER_MOBILE': mobile,
        'TXN_SIGNATURE': "",
        'PAYMENT_METHOD': "",
        'SOURCE_FROM': "",
        'IS_TEST': "",
        'CURRENCY': "",
    });
    const [totalShippingPrice, setTotalShippingPrice] = useState([]);
    const [validBalance, setValidBalance] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState();
    const [totalPayment, setTotalPayment] = useState([]); // include shipping fee
    const [totalPrices, setTotalPrices] = useState([]);// product fee
    const [paymentMethod, setPaymentMethod] = useState('');// product fee

    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // ---------------------------------- API ---------------------------------------------
    useEffect(() => {
        isMountedRef.current = true;
        let purchase_no = '';
        if (oid) {
            getUrl(`payment/${oid}`)
                .then(orderList => {
                    // console.log("orderList", orderList);
                    if (isMountedRef.current) {
                        setLoading(false);
                        setCurrencyData(orderList.currency_data);
                        if (orderList.status === 1 && _.size(orderList.data) > 0) {
                            setPaymentInput(paymentInput => ({ ...paymentInput, CUSTOMER_NAME: orderList.data[0]['buyer_name'], CUSTOMER_EMAIL: orderList.data[0]['buyer_email'], CUSTOMER_MOBILE: orderList.data[0]['buyer_mobile'] }));
                            // setState({ customer_name: orderList.data[0]['buyer_name'] });
                            let txn_desc = 'Transaction for order ';
                            let allow_payment = true;
                            let tempPaymentMthd = ''
                            setOrder(orderList.data);
                            _.map(orderList.data, (orderItem, orderIndex) => { // loop purchases to get order
                                txn_desc += `${orderItem.order_no},`;
                                //check payment Method 
                                if (orderIndex !== 'recipient_username') {
                                    if (tempPaymentMthd == '') {
                                        tempPaymentMthd = orderItem.payment_method;
                                    } else if (tempPaymentMthd != orderItem.payment_method) {
                                        history.push('/order?type=new')
                                    }
                                }

                                // console.log("asd", orderItem);
                                if (orderItem.status === 20) {
                                    _.map(orderItem.order_shippings, shippingItem => {
                                        let countShipping = false;
                                        _.map(shippingItem.order_shipping_details, shippingDetails => {
                                            _.map(orderItem.order_details, detailItem => {
                                                if (shippingDetails.order_detail_id === detailItem.id) {
                                                    if (detailItem.status === 1) {
                                                        countShipping = true;
                                                    }
                                                }
                                            })
                                        })
                                        if (countShipping) {
                                            let walletType = 'cash';
                                            if (shippingItem.wallet_id > 0) {
                                                walletType = shippingItem.wallet_id;
                                            }
                                            if (_.isUndefined(totalShippingPrice[walletType])) {
                                                totalShippingPrice[walletType] = 0;
                                                setTotalShippingPrice(totalShippingPrice => ({ ...totalShippingPrice, [walletType]: 0 }));
                                            }
                                            setTotalShippingPrice(totalShippingPrice => ({ ...totalShippingPrice, [walletType]: totalShippingPrice[walletType] + parseFloat(shippingItem.fee) }));
                                            totalShippingPrice[walletType] += parseFloat(shippingItem.fee);
                                        }
                                    })
                                    // setTotalShippingPrice(totalShippingPrice => parseFloat(totalShippingPrice) + parseFloat(orderItem.shipping_fee));
                                }

                                // console.log("orderItem", orderItem);
                                _.map(orderItem.order_details, detailItem => { // loop order to get order details
                                    // console.log(detailItem)
                                    if (detailItem.status === 1) {
                                        _.map(detailItem.payment, (paymentItem, paymentIndex) => { // loop order details to get payment
                                            if (detailItem.error === false) {
                                                if (paymentIndex == "multi_wallet") {
                                                    let multiP = JSON.parse(detailItem.multi_pricing);
                                                    paymentIndex = multiP.multi_wallet_detail.pricing_name;
                                                }
                                                if (_.isUndefined(totalPrices[paymentIndex])) {
                                                    totalPrices[paymentIndex] = 0;
                                                    setTotalPrices(totalPrices => ({ ...totalPrices, [paymentIndex]: 0 }));

                                                }
                                                setTotalPrices(totalPrices => ({ ...totalPrices, [paymentIndex]: totalPrices[paymentIndex] + parseFloat(paymentItem) }));
                                                totalPrices[paymentIndex] += parseFloat(paymentItem);
                                            } else {
                                                allow_payment = false;
                                            }
                                        })
                                    }
                                })
                            });
                            if (totalPrices['cash']) {
                                setPaymentInput(paymentInput => ({ ...paymentInput, TXN_DESC: txn_desc }));
                            }
                            if (allow_payment)
                                setValidBalance(orderList.valid_balance);
                            if (orderList.valid_balance === false) {
                                setInvalidMsg(t('payment.invalidWalletBalance'));
                            }
                            setPaymentMethod(tempPaymentMthd);
                        } else {
                            history.push('/order')
                            // addAlert(orderList.message);
                        }
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [oid, addAlert, refreshData]);


    useEffect(() => {
        let tempWallet = {};
        _.map(totalPrices, (walletTotal, walletType) => {
            tempWallet[walletType] = walletTotal;
        });

        _.map(totalShippingPrice, (walletTotal, walletType) => {
            if (walletTotal > 0) {
                let decimal = 2;
                if (walletType !== 'cash') {
                    _.map(wallets, wallet => {
                        let name = _.split(wallet.name, '|');
                        let walletName = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                    })
                }
                if (_.isUndefined(tempWallet[walletType])) {
                    tempWallet[walletType] = 0;
                }
                tempWallet[walletType] += walletTotal;
            }
        });
        setTotalPayment(tempWallet);
    }, [totalPrices, totalShippingPrice, wallets]);

    // --------------------------------- FUNCTION ---------------------------------------------
    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if (paymentIndex === 'multi_wallet') {
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if (multiPricing) {
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];

            } else {
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                prefix = "Other wallets";
            }
        } else
            if (paymentIndex !== 'cash' && !isNaN(paymentIndex)) {
                _.map(wallets, wallet => {
                    if (parseInt(wallet.id) === parseInt(paymentIndex)) {
                        let name = _.split(wallet.name, '|');
                        prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                        // decimal = wallet.decimal;
                    }
                })
            } else if (paymentIndex === 'cash') {
                paymentItem = paymentItem * currencyRate[`${currency}`]
                prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
            } else {
                let name = _.split(paymentIndex, '|');
                prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
            }
        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix + ' ' : ''} />
        )
    }

    const requestPayment = async (type) => {
        const data = { payType: type, purchaseNo: oid };

        return postUrl(`payment`, data)
            .then(result => {
                if (result.status == 0 && result.error && result.error.validate_error) {
                    window.location.reload();
                } else {
                    return result.status;
                }

            }).catch(error => {
                addAlert(JSON.stringify(error.message));
                return false;
            });
    }

    const makePayment = async type => {
        setLoading(true);

        const paymentStatus = await requestPayment(type);

        if (paymentStatus) {
            if (_.includes(['revenuemonster'], type)) {
                getUrl(`${type}/generate/${oid}`)
                    .then(async response => {
                        if (response.status === 1) {
                            setLoading(false);
                            if (type === 'revenuemonster') {
                                const { code, item } = response.data;

                                if (code === 'SUCCESS' && item.url) {
                                    window.location.href = item.url;
                                } else {
                                    addAlert(response.message);
                                }
                            }
                        } else {
                            setLoading(false);
                            addAlert(response.message);
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
            }
        }
    }

    const walletPayment = () => {
        setLoading(true);
        let apiParams = {
            paymentMethod: paymentMethod
        }
        postUrl(`wallet/${oid}`, apiParams).then((response) => {
            setLoading(false);
            if (response.status === 1) {
                history.push(`/paymentresult/${oid}?source=wallet`);
            } else {
                addAlert(response.message);
            }
        })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const confirmCompleted = (orderNo) => {
        setLoading(true);
        putUrl(`orders/${orderNo}`)
            .then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert(t('order.confirmCompletedSuccess'), 'success');
                    setRefreshData(!refreshData);
                    history.push(`/order?type=completed`);
                } else {
                    addAlert(response.data);
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    // -------------------------------- PRICE CALCULATION ---------------------------------------------------
    _.map(order?.order_details, (orderItem) => {
        if ((order?.status === 60 || orderItem.status) && _.size(orderItem.payment) > 0) {
            _.map(orderItem.payment, (paymentItem, paymentIndex) => {
                if (paymentIndex === "multi_wallet") {
                    let multiP = JSON.parse(orderItem.multi_pricing);
                    let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                    paymentIndex = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                }
                if (_.isUndefined(totalPrice[paymentIndex])) {
                    totalPrice[paymentIndex] = 0;
                }
                if (_.isUndefined(subtotalPrice[paymentIndex])) {
                    subtotalPrice[paymentIndex] = 0;
                }
                totalPrice[paymentIndex] += parseFloat(paymentItem);
                subtotalPrice[paymentIndex] += parseFloat(paymentItem);
            })
        }
    })

    return (
        <Box>
            <ImgHeader title={t('order.details')} menuButton={false} normalDisplay imageDisplay={false} imageLongDisplay={true} backUrl={oid ? '/order?type=delivered' : '/home'} />
            <Box className={styles.orderHistoryPage}>
                <div style={{ marginTop: 0 }}>
                    <div className='p-lr-40' style={{ marginTop: -60 }}>
                        <div className='pos-relative flex-c-m'>
                            <div style={{ width: '100%', height: 170, borderRadius: 15 }} />
                            <div className='pos-absolute card-color p-all-20 flex-sb flex-col card-text txt-upper' style={{ zIndex: 2 }}>
                                <div className='flex-sb-m w-full' >
                                    <div>
                                        <Typography>{order[0]?.buyer_name}</Typography>
                                        <Typography>{order[0]?.buyer_mobile}</Typography>
                                    </div>

                                </div>
                                <div className='p-t-10'>
                                    <p className='fs-15' style={{ textTransform: 'capitalize' }}>{order[0]?.full_address}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', display: 'flex', justifyContent: 'space-between', width: '100%', zIndex: 1, paddingLeft: '40px', paddingRight: '40px' }}>
                        <div className='card-color2' style={{ marginTop: '-30px', marginLeft: '20px', maxWidth: '18px', maxHeight: '10x' }}>

                        </div>
                        <div className='card-color3' style={{ marginTop: '-30px', marginRight: '20px', maxWidth: '18px', maxHeight: '30px' }}>

                        </div>
                    </div>
                    <div className='card-color-recipient' style={{ marginTop: '-10px', zIndex: 2, marginLeft: '40px', marginRight: '40px', width: 'auto', display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>
                            <Typography className={styles.recipientText}>{t('checkout.buyFor', { recipient: order.recipient_username })}</Typography>
                        </div>
                    </div>

                    <div className='p-lr-40'>
                        <Grid container>
                            {
                                _.size(order) > 0 &&
                                _.map(order, (orderListing, key) => {
                                    if (key != 'recipient_username') {
                                        return (
                                            <div key={key} style={{ width: "100%" }}>
                                                <Grid item xs={12} className='p-t-20' >
                                                    <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                        <Storefront style={{ marginRight: 10 }} />
                                                        <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 18, marginLeft: 5 }}>
                                                            {orderListing?.merchant_company?.[`shop_name_${i18n.language}`]}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                {
                                                    _.map(orderListing?.order_details, (detail, key2) => {
                                                        let title_translate = _.split(detail.title, '|');
                                                        return (
                                                            <Grid item xs={12} className={styles.productDetailStyle} key={key2}>
                                                                <Box style={{ padding: 10 }}>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <WebpImg style={{ width: "100%", height: 93, borderRadius: 10, objectFit: 'cover' }} alt={detail.id} src={detail.image ? detail.image : '/images/logo.png'} />
                                                                            </Grid>
                                                                            <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '15px 10px 15px 0px' }}>
                                                                                <Typography variant="caption" style={{ paddingBottom: 5, color: "#4E4E4E", overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{i18n.language === 'cn' ? title_translate[1] : title_translate[0]}</Typography>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    <div className={styles.productVariantStyle}>
                                                                                        {
                                                                                            _.size(detail.product_attribute_display) > 0 &&
                                                                                            _.map(detail.product_attribute_display, (variantText, i) => {
                                                                                                if (_.includes(['color', 'size'], i)) {
                                                                                                    return (
                                                                                                        <Typography key={i} variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}&nbsp;</Typography>
                                                                                                    )
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                <div style={{ paddingTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    {
                                                                                        _.size(detail.payment) > 0
                                                                                            ?
                                                                                            _.map(detail.payment, (paymentItem, paymentIndex) => {
                                                                                                if (parseFloat(paymentItem) > 0) {
                                                                                                    return (
                                                                                                        <Box key={paymentIndex}>
                                                                                                            <Typography variant="caption" style={{ fontSize: 14 }}>
                                                                                                                {priceDisplay(paymentItem, paymentIndex, detail)}
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    );
                                                                                                }
                                                                                            })
                                                                                            :
                                                                                            <Typography variant="caption">
                                                                                                <NumberFormat style={{ fontSize: 14 }} value={parseFloat(detail.price) * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                            </Typography>
                                                                                    }
                                                                                    <Typography variant="body2" style={{ fontSize: 14 }}>x {detail.quantity}</Typography>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </Box>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )

                                    }

                                })
                            }

                        </Grid>
                    </div>

                    {
                        _.size(order?.order_shippings) > 0 ?
                            _.map(order?.order_shippings, shippingValue => {
                                let countShipping = false;
                                if (order?.status === 60) {
                                    countShipping = true;
                                } else {
                                    _.map(shippingValue.order_shipping_details, shipingDetail => {
                                        _.map(order?.order_details, orderDetails => {
                                            if (orderDetails.id === shipingDetail.order_detail_id) {
                                                if (orderDetails.status) {
                                                    countShipping = true;
                                                }
                                            }
                                        })
                                    })
                                }
                                if (countShipping) {
                                    if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                        let wid = shippingWalletId;
                                        if (shippingValue.wallet_id > 0) {
                                            wid = shippingValue.wallet_id;
                                        }
                                        if (_.isUndefined(totalPrice[wid])) {
                                            totalPrice[wid] = 0;
                                        }
                                        if (_.isUndefined(totalShipping[wid])) {
                                            totalShipping[wid] = 0;
                                        }
                                        totalPrice[wid] += parseFloat(shippingValue.fee);
                                        totalShipping[wid] += parseFloat(shippingValue.fee);
                                    }
                                }
                            })
                            : null
                    }

                    {
                        validBalance && ((parseFloat(totalPayment['cash'])).toFixed(2) > 0) ?
                            <>
                                <Divider style={{ background: '#E8E8E8', height: 8, margin: '20px 0' }} />
                                <div className='p-lr-40 '>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Box>
                                                <div >
                                                    <Grid container>
                                                        <Grid item xs={12} className='p-b-5'>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('checkout.subtotal')}</Typography>
                                                                {
                                                                    parseFloat(totalPrices['cash'] * currencyRate[`${currency}`]).toFixed(2) > 0 ?
                                                                        <Typography variant="body2" style={{ textAlign: 'end' }}>
                                                                            <NumberFormat value={parseFloat(totalPrices['cash'] * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                        </Typography>
                                                                        :
                                                                        <Typography variant="body2" style={{ textAlign: 'end' }}>
                                                                            <NumberFormat style={{ fontSize: 14 }} value={0} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                        </Typography>
                                                                }
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} className='p-b-5'>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('order.shippingFee')}</Typography>
                                                                {
                                                                    parseFloat(totalShippingPrice['cash']).toFixed(2) > 0 ?
                                                                        <NumberFormat value={parseFloat(totalShippingPrice['cash'] * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                        :
                                                                        <Typography>{t('order.freeShipping')}</Typography>
                                                                }
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} className='p-b-5'>
                                                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('checkout.totalPayment')}</Typography>
                                                                {
                                                                    (parseFloat(totalPayment['cash']) * currencyRate[`${currency}`]).toFixed(2) > 0 ?
                                                                        <NumberFormat value={(parseFloat(totalPayment['cash']) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                        :
                                                                        <Typography variant="body2" style={{ textAlign: 'end' }}>
                                                                            <NumberFormat value={0} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                        </Typography>
                                                                }
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                {((parseFloat(totalPayment['cash'])).toFixed(2) > 0) ?
                                                    <>
                                                        {REVENUEMONSTER_AVAILABLE ?
                                                            <Box className={styles.stickyFooter} style={{ textAlign: 'right', zIndex: 1299, minHeight: 0, height: 68 }}>
                                                                <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '100%' }}>
                                                                    <Button variant="contained" fullWidth style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white.main, borderRadius: 10 }} onClick={() => makePayment('revenuemonster')}>
                                                                        <b>{t('payment.systemWallet')}</b>
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                            : null}
                                                    </>
                                                    : null}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>

                            :
                            null
                    }

                    {
                        validBalance && !((parseFloat(totalPayment['cash'])).toFixed(2) > 0) ?
                            <>
                                <Divider style={{ background: '#E8E8E8', height: 8, margin: '20px 0' }} />
                                <div className='p-lr-40 '>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Box>
                                                <Grid container>
                                                    <Grid item xs={12} className='p-b-5'>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('checkout.totalPayment')}</Typography>
                                                            {
                                                                totalPayment ?
                                                                    _.map(totalPayment, (priceItem, priceIndex) => {
                                                                        if (parseFloat(priceItem) > 0) {
                                                                            return (
                                                                                <Box key={priceIndex}>
                                                                                    {priceDisplay(priceItem, priceIndex)}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                    })
                                                                    : null
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Box className={styles.stickyFooter} style={{ textAlign: 'right', zIndex: 1299, minHeight: 0, height: 68 }}>
                                                    <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '100%' }}>
                                                        <Button variant="contained" fullWidth style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white.main, borderRadius: 10 }} onClick={() => walletPayment()}>
                                                            <b>{t('payment.systemWallet')}</b>
                                                        </Button>
                                                    </Box>
                                                </Box>

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>

                            :
                            null
                    }
                </div>
            </Box>
        </Box>

    )
}

const useStyles = makeStyles(theme => ({
    productDetailStyle: {
        width: "100%",
        borderRadius: 15,
        margin: '10px 0px',
        background: '#F4F4F4',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF',
    },
    productVariantStyle: {
        borderRadius: 5,
        background: '#EDEDED',
        boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 3px 0px #FFF',
        padding: '0px 10px',
        width: 180,
    },
    inputStyle: {
        textAlign: 'center',
        width: 20,
        height: 16,
        fontSize: 14,
        padding: 5,
    },
    buttonDisplayStyle: {
        // background: theme.palette.primary.main,
        textTransform: 'capitalize',
        color: '#fff',
        borderRadius: 100,
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#000'
        }
    },
    orderHistoryPage: {
        borderRadius: '30px 30px 0px 0px',
        background: '#F4F4F4',
        position: 'absolute',
        top: 200,
        width: '100%',
    },
    recipientText: {
        color: '#FFF',
        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.10)',
        fontSize: '15px',
        textTransform: 'capitalize'
    },
}));