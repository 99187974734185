import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, useTheme, Typography, Box, Link, Grid, } from '@material-ui/core';
import TitleBar from './Layouts/TitleBar';

import { currencyFormat } from '../helper/Tools';
import { getUrl } from '../helper/ApiAction';
import { storeWalletBalance } from '../actions';
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import { RiShutDownLine, RiFileList3Line } from "react-icons/ri";

import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { BiTransfer } from "react-icons/bi";

import Slider from "react-slick";
import NumberFormat from 'react-number-format';
import { green } from '@material-ui/core/colors';

import './css/styles.css';

export default function Wallet() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const { addAlert } = useNotificationLoading();
    const { walletBalance, walletList, accessToken } = useSelector(state => ({
        walletBalance: state.user.walletBalance,
        walletList: state.wallet.walletList,
        accessToken: state.general.accessToken
    }));
    const styles = useStyles();

    const isMountedRef = useRef(null);

    const [commissionSummary, setCommissionSummary] = useState([]);
    const [currentpage, setCurrentPages] = useState(1);

    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            getUrl('wallets/balance').then(response => {
                if (response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            getUrl('commissions/summary').then(response => {
                if (response.status && isMountedRef.current) {
                    setCommissionSummary(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [dispatch, addAlert, accessToken])

    //get transaction detail base on currentpage
    const[type , setType]= useState();
    const[transactionList , setTransactionList]= useState({});
    useEffect(() => {
        setType(walletBalance[currentpage]?walletBalance[currentpage].code!='MP'?walletBalance[currentpage].code:'bonues':'bonues');
    }, [currentpage])
    
    useEffect(() => {
        let params = {'code':type}
        getUrl('transaction/list',params).then(response => {
            setTransactionList(response.data);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }, [type])
    
    return (

        <Box paddingBottom={5} style={{ background: theme.palette.white.mobileBkg, padding: '0 0 80px', minHeight: '100%' }}>{/* className={styles.root} style={{ padding: '0 0 80px', }} */}
            <TitleBar height={70} title={t('title.wallet')} displayInfo displayQr currencyButton displayCart linkMall logoIcon />
            <div className='fixed-header-padding'>
                {/* <div style={{ padding: 25 }}>
                    <AutoplaySlider
                        play={true}
                        cancelOnInteraction={false}
                        interval={3000}
                        bullets={true}
                        className={'wallet'}
                        organicArrows={false}
                    >
                        {_.map(walletBalance, walletItem => {
                            if (walletItem.code === 'MP' && walletItem.id === 4 && walletItem.balance == 0) { }
                            else {
                                return <div className="awssld__content">
                                    <div style={{ position: 'relative' }}>
                                        <img src="/images/general/card/wallet-bkg.png" style={{ width: '100%' }} />
                                        <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} />
                                    </div>
                                </div>
                            }
                        })}
                    </AutoplaySlider>
                </div> */}

                <div style={{ padding: 25 }}>
                    <Slider
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={false}
                        pauseOnHover={true}
                        initialSlide={1}
                        bullets={true}
                        responsive={[
                            { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: false } },
                            { breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 } },
                            { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 2 } },
                        ]}
                        afterChange= {(current) => setCurrentPages(current)}
                    // nextArrow={<NextArrow />}
                    // prevArrow={<PrevArrow />}
                    // style={{ marginLeft: -5 }}
                    >
                        {_.map(walletBalance, walletItem => {
                            if (walletItem.code === 'MP' && walletItem.id === 4 && walletItem.balance == 0) { }
                            else {
                                return <div >
                                    <div style={{ position: 'relative', margin: '0 20px' }}>
                                        <img src="/images/general/card/wallet-bkg.png" style={{ width: '100%', borderRadius: 15 }} />
                                        <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} style={{ position: 'absolute', bottom: 0 }} />
                                    </div>
                                    <div>
                                        <WalletComponentMenu key={walletItem.id} data={walletItem} walletList={walletList} style={{ position: 'absolute', bottom: 0 }} />
                                    </div>
                                </div>
                            }
                        })}

                        {/* // jianwei jian wei - new accumulate point  */}
                        {/* <div>
                            <div style={{ position: 'relative', margin: '0 20px' }} className='converted-text'>
                                <img src="/images/general/card/wallet-bkg.png" style={{ width: '100%', borderRadius: 15 }} className='shadow-out' />
                                <div style={{ position: 'absolute', top: 0 }} className='h-full p-all-10 w-full'>
                                    <div className='w-full h-full flex-c-m'>
                                        <div className='w-full'>
                                            <div className='flex-m w-full p-b-10'>
                                                <div className='flex-c-m p-all-8' style={{ height: 65, width: 65, background: 'rgb(0 0 0 / 46%)', borderRadius: 10, boxShadow: 'inset 2px 3px 3px 0 #000, 2px 3px 3px 0 #b4e0f44a' }}>
                                                    <img src={`/images/rank_logo/rank-0.png`} alt="rank" style={{ width: '100%' }} />
                                                </div>
                                                <div className='p-l-15' style={{ color: theme.palette.text.lightBlue }}>
                                                    <p className='fs-18 lh-12 p-b-5'><b>Username Here</b></p>
                                                    <div className='fs-15'>
                                                        <p className='lh-12 fs-12'>Rank Name Here</p>
                                                        <p className='lh-12 fs-12'><b>35k</b> {t('package.totalSubscription')}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex-c-m w-full' style={{ position: 'relative', color: theme.palette.text.lightBlue }}>
                                                <div className='borderBottom w-full' style={{ borderBottom: '1px dashed #ffffff57' }} />
                                                <div className='flex-m p-l-15 p-r-30'>
                                                    <p className='fs-13 p-r-8 txt-upper' style={{ whiteSpace: 'nowrap' }}>{t('package.convertedPoint')}</p>
                                                    <img src='/images/wallet/usdt.png' alt="usdt" style={{ width: 15 }} />
                                                </div>
                                                <div className='borderBottom w-full' style={{ borderBottom: '1px dashed #ffffff57' }} />
                                            </div>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} className='txt-center' style={{ color: theme.palette.text.lightBlue }}>
                                                    <p className='fs-18'><b>8,888</b></p>
                                                    <p className='txt-upper fs-12'>{t('package.today')}</p>
                                                </Grid>
                                                <Grid item xs={6} className='txt-center' style={{ color: theme.palette.text.lightBlue }}>
                                                    <p className='fs-18'><b>8,888</b></p>
                                                    <p className='txt-upper fs-12'>{t('package.accumulate')}</p>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <Box display="flex" justifyContent="space-between" alignItems="flex-start" paddingY={2} style={{ width: '100%' }}>
                                    <div style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                                        <div style={{width:47, height:47, backgroundColor:"#d1d1d1", color:'#aeaeae', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                                            <FiArrowUp style={{fontSize:28}}/>
                                        </div>
                                        <Typography style={{color:'#d1d1d1', textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.transfer')}</Typography>
                                    </div>
                                    <div style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                                        <div style={{width:47, height:47, backgroundColor:"#d1d1d1", color:'#aeaeae', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                                            <BiTransfer style={{fontSize:28}}/>
                                        </div>
                                        <Typography style={{color:'#d1d1d1', textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.convert')}</Typography>
                                    </div>
                                    <div style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                                        <div style={{ width: 47, height: 47, backgroundColor: '#d1d1d1', color: '#aeaeae', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                                <FiArrowDown style={{ fontSize: 28 }} />
                                        </div>
                                        <Typography style={{ color: '#d1d1d1', textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('wallet.withdrawal')}</Typography>
                                    </div>
                                    <div style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                                        <div style={{ width: 47, height: 47, backgroundColor: '#d1d1d1', color: '#aeaeae', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                            <RiFileList3Line style={{ fontSize: 28 }} />
                                        </div>
                                        <Typography style={{ color: '#d1d1d1', textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('title.withdrawalHistory')}</Typography>
                                    </div>
                                </Box>
                        </div> */}
                        {_.size(commissionSummary) ?
                            <>
                                <div style={{ position: 'relative', margin: '0 20px' }}>
                                    <img src="/images/general/card/wallet-bkg.png" style={{ width: '100%', borderRadius: 15 }} />
                                    <div className={styles.bonusCard}>
                                        <div className='flex-c-m w-full h-full'>
                                        <Grid container spacing={2} style={{height:'100%' }} >
                                            {_.map(commissionSummary.bonus, bonusItem => {
                                                let bonusName = _.split(bonusItem.name, '|');
                                                bonusName = i18n.language === "cn" && bonusName[1] ? bonusName[1] : bonusName[0];
                                                let summary = _.find(commissionSummary.summary, { bonus_id: bonusItem.id });
                                                let amount = "0.00";
                                                if (summary) amount = Number(summary.amount).toFixed(2);
                                                return (

                                                    <Grid item xs={6} key={bonusItem.id} style={{color:'#fff', textAlign:'center', paddingBottom:5, height:'fit-content'}}>
                                                        <Typography variant="body1" style={{lineHeight:1, fontWeight:'bold', fontSize:21}}>{amount}</Typography>
                                                        <Typography variant="body1"  style={{fontSize: 13, lineHeight:1.0, wordBreak: 'break-word', textTransform: 'uppercase', paddingTop: 6}}>{bonusName}</Typography>
                                                    </Grid>

                                                )
                                            })
                                            }
                                            <Grid item xs={6} style={{color:'#fff', textAlign:'center', paddingBottom:20}}>
                                            <Typography style={{  fontSize: 27, textTransform: 'uppercase',  fontWeight: 'bold' }}>{t('title.bonus')}</Typography>
                                            </Grid>
                                        </Grid>
                                        </div>
                                    </div>
                                    
                                    {/* <div style={{ position: 'absolute', top: 105, right: 25 }}>
                                        <Typography style={{ color: '#fff', fontSize: 27, textTransform: 'uppercase', textAlign: 'right', fontWeight: 'bold' }}>{t('title.bonus')}</Typography>
                                    </div> */}
                                      {/* <Link underline="none" to={`/bonus`} component={RouterLink} style={{ marginRight: 5, color:'#fff' }}>
                                            <Typography style={{ textDecoration: "underline" }}>{t('commission.seeMore')}</Typography>
                                        </Link> */}
                                </div>
                                <Box display="flex" justifyContent="space-between" alignItems="flex-start" paddingY={2} style={{ width: '100%' }}>
                                    <div style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                                        <div style={{width:47, height:47, backgroundColor:"#d1d1d1", color:'#aeaeae', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                                            <FiArrowUp style={{fontSize:28}}/>
                                        </div>
                                        <Typography style={{color:'#d1d1d1', textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.transfer')}</Typography>
                                    </div>
                                    <div style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                                        <div style={{width:47, height:47, backgroundColor:"#d1d1d1", color:'#aeaeae', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                                            <BiTransfer style={{fontSize:28}}/>
                                        </div>
                                        <Typography style={{color:'#d1d1d1', textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.convert')}</Typography>
                                    </div>
                                    <div style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                                        <div style={{ width: 47, height: 47, backgroundColor: '#d1d1d1', color: '#aeaeae', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                                <FiArrowDown style={{ fontSize: 28 }} />
                                        </div>
                                        <Typography style={{ color: '#d1d1d1', textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('wallet.withdrawal')}</Typography>
                                    </div>
                                    <div style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                                        <div style={{ width: 47, height: 47, backgroundColor: '#d1d1d1', color: '#aeaeae', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                            <RiFileList3Line style={{ fontSize: 28 }} />
                                        </div>
                                        <Typography style={{ color: '#d1d1d1', textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('title.withdrawalHistory')}</Typography>
                                    </div>
                                </Box>
                            </>
                            : null
                        }
                    </Slider>
                </div>

                <div style={{borderRadius: '40px 40px 0px 0px', boxShadow: 'rgb(0 0 0 / 11%) 0px 6px 8px 0px inset', padding: '25px 0',}}>
                    <Link underline='none' to={(accessToken ? "/collection/1" : "/login")} component={RouterLink} className={styles.iconStyle}>
                        <div className='starter-button-style'>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                                <RiShutDownLine style={{ color: '#fff', fontSize: 26 }} />
                                <Typography style={{ fontWeight: 'bold', fontSize: 23, paddingLeft: 10 }}>{t('button.starterPack')}</Typography>
                            </div>
                        </div>
                    </Link>

                    <div style={{padding:20}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Typography style={{fontSize:18, color:theme.palette.gray.text, paddingBottom:10}}>
                            {t('title.transaction')}
                        </Typography>
                        {type == 'bonues'?
                        <Link underline='none' to={`/bonus`} component={RouterLink} >
                        <Typography style={{fontSize:14, color:theme.palette.gray.text, paddingBottom:10}}>
                            {t('item.viewAll')}
                        </Typography>
                        </Link>
                        :
                        <Link underline='none' to={`/transaction/${type}`} component={RouterLink} >
                        <Typography style={{fontSize:14, color:theme.palette.gray.text, paddingBottom:10}}>
                            {t('item.viewAll')}
                        </Typography>
                        </Link>
                        }
                        
                        </div>

                        {_.map(transactionList,transactionList=>{
                            let operator = transactionList.transaction.factor > 0 ? "+" : "-";
                            return (<div style={{padding:'10px 0', borderBottom:'2px dashed #ececec', padding:'15px 0'}}>
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
                                <div style={{fontSize:14}}>
                                    {/* <Typography>{transactionList.transaction.type}</Typography> */}
                                    <Typography>{_.hasIn(transactionList.transaction.type_display, i18n.language) ? transactionList.transaction.type_display[i18n.language] : '-'}</Typography>
                                    <Typography className='txt_truncate2' style={{color:theme.palette.gray.text, height:'unset', lineHeight:1.2}}>{transactionList.transaction.remark?transactionList.transaction.remark:'-'}</Typography>
                                </div>
                                <Typography style={{color:operator!="+"?theme.palette.primary.main:"green", whiteSpace:'nowrap'}}><b>{operator}{<NumberFormat value={parseFloat(transactionList.amount).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} />}</b></Typography>
                            </div>
                            <Typography style={{paddingTop:8, fontSize:14, color:theme.palette.gray.text,}}>{transactionList.updated_at?transactionList.updated_at:'-'}</Typography>
                        </div>)
                            
                        })
                        }
                    </div>

                </div>

                {/* <Grid container spacing={1} justify="center" style={{ padding: '20px 15px 0' }}>
                    <Grid item xs={12}>
                        <div >
                            <div>
                                <Link underline='none' to={(accessToken ? "/collection/1" : "/login")} component={RouterLink} className={styles.iconStyle}>
                                    <WebpImg src={`/images/slides/StarterPack.jpeg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius: 15, boxShadow: 'rgb(67 36 14 / 32%) 2px 3px 8px 0px' }} />
                                </Link>
                            </div>
                        </div>

                    </Grid>
                </Grid> */}


                <Box minHeight={500}>
                    {/* {_.map(walletBalance, walletItem => {
                        if (walletItem.code === 'MP' && walletItem.id === 4 && walletItem.balance == 0) { }
                        else {
                            return <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} />
                        }
                    })} */}


                </Box>
            </div>
        </Box>
    );
}

const WalletComponent = (props) => {
    const { data, walletList } = props;
    // console.log("data", data);

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const existWallet = _.find(walletList, { id: data.id });
    let allowTransfer = false;
    let allowConvert = false;
    let allowWithdraw = false;
    if (existWallet) {
        allowTransfer = existWallet.allow_transfer;
        allowConvert = existWallet.allow_convert;
        allowWithdraw = existWallet.allow_withdraw;
    }
    const balance = currencyFormat((data.balance * 100) / 100);

    return (
        <>
            <div style={{ position: 'absolute', bottom: 0, padding: 25 }}>
                <Typography style={{ color: theme.palette.white.main, fontSize: 30, fontWeight: 'bold', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textTransform: 'uppercase' }}>{balance}</Typography>
                <Typography style={{ color: theme.palette.white.main, fontSize:13 }}>{t('wallet.totalBalance')}</Typography>
            </div>
            <div style={{ position: 'absolute', top: 25, right: 25 }}>
                <Typography style={{ color: '#fff', fontSize: 27, textTransform: 'uppercase', textAlign: 'right', fontWeight: 'bold' }}>{data.wallet_name}</Typography>
            </div>
            {/* <Box padding={2} borderRadius={15} boxShadow='0 0 8px 0 #b9b9b9' style={{ backgroundColor: theme.palette.common.white }}>
                <Typography style={{ color: theme.palette.secondary.main, fontSize: 30, fontWeight: 'bold', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{balance}</Typography>
                <Typography style={{ color: theme.palette.silver.text, fontWeight: 'bold' }}>{data.wallet_name}</Typography>
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" paddingY={2} style={{ width: '100%' }}>
                    {allowTransfer ?
                        <Link underline='none' to={`/transfer/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                            <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.transfer')}</Typography>
                        </Link>
                        : null}
                    {allowConvert ?
                        <Link underline='none' to={`/convert/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                            <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.convert')}</Typography>
                        </Link>
                        : null}
                    <Link underline='none' to={`/transaction/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                        <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.transaction')}</Typography>
                    </Link>
                    {allowWithdraw ?
                        <>
                            <Link underline='none' to={`/withdrawal/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                                <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.withdrawal')}</Typography>
                            </Link>
                            <Link underline='none' to={`/withdrawal-history/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                                <Typography classes={{ root: classes.walletButtonStyle }}>{t('title.withdrawalHistory')}</Typography>
                            </Link>
                        </>
                        : null}
                </Box>
            </Box> */}
        </>
    )
}

const WalletComponentMenu = (props) => {
    const { data, walletList } = props;
    // console.log("data", data);

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const existWallet = _.find(walletList, { id: data.id });
    let allowTransfer = false;
    let allowConvert = false;
    let allowWithdraw = false;
    if (existWallet) {
        allowTransfer = existWallet.allow_transfer;
        allowConvert = existWallet.allow_convert;
        allowWithdraw = existWallet.allow_withdraw;
    }
    const balance = currencyFormat((data.balance * 100) / 100);

    return (
        <>
            <Box >
                <Box display="flex" justifyContent="space-between" alignItems="flex-start" paddingY={2} style={{ width: '100%' }}>
                    {allowTransfer ?
                        <Link underline='none' to={`/transfer/${data.code}`} component={RouterLink} style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                        <div style={{width:47, height:47, backgroundColor:theme.palette.primary.main, color:'#fff', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                        <FiArrowUp style={{fontSize:28}}/>
                        </div>
                        <Typography style={{color:theme.palette.gray.text, textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.transfer')}</Typography>
                    </Link>
                        :
                        <div style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                            <div style={{width:47, height:47,  backgroundColor:"#d1d1d1", color:'#aeaeae', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                            <FiArrowUp style={{fontSize:28}}/>
                            </div>
                            <Typography style={{color:'#d1d1d1', textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.transfer')}</Typography>
                        </div>
                    }
                    {allowConvert ?
                        <Link underline='none' to={`/convert/${data.code}`} component={RouterLink} style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                            <div style={{width:47, height:47, backgroundColor:theme.palette.primary.main, color:'#fff', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                            <BiTransfer style={{fontSize:28}}/>
                            </div>
                            <Typography style={{color:theme.palette.gray.text, textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.convert')}</Typography>
                        </Link>
                        : 
                        <div style={{ margin: 5, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'25%' }}>
                            <div style={{width:47, height:47, backgroundColor:"#d1d1d1", color:'#aeaeae', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:48}}>
                            <BiTransfer style={{fontSize:28}}/>
                            </div>
                            <Typography style={{color:'#d1d1d1', textAlign:'center', paddingTop:8, lineHeight:1.0, fontSize:13}}>{t('wallet.convert')}</Typography>
                        </div>}
                    {/* <Link underline='none' to={`/transaction/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                        <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.transaction')}</Typography>
                    </Link> */}
                    {allowWithdraw ?
                        <>
                            <Link underline='none' to={`/withdrawal/${data.code}`} component={RouterLink} style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                                <div style={{ width: 47, height: 47, backgroundColor: theme.palette.primary.main, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                    <FiArrowDown style={{ fontSize: 28 }} />
                                </div>
                                <Typography style={{ color: theme.palette.gray.text, textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('wallet.withdrawal')}</Typography>
                            </Link>
                        </>
                        : 
                        <div style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                            <div style={{ width: 47, height: 47, backgroundColor:"#d1d1d1", color:'#aeaeae', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                    <FiArrowDown style={{ fontSize: 28 }} />
                            </div>
                            <Typography style={{ color: '#d1d1d1', textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('wallet.withdrawal')}</Typography>
                        </div>}
                    {allowWithdraw ?
                        <>
                        <Link underline='none' to={`/withdrawal-history/${data.code}`} component={RouterLink} style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                                <div style={{ width: 47, height: 47, backgroundColor: theme.palette.primary.main, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                    <RiFileList3Line style={{ fontSize: 28 }} />
                                </div>
                                <Typography style={{ color: theme.palette.gray.text, textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('title.withdrawalHistory')}</Typography>
                            </Link>
                        </>
                        : 
                        <div style={{ margin: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width:'25%' }}>
                            <div style={{ width: 47, height: 47, backgroundColor:"#d1d1d1", color:'#aeaeae', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 48 }}>
                                <RiFileList3Line style={{ fontSize: 28 }} />
                            </div>
                            <Typography style={{ color: '#d1d1d1', textAlign: 'center', paddingTop: 8, lineHeight:1.0, fontSize:13 }}>{t('title.withdrawalHistory')}</Typography>
                        </div>
                        }
                </Box>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    bonusCard:{
        position: 'absolute',
        top: 0,
        padding: 25, 
        height:'100%',
        [theme.breakpoints.up('sm')]: {
            top:'-1vw'
          },
          [theme.breakpoints.down('sm')]: {
            top:'-1vw'
          },
          [theme.breakpoints.down('xs')]: {
            top:'-1vw'
          },
      
    },
    walletButtonStyle: {
        border: '1px solid #696969',
        borderRadius: 48,
        padding: '5px 10px',
        color: theme.palette.silver.text,
        fontSize: 14,
        minWidth: 75,
        textAlign: 'center'
    }
}));
