import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, Box, Paper, Typography, InputAdornment, TextField, useTheme, Button } from '@material-ui/core';

import TitleBar from './Layouts/TitleBar';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import { storeWalletBalance } from '../actions';

const DEFAULT_ERROR_FIELD = { amount: "", security_password: "" };
// const DEFAULT_ERROR_FIELD = { amount: "", password: "" };

export default function Withdrawal() {
    const [withdrawRules, setWithdrawRules] = useState({
        "min": null,
        "max": null,
        "charges": null,
        "charges_type": null,
        "charges_value": null,
    });

    const [walletInfo, setWalletInfo] = useState([]);
    const [walletName, setWalletName] = useState("");
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", security_password: "", dateTime: "", id: "" });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [withdrawalStatus, setWithdrawalStatus] = useState("insert"); // insert -> review -> result

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        let walletNameArray = _.split(existWallet.name, '|');
        setWalletName(_.size(walletNameArray) > 1 && i18n.language ==="cn" ? walletNameArray[1] : walletNameArray[0]);
        setUserWalletInfo(existWalletBalance);
        if(!existWallet) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo)) {
            getUrl('withdrawal_rules').then(response => {
                if(response.data && isMountedRef.current) {
                    if(response.status) {
                        if(parseInt(response?.data['wallet_id']) !== walletInfo.id) {
                            history.push('/wallet');
                        } else {
                            setWithdrawRules(response.data)
                            // setMaxWithdraw(response.data?.max);
                            // setMinWithdraw(response.data?.min);
                        }
                    } else {
                        history.push('/wallet');
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [addAlert, walletInfo])

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const reviewWithdrawal = () => {
        let error = false;
        let newErrorField = errorField;
        _.map(["amount", "security_password"], field => {
        //  _.map(["amount", "password"], field => {
            if(_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('withdrawal.isRequired', { field: t(`transfer.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        });

        if(withdrawRules.min > 0) {
            if(parseFloat(state.amount) < parseFloat(withdrawRules.min)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountLesserThan', { amount: withdrawRules.min });
            }
        }
        if(withdrawRules.max > 0) {
            if(parseFloat(state.amount) > parseFloat(withdrawRules.max)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountGreaterThan', { amount: withdrawRules.max });
            }
        }

        setErrorField(errorField => ({ ...errorField, newErrorField }));
        if(!error) {
            setWithdrawalStatus('review')
        } else {
            setWithdrawalStatus('insert')
        }
    }

    const withdrawWallet = () => {
        const { amount, security_password } = state;
        // const { amount, password } = state;
        const withdrawalData = {
            amount,
            security_password,
            // password,
        };

        postUrl(`withdrawal`, withdrawalData).then(response => {
            console.log("response", response)
            if(response.status) {
                // const { created_at, transaction_id, data } = response;
                setWithdrawalStatus('result');
                // setState({ ...state, id: transaction_id, dateTime: created_at });
                setErrorField(DEFAULT_ERROR_FIELD);
                refetchWallet();
            } else {
                if(response.errors) {
                    let newErrorField = errorField;
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if(response.data) {
                    addAlert(response.data);
                }
                setWithdrawalStatus('insert')
            }
        }).catch(error => {
            setWithdrawalStatus('insert')
            addAlert(JSON.stringify(error.message));
        })
    }

    const refetchWallet = () => {
        getUrl('wallets/balance').then(response => {
            if(response.status) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const getChargesValue = () => {
        const { charges_type, charges_value } = withdrawRules;

        if(charges_type === 'percent') {
            return (parseFloat(state.amount) * charges_value) / 100;
        } else {
            return charges_value;
        }
    }

    return (
        <Box className={classes.root}>
            <TitleBar height={70} title={ t('title.walletWithdrawal', { wallet: walletName }) } displayInfo displayQr currencyButton displayCart linkMall back backtext />
            <div className='fixed-header-padding'>           
                <Box style={{ background:theme.palette.primary.main, paddingBottom:70 }} display="flex" alignItems="center" justifyContent="center" >
                    <Box display="flex" flexDirection="column">
                        <Typography className={classes.balanceStyle}>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)} <span style={{ fontSize: 16 }}>{_.size(walletInfo) > 0 ? walletName : '-'}</span></Typography>
                        <Typography className={classes.infoStyle}>{t('withdrawal.withdrawableBalance')}</Typography>
                    </Box>
                </Box>
            <Paper elevation={3} classes={{ root: classes.paperContainer }}>
                { withdrawalStatus === 'result' ?
                    <Box className={classes.boxContainer}>
                        <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{ t('withdrawal.successfullyWithdraw') }</Typography>
                        <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.secondary.main }}>
                            { `${ currencyFormat(state.amount) } ${_.size(walletInfo) ? walletName : ''}`}
                        </Typography>
                        <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Box flex={1}>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{ t('withdrawal.feeCharges') }</Typography>
                            </Box>
                            <Box flex={2}>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{ currencyFormat(getChargesValue()) }</Typography>
                            </Box>
                        </Box>
                    </Box>
                : <Box className={classes.boxContainer}>
                    <Typography variant="h6" style={{ color: theme.palette.secondary.main, padding: 15 }}>{ t('title.walletWithdrawal', { wallet: walletName }) }</Typography>
                    <Box display="flex" width="100%" paddingX={2} flexDirection="row" justifyContent="space-between">
                        <div>
                            <Typography style={{ color: theme.palette.silver.bg, fontSize: 12 }}>{ t('withdrawal.minWithdraw', { amount: currencyFormat(withdrawRules.min) }) }</Typography>
                        </div>
                        <div>
                            <Typography style={{ color: theme.palette.silver.bg, fontSize: 12 }}>{ t('withdrawal.maxWithdraw', { amount: currencyFormat(withdrawRules.max) }) }</Typography>
                        </div>
                    </Box>
                    <Box display="flex" width="100%" paddingX={2} flexDirection="row" justifyContent="flex-start">
                            <Typography style={{ color: theme.palette.silver.bg, fontSize: 12 }}>{ `${t('withdrawal.chargesFee', { amount: currencyFormat(withdrawRules.charges_value) })} ${withdrawRules.charges_type === 'percent' ? "%" : walletName}` }</Typography>
                    </Box>
                    <Box borderTop={.5} borderColor={theme.palette.silver.bg} paddingY={2} paddingX={2} width="100%">
                        <TextField 
                            type="text"
                            variant="outlined"
                            value={state.amount}
                            name="amount"          
                            onChange={ handleChange }   
                            classes={{ root: classes.textFieldRoot }}
                            InputProps={{ readOnly: withdrawalStatus === 'review' ? true : false }}
                            label={ t('withdrawal.amount') }
                            fullWidth
                            error={ _.size(errorField.amount) ? true : false }
                            helperText={ errorField.amount }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                        />
                        
                        { withdrawalStatus === 'review' ?
                            <TextField 
                                type="text"
                                disabled
                                variant="outlined"
                                value={getChargesValue()}
                                onChange={ handleChange }   
                                classes={{ root: classes.textFieldRoot }}
                                label={ t('withdrawal.feeCharges') }
                                fullWidth
                            />
                        : null }

                        {/* <TextField
                            type="password"
                            variant="outlined"
                            value={state.password}
                            name="password"
                            onChange={handleChange}
                            classes={{
                                root: classes.textFieldRoot,
                            }}
                            InputProps={{
                                readOnly: withdrawalStatus === 'review' ? true : false,
                            }}
                            label={t('withdrawal.password')}
                            error={_.size(errorField.password) ? true : false}
                            helperText={errorField.password}
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                        /> */}

                        <TextField 
                            type="password"
                            variant="outlined"
                            value={state.security_password}
                            name="security_password"          
                            onChange={ handleChange }   
                            classes={{
                                root: classes.textFieldRoot,
                            }}
                            InputProps={{
                                readOnly: withdrawalStatus === 'review' ? true : false,
                            }}
                            label={ t('withdrawal.security_password') }
                            error={ _.size(errorField.security_password) ? true : false }
                            helperText={ errorField.security_password }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                        />
                    </Box>
                </Box> }
                <Box className={classes.boxContainer}>
                    { withdrawalStatus === 'insert' && <Button variant="contained" classes={{ root: classes.buttonRoot }} className='red-button-style' onClick={ reviewWithdrawal }>
                        { t('withdrawal.withdrawNow') }
                    </Button> }
                    { withdrawalStatus === 'review' && <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around">
                        <Button variant="contained" classes={{ root: classes.buttonRoot }} className='red-button-style' onClick={ () => setWithdrawalStatus('insert') }>
                            { t('withdrawal.changeDetail') }
                        </Button>
                        <Button variant="contained" classes={{ root: classes.buttonRoot }} className='red-button-style' onClick={ withdrawWallet }>
                            { t('withdrawal.confirmWithdraw') }
                        </Button>
                    </Box> }
                    { withdrawalStatus === 'result' && <Button variant="contained" classes={{ root: classes.buttonRoot }} className='red-button-style' onClick={ () => history.push('/wallet') }>
                        { t('withdrawal.complete') }
                    </Button> }
                </Box>
            </Paper>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -58,
        padding: 10,
        borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15, 
        marginBottom: 30
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: "white",//theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    balanceStyle: {
        fontSize: 30, 
        color: "white",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.white.main,
        fontSize: 11, 
        letterSpacing: 1 
    },
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 36, 
        fontWeight: 'bold', 
    }
}