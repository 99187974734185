import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { storeProfile } from "../actions";

import { Typography, Grid, Box, List, ListItem, Divider } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { putUrl } from "../helper/ApiAction";

import useNotificationLoading from "../helper/useNotificationLoading";

import TitleBar from "./Layouts/TitleBar";

export default function PlacementSetting() {
	const { t } = useTranslation();

	const { username, additional_info } = useSelector((state) => state.user);
	const { addAlert, setLoading } = useNotificationLoading();
	const theme = useTheme();

	const [additionalInfoFormState, setAdditionalInfoFormState] = useState({
		...additional_info,
	});

	const styles = useStyles();
	const dispatch = useDispatch();

	const handleAutoPlacementChange = (event) => {
		setLoading(true);

		if (
			event.target.name == "auto_placement_account" &&
			event.target.value == "0"
		) {
			setAdditionalInfoFormState({
				...additionalInfoFormState,
				[event.target.name]: event.target.value,
				auto_placement_position: "0",
			});
		} else {
			setAdditionalInfoFormState({
				...additionalInfoFormState,
				[event.target.name]: event.target.value,
			});
		}
		putUrl("user/additional_info", {
			[event.target.name]: event.target.value,
		}).then((result) => {
			setLoading(false);
			addAlert(result.message, result.status ? "success" : "error");
			if (result.status) {
				dispatch(storeProfile(result.data));
			}
		});
	};

	return (
		<Box
			className={styles.root} style={{ background: theme.palette.white.mobileBkg, padding: "0 0 80px", minHeight: "100%", }}
		>
			<TitleBar height={70} title={t('profile.placementSetting')} displayInfo displayQr currencyButton displayCart back backtext />
			<div className='fixed-header-padding'>
				<div style={{padding:'20px 25px'}}>
				<Typography variant="h6" style={{color:theme.palette.primary.main, paddingBottom:15}}>{t("profile.placementSetting")} </Typography>
					<div>
						<Typography style={{ padding: '9px 15px', boxShadow: '0 0 6px 0 #0003', borderRadius: 48}}>{t("profile.autoPlacementAccount")}</Typography>
						<div style={{padding:'0 15px'}}>
						<RadioGroup
							aria-label="auto_placement_account" column
							name="auto_placement_account"
							value={
								additionalInfoFormState.auto_placement_account
							}
							onChange={
								handleAutoPlacementChange
							}
						>
							<FormControlLabel
								value="0"
								control={<Radio />}
								label={t("profile.auto")}
							/>
							<FormControlLabel
								value="1"
								control={<Radio />}
								label={
									username.toUpperCase() +
									"-2"
								}
							/>
							<FormControlLabel
								value="2"
								control={<Radio />}
								label={
									username.toUpperCase() +
									"-3"
								}
							/>
						</RadioGroup>
						</div>
					</div>

					<div style={{paddingTop:25}}>
						<Typography style={{ padding: '9px 15px', boxShadow: '0 0 6px 0 #0003', borderRadius: 48}}>{t("profile.autoPlacementPosition")}</Typography>
						<div style={{padding:'0 15px'}}>
							<RadioGroup
								aria-label="auto_placement_position"
								column
								name="auto_placement_position"
								value={
									additionalInfoFormState.auto_placement_position
								}
								onChange={
									handleAutoPlacementChange
								}
							>
								<FormControlLabel
									value="0"
									control={<Radio />}
									label={t("profile.auto")}
								/>
								<FormControlLabel
									value="1"
									control={<Radio />}
									label={t("profile.left")}
									disabled={
										additionalInfoFormState.auto_placement_account ==
										"0"
									}
								/>
								<FormControlLabel
									value="2"
									control={<Radio />}
									label={t("profile.right")}
									disabled={
										additionalInfoFormState.auto_placement_account ==
										"0"
									}
								/>
							</RadioGroup>
						</div>
					</div>
				</div>
			</div>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	listPaddingVertical: {
		paddingTop: 15,
		paddingBottom: 15,
	},
	listStyle: {
		backgroundColor: "#fff",
		marginBottom: 20,
	},
}));
