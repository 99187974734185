import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, List, ListItem, Typography, TextField, Button, Dialog, Slider,CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUrl, putUrl } from '../helper/ApiAction';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useSelector } from 'react-redux';

import WebpImg from './Layouts/WebpImg';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';


export default function VoucherRedemption() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const isMountedRef = useRef(null);
    const [voucherData, setVoucherData] = useState(null);
    const [merchantInput, setMerchantInput] = useState();
    const [voucherInput, setVoucherInput] = useState();
    const [merchantInputError, setMerchantInputError] = useState();
    const [message, setMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const theme = useTheme();
    const [swipeValue, setSwipeValue] = useState(0);
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const [voucherInputError , setVoucherInputError]=useState();
    const [errField, setErrField]=useState(true);
    

    const query = useQuery();

    const voucher_code = query.get('voucher_code');
    // const verification_code = query.get('verification_code');

    let history = useHistory();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setSwipeValue(newValue);
    };

    useEffect(() => {
        isMountedRef.current = true;
        if (_.size(voucherInput) > 0) {
            if (isMountedRef.current) {
                getUrl(`voucher_redemption/${voucherInput}`).then(response => {
                    if (response.status === 1) {
                        setVoucherData(response.data);
                        setErrField(false);
                    }
                    else if (response.status === 0) {
                        setVoucherInputError(response.error);
                        setErrField(true);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
    }, [voucherInput]);


    const nextRedemption = () => {
        if (accessToken) {
            const newWindow = window.open('/account/vouchers', '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            const newWindow = window.open('/voucher_redemption', '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    const redemptVoucher = () => {
        let apiData = {
            voucher_code: voucherInput,
            merchant_code: merchantInput,
        }

        setLoading(true);
        // console.log('apiData', apiData);

        putUrl(`redemptvoucher`, apiData).then(response => {
            let { status, error, message } = response;
            if (status === 0 && error != null) {
                setMerchantInputError(error);
                setLoading(false);
            } else if (status === 1 && error !=null) {
                setMessage(error);
                setLoading(false);
                handleOpen();
            } else if (status === 1 && message !=null) {
                setSuccessMessage(message);
                setLoading(false);
                handleOpen();
            }
        }).catch(error=>{
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {

        if (voucher_code != null) {
            setVoucherInput(voucher_code);
        }

        // if (verification_code != null) {
        //     setMerchantInput(verification_code);
        // }

    }, [voucherInput]);

    return (
        <Container>
            <Grid container spacing={1} className={classes.box} justify="center" alignItems="center">

                <List>
                    <ListItem>
                        <Grid container spacing={1} justify="center">
                            <Typography variant="h5" >{t('voucher.voucherRedemption')}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container spacing={3} direction="column" justify="center" alignItems="center">
                            <Grid item>

                                {
                                    // _.size(voucherData) > 0 && voucherData != null ?
                                    //     <WebpImg src={voucherData.voucher_header.product.images_array[0]} alt="voucherImage" style={{ maxWidth: '100%', width: '275px', maxHeight: '100%', height: '250px' }} />
                                    //     :
                                    //     <WebpImg src="/images/no-image.png" alt="voucherImage" style={{ maxWidth: '100%', width: '275px', maxHeight: '100%', height: '250px' }} />
                                    _.size(voucherInput) > 0 && voucherInput != null ?
                                    errField ?
                                        <WebpImg src="/images/no-image.png" alt="voucherImage" style={{ maxWidth: '100%', width: '275px', maxHeight: '100%', height: '250px' }} />
                                        :
                                        _.size(voucherData) > 0 && voucherData != null ?
                                            <WebpImg src={voucherData.voucher_header.product.images_array[0]} alt="voucherImage" style={{ maxWidth: '100%', width: '275px', maxHeight: '100%', height: '250px' }} />
                                            :
                                            <WebpImg src="/images/no-image.png" alt="voucherImage" style={{ maxWidth: '100%', width: '275px', maxHeight: '100%', height: '250px' }} />
                                    :
                                    <WebpImg src="/images/no-image.png" alt="voucherImage" style={{ maxWidth: '100%', width: '275px', maxHeight: '100%', height: '250px' }} />

                                }
                            </Grid>
                            <Grid item>
                                {
                                    // _.size(voucherData) > 0 && voucherData != null ?
                                    //     <Typography variant="h6" style={{ paddingBottom: 10 }}>
                                    //         {voucherData.voucher_header.product.title}
                                    //     </Typography>
                                    //     :
                                    //     _.size(voucherInput)>0 && voucherInput !=null ?
                                    //     <CircularProgress color="secondary" disableShrink />
                                    //     :
                                    //     null
                                    _.size(voucherInput) > 0 && voucherInput != null ?
                                    errField ?
                                        <Typography color="error" variant="h6" style={{ paddingBottom: 10 }}>{voucherInputError}</Typography>
                                        :
                                        _.size(voucherData) > 0 && voucherData != null ?
                                        <Typography variant="h6" style={{ paddingBottom: 10 }}>
                                            {voucherData.voucher_header.product.title}
                                        </Typography>:
                                        <CircularProgress color="secondary" disableShrink />
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item >
                                <TextField
                                    id="voucher_code"
                                    variant="outlined"
                                    label={t('voucher.voucherCode')}
                                    value={voucherInput || ""}
                                    fullWidth
                                    size="small"
                                    onChange={({ target }) => setVoucherInput(target.value)}
                                    defaultValue={voucherInput}
                                />

                            </Grid>
                            {
                                errField ?
                                    null :
                                    swipeValue != 100 ?
                                        <>
                                            <Typography variant="body2">{t('voucher.swipeToRedeem')}</Typography>
                                            <SwipeSlider defaultValue={0} onChange={handleChange} value={swipeValue} />
                                        </>
                                        :
                                        <>
                                            <Grid item>
                                                <TextField
                                                    id="merchant_code"
                                                    variant="outlined"
                                                    label={t('voucher.merchantCode')}
                                                    fullWidth
                                                    size="small"
                                                    value={merchantInput || ""}
                                                    onChange={({ target }) => setMerchantInput(target.value)}
                                                    error={merchantInputError ? true : false}
                                                    helperText={merchantInputError ? merchantInputError : ''}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Dialog
                                                    open={open}
                                                    onClose={handleClose}
                                                    maxWidth="md"

                                                >
                                                    {
                                                        message != null ?
                                                            <Grid container spacing={2} justify="center" direction="column" alignItems="center" style={{ padding: 10 }}>
                                                                <Grid item>
                                                                    <WarningIcon style={{ color: '#F4FA58' }} fontSize="large" />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>{message}</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button variant="contained" color="secondary" component="span" className={classes.button} onClick={() => history.push(`/`)}>{t('voucher.backToHomepage')}</Button>
                                                                </Grid>

                                                            </Grid> :
                                                            <Grid container spacing={1} justify="center" direction="column" alignItems="center" style={{ padding: 10 }}>
                                                                <Grid item>
                                                                    <CheckCircleIcon style={{ color: '#2EFE64' }} fontSize="large" />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>{successMessage}</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button variant="contained" color="secondary" component="span" className={classes.button} onClick={nextRedemption}>{t('voucher.nextRedemption')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                    }

                                                </Dialog>
                                                <Button variant="contained" color="secondary" component="span" className={classes.button} onClick={redemptVoucher}>{t('voucher.redeemNow')}</Button>
                                            </Grid>
                                        </>
                            }
                        </Grid>
                    </ListItem>
                </List>
            </Grid>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({

    box: {
        backgroundColor: '#FFFFFF',
    },
    button: {
        borderStyle: 'solid',
        borderRadius: 20,
    }

}));

const SwipeSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: 20,
        width: 200,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: 0,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 24,
        borderRadius: 10,
        width: 200,

    },
    rail: {
        height: 24,
        borderRadius: 10,
        width: 200,
    },
}))(Slider);