import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box,Divider,Container, CardContent,Card} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { getUrl ,putUrl} from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import TitleBar from './Layouts/TitleBar';


export default function NotificationDetail() {

    const [page, setPage] = useState(1);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const {id} = useParams();
    const [notification, setNotifications]= useState([])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`notifications/${id}`).then(response => {
            console.log("response", response);
            if (response.status) {
                if (isMountedRef.current) {
                    setLoading(false);
                    setNotifications(response.data);
                    
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, addAlert]);
    useEffect(() => {
        setLoading(true);
        putUrl(`notifications/${id}`).then(response => {
            console.log("response", response);
            if (response.status) {
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        // eslint-disable-next-line
    }, []);

    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <Box className={styles.root} >
            <div style={{ width:420, maxWidth:'100%', zIndex:2}}>
                {
                    notification.subject ?
                        <TitleBar height={70} title={notification[`subject_${i18n.language}`] ? notification[`subject_${i18n.language}`] : notification.subject} displayInfo displayQr currencyButton displayCart linkMall back pagetitle />
                        :
                        <TitleBar height={70} title={t('profile.notification')} displayInfo displayQr currencyButton displayCart linkMall back pagetitle />
                }               
                <div className='fixed-header-padding'>
                    <div style={{padding:20}}>
                        <div style={{ boxShadow: 'rgb(255, 255, 255) -4px -4px 6px, inset 2px 6px 8px 0 #00000021', borderRadius: 15, marginBottom: 15, borderBottom:'5px solid #fff', borderRight:'5px solid #fff' }}>
                            <div style={{padding:'10px 20px'}} dangerouslySetInnerHTML={{ __html: notification[`message_${i18n.language}`] }} />
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        //padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg, 
        padding:'0 0 80px',
        minHeight: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
      },
}));