import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
// import { useQuery } from '../helper/Tools';

import _ from 'lodash';

import Order from './Order';
import OrderDetail from './OrderDetail';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {  Container, Grid, Box } from '@material-ui/core';
import TitleBarWhite from './Layouts/TitleBarWhite';
import TitleBar from './Layouts/TitleBar';

import './css/styles.css';

export default function Account() {

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let { oid } = useParams();
    let history = useHistory();
    const isMountedRef = useRef(null);
    // const query = useQuery();

    const displaySubPage = () => {
        if(oid) {
            return <OrderDetail t={t} i18n={i18n} oid={oid} language={i18n.language} />
        } else {
            return <Order t={t} i18n={i18n} language={i18n.language} />
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
    }, []);

    return (
        <Box className={styles.root2} >
            {/* <TitleBar title={t('title.order')} displayInfo={false} back/> */}
            <div className='mobile-width-fixed' style={{zIndex:3}}>
                {/* <TitleBarWhite height={0} title={t('title.order')} displayInfo={false} back /> */}
                <TitleBar height={70} title={t('title.order')} displayInfo displayQr currencyButton displayCart linkMall back pagetitle />
            </div>
            
            <Box className='fixed-header-padding' style={{zIndex:-1}}>
                <Container fixed className={ styles.root }>
                    <Grid container spacing={1} justify="center">
                        
                        <Box style={{width: 420, maxWidth: '100%'}}>
                            { displaySubPage() }
                        </Box>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'white',
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    }
}));