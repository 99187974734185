import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

import { getUrl, putUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import { Grid, Typography, Link, Box, Divider, List, ListItem, Chip, Table, TableBody, TableCell, TableRow, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import { KeyboardArrowLeft, LocalShipping, Stars, StarBorder, ExpandLess, ExpandMore, Storefront } from '@material-ui/icons';
import { FaTruckFast } from "react-icons/fa6";

import WebpImg from './Layouts/WebpImg';

export default function OrderDetailNew(props) {
    const { t, i18n, oid } = props;

    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let history = useHistory();

    const { addAlert, setLoading } = useNotificationLoading();
    const { walletList: wallets, username } = useSelector(state => ({
        walletList: state.wallet.walletList,
        username: state.user.username,
    }));
    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);


    const [order, setOrder] = useState([]);
    const [currencyData, setCurrencyData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [trackingData, setTrackingData] = useState([]);
    const [orderDetailsNoTracking, setOrderDetailsNoTracking] = useState([]);
    const [orderDetailsCancel, setOrderDetailsCancel] = useState([]);
    const [trackingDetailsOpen, setTrackingDetailsOpen] = useState([]);
    const [showRating, setShowRating] = useState(false);
    const [shippingWalletId, setShippingWalletId] = useState([]);
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order_no: '',
        remark: ''
    });

    let currency_code = [];
    let totalPrice = {};
    let totalShipping = {};
    let subtotalPrice = {};
    let rateDone = false;

    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // ---------------------------------- API ---------------------------------------------
    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`logistic/update_tracking_payment_parcel_status/${oid}`).then(response => {
            // no need show update error for member
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert(msg, 'error');
        });

        getUrl(`orders/${oid}`).then(orderList => {
            if (isMountedRef.current) {
                if (orderList.status === 1 && _.size(orderList.data) > 0) {
                    setCurrencyData(orderList.currency_data);
                    setOrder(orderList.data);
                    setTrackingData(orderList.order_tracking);
                    setOrderDetailsNoTracking(orderList.order_details_no_tracking);
                    setOrderDetailsCancel(orderList.order_details_cancelled);

                    let totalPrice = 0;
                    _.map(orderList.data.order_details, order => {
                        totalPrice += parseFloat(order.price);
                    });
                    setOrder(order => ({ ...order, order_total: totalPrice }));

                    getUrl('shipping_fee/wallet').then(response => {
                        if (response.status) {
                            if (_.size(response.data) > 0) {
                                setShippingWalletId(response.data.id);
                            } else {
                                setShippingWalletId('cash');
                            }
                        } else {
                            addAlert(JSON.stringify(response.data));
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                } else {
                    addAlert(t('order.invalidOrder'));
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [oid, addAlert, refreshData]);

    // --------------------------------- FUNCTION ---------------------------------------------
    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if(paymentIndex === 'multi_wallet'){
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if(multiPricing){
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
            }else{
                prefix = "Other wallets";
            }
        }else
        if(paymentIndex !== 'cash' && !isNaN(paymentIndex)){
            _.map(wallets, wallet => {
                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                    let name = _.split(wallet.name, '|');
                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                    // decimal = wallet.decimal;
                }
            })
        }else if(paymentIndex === 'cash'){
            paymentItem = paymentItem * currencyRate[`${currency}`]
            prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
        }else{
            prefix = paymentIndex;
        }

        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix+' ' : ''} />
        )
    }

    const confirmCompleted = (orderNo) => {
        setLoading(true);
        putUrl(`orders/${orderNo}`)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert(t('order.confirmCompletedSuccess'), 'success');
                setRefreshData(!refreshData);
                history.push(`/order?type=completed`);
            }else{
                addAlert(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const handleCancelOrderOpen = (orderNo) => {
        setCancelOrderDialog({
            open: true,
            order_no: orderNo
        });
    }

    const handleCancelOrderClose = () => {
        setCancelOrderDialog({
            open: false,
            order_no: '',
            remark: ''
        });
    }

    // -------------------------------- PRICE CALCULATION ---------------------------------------------------
    _.map(order?.order_details, (orderItem) => {
        if ((order?.status === 60 || orderItem.status) && _.size(orderItem.payment) > 0) {
            _.map(orderItem.payment, (paymentItem, paymentIndex) => {
                if (paymentIndex === "multi_wallet") {
                    let multiP = JSON.parse(orderItem.multi_pricing);
                    let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                    paymentIndex = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                }
                if (_.isUndefined(totalPrice[paymentIndex])) {
                    totalPrice[paymentIndex] = 0;
                }
                if (_.isUndefined(subtotalPrice[paymentIndex])) {
                    subtotalPrice[paymentIndex] = 0;
                }
                totalPrice[paymentIndex] += parseFloat(paymentItem);
                subtotalPrice[paymentIndex] += parseFloat(paymentItem);
            })
        }
    })

    return (
        <div style={{ marginTop: 0 }}>
            <div className='p-lr-40' style={{ marginTop: -60 }}>
                <div className='pos-relative flex-c-m'>
                    <div style={{ width: '100%', height: 170, borderRadius: 15, background: 'linear-gradient(99deg, #00B09B 8.4%, #96C93D 98.03%)', transform: 'rotate(0deg)' }} />
                    <div className='pos-absolute card-transparent flex-col-sb flex-col card-text txt-upper' style={{ height: 170, padding: '20px 30px' }}>
                        <div className='w-full'>
                            <p style={{ textTransform: 'uppercase', fontSize: 16 }}><b>{order?.buyer_name}</b></p>
                            <p style={{ textTransform: 'capitalize', fontSize: 14 }}>{order?.buyer_mobile}</p>
                        </div>
                        
                        <div className='p-t-10'>
                            <p className='fs-15' style={{ textTransform: 'capitalize' }}>{order?.full_address}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='p-lr-40 p-t-10'>
                <p style={{ fontSize: 20 }}>{`${order.status_display ? order.status_display : '-'}`}</p>
                <p>{t('order.id')}: {order.order_no}</p>
            </div> */}

            <div className='p-lr-40'>
                {
                    // trackingData ?
                    //     _.map(trackingData, (trackingDetails, index) => {
                    //         return (
                    //             <div key={index}>
                    //                 <Typography variant="h6" style={{ fontWeight: 'bold', paddingTop: 20 }}>{t('order.trackingNo')}: {trackingDetails.tracking_data.tracking_no}</Typography>
                    //                 {
                    //                     trackingDetails.tracking_data.logistic_parcel_status
                    //                         ? <Typography variant="caption">{t('order.trackingStatus') + ': ' + trackingDetails.tracking_data.logistic_parcel_status}</Typography>
                    //                         :
                    //                         trackingDetails.tracking_data.status_display
                    //                             ? <Typography variant="caption">{`${t('order.trackingStatus')}: ${trackingDetails.tracking_data.status_display}`}</Typography>
                    //                             : <Typography variant="caption">{`${t('order.trackingStatus')}: -`}</Typography>
                    //                 }
                    //             </div>
                                
                    //         )
                    //     })
                    //     : orderDetailsNoTracking || orderDetailsCancel || rateDone
                    //         ? orderDetailsNoTracking
                    //             ? <Typography variant="h6" style={{ fontWeight: 'bold', paddingTop: 20 }}>{t('order.processingItem')}</Typography>
                    //             : _.size(orderDetailsCancel) > 0
                    //                 ? <Typography variant="h6" style={{ fontWeight: 'bold', paddingTop: 20 }}>{t('order.cancelItem')}</Typography>
                    //                 : <>
                    //                     {
                    //                         _.map(order.order_details, (orderItem) => {
                    //                             if (_.size(orderItem.product_review) > 0) {
                    //                                 rateDone = true;
                    //                             }
                    //                         })
                    //                     }
                    //                     {
                    //                         rateDone ?
                    //                             <Button  color="secondary" variant="outlined" style={{ width: '100%', marginTop: 5 }} startIcon={<Stars fontSize="inherit" />} onClick={() => setShowRating(!showRating)}>
                    //                                 <Typography style={{ fontSize: 12 }}>{showRating ? t('order.hideRating') : t('order.showRating')}</Typography>
                    //                             </Button> : null
                    //                     }
                    //                 </>
                    //         : null
                }

                <Grid container>
                    <Grid item xs={12} className='p-t-20'>
                        <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                            <Storefront style={{ marginRight: 10 }} />
                            <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 18, marginLeft: 5 }}>
                                {order?.merchant_company?.[`shop_name_${i18n.language}`]}
                            </Typography>
                        </div>
                    </Grid>
                    {
                        _.size(order?.order_details) > 0 &&
                        _.map(order?.order_details, (detail, key) => {
                            let title_translate = _.split(detail.title, '|');
                            return (
                                <Grid item xs={12} className={styles.productDetailStyle} key={key}>
                                    <Box style={{ padding: 10 }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <WebpImg style={{ width: "100%", height: 93, borderRadius: 10, objectFit: 'cover' }} alt={detail.id} src={detail.image ? detail.image : '/images/logo.png'} />
                                                </Grid>
                                                <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '15px 10px 15px 0px' }}>
                                                    <Typography variant="caption" style={{ paddingBottom: 5, color: "#4E4E4E", overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{i18n.language === 'cn' ? title_translate[1] : title_translate[0]}</Typography>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div className={styles.productVariantStyle}>
                                                            {
                                                                _.size(detail.product_attribute_display) > 0 &&
                                                                _.map(detail.product_attribute_display, (variantText, i) => {
                                                                    if (_.includes(['color', 'size'], i)) {
                                                                        return (
                                                                            <Typography key={i} variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}&nbsp;</Typography>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                    <div style={{ paddingTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        {
                                                            _.size(detail.payment) > 0
                                                                ?
                                                                _.map(detail.payment, (paymentItem, paymentIndex) => {
                                                                    if (parseFloat(paymentItem) > 0) {
                                                                        return (
                                                                            <Box key={paymentIndex}>
                                                                                <Typography variant="caption" style={{ fontSize: 14 }}>
                                                                                    {priceDisplay(paymentItem, paymentIndex, detail)}
                                                                                </Typography>
                                                                            </Box>
                                                                        );
                                                                    }
                                                                })
                                                                :
                                                                <Typography variant="caption">
                                                                    <NumberFormat style={{ fontSize: 14 }} value={parseFloat(detail.price) * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                </Typography>
                                                        }
                                                        <Typography variant="body2" style={{ fontSize: 14 }}>x {detail.quantity}</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>

            {
                order?.remark &&
                <>
                    <Divider style={{ background: '#E8E8E8', height: 8, margin: '20px 0' }} />

                    <div className='p-lr-40'>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('checkout.remark')}</Typography>
                        <Typography variant="body2" style={{ textAlign: 'start' }}>{order.remark ? order.remark : '-'}</Typography>
                    </div>
                </>
            }
            
            <Divider style={{ background: '#E8E8E8', height: 8, margin: '20px 0' }} />

            {
                _.size(order?.order_shippings) > 0 ?
                    _.map(order?.order_shippings, shippingValue => {
                        let countShipping = false;
                        if (order?.status === 60) {
                            countShipping = true;
                        } else {
                            _.map(shippingValue.order_shipping_details, shipingDetail => {
                                _.map(order?.order_details, orderDetails => {
                                    if (orderDetails.id === shipingDetail.order_detail_id) {
                                        if (orderDetails.status) {
                                            countShipping = true;
                                        }
                                    }
                                })
                            })
                        }
                        if (countShipping) {
                            if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                let wid = shippingWalletId;
                                if (shippingValue.wallet_id > 0) {
                                    wid = shippingValue.wallet_id;
                                }
                                if (_.isUndefined(totalPrice[wid])) {
                                    totalPrice[wid] = 0;
                                }
                                if (_.isUndefined(totalShipping[wid])) {
                                    totalShipping[wid] = 0;
                                }
                                totalPrice[wid] += parseFloat(shippingValue.fee);
                                totalShipping[wid] += parseFloat(shippingValue.fee);
                            }
                        }
                    })
                    : null
            }

            <div className='p-lr-40'>
                <Grid container>
                    <Grid item xs={12} className='p-b-5'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('checkout.productPayment', { cartCount: _.size(order.order_details) })}</Typography>
                            {
                                _.size(totalPrice) ?
                                    _.map(subtotalPrice, (priceItem, priceIndex) => {
                                        if (parseFloat(priceItem) > 0) {
                                            return (
                                                <Typography key={priceIndex} variant="body2" style={{ textAlign: 'end' }}>
                                                    {priceDisplay(priceItem, priceIndex)}
                                                </Typography>
                                            )
                                        }
                                    })
                                    : <Typography variant="body2" style={{ textAlign: 'end' }}>
                                        <NumberFormat style={{ fontSize: 14 }} value={0} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                    </Typography>
                            }
                        </div>
                    </Grid>

                    <Grid item xs={12} className='p-b-5'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('order.shippingFee')}</Typography>
                            {
                                _.size(totalShipping) ?
                                    _.map(totalShipping, (shippingValue, shippingIndex) => {
                                        return (
                                            <Typography key={shippingIndex} variant="body2" style={{ textAlign: 'end' }}>
                                                {priceDisplay(shippingValue, shippingIndex)}
                                            </Typography>
                                        )
                                    })
                                    : <Typography variant="body2" style={{ textAlign: 'end' }}>
                                        {t('order.freeShipping')}
                                    </Typography>
                            }
                        </div>
                    </Grid>

                    <Grid item xs={12} className='p-b-5'>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>{t('checkout.totalPayment')}</Typography>
                            {
                                _.size(totalPrice) ?
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        {
                                            _.map(totalPrice, (priceItem, priceIndex) => {
                                                if (parseFloat(priceItem) > 0) {
                                                    return (
                                                        <Typography key={priceIndex} variant="body2" style={{ textAlign: 'end' }}>
                                                            {priceDisplay(priceItem, priceIndex)}
                                                        </Typography>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    : 
                                    <Typography variant="body2" style={{ textAlign: 'end' }}>
                                        <NumberFormat value={0} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                    </Typography>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className='p-lr-40'>
                {
                    (order.status === 35 || order.status === 40) ?
                        <Button fullWidth size="large" variant="contained" color="primary" className={styles.buttonDisplayStyle} onClick={() => confirmCompleted(order.order_no)}>
                            <Typography variant="body1">
                                {t('order.confirmCompleted')}
                            </Typography>
                        </Button>
                        : order.status === 20 ?
                            <Button fullWidth size="large" variant="contained" color="primary" className={styles.buttonDisplayStyle} onClick={() => handleCancelOrderOpen(order.order_no)}>
                                <Typography variant="body1">
                                    {t('order.cancelOrder')}
                                </Typography>
                            </Button>
                            : null
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    productDetailStyle: {
        width: "100%",
        borderRadius: 15,
        margin: '10px 0px',
        background: '#F4F4F4',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF',
    },
    productVariantStyle: {
        borderRadius: 5,
        background: '#EDEDED',
        boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 3px 0px #FFF',
        padding: '0px 10px',
        width: 180,
    },
    inputStyle: {
        textAlign: 'center',
        width: 20,
        height: 16,
        fontSize: 14,
        padding: 5,
    },
    buttonDisplayStyle: {
        marginTop: 20,
        background: theme.palette.primary.main,
        textTransform: 'capitalize',
        color: '#fff',
        borderRadius: 100,
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#000'
        }
    },
}));