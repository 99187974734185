import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";

// import configureStore from './configs/Store';
import { store, persistor } from './configs/Store';
import { PersistGate } from 'redux-persist/integration/react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import App from './components/App';
import theme from './Theme';
import { ENV } from './configs/Config';

import './i18n';

// const { store, persistor } = configureStore();

if (ENV !== "dev") {
    console.log = () => {};
}

ReactDOM.render(
    <Provider store={ store }>
        <PersistGate persistor={ persistor }>
            <ThemeProvider theme={ theme }>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>, 
    document.getElementById('root')
);