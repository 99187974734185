import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useTheme, Typography, Box, makeStyles } from '@material-ui/core';
import TitleBar from './Layouts/TitleBar';

import useNotificationLoading from '../helper/useNotificationLoading';
import { getUrl } from '../helper/ApiAction';

export default function Home() {
    const [subAccount, setSubAccount] = useState([]);
    const [selectedSubAccount, setSelectedSubAccount] = useState([]);
    const [contract, setContract] = useState();
    const [lotEntry, setLotEntry] = useState();
    const [totalActiveLot, setTotalActiveLot] = useState(0);
    
    const classes = useStyles();
    const { addAlert } = useNotificationLoading();
    const { t } = useTranslation();
    const theme = useTheme();
    const { username } = useParams();

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;

        getUrl('bind_account').then(response => {
            console.log("bind_account response", response);
            if(response.status && isMountedRef.current) {
                setSubAccount(response.data);
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })

        return () => isMountedRef.current = false;
    }, []);

    useEffect(() => {
        isMountedRef.current = true;

        if(username) {
            getUrl(`contract_orders/${username}`).then(response => {
                console.log("contract_orders response", response);
                if(response.status && isMountedRef.current) {
                    setContract(response.data[0]);
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            });

            getUrl(`lot_entries/${username}`).then(response => {
                console.log("lot_entries response", response);
                if(response.status && isMountedRef.current) {
                    const sumActiveLot = _.sumBy(response.data, (o) => {
                        return o.status === 20 ? o.lot_units : 0
                    })
                    setTotalActiveLot(sumActiveLot);
                    const orderredLotEntry = _.orderBy(response.data, ['id'], ['desc']);
                    setLotEntry(orderredLotEntry);
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            });
        }

        return () => isMountedRef.current = false;
    }, [addAlert, username]);

    useEffect(() => {
        if(_.size(subAccount) && username) {
            const newSelectedSubAccount = _.find(subAccount, { username });
            console.log(999, [subAccount, username, newSelectedSubAccount]);
            setSelectedSubAccount(newSelectedSubAccount);
        }
    }, [username, subAccount])

    return (
        <Box style={{background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}}>
            <TitleBar title={ t('title.contractDetails') } back>
                <Box display="flex" alignItems="center">
                    <Box className={classes.rankWrapperStyle}>
                        <Typography className={classes.rankTitleStyle}>{_.size(username) > 0 ? username[0] : '-'}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" marginLeft={2}>
                        <Typography className={classes.usernameStyle}>{username}</Typography>
                        <Typography className={ classes.infoStyle }>{_.size(selectedSubAccount) > 0 ? selectedSubAccount.mobile || '-' : '-'} <span className={ classes.dividerStyle }>|</span> {_.size(selectedSubAccount) ? selectedSubAccount.country || '-' : '-'} </Typography>
                    </Box>
                </Box>
            </TitleBar>

            <Box style={{ padding: '0 15px', marginTop:'-58px' }}>
                <Box style={{ backgroundColor: theme.palette.secondary.main, borderRadius: 15, padding:15 }}>
                    <Box style={{textAlign:'center', color: theme.palette.primary.white, paddingBottom:10, borderBottom:'1px dashed #fff'}}>
                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{ t('contract.currentContract', { contractName: contract ? contract.contract_name_display : '-' }) }</Typography>
                        <Typography style={{ fontSize: 12 }}>{ `${t('contract.joinedAt')}: ${contract ? contract.created_at : '-'}` }</Typography>
                    </Box>
                    <Box style={{paddingTop:10, display:'flex', justifyContent:'space-around'}}>
                        <Box style={{textAlign:'center', color:theme.palette.primary.white}}>
                            <Typography style={{ fontSize: 21, fontWeight: 'bold' }}>{ contract ? contract.contract.lot : '-' }</Typography>
                            <Typography style={{ fontSize: 12 }}>{ t('contract.totalLot') }</Typography>
                        </Box>
                        <Box style={{textAlign:'center', color:theme.palette.primary.white}}>
                            <Typography style={{ fontSize: 21, fontWeight: 'bold' }}>{ totalActiveLot || '-' }</Typography>
                            <Typography style={{ fontSize: 12 }}>{ t('contract.activeLot') }</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>


            <Box paddingBottom={20}>
                { _.map(lotEntry, lotItem => {
                    if(contract && (contract.id === lotItem.contract_order_id)){
                        return <LotEntry key={lotItem.id} data={lotItem} contract={contract} />
                    }
                })}
            </Box>
        </Box>
    );
}

const LotEntry = (props) => {
    const { data, contract } = props;
    const [lotWorth, setLotWorth] = useState(0);

    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if(contract && data) {
            const lotEntryWorth = (parseFloat(contract.amount) / contract.contract.lot) * data.lot_units;
            setLotWorth(lotEntryWorth);
        }
    }, [data, contract]);

    return (
        <Box style={{padding:'0 15px'}}>
            <Box style={{ borderRadius: 15, backgroundColor: theme.palette.primary.white, boxShadow: '0 0 6px 0 #dadada', padding: '15px 5%', margin: '20px 0' }}>
            <Box style={{ borderBottom: '1px solid #d8d8d8' }}>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '15px 0' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ backgroundColor: theme.palette.secondary.main, borderRadius: '50%', width: 45, height: 45, padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 25, color: theme.palette.primary.white, fontWeight: 'bold' }}>{ data.level }</Typography>
                        </div>
                        <Typography style={{ textAlign: 'center', paddingTop: 5, fontSize: 13, color: theme.palette.silver.text }}>{ t('lotEntry.level') }</Typography>
                    </div>
                    <div style={{ color: theme.palette.silver.text, paddingLeft: 20 }}>
                        {/* <Typography style={{ fontSize: 15, color: theme.palette.secondary.main }}>AMOUNT : 1000</Typography>
                        <Typography style={{ fontSize: 13 }}>BATCH QUEUED : 1</Typography>
                        <Typography style={{ fontSize: 13 }}>ACTIVE LOT : 1</Typography> */}
                        <Typography style={{ fontSize: 15, color:theme.palette.secondary.main }}>{ t('lotEntry.contractAmount', { amount: lotWorth.toFixed(2) }) }</Typography>
                        <Typography style={{ fontSize: 13 }}>{ t('lotEntry.batchQueueAmount', { 'amount' : data ? data.joined_at_batch : '-' }) }</Typography>
                        <Typography style={{ fontSize: 13 }}>{ t('lotEntry.activeLotAmount', { 'amount' : data ? data.lot_units : '-' }) }</Typography>
                    </div>
                </Box>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 10 }}>
                {/* <Typography style={{ whiteSpace: 'nowrap', fontSize: 14, color: theme.palette.secondary.main }}>Renew Now</Typography> */}
                <Box style={{ textAlign: 'right', color: theme.palette.silver.text, width: '100%' }}>
                    <Typography style={{ fontSize: 12 }}>{ t('lotEntry.joinedAt') }</Typography>
                    <Typography style={{ fontSize: 12 }}>{ data ? data.joined_at ?? '-'  : '-' }</Typography>
                </Box>
            </Box>
        </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 36,
        fontWeight:'bold',
        textTransform:'uppercase'
    },
    dividerStyle: {
        padding:'0 10px'
    },
    usernameStyle: {
        fontSize: 15, 
        color: theme.palette.secondary.main, 
        fontWeight: 'bold',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white, 
        fontSize: 11, 
        letterSpacing: 1 
    },
}))