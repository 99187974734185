export const ENV = 'liv'; // 'dev';
export const APP_NAME = 'HealthCare2U';
export const PROJECT_TYPE = 'mall'; // 'mall/catalog';

export const BASE_URL = ENV === 'liv' ? '' : '';

const BACKEND_URL_LIST = {
    liv: `https://api.healthcare2u.asia`,
    stage: `https://dapi.healthcare2u.asia`,
    dev: 'http://localhost:8000',
};

const LOCAL_URL_LIST = {
    liv: `https://www.healthcare2u.asia`,
    stage: `https://dmall.healthcare2u.asia`,
    dev: `http://localhost:3000`,
};

const SELLER_URL_LIST = {
    liv: 'https://seller.healthcare2u.asia/',
    stage: 'https://dseller.healthcare2u.asia/',
    dev: 'http://localhost:3005/',
};

export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const LOCAL_URL = LOCAL_URL_LIST[ENV];
export const SELLER_URL = SELLER_URL_LIST[ENV];

export const CLIENT_SECRET = 'EykDL0NJzr2GydtmeMnTC2DI5xjwRUAv6adPEBes';

export const API_URL = `${BACKEND_URL}/api/`;

export const EXTERNAL_MEMBER_SITE = ENV === 'liv' ? 'https://mib88.com/' : 'https://mib88.com/';
export const EXTERNAL_LOGIN = ENV === 'liv' ? 'https://mib88.com/live/login-authentication?src=mib_mall' : 'https://mib88.com/demo/login-authentication?src=mib_mall';
export const EXTERNAL_ABOUTUS = ENV === 'liv' ? 'https://mib88.com/' : 'https://mib88.com/';

export const UPAY_URL = ENV === 'liv' ? '' : 'https://uat.upay2us.com/iServeGateway/transaction_window';
export const PAYPAL_URL = ENV === 'liv' ? '' : 'https://www.paypal.com/sdk/js?client-id=ATmcw_7BZ11ecNZBgC8F-UHP93-aj2wuKEsgJV4EpGhxbH-TVlvCpxPecWEd-WgZ6L74vwWNayzzlqIh&disable-funding=credit,card&currency=USD';
export const STRIPE_KEY = ENV === 'liv' ? '' : '';

export const CONTACT_US_EMAIL = 'info@healthcare2u.asia';

export const PUSHER_KEY = ENV === 'liv' ? '300a8ddcd263bda445fc' : '4055f5d563df469ce07a';
export const PUSHER_CLUSTER = 'ap1';
export const PUSHER_AUTH = `${BACKEND_URL}/broadcasting/auth`;
export const PUSHER_MESSAGE_CHANNEL = 'chat';
export const PUSHER_MESSAGE_EVENT = 'MessageSent';

export const FACEBOOK_APPID = "";
export const DEFAULT_LANGUAGE = "en"; // "en"
export const DEFAULT_CURRENCY = "MYR"; // "USD"

export const LOGIN_SECRET_KEY = "VSgwUGPVQvN4z88s0hUVo9mb";
