import React, { useState, useRef, useEffect } from 'react';

import { CircularProgress, Grid, Typography, Box, Card, CardContent, Container, Paper, Tabs, Tab, Select, MenuItem, IconButton, Button, CardHeader, CardMedia, Tooltip, List, ListItemText, ListItemIcon, ListItem, Divider, Hidden, Link } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import NumberFormat from 'react-number-format';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getUrl } from '../helper/ApiAction';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import { List as ListIcon, ChevronRight, ChevronLeft } from 'react-feather';
import { useQuery } from '../helper/Tools';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import WebpImg from './Layouts/WebpImg';
import './css/styles.css';

export default function Shop() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    let { shopSlug } = useParams();
    let query = useQuery();
    const history = useHistory();
    const pageNumber = query.get("page");
    const catId = query.get("cat");
    const collectionId = query.get("collection");
    const sortBy = query.get("sort");

    const [state, setState] = useState({ tabIndex: 0 });

    const [shopInfo, setShopInfo] = useState({});
    const [shopCategory, setShopCategory] = useState({});
    const [shopCategoryProduct, setShopCategoryProduct] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [currencyDisplay, setBaseCurrencyDisplay] = useState('');
    const [review, setReview] = useState({});
    const [reviewSort, setReviewSort] = useState('all');
    const [reviewPage, setReviewPage] = useState(1);
    const [reviewTotalPage, setReviewTotalPage] = useState(1);

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/info`)
            .then(returnShopInfo => {
                setLoading(false);
                if (isMountedRef.current && returnShopInfo.status === 1) {
                    setShopInfo(returnShopInfo.data);
                } else {
                    addAlert(t('shop.invalidMerchant'));
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, setLoading, addAlert])

    useEffect(() => {
        if (collectionId && _.size(shopInfo.collections) > 0) {
            const selectedCollection = _.findKey(shopInfo.collections, { id: parseInt(collectionId) });
            if (selectedCollection) {
                setState({ ...state, tabIndex: parseInt(selectedCollection) + 2 });
            }
        }
        // eslint-disable-next-line
    }, [collectionId, shopInfo.collections])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/category`)
            .then(returnShopCategory => {
                setLoading(false);
                if (isMountedRef.current && returnShopCategory.status === 1) {
                    setShopCategory(returnShopCategory.data);
                } else {
                    addAlert(t('shop.invalidMerchant'));
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, setLoading, addAlert])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/products?cat=${catId ? catId : 'all'}&page=${pageNumber ? pageNumber : 1}&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`)
            .then(returnShopProduct => {
                setLoading(false);
                if (isMountedRef.current && returnShopProduct.status === 1) {
                    let { current_page, last_page } = returnShopProduct.data.meta;
                    setShopCategoryProduct(returnShopProduct.data.data);
                    setTotalPage(last_page);
                    setPage(current_page ? current_page : 1);

                    if (_.size(returnShopProduct.currency_data) > 0) {
                        // console.log("returnShopProduct.currency_data", returnShopProduct.currency_data);
                        setBaseCurrencyDisplay(returnShopProduct.currency_data[`currency_display_${i18n.language}`]);
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, catId, pageNumber, addAlert, setLoading, collectionId, sortBy])

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`shops/${shopSlug}/review?sort=${reviewSort}&page=${reviewPage}`)
            .then(reviewData => {
                console.log("reviewData", reviewData)
                if (isMountedRef.current) {
                    setLoading(false);
                    setReview(reviewData.data.data);
                    setReviewTotalPage(reviewData.data.last_page);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [shopSlug, reviewSort, reviewPage, setLoading, addAlert])

    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });
        if (newValue === 0) {
            history.push(`/shop/${shopSlug}`)
        } else if (newValue === 1) {
            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=&sort=${sortBy ? sortBy : 'popular'}`)
        } else if (newValue !== 999 && newValue > 1 && _.size(shopInfo.collections) > 0) {
            // collection page 
            const selectedCollection = shopInfo.collections[newValue - 2];
            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${selectedCollection.id}&sort=${sortBy ? sortBy : 'popular'}`)
        }
    };

    const renderReview = () => {
        return (
            <Card style={{ marginTop: 20, backgroundColor: '#FFFFFF' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={3}>
                            <Box >
                                <Typography style={{ fontWeight: 'bold', fontSize: 13, color:'#8c8c8c', textAlign:'center' }}>{t('shop.shopRating')}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-around" flexDirection="column" style={{ height: '100%', padding: '20px 0' }}>
                                <Box>
                                    {
                                        // _.size(positive_score) > 0 ?
                                        // <Typography color="primary" variant="h3" style={{ fontWeight: 'bold', textAlign: 'center' }}>{shopInfo.shop_average_review.positive_score}<span style={{fontSize:29, paddingLeft:5}}>%</span></Typography>
                                        // :
                                        // <Typography color="primary" variant="h3" style={{ fontWeight: 'bold', textAlign: 'center' }}>- <span style={{fontSize:29, paddingLeft:5}}>%</span></Typography>
                                    }
                                    <Typography color="primary" variant="h3" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        { _.size(shopInfo) > 0 ? _.size(shopInfo.shop_average_review.positive_score) > 0 ? shopInfo.shop_average_review.positive_score : 0 : 0 }<span style={{fontSize:29, paddingLeft:5}}>%</span>
                                    </Typography>
                                    
                                    <Typography style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'center', color:'#8c8c8c' }}>{t('shop.shopRating')}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center">
                                        <SentimentVeryDissatisfiedIcon style={{ color: theme.palette.gray.main }} />
                                        <Typography style={{ fontSize: 12 }}>{`(${shopInfo.shop_average_review.satisfaction_count[1]})`}</Typography>
                                    </Box>
                                    <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center">
                                        <SentimentSatisfiedIcon style={{ color: theme.palette.gray.main }} />
                                        <Typography style={{ fontSize: 12 }}>{`(${shopInfo.shop_average_review.satisfaction_count[2]})`}</Typography>
                                    </Box>
                                    <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center">
                                        <SentimentVerySatisfiedIcon style={{ color: theme.palette.gray.main }} />
                                        <Typography style={{ fontSize: 12 }}>{`(${shopInfo.shop_average_review.satisfaction_count[3]})`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9}>
                            {
                                _.size(review) > 0 ?
                                    <Box>
                                        <Box display="flex" flexDirection="column" paddingTop={2}>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>{t('shop.sellerRatingsReviews')}</Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="row">
                                            {
                                                _.map([3, 2, 1], satisfactionSort => {
                                                    return (
                                                        <Button
                                                            key={`${satisfactionSort}score`}
                                                            color={reviewSort === `${satisfactionSort}score` ? 'primary' : 'default'}
                                                            className={styles.buttonStyle}
                                                            style={{ borderColor: reviewSort === satisfactionSort ? theme.palette.primary.main : '#dadada' }}
                                                            onClick={() => {
                                                                setReviewPage(1)
                                                                setReviewSort(satisfactionSort)
                                                            }}
                                                        >
                                                            <Box display="flex" justifyContent="center" flexDirection="column">
                                                                {
                                                                    satisfactionSort === 3 ?
                                                                        <SentimentVerySatisfiedIcon style={{ color: reviewSort === 3 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                                        : satisfactionSort === 2 ?
                                                                            <SentimentSatisfiedIcon style={{ color: reviewSort === 2 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                                            : satisfactionSort === 1 ?
                                                                                <SentimentVeryDissatisfiedIcon style={{ color: reviewSort === 1 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                                                : null
                                                                }
                                                                {/* <Typography className={ styles.sortingTitle }>{`(${productReviewSummary.star_count[satisfactionSort]})`}</Typography> */}
                                                            </Box>
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </Box>

                                        <Box padding={2}>
                                            {
                                                _.map(review, reviewItem => {
                                                    return (
                                                        <Box key={reviewItem.id}>
                                                            <Box display="flex" alignItems="center">
                                                                {
                                                                    reviewItem.rating === 3 ?
                                                                        <SentimentVerySatisfiedIcon color="primary" fontSize="large" />
                                                                    : reviewItem.rating === 2 ?
                                                                        <SentimentSatisfiedIcon color="primary" fontSize="large" />
                                                                    : reviewItem.rating === 1 ?
                                                                        <SentimentVeryDissatisfiedIcon color="primary" fontSize="large" />
                                                                    : null
                                                                }
                                                                <Typography style={{ margin: 0, paddingLeft: 10 }}>{reviewItem.anonymous ? `${reviewItem.user.name[0]}*****` : reviewItem.user.name}</Typography>
                                                            </Box>
                                                            <Box marginTop={2}>
                                                                <Typography style={{ fontSize: 13, paddingBottom: 10 }}>
                                                                    {reviewItem.comment}
                                                                </Typography>
                                                            </Box>
                                                            {
                                                                _.size(reviewItem.response) > 0 ?
                                                                    <Box padding={2} borderRadius={10} marginBottom={1} style={{ backgroundColor: '#f1f1f1' }}>
                                                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                            <Typography className={styles.merchantReplyTitle}>{t('item.merchantReply')}</Typography>
                                                                            <Typography className={styles.merchantReplyTitle}>{reviewItem.updated_date_display}</Typography>
                                                                        </Box>
                                                                        <Typography style={{ fontSize: 13 }}>
                                                                            {reviewItem.response}
                                                                        </Typography>
                                                                    </Box>
                                                                    : null
                                                            }
                                                            <Typography style={{ margin: 0, fontSize: 13, color: '#989898' }}>{reviewItem.created_date_display}</Typography>

                                                            <Divider style={{ margin: '20px 0' }} />
                                                        </Box>
                                                    )
                                                })
                                            }
                                            <Box display="flex" justifyContent="center">
                                                <Pagination count={reviewTotalPage} shape="rounded" page={page}
                                                    onChange={(event, value) => setReviewPage(value)}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    :
                                    // <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
                                    //     <Typography variant="body2">{t('shop.noRatingReview')}</Typography>
                                    // </Box>
                                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >

                                        <WebpImg src="/images/general/nodata3.png" alt="noimage" style={{ width: '150px', maxWidth: '100%' }} />
                                        <Typography variant="body2">{t('shop.noRatingReview')}</Typography>
                                    </Box>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    const renderShopInfo = () => {
        return (
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Card style={{ backgroundColor: 'white', minHeight: 200, width: '100%' }}>
                    <CardHeader
                        title={t('shop.aboutShop')}
                        titleTypographyProps={{ style: { fontSize: 12, fontWeight: 'bold' } }}
                        style={{ paddingBottom: 0 }}
                    />
                    <CardContent style={{ paddingTop: 10 }}>
                        <Grid container>
                            {/* <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Grid item xs={12} sm={3}>
                                <Grid container justify="center">
                                    <Grid item xs={12} md={12}>
                                        <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                                            {
                                                _.size(shopInfo.merchant_company_icon) > 0 ?
                                                <WebpImg src={shopInfo.merchant_company_icon.file_name} style={{ height: 56, width: 56 }} alt={`${shopInfo[`shop_name_${i18n.language}`]}`}/>
                                                :
                                                <Avatar style={{ width: theme.spacing(7), height: theme.spacing(7), backgroundColor: theme.palette.gray.border }}>
                                                    <PersonOutlineIcon style={{ color: theme.palette.gray.main }} />
                                                </Avatar>

                                            }
                                            <Box marginLeft={3}>
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <StorefrontIcon style={{ fontSize: 22, marginRight: 5 }} />
                                                    <Typography style={{ fontSize: 14 }} className={styles.iconTitleStyle}>{t('shop.product')}:</Typography>
                                                    <NumberFormat color="primary" className={styles.iconDescStyle} style={{ color: theme.palette.primary.main }} value={shopInfo.total_product > 0 ? shopInfo.total_product : 0} displayType={'text'} thousandSeparator={true} />
                                                </Box>
                                                <Box display="flex" flexDirection="row" paddingTop={1} alignItems="center">
                                                    <Star style={{ marginRight: 5 }} size={22} />
                                                    <Typography style={{ fontSize: 14 }} className={styles.iconTitleStyle}>{t('shop.ratings')}:</Typography>
                                                    <Typography color="primary" className={styles.iconDescStyle}>{(shopInfo.shop_average_review ? shopInfo.shop_average_review.average_rating : 0).toFixed(1)}</Typography>
                                                </Box>
                                                <Box display="flex" flexDirection="row" paddingTop={1} alignItems="center">
                                                    <Clock style={{ marginRight: 5 }} size={22} />
                                                    <Typography style={{ fontSize: 14 }} className={styles.iconTitleStyle}>{t('shop.joined')}:</Typography>
                                                    <Typography color="primary" className={styles.iconDescStyle}>{shopInfo.joined_day > 31 ? shopInfo.joined_month : shopInfo.joined_day}</Typography>
                                                    <Typography className={styles.iconDescStyle}>{shopInfo.joined_day > 31 ? t('shop.joinedMonth') : t('shop.joinedDay')}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden> */}

                            {/* <Hidden only={['xs']}> */}
                            <Grid item xs={12} sm={12} md={5}>
                                <Grid container justify="center">
                                    <Grid item xs={12} md={12}>
                                        <Box display="flex" flexDirection="column" style={{ alignItems: 'center' }}>
                                            {
                                                _.size(shopInfo.merchant_company_icon) > 0 ?
                                                    // <WebpImg src={shopInfo.merchant_company_icon.file_name} style={{ height: 65, width: 65 }} alt={`${shopInfo[`shop_name_${i18n.language}`]}`}/>
                                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <WebpImg containerStyle={{ height: 120, maxHeight: 120, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={shopInfo.merchant_company_icon.file_name} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'contain', height: 120 }} alt={`${shopInfo[`shop_name_${i18n.language}`]}`} />
                                                    </Box>
                                                    :
                                                    // <Avatar style={{ width: theme.spacing(7), height: theme.spacing(7), backgroundColor: theme.palette.gray.border }}>
                                                    //     <PersonOutlineIcon style={{ color: theme.palette.gray.main }} />
                                                    // </Avatar>
                                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <WebpImg containerStyle={{ height: 120, maxHeight: 120, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src="/images/general/shop-nologo2.png" style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 120 }} alt="shoplogo-default" />
                                                    </Box>

                                            }


                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* </Hidden> */}
                            <Grid item xs={12} sm={12} md={7}>
                                <Typography color="primary" style={{ fontSize: 20, textAlign: 'center' }}>{shopInfo[`shop_name_${i18n.language}`]}</Typography>
                                <Typography variant="body1" style={{ fontSize: 12, textAlign: 'justify', textAlignLast: 'center' }}>
                                    {shopInfo[`about_${i18n.language}`]}
                                </Typography>
                                <Box marginTop={3} display="flex" alignItems="center" justifyContent="space-around" width="100%">
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box minHeight={38}>
                                            <NumberFormat color="primary" className={styles.iconDescStyle} style={{ color: theme.palette.primary.main, fontSize: 21, lineHeight: '39px' }} value={shopInfo.total_product > 0 ? shopInfo.total_product : 0} displayType={'text'} thousandSeparator={true} />
                                        </Box>
                                        <Box display="flex" alignItems="center" minHeight={30}>
                                            {/* <StorefrontIcon style={{ fontSize: 22, marginRight: 5 }} /> */}
                                            <Typography style={{ fontSize: 14, color: '#8c8c8c' }} className={styles.iconTitleStyle}>{t('shop.product')}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box display="flex" alignItems="center" minHeight={38}>
                                            <Typography color="primary" style={{ fontSize: 23 }} className={styles.iconDescStyle}>{ _.size(shopInfo) > 0 ? _.size(shopInfo.shop_average_review.positive_score) > 0 ? shopInfo.shop_average_review.positive_score : 0 : 0 }<span style={{fontSize:18, paddingLeft:5}}>%</span></Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" minHeight={30}>
                                            <Typography style={{ fontSize: 14, color: '#8c8c8c', textAlign:'center' }} className={styles.iconTitleStyle}>{t('shop.shopRating')}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box display="flex" alignItems="flex-end" minHeight={38}>
                                            <Typography color="primary" style={{ fontSize: 24, lineHeight:'1.5' }} className={styles.iconDescStyle}>{shopInfo.joined_day > 31 ? shopInfo.joined_month : shopInfo.joined_day}</Typography>
                                            <Typography style={{ fontSize: 14, paddingBottom:5 }} color="primary" className={styles.iconDescStyle}>{shopInfo.joined_day > 31 ? t('shop.joinedMonth') : t('shop.joinedDay')}</Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" minHeight={30}>
                                            {/* <UserCheck style={{ marginRight: 5 }} size={22} /> */}
                                            <Typography style={{ fontSize: 14, color: '#8c8c8c' }} className={styles.iconTitleStyle}>{t('shop.joined')}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>

                <Hidden only={['xs']}>
                    <Card style={{ backgroundColor: 'white', minHeight: 200, width: '300px', maxWidth: '100%', padding: 10, marginLeft: 15 }}>
                        <Box display="flex" flexDirection="column" style={{ height: '100%', justifyContent: 'center' }}>
                            <Box>
                            <Typography color="primary" variant="h3" style={{ fontWeight: 'bold', textAlign: 'center', padding: '10px 0', fontSize: '66px' }}>
                            { _.size(shopInfo) > 0 ? _.size(shopInfo.shop_average_review.positive_score) > 0 ? shopInfo.shop_average_review.positive_score : 0 : 0 }
                                 <span style={{fontSize:29, paddingLeft:5}}>%</span>
                            </Typography>
                            </Box>
                            <Typography style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'center', color:'#8c8c8c' }}>{t('shop.shopRating')}</Typography>
                        </Box>
                    </Card>
                </Hidden>
            </Box>
        )
    }

    return (
        <Box>
            {
                _.size(shopInfo.merchant_company_banners) > 0 ?
                    <AutoplaySlider
                        play={true}
                        cancelOnInteraction={false}
                        interval={3000}
                        bullets={false}
                        className="shop"
                    >
                        {
                            _.map(shopInfo.merchant_company_banners, bannerItem => {
                                return (
                                    <div key={bannerItem.id}>
                                        <WebpImg src={bannerItem.file_name} alt={bannerItem.file_name} style={{ objectFit: 'cover', display: 'block', width: '100%' }} containerStyle={{ height: '100%', width: '100%' }} />
                                    </div>
                                )
                            })
                        }
                    </AutoplaySlider>
                    :
                    <Box className="shop">
                        <WebpImg src="/images/general/shop-noimage4.png" alt="noimage" style={{ objectFit: 'cover', display: 'block', width: '100%' }} />
                    </Box>
                //     <AutoplaySlider
                //     play={true}
                //     cancelOnInteraction={false}
                //     interval={3000}
                //     bullets={false}
                //     className="shop"
                // >
                //      <div>
                //         <WebpImg src="/images/general/shop-noimage4.png" alt="noimage"/>
                //     </div>
                // </AutoplaySlider>
            }
            <Box>
                <Paper className={styles.paperRoot}>
                    <Tabs
                        value={state.tabIndex}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        style={{ backgroundColor: '#FFF' }}
                    >
                        <Tab label={t('shop.home')} disableRipple />
                        <Tab label={t('shop.allProduct')} disableRipple />
                        {
                            _.size(shopInfo.collections) > 0 ?
                                _.map(shopInfo.collections, (collectionItem, collectionIndex) => {
                                    return (
                                        <Tab key={collectionIndex} value={collectionIndex + 2} label={collectionItem[`title_${i18n.language}`]} disableRipple />
                                    )
                                })
                                : null
                        }
                        <Tab label={t('shop.info')} value={999} disableRipple />
                    </Tabs>
                    <Container style={{ padding: '24px 15px' }}>
                        {
                            state.tabIndex === 0 ?
                                renderShopInfo()
                                : null
                        }
                        <Hidden smDown>
                            {
                                state.tabIndex === 0 ?
                                    <>
                                        {
                                            _.size(shopInfo.collections) > 0 ?
                                                // only takes first 2
                                                _.map(_.slice(shopInfo.collections, 0, 2), collectionItem => {
                                                    // console.log("collectionItem", collectionItem)
                                                    return (
                                                        <Grid key={collectionItem.id} container style={{ paddingTop: 10 }}>
                                                            <Grid item xs={12}>
                                                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                                    <Typography style={{ fontSize: 18, fontWeight: 'bold', flex: 1 }}>{collectionItem[`title_${i18n.language}`]}</Typography>
                                                                    <Link underline='none' color="inherit" to={`/shop/${shopSlug}?cat=all&page=1&collection=${collectionItem.id}&sort=${sortBy ? sortBy : 'popular'}`} component={RouterLink}>
                                                                        <Typography color="primary" style={{ fontSize: 14, flex: 1 }}>{`${t('shop.seeAll')} >`}</Typography>
                                                                    </Link>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    {
                                                                        _.size(collectionItem.product_ids_array) > 0 ?
                                                                            _.map(_.slice(collectionItem.product_ids_array, 0, 6), productItem => {
                                                                                let showRetail = false;
                                                                                let discountPercent = 0;
                                                                                if (parseFloat(productItem.retail_price) > 0 && parseFloat(productItem.retail_price) > parseFloat(productItem.sell_price)) {
                                                                                    showRetail = true;
                                                                                    discountPercent = ((parseFloat(productItem.retail_price) - parseFloat(productItem.sell_price)) / parseFloat(productItem.retail_price)) * 100;
                                                                                    discountPercent = Math.round(discountPercent);
                                                                                }
                                                                                return (
                                                                                    <Grid item xs={6} sm={4} md={3} lg={2} key={productItem.id} className={styles.cardContentRootStyle}>
                                                                                        <Link underline='none' to={`/item/${productItem.id}`} component={RouterLink}>
                                                                                            <Card variant="outlined" style={{ borderRadius: '15px', position: 'relative' }}>
                                                                                                <CardMedia style={{ padding: 10 }}>
                                                                                                    {/* <WebpImg src={productItem ? productItem.images_array[0] : ''} style={{ height: 150 }} alt={productItem[`title_${i18n.language}`]} /> */}

                                                                                                    {/* <WebpImg containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={productItem ? productItem.images_array[0] : ''} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 195 }} alt={productItem[`title_${i18n.language}`]} /> */}
                                                                                                    <WebpImg containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={productItem ? productItem.images_array[0] : ''} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 195, maxHeight:150 }} alt={productItem[`title_${i18n.language}`]} />
                                                                                                </CardMedia>
                                                                                                <CardContent style={{ padding: '0 10px 24px' }}>
                                                                                                    <Tooltip title={i18n.language === 'cn' ? productItem['title_cn'] : productItem['title_en']}>
                                                                                                        <Typography variant="body2" color="textSecondary" className='txt_truncate2'>
                                                                                                            {i18n.language === 'cn' ? productItem['title_cn'] : productItem['title_en']}
                                                                                                        </Typography>
                                                                                                    </Tooltip>
                                                                                                    {
                                                                                                        showRetail ?
                                                                                                            <Typography align="center" variant="body2" style={{ fontSize: 10, height: 18, fontWeight: 'bold', textDecoration: 'line-through' }}>
                                                                                                                <NumberFormat value={productItem.retail_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                                                            </Typography>
                                                                                                            : <Typography style={{ height: 18 }}></Typography>
                                                                                                    }
                                                                                                    <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                                                                        <NumberFormat value={productItem.sell_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                                                    </Typography>
                                                                                                </CardContent>
                                                                                                {
                                                                                                    showRetail ?
                                                                                                        <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                                                            <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                                                                        </Box>
                                                                                                        : null
                                                                                                }
                                                                                                <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor:'#f75757' }}>
                                                                                                    {
                                                                                                        _.map(productItem.ipoint, (idata, key) => {
                                                                                                            if(idata.warehouse_id === 0){
                                                                                                                return (
                                                                                                                    <Typography key={key} style={{ color: '#fff', fontSize:16,  }} variant="body2"><NumberFormat value={ parseFloat(idata.ipoint) } decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </Box>
                                                                                            </Card>
                                                                                        </Link>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                            : null
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                                : null
                                        }
                                    </>
                                    : null
                            }
                        </Hidden>
                        {
                            state.tabIndex === 999 ?
                                <Box>
                                    {renderShopInfo()}
                                    {renderReview()}
                                </Box>
                                :
                                <Grid container style={{ paddingTop: 30 }}>
                                    <Hidden only='xs'>
                                        <Grid item sm={3} lg={2}>
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon style={{ minWidth: 20 }}>
                                                        <ListIcon color="#000" size={14} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('shop.category')} />
                                                </ListItem>
                                                <Divider />
                                                <Link underline='none' color="inherit" to={`/shop/${shopSlug}?cat=all&page=1&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`} component={RouterLink}>
                                                    <ListItem style={{ padding: '5px 30px 5px 30px' }}>
                                                        {
                                                            !catId || catId === 'all' ?
                                                                <ListItemIcon style={{ minWidth: 20 }}>
                                                                    <ChevronRight color={theme.palette.primary.main} size={14} />
                                                                </ListItemIcon>
                                                                : null
                                                        }
                                                        <Typography variant="body1" style={{ fontWeight: (!catId || catId === 'all') ? 'bold' : 'normal', fontSize: (!catId || catId === 'all') ? 14 : 12, color: (!catId || catId === 'all') ? theme.palette.primary.main : '#000' }}>{t('shop.allProduct')}</Typography>
                                                    </ListItem>
                                                </Link>
                                                {
                                                    _.size(shopCategory) > 0 ?
                                                        _.map(shopCategory, (categoryItem, categoryIndex) => {
                                                            return (
                                                                <Link key={categoryIndex} underline='none' color="inherit" to={`/shop/${shopSlug}?cat=${categoryIndex}&page=1&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`} component={RouterLink}>
                                                                    <ListItem style={{ padding: '5px 30px 5px 30px' }}>
                                                                        {
                                                                            catId === categoryIndex ?
                                                                                <ListItemIcon style={{ minWidth: 20 }}>
                                                                                    <ChevronRight color={theme.palette.primary.main} size={14} />
                                                                                </ListItemIcon>
                                                                                : null
                                                                        }
                                                                        <Typography variant="body1" style={{ fontWeight: catId === categoryIndex ? 'bold' : 'normal', fontSize: catId === categoryIndex ? 14 : 12, color: catId === categoryIndex ? theme.palette.primary.main : '#000' }}>{i18n.language === 'cn' ? categoryItem.name_cn : categoryItem.name_en}</Typography>
                                                                    </ListItem>
                                                                </Link>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </List>
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={9} lg={10} >
                                        <Hidden mdDown>
                                            <Grid container style={{ padding: '5px 15px 5px 15px', background: 'rgba(0, 0, 0, .05)' }}>
                                                <Grid item xs={12} lg={7}>
                                                    <Box display="flex" flex={3} flexDirection="row" alignItems="center" justifyContent="space-between">
                                                        <Typography variant="body1" style={{ fontSize: 12 }}>{t('shop.sortBy')}</Typography>
                                                        <Button
                                                            variant={!sortBy || sortBy === 'popular' ? 'contained' : 'outlined'}
                                                            color={!sortBy || sortBy === 'popular' ? 'primary' : 'default'}
                                                            size="medium"
                                                            disableElevation
                                                            onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=popular`)}
                                                        >
                                                            <Typography style={{ color: !sortBy || sortBy === 'popular' ? '#FFF' : '#000', fontSize: 12 }} variant="button">{t('shop.popular')}</Typography>
                                                        </Button>
                                                        <Button
                                                            variant={sortBy === 'latest' ? 'contained' : 'outlined'}
                                                            color={sortBy === 'latest' ? 'primary' : 'default'}
                                                            size="medium"
                                                            style={{ fontSize: 12 }}
                                                            disableElevation
                                                            onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=latest`)}
                                                        >
                                                            <Typography style={{ color: sortBy === 'latest' ? '#FFF' : '#000', fontSize: 12 }} variant="button">{t('shop.latest')}</Typography>
                                                        </Button>
                                                        <Button
                                                            variant={sortBy === 'topSales' ? 'contained' : 'outlined'}
                                                            color={sortBy === 'topSales' ? 'primary' : 'default'}
                                                            size="medium"
                                                            style={{ fontSize: 12 }}
                                                            disableElevation
                                                            onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=topSales`)}
                                                        >
                                                            <Typography style={{ color: sortBy === 'topSales' ? '#FFF' : '#000', fontSize: 12 }} variant="button">{t('shop.topSales')}</Typography>
                                                        </Button>
                                                        <Select
                                                            value={_.includes(['priceDesc', 'priceAsc'], sortBy) ? sortBy : "default"}
                                                            onChange={(event) => {
                                                                history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=${event.target.value}`)
                                                            }}
                                                            style={{ width: 200 }}
                                                        >
                                                            <MenuItem value="default" disabled>
                                                                <Typography style={{ fontSize: 12 }} variant="button">{t('shop.price')}</Typography>
                                                            </MenuItem>
                                                            <MenuItem value="priceDesc">
                                                                <Typography color={sortBy === 'priceDesc' ? 'primary' : 'initial'} style={{ fontSize: 12 }} variant="button">{t('shop.priceLowHigh')}</Typography>
                                                            </MenuItem>
                                                            <MenuItem value="priceAsc">
                                                                <Typography color={sortBy === 'priceAsc' ? 'primary' : 'initial'} style={{ fontSize: 12 }} variant="button">{t('shop.priceHighLow')}</Typography>
                                                            </MenuItem>
                                                        </Select>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={5}>
                                                    <Box display="flex" flexDirection="row" style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <Typography color="primary" style={{ fontSize: 12 }}>{page}</Typography>
                                                        <Typography style={{ fontSize: 12 }}>/{totalPage}</Typography>

                                                        <Box display='flex' flexDirection="row">
                                                            <IconButton
                                                                variant="contained"
                                                                size="medium"
                                                                component="span"
                                                                style={{ borderRadius: 0, margin: 1 }}
                                                                disabled={page === 1 ? true : false}
                                                                onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=${page - 1}&collection=123`)}
                                                            >
                                                                <ChevronLeft size={12} />
                                                            </IconButton>
                                                            <IconButton
                                                                variant="contained"
                                                                size="medium"
                                                                component="span"
                                                                style={{ borderRadius: 0, margin: 1 }}
                                                                disabled={page === totalPage ? true : false}
                                                                onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=${page + 1}&collection=123`)}
                                                            >
                                                                <ChevronRight size={12} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                        <Hidden lgUp>
                                            {/* <Box display="flex" flexDirection="row-reverse">
                                            <IconButton 
                                                variant="contained" 
                                                size="medium" 
                                                component="span" 
                                                onClick={ () => setDrawerOpen(true) }
                                            >
                                                <ChevronLeft size={12} />
                                            </IconButton>
                                        </Box> */}
                                            <Box display="flex" flexDirection="row">
                                                <Button
                                                    size="medium"
                                                    style={{ fontSize: 12, flex: 1 }}
                                                    disableElevation
                                                    onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=popular`)}
                                                >
                                                    <Typography color={!sortBy || sortBy === 'popular' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.popular')}</Typography>
                                                </Button>
                                                <Button
                                                    size="medium"
                                                    style={{ fontSize: 12, flex: 1 }}
                                                    disableElevation
                                                    onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=latest`)}
                                                >
                                                    <Typography color={sortBy === 'latest' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.latest')}</Typography>
                                                </Button>
                                                <Button
                                                    size="medium"
                                                    style={{ fontSize: 12, flex: 1 }}
                                                    disableElevation
                                                    onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=topSales`)}
                                                >
                                                    <Typography color={sortBy === 'topSales' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.topSales')}</Typography>
                                                </Button>
                                                <Button
                                                    size="medium"
                                                    style={{ fontSize: 12, flex: 1 }}
                                                    disableElevation
                                                    onClick={() => {
                                                        const priceSort = sortBy === 'priceDesc' ? 'priceAsc' : 'priceDesc';
                                                        history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=${priceSort}`)
                                                    }}
                                                    endIcon={
                                                        sortBy === 'priceDesc' ?
                                                            <KeyboardArrowDownIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                                                            : <KeyboardArrowUpIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                                                    }
                                                >
                                                    <Typography color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.price')}</Typography>
                                                </Button>
                                            </Box>
                                        </Hidden>
                                        <Grid container spacing={1}>
                                            {
                                                _.size(shopCategoryProduct) > 0 ?
                                                    _.map(shopCategoryProduct, categoryProduct => {
                                                        let showRetail = false;
                                                        let discountPercent = 0;
                                                        if (parseFloat(categoryProduct.retail_price) > 0 && parseFloat(categoryProduct.retail_price) > parseFloat(categoryProduct.sell_price)) {
                                                            showRetail = true;
                                                            discountPercent = ((parseFloat(categoryProduct.retail_price) - parseFloat(categoryProduct.sell_price)) / parseFloat(categoryProduct.retail_price)) * 100;
                                                            discountPercent = Math.round(discountPercent);
                                                        }
                                                        return (
                                                            <Grid item xs={6} sm={4} md={3} lg={2} key={categoryProduct.id} className={styles.cardContentRootStyle}>
                                                                <Link underline='none' to={`/item/${categoryProduct.id}`} component={RouterLink}>
                                                                    <Card variant="outlined" style={{ borderRadius: '15px', position: 'relative' }}>
                                                                        <CardMedia style={{ padding: 10 }}>
                                                                            {/* <WebpImg src={categoryProduct ? categoryProduct.images_array[0] : ''} style={{ height: 150 }} alt={categoryProduct[`title_${i18n.language}`]} /> */}
                                                                            <WebpImg containerStyle={{ height: 120, maxHeight: 120, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={categoryProduct ? categoryProduct.images_array[0] : ''} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 120, maxHeight:120 }} alt={categoryProduct[`title_${i18n.language}`]} />
                                                                        </CardMedia>
                                                                        <CardContent style={{padding:'0px 10px 24px'}}>
                                                                            <Tooltip title={i18n.language === 'cn' ? categoryProduct['title_cn'] : categoryProduct['title_en']}>
                                                                                <Typography variant="body2" color="textSecondary" className='txt_truncate2'>
                                                                                    {i18n.language === 'cn' ? categoryProduct['title_cn'] : categoryProduct['title_en']}
                                                                                </Typography>
                                                                            </Tooltip>
                                                                            {
                                                                                showRetail ?
                                                                                    <Typography align="center" variant="body2" style={{ fontSize: 10, height:18, fontWeight: 'bold', textDecoration: 'line-through' }}>
                                                                                        <NumberFormat value={categoryProduct.retail_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                                    </Typography>
                                                                                    : <Typography style={{ height: 18 }}></Typography>
                                                                            }
                                                                            <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                                                <NumberFormat value={categoryProduct.sell_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                            </Typography>
                                                                        </CardContent>
                                                                        {
                                                                            showRetail ?
                                                                                <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                                    <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                                                </Box>
                                                                                : null
                                                                        }
                                                                        <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor:'#f75757' }}>
                                                                            {
                                                                                _.map(categoryProduct.ipoint, (idata, key) => {
                                                                                    if(idata.warehouse_id === 0){
                                                                                        return (
                                                                                            <Typography key={key} style={{ color: '#fff', fontSize:16,  }} variant="body2"><NumberFormat value={ parseFloat(idata.ipoint) } decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </Card>
                                                                </Link>
                                                            </Grid>
                                                        )
                                                    })
                                                    :
                                                    <Box minHeight={200} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                                        {
                                                            shopCategoryProduct === null ?
                                                                <CircularProgress disableShrink />
                                                                :
                                                                <Typography variant="caption">{t('general.noProduct')}</Typography>
                                                        }
                                                    </Box>
                                            }
                                        </Grid>
                                        <Grid container spacing={1} justify="center" >
                                            <Pagination count={totalPage} shape="rounded" page={page}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        component={RouterLink}
                                                        to={`/shop/${shopSlug}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                        {...item}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }

                    </Container>
                </Paper>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'light',
    },
    paperRoot: {
        flexGrow: 1,
        borderWidth: 1,
        backgroundColor: 'transparent'
    },
    root: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'white',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    collectionList: {
        padding: '0px 10px 0px 10px',
    },
    collectionListFirst: {
        padding: '0px 10px 0px 0px',
    },
    collectionListLast: {
        padding: '0px 0px 0px 10px',
    },
    iconTitleStyle: {
        fontWeight: 'bold',
        fontSize: 12
    },
    iconDescStyle: {
        fontWeight: 'bold',
        fontSize: 12,
        marginLeft: 5
    },
    shopIcon: {
        objectFit: 'fill',
        height: 56,
        maxWidth: 102
    },
    buttonStyle: {
        width: '10%',
        backgroundColor: '#efefef',
        margin: '0px 10px',
        padding: '5px 0',
        borderRadius: 5,
        border: '1px solid'
    },

}));