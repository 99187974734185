import React, { useState, useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import _ from 'lodash';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails, Breadcrumbs, FormControl, Card, CardContent, Button, Select, MenuItem, Snackbar, Typography, Container, Grid, Tabs, Tab, AppBar, Link, Box, Hidden, Divider, List, ListItem, CardMedia, Input, InputAdornment, IconButton, Tooltip, Avatar, Dialog, DialogContent, DialogActions, ListItemText, } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

import { updateCartTotal } from '../actions';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import WebpImg from './Layouts/WebpImg';
import { getUrl, postUrl, deleteUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

const INITIAL_STATE = {
    tabIndex: 0,
    variant: 0,
    warehoustId: 0,
    multiPricingId: 0,
    wid: 0,
    open: false,
    error: false,
    quantity: 1,
    message: "",
    product_options: true,
    shippingRegionDialog: false,
    user_wishlist: false
};

export default function VoucherItem() {
    const [state, setState] = useState(INITIAL_STATE);
    const [product, setProduct] = useState({});
    const [review, setReview] = useState({});
    const [productReviewSummary, setProductReviewSummary] = useState({
        all_count: 0,
        average_rating: 0,
        comment_count: 0,
        total_sold: 0,
        star_count: {
            1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
        }
    });
    const [merchantCompany, setMerchantCompany] = useState({});
    // const [productShippingVariant, setProductShippingVariant] = useState({});
    const [recommended, setRecommended] = useState({});

    const [category, setCategory] = useState([]);
    const [oriPrice, setOriPrice] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [retailPrice, setRetailPrice] = useState(0);
    const [warehouse, setWarehouse] = useState([]);
    const [voucherHeader, setVoucherHeader] = useState([]);
    const [dialogStatus, setDialogStatus] = useState(false);

    const dispatch = useDispatch();
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    let history = useHistory();
    const [currencyDisplay, setBaseCurrencyDisplay] = useState('');

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        setState(INITIAL_STATE);
        setWarehouse([]);

        let pointList = [];
        getUrl(`point_list`)
        .then(response => {
            if(response.status) {
                pointList = response.data;
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        getUrl(`products/${id}`)
        .then(productDetail => {
            if (isMountedRef.current) {
                setLoading(false);
                if(productDetail.status) {
                    const { data, merchant_company_info } = productDetail;
                    // const { options } = data;
            
                    if(_.size(data.voucher_header) > 0) {
                        setVoucherHeader(data.voucher_header);
                    } else {
                        history.push(`/item/${id}`);
                    }

                    setBaseCurrencyDisplay(data[`currency_display_${i18n.language}`]);

                    if (_.size(data.images_array) <= 0) {
                        data['images_array'] = ['/images/no-image.png'];
                    }
                    
                    // filter product's points
                    let tempPoint = [];
                    let visible = false;
                    let filteredArray = [];
                    setState(state => ({ ...state, user_wishlist: data.user_wishlist }));
                    if(_.size(data.points) > 0){
                        tempPoint = [];
                        _.map(data.points, (point, key) => {
                            visible = false;
                            if(_.size(pointList) > 0){
                                _.map(pointList, visiblePoint => {
                                    if(visiblePoint.id === point.point_id){
                                        visible = true;
                                    }
                                })
                                if(visible){
                                    tempPoint[key] = point;
                                }
                            }
                        })
                        filteredArray = tempPoint.filter(item => item !== null);
                        data.points = filteredArray;
                    }

                    if (_.size(data.options) <= 0) {
                        setState(state => ({ ...state, product_options: false }));
                    } else {
                        // setProductShippingVariant({
                        //     weight: parseFloat(options) ? options[0]['volume_weight'] : 0,
                        //     length: parseFloat(options) ? options[0]['package_length'] : 0,
                        //     width: parseFloat(options) ? options[0]['package_width'] : 0,
                        //     height: parseFloat(options) ? options[0]['package_height'] : 0
                        // });

                        // filter options's points
                        _.map(data.options, (option,opKey) => {
                            if(_.size(option.points) > 0){
                                tempPoint = [];
                                _.map(option.points, (point, key) => {
                                    visible = false;
                                    if(_.size(pointList) > 0){
                                        _.map(pointList, visiblePoint => {
                                            if(visiblePoint.id === point.point_id){
                                                visible = true;
                                            }
                                        })
                                        if(visible){
                                            tempPoint[key] = point;
                                        }
                                    }
                                })
                                filteredArray = tempPoint.filter(item => item !== null);
                                data.options[opKey].points = filteredArray;
                            }
                        })
                    }

                    setRetailPrice(data.retail_price);
                    setProduct(data);
                    setMerchantCompany(merchant_company_info);
                    if (_.size(data)) {
                        setBaseCurrencyDisplay(data[`currency_display_${i18n.language}`]);
                    }

                    if (data.category_id) {
                        getUrl(`categories/${data.category_id}/breadcrumb`).then(categoryBreadcrumb => {
                            setCategory(categoryBreadcrumb.data);
                        })
                    }

                    setOriPrice(data.sell_price);
                    setSellPrice(data.sell_price);
                    if (_.size(data.multi_pricings) > 0) {
                        console.log("multipricing", data.multi_pricings);
                        setState(state => ({ ...state, multiPricingId: data.multi_pricings[0]['id'] }));
                    }
                    // if (_.size(data.multi_pricings) > 0) {
                    //     setState(state => ({ ...state, multiPricingId: data.multi_pricings[0]['id'] }));
                    //     setOriPrice(data.multi_pricings[0]['cash_amount']);
                    //     setSellPrice(data.multi_pricings[0]['cash_amount']);
                    // } else {
                    //     setOriPrice(data.sell_price);
                    //     setSellPrice(data.sell_price);
                    // }
                } else {
                    addAlert(JSON.stringify(t('general.invalidProduct')));
                }
            }
        }).catch((error) => {
            addAlert(JSON.stringify(t('general.invalidProduct')));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert, history]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`products/${id}/recommend`)
            .then(productDetail => {
                if (isMountedRef.current) {
                    setLoading(false);
                    const { data } = productDetail;
                    setRecommended(data);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, setLoading, addAlert]);

    const renderVideo = (item) => {
        return (
            <div className='video-wrapper'>
                <div className="iframe-container">
                    <iframe
                        className="responsive-iframe"
                        src={item.embedUrl}
                        frameBorder='0'
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </div>
        );
    }

    const generateImageSlide = ({ images_array, video, embed_video }) => {
        let imgList = [];

        if(_.size(video) > 0 || _.size(embed_video) > 0) {
            if(_.size(embed_video) > 0) video = embed_video;
            const defaultImage = _.size(images_array) > 0 ? images_array[0] : "/images/no-image.png";
            const videoProps = {
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
            }
            imgList.push({ 
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
                renderItem: () => renderVideo(videoProps),
            });
        }

        _.map(images_array, (url) => {
            imgList.push({ original: url, thumbnail: url });
        });
        console.log("imgList", imgList)
        return imgList;
    }

    const changeQuantity = condition => {
        let newQuantity = state.quantity;
        if (condition === 'deduct') {
            newQuantity = newQuantity === 1 ? 1 : parseInt(newQuantity) - 1;
        } else {
            newQuantity = parseInt(newQuantity) + 1;
        }
        setState({ ...state, quantity: newQuantity });
    }

    const priceDisplay = (optionId, optionMultiPricing) => {
        return (
            <>
            { (_.size(product.multi_pricings) > 0 && (!optionId || (optionId && _.size(optionMultiPricing) > 0))) ?
                <Select
                    fullWidth
                    value={state.multiPricingId ? state.multiPricingId : ''}
                    onChange={({ target }) => setState({ ...state, multiPricingId: target.value })}
                    inputProps={{ name: 'price' }}
                    style={{ paddingTop: 10 }}
                >
                    {
                        _.map(product.multi_pricings, (pricingItem, pricingIndex) => {
                            // let pricingText = `${currencyDisplay} ${sellPrice}`;
                            let name = _.split(pricingItem.wallet_name, '|');
                            let pricingText = parseFloat(pricingItem.cash_scale) > 0 ?`${currencyDisplay} ${parseFloat(sellPrice*pricingItem.cash_scale).toFixed(2)}` : '';
                            pricingText += (parseFloat(pricingItem.cash_scale) > 0 && parseFloat(pricingItem.wallet_amount) > 0) ? ' + ' : '';
                            pricingText += parseFloat(pricingItem.wallet_amount) > 0 ? `${parseFloat(pricingItem.wallet_amount).toFixed(pricingItem.decimal)} ${(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0])}` : '';
                            
                            let available = false;
                            if(optionId){
                                _.map(optionMultiPricing, (id) => {
                                    if(parseInt(pricingItem.id) === parseInt(id)){
                                        available = true;
                                    }
                                })
                            }else{
                                available = true;
                            }
                            if(available){
                                return (
                                    <MenuItem key={pricingIndex} value={pricingItem.id}>
                                        <Typography variant="subtitle1" style={{ paddingRight: 10, paddingLeft: 10, color: '#f36c48', fontWeight: 'bold', fontSize: 16 }}>{pricingText}</Typography>
                                    </MenuItem>
                                )
                            }
                        })
                    }
                </Select>
                :
                <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                    <NumberFormat value={sellPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 10, color: theme.palette.green.main, fontSize: 24, fontWeight: 'bold' }} prefix={currencyDisplay} />
                    {/* <Typography variant="subtitle1" style={{ paddingRight: 10, paddingLeft: 10, color: '#f36c48', fontWeight: 'bold', fontSize: 24 }}>{ `${currencyDisplay} ${sellPrice}` }</Typography> */}
                    {
                        parseFloat(product.retail_price) > parseFloat(product.sell_price) ?
                        <Box style={{ backgroundColor: theme.palette.primary.main, borderRadius: 5, padding: 5 }}><Typography style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>{`${isNaN(Math.round(((parseFloat(retailPrice) - parseFloat(sellPrice)) / parseFloat(retailPrice)) * 100)) ? '-' : Math.round(((parseFloat(retailPrice) - parseFloat(sellPrice)) / parseFloat(retailPrice)) * 100)}% ${t('general.discount')}`}</Typography></Box>
                        : null
                    }
                </Box> }
            </>
        )
    }

    const onButtonClick = condition => {
        const { variant, warehoustId, quantity, product_options } = state;
        let multiPricingId = state.multiPricingId;
        if (product_options) {
            if (!variant) {
                return setState({ ...state, open: true, error: true, message: t('item.selectVariantAddCart') });
            } else if (!warehoustId) {
                return setState({ ...state, open: true, error: true, message: t('item.selectWarehouseAddCart') });
            }
        }

        if (quantity <= 0) {
            return setState({ ...state, open: true, error: true, message: t('item.selectQuantityAddCart') });
        }

        if (_.size(product.multi_pricings) > 0) {
            if(multiPricingId){
                if(product_options && variant){
                    let available = false;
                    _.map(product.options, (option, key) => {
                        if(variant === option.id){
                            if(_.size(option.multi_pricing_id) > 0){
                                _.map(option.multi_pricing_id , id => {
                                    if(parseInt(multiPricingId) === parseInt(id)){
                                        available = true;
                                    }
                                })
                            }else{
                                multiPricingId = 0;
                                available = true;
                            }
                        }
                    })
                    if(!available){
                        return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
                    }
                }
            }else{
                return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
            }
        }

        if (condition === 'buynow') {
            if (accessToken) {
                postUrl(`carts`, {
                    product_id: id,
                    product_warehouse_id: warehoustId,
                    quantity,
                    multi_pricing_id: multiPricingId,
                    buyNow: true,
                }).then(response => {
                    if (response.status === 1) {
                        history.push('/checkout');
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                setState({ ...state, open: true, error: true, message: t('item.loginFirst') });
            }
        } else {
            console.log("post", { 
                product_id: id,
                product_warehouse_id: warehoustId,
                quantity,
                multi_pricing_id: multiPricingId
            })
            postUrl(`carts`, {
                product_id: id,
                product_warehouse_id: warehoustId,
                quantity,
                multi_pricing_id: multiPricingId
            }).then(response => {
                if (response.status === 1) {
                    getUrl(`carts`).then(cartList => {
                        let cartTotal = 0;
                        _.map(cartList.data, cartItem => {
                            cartTotal += parseInt(_.size(cartItem.items));
                        })
                        dispatch(updateCartTotal(cartTotal));
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                    setState({ ...state, open: true, error: false, message: t('item.addedToCart'), warehoustId: 0, variant: 0, quantity: 1, wid: 0 });
                    setWarehouse([]);
                } else {
                    setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                }
            }).catch((error) => {
                setState({ ...state, open: true, error: true, message: t('item.addedToCartFail') });
            });
        }
    }

    return (
        <div>
            <div style={{ backgroundColor: '#fff' }}>
                <Box padding={3}>
                    <WebpImg className={styles.imgStyle} alt={ product[`title_${i18n.language}`] } src={_.size(product.images_array) > 0 ? product.images_array[0] : '/images/logo.png'} />
                </Box>
                <Box style={{ padding: '0px 16px' }}>
                    <Box display='flex' alignItems="center" padding={1}>
                        { parseFloat(product.retail_price) > parseFloat(product.sell_price) ?
                            <NumberFormat value={retailPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 18, textDecoration: 'line-through' }} prefix={currencyDisplay} />
                            : null }
                        { _.size(product.options) > 0 && state.variant ?
                            _.map(product.options, (option) => {
                                if(option.id === state.variant){
                                    return (
                                        <Box key={option.id}>
                                            {priceDisplay(option.id, option.multi_pricing_id)}
                                        </Box>
                                    )
                                }
                            })
                            :
                            priceDisplay(0, [])
                        }
                    </Box>
                    <Box display='flex' alignItems="center" padding={1}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '20px' }}>{ product[`title_${i18n.language}`] }</Typography>
                    </Box>
                    <Box display='flex' alignItems="center" padding={1}>
                        <div dangerouslySetInnerHTML={{ __html: product[`descr_${i18n.language}`] }} />
                        {/* <Typography variant="subtitle1" style={{ fontSize: '14px' }}>{ product[`title_${i18n.language}`] }</Typography> */}
                    </Box>
                </Box>
            </div>

            <div style={{ backgroundColor: '#fff', marginTop: 10 }}>
                {
                    _.size(voucherHeader) > 0 ?
                    <Box style={{ padding: '10px 16px' }}>
                        <Box display='flex' alignItems="center" padding={1}>
                            <Avatar style={{ backgroundColor: theme.palette.primary.main, width: theme.spacing(4), height: theme.spacing(4) }}>
                                <CalendarTodayIcon fontSize="small" />
                            </Avatar>
                            <Typography variant="subtitle1" style={{ fontSize: 14, paddingLeft: 5 }}>{ t('voucher.redeemOfferFrom') }</Typography>
                            <Typography variant="subtitle1" color="primary" style={{ fontSize: 14, paddingLeft: 5 }}>{ voucherHeader.redeem_sdate }</Typography>
                            <Typography variant="subtitle1" style={{ fontSize: 14, paddingLeft: 5 }}>{ t('voucher.until') }</Typography>
                            <Typography variant="subtitle1" color="primary" style={{ fontSize: 14, paddingLeft: 5 }}>{ voucherHeader.redeem_edate }</Typography>
                        </Box>
                    </Box>
                    : null
                }
            </div>
            <div style={{ backgroundColor: '#fff', marginTop: 10 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{ t('voucher.packageOffer') }</Typography>
                    </AccordionSummary>
                    <Divider />
                    {
                        _.size(voucherHeader) > 0 ?
                        <AccordionDetails>
                            <Typography>
                                { voucherHeader.package_offer[i18n.language] }
                            </Typography>
                        </AccordionDetails>
                        : null
                    }
                </Accordion>
            </div>
            <div style={{ backgroundColor: '#fff', marginTop: 10 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>{ t('voucher.finePrint') }</Typography>
                    </AccordionSummary>
                    <Divider />
                    {
                        _.size(voucherHeader) > 0 ?
                        <AccordionDetails>
                            <Typography>
                                { voucherHeader.fine_print[i18n.language] }
                            </Typography>
                        </AccordionDetails>
                        : null
                    }
                </Accordion>
            </div>
            <div style={{ backgroundColor: '#fff', marginTop: 10 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>{ t('voucher.redemptionInstructions') }</Typography>
                    </AccordionSummary>
                    <Divider />
                    {
                        _.size(voucherHeader) > 0 ?
                        <AccordionDetails>
                            <Typography>
                                { voucherHeader.redeem_instruction[i18n.language] }
                            </Typography>
                        </AccordionDetails>
                        : null
                    }
                </Accordion>
            </div>
            <div style={{ backgroundColor: '#fff', marginTop: 10 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>{ t('voucher.photos') }</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ImageGallery
                            items={generateImageSlide(product)}
                            autoPlay={false}
                            onErrorImageURL="/images/no-image.png"
                            renderItem={(item) => {
                                return <WebpImg className='image-gallery-image' src={item.original} />
                            }}
                            renderThumbInner={(item) => {
                                return <WebpImg className='image-gallery-image' src={item.original} />
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div style={{ backgroundColor: '#fff', marginTop: 10 }}>
                <Box style={{ padding: '0px 16px' }}>
                    <Box display='flex' alignItems="center" paddingY={1}>
                        <Typography variant="subtitle1">{ t('voucher.similarDeals') }</Typography>
                    </Box>
                    <Box display='flex' flexDirection="column" alignItems="center" paddingY={1}>
                        {
                            _.map(recommended, (value, index) => {
                                const title_translate = _.split(value.title, '|');

                                let showRetail = false;
                                let discountPercent = 0;
                                if (parseFloat(value.retail_price) > 0 && parseFloat(value.retail_price) > parseFloat(value.sell_price)) {
                                    showRetail = true;
                                    discountPercent = ((parseFloat(value.retail_price) - parseFloat(value.sell_price)) / parseFloat(value.retail_price)) * 100;
                                    discountPercent = Math.round(discountPercent);
                                }

                                return (
                                    <div  key={index}>
                                    <Divider />
                                    <Link underline='none' to={`/item/${value.id}`} component={RouterLink}>
                                        {/* <Box display="flex" flexDirection="row" paddingY={1}>
                                            <Box flex={1} paddingRight={1} maxHeight={100}>
                                                <WebpImg className={styles.imgStyle} alt={i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]} src={value.images_array ? value.images_array[0] : ""} />
                                            </Box>
                                            <Box display="flex" flexDirection="column" flex={1} paddingLeft={1}>
                                                <Box display='flex' alignItems="center" padding={1}>
                                                    { parseFloat(product.retail_price) > parseFloat(product.sell_price) ?
                                                        <NumberFormat value={retailPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 12, textDecoration: 'line-through', color: "#000" }} prefix={currencyDisplay} />
                                                        : null }
                                                    <NumberFormat value={sellPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 16, color: theme.palette.primary.main, fontSize: 14, fontWeight: 'bold' }} prefix={currencyDisplay} />
                                                </Box>
                                                <Typography variant="subtitle1" style={{ color: '#000', fontSize: 14 }}>{i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}</Typography>
                                            </Box>
                                        </Box> */}
                                        <Card style={{ marginBottom: 10, borderRadius: 15, position: 'relative', borderWidth: 0 }} variant="outlined">
                                            <Box display="flex" flexDirection="row">
                                                <CardMedia style={{ padding: 10, flex: 2 }}>
                                                    <WebpImg containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={value.images_array ? value.images_array[0] : ""} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 195, maxHeight:150 }}/>
                                                </CardMedia>
                                                <CardContent style={{ paddingLeft: 2, flex: 3 }}>
                                                    <Box paddingY={1} display="flex" flexDirection="row" alignItems="center">
                                                        {
                                                            showRetail ?
                                                                <NumberFormat value={value.retail_price} decimalScale={2} displayType={'text'} style={{ fontSize: 12, fontWeight: 'bold', textDecoration: 'line-through', paddingRight: 5 }} thousandSeparator={true} prefix={currencyDisplay} />
                                                            : <Typography style={{ height: 18 }}></Typography>
                                                        }
                                                        <NumberFormat value={value.retail_price} decimalScale={2} displayType={'text'} style={{ fontSize: 12, fontWeight: 'bold', textDecoration: 'line-through', paddingRight: 5 }} thousandSeparator={true} prefix={currencyDisplay} />
                                                        <NumberFormat value={value.sell_price} decimalScale={2} displayType={'text'} color="secondary" style={{ fontWeight: 'bold', fontSize: 16, color: theme.palette.secondary.main }} thousandSeparator={true} prefix={currencyDisplay} />
                                                    </Box>
                                                    <Tooltip title={i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}>
                                                        <Typography variant="body2" color="textSecondary" className='txt_truncate2'>
                                                            {i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}
                                                        </Typography>
                                                    </Tooltip>
                                                </CardContent>
                                                {
                                                    showRetail ?
                                                        <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                            <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                        </Box>
                                                    : null
                                                }
                                            </Box>
                                        </Card>
                                        <Divider />
                                    </Link>
                                    </div>
                                )
                            })
                        }
                    </Box>
                </Box>
            </div>
            <Hidden only={['xs']}>
                <Box className={ styles.fixedVoucherBottomLarge }>
                    <Box display="flex" flexDirection="row">
                        <div className={ styles.bottomNavi }>
                            <NumberFormat value={sellPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 10, color: theme.palette.green.main, fontSize: 24, fontWeight: 'bold' }} prefix={currencyDisplay} />
                        </div>
                        <div className={ styles.bottomNavi }>
                            <Button onClick={ () => setDialogStatus(true) } size="large" color="primary" style={{ margin: 10 }} variant="contained" aria-label="plus">
                                <Typography variant="overline" style={{ color: '#fff' }}>{t('item.addToCart')}</Typography>
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Box className={ styles.fixedVoucherBottomSmall }>
                    <Box display="flex" flexDirection="row">
                        <div className={ styles.bottomNavi }>
                            <NumberFormat value={sellPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 10, color: theme.palette.green.main, fontSize: 24, fontWeight: 'bold' }} prefix={currencyDisplay} />
                        </div>
                        <div className={ styles.bottomNavi }>
                            <Button onClick={ () => setDialogStatus(true) } size="large" color="primary" style={{ margin: 10 }} variant="contained" aria-label="plus">
                                <Typography variant="overline" style={{ color: '#fff' }}>{t('item.addToCart')}</Typography>
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Hidden>
            <Dialog
                open={dialogStatus}
                onClose={() => setDialogStatus(false)}
                classes={{ container: styles.root, paper: styles.paper }}
            >
                <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Box paddingX={2}>
                        <Box display="flex" flexDirection="row">
                            <Box flex={1} paddingRight={1}>
                                <WebpImg className={styles.imgStyle} alt={ product[`title_${i18n.language}`] } src={_.size(product.images_array) > 0 ? product.images_array[0] : '/images/logo.png'} />
                            </Box>
                            <Box display="flex" flexDirection="column" flex={1} paddingLeft={1}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: 20 }}>{ product[`title_${i18n.language}`] }</Typography>
                                <Box display='flex' alignItems="center" padding={1}>
                                    { parseFloat(product.retail_price) > parseFloat(product.sell_price) ?
                                        <NumberFormat value={retailPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14, textDecoration: 'line-through' }} prefix={currencyDisplay} />
                                        : null }
                                    <NumberFormat value={sellPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 16, color: theme.palette.primary.main, fontSize: 18, fontWeight: 'bold' }} prefix={currencyDisplay} />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" paddingY={1}>
                            <Box display="flex" flexDirection="column" flex={1}>
                                {
                                    _.size(voucherHeader) > 0 ?
                                    <Box display='flex' alignItems="center">
                                        <Typography variant="subtitle1" style={{ fontSize: 14, paddingLeft: 5 }}>{ t('voucher.redeemOfferFrom') }</Typography>
                                        <Typography variant="subtitle1" color="primary" style={{ fontSize: 14, paddingLeft: 5 }}>{ voucherHeader.redeem_sdate }</Typography>
                                        <Typography variant="subtitle1" style={{ fontSize: 14, paddingLeft: 5 }}>{ t('voucher.until') }</Typography>
                                        <Typography variant="subtitle1" color="primary" style={{ fontSize: 14, paddingLeft: 5 }}>{ voucherHeader.redeem_edate }</Typography>
                                    </Box>
                                    : null
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Divider style={{ padding: 2 }} />
                    <Box display="flex" flexDirection="row" padding={2}>
                        <Box flex={2} flexGrow={1}>
                            <Typography variant="subtitle1" style={{ fontSize: 14, paddingLeft: 5 }}>{t('product.quantity')}</Typography>
                        </Box>
                        <Box flex={1} padding={1}>
                            <FormControl>
                                <Input
                                    type="text"
                                    value={state.quantity}
                                    disableUnderline={true}
                                    fullWidth={true}
                                    classes={{ input: styles.inputStyle }}
                                    onChange={({ target }) => {
                                        const newQuantity = parseInt(target.value) ? parseInt(target.value) : 1;
                                        setState({ ...state, quantity: newQuantity });
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconButton
                                                size="small"
                                                style={{ color: 'black', backgroundColor: theme.palette.background.default, borderRadius: 0 }}
                                                onClick={() => changeQuantity('deduct')}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                style={{ color: 'black', backgroundColor: theme.palette.background.default, borderRadius: 0 }}
                                                onClick={() => changeQuantity('add')}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Divider style={{ padding: 2 }} />

                    <Box display="flex" flexDirection="row">
                        <div className={ styles.bottomNavi }>
                            <Button onClick={() => onButtonClick("add")} size="large" style={{ margin: 10, backgroundColor: theme.palette.green.main }} variant="contained" aria-label="plus">
                                <Typography variant="overline" style={{ color: '#fff' }}>{t('item.addToCart')}</Typography>
                            </Button>
                        </div>
                        <div className={ styles.bottomNavi }>
                            <Button onClick={() => onButtonClick("buynow")} size="large" color="primary" style={{ margin: 10 }} variant="contained" aria-label="plus">
                                <Typography variant="overline" style={{ color: '#fff' }}>{t('item.buyNow')}</Typography>
                            </Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
            alignItems: "flex-end" // push the dialog to bottom
    },
    paper: {
        // make the content full width
            margin: 0,
            maxWidth: "100%",
            width: "100%",
            minHeight: "40%"
    },
    imgStyle: {
        height: '100%',
        width: '100%'
    },
    accordionRoot: {
        backgroundColor: '#fff', 
        marginTop: 10
    },
    bottomNavi: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    fixedVoucherBottomSmall: {
        backgroundColor: '#fff',
        minHeight: 50,
        position: 'fixed',
        bottom: 56,
        left: 0,
        right: 0,
        zIndex: 1000,
        borderTop: 1,
    },
    inputStyle: {
        textAlign: 'center',
        width: 100,
        fontSize: 14
    },
    fixedVoucherBottomLarge: {
        backgroundColor: '#fff',
        minHeight: 50,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        borderTop: 1,
    }
}));