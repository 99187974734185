import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

import { Container, Grid, Box, Typography, FormControl, TextField, ThemeProvider, Switch, Button } from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, styled } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import { StarRounded, StarBorderRounded, Storefront } from '@material-ui/icons';

import { getUrl, putUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import WebpImg from './Layouts/WebpImg';
import ImgHeader from './Layouts/ImgHeader';

import './css/styles.css';

const DEFAULT_ERROR_FIELD = { shop_rate: "", shop_review: "" };
const productLabels = {
    0: {'en': '', 'cn': ''},
    1: {'en': 'Poor', 'cn': '差'},
    2: {'en': 'Ok', 'cn': '还好'},
    3: {'en': 'Good', 'cn': '良好'},
    4: {'en': 'Great!', 'cn': '非常好'},
    5: {'en': 'Excellent!', 'cn': '大好'},
};
const shopLabels = {
    0: {'en': '', 'cn': ''},
    1: {'en': 'Poor', 'cn': '差'},
    2: {'en': 'Good', 'cn': '良好'},
    3: {'en': 'Excellent!', 'cn': '大好'},
};
const REVIEW_CHARACTER_LIMIT = 250;

export default function ReviewNew() {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    let history = useHistory();

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const { walletList: wallets } = useSelector(state => state.wallet);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);

    const [order, setOrder] = useState([]);
    const [currencyData, setCurrencyData] = useState(null);
    const [state, setState] = useState({
        shop_rate: 0,
        shop_review: '',
        anonymous: false,
    });
    const [review, setReview] = useState();
    const [rate, setRate] = useState();
    const [refreshData, setRefreshData] = useState(false);
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [shopInfo, setShopInfo] = useState({});
    const [shippingWalletId, setShippingWalletId] = useState([]);
    const [productHover, setProductHover] = useState();
    const [shopHover, setShopHover] = useState(-1);

    let currency_code = [];
    let totalPrice = {};
    let totalShipping = {};
    let subtotalPrice = {};
    let rateDone = false;

    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // --------------------------------- API -----------------------------------------------
    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`logistic/update_tracking_payment_parcel_status/${id}`).then(response => {
            // no need show update error for member
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert(msg, 'error');
        });

        getUrl(`orders/${id}`).then(orderList => {
            if (isMountedRef.current) {
                if (orderList.status === 1 && _.size(orderList.data) > 0) {
                    setCurrencyData(orderList.currency_data);
                    setOrder(orderList.data);

                    let totalPrice = 0;
                    _.map(orderList.data.order_details, order => {
                        totalPrice += parseFloat(order.price);
                    });
                    setOrder(order => ({ ...order, order_total: totalPrice }));

                    setShopInfo(orderList.data.merchant_company);
                    _.map(orderList.data.order_details, orderItem => {
                        setRate(rate => ({ ...rate, [orderItem.id]: 0 }));
                        setReview(review => ({ ...review, [orderItem.id]: '' }));
                        setProductHover(productHover => ({ ...productHover, [orderItem.id]: -1 }))
                    })

                    getUrl('shipping_fee/wallet').then(response => {
                        if (response.status) {
                            if (_.size(response.data) > 0) {
                                setShippingWalletId(response.data.id);
                            } else {
                                setShippingWalletId('cash');
                            }
                        } else {
                            addAlert(JSON.stringify(response.data));
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                } else {
                    addAlert(t('order.invalidOrder'));
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert, refreshData]);

    // --------------------------------- FUNCTION ------------------------------------------
    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if(paymentIndex === 'multi_wallet'){
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if(multiPricing){
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
            }else{
                prefix = "Other wallets";
            }
        }else
        if(paymentIndex !== 'cash' && !isNaN(paymentIndex)){
            _.map(wallets, wallet => {
                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                    let name = _.split(wallet.name, '|');
                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                    // decimal = wallet.decimal;
                }
            })
        }else if(paymentIndex === 'cash'){
            paymentItem = paymentItem * currencyRate[`${currency}`]
            prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
        }else{
            prefix = paymentIndex;
        }

        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix+' ' : ''} />
        )
    }

    function getProductLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${productLabels[value][i18n.language]}`;
    }

    function getShopLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${shopLabels[value][i18n.language]}`;
    }

    const submitRating = () => {
        const postParam = {
            shop_rate: state.shop_rate,
            shop_review: state.shop_review,
            anonymous: state.anonymous,
            rate,
            review
        };
        
        putUrl(`orders/review/${order.order_no}`, postParam).then(response => {
            if (response.status) {
                addAlert(t('order.ratingComplete'), 'success');
                setRate();
                setReview();
                setErrorField(DEFAULT_ERROR_FIELD);
                history.push('/order?type=completed');
            } else {
                if(response.errors) {
                    let newErrorField = errorField;
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if(response.data) {
                    addAlert(response.data);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box>
            <ImgHeader normalDisplay title={t('order.review')} menuButton={false} backUrl={'/order?type=completed'} />

            <Box className={styles.reviewPage}>
                <div className='p-lr-40 p-t-10'>
                    {
                        _.size(order) > 0 &&
                        _.map(order.order_details, (item, key) => {
                            let title_translate = _.split(item.title, '|');
                            return (
                                <div key={key}>
                                    <div className={styles.productDetailStyle} style={{ marginTop: 20 }}>
                                        <Box style={{ padding: '10px 0px' }}>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                <Storefront style={{ marginLeft: 20 }} />
                                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 18, marginLeft: 5 }}>{order.merchant_company[`shop_name_${i18n.language}`]}</Typography>
                                            </div>
                                        </Box>

                                        <Box style={{ padding: '5px 20px' }}>
                                            <Typography variant="caption" style={{ marginBottom: 10 }}>{t('order.id')} : {order.order_no}</Typography>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <WebpImg style={{ width: "100%", height: 93, borderRadius: 10, objectFit: 'cover' }} alt={item.id} src={item.image ? item.image : '/images/logo.png'} />
                                                    </Grid>
                                                    <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '15px 10px 15px 0px' }}>
                                                        <Typography variant="caption" style={{ paddingBottom: 5, color: "#4E4E4E", overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{i18n.language === 'cn' ? title_translate[1] : title_translate[0]}</Typography>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <div className={styles.productVariantStyle}>
                                                                {
                                                                    _.size(item.product_attribute_display) > 0 &&
                                                                    _.map(item.product_attribute_display, (variantText, i) => {
                                                                        if (_.includes(['color', 'size'], i)) {
                                                                            return (
                                                                                <Typography key={i} variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}&nbsp;</Typography>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                            <Typography variant="body2" style={{ fontSize: 14 }}>x {item.quantity}</Typography>
                                                        </div>

                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            {
                                                                _.size(item.payment) > 0
                                                                    ?
                                                                    _.map(item.payment, (paymentItem, paymentIndex) => {
                                                                        if (parseFloat(paymentItem) > 0) {
                                                                            return (
                                                                                <Box key={paymentIndex}>
                                                                                    <Typography variant="caption" style={{ fontSize: 14 }}>
                                                                                        {priceDisplay(paymentItem, paymentIndex, item)}
                                                                                    </Typography>
                                                                                </Box>
                                                                            );
                                                                        }
                                                                    })
                                                                    :
                                                                    <Typography variant="caption">
                                                                        <NumberFormat style={{ fontSize: 14 }} value={parseFloat(item.price) * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                    </Typography>
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Box>
                                    </div>

                                    <div>
                                        <Typography><b>{t('order.reviewTitle')}</b></Typography>
                                        <Typography style={{ color: '#CACACA' }}>{t('order.reviewQuestion')}</Typography>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Rating
                                                name={`rating_${item.id}`}
                                                value={rate?.[item.id] || 0}
                                                onChange={(event, newValue) => {
                                                    // setState({ ...state, shop_rate: newValue });
                                                    setRate(rate => ({ ...rate, [item.id]: newValue }));
                                                }}
                                                onChangeActive={(event, newHover) => {
                                                    setProductHover(productHover => ({ ...productHover, [item.id]: newHover }));
                                                }}
                                                size="large"
                                                getLabelText={getProductLabelText}
                                                style={{ fontSize: 50, color: '#49B991' }}
                                            />
                                            <Typography style={{ color: '#49B991' }}><b>
                                                {
                                                    productLabels[
                                                    productHover?.[item?.id] !== undefined && productHover?.[item?.id] !== -1
                                                        ? productHover?.[item?.id]
                                                        : rate?.[item?.id] !== undefined
                                                            ? rate?.[item?.id]
                                                            : 0
                                                    ][i18n.languages]
                                                }
                                            </b></Typography>
                                        </div>

                                        <ThemeProvider theme={accordingStyles}>
                                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                                <TextField
                                                    type="text"
                                                    variant="outlined"
                                                    value={review?.[item.id]}
                                                    name={`review_${item.id}`}
                                                    onChange={({ target }) => {
                                                        // setState({ ...state, shop_review: target.value });
                                                        setReview({ ...review, [item.id]: target.value });
                                                    }}
                                                    classes={{ root: styles.textFieldRoot }}
                                                    label={t('order.review')}
                                                    placeholder={t('order.leaveReview')}
                                                    fullWidth
                                                    inputProps={{ name: 'review', maxLength: REVIEW_CHARACTER_LIMIT }}
                                                    error={errorField.review ? true : false}
                                                    helperText={errorField.review}
                                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                                    multiline
                                                    rows={5}
                                                    style={{ borderRadius: 15 }}
                                                />
                                            </FormControl>
                                        </ThemeProvider>

                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <Typography style={{ color: '#CACACA' }}>{t('order.leftWords1')}&nbsp;</Typography>
                                            <Typography style={{ color: '#49B991' }}>{review?.[item.id] !== undefined ? (REVIEW_CHARACTER_LIMIT - review?.[item.id]?.length) : 0}</Typography>
                                            <Typography style={{ color: '#CACACA' }}>&nbsp;{t('order.leftWords2')}</Typography>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='p-lr-40 p-t-10'>
                    <div>
                        <Typography style={{ color: '#CACACA' }}>{t('order.reviewShop', {shopName: shopInfo[`shop_name_${i18n.language}`]})}</Typography>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Rating
                                name="shop_rating"
                                defaultValue={state.shop_rate}
                                value={state.shop_rate}
                                max={3}
                                precision={1}
                                getLabelText={getShopLabelText}
                                onChange={(event, newValue) => {
                                    setState({ ...state, shop_rate: newValue });
                                }}
                                onChangeActive={(event, newHover) => {
                                    setShopHover(newHover);
                                }}
                                style={{ fontSize: 50, color: '#49B991' }}
                            />
                            <Typography style={{ color: '#49B991' }}><b>{shopLabels[shopHover !== -1 ? shopHover : state.shop_rate][i18n.languages]}</b></Typography>
                        </div>

                        <ThemeProvider theme={accordingStyles}>
                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={state.shop_review}
                                    name={`shop_review`}
                                    onChange={({ target }) => {
                                        setState({ ...state, shop_review: target.value });
                                    }}
                                    classes={{ root: styles.textFieldRoot }}
                                    label={t('order.review')}
                                    placeholder={t('order.leaveReview')}
                                    fullWidth
                                    inputProps={{ name: 'revishop_reviewew', maxLength: REVIEW_CHARACTER_LIMIT }}
                                    error={errorField.shop_review ? true : false}
                                    helperText={errorField.shop_review}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    multiline
                                    rows={5}
                                    style={{ borderRadius: 15 }}
                                />
                            </FormControl>
                        </ThemeProvider>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Typography style={{ color: '#CACACA' }}>{t('order.leftWords1')}&nbsp;</Typography>
                            <Typography style={{ color: '#49B991' }}>{(REVIEW_CHARACTER_LIMIT - state.shop_review.length)}</Typography>
                            <Typography style={{ color: '#CACACA' }}>&nbsp;{t('order.leftWords2')}</Typography>
                        </div>
                    </div>
                </div>

                <div className='p-lr-40 p-t-20'>
                    <div className='p-t-20' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography className='fs-18'>{t('order.stayAnonymous')}</Typography>
                        <IOSSwitch sx={{ m: 1 }} onClick={() => setState({ ...state, anonymous: !state.anonymous })} checked={state.anonymous} />
                    </div>

                    <Button fullWidth size='large' className={styles.buttonDisplayStyle} style={{ marginTop: 20 }} onClick={submitRating}>
                        {t('button.submit')}
                    </Button>
                </div>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    reviewPage: {
        borderRadius: '30px 30px 0px 0px',
        background: '#F4F4F4',
        position: 'absolute',
        top: 150,
        width: '100%',
        paddingBottom: 130,
    },
    formControl: {
        padding: 5
    },
    textFieldRoot: {
        marginTop: 15,
    },
    merchantBoxStyle: {
        width: "100%",
        padding: '20px 28px',
        display: 'flex',
        alignItems: 'center',
    },
    productBoxStyle: {
        width: "100%",
        padding: '15px 28px', 
        display: 'flex',
        alignItems: 'center',
    },
    productDetailStyle: {
        width: "100%",
        borderRadius: 15,
        margin: '10px 0px',
        background: '#F4F4F4',
        boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05), -2px -2px 4px 0px #FFF',
    },
    productVariantStyle: {
        borderRadius: 5,
        background: '#EDEDED',
        boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 3px 0px #FFF',
        padding: '0px 10px',
        width: 180,
    },
    inputStyle: {
        textAlign: 'center',
        width: 20,
        height: 16,
        fontSize: 14,
        padding: 5,
    },
    buttonDisplayStyle: {
        background: theme.palette.primary.main, 
        textTransform: 'capitalize', 
        color: '#fff',
        borderRadius: 48,
        '&:hover': {
            color: '#000'
        }
    },
}));

const accordingStyles = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                justifyContent: 'flex-start',
                fontSize: 15,
                fontWeight: 400,
                textTransform: 'capitalize',
                borderRadius: 15,
                boxShadow: 'inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff',
                border: 'none',
            }
        }
    }
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 60,
    height: 30,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        backgroundImage: `linear-gradient(to bottom right, #D2D2D2, #8B8B8B)`,
        '&.Mui-checked': {
            transform: 'translateX(30px)',
            color: theme.palette.primary.light,
            '& + .MuiSwitch-track': {
                background: '#ededed',
                boxShadow: 'inset 2px 2px 6px 0 #00961926, 3px 4px 3px 0 #fff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 26,
        height: 26,
    },
    '& .MuiSwitch-track': {
        borderRadius: 30 / 2,
        background: '#ededed',
        boxShadow: 'inset 2px 2px 6px 0 #00000026, 3px 4px 3px 0 #fff',
        border: 0,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));