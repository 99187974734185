import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateCartTotal, updateShareLinkRef, updateShareLinkProduct } from '../actions';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import _ from 'lodash';
import { PROJECT_TYPE } from '../configs/Config';

import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Breadcrumbs, FormControl, Card, CardContent, Button, Select, MenuItem, Snackbar, Typography, Container, Grid, Tabs, Tab, AppBar, Link, Box, Hidden, Divider, List, ListItem, CardMedia, Input, InputAdornment, IconButton, Tooltip, Avatar, Dialog, DialogContent, DialogActions, ListItemText, Badge, Chip, Drawer, Modal } from '@material-ui/core';
import { FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

import { Rating, Alert } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
// import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Close, LinkOutlined } from '@material-ui/icons';
import { Share } from '@material-ui/icons';
import TitleBar from './Layouts/TitleBar';
import TitleBarTransparent from './Layouts/TitleBarTransparent';

import ImageGallery from 'react-image-gallery';
import "../image-gallery.css";
import { getUrl, postUrl, deleteUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { numberFormat, useQuery } from '../helper/Tools';
import './css/styles.css';
import util from'./css/util.css';
import WebpImg from './Layouts/WebpImg';
// import Question from './Question';
import Delivery from './Delivery';
import Review from './Review';
import TabPanel from './Layouts/TabPanel';
import { FormatListNumberedRtlOutlined } from '@material-ui/icons';
import { FACEBOOK_APPID } from '../configs/Config';
import { Helmet } from "react-helmet";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CloseIcon from '@material-ui/icons/Close';
import MemberButtonDrawer from './components/MemberButtonDrawer'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';


const INITIAL_STATE = {
    tabIndex: 0,
    variant: 0,
    warehoustId: 0,
    multiPricingId: 0,
    wid: 0,
    open: false,
    error: false,
    quantity: 1,
    message: "",
    product_options: true,
    // shippingRegionDialog: false,
    user_wishlist: false
};

export default function Item(props) {
    const [state, setState] = useState(INITIAL_STATE);
    const [product, setProduct] = useState({});
    const [review, setReview] = useState({});
    const [productReviewSummary, setProductReviewSummary] = useState({
        all_count: 0,
        average_rating: 0,
        comment_count: 0,
        total_sold: 0,
        star_count: {
            1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
        }
    });
    const [merchantCompany, setMerchantCompany] = useState({});
    const [recommended, setRecommended] = useState({});

    const [category, setCategory] = useState([]);
    const [oriPrice, setOriPrice] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [retailPrice, setRetailPrice] = useState(0);
    const [warehouse, setWarehouse] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [hideAddToCart, setHideAddToCart] = useState(false);
    const [addToCartSheet, setAddToCartSheet] = useState(false);
    const [sharePopup, setSharePopup] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [BasecurrencyDisplay, setBaseCurrencyDisplay] = useState('');
    const [shareLink, setShareLink] = useState(window.location.href.split('?')[0]);

    const dispatch = useDispatch();
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken, availableEvent2, shareLinkProductId, cartTotal:cartTotal2 } = useSelector(state => state.general);
    const { source, id:user_id, rank:user_rank, max_rank } = useSelector(state => state.user);
    let history = useHistory();
    const query = useQuery();
    const encoded_ref_id = query.get('r');
    const {currency,currencyRate,currencydisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencydisplay,(currency_detail,index)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
        
    });

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        setState(INITIAL_STATE);
        setWarehouse([]);

        let pointList = [];
        getUrl(`point_list`)
        .then(response => {
            if(response.status) {
                pointList = response.data;
            }
        })
        .catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        getUrl(`products/${id}`)
        .then(productDetail => {
            if (isMountedRef.current) {
                setLoading(false);
                if(productDetail.status) {
                    const { data, merchant_company_info, wallets:walletsName } = productDetail;

                    setWallets(walletsName);
                    
                    if(_.size(data.voucher_header) > 0) {
                        history.push(`/voucher/${id}`);
                    }


                    if (_.size(data.images_array) <= 0) {
                        data['images_array'] = ['/images/no-image.png'];
                    }
                    
                    // filter product's points
                    let tempPoint = [];
                    let visible = false;
                    let filteredArray = [];
                    setState(state => ({ ...state, user_wishlist: data.user_wishlist }));
                    if(_.size(data.points) > 0){
                        tempPoint = [];
                        _.map(data.points, (point, key) => {
                            visible = false;
                            if(_.size(pointList) > 0){
                                _.map(pointList, visiblePoint => {
                                    if(visiblePoint.id === point.point_id){
                                        visible = true;
                                    }
                                })
                                if(visible){
                                    tempPoint[key] = point;
                                }
                            }
                        })
                        filteredArray = tempPoint.filter(item => item !== null);
                        data.points = filteredArray;
                    }

                    if (_.size(data.options) <= 0) {
                        setState(state => ({ ...state, product_options: false }));
                    } else {
                        selectVariant(data.options[0]);
                        setWarehouse(warehouse => (data.options[0].pricing));
                        let priceModDisplay = 'price_mod';
                        if (_.size(data.options[0].pricing) === 1) {
                            setState({ ...state, variant: data.options[0].id, warehoustId: data.options[0].pricing[0].id });
                            if (parseFloat(data.options[0].pricing[0][priceModDisplay]) > 0) {
                                setSellPrice((parseFloat(data.options[0].pricing[0][priceModDisplay])).toFixed(2));
                            }
                            if (parseFloat(data.options[0].pricing[0].retail_price) > 0) {
                                setRetailPrice(data.options[0].pricing[0].retail_price);
                            }
                        }
                        if (_.size(data.options[0].pricing) > 0) {
                            _.map(data.options[0].pricing, warehouseOption => {
                                if (warehouseOption.warehouse_id === state.wid) {
                                    setState({ ...state,variant: data.options[0].id, warehoustId: warehouseOption.id });
                                    if (parseFloat(warehouseOption[priceModDisplay]) > 0) {
                                        setSellPrice((parseFloat(warehouseOption[priceModDisplay])).toFixed(2));
                                    }
                                    if (parseFloat(warehouseOption.retail_price) > 0) {
                                        setRetailPrice(warehouseOption.retail_price);
                                    }
                                }
                            })
                        }
                        _.map(data.options, (option,opKey) => {
                            if(_.size(option.points) > 0){
                                tempPoint = [];
                                _.map(option.points, (point, key) => {
                                    visible = false;
                                    if(_.size(pointList) > 0){
                                        _.map(pointList, visiblePoint => {
                                            if(visiblePoint.id === point.point_id){
                                                visible = true;
                                            }
                                        })
                                        if(visible){
                                            tempPoint[key] = point;
                                        }
                                    }
                                })
                                filteredArray = tempPoint.filter(item => item !== null);
                                data.options[opKey].points = filteredArray;
                            }
                        })
                    }

                    setRetailPrice(data.retail_price);
                    setProduct(data);
                    setMerchantCompany(merchant_company_info);
                    if (_.size(data)) {
                        setBaseCurrencyDisplay(data[`currency_display_${i18n.language}`]);
                    }

                    if (data.category_id) {
                        getUrl(`categories/${data.category_id}/breadcrumb`).then(categoryBreadcrumb => {
                            setCategory(categoryBreadcrumb.data);
                        })
                    }

                    let sellPrice = data.sell_price;
                    if(_.size(data.options) <= 0){
                        setOriPrice(sellPrice);
                        setSellPrice(sellPrice);
                    }
                    if (_.size(data.multi_pricings) > 0) {
                        setState(state => ({ ...state, multiPricingId: data.multi_pricings[0]['id'] }));
                    }
                    if(data.event_id_list.includes(String(data.category_id))){
                        setHideAddToCart(true);
                    }else{
                        setHideAddToCart(false);
                    }
                    
                } else {
                    addAlert(JSON.stringify(t('general.invalidProduct')));
                }
            }
        }).catch((error) => {
            addAlert(JSON.stringify(t('general.invalidProduct')));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert, history, user_rank]);

    useEffect(() => {
        if(user_id){
            setShareLink(`${window.location.href.split('?')[0]}?r=${btoa(user_id)}`);
        }
        // eslint-disable-next-line
    }, [user_id]);

    useEffect(() => {
        if(encoded_ref_id && !accessToken){
            dispatch(updateShareLinkRef(encoded_ref_id));
            dispatch(updateShareLinkProduct(id));
        }
        // eslint-disable-next-line
    }, [encoded_ref_id, accessToken, id]);

    useEffect(() => {
        if(accessToken && shareLinkProductId == id){
            dispatch(updateShareLinkProduct(""));
        }
        // eslint-disable-next-line
    }, [accessToken, id, shareLinkProductId]);

    useEffect(() => {
        if(_.size(product)){
            setBaseCurrencyDisplay(product[`currency_display_${i18n.language}`]);
        }
        // eslint-disable-next-line
    }, [i18n.language]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        // for mobile only
        getUrl(`reviews/${id}?per_page=2`)
            .then(reviewData => {
                if (isMountedRef.current) {
                    if(reviewData.status) {
                        setLoading(false);
                        setReview(reviewData.data.data);
                    } else {
                        addAlert(JSON.stringify(t('general.invalidProduct')));
                    }
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, setLoading, addAlert])

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`products/${id}/recommend`)
            .then(productDetail => {
                if (isMountedRef.current) {
                    setLoading(false);
                    const { data } = productDetail;
                    // console.log("recommend", data);
                    setRecommended(data);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, setLoading, addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl(`reviews/${id}/summary`)
            .then(reviewSummary => {
                if(reviewSummary.status) {
                    setProductReviewSummary(reviewSummary.data);
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, setLoading, addAlert])

    useEffect(() => {
        let disable = false;
        if(parseInt(product.category_id) === parseInt(product.event2_cat_id)){
            disable = availableEvent2?false:true;
        }
        setButtonDisable(disable);
        // eslint-disable-next-line
    }, [id, setLoading, addAlert, availableEvent2, product])

    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });
    };

    const handleChangeIndex = index => {
        setState({ ...state, tabIndex: parseInt(index) });
    };

    const onButtonClick = condition => {
        const { variant, warehoustId, quantity, product_options } = state;
        let multiPricingId = state.multiPricingId;
        if (product_options) {
            if (!variant) {
                return setState({ ...state, open: true, error: true, message: t('item.selectVariantAddCart') });
            } else if (!warehoustId) {
                return setState({ ...state, open: true, error: true, message: t('item.selectWarehouseAddCart') });
            }
        }

        if (quantity <= 0) {
            return setState({ ...state, open: true, error: true, message: t('item.selectQuantityAddCart') });
        }
        if (_.size(product.multi_pricings) > 0) {
            if(multiPricingId){
                if(product_options && variant){
                    let available = false;
                    _.map(product.options, (option, key) => {
                        if(variant === option.id){
                            if(_.size(option.multi_pricing_id) > 0){
                                _.map(option.multi_pricing_id , id => {
                                    if(parseInt(multiPricingId) === parseInt(id)){
                                        available = true;
                                    }else{
                                        setState({...state, multiPricingId : id});
                                        multiPricingId = parseInt(id);
                                        available = true;
                                    }
                                })
                            }else{
                                multiPricingId = 0;
                                available = true;
                            }
                        }
                    })
                    if(!available){
                        return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
                    }
                }
            }else{
                return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
            }
        }

        if (condition === 'buynow') {
            if (accessToken) {
                postUrl(`carts`, {
                    product_id: id,
                    product_warehouse_id: warehoustId,
                    quantity,
                    multi_pricing_id: multiPricingId,
                    buyNow: true,
                }).then(response => {
                    if (response.status === 1) {
                        history.push('/checkout');
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                // setState({ ...state, open: true, error: true, message: t('item.loginFirst') });
                postUrl(`carts`, {
                    product_id: id,
                    product_warehouse_id: warehoustId,
                    quantity,
                    multi_pricing_id: multiPricingId,
                    buyNow: true,
                }).then(response => {
                    if (response.status === 1) {
                        if(encoded_ref_id){
                            dispatch(updateShareLinkProduct(""));
                        }
                    } else {
                        setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                    }
                }).then(() => history.push('/cart')
                ).catch((error) => {
                    setState({ ...state, open: true, error: true, message: t('item.addedToCartFail') });
                });
            }
        } else {
            postUrl(`carts`, {
                product_id: id,
                product_warehouse_id: warehoustId,
                quantity,
                multi_pricing_id: multiPricingId
            }).then(response => {
                if (response.status === 1) {
                    if(PROJECT_TYPE === "catalog"){
                        getUrl('mystore/products').then(cartList => {
                            dispatch(updateCartTotal(cartList.data.total));
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }else{
                        getUrl(`carts`)
                            .then(cartList => {
                                let cartTotal = 0;
                                _.map(cartList.data, (cartItem, carttitle) => {
                                    if(carttitle !== 'allow_bank'){
                                        cartTotal += parseInt(_.size(cartItem.items.source));
                                    }
                                    
                                })
                                dispatch(updateCartTotal(cartTotal));
                            }).catch(error => {
                                addAlert(JSON.stringify(error.message));
                            });
                    }
                    setState({ ...state, open: true, error: false, message: t('item.addedToCart'), warehoustId: 0, variant: 0, quantity: 1, wid: 0 });
                    setWarehouse([]);

                    if(encoded_ref_id){
                        dispatch(updateShareLinkProduct(""));
                    }
                } else {
                    setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                }
            }).catch((error) => {
                setState({ ...state, open: true, error: true, message: t('item.addedToCartFail') });
            });
        }
    }

    const renderVideo = (item) => {
        return (
            <div className='video-wrapper'>
                <div className="iframe-container">
                    <iframe
                        className="responsive-iframe"
                        src={item.embedUrl}
                        frameBorder='0'
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </div>
        );
    }
    const windowSize = useRef([window.innerWidth, window.innerHeight.current]);
    const scrolled = document.documentElement.scrollTop
    const [visible,setVisible]=useState(false)
    useEffect(()=>{
        window.addEventListener('scroll', toggleVisible);
    },[])
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 75) {
            setVisible(true);
        }
        else if (scrolled <= 75) {
            setVisible(false);
        }
    };

    const selectVariant = ({ id, volume_weight, package_length, package_width, package_height, pricing }) => {
        setState({ ...state, variant: id, open: false });
        setProduct({
            ...product, variantInfo: {
                weight: parseFloat(volume_weight) ? volume_weight : 0,
                length: parseFloat(package_length) ? package_length : 0,
                width: parseFloat(package_width) ? package_width : 0,
                height: parseFloat(package_height) ? package_height : 0,
            }
        });
        setSellPrice(oriPrice);
    }

    const changeQuantity = condition => {
        let newQuantity = state.quantity;
        if (condition === 'deduct') {
            newQuantity = newQuantity === 1 ? 1 : parseInt(newQuantity) - 1;
        } else {
            newQuantity = parseInt(newQuantity) + 1;
        }
        setState({ ...state, quantity: newQuantity });
    }

    const lastIndex = _.findLastIndex(category);

    const chatWithMerchant = merchantId => {
        if(accessToken && merchantId) {
            postUrl(`chats`, {
                'merchant_id': merchantId,
            }).then(response => {
                if (response.status === 1) {
                    history.push('/chat');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const generateVariant = option => {
        let variant_txt = "";
        if(_.size(option.color) > 0) {
            variant_txt = `${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`;
        }
        
        if(_.size(option.size) > 0) {
            if(_.size(variant_txt) > 0) {
                variant_txt = `${variant_txt} ( ${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en )} )`;
            } else {
                variant_txt = `${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en )}`;
            }
        }

        if(_.size(option.attribute_value) > 0) {
            if(_.size(variant_txt) > 0) {
                variant_txt = `${variant_txt} ( ${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en )} )`;
            } else {
                variant_txt = `${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en )}`;
            }
        }

        return variant_txt;
    }
    const vpRewardDisplay = (reward_list,sell_price,multi_pricings,optionMultiPricing)=>{
        let total_vp_amount;
        let wallet_exist;
        if(reward_list.type == "percent"){
            total_vp_amount = sell_price * reward_list.amount /100;
        }else {
            total_vp_amount = reward_list.amount;
        }
        if (optionMultiPricing){
            const current_multi_pricings = _.find(optionMultiPricing, { id: multi_pricings});
            if (current_multi_pricings){
                let wallet_setting =[];
                wallet_setting = current_multi_pricings.wallet_settings;
                for (const wallet_detail of wallet_setting) {
                    const isFound = wallet_detail.wallet_ids.some(element => {
                        if (element === 6) {
                          return true;
                        }
                        return false;
                      });
                    if(isFound){
                        wallet_exist = true;
                    }
                }
            }
        }
        return (
                <Grid container spacing={0}>
                    {
                        (wallet_exist)
                        ?
                        null
                        : 
                        //<Grid item xs={9} md={10}>
                            <div style={{ marginLeft: 5, marginRight: 5, backgroundColor: theme.palette.primary.main, width:'100%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ffe3bf' }}>
                            <Typography variant="body1" className={styles.vpwithoutlinethrough}>{ t('product.vpreward', {'vp':parseFloat(total_vp_amount).toFixed(2) })} </Typography>
                            </div>
                           
                       //</Grid>
                    }
                </Grid>
        )
    }
    const priceDisplay = (optionId, optionMultiPricing) => {
        let sellPriceDisplay = product.sell_price;
        return (
            <>
            <ListItem>
                <Grid container spacing={1}>
                    <Grid item xs={3} >
                        <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('product.price')}</Typography>
                    </Grid>
                    {
                        (_.size(product.multi_pricings) > 0 && (!optionId || (optionId && _.size(optionMultiPricing) > 0)))
                        ?
                            <Grid item xs={9}  style={{ marginTop: -18 }}>
                                <Select
                                    fullWidth
                                    value={state.multiPricingId ? state.multiPricingId : ''}
                                    onChange={({ target }) => setState({ ...state, multiPricingId: target.value })}
                                    inputProps={{ name: 'price' }}
                                    style={{ paddingTop: 10 }}
                                >
                                    {
                                        _.map(product.multi_pricings, (pricingItem, pricingIndex) => {
                                            let pricingText = '';
                                            _.map(pricingItem.wallet_settings, (setting, key) => {
                                                let minAmount = parseFloat(setting.type === 'percent' ? sellPrice*(setting.min_amount/100) : setting.min_amount).toFixed(2);
                                                let maxAmount = parseFloat(setting.type === 'percent' ? sellPrice*(setting.max_amount/100) : setting.max_amount).toFixed(2);
                                                let label = setting.pricing_name + ' : ' + (minAmount + (setting.min_amount === setting.max_amount ? '' : (' - ' + maxAmount)));
                                                pricingText = pricingText ? (pricingText + ' + [ ' + label + ' ]') : ('[ ' + label + ' ]');
                                            })
                                            let available = false;
                                            if(optionId){
                                                _.map(optionMultiPricing, (id) => {
                                                    if(parseInt(pricingItem.id) === parseInt(id)){
                                                        available = true;
                                                    }
                                                })
                                            }else{
                                                available = true;
                                            }
                                            if (available) {
                                                return (
                                                    <MenuItem key={pricingIndex} value={pricingItem.id}>
                                                        
                                                        <Typography variant="subtitle1" style={{ paddingRight: 10, paddingLeft: 10, color: '#3C9DE5', fontWeight: 'bold', fontSize: 11, lineHeight:1.3 }}>{pricingText}</Typography>
                                                    </MenuItem>
                                                )
                                            }
                                        })
                                    }
                                </Select>
                            </Grid>
                        :
                        <Grid item xs={9} md={10}>
                            <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                                <NumberFormat value={sellPrice*currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 10, color: '#3C9DE5', fontSize: 24, fontWeight: 'bold' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                {
                                    parseFloat(product.retail_price) > parseFloat(sellPriceDisplay) ?
                                    <Box style={{ backgroundColor: 'red', borderRadius: 5, padding: 5 }}><Typography style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>{`${isNaN(Math.round(((parseFloat(retailPrice) - parseFloat(sellPrice)) / parseFloat(retailPrice)) * 100)) ? '-' : Math.round(((parseFloat(retailPrice) - parseFloat(sellPrice)) / parseFloat(retailPrice)) * 100)}% ${t('general.discount')}`}</Typography></Box>
                                    : null
                                }
                            </Box>
                        </Grid>
                    }
                </Grid>
            </ListItem>
            </>
        )
    }

    const likeProduct = () => {
        if (accessToken) {
            postUrl(`wishlist/product`, {
                product_id: id
            }).then(response => {
                if (response.status === 1) {
                    setProduct({... product,user_wishlist: true , wishlist_count:product.wishlist_count+1})
                    setState({ ...state, user_wishlist: true, open: true, error: false, message: t('item.productLiked') });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const dislikeProduct = () => {
        if (accessToken) {
            deleteUrl(`wishlist/product/${id}`).then(response => {
                if (response) {
                    if (isMountedRef.current) {
                        setProduct({... product,user_wishlist: false, wishlist_count:product.wishlist_count-1})
                        setState({ ...state, user_wishlist: false, open: true, error: false, message: t('item.productDislike') });
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    };

    const rewardDisplay = (reward) => {
        let availableSource = "all";
        if(reward[source]) availableSource = source;
        if(!reward[availableSource]) return null;
        let langIndex = 0;
        if(i18n.language == "cn") langIndex = 1;
        return (
            <ListItem>
                <Grid container spacing={1}>
                    <Grid item xs={3} md={3}>
                        <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('product.rewards')}</Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                        <Typography variant="subtitle1" className={styles.subtitleTypography}>
                        {   
                            Object.keys(reward[availableSource]['wallet_amount']).map(function ( key, index ) {
                                let walletName = _.split(wallets[key], "|");
                                return (
                                    <span key={index}>{index > 0 ? " + " : ""}{reward[availableSource]['wallet_amount'][key]}{reward[availableSource]['amount_type'] === "percent" ? "%" : ""} {walletName[langIndex] ? walletName[langIndex] : walletName[0]} </span>
                                )
                            })                        
                        }
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    const openshareFunction = () =>{
        setSharePopup(true)
    }

    const copyLinkFuction = () =>{
        const url = window.location.href;
        navigator.clipboard.writeText(url);
        let msg = t('product.copySuccess')
        addAlert(msg ,'success')
    }

    return(
        <Box className={styles.root2} >
            {visible == true?
            <div className='item'>
            <TitleBar height={70} title={t('title.products')} displayInfo currencyButton displayCart back backtext likeButton={true} likeFunction={() => likeProduct()} disLikeFuction={() => dislikeProduct()} liked={product.user_wishlist?product.user_wishlist:false} openshareFunction={openshareFunction} shareButton = {true} />
            </div>
            :
            <div className='item'>
            <TitleBarTransparent height={70} title={t('title.products')} displayInfo currencyButton displayCart back backtext likeButton={true} likeFunction={() => likeProduct()} disLikeFuction={() => dislikeProduct()} liked={product.user_wishlist?product.user_wishlist:false} openshareFunction={openshareFunction} shareButton = {true}/>
            </div>
            }
            {
                _.size(product) > 0 ?
                    <Box>
                        <Helmet>
                            <title>{i18n.language === 'cn' ? product.title_cn : product.title_en}</title>
                            <meta name="description" content={i18n.language === 'cn' ? product.title_cn : product.title_en} />
                            <meta name="og:url" content={window.location.href} />
                            <meta name="og:title" content={i18n.language === 'cn' ? product.title_cn : product.title_en} />
                            <meta name="og:description" content={i18n.language === 'cn' ? product.title_cn : product.title_en} />
                            {_.size(product.images_array) > 0 ?
                                <meta property="og:image" content={product.images_array[0]} />
                                : null}
                        </Helmet>
                        <Box style={{ backgroundColor: '#fff' }}>
                            <Grid item xs={12} style={{ width: '100%', maxWidth: '100%', padding: '0' }}>
                                <WebpImg className='image-gallery-image' src={_.size(product.images_array) > 0 ? product.images_array[0] : "/images/no-image.png"} alt="noimage" style={{ objectFit: 'cover', height: 370, width: 420, maxWidth: '100%', maxHeight: '100vw', borderBottomLeftRadius: 60, boxShadow: ' 0px 5px 15px rgba(0, 0, 0, 0.25)' }} />
                            </Grid>
                            <Container fixed className={styles.breadcrumbRoot} style={{ padding: 0 }}>
                                <Box bgcolor="white" className={styles.root} style={{ padding: 0 }}>
                                    <Grid container spacing={1} justify="center" style={{ maxWidth: '100%', margin: 0 }}>
                                        <Grid item xs={12}>
                                            <List>
                                                <div style={{ padding: '0 30px ', display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                                                    <Typography variant="subtitle1" className='txt_truncate3' style={{ fontWeight: '700', color: '#4E4E4E', fontSize: '15px', lineHeight: 'normal', height: 'unset' }}>{product[`title_${i18n.language}`]}</Typography>
                                                    {
                                                        parseFloat(product.retail_price) > parseFloat(product.sell_price) ?
                                                            <Box style={{ backgroundColor: 'transparent', maxWidth: '50px', borderRadius: 5, padding: 5, position: 'relative', top: -15, maxHeight: '55px' }}>
                                                                <BookmarkIcon style={{ color: '#FFBF42', fontSize: 90, position: 'relative', top: -17, right: 24 }} />
                                                                <Typography align="center" style={{ fontSize: 15, color: '#FFF', fontWeight: 'bold', position: 'absolute', top: 5 }}>{`${isNaN(Math.round(((parseFloat(product.retail_price) - parseFloat(product.sell_price)) / parseFloat(retailPrice)) * 100)) ? '-' : Math.round(((parseFloat(product.retail_price) - parseFloat(product.sell_price)) / parseFloat(product.retail_price)) * 100)}% ${t('general.discount')}`}</Typography>
                                                            </Box>
                                                            : null
                                                    }
                                                </div>
                                                <Box style={{ padding: '0 16px', marginTop: '20px' }}>
                                                    <Hidden only={['xs']}>
                                                        <Box display="flex" alignItems="center" justifyContent="space-between" padding={1} >
                                                            <Box flex={3} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >
                                                                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" style={{ width: '83px', backgroundColor: '#F4F4F4', height: '83px', boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px #FFF', borderRadius: "15px" }}>
                                                                    <Typography style={{ color: '#797979', textAlign: 'center', fontSize: '10px', textTransform: 'uppercase' }} >{t('item.ratingCount')}</Typography>
                                                                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.5, color: '#559884', textAlign: 'center' }}>{numberFormat(productReviewSummary.average_rating, 1)}</Typography>
                                                                </Box>

                                                                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" style={{ width: '83px', backgroundColor: '#F4F4F4', height: '83px', boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px #FFF', borderRadius: "15px", marginLeft: 30 }}>
                                                                    <Typography style={{ color: '#797979', textAlign: 'center', fontSize: '10px', textTransform: 'uppercase' }} >{t('item.likesCount')}</Typography>
                                                                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.5, color: '#559884', textAlign: 'center' }}>{product.wishlist_count}</Typography>
                                                                </Box>

                                                                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" style={{ width: '83px', backgroundColor: '#F4F4F4', height: '83px', boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px #FFF', borderRadius: "15px", marginLeft: 30 }}>
                                                                    <Typography style={{ color: '#797979', textAlign: 'center', fontSize: '10px', textTransform: 'uppercase' }} >{t('item.sold')}</Typography>
                                                                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.5, color: '#559884', textAlign: 'center' }}>{productReviewSummary.total_sold}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Hidden>
                                                </Box>
                                            </List>
                                            {
                                                state.open ?
                                                    <Snackbar
                                                        open={state.open}
                                                        autoHideDuration={3000}
                                                        onClose={() => setState({ ...state, open: false, error: false })}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    >
                                                        <Alert elevation={6} variant="filled" severity={state.error ? 'error' : 'success'}>
                                                            {state.message}
                                                        </Alert>
                                                    </Snackbar>
                                                    : null
                                            }

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container spacing={1} justify="center" style={{ maxWidth: '100%' }}>
                                    <Grid item xs={12} >
                                        <Box style={{ paddingLeft: 5 }}>
                                            <AppBar position="static" color="default" elevation={0} style={{ backgroundColor: 'transparent', paddingLeft: 20 }}>
                                                <Tabs
                                                    value={state.tabIndex}
                                                    onChange={handleChange}
                                                    variant={"fullWidth"}
                                                    scrollButtons="off"
                                                    indicatorColor="Primary"
                                                    textColor="Primary"
                                                    centered
                                                >
                                                    <Tab classes={{ root: styles.tab }} style={{ backgroundColor: state.tabIndex === 0 ? '#FFFFFF' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 'bold' }} label={t('item.detail')} />
                                                    <Tab classes={{ root: styles.tab }} style={{ backgroundColor: state.tabIndex === 1 ? '#FFFFFF' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 'bold' }} label={t('item.review')} />
                                                    <Tab classes={{ root: styles.tab }} style={{ backgroundColor: state.tabIndex === 2 ? '#FFFFFF' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 'bold' }} label={t('item.delivery')} />
                                                </Tabs>
                                            </AppBar>
                                            <SwipeableViews
                                                axis='x'
                                                index={state.tabIndex}
                                                onChangeIndex={handleChangeIndex}
                                            >
                                                <TabPanel value={state.tabIndex} index={0} style={{ marginBottom: '100px' }}>
                                                    <div dangerouslySetInnerHTML={{ __html: product[`descr_${i18n.language}`] }} />
                                                </TabPanel>
                                                <TabPanel value={state.tabIndex} index={1} style={{ marginBottom: '100px' }}>
                                                    <Review productId={id} />
                                                </TabPanel>
                                                <TabPanel value={state.tabIndex} index={2} style={{ marginBottom: '100px' }}>
                                                    <Delivery product={product} category={category} lastIndex={lastIndex} />
                                                </TabPanel>
                                            </SwipeableViews>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                        <Box className={styles.stickyFooter} style={{ textAlign: 'right', zIndex: 1299, minHeight: 0, height: 100 }}>
                            <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around ', padding: '0 20px 0 30px', height: '100%' }}>
                                {
                                    parseFloat(product.retail_price) > parseFloat(sellPrice) ?
                                        <Box style={{ backgroundColor: 'transparent' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <NumberFormat value={sellPrice * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 5, fontSize: 18, color: '#4E4E4E', fontWeight: 'bold' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                                                <NumberFormat value={retailPrice * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14, textDecoration: 'line-through' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                                            </div>
                                            {
                                                _.size(product.multi_pricings) > 0 ?
                                                    // hard code to choose first multi pricings
                                                    _.map(product.multi_pricings[0].wallet_settings, (wallet_detail, index) => {
                                                        let wallet_selected = wallets[wallet_detail.wallet_ids[0]]?.split('|');
                                                        if (index == 0 && wallet_selected) {
                                                            return (
                                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                    <NumberFormat value={wallet_detail.max_amount * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={`≈ ${i18n.language === 'cn' ? (wallet_selected[1] ? wallet_selected[1] : wallet_selected[0]) : wallet_selected[0]}`} fixedDecimalScale />
                                                                </div>
                                                            )
                                                        }

                                                    })
                                                    :
                                                    null
                                            }
                                        </Box>
                                        :
                                        <Box style={{ backgroundColor: 'transparent' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <NumberFormat value={sellPrice * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 5, fontSize: 18, color: '#4E4E4E', fontWeight: 'bold' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                                            </div>
                                            {
                                                _.size(product.multi_pricings) > 0 ?
                                                    // hard code to choose first multi pricings
                                                    _.map(product.multi_pricings[0].wallet_settings, (wallet_detail, index) => {
                                                        let wallet_selected = wallets[wallet_detail.wallet_ids[0]]?.split('|');
                                                        if (index == 0 && wallet_selected) {
                                                            return (
                                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                    <NumberFormat value={wallet_detail.max_amount * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, fontSize: 14 }} prefix={`≈ ${i18n.language === 'cn' ? (wallet_selected[1] ? wallet_selected[1] : wallet_selected[0]) : wallet_selected[0]}`} fixedDecimalScale />
                                                                </div>
                                                            )
                                                        }

                                                    })
                                                    :
                                                    null
                                            }
                                        </Box>
                                }
                                {
                                    hideAddToCart
                                        ?
                                        null
                                        :
                                        <Button size="small" className={styles.addCartStyle} onClick={() => setAddToCartSheet(true)} variant="contained" aria-label="plus" disabled={buttonDisable}>
                                            <Typography variant="overline" style={{ color: '#F4F4F4', textAlign: 'center', fontSize: '15px', fontWeight: '700' }}>{t('item.continue')}</Typography>
                                        </Button>
                                }
                                <MemberButtonDrawer open={addToCartSheet} onClose={() => setAddToCartSheet(false)}>
                                    <Grid container spacing={2} style={{ paddingLeft: 30, paddingRight: 10}}>
                                        <Grid item xs={12} style={{ marginTop: 30, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                                                <WebpImg className='image-gallery-image' src={_.size(product.images_array) > 0 ? product.images_array[0] : "/images/general/nodata3.png"} alt="noimage" style={{ objectFit: 'cover', height: 62, width: 62, maxWidth: '100%', maxHeight: '100vw', borderRadius: 10 }} />
                                                {
                                                    parseFloat(product.retail_price) > parseFloat(sellPrice) ?
                                                        <Box style={{ backgroundColor: 'transparent', marginLeft: 30, marginTop: 10 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <NumberFormat value={sellPrice * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 5, fontSize: 25, color: '#4E4E4E', fontWeight: 'bold' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                                                                <NumberFormat value={retailPrice * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, color: '#ADADAD', fontSize: 16, textDecoration: 'line-through' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                                                            </div>
                                                        </Box>
                                                        :
                                                        <Box style={{ backgroundColor: 'transparent', marginLeft: 30, marginTop: 10 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <NumberFormat value={sellPrice * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 5, fontSize: 18, color: '#4E4E4E', fontWeight: 'bold' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} fixedDecimalScale />
                                                            </div>
                                                        </Box>
                                                }
                                            </div>
                                            <Button onClick={() => setAddToCartSheet(false)}>
                                                <CloseIcon style={{ color: '#ADADAD' }}></CloseIcon>
                                            </Button>
                                        </Grid>
                                        {
                                            product.option ?
                                                <Grid item xs={12}>
                                                    <Typography className={styles.sheetTypo}>{t('product.combo')}</Typography>
                                                </Grid>
                                                : null
                                        }

                                        <div style ={{ overflowX: 'auto',overflowY: 'auto' , maxHeight:300}}>
                                            {
                                                _.map(product.options, (option_detail) => {
                                                    let priceModDisplay = 'price_mod';
                                                    if (option_detail.id == state.variant) {
                                                        return (
                                                            <Grid item xs={12} style={{marginBottom:10}}>
                                                                <Button variant='contained' style={{ backgroundColor: '#FFBF42', borderRadius: 10, boxShadow: '2px 2px 5px 0px rgba(255, 255, 255, 0.65), 2px 2px 4px 0px rgba(0, 0, 0, 0.06) inset', width: '370px', height: '70px', alignItems: 'center', justifyContent: 'flex-start' }}
                                                                    onClick={() => {
                                                                        selectVariant(option_detail);
                                                                        setWarehouse(warehouse => (option_detail.pricing));
                                                                        if (_.size(option_detail.pricing) === 1) {
                                                                            setState({ ...state, variant: option_detail.id, warehoustId: option_detail.pricing[0].id });
                                                                            if (parseFloat(option_detail.pricing[0][priceModDisplay]) > 0) {
                                                                                setSellPrice((parseFloat(option_detail.pricing[0][priceModDisplay])).toFixed(2));
                                                                            }
                                                                            if (parseFloat(option_detail.pricing[0].retail_price) > 0) {
                                                                                setRetailPrice(option_detail.pricing[0].retail_price);
                                                                            }
                                                                        } else {
                                                                            setRetailPrice(product.retail_price);
                                                                            setState(state => ({ ...state, variant: option_detail.id, warehoustId: 0 }));
                                                                        }

                                                                        if (_.size(option_detail.pricing) > 0) {
                                                                            _.map(option_detail.pricing, warehouseOption => {
                                                                                if (warehouseOption.warehouse_id === state.wid) {
                                                                                    setState({ ...state, variant: option_detail.id, warehoustId: warehouseOption.id });
                                                                                    if (parseFloat(warehouseOption[priceModDisplay]) > 0) {
                                                                                        setSellPrice((parseFloat(warehouseOption[priceModDisplay])).toFixed(2));
                                                                                    }
                                                                                    if (parseFloat(warehouseOption.retail_price) > 0) {
                                                                                        setRetailPrice(warehouseOption.retail_price);
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <img src={option_detail.image ? option_detail.image : "/images/no-image.png"} alt="header-bkg" className='w-full' style={{ height: '50px', width: '50px' }} />
                                                                    <Typography variant="caption" className='txt_truncate1' style={{ fontSize: 10, fontWeight: '500', maxHeight: '21px', paddingTop: '3px', paddingLeft: '10px' }}>{`${(i18n.language === 'cn' ? (option_detail.color_cn ? option_detail.color_cn : option_detail.color_en) : option_detail.color_en)}`}</Typography>
                                                                </Button>

                                                            </Grid>
                                                        )
                                                    } else {
                                                        return (
                                                            <Grid item xs={12} style={{marginBottom:10}}>
                                                                <Button variant='contained' style={{ backgroundColor: 'rgba(235, 235, 235, 0.76)', borderRadius: 10, boxShadow: '2px 2px 5px 0px rgba(255, 255, 255, 0.65), 2px 2px 4px 0px rgba(0, 0, 0, 0.06) inset', width: '370px', height: '70px', alignItems: 'center', justifyContent: 'flex-start' }}
                                                                    onClick={() => {
                                                                        selectVariant(option_detail);
                                                                        setWarehouse(warehouse => (option_detail.pricing));
                                                                        if (_.size(option_detail.pricing) === 1) {
                                                                            setState({ ...state, variant: option_detail.id, warehoustId: option_detail.pricing[0].id });
                                                                            if (parseFloat(option_detail.pricing[0][priceModDisplay]) > 0) {
                                                                                setSellPrice((parseFloat(option_detail.pricing[0][priceModDisplay])).toFixed(2));
                                                                            }
                                                                            if (parseFloat(option_detail.pricing[0].retail_price) > 0) {
                                                                                setRetailPrice(option_detail.pricing[0].retail_price);
                                                                            }
                                                                        } else {
                                                                            setRetailPrice(product.retail_price);
                                                                            setState(state => ({ ...state, variant: option_detail.id, warehoustId: 0 }));
                                                                        }

                                                                        if (_.size(option_detail.pricing) > 0) {
                                                                            _.map(option_detail.pricing, warehouseOption => {
                                                                                if (warehouseOption.warehouse_id === state.wid) {
                                                                                    setState({ ...state, variant: option_detail.id, warehoustId: warehouseOption.id });
                                                                                    if (parseFloat(warehouseOption[priceModDisplay]) > 0) {
                                                                                        setSellPrice((parseFloat(warehouseOption[priceModDisplay])).toFixed(2));
                                                                                    }
                                                                                    if (parseFloat(warehouseOption.retail_price) > 0) {
                                                                                        setRetailPrice(warehouseOption.retail_price);
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <img src={option_detail.image ? option_detail.image : "/images/no-image.png"} alt="header-bkg" className='w-full' style={{ height: '50px', width: '50px' }} />
                                                                    <Typography variant="caption" className='txt_truncate1' style={{ fontSize: 10, fontWeight: '500', maxHeight: '21px', paddingTop: '3px', paddingLeft: '10px' }}>{`${(i18n.language === 'cn' ? (option_detail.color_cn ? option_detail.color_cn : option_detail.color_en) : option_detail.color_en)}`}</Typography>
                                                                </Button>
                                                            </Grid>
                                                        )
                                                    }

                                                })
                                            }
                                        </div>
                                        <Grid item xs={3} md={3} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                            <Typography className={styles.sheetTypo}>{t('product.quantity')}</Typography>
                                        </Grid>
                                        <Grid item xs={9} md={9} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                            <FormControl>
                                                <Input
                                                    type="text"
                                                    value={state.quantity}
                                                    disableUnderline={true}
                                                    // fullWidth={true}
                                                    classes={{ input: styles.inputStyle }}
                                                    onChange={({ target }) => {
                                                        const newQuantity = parseInt(target.value) ? parseInt(target.value) : 1;
                                                        setState({ ...state, quantity: newQuantity });
                                                    }}
                                                    style={{boxShadow:'2px 2px 4px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px #FFF',backgroundColor:'#EBEBEB',  borderRadius: 50, width:'180px', marginRight:'15px'}}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <IconButton
                                                                style={{ color: 'black',backgroundColor: '#FFBF42',  borderRadius: 50 }}
                                                                onClick={() => changeQuantity('deduct')}
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                style={{ color: 'black',backgroundColor: '#FFBF42',  borderRadius: 50 }}
                                                                onClick={() => changeQuantity('add')}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {
                                            hideAddToCart
                                                ?
                                                null
                                                :
                                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between' , alignItems:'center', width:'100%', marginBottom: 30}}>
                                                    <Button size="small" className={styles.addCartStyle} onClick={() => onButtonClick("add")} variant="contained" aria-label="plus" disabled={buttonDisable} style={{ width: '100%', marginRight: 30 }}>
                                                        <Typography variant="overline" style={{ color: '#F4F4F4', textAlign: 'center', fontSize: '15px', fontWeight: '700' }}>{t('item.addToCart')}</Typography>
                                                    </Button>
                                                    <Button size="small" className={styles.addCartStyle} onClick={() => onButtonClick("buynow")} variant="contained" aria-label="plus" disabled={buttonDisable} style={{ width: '100%', marginRight: 30 }}>
                                                        <Typography variant="overline" style={{ color: '#F4F4F4', textAlign: 'center', fontSize: '15px', fontWeight: '700' }}>{t('item.buyNow')}</Typography>
                                                    </Button>
                                                </div>
                                                
                                        }
                                    </Grid>
                                </MemberButtonDrawer>
                                <MemberButtonDrawer open={sharePopup} onClose={() => setSharePopup(false)}>
                                    <Grid container style={{ paddingLeft: 40 , marginBottom:'50px' }}>
                                        <Grid item xs={12} style={{ marginTop: 30, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-end', width: '100%' }}>
                                            <Button onClick={() => setSharePopup(false)}>
                                                <CloseIcon style={{ color: '#ADADAD' }}></CloseIcon>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{color:'#2E523C' , fontSize:'22px' , fontWeight:'bold' }}>Share</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} style={{paddingRight:30}}>
                                                <Grid item xs={6} md={3} onClick={() => copyLinkFuction()} style={{ cursor: 'pointer'}}>
                                                    <div style={{ backgroundColor: theme.palette.primary.main, height: '66px', width: '66px', borderRadius:20, display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px'}}>
                                                        <LinkOutlinedIcon style={{color:'white', fontSize:'55px'}}></LinkOutlinedIcon>
                                                    </div>
                                                    <Typography style={{marginTop:'10px'}}>
                                                        {t('product.copyLink')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>

                                    </Grid>
                                </MemberButtonDrawer>

                            </Box>
                        </Box>
                    </Box>
                :
                null
            }
           
        </Box>
    );
}

const fieldStyle = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paperAnchorBottom: {
                top: 'auto',
                left: 0,
                right: 0,
                bottom: 0,
                height: 'auto',
                maxHeight: '100%',
                maxWidth: '420px',
                overflow: 'hidden',
                margin:'0 auto',
                borderRadius:'30px 30px 0px 0px'
            }
        },
    },
});

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    tab: {
        minWidth: '20%', // a number of your choice
        width: '30%', // a number of your choice
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    iconButton: {
        padding: 0,
        borderRadius: 0,
    },
    input: {
        width: 100,
        textAlign: 'center',
    },
    subtitleTypography: {
        paddingRight: 10,
        paddingLeft: 10,
        fontSize: 12
    },
    vpwithoutlinethrough: {
        color: '#fff', 
        padding:'3px 20px', 
        textAlign: 'center' 
    },
    inputStyle: {
        textAlign: 'center',
        width: 100,
        fontSize: 24
    },
    addCartStyle: {
        margin: 10,
        borderRadius: 15,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        maxHeight:50,
    },
    buyNowStyle: {
        margin: 10,
        borderRadius: 30,
        borderWidth: 2,
    },
    paddingVertical2: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%',
    },
    badgeStyle: {
        backgroundColor: theme.palette.background.default,
        color: "#000000"
    },
    chipStyle: {
        backgroundColor: 'red',
        color: 'white'
    },
    stickyFooter: {
        width: '100%',
        maxWidth: 420,
        position: 'fixed',
        // Top: 'calc(5% + 60px)',
        bottom: 0,
        backgroundColor:'#f2f2f2',
        boxShadow: '0 -5px 11px 0 #0000001a',
        zIndex: 1,
        minHeight: 70,
    },
    sheetTypo:{
        fontSize: 12, 
        color: '#4E4E4E', 
        fontWeight: 'bold'
    }
}));