import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import _ from 'lodash';

import { Avatar, Box, Button, BottomNavigationAction, BottomNavigation, Card, CardHeader, CardContent, Container, Divider, Dialog, DialogActions, DialogContent, DialogTitle,
         Grid,InputAdornment, IconButton, TextField, Typography, Link, ThemeProvider
} from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { FiArrowLeft } from 'react-icons/fi';

import { postUrl, getUrl, logoutUser } from '../helper/ApiAction';
import { BACKEND_URL, CLIENT_SECRET, EXTERNAL_LOGIN, FACEBOOK_APPID, LOGIN_SECRET_KEY, ISHOPPING_LOGIN } from '../configs/Config';
import { authSuccess, authFail, storeCodeState ,changeLanguage as reduxChangeLanguage} from '../actions';
import { generateRandomString, useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import { Base64 } from 'js-base64';
import sha256 from 'crypto-js/sha256';

import WebpImg from './Layouts/WebpImg';
import ImgHeader from './Layouts/ImgHeader';

export default function Login() {
    const styles = useStyles();
    const dispatch = useDispatch();
    const lastLocation = useLastLocation();
    const query = useQuery();
    const source = query.get('src');
    let history = useHistory();

    const { t , i18n} = useTranslation();
    const { accessToken, shareLinkProductId } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { name } = useSelector(state => state.user);

    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });
    const [userLogin, setUserLogin] = useState(false);
    const [continueDialogOpen, setContinueDialogOpen] = useState(false);

    useEffect(() => {
        if(accessToken) {
            if(source){
                if(userLogin){
                    // user login, redirect to third party page
                    setLoading(true);
                    getUrl(`/login-to/${source}`).then(response => {
                        setLoading(false);
                        if(response.status === 1 && response.url){
                            window.location.href = response.url; 
                        }else{
                            addAlert(t('general.loginRedirectError'));
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }else{
                    // user already login, ask continue as XXX
                    setContinueDialogOpen(true);
                }
            }else{
                let previousUrl = "";
                if(document.referrer) previousUrl = new URL(document.referrer);
                if(shareLinkProductId){
                    history.replace({ pathname: `item/${shareLinkProductId}` });
                }else if(!previousUrl || (previousUrl && window.location.host != previousUrl['host'])){
                    history.replace({ pathname: "/" });
                }else{
                    history.goBack();
                }
            }
        }
    }, [accessToken, history, shareLinkProductId, source]);

    const loginUser = () => {
        setLoading(true);
        setUserLogin(true);

        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        }
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, loginState)
        .then((response) => {
            // setLoading(false);
            setLoading(false);
            if(response.status === 200) {
                // dispatch(authSuccess(response.data));
                // history.goBack();
                const decoded_jwt = jwt_decode(response.data.access_token);
                const userRole = decoded_jwt ? decoded_jwt.role : [];
                if(_.size(_.intersection(userRole, ['member']))){
                    dispatch(authSuccess(response.data));
                }else{
                    addAlert(t('general.incorrectUsernamePassword'));
                }
            }
        }).catch((error) => {
            console.log("error", error);
            setLoading(false);
            if (error.response && error.response.status === 400) {
                addAlert(t('general.incorrectUsernamePassword'));
                // console.log('The username or password is incorrect');
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                addAlert(errorResponse.message);
            }else{
                addAlert(JSON.stringify(error.message));
                // console.log('Please try again later or contact Technical Support if problem persist.');
            }            
            dispatch(authFail());
            // setLoading(false);            
        }); 
    }

    const dontContinueAs = () => {
        setContinueDialogOpen(false);
        logoutUser();
    }
    const continueAs = () => {
        setContinueDialogOpen(false);
        setLoading(true);
        getUrl(`/login-to/${source}`).then(response => {
            setLoading(false);
            if(response.status === 1 && response.url){
                window.location.href = response.url; 
            }else{
                addAlert(t('general.loginRedirectError'));
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
    return (
        <Box id='loginpage' className={styles.backgroundScreen}>
            <ImgHeader imageDisplay={false} title={t('title.home')} backUrl={"/splashscreen"} languageButton={false} />

            <div style={{ display: 'flex', alignItems:'center', justifyContent: 'center', height: '100%' }}>
                <Card className={styles.loginBox}>
                    <WebpImg src="/images/logo-h2u-text.png" style={{ width: '130px', padding: '0px 10px', marginTop: 52 }} alt="logo" />

                    <CardContent style={{ padding: 30 }}>
                        <ThemeProvider theme={accordingStyles}>
                            <TextField
                                label={t('register.username')}
                                value={state.username}
                                InputProps={{
                                    className: styles.textFieldText,
                                }}
                                InputLabelProps={{
                                    className: styles.textFieldText,
                                }}
                                variant='outlined'
                                autoComplete="email"
                                fullWidth={true}
                                onChange={({ target }) => setState({ ...state, username: target.value })}
                                style={{ marginBottom: '17px' }}
                            />

                            <TextField
                                label={t('register.password')}
                                type={state.showPassword ? 'text' : 'password'}
                                value={state.password}
                                InputProps={{
                                    className: styles.textFieldText,
                                }}
                                InputLabelProps={{
                                    className: styles.textFieldText,
                                }}
                                variant="outlined"
                                fullWidth={true}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                style={{ marginBottom: '18px' }}
                            />
                        </ThemeProvider>

                        <Box display="flex" justifyContent="center" style={{ marginBottom: '16px' }}>
                            <Button variant="contained" color="secondary" size="large" className={styles.signInButton} fullWidth={true} onClick={loginUser}>
                                {t('button.signin')}
                            </Button>
                        </Box>

                        <center>
                            <Link underline='none' to={`/forgot-password`} component={RouterLink}>
                                <Typography variant="caption" className={styles.forgetButtonText} >
                                    {t('title.forgotPassword')}
                                </Typography>
                            </Link>
                        </center>
                        
                        <Box className={styles.paddingVertical10} display="flex" justifyContent="center">
                            <Link underline='none' onClick={() => changeLanguage('en')} component={RouterLink} style={{ marginRight: 5, color: 'white' }}>
                                <Typography>{t('ENG')}</Typography>
                            </Link>
                            <Typography style={{ color: 'white' }}>{t('|')}</Typography>
                            <Link underline='none' onClick={() => changeLanguage('cn')} component={RouterLink} style={{ marginLeft: 5, color: 'white' }}>
                                <Typography>{t('中文')}</Typography>
                            </Link>
                        </Box>
                    </CardContent>
                </Card>
            </div>

            <Dialog open={continueDialogOpen} >
                <DialogTitle id="scroll-dialog-title">{t('login.continueAsTitle', { 'name': name })}</DialogTitle>
                <DialogContent dividers={false}>{t('login.continueAsContent', { 'source': source })}</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => continueAs()} color="primary">{t("login.continueYes")}</Button>
                    <Button variant="contained" onClick={() => dontContinueAs()} color="primary">{t("login.continueNo")}</Button>
                </DialogActions>
            </Dialog>
        </Box>

    );
}



const useStyles = makeStyles(theme => ({
    backgroundScreen:{
        backgroundImage: `linear-gradient(180deg, #000 -6%, rgba(0, 0, 0, 0.00) 72.16%), url('/images/background/background_image.png')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat-y',
        margin: '0',
        padding: '0',
        height: '100vh',
        width: 'auto',
    },
    loginBox: {
        width: '80%',
        borderRadius: '20px',
        borderTop: '2px solid rgba(255, 255, 255, 0.23)',
        borderLeft: '2px solid rgba(255, 255, 255, 0.23)',
        background: 'rgba(255, 255, 255, 0.06)',
        boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.53)',
        backdropFilter: 'blur(6px)',
        textAlign: 'center',
    },
    iconCss: {
        color: 'white',
        position: 'absolute',
        left: 31,
        top: 26,
    },
    textFieldText: {
        color: 'white',
        boxShadow: '0 0 0 0 ',
    },
    textFieldBox: {
        width: '100%',
        borderRadius: '48px',
        border: "1px solid #FFF",
        boxShadow: '0 0 0 0 ',
    },
    forgetButtonText: {
        color:'#E8E8E8', 
        fontSize: 15, 
        textAlign: 'center',
        fontWeight: 'lighter',
        lineHeight: 'normal',
    },
    signInButton: {
        width: '100%',
        height: '48px',
        flexShrink: 0,
        borderRadius: '48px',
        background: '#D9D9D9',
        boxShadow: '4px 4px 6px 0px rgba(0, 0, 0, 0.42)',
    },
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    containerStyle: {
        padding: 20,
    }
}));

const accordingStyles = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                '& fieldset': {
                    borderColor: 'white !important',
                    borderRadius: '48px',
                    boxShadow: '0 0 0 0 ',
                },
            },
        },
        
        MuiFormLabel: {
            root: {
                '&.Mui-focused': {
                    color: '#fff'
                }
            }
        }
    }
});