import React, { useState, useEffect, useRef, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeProfile } from '../actions';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { TextField, Button, Typography, Grid, Box, Divider, List, Link, ListItem, Avatar,Snackbar,Card ,CardContent,Badge,IconButton } from '@material-ui/core';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles, useTheme,withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { getUrl, postUrl, putUrl, deleteUrl } from '../helper/ApiAction';
import { red, green } from '@material-ui/core/colors';

import useNotificationLoading from '../helper/useNotificationLoading';
import EditIcon from '@material-ui/icons/Edit';
import QRCode from "easyqrcodejs";

import TitleBar from './Layouts/TitleBar';
import './css/styles.css';


const INITIAL_ADDRESS_STATE = { address: '', address2: '', city: '', state: '', zip: '', country: '' };
const INITIAL_PASSWORD_STATE = { old_password: '', password: '', password_confirmation: '' };

export default function Profile(props) {
    // const { t, language } = props;
    const { t,  i18n} = useTranslation();

    const { id, name, email, mobile, username, nric, referral_username, max_rank:rank, max_mrank, /* additional_info */} = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();

    const [state, setState] = useState({
        modalShow: false,
        mode: null,
        deleteAddressId: null,
        editAddressId: null,
        snackbarShow: false,
        deleteAddressShow: false,
        error: false,
        message: '',
    });
    const [profileFormState, setProfileFormState] = useState({ name, email, mobile, nric });
    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [passwordFormState, setPasswordFormState] = useState(INITIAL_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        name: '', mobile: '',
        old_password: '', password: '', password_confirmation: '',
    })
    const [errorAddressState, setErrorAddressState] = useState({
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
    })
    // const [additionalInfoFormState, setAdditionalInfoFormState] = useState({...additional_info});

    const [addresses, setAddress] = useState([]);
    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [iconMedia , setIconMedia]=useState();
    
    const fileInput =createRef();
    let history = useHistory();

    const styles = useStyles();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    const qrcode = useRef(null);
    const shareLink = useRef(null);

    // setProfileFormState({ name, mobile });

    useEffect(() => {
        isMountedRef.current = true;
            getUrl('address_group_list').then(addressList => {
                if(isMountedRef.current && addressList.status === 1) {
                    setAddressGroup({
                        stateGroup: addressList.state_group,
                        cityGroup: addressList.city_group,
                        districtGroup: addressList.district_group,
                    });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
            getUrl('user/address').then(addressList => {
                if(isMountedRef.current) {
                    setAddress(addressList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            // console.log(countryList)
            if(isMountedRef.current) {
                setCountry(countryList.data);
                // const filteredCountryList = _.filter(countryList.data, countryItem => countryItem.code === 'CN' || countryItem.code === 'MY');
                // setCountry(filteredCountryList);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country]);

    useEffect(() => {
        isMountedRef.current = true;
        if(addressFormState.country) {
            if(_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if (isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });

            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if(addressFormState.state) {
            if(_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if(addressFormState.city) {
            if(_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`user/landingicon`).then(iconImg => {
            if (isMountedRef.current) {
                setIconMedia(iconImg.data);
            }

        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    const handleProfileChange = (event) => {
        const name = event.target.name;
        setProfileFormState({ ...profileFormState, [name]: event.target.value });
    }

    const handleAddressChange = (event) => {
        const name = event.target.name;
        setAddressFormState({ ...addressFormState, [name]: event.target.value });
        if (name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };

    const handlePasswordChange = (event) => {
        const name = event.target.name;
        setPasswordFormState({ ...passwordFormState, [name]: event.target.value });
    }

    const updateProfile = () => {
        setLoading(true);
        setErrorFormState({});
        const { name, email, mobile, nric } = profileFormState;
        if(!name || (!mobile && !email)) {
            setLoading(false);
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user', profileFormState).then(result => {
                if(!result.status) {
                    setLoading(false);
                    setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setLoading(false);
                    setState(state => ({ ...state, snackbarShow: true, error: false, message: result.message }));
                    dispatch(storeProfile(result.data));
                }
            })
        }
    }

    // const handleAutoPlacementChange = (event) => {
    //     setLoading(true);

    //     if(event.target.name == "auto_placement_account" && event.target.value == "0"){
    //         setAdditionalInfoFormState({ ...additionalInfoFormState, [event.target.name]: event.target.value, "auto_placement_position": "0" });
    //     }else{
    //         setAdditionalInfoFormState({ ...additionalInfoFormState, [event.target.name]: event.target.value });
    //     }
    //     putUrl('user/additional_info', {[event.target.name]: event.target.value}).then(result => {
    //         setLoading(false);
    //         if(!result.status) {
    //             setState(state => ({ ...state, snackbarShow: true, error: true, message: result.message }));
    //         } else {
    //             setState(state => ({ ...state, snackbarShow: true, error: false, message: result.message }));
    //             dispatch(storeProfile(result.data));
    //         }
    //     })
    // };

    const updatePassword = () => {
        const { old_password, password, password_confirmation } = passwordFormState;
        if (!old_password || !password || !password_confirmation) {
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/password', passwordFormState).then(result => {
                if (result.error) {
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: result.message }));
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setState(state => ({ ...state, snackbarShow: true, error: false, message: result.message }));
                    setPasswordFormState(INITIAL_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    const submitAddress = () => {
        // const { country, city, address2, zip, address } = addressFormState;
        // if(!country || !addressFormState.state || !city) {
        //     if(cities) {
        //         if(!address2 || !zip || !address) {
        //             setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        //         }
        //     }
        //     setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        // } else {
        if (state.mode === 'add') {
            postUrl('user/address', addressFormState).then(result => {
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }));
                } else {
                    setAddressFormState(INITIAL_ADDRESS_STATE);
                    setState(state => ({ ...state, modalShow: false, snackbarShow: true, error: false, message: result.message }));
                    getUrl('user/address').then(addressList => {
                        setAddress(addressList.data);
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        } else {
            putUrl(`user/address/${state.editAddressId}`, addressFormState).then(result => {
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }));
                } else {
                    setAddressFormState(INITIAL_ADDRESS_STATE);
                    setState(state => ({ ...state, modalShow: false, mode: null, editAddressId: null, snackbarShow: true, error: false, message: result.message }));
                    getUrl('user/address').then(addressList => {
                        setAddress(addressList.data);
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        // }
    }

    const deleteUserAddress = () => {
        if (state.deleteAddressId) {
            deleteUrl(`user/address/${state.deleteAddressId}`).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
            setState({ ...state, deleteAddressId: null, deleteAddressShow: false });

            getUrl('user/address').then(addressList => {
                setAddress(addressList.data);
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const openAddAddress = () => {
        setErrorAddressState({});
        setState({ ...state, mode: 'add', modalShow: true });
    };

    const closeModal = () => {
        setState({ ...state, modalShow: false, mode: null, editAddressId: null, });
        setAddressFormState(INITIAL_ADDRESS_STATE);
    };

    const openEditAddress = addressId => {
        setErrorAddressState({});
        const editedAddress = _.find(addresses, { 'id': addressId });
        if (editedAddress) {
            const { address, address2, city, state, zip, country } = editedAddress;
            setAddressFormState({ address, address2, city, state, zip, country });
            setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: addressId }));
        }
    }

    const setDefaultAddress = addressId => {
        const editedAddress = _.find(addresses, { 'id': addressId });

        const { address, address2, city, state, zip, country } = editedAddress;
        const defaultAddress = {
            address, address2, city, state, zip, country, default: 1,
        }
        putUrl(`user/address/${addressId}`, defaultAddress).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        getUrl('user/address').then(addressList => {
            // addAlert(JSON.stringify(error.message));
            setAddress(addressList.data);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const uploadIcon = async event => {
        setLoading(true);
        event.preventDefault();
        let temp = fileInput.current.files[0];
        let newImageBase64 = await toBase64(temp);
        let apiData = { image: newImageBase64 };

        putUrl(`user/addlandingicon/icon`, apiData).then(response => {
            setLoading(false);
            let { status, message, errors, data,result } = response;
            if (status === 1) {

                setState(state => ({ ...state, snackbarShow: true, error: false, message: message }));
                setIconMedia(data);
                dispatch(storeProfile(result));
            } else {

                setState(state => ({ ...state, snackbarShow: true, error: true, message: message }));
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        new QRCode(qrcode.current, { 
            text: `${window.location.origin}/register?r=${btoa(id)}`, 
            drawer: 'svg',
            // logo: `/images/logo.png`,
            // logoBackgroundTransparent: true,
            // logoBackgroundColor: theme.palette.primary.main,
            backgroundImage: `/images/logo2.png`,
            backgroundImageAlpha: 0.5,
            PO: theme.palette.primary.main,
            AI: theme.palette.primary.main,
            // title: name,
            // titleTop: 10,
            // titleColor: '#e53935',
            dotScale: 0.6
        });
    }, [qrcode]);

    const copyShareLink = () => {
        var textField = document.createElement('textarea');
        textField.innerText = `${window.location.origin}/register?r=${btoa(id)}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        addAlert(t('profile.copySuccess'), "success");
    };

    return (
        <Box className={styles.root} style={{  background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}}>
            {/* <div className='mobile-width-fixed' style={{ zIndex:2}}>
                <TitleBarWhite height={0} title={t('profile.profile')} displayInfo={false} back />
            </div> */}
            <TitleBar height={70} title={t('profile.profile')} displayInfo displayQr currencyButton displayCart linkMall back pagetitle />

            <div style={{ color: theme.palette.gray.dtext, padding:'30px 7%' }}>
                <Typography style={{color:theme.palette.primary.main, fontSize:25, paddingBottom:15}}><b>{t('profile.profile')}</b></Typography>
                <div style={{paddingBottom:25}}>
                    <Typography><b>{t('profile.name')}</b></Typography>
                    <Typography>{name}</Typography>
                </div>
                <div style={{paddingBottom:25}}>
                    <Typography><b>{t('profile.rank')}</b></Typography>
                    <Typography>{(rank && _.size(rank) > 0) ? rank.name_display : '-'}</Typography>
                    <Typography>{(max_mrank && _.size(max_mrank) > 0) ? max_mrank.name_display : ''}</Typography>
                </div>
                <div style={{paddingBottom:25}}>
                    <Typography><b>{t('register.nric_passport')}</b></Typography>
                    <Typography>{nric}</Typography>
                </div>
                <div style={{paddingBottom:25}}>
                    <Typography><b>{t('profile.email')}</b></Typography>
                    <Typography>{email}</Typography>
                </div>
                <div style={{paddingBottom:25}}>
                    <Typography><b>{t('profile.mobile')}</b></Typography>
                    <Typography>{mobile}</Typography>
                </div>
                <div style={{paddingBottom:25}}>
                    <Typography><b>{t('profile.username')}</b></Typography>
                    <Typography>{username}</Typography>
                </div>
            </div>

            <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
   
            {/* <Grid container spacing={1} justify="center" style={{ padding: '40px 15px 0' }}>
                <Grid item xs={12}>
                    <List className={styles.listStyle} style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>

                        <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                            <Box style={{ color: theme.palette.primary.main }}>
                                <Typography variant="h6">
                                    {t('profile.profile')}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {t('profile.profileSubtitle')}
                                </Typography>
                            </Box>

                        </ListItem>
                        <Divider variant="middle" light />
                        <Grid item xs={12} >
                            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" paddingTop="10px" paddingBottom="10px">
                                <Box className={styles.rankWrapperStyle}>

                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        badgeContent={<SmallAvatar alt="icon"><input accept="image/*" className={styles.inputFile} id="icon-button-file" type="file" onChange={uploadIcon} ref={fileInput} /><label htmlFor="icon-button-file"><IconButton component="span"><EditIcon style={{ fontSize: "small" }} color="secondary" /></IconButton></label></SmallAvatar>}
                                    >
                                        {
                                            _.size(iconMedia) > 0 && iconMedia ?
                                                <Avatar className={styles.large} alt="icon" src={_.size(iconMedia) && iconMedia ? iconMedia.file_name : ''} />
                                                :
                                                <Avatar className={styles.large} alt="icon" style={{ backgroundColor: theme.palette.white.main }}><Typography className={styles.rankTitleStyle}>{_.size(username) > 0 ? username[0] : '-'}</Typography></Avatar>

                                        }
                                    </Badge>
                                </Box>
                            </Box>
                        </Grid>


                        <ListItem className={styles.listPaddingVertical}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Typography variant="caption" color="textSecondary">{t('profile.referral')}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9} sm={8}>
                                    <Typography variant="overline" color="textPrimary">{(id === 7 || id === 10) ? `-` : referral_username}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem className={styles.listPaddingVertical}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Typography variant="caption" color="textSecondary">{t('profile.rank')}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9} sm={8}>
                                    <Typography variant="overline" color="textPrimary">{_.size(rank) > 0 && rank ? rank.name_display : ''}</Typography>
                                        //<Typography variant="overline" color="textPrimary">{rank.name_display}</Typography>

                                </Grid>
                            </Grid>
                        </ListItem>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                            <Button onClick={updateProfile} variant="contained" style={{ width: 167, padding: '8px 9px', maxWidth: '80%', borderRadius: 48, color: '#fff' }} color="secondary">{t('button.update')}</Button>
                        </Box>
                        <Grid container spacing={2} >
                            <Grid item xs={12} >
                                <ListItem className={styles.listPaddingVertical}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={3} sm={2}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Typography variant="caption" color="textSecondary">{t('profile.autoPlacementAccount')}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={9} sm={8}>
                                            <RadioGroup aria-label="auto_placement_account" row name="auto_placement_account" value={additionalInfoFormState.auto_placement_account} onChange={handleAutoPlacementChange}>
                                                <FormControlLabel value="0" control={<Radio />} label={t('profile.auto')} />
                                                <FormControlLabel value="1" control={<Radio />} label={username.toUpperCase() + "-2"} />
                                                <FormControlLabel value="2" control={<Radio />} label={username.toUpperCase() + "-3"} />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <ListItem className={styles.listPaddingVertical}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={3} sm={2}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Typography variant="caption" color="textSecondary">{t('profile.autoPlacementPosition')}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={9} sm={8}>
                                            <RadioGroup aria-label="auto_placement_position" row name="auto_placement_position" value={additionalInfoFormState.auto_placement_position} onChange={handleAutoPlacementChange}>
                                                <FormControlLabel value="0" control={<Radio />} label={t('profile.auto')} />
                                                <FormControlLabel value="1" control={<Radio />} label={t('profile.left')} disabled={(additionalInfoFormState.auto_placement_account == "0")} />
                                                <FormControlLabel value="2" control={<Radio />} label={t('profile.right')} disabled={(additionalInfoFormState.auto_placement_account == "0")} />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </List>



                </Grid>
            </Grid> */}
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    paddingVerticalZero: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    chipLabel: {
        fontSize: 10
    },
    formControl: {
        padding: 5
    },
    large: {
        width: 55,
        height: 55,
        bottom:1,
    },
    inputFile: {
        display: 'none',
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 36,
        fontWeight:'bold',
        textTransform:'uppercase'
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
    },
}));

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.white.main,
        right:-5,
    },
}))(Avatar);