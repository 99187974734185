import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Card, CardContent, Link,Container,Avatar,} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink} from 'react-router-dom';
import {  ChevronRightSharp,LocalOfferOutlined,RedeemOutlined,SystemUpdate,} from '@material-ui/icons';
import AnnouncementOutlined from '@material-ui/icons/AnnouncementOutlined';

import { HiOutlineSpeakerphone } from "react-icons/hi";

import TitleBar from './Layouts/TitleBar';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { AiOutlineConsoleSql } from 'react-icons/ai';

export default function Notification() {
    const [unreadMstoreUpdate, setunreadMstoreUpdate] = useState();
    const [unreadPromote, setunreadPromote] = useState();
    const [unreadPrizeNotification, setunreadPrizeNotification] = useState();
    const [unreadAnnoucement, setunreadAnnoucement] = useState();

    const { t} = useTranslation();
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`notifications`).then(response => {
            if (response.status) {
                if (isMountedRef.current) {
                    setunreadMstoreUpdate(response.new_count_mstore_update);
                    setunreadPromote(response.new_count_promote);
                    setunreadPrizeNotification(response.new_count_prize_notification);
                    setunreadAnnoucement(response.new_announcement);
                    setLoading(false)
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [ addAlert]);
    
    return (
        <Box paddingBottom={5} style={{background: theme.palette.white.mobileBkg, paddingBottom:80, minHeight: '100%' }}>
            <TitleBar height={70} title={t('profile.notification')} displayInfo displayQr currencyButton displayCart linkMall logoIcon />
            <div className='fixed-header-padding' style={{ maxWidth: '100%', zIndex: 2, paddingTop: 80 }}>
                <Link underline='none' to="/Announcement" component={RouterLink} >
                    <Card variant="outlined" className='announcement-menu'>
                        <CardContent>
                            <Grid container>
                            <Grid item xs={2}>
                                    <HiOutlineSpeakerphone color="primary" style={{ width: 40, height: 30, color:theme.palette.primary.main }} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6" >{t('title.announcements')}</Typography>
                                </Grid>
                                <Grid item xs={1} align="right" justify='center' style={{ marginTop: 5 }}>
                                    {unreadAnnoucement > 99 ?
                                        <Avatar variant="circle" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                            <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12, paddingTop: 2 }}>{"99+"}</Typography>
                                        </Avatar>
                                        :
                                        (unreadAnnoucement > 0 ?
                                            <Avatar variant="rounded" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                                <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12, paddingTop: 2 }}>{unreadAnnoucement}</Typography>
                                            </Avatar>
                                            :
                                            null
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, width: 40, height: 30, }} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Link>
                <Link underline='none' to="/notification?mt=promotions" component={RouterLink} >
                    <Card variant="outlined" className='announcement-menu'>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={2}>
                                    <LocalOfferOutlined color="primary" style={{ width: 40, height: 30, }} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6" >{t('message.promotion')}</Typography>
                                </Grid>
                                <Grid item xs={1} align="right" justify='center' style={{ marginTop: 5 }}>
                                    {unreadPromote > 99 ?
                                        <Avatar variant="circle" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                            <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12, paddingTop: 2 }}>{"99+"}</Typography>
                                        </Avatar>
                                        :
                                        (unreadPromote > 0 ?
                                            <Avatar variant="rounded" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                                <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12, paddingTop: 2 }}>{unreadPromote}</Typography>
                                            </Avatar>
                                            :
                                            null
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, width: 40, height: 30, }} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Link>
                <Link underline='none' to="/notification?mt=mstore_update" component={RouterLink} >
                    <Card variant="outlined" className='announcement-menu'>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={2}>
                                    <AnnouncementOutlined color="primary" style={{ width: 40, height: 30, }} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6" >{t('message.mstoreupdate')}</Typography>
                                </Grid>
                                <Grid item xs={1} align="right" justify='center' style={{ marginTop: 5 }}>
                                    {unreadMstoreUpdate > 99 ?
                                        <Avatar variant="circle" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                            <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12, paddingTop: 2 }}>{"99+"}</Typography>
                                        </Avatar>
                                        :
                                        (unreadMstoreUpdate > 0 ?
                                            <Avatar variant="rounded" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                                <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12, paddingTop: 2 }}>{unreadMstoreUpdate}</Typography>
                                            </Avatar>
                                            :
                                            null
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 0, width: 40, height: 30, }} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Link>
                {/* <Link underline='none' to="/notification?mt=prizes_notification" component={RouterLink} >
                    <Card variant="outlined" className='announcement-menu'>
                        <CardContent >
                            <Grid container>
                                <Grid item xs={2}>
                                    <RedeemOutlined color="primary" style={{ width: 40, height: 30, }} />
                                </Grid>
                                <Grid item xs={8} >
                                    <Typography variant="h6" >{t('message.prizenotification')}</Typography>
                                </Grid>
                                <Grid item xs={1} align="right" justify='center' style={{ marginTop: 5 }}>
                                    {unreadPrizeNotification > 99 ?
                                        <Avatar variant="circle" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                            <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12 }}>{"99+"}</Typography>
                                        </Avatar>
                                        :
                                        (unreadPrizeNotification > 0 ?
                                            <Avatar variant="rounded" className={styles.unreadiconColor} style={{ marginLeft: 10 }} >
                                                <Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 12 }}>{unreadPrizeNotification}</Typography>
                                            </Avatar>
                                            :
                                            null
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 0, width: 40, height: 30, }} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Link> */}
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding:'0 0 80px',
        minHeight: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    large: {
        width: 20,
        height: 20,
    },
    
    unreadiconColor: {
        padding: 5,
        width: 25, 
        height: 20,
        backgroundColor:'#9FD01A',
        color:"#fff"
    },
}));