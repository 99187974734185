import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Link, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TitleBar from './Layouts/TitleBar';

import './css/styles.css';

export default function TermOfUse() {
    const { t } = useTranslation();

    return (
        <Box>
            {/* <div className='mobile-width-fixed'>
                <TitleBarWhite height={0} title={t('support.termOfUse')}  back  displayInfo={false} />
            </div> */}
            <TitleBar height={70} title={t('support.termOfUse')} displayInfo displayQr currencyButton displayCart linkMall back backtext />
            <div className='fixed-header-padding'>
                <div style={{ textAlign: 'center', padding: '40px 0 20px' }}>
                    <Typography style={{ fontSize: '28px', color: '#777' }}>{t('support.termOfUse')}</Typography>
                </div>

                <div style={{ backgroundColor: '#fff', padding: '10px 0 100px 0', color: '#666', lineHeight: '1.7' }}>
                    <Container>
                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            1. INTRODUCTION
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            1.1 Welcome to the Healthcare2u platform (the "Site"). Please read the following Terms of Service carefully before using this Site or opening a Healthcare2u account ("Account") so that you are aware of your legal rights and obligations with respect to Bukti Tulus Sdn. Bhd. (Company Registration No. 201601013092 (1184023-D)), its affiliates and/or subsidiaries (individually and collectively, "Healthcare2u," "we," "us," or "our"). The "Services" we provide or make available include (a) the Site, (b) the services provided by the Site and by Healthcare2u client software made available through the Site, and (c) all information, linked pages, features, data, text, images, photographs, graphics, music, sounds, video (including live streams), messages, tags, content, programming, software, application services (including, without limitation, any mobile application services) or other materials made available through the Site or its related services ("Content"). Any new features added to or augmenting the Services are also subject to these Terms of Service. These Terms of Service govern your use of Services provided by Healthcare2u.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            1.2 The Services include an online platform service that provides a place and opportunity for the sale of goods between the buyer (“Buyer”) and the seller (“Seller”) (collectively “you,” “Users,” or “Parties”). The actual contract for sale is directly between Buyer and Seller, and Healthcare2u is not a party to that or any other contract between Buyer and Seller and accepts no obligations in connection with any such contract. Parties to such a transaction will be entirely responsible for the sales contract between them, the listing of goods, warranty of purchase, and the like. Healthcare2u is not involved in the transaction between Users. Healthcare2u may or may not pre-screen Users or the Content or information provided by Users. Healthcare2u reserves the right to remove any Content or information posted by you on the Site in accordance with Section 6.4 herein. Healthcare2u cannot ensure that Users will actually complete a transaction.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            1.3 Before becoming a User of the Site, you must read and accept all of the terms and conditions in, and linked to, these Terms of Service, and you must consent to the processing of your personal data as described in the Privacy Policy linked hereto.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            1.4 Healthcare2u reserves the right to change, modify, suspend, or discontinue all or any part of this Site or the Services at any time or upon notice as required by local laws. Healthcare2u may release certain Services or their features in a beta version, which may not work correctly or in the same way the final version may work, and we shall not be held liable in such instances. Healthcare2u may also impose limits on certain features or restrict your access to parts of, or the entire, Site or Services in its sole discretion and without notice or liability.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            1.5 Healthcare2u reserves the right to refuse to provide you access to the Site or Services or to allow you to open an Account for any reason.
                        </Typography>
                        <br />
                        <p style={{ fontSize: 13 }}>BY USING HEALTHCARE2U SERVICES OR OPENING AN ACCOUNT, YOU GIVE YOUR IRREVOCABLE ACCEPTANCE OF AND CONSENT TO THE TERMS OF THIS AGREEMENT, INCLUDING THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO.</p>
                        <br />
                        <p style={{ fontSize: 13 }}>IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE OUR SERVICES OR ACCESS THE SITE. IF YOU ARE UNDER THE AGE OF 18 OR THE LEGAL AGE FOR GIVING CONSENT HEREUNDER PURSUANT TO THE APPLICABLE LAWS IN YOUR COUNTRY (THE “LEGAL AGE”), YOU MUST GET PERMISSION FROM A PARENT OR LEGAL GUARDIAN TO OPEN AN ACCOUNT, AND THAT PARENT OR LEGAL GUARDIAN MUST AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU DO NOT KNOW WHETHER YOU HAVE REACHED THE LEGAL AGE, OR DO NOT UNDERSTAND THIS SECTION, PLEASE DO NOT CREATE AN ACCOUNT UNTIL YOU HAVE ASKED YOUR PARENT OR LEGAL GUARDIAN FOR HELP. IF YOU ARE THE PARENT OR LEGAL GUARDIAN OF A MINOR WHO IS CREATING AN ACCOUNT, YOU MUST ACCEPT THE TERMS OF THIS AGREEMENT ON THE MINOR'S BEHALF, AND YOU WILL BE RESPONSIBLE FOR ALL USE OF THE ACCOUNT OR COMPANY SERVICES USING SUCH ACCOUNT, WHETHER SUCH ACCOUNT IS CURRENTLY OPEN OR CREATED LATER.</p>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            2. PRIVACY
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            2.1 Your privacy is very important to us at Healthcare2u. To better protect your rights, we have provided Healthcare2u's Privacy Policy to explain our privacy practices in detail. Please review the Privacy Policy to understand how Healthcare2u collects and uses the information associated with your Account and/or your use of the Services (the “User Information”). By using the Services or providing information on the Site, you:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>consent to Healthcare2u's collection, use, disclosure, and/or processing of your Content, personal data, and User Information as described in the Privacy Policy;</li>
                            <li>agree and acknowledge that the proprietary rights of your User Information are jointly owned by you and Healthcare2u; and</li>
                            <li>shall not, whether directly or indirectly, disclose your User Information to any third party or otherwise allow any third party to access or use your User Information without Healthcare2u's prior written consent.</li>
                        </ol>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            2.2 Users in possession of another User’s personal data through the use of the Services (the “Receiving Party”) hereby agree that they will (i) comply with all applicable personal data protection laws with respect to any such data; (ii) allow the User whose personal data the Receiving Party has collected (the “Disclosing Party”) to remove his or her data so collected from the Receiving Party’s database; and (iii) allow the Disclosing Party to review what information has been collected about them by the Receiving Party, in compliance with and where required by applicable laws.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            3. LIMITED LICENSE
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            3.1 Healthcare2u grants you a limited and revocable license to access and use the Services subject to the terms and conditions of these Terms of Service. All proprietary Content, trademarks, service marks, brand names, logos, and other intellectual property (“Intellectual Property”) displayed on the Site are the property of Healthcare2u and where applicable, third-party proprietors identified on the Site. No right or license is granted directly or indirectly to any party accessing the Site to use or reproduce any Intellectual Property, and no party accessing the Site shall claim any right, title, or interest therein. By using or accessing the Services, you agree to comply with the copyright, trademark, service mark, and all other applicable laws that protect the Services, the Site, and its Content. You agree not to copy, distribute, republish, transmit, publicly display, publicly perform, modify, adapt, rent, sell, or create derivative works of any portion of the Services, the Site, or its Content. You also may not, without our prior written consent, mirror or frame any part or the whole of the contents of this Site on any other server or as part of any other website. In addition, you agree that you will not use any robot, spider, or any other automatic device or manual process to monitor or copy our Content without our prior written consent (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this website).
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            4. SOFTWARE
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            4.1 Any software provided by us to you as part of the Services is subject to the provisions of these Terms of Service. Healthcare2u reserves all rights to the software not expressly granted by Healthcare2u hereunder. Any third-party scripts or code linked to or referenced from the Services are licensed to you by the third parties that own such scripts or code, not by Healthcare2u.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            5. ACCOUNTS AND SECURITY
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            5.1 Some functions of our Services require registration for an Account by selecting a unique user identification ("User ID") and password and by providing certain personal information. If you select a User ID that Healthcare2u, in its sole discretion, finds offensive or inappropriate, Healthcare2u has the right to suspend or terminate your Account. You may be able to use your Account to gain access to other products, websites, or services to which we have enabled access or with which we have tied up or collaborated. Healthcare2u has not reviewed and assumes no responsibility for any third-party content, functionality, security, services, privacy policies, or other practices of those products, websites, or services. If you do so, the terms of service for those products, websites, or services, including their respective privacy policies, if different from these Terms of Service and/or our Privacy Policy, may also apply to your use of those products, websites, or services.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            5.2 You agree to (a) keep your password confidential and use only your User ID and password when logging in, (b) ensure that you log out from your account at the end of each session on the Site, (c) immediately notify Healthcare2u of any unauthorized use of your Account, User ID, and/or password, and (d) ensure that your Account information is accurate and up-to-date. You are fully responsible for all activities that occur under your User ID and Account even if such activities or uses were not committed by you. Healthcare2u will not be liable for any loss or damage arising from unauthorized use of your password or your failure to comply with this Section.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            5.3 You agree that Healthcare2u may, for any reason, in its sole discretion and with or without notice or liability to you or any third party, immediately suspend, freeze, or terminate your Account and your User ID, remove or discard from the Site any Content associated with your Account and User ID, withdraw any subsidies offered to you, cancel or suspend any transactions associated with your Account and User ID, temporarily or in more serious cases permanently withhold any sale proceeds or refunds, and/or take any other actions that Healthcare2u deems necessary. Grounds for such actions may include, but are not limited to, actual or suspected (a) extended periods of inactivity, as determined by us from time to time, (b) violation of the letter or spirit of these Terms of Service, (c) illegal, fraudulent, harassing, defamatory, threatening, or abusive behavior (d) having multiple user accounts, (e) buying products on the Site for the purpose of commercial resale, (f) abnormal or excessive purchase of products from the same Seller or related group of Sellers, (g) voucher abuse (including, but not limited to, selling of vouchers to third parties, selling of vouchers or other credits at a significant markup above face value, and/or abnormal or excessive use of vouchers on the Site), or (h) behavior that is harmful to other Users, third parties, or the business interests of Healthcare2u. Use of an Account for illegal, fraudulent, harassing, defamatory, threatening, or abusive purposes may be referred to law enforcement authorities without notice to you. If a legal dispute arises or law enforcement action is commenced relating to your Account or your use of the Services for any reason, Healthcare2u may terminate your Account immediately with or without notice.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            5.4 Users may terminate their Account if they notify Healthcare2u in writing (including via email at (support@healthcare2u.asia) of their desire to do so. Notwithstanding any such termination, Users remain responsible and liable for any incomplete transaction (whether commenced prior to or after such termination), shipment of the product, payment for the product, or the like, and Users must contact Healthcare2u after he or she has promptly and effectively carried out and completed all incomplete transactions according to the Terms of Service. Healthcare2u shall have no liability, and shall not be liable for any damages incurred due to the actions taken in accordance with this Section. Users waive any and all claims based on any such action taken by Healthcare2u.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            5.5 You may only use the Services and/or open an Account if you are located in one of our approved countries, as updated from time to time.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            5.6 If you are a Seller, you may be entitled to create sub-accounts (“Sub-Account”) on the sub-account platform operated by Healthcare2u (“Sub-Account Platform”) that are linked to your Account(s). You acknowledge and agree that: (a) the purpose of the Sub-Accounts is to enhance the management of your Account(s) as a Seller, and you shall not use the Sub-Accounts for any other purposes (including, but not limited to, fraudulent or illegal activities); (b) while Sub-Accounts are not considered “Accounts” for the purposes of these Terms of Service, the provisions of this Section 5 shall apply mutatis mutandis to the Sub-Accounts, except that references to “Accounts” shall be to the Sub-Accounts; (c) Sub-Accounts can be configured to have access to your Account(s) and the same rights as your Account(s) (or parts thereof), and you expressly acknowledge and agree that: (i) you are solely responsible for creating and configuring Sub-Accounts, and granting and controlling third-party access to the Sub-Accounts; (ii) any third parties to whom you grant access to your Sub-Accounts will have the ability to access and operate your Account; (iii) we shall be entitled to treat any actions of the Sub-Accounts as actions of your Account; and (iv) you shall be responsible for all actions of the Sub-Accounts; and (d) Healthcare2u reserves the right to amend or terminate the operation of the Sub-Account Platform and to close any Sub-Accounts at any time in its sole discretion.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            6. TERM OF USE
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            6.1 The license for use of this Site and the Services is effective until terminated. This license will terminate as set forth under these Terms of Service or if you fail to comply with any term or condition of these Terms of Service. In any such event, Healthcare2u may effect such termination with or without notice to you.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            6.2 You agree not to:
                        </Typography>
                        <ol type='a' style={{ fontSize: 13 }}>
                            <li>upload, post, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;</li>
                            <li>violate any laws, including without limitation any laws and regulation in relation to export and import restrictions, third-party rights, or our Prohibited and Restricted Items Policy;</li>
                            <li>upload, post, transmit or otherwise make available any Content featuring an unsupervised minor or use the Services to harm minors in any way;</li>
                            <li>use the Services or upload Content to impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity;</li>
                            <li>forge headers or otherwise manipulate identifiers to disguise the origin of any Content transmitted through the Services;</li>
                            <li>remove any proprietary notices from the Site;</li>
                            <li>cause, permit, or authorize the modification, creation of derivative works, or translation of the Services without the express permission of Healthcare2u;</li>
                            <li>use the Services for the benefit of any third party or any manner not permitted by the licenses granted herein;</li>
                            <li>use the Services or upload Content in a manner that is fraudulent, unconscionable, false, misleading, or deceptive;</li>
                            <li>open and/or operate multiple user accounts in connection with any conduct that violates either the letter or spirit of these Terms of Service;</li>
                            <li>access the Healthcare2u platform, open a user account, or otherwise access your user account using an emulator, simulator, bot, or other similar hardware or software;</li>
                            <li>manipulate the price of any item or interfere with other Users' listings;</li>
                            <li>take any action that may undermine the feedback or ratings systems;</li>
                            <li>attempt to decompile, reverse engineer, disassemble, or hack the Services (or any portion thereof), or to defeat or overcome any encryption technology or security measures implemented by Healthcare2u with respect to the Services and/or data transmitted, processed, or stored by Healthcare2u;</li>
                            <li>harvest or collect any information about or regarding other Account holders, including, without limitation, any personal data or information;</li>
                            <li>upload, email, post, transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</li>
                            <li>upload, email, post, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party;</li>
                            <li>upload, email, post, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other unauthorized form of solicitation;</li>
                            <li>upload, email, post, transmit or otherwise make available any material that contains software viruses, worms, Trojan-horses or any other computer code, routines, files or programs designed to directly or indirectly interfere with, manipulate, interrupt, destroy or limit the functionality or integrity of any computer software or hardware or data or telecommunications equipment;</li>
                            <li>disrupt the normal flow of dialogue, cause a screen to "scroll" faster than other Users of the Services are able to type, or otherwise act in a manner that negatively affects other Users' ability to engage in real-time exchanges;</li>
                            <li>interfere with, manipulate or disrupt the Services or servers or networks connected to the Services or any other User's use and enjoyment of the Services, or disobey any requirements, procedures, policies, or regulations of networks connected to the Site;</li>
                            <li>take any action or engage in any conduct that could directly or indirectly damage, disable, overburden, or impair the Services or the servers or networks connected to the Services;</li>
                            <li>use the Services to intentionally or unintentionally violate any applicable local, state, national, or international law, rule, code, directive, guideline, policy, or regulation including, without limitation, laws and requirements (whether or not having the force of law) relating to anti-money laundering or counter-terrorism;</li>
                            <li>use the Services in violation of or to circumvent any sanctions or embargo administered or enforced by the U.S. Department of Treasury’s Office of Foreign Assets Control, the United Nations Security Council, the European Union, or Her Majesty’s Treasury;</li>
                            <li>use the Services to violate the privacy of others or to "stalk" or otherwise harass another;</li>
                            <li>infringe the rights of Healthcare2u, including any intellectual property rights and any passing off of the same thereof;</li>
                            <li>use the Services to collect or store personal data about other Users in connection with the prohibited conduct and activities set forth above;</li>
                            <li>list items that infringe upon the copyright, trademark, or other intellectual property rights of third parties or use the Services in a manner that will infringe the intellectual property rights of others; and/or</li>
                            <li>direct or encourage another user to conduct a transaction other than on the Site.</li>
                        </ol>
                        <br/>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            6.3 You understand that all Content, whether publicly posted or privately transmitted, is the sole responsibility of the person from whom such Content originated. This means that you, and not Healthcare2u, are entirely responsible for all Content that you upload, post, email, transmit, or otherwise make available through the Site. You understand that by using the Site, you may be exposed to Content that you may consider to be offensive, indecent, or objectionable. To the maximum extent permitted by applicable law, under no circumstances will Healthcare2u be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of, or reliance on, any Content posted, emailed, transmitted, or otherwise made available on the Site.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            6.4 You acknowledge that Healthcare2u and its designees shall have the right (but not the obligation) in their sole discretion to pre-screen, refuse, delete, stop, suspend, remove, or move any Content, including without limitation any Content or information posted by you, that is available on the Site without any liability to you. Without limiting the foregoing, Healthcare2u and its designees shall have the right to remove any Content (i) that violates these Terms of Service or our Prohibited and Restricted Items Policy; (ii) if we receive a complaint from another User; (iii) if we receive a notice or allegation of intellectual property infringement or other legal instruction or request for removal; or (iv) if such Content is otherwise objectionable. We may also block delivery of a communication (including, without limitation, status updates, postings, messages, and/or chats) to or from the Services as part of our effort to protect the Services or our Users, or otherwise enforce the provisions of these Terms and Conditions. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including, without limitation, any reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that you have not and, to the maximum extent permitted by applicable law, may not rely on any Content created by Healthcare2u or submitted to Healthcare2u, including, without limitation, information in Healthcare2u Forums and in all other parts of the Site.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            6.5 You acknowledge, consent to, and agree that Healthcare2u may access, preserve, and disclose your Account information, Content, and any other materials or information you provide to us to any legal, regulatory, or governmental authority, the relevant rights owner, or other third parties if required to do so by law pursuant to an order of a court or lawful request by any governmental or regulatory authority having jurisdiction over Healthcare2u or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce these Terms of Service or our Prohibited and Restricted Items Policy; (c) respond to claims that any Content violates the rights of third parties, including intellectual property rights; (d) respond to your requests for customer service; or (e) protect the rights, property, or personal safety of Healthcare2u, its Users, and/or the public.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            7. VIOLATION OF OUR TERMS OF SERVICE
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            7.1 Violations of this policy may result in a range of actions, including, without limitation, any or all of the following:
                        </Typography>
                        <ul style={{ fontSize: 13 }}>
                            <li>Listing deletion</li>
                            <li>Limits placed on Account privileges</li>
                            <li>Account suspension and subsequent termination</li>
                            <li>Criminal charges</li>
                            <li>Civil actions, including without limitation a claim for damages and/or interim or injunctive relief</li>
                        </ul>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            7.2 If you believe a User on our Site is violating these Terms of Service, please contact support@healthcare2u.asia.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            8. REPORTING INTELLECTUAL PROPERTY RIGHTS INFRINGEMENT
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            8.1 As stated above, Healthcare2u does not allow listings that violate the intellectual property rights of brands or other intellectual property rights owners (“IPR Owner”).
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            8.2 Except where expressly stated otherwise, the Users are independent individuals or businesses, and they are not associated with Healthcare2u in any way. Healthcare2u is neither the agent nor representative of the Users and does not hold and/or own any of the merchandise listed on the Site.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            8.3 If you are an IPR Owner or an agent duly authorized by an IPR Owner (“IPR Agent”) and you believe that your right or your principal’s right has been infringed, please notify us by submitting the Intellectual Property Right (IPR) form and provide us with the documents requested below to support your claim. Please allow us time to process the information provided. Healthcare2u will respond to your complaint as soon as practicable.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            8.4 Complaints under this Section 8 must be provided in the form prescribed by Healthcare2u, which may be updated from time to time, and must include at least the following: (a) a physical or electronic signature of an IPR Owner or IPR Agent (collectively, “Informant”); (b) a description of the type and nature of the intellectual property right that is allegedly infringed and proof of rights; (c) a description of the nature of alleged infringement with sufficient details to enable Healthcare2u to assess the complaint (d) URL(s) of the listing(s) which contain the alleged infringement; (e) sufficient information to allow Healthcare2u to contact the Informant, such as the Informant’s physical address, telephone number, and e-mail address; (f) a statement by the Informant that the complaint is filed on good faith belief and that the use of the intellectual property as identified by the Informant is not authorized by the IPR Owner or the law; (g) a statement by the Informant that the information in the notification is accurate, that the Informant will indemnify us for any damages we may suffer as a result of the information provided by the Informant, and that the Informant has the appropriate right or is authorized to act on the IPR Owner’s behalf in all matters relating to the complaint.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            8.5 Healthcare2u acknowledges that a brand or manufacturer may, subject to applicable law, have the right to enter into certain exclusive distribution agreements or minimum advertised price agreements for its products with other third parties. However, violations of such agreements do not constitute intellectual property rights infringement. As the enforcement of these agreements is a matter between the brand or manufacturer and the respective third parties with whom it contracts, Healthcare2u generally does not assist in the enforcement of such exclusive distribution rights or price-control matters except where required by law.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            8.6 Without limitation to the indemnity at Section 32, each and every Seller agrees to indemnify and hold Healthcare2u harmless from any and all claims, causes of action, damages, and judgments arising out of or relating to (a) any intellectual property infringement claim, complaint, or violation relating to any Content or product listings, and/or (b) any removal of such Content or product listings pursuant to or in relation to any intellectual property infringement claim, complaint, or violation.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            9. PURCHASE AND PAYMENT
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            9.1 Healthcare2u supports one or more of the following payment methods in each country it operates in:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>Credit Card<br />Card payments are processed through third-party payment channels, and the type of credit cards accepted by these payment channels may vary depending on the jurisdiction you are in.</li>
                            <li>Cash on Delivery (“COD”)<br />Healthcare2u provides COD services in selected countries. Buyers may pay cash directly to the delivery agent upon their receipt of the purchased item.</li>
                            <li>Bank Transfer<br />Buyers may make payments through an Automated Teller Machine or internet bank transfer (“Bank Transfer”) to our designated Healthcare2u Guarantee Account (as defined in Section 12). Buyers must provide Healthcare2u with the transfer receipt or payment transaction reference for verification purposes through the ‘Upload Receipt’ function found in Healthcare2u’s app as payment confirmation. If payment confirmation is not received by Healthcare2u within three (3) days, the buyer's order will be canceled.</li>
                            <li>Instalment Purchase Plan<br />Buyers may pay via instalment for orders exceeding RM 500. Payment via instalments is processed through third-party channels. The banks that currently support instalment plans are Malayan Banking Berhad and Public Bank Berhad.</li>
                        </ol>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            9.2 Buyers may only change their preferred mode of payment for their purchase prior to making payment.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            9.3 Healthcare2u takes no responsibility and assumes no liability for any loss or damages to the buyer arising from shipping information and/or payment information entered by the buyer or wrong remittance by the buyer in connection with the payment for the items purchased. We reserve the right to check whether the buyer is duly authorized to use a certain payment method and may suspend the transaction until such authorization is confirmed or cancel the relevant transaction where such confirmation is not available.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            10. SELLER BALANCE
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.1 Your Account allows the storage of money you receive from your sales proceeds made on the Platform (“Seller Balance”). The sum of this money, minus any withdrawals, will be reflected as your Seller Balance’s balance.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.2 You may transfer funds from your Seller Balance (up to the amount of your Seller Balance balance) to your linked bank account (“Linked Account”) by submitting a transfer request (“Withdrawal Request”) a maximum of once per day. Healthcare2u may also automatically transfer funds from your Seller Balance to your Linked Account on a regular basis, as determined by Healthcare2u. Healthcare2u shall only process such transfers on business days, and such transfers may take up to two business days to be credited to your Linked Account.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.3 Sellers are entitled to one (1) Withdrawal Request per week on every Thursday (“Weekly Withdrawal Limit”). Healthcare2u may impose a fee of RM0.12 for each additional Withdrawal Request made in excess of the Weekly Withdrawal Limit (“Withdrawal Fee”). The Withdrawal Fee is subjected to Sales and Service Tax (“SST”). The Weekly Withdrawal Limit and the Withdrawal Fee are subject to change at Healthcare2u’s discretion.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.4 Money from your sale of items on Healthcare2u shall be credited to your Seller Balance within three (3) days after the item is delivered to the Buyer or immediately after the Buyer has acknowledged that they have received the item.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.5 Once submitted, you may not modify or cancel a Withdrawal Request.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.6 If there is an error in the processing of any transaction, you authorize us to initiate debit or credit entries to your designated bank account to correct such errors, provided that any such correction is made in accordance with applicable laws and regulations. If we are unable to debit your designated bank account for any reason, you authorize us to resubmit the debit, plus any applicable fees, to any other bank account or payment instrument that you have on file with us or to deduct the debit and applicable fees from your Seller Balance in the future. If we are unable to credit your Alternative Account, for any reason, we reserve the right to handle or process such money in accordance with applicable law, including lodging such money with the Registrar of Unclaimed Moneys (Jabatan Akauntan Negara Malaysia) (“Registrar”) in accordance with the requirements under the Malaysia Unclaimed Moneys Act. In such instance, you may recover your unclaimed money directly from the Registrar, less any fees or charges that may be imposed, with no further recourse to us.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.7 You authorize us to initiate debit or credit entries to your Seller Balance:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>to correct any errors in the processing of any transaction;</li>
                            <li>in connection with you wrongly categorizing products that impact the Healthcare2u Commission Fee (defined below);</li>
                            <li>where Healthcare2u has determined that you have engaged in fraudulent or suspicious activity and/or transactions;</li>
                            <li>in connection with any lost, damaged, or incorrect items;</li>
                            <li>in connection with any rewards or rebates;</li>
                            <li>in connection with any uncharged fees;</li>
                            <li>in connection with the settlement of any transaction dispute, including any compensation due to, or from, you;</li>
                            <li>in connection with any banned items or items that are detained by customs;</li>
                            <li>in connection with any change of mind agreed to by both the Buyer and Seller;</li>
                            <li>to recover any outstanding amounts (including late payment fees and/or interest) owed by you to us; and</li>
                            <li>to purchase Advertising Credits where you have activated the Advertising Credits Top-Up Feature and your Advertising Credits have fallen below your Advertising Credits Minimum Amount.</li>
                        </ol>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.8 We have the right to limit, freeze, or suspend your Seller Balance for any reason, including where you fail to repay outstanding amounts due to us by the due date.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            10.9 If your Account has been terminated, deactivated, or suspended in accordance with these Terms of Service, or your Account remains inactive (for such duration as Healthcare2u may prescribe from time to time), we shall be entitled to charge a fee for the administration of any remaining Seller Balance in your Account. The amount and frequency of such fee shall be prescribed by Healthcare2u from time to time.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            11. DELIVERY
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            11.1 If Seller informs Healthcare2u that it will deliver the relevant purchased item(s), Seller shall arrange for the delivery of the purchased item(s) according to the information provided by Buyer. Healthcare2u will inform Seller when Healthcare2u receives Buyer’s Purchase Monies in order to arrange for the delivery of such purchased item(s). Unless otherwise agreed with Healthcare2u, Seller should then make the necessary arrangements to have the purchased item delivered to Buyer and provide details such as the name of the delivery company, the tracking number, etc. to Buyer through the Site.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            11.2 Seller must use his/her best effort to ensure that Buyer receives the purchased item(s) within, whichever applicable, the Healthcare2u Guarantee Period or the time period specified (for offline payment) by Seller on Seller’s listing, and Seller shall be responsible for the delivery fee for any purchased items(s) (which Seller may charge from Buyer) and bears all risk attached to the delivery of the purchased item(s).
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            11.3 Users understand that Seller bears all risk attached to the delivery of the purchased item(s) and warrants that he/she has or will obtain adequate insurance coverage for the delivery of the purchased item(s). In the event where the purchased item(s) is damaged, lost, or failure of delivery during the course of delivery, Users acknowledge and agree that Healthcare2u will not be liable for any damage, expense, cost, or fees resulted therefrom, and Seller and/or Buyer will reach out to the logistic service provider to resolve such dispute.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            11.4 For Cross-Border Transaction. Users understand and acknowledge that, where an item listing states that the item will ship from overseas, such item is being sold from a Seller based outside of Malaysia, and the importation and exportation of such item is subject to local laws and regulations. Users should familiarize themselves with all import and export restrictions that apply to the designating country. Users acknowledge that Healthcare2u cannot provide any legal advice in this regard and agree that Healthcare2u shall not bear any risks or liabilities associated with the import and export of such items to Malaysia.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            11.5 Healthcare2u also operates an online platform whereby Buyers may, amongst other things, contract with third party delivery partners (“Delivery Partners”) for the provision of delivery services of food and/or beverages in accordance with the Healthcare2u Food Terms of Service. Such engagement of Delivery Partners shall be (i) subject to a separate contract between the Buyer and the relevant Delivery Partner (and Healthcare2u shall not incur any liability in connection thereto); (ii) at the cost and expense of the relevant Buyer; and (iii) in accordance with the Healthcare2u Food Terms of Service, Delivery Partners Terms of Service, and such other requirements (including requirements as to items which may not be delivered by Delivery Partners) as may be notified by Healthcare2u from time to time.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            11.6 Seller acknowledges and agrees that if the Buyer elects to engage a Delivery Partner to deliver a purchased item, the Seller shall make such purchased item available for pick-up by the Delivery Partner within such timelines and in accordance with such procedures as may be notified by Healthcare2u from time to time. In the event of Seller’s non-compliance with the foregoing, Healthcare2u may take such action or steps as it deems necessary at its sole discretion, including but not limited to, charging additional delivery fee to Seller, cancellation of the relevant order, issuance of warnings and/or suspension and/or termination of the Seller’s Account.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            11.7 Where the Buyer elects to have a purchased item delivered by any other shipping method, the fee payable to the delivery company (“Shipping Fee”) shall be borne by the Buyer and Seller, in such proportions as may be determined by Healthcare2u and published on the Site from time to time. Healthcare2u shall (i) collect the Buyer’s proportion of the Shipping Fee from the Buyer, (ii) deduct the Seller’s proportion of the Shipping Fee from the Buyer’s Purchase Monies, and (iii) pay the total Shipping Fee to the delivery company.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            12. CANCELLATION, RETURN, AND REFUND
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            12.1 Buyer may only cancel his/her order prior to the payment of Buyer’s Purchase Monies into Healthcare2u Guarantee Account.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            12.2 Buyer may apply for the return of the purchased item and refund prior to the expiry of Healthcare2u Guarantee Period, if applicable, subject to and in accordance with Healthcare2u’s Refunds and Return Policy. Please refer to Healthcare2u’s Refunds and Return Policy for further information.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            12.3 Healthcare2u reserves the right to cancel any transaction on the Site, and Buyer agrees that Buyer’s sole remedy will be to receive a refund of the Buyer’s Purchase Monies paid into Healthcare2u Guarantee Account.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            12.4 Healthcare2u does not monitor the cancellation, return, and refund process for offline payment.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            13. HEALTHCARE2U RESPONSIBILITIES
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.1 Healthcare2u shall properly manage and ensure that relevant information such as the price and the details of items, inventory amount, and terms and conditions for sales are updated on Healthcare2u's listing and shall not post inaccurate or misleading information.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.2 Healthcare2u shall also ensure that Healthcare2u properly and correctly categorizes each product that Healthcare2u intends to list on the Site by following this guide found at the Healthcare2u Seller Education Hub. Healthcare2u acknowledges and agrees that the guide may be updated from time to time by Healthcare2u, and Healthcare2u agrees to follow any updated guide thereafter. Healthcare2u agrees that in the event a product is wrongly categorized, Healthcare2u may take such actions as may be necessary as provided in this article in the Healthcare2u Seller Education Hub. The article may be updated by Healthcare2u from time to time. In the event of any dispute over the categorization of products, Healthcare2u agrees that Healthcare2u has the final decision over the dispute.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.3 The price of items for sale will be determined by Healthcare2u at its own discretion. The price of an item and shipping charges shall include the entire amount to be charged to the Buyer, such as sales tax, value-added tax, tariffs, etc., and Healthcare2u shall not charge the Buyer such amount additionally and separately.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.4 Healthcare2u agrees that it may, at its discretion, engage in promotional activities to induce transactions between the Buyer and Healthcare2u by reducing, discounting, or refunding fees, or in other ways. The final price that the Buyer will pay will be the price to which such adjustment is applied.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.5 For the purpose of promoting the sales of the items listed by Healthcare2u, Healthcare2u may post such items (at adjusted prices) on third-party websites (such as portal sites and price comparison sites) and other websites (domestic or foreign) operated by Healthcare2u.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.6 Healthcare2u shall issue receipts, credit card slips, or tax invoices to the Buyer upon request.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.7 Healthcare2u acknowledges and agrees that Healthcare2u will be responsible for paying all taxes, customs, and duties for the item sold, and Healthcare2u cannot provide any legal or tax advice in this regard. As tax laws and regulations may change from time to time, Healthcare2u is advised to seek professional advice if in doubt.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            13.8 Healthcare2u acknowledges and agrees that Healthcare2u's violation of any of Healthcare2u's policies will result in a range of actions as stated in Section 7.1.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            14. PURCHASE AND SALE OF ALCOHOL
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            14.1 The purchase and sale of alcoholic products ("Alcohol") on the Site are permitted by Healthcare2u subject to the terms and conditions of this Section 14. If you are an Alcohol Buyer ("Alcohol Buyer"), you will be deemed to have consented to the terms and conditions in this Section 14 when you purchase Alcohol on the Site. Similarly, if you are an approved seller of Alcohol ("Alcohol Seller"), you will be deemed to have consented to the terms and conditions in this Section 14 when you sell Alcohol on the Site.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            14.2 If you are an Alcohol Buyer:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>You represent and warrant that you and (if applicable) the person receiving the Alcohol ("Recipient") are (a) aged 21 or above; (b) are not Muslim or otherwise prohibited from buying Alcohol under any law, regulation, or religion; and (c) understand the following: "MEMINUM ARAK BOLEH MEMBAHAYAKAN KESIHATAN"; and</li>
                            <li>If requested by an Alcohol Seller or Healthcare2u (or its agents), you and/or the Recipient shall provide photo identification for age verification purposes.</li>
                        </ol>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            14.3 If you are an Alcohol Seller, you represent and warrant that:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>You are not Muslim or otherwise prohibited from selling Alcohol under any law, regulation, or religion;</li>
                            <li>You hold all necessary licenses and/or permits to sell Alcohol through the Site, and shall provide a copy of such licenses and/or permits and supporting documents to Healthcare2u immediately upon request for verification purposes; and</li>
                            <li>All information and documents provided to Healthcare2u are true and accurate.</li>
                        </ol>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            14.4 When delivering Alcohol to an Alcohol Buyer:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>The delivery agent reserves the right to request valid photo identification for age verification purposes; and</li>
                            <li>Healthcare2u (via the delivery agent) reserves the right to refuse the delivery of Alcohol if the Alcohol Buyer and/or the Recipient appears intoxicated or is unable to provide valid photo identification for age verification purposes.</li>
                        </ol>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            14.5 Each Alcohol Buyer and Alcohol Seller severally agrees to indemnify, defend, and hold harmless Healthcare2u, and its shareholders, subsidiaries, affiliates, directors, officers, agents, co-branders, or other partners, and employees (collectively, the "Indemnified Parties") from and against any and all claims, actions, proceedings, and suits and all related liabilities, damages, settlements, penalties, fines, costs, and expenses (including, without limitation, any other dispute resolution expenses) incurred by any Indemnified Party arising out of or relating to: (a) any inaccuracy or breach of its representations in Section 14.2 and/or Section 14.3 (as applicable); and (b) its breach of any law or any rights of a third party.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            15. TRANSACTION FEES & COMMISSION FEES
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            15.1 Healthcare2u charges a fee for all successful transactions completed on the Platform, including any other program fees that may be charged by Healthcare2u under any other program ("Transaction Fee"). The Transaction Fee charged for a successful transaction completed on the Platform is calculated at two percent (2%) of the Buyer's Purchase Monies, rounded up to the nearest cent, in addition to any other program fees (where applicable).
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            15.2 For Sellers located outside of Malaysia, Healthcare2u charges a fee for all successful transactions completed on the Platform ("Cross Border Fee"). The Cross Border Fee is borne by the Seller and is calculated according to the rates as notified to such Sellers from time to time on the Platform.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            15.3 In addition to Transaction Fees, Healthcare2u also charges a fee for all successful transactions completed by Sellers on the Platform ("Healthcare2u Commission Fee"). The Healthcare2u Commission Fee is borne by the Seller and is calculated by percentage based on the gross settlement price of the product ("Commission Rate"). The Commission Rate varies depending on the category of the product sold and is provided in this article found at the Seller Education Hub. Healthcare2u may amend the Commission Rate from time to time by announcing the amendment in the Seller Education Hub.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            15.4 The gross settlement price refers to the final product price after deducting seller vouchers and other seller promotions (e.g. seller free shipping, seller discounts, buyer-paid shipping fee). For the avoidance of doubt, the gross settlement price shall not be affected by Healthcare2u vouchers, Healthcare2u rebates. For more details, please refer to this article found at the Seller Education Hub.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            15.5 The Healthcare2u Commission Fee provided in these Terms of Service does not apply to non-Mall Sellers. For Mall Sellers, the rates provided in the Healthcare2u Mall Terms of Service shall continue to apply to the Mall Sellers.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            15.6 The Transaction Fee and the Healthcare2u Commission Fee is subject to SST ("Tax Amount"), and the Seller is responsible for such Tax Amount.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            15.7 Following the successful completion of a transaction, Healthcare2u shall deduct the Transaction Fee, Healthcare2u Commission Fee, and the Tax Amount, and the Cross Border Fee (as applicable) from the Buyer's Purchase Monies, and remit the balance to the Seller. Healthcare2u shall issue receipts or tax invoices for the Transaction Fee, Healthcare2u Commission Fee, and Tax Amount paid by Seller on request.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            16. DISPUTES
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            16.1 In the event a problem arises in a transaction, the Healthcare2u Buyer and Seller agree to communicate with each other first to attempt to resolve such dispute by mutual discussions, which Healthcare2u shall use reasonable commercial efforts to facilitate. If the matter cannot be resolved by mutual discussions, Users may approach the claims tribunal of their local jurisdiction to resolve any dispute arising from a transaction.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            16.2 Each Healthcare2u Buyer and Seller covenants and agrees that it will not bring suit or otherwise assert any claim against Healthcare2u (except where Healthcare2u is the Seller of the product that the claim relates to) in relation to any transaction made on the Platform or any dispute related to such transaction.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            16.3 Users may send written request to Healthcare2u to assist them in resolving issues which may arise from a transaction upon request. Healthcare2u may, at its sole discretion and with absolutely no liability to Seller and Buyer, take all necessary steps to assist Users in resolving their dispute. For more information, please refer to Healthcare2u’s Refunds and Return Policy.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            16.4 To be clear, the services provided under this Section 16 are only available to Buyers covered under Healthcare2u.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            17. FEEDBACK
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            17.1 Healthcare2u welcomes information and feedback from our Users which will enable Healthcare2u to improve the quality of service provided. Please refer to our feedback procedure below for further information:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>Feedback may be made in writing through email to or using the feedback form found on the Platform.</li>
                            <li>Anonymous feedback will not be accepted</li>
                            <li>Users affected by the feedback should be fully informed of all facts and given the opportunity to put forward their case.</li>
                            <li>Vague and defamatory feedback will not be entertained.</li>
                        </ol>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            18. DISCLAIMERS
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            18.1 THE SERVICES ARE PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES, CLAIMS, OR REPRESENTATIONS MADE BY HEALTHCARE2U OF ANY KIND, EITHER EXPRESSED, IMPLIED, OR STATUTORY, WITH RESPECT TO THE SERVICES, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE, OR TRADE USAGE. WITHOUT LIMITING THE FOREGOING AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HEALTHCARE2U DOES NOT WARRANT THAT THE SERVICES, THIS SITE, OR THE FUNCTIONS CONTAINED THEREIN WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE, OR ERROR-FREE. HEALTHCARE2U DOES NOT WARRANT THAT DEFECTS, IF ANY, WILL BE CORRECTED, OR THAT THIS SITE AND/OR THE SERVER THAT MAKES THE SAME AVAILABLE ARE FREE OF VIRUSES, CLOCKS, TIMERS, COUNTERS, WORMS, SOFTWARE LOCKS, DROP DEAD DEVICES, TROJAN-HORSES, ROUTINGS, TRAP DOORS, TIME BOMBS, OR ANY OTHER HARMFUL CODES, INSTRUCTIONS, PROGRAMS, OR COMPONENTS.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            18.2 YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SITE AND/OR THE SERVICES REMAINS WITH YOU TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            18.3 HEALTHCARE2U HAS NO CONTROL OVER AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DOES NOT GUARANTEE OR ACCEPT ANY RESPONSIBILITY FOR: (A) THE FITNESS FOR PURPOSE, EXISTENCE, QUALITY, SAFETY, OR LEGALITY OF ITEMS AVAILABLE VIA THE SERVICES; OR (B) THE ABILITY OF SELLERS TO SELL ITEMS OR OF BUYERS TO PAY FOR ITEMS. IF THERE IS A DISPUTE INVOLVING ONE OR MORE USERS, SUCH USERS AGREE TO RESOLVE SUCH DISPUTE BETWEEN THEMSELVES DIRECTLY AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RELEASE HEALTHCARE2U FROM ANY AND ALL CLAIMS, DEMANDS, AND DAMAGES ARISING OUT OF OR IN CONNECTION WITH ANY SUCH DISPUTE.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            19. EXCLUSIONS AND LIMITATIONS OF LIABILITY
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            19.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL HEALTHCARE2U BE LIABLE WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE - WHETHER ACTIVE, PASSIVE, OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY, OR OTHER THEORY, OR OTHER CAUSE OF ACTION AT LAW, IN EQUITY, BY STATUTE, OR OTHERWISE, FOR:
                        </Typography>
                        <ol type='i' style={{ fontSize: 13 }}>
                            <li>(A) LOSS OF USE; (B) LOSS OF PROFITS; (C) LOSS OF REVENUES; (D) LOSS OF DATA; (E) LOSS OF GOOD WILL; OR (F) FAILURE TO REALIZE ANTICIPATED SAVINGS, IN EACH CASE WHETHER DIRECT OR INDIRECT; OR</li>
                            <li>ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE USE OR INABILITY TO USE THIS SITE OR THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES RESULTING THEREFROM, EVEN IF HEALTHCARE2U HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                        </ol>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            19.2 YOU ACKNOWLEDGE AND AGREE THAT YOUR ONLY RIGHT WITH RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO REQUEST FOR TERMINATION OF YOUR ACCOUNT AND/OR DISCONTINUE ANY USE OF THE SERVICES.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            19.3 IF, NOTWITHSTANDING THE PREVIOUS SECTIONS, HEALTHCARE2U IS FOUND BY A COURT OF COMPETENT JURISDICTION TO BE LIABLE (INCLUDING FOR GROSS NEGLIGENCE), THEN, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ITS LIABILITY TO YOU OR TO ANY THIRD PARTY IS LIMITED TO THE LESSER OF:
                            (A) ANY AMOUNTS DUE AND PAYABLE TO YOU PURSUANT TO THE HEALTHCARE2U GUARANTEE; AND (B) RM 100 (ONE HUNDRED RINGGIT).
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            19.4 NOTHING IN THESE TERMS OF SERVICE SHALL LIMIT OR EXCLUDE ANY LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY HEALTHCARE2U’S NEGLIGENCE, FOR FRAUD, OR FOR ANY OTHER LIABILITY ON THE PART OF HEALTHCARE2U THAT CANNOT BE LAWFULLY LIMITED AND/OR EXCLUDED.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            20. LINKS TO THIRD PARTY SITES AND SHARING VIDEOS FROM YOUTUBE
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            20.1 Third party links provided throughout the Site will let you leave this Site. These links are provided as a courtesy only, and the sites they link to are not under the control of Healthcare2u in any manner whatsoever, and you, therefore, access them at your own risk. Healthcare2u is in no manner responsible for the contents of any such linked site or any link contained within a linked site, including any changes or updates to such sites. Healthcare2u is providing these links merely as a convenience, and the inclusion of any link does not in any way imply or express affiliation, endorsement, or sponsorship by Healthcare2u of any linked site and/or any of its content therein.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            20.2 Healthcare2u allows you to share videos from YouTube on the Healthcare2u Livestream feature ("YouTube Content"). By sharing YouTube Content, you hereby agree to be bound by the YouTube Terms of Service (https://www.youtube.com/t/terms).
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            21. YOUR CONTRIBUTIONS TO THE SERVICES
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            21.1 By submitting Content for inclusion on the Services, you represent and warrant that you have all necessary rights and/or permissions to grant the licenses below to Healthcare2u. You further acknowledge and agree that you are solely responsible for anything you post or otherwise make available on or through the Services, including, without limitation, the accuracy, reliability, nature, rights clearance, compliance with law, and legal restrictions associated with any Content contribution. You hereby grant Healthcare2u and its successors a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable, and transferable license to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform such Content contribution on, through, or in connection with the Services in any media formats and through any media channels, including, without limitation, for promoting and redistributing part of the Services (and its derivative works) without the need for attribution, and you agree to waive any moral rights (and any similar rights in any part of the world) in that respect. You understand that your contribution may be transmitted over various networks and changed to conform and adapt to technical requirements.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            21.2 Any Content, material, information, or idea you post on or through the Services or otherwise transmit to Healthcare2u by any means (each, a "Submission"), is not considered confidential by Healthcare2u and may be disseminated or used by Healthcare2u without compensation or liability to you for any purpose whatsoever, including, but not limited to, developing, manufacturing, and marketing products. By making a Submission to Healthcare2u, you acknowledge and agree that Healthcare2u and/or other third parties may independently develop software, applications, interfaces, products, and modifications and enhancements of the same, which are identical or similar in function, code, or other characteristics to the ideas set out in your Submission. Accordingly, you hereby grant Healthcare2u and its successors a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable, and transferable license to develop the items identified above and to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform any Submission on, through, or in connection with the Services in any media formats and through any media channels, including, without limitation, for promoting and redistributing part of the Services (and its derivative works). This provision does not apply to personal information that is subject to our privacy policy except to the extent that you make such personal information publicly available on or through the Services.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            22. THIRD PARTY CONTRIBUTIONS TO THE SERVICES AND EXTERNAL LINKS
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            22.1 Each contributor to the Services of data, text, images, sounds, video, software, and other Content is solely responsible for the accuracy, reliability, nature, rights clearance, compliance with law, and legal restrictions associated with their Content contribution. As such, Healthcare2u is not responsible to, and shall not regularly monitor or check for the accuracy, reliability, nature, rights clearance, compliance with law, and legal restrictions associated with any contribution of Content. You will not hold Healthcare2u responsible for any User's actions or inactions, including, without limitation, things they post or otherwise make available via the Services.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            22.2 In addition, the Services may contain links to third party products, websites, services, and offers. These third party links, products, websites, and services are not owned or controlled by Healthcare2u. Rather, they are operated by, and are the property of, the respective third parties and may be protected by applicable copyright or other intellectual property laws and treaties. Healthcare2u has not reviewed and assumes no responsibility for the content, functionality, security, services, privacy policies, or other practices of these third parties. You are encouraged to read the terms and other policies published by such third parties on their websites or otherwise. By using the Services, you agree that Healthcare2u shall not be liable in any manner due to your use of, or inability to use, any website or widget. You further acknowledge and agree that Healthcare2u may disable your use of, or remove, any third-party links or applications on the Services to the extent they violate these Terms of Service.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            23. YOUR REPRESENTATIONS AND WARRANTIES
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            23.1 You represent and warrant that:
                        </Typography>
                        <ol type='a' style={{ fontSize: 13 }}>
                            <li>you possess the legal capacity (and in the case of a minor, valid parent or legal guardian consent), right, and ability to enter into these Terms of Service and to comply with its terms; and</li>
                            <li>you will use the Services for lawful purposes only and in accordance with these Terms of Service and all applicable laws, rules, codes, directives, guidelines, policies, and regulations.</li>
                        </ol>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            23. FRAUDULENT OR SUSPICIOUS ACTIVITY
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            24.1 If Healthcare2u, in its sole discretion, believes that you may have engaged in any potentially fraudulent or suspicious activity and/or transactions, we may take various actions to protect Healthcare2u, other Buyers or Sellers, other third parties, or you from Reversals, Chargebacks, Claims, fees, fines, penalties, and any other liability. The actions we may take include but are not limited to the following:
                        </Typography>
                        <ol type='a' style={{ fontSize: 13 }}>
                            <li>We may close, suspend, or limit your access to your Account or the Services, and/or suspend the processing of any transaction;</li>
                            <li>We may suspend your eligibility for Healthcare2u Guarantee;</li>
                            <li>We may hold, apply or transfer the funds in your Account as required by judgments and orders which affect you or your Account, including judgments and orders issued by a competent court or elsewhere and directed to Healthcare2u;</li>
                            <li>We may refuse to provide the Services to you now and in the future;</li>
                            <li>We may hold your funds for a period of time reasonably needed to protect against the risk of liability to Healthcare2u or a third party, or if we believe that you may be engaging in potentially fraudulent or suspicious activity and/or transactions.</li>
                        </ol>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            For the purposes of this Section:
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            "Chargeback" means a request that a Buyer files directly with his or her debit or credit card company or debit or credit card issuing bank to invalidate a payment.
                            "Claim" means a challenge to a payment that a Buyer or Seller files directly with Healthcare2u.
                            "Reversal" means the reversal of a payment by Healthcare2u because (a) it is invalidated by the sender's bank, (b) it was sent to you in error by Healthcare2u, (c) the sender of the payment did not have authorization to send the payment (for example: the sender used a stolen credit card), (d) you received the payment for activities that violated these Terms of Service or any other Healthcare2u policy, or (e) Healthcare2u decided a Claim against you.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            25. INDEMNITY
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            25.1 You agree to indemnify, defend, and hold harmless Healthcare2u, and its shareholders, subsidiaries, affiliates, directors, officers, agents, co-branders, or other partners, and employees (collectively, the "Indemnified Parties") from and against any and all claims, actions, proceedings, and suits and all related liabilities, damages, settlements, penalties, fines, costs, and expenses (including, without limitation, any other dispute resolution expenses) incurred by any Indemnified Party arising out of or relating to: (a) any transaction made on the Site, or any dispute in relation to such transaction (except where Healthcare2u is the Seller in the transaction that the dispute relates to), (b) the Healthcare2u Guarantee, (c) the hosting, operation, management, and/or administration of the Services by or on behalf of Healthcare2u, (d) your violation or breach of any term of these Terms of Service or any policy or guidelines referenced herein, (e) your use or misuse of the Services, (f) your breach of any law or any rights of a third party, or (g) any Content uploaded by you.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            26. SEVERABILITY
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            26.1 If any provision of these Terms of Service shall be deemed unlawful, void, or for any reason unenforceable under the law of any jurisdiction, then that provision shall be deemed severable from these terms and conditions and shall not affect the validity and enforceability of any remaining provisions in such jurisdiction nor the validity and enforceability of the provision in question under the law of any other jurisdiction.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            27. GOVERNING LAW
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            27.1 These Terms of Service shall be governed by and construed in accordance with the laws of Malaysia without regard to its conflict of law rules. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act, to the extent applicable, are expressly disclaimed. Unless otherwise required by applicable laws, any dispute, controversy, claim, or difference of any kind whatsoever shall arise out of or relate to these Terms of Service against or relating to Healthcare2u or any Indemnified Party under these Terms of Service shall be referred to and finally resolved by arbitration in Malaysia in accordance with the Arbitration Rules of the Asian International Arbitration Centre (Malaysia) (“AIAC Rules”) for the time being in force, which rules are deemed to be incorporated by reference in this Section. There will be one (1) arbitrator, and the language of the arbitration shall be English.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            28. GENERAL PROVISIONS
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.1 Healthcare2u reserves all rights not expressly granted herein.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.2 Healthcare2u may modify these Terms of Service at any time by posting the revised Terms of Service on this Site. Your continued use of this Site and/or receipt of any Services (including, for the avoidance of doubt, the continued provision of an Account to you by Healthcare2u) after such changes have been posted shall constitute your acceptance of such revised Terms of Service.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.3 You may not assign, sublicense, or transfer any rights granted to you hereunder or subcontract any of your obligations.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.4 Nothing in these Terms of Service shall constitute a partnership, joint venture, or principal-agent relationship between you and Healthcare2u, nor does it authorize you to incur any costs or liabilities on Healthcare2u’s behalf.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.5 The failure of Healthcare2u at any time or times to require performance of any provision hereof shall in no manner affect its right at a later time to enforce the same unless the same is waived in writing.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.6 These Terms of Service are solely for your and our benefit and are not for the benefit of any other person or entity, except for Healthcare2u's affiliates and subsidiaries (and each of Healthcare2u's and its affiliates' and subsidiaries' respective successors and assigns).
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.7 The terms set forth in these Terms of Service and any agreements and policies included or referred to in these Terms of Service constitute the entire agreement and understanding of the parties with respect to the Services and the Site and supersede any previous agreement or understanding between the parties in relation to such subject matter. The parties also hereby exclude all implied terms in fact. In entering into the agreement formed by these Terms of Service, the parties have not relied on any statement, representation, warranty, understanding, undertaking, promise, or assurance of any person other than as expressly set out in these Terms of Service. Each party irrevocably and unconditionally waives all claims, rights, and remedies which but for this Section it might otherwise have had in relation to any of the foregoing. These Terms of Service may not be contradicted, explained, or supplemented by evidence of any prior agreement, any contemporaneous oral agreement, or any consistent additional terms.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.8 You agree to comply with all applicable laws, statutes, regulations, and codes relating to anti-bribery and corruption, including, without limitation, the UK Bribery Act, the US Foreign Corrupt Practices Act, the Singapore Prevention of Corruption Act, and the Malaysian Anti-Corruption Commission Act, and confirm that you have and shall have in place all policies and procedures needed to ensure compliance with such requirements.
                        </Typography>
                        <br />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            28.9 If you have any questions or concerns about these Terms of Service or any issues raised in these Terms of Service or on the Site, please contact us at: support@healthcare2u.asia.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            LEGAL NOTICES: Please send all legal notices to info@healthcare2u.asia and Attention it to the “General Counsel”.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-title" variant="body1" style={{ fontWeight: 'bold' }}>
                            I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE AND ANY REVISION THE SAME HEREAFTER. BY CLICKING THE “SIGN UP” OR “CONNECT WITH FACEBOOK” BUTTON DURING REGISTRATION, I UNDERSTAND THAT I AM CREATING A DIGITAL SIGNATURE, WHICH I INTEND TO HAVE THE SAME FORCE AND EFFECT AS IF I HAD SIGNED MY NAME MANUALLY.
                        </Typography>
                        <br />

                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 13 }}>
                            Last Modified: 16th October 2023
                        </Typography>
                    </Container>
                </div>
            </div>
        </Box>
    );
}
