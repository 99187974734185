import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Card, CardContent, Breadcrumbs, Typography, Container, Grid, Link, CardMedia, Divider, Stack, List, ListItem, TextField, MenuItem, Hidden, ListItemText, Tooltip } from '@material-ui/core';
import { createMuiTheme, ThemeProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { style } from '@material-ui/system';
import { Pagination, PaginationItem } from '@material-ui/lab';

import { getUrl } from '../helper/ApiAction';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import WebpImg from './Layouts/WebpImg';
// import TitleBar from './Layouts/TitleBar';
import TitleBar from './Layouts/TitleBarTransparent';
import ImgHeader from './Layouts/ImgHeader';
import BackToTop from './Layouts/BackToTop';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import { NavigateNext, Favorite } from '@material-ui/icons';
import { FiChevronLeft } from 'react-icons/fi';

export default function CategoriesNew() {
    const styles = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isMountedRef = useRef(null);
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    let query = useQuery();
    
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { rank } = useSelector(state => state.user);
    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    let { id } = useParams();
    
    const pageNumber = query.get("page");

    const [slideBanner, setSlideBanner] = useState(null);
    const [breadcrumb, setCategoryBreadCrumb] = useState([]);
    const [categoryChilds, setCategoryChilds] = useState([]);
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState('all');
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);

    let currency_rate = currencyRate;
    let currency_code = [];
    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // ------------------------------------- API ---------------------------------------------
    useEffect(() => {
        isMountedRef.current = true;

        if (id !== "all") {
            getUrl(`categories/${id}/breadcrumb`).then(response => {
                if (response) {
                    if (isMountedRef.current) {
                        setCategoryBreadCrumb(response.data);
                        setCategory(response.data[0].id);
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

            getUrl(`categories/${id}`).then(response => {
                console.log(response);
                if (response) {
                    setCategoryChilds(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }

        getUrl('categories?level=1').then(categoryList => {
            if (isMountedRef.current) {
                setCategories(categoryList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        
        getUrl('sliderbanners').then(slideBannerList => {
            if (isMountedRef.current) {
                setSlideBanner(slideBannerList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        
        getUrl(`products?cat=${id}&page=${page}&per_page=20`).then(response => {
            if (response) {
                if (isMountedRef.current) {
                    setLoading(false);

                    let { current_page, last_page } = response.data.meta;
                    setTotal(last_page);
                    
                    if (page < last_page) {
                        window.addEventListener('scroll', handleScroll);
                    }

                    if (page > 1) {
                        setCategoryProducts(_.concat(categoryProducts, response.data.data));
                    } else {
                        setCategoryProducts(response.data.data);
                    }
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
        
        return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll);}
    }, [id, page, addAlert]);

    // ---------------------------------------- FUNCTIONS -----------------------------------------------
    const handleCategoryChange = (event) => {
        history.push(`/category/${event.target.value}?page=1`);
    };

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if (bottom ) {
            setPage(page + 1);
        }
    }

    // --------------------------------- DESIGN FUNCTION --------------------------------------------
    const productItemCard = (product) => {
        let bvDisplay = product.bv;
        let priceDisplay = product.sell_price;
        let showRetail = false;
        let discountPercent = 0;

        if(parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(priceDisplay)) {
            showRetail = true;
            discountPercent = ((parseFloat(product.retail_price) - parseFloat(priceDisplay)) / parseFloat(product.retail_price)) * 100;
            discountPercent = Math.round(discountPercent);
        }

        return(
            <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                <Card variant="outlined" className={styles.productHover} >
                    <CardMedia style={{ textAlign: "center" }}>
                        <WebpImg
                            containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            src={product ? product.images_array[0] : ''}
                            style={{ width: '100%', padding: '0', display: 'block',  objectFit: 'cover', height: 195, maxHeight:150, margin: "auto" }}
                            alt={product[`title_${i18n.language}`]}
                        />
                    </CardMedia>
                    <CardContent >
                        <div style={{ textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <Tooltip title={product[`title_${i18n.language}`]}>
                                    <Typography variant="body2" color="textSecondary" className={styles.productTitleText}>
                                        {product[`title_${i18n.language}`]}
                                    </Typography>
                                </Tooltip>
                            </div>

                            <Grid container style={{ paddingTop: 10 }}>
                                <Grid item xs={8}>
                                    {
                                        showRetail
                                            ?
                                            <Typography align="center" variant="body2" style={{ fontSize: 11, height: 18, fontWeight: 'bold', textDecoration: 'line-through', textAlign: 'left' }}>
                                                <NumberFormat value={(product.retail_price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                            </Typography>
                                            : <Typography style={{ height: 18 }} />
                                    }
                                    <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}>
                                        <NumberFormat value={(priceDisplay * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
                                    <Typography style={{ height: 18 }} />
                                    <Typography align="center" variant="body1" style={{ fontSize: 10, fontWeight: '400px', textAlign: 'right', color: "#646464" }}>
                                        <NumberFormat value={product?.purchased_quantity} decimalScale={2} displayType={'text'} thousandSeparator={true} />&nbsp; {t(`item.sold`)}
                                    </Typography>

                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                    {
                        showRetail ?
                            <Box className={styles.showRetailBox}>
                                <Box className={styles.showRetailText}>
                                    {`${discountPercent}% `}
                                    {`${t('general.discount')}`}
                                </Box>
                            </Box>
                            : null
                    }
                </Card>
            </Link>
        )
    }

    return (
        <Box className={styles.root2} >
            <TitleBar height={70} title={t('item.products')} displayInfo currencyButton displayCart back pagetitle backUrl={'/'} />
            {/* <ImgHeader cardDisplay src={'/images/banneradv/pdt_background.png'} /> */}

            <AutoplaySlider
                play={false}
                cancelOnInteraction={false}
                interval={3000}
                autoplay={false}
                mobileTouch={true}
                bullets={_.size(slideBanner) > 0 ? true : false}
                className='category'
                organicArrows={false}
                style={{ height: 170 }}
            >
                {
                    _.size(slideBanner) > 0 && slideBanner !== null 
                        ? _.map(slideBanner, (slideBannerLists) => {
                            return (
                                <div className="awssld__content" key={slideBannerLists.id} style={{ objectFit: 'cover', width: '100%' }} >
                                    <WebpImg src={slideBannerLists.file_name} alt="slide" style={{ objectFit: 'cover', objectPosition: 'center', width: '100%' }} />
                                </div>
                            )
                        })
                        : <div className="awssld__content" style={{ objectFit: 'cover', width: '100%' }} >
                            <WebpImg src={'/images/banneradv/pdt_background.png'} alt="slide" style={{ objectFit: 'cover', objectPosition: 'center', width: '100%' }} />
                        </div>
                }
            </AutoplaySlider>

            <div className='p-lr-20'>
                <Typography variant='h6' style={{ color: '#000', fontSize: 25, textAlign: 'center', paddingTop: 20 }}><b>{t('item.products')}</b></Typography>
            </div>

                {
                    _.size(categories) > 0 &&
                    <div className='p-b-20'>
                        <List style={{ display: 'flex', overflow: 'auto', padding: '10px 20px' }}>
                            <ListItem className={id === 'all' ? styles.categoryBoxSelectedStyle : styles.categoryBoxStyle}>
                                <Link underline='none' color="primary" to={`/category/all`} component={RouterLink} className={styles.categoryTitleText} style={{ fontSize: 13, color: id === 'all' ? '#fff' : '#2E523C', height: '100%' }}>
                                    {i18n.language === 'cn' ? "所有" : "All"}
                                </Link>
                            </ListItem>
                            {
                                _.map(categories, (categoriesItem) => {
                                    const category_translate = _.split(categoriesItem.cat_name, '|');
                                    return (
                                        <ListItem key={categoriesItem.id} className={parseInt(id) === categoriesItem.id ? styles.categoryBoxSelectedStyle : styles.categoryBoxStyle}>
                                            <Link key={categoriesItem.id} underline='none' color="primary" to={`/category/${categoriesItem.id}`} component={RouterLink} className={styles.categoryTitleText} style={{ fontSize: 13, color: parseInt(id) === categoriesItem.id ? '#fff' : '#2E523C', height: '100%' }}>
                                                {i18n.language === 'cn' ? (category_translate[1] ? category_translate[1] : category_translate[0]) : category_translate[0]}
                                            </Link>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </div>
                }

            <div className='p-lr-20 p-b-90'>
                <Grid container spacing={1} justify="flex-start">
                    {
                        _.size(categoryProducts) > 0
                            ? _.map(categoryProducts, (product) => {
                                return (
                                    <Grid item xs={6} key={product.id} className={styles.cardContentRootStyle}>
                                        {productItemCard(product)}
                                    </Grid>
                                )
                            })
                            : <Box style={{ width: '100%', textAlign: 'center' }}>
                                <WebpImg alt={'emptycart'} src={'/images/no-image.png'} style={{ width: 150, maxWidth: '100%' }} />
                                <Typography style={{ textAlign: 'center' }}>{t('general.noProduct')}</Typography>
                            </Box>
                    }
                </Grid>
            </div>

            <BackToTop />
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    Tooltip: {
        fontSize: 21
    },
    root2: {
        background: theme.palette.white.mobileBkg,
        minHeight: '100%'
    },
    productHover:{
        borderRadius: '0px !important',
        border: 'none !important',
        height: '100%',
        position: 'relative',
        top: 0,
        '-webkit-transition': '0.5s',
        '-o-transition': '0.5s',
        top: 0,
        transition: '0.5s',
        "&:hover":{
            boxShadow:'0 0 19px 0 #00000029',
            borderRadius: '0 0 0 0',
            top: -5,
        }
    },
    categoryBoxStyle: {
        border: '1px solid #2E523C',
        borderRadius: 48,
        color: '#2E523C',
        textAlign: 'center',
        width: 80,
        height: 35,
        padding: '8px 5px',
        margin: '0px 5px',
    },
    categoryBoxSelectedStyle: {
        border: '1px solid #2E523C',
        borderRadius: 48,
        background: '#2E523C',
        color: '#fff',
        textAlign: 'center',
        width: 80,
        height: 35,
        padding: '8px 5px',
        margin: '0px 5px',
    },
    categoryTitleText: {
        wordWrap: 'break-word', 
        textOverflow: 'ellipsis', 
        display: '-webkit-box', 
        textAlign: 'center', 
        overflow: 'hidden', 
        width: 80,
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 1,
        textTransform: 'uppercase',
    },
    productTitleText: {
        wordWrap: 'break-word', 
        textOverflow: 'ellipsis', 
        display: '-webkit-box', 
        textAlign: 'start', 
        overflow: 'hidden', 
        width: 150, 
        height: 30, 
        lineHeight: '15px', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 2
    },
    showRetailText: {
        color: '#fff',
        textAlign: 'center',
        fontSize: 9,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        lineHeight: 1,
        padding: 1,
        width: 29,
    },
    showRetailBox: {
        position:"absolute" ,
        width: 38,
        height: '100%',
        top:0, 
        right:0,
        backgroundImage: 'url("/images/Ribbon.png")',
        backgroundRepeat: "no-repeat",
        paddingTop: 6,
    },
}));

const tooltipStyle = createMuiTheme();
const muiTheme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "15px",
                color: "#fff",
                backgroundColor: "#000000bf"
            }
        }
    }
});
