import React, { useState, useEffect, useRef } from 'react';

import { getUrl, postUrl, deleteUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';
import { useQuery } from '../helper/Tools';
import { useHistory } from 'react-router-dom';

import { Grid, Typography, Box, List, ListItem, Divider, IconButton , Chip, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Button, Stepper, Step, StepLabel, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import AddBoxIcon from '@material-ui/icons/AddBox';

export default function Integration(props) {
    const { t } = props;
    const styles = useStyles();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);
    let query = useQuery();
    let history = useHistory();

    const [storeList, setUserStoreList] = useState([]);
    const [selectedStoreIndex, setSelectedStoreIndex] = useState('');
    const [connectSuccess, setConnectSuccess] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        if(query.get("store_code")){
            setConnectSuccess(true);
            history.push('/account/integration');
        }
        getUrl('mystore').then(userStoreList => {
            if(isMountedRef.current && userStoreList.status === 1) {
                setUserStoreList(userStoreList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, []);

    const goToSelectedStore = () => {
        let formatted_params = new URLSearchParams(storeList[selectedStoreIndex]['extra_data']).toString();
        // let url = 'http://localhost:3001/authorize?'+formatted_params;
        let url = storeList[selectedStoreIndex]['login_url']+'?'+formatted_params;
        window.location.href = url;
    }

    const handleDelete = (storeId) => {
        addAlert('To disconnect from 3rd party platform, kindly contact our Admin for more info.');
        // console.log('delete '+storeId);
    }

    return (
        <Grid container spacing={1} justify="center" style={{padding:'0 15px'}}>
            <Grid item xs={12}>
                <List className={ styles.listStyle } style={{ boxShadow: '0 0 6px #bbbbbb', borderRadius: 15 }}>
                    <ListItem style={{display:'flex', justifyContent:'space-between', alignItem:'center'}}>
                        <Box>
                            <Typography variant="h6">
                                { t('integration.integration') }
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                { t('integration.integrationSubtitle') }
                            </Typography>
                        </Box>
                    </ListItem>
                    <Divider variant="middle" light />
                    {
                        _.size(storeList) > 0 ?
                        _.map(storeList, (storeItem, storeIndex) => {
                            return (
                                <Box key={`storeplatform${storeIndex}`}>
                                    <ListItem>
                                        <Grid container spacing={2} justify="center">
                                            <Grid item xs={12} md={3} style={{ alignSelf: 'center' }}>
                                                <Typography variant="overline" color="textPrimary" style={{ fontWeight: 'bolder'}}>{ storeItem.name }</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={7}>
                                                {_.size(storeItem.stores) < 1 ?
                                                    <Chip label={t('integration.notConnected')} disabled />
                                                    : 
                                                    _.map(storeItem.stores, store => {
                                                        return(
                                                            <Chip key={store.id} label={store.store_name} onDelete={() => handleDelete(store.id)} color="primary" style={{ marginLeft: '5px'}} />
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={2} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                                <IconButton aria-label="add" style={{ padding: 0 }} onClick={() => setSelectedStoreIndex(storeIndex)}>
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <Divider variant="middle" />
                                </Box>
                            )
                        }) :  null
                    }
                </List>
                <Dialog open={selectedStoreIndex !== "" ? true : false} onClose={() => setSelectedStoreIndex('')} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('integration.integration')}</DialogTitle>
                    <DialogContent>
                        <Box style={{ textAlign: 'center' }}>
                            <DialogContentText><b>{ selectedStoreIndex !== "" ? storeList[selectedStoreIndex].name : ''}</b></DialogContentText>
                            <Button onClick={goToSelectedStore} style={{ color: '#fff', backgroundColor: '#f7952a', width: '100%', maxWidth: '149px', borderRadius: '48px' }} color="secondary">{t('button.integrateNow')}</Button>
                        </Box>
                        <Divider variant="middle" light style={{marginBottom: '20px', marginTop: '20px' }}/>
                        <Typography style={{ textAlign: 'center' }}>{t('integration.integrationSteps')}</Typography>
                        <Stepper activeStep={0}>
                            <Step>
                                <StepLabel>{t('integration.clickOnIntegrateNow')}</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>{t('integration.loginYourAccount')}</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>{t('integration.integrateSuccess')}</StepLabel>
                            </Step>
                        </Stepper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSelectedStoreIndex('')} color="primary">{t('button.cancel')}</Button>
                    </DialogActions>
                </Dialog>
                {
                    connectSuccess ?
                        <Snackbar
                            open={connectSuccess}
                            autoHideDuration={10000}
                            onClose={() => setConnectSuccess(false)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Alert elevation={6} variant="filled" severity={'success'}>
                                Account Connect Successfully
                            </Alert>
                        </Snackbar>
                        : null
                }
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    }
}));