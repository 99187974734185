import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Card, CardContent, Link,Divider,Container} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import WebpImg from './Layouts/WebpImg';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import TitleBar from './Layouts/TitleBar';
import { format } from "date-fns";
import { useQuery } from '../helper/Tools';

export default function Notification() {

    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();

    const query = useQuery();
    const messagetype = query.get("mt") && query.get("mt") !== '' ? query.get("mt") : '';

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`notifications?mt=${messagetype}&&page=${page}`).then(response => {
            if (response.status) {
                if (isMountedRef.current) {
                    setNotifications(response.data.data);
                    setTotalPage(response.data.last_page);
                    setPage(response.data.current_page ? response.data.current_page : 1);
                    setLoading(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, addAlert]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const displayTitle = () => {
        let title = "";
        switch (messagetype) {
            case 'mstore_update':
                title = t('message.mstoreupdate');
            break;
            case 'promotions':
                title = t('message.promotion');
            break;
            case 'prizes_notification':
                title = t('message.prizenotification');
            break;
        
            default:
                title = t('profile.notification');
                break;
        }
        return title;
    }

    
    return (
        <Box className={styles.root} >
            <TitleBar height={70} title={displayTitle()} displayInfo displayQr currencyButton displayCart linkMall back pagetitle />
            <div className='fixed-header-padding'>
                <div style={{ width:420, maxWidth:'100%', zIndex:2, paddingTop:20}}>
                    <Container maxWidth='lg'>
                        <Grid container spacing={2} justify="center" >
                            <Grid item xs={12} >
                                {/* <div style={{paddingBottom:15}}>
                                    <Box style={{ color: theme.palette.primary.main, paddingBottom:5 }}>
                                        <Typography variant="h6">
                                            {t('profile.notification')}
                                        </Typography>
                                    </Box>
                                    <Divider variant="middle" light style={{margin:0}}/>
                                </div> */}
                                {
                                
                                    _.size(notifications) > 0 ?
                                        <> {
                                            _.map(notifications, notificationItem => {
                                                var date = new Date(notificationItem['updated_at']);
                                                let formattedDate = format(date, "MMMM do H:mma");
                                                return (
                                                    <Link key={notificationItem.id} underline='none' color="inherit" to={notificationItem.type === 'order' ? `/account/order/${notificationItem.linking_array.orderNo}` : `/notification_detail/${notificationItem.id}`} component={RouterLink}>
                                                        {notificationItem.pivot.read_at ?
                                                            <Card style={{ boxShadow: 'rgb(0 0 0 / 7%) 4px 5px 6px, rgb(255 255 255) -4px -4px 6px', border: 'none', borderTop: '2px solid #eeeeee82', borderLeft: '2px solid #eeeeee40', borderRadius: 15, marginBottom: 15, backgroundColor: '#f9f9f9' }} variant="outlined">
                                                                <CardContent style={{ borderRadius: 0 }}>
                                                                    <Grid container >
                                                                        <Grid item xs={2}  >
                                                                            <Box display="flex" alignItems="center" justifyContent="center" height="100%"  >
                                                                                {
                                                                                    notificationItem.type == 'order' ?
                                                                                        <WebpImg src={notificationItem.linking_array.orderImage} style={{ height: 40, width: 40, objectFit: 'contain' }} alt={notificationItem.linking_array.orderNo} />
                                                                                        :
                                                                                        <NotificationsNoneIcon color="primary" />
                                                                                }
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={10}>
                                                                            <Typography variant="h6" >{notificationItem[`subject_${i18n.language}`]}</Typography>
                                                                            <Typography variant="body2" >{formattedDate}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                            :
                                                            <Card style={{ boxShadow: '0 0 6px #bbbbbb', borderRadius: 15, marginBottom: 15, backgroundColor: '#c9c9c9' }} variant="outlined">
                                                                <CardContent style={{ borderRadius: 0 }}>
                                                                    <Grid container>
                                                                        <Grid item xs={2}>
                                                                            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                                                                                {
                                                                                    notificationItem.type == 'order' ?
                                                                                        <WebpImg src={notificationItem.linking_array.orderImage} style={{ height: 40, width: 40, objectFit: 'contain' }} alt={notificationItem.linking_array.orderNo} />
                                                                                        :
                                                                                        <NotificationsNoneIcon color="primary" />
                                                                                }
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={10}>
                                                                            <Typography variant="h6">{notificationItem[`subject_${i18n.language}`]}</Typography>
                                                                            <Typography variant="body2">{formattedDate}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        }
                                                    </Link>
                                                )
                                            })

                                        }
                                        <Grid container spacing={1} justify="center" >
                                            <Pagination count={totalPage} shape="rounded" page={page}
                                                onChange={handleChange}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        component={RouterLink}
                                                        to={`/notification?page=${item.page}`}
                                                        {...item}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                      </>  
                                    :
                                    <Card style={{ boxShadow: 'rgb(0 0 0 / 7%) 4px 5px 6px, rgb(255 255 255) -4px -4px 6px', border:'none', borderTop: '2px solid #eeeeee82', borderLeft: '2px solid #eeeeee40', borderRadius: 15, marginBottom: 15, backgroundColor:'#f9f9f9'}} variant="outlined">
                                        <CardContent style={{ height: 150, borderRadius: 0 }}>
                                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                                <Typography variant="h5">
                                                { t('notification.noNotification') }
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                }
                                
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        //padding: '10px 0px 30px 0px',
        //background: theme.palette.white.mobileBkg, 
        padding:'0 0 80px',
        minHeight: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
      },
}));