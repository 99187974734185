import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, makeStyles, Box, Paper, Typography, FormControl, List, ListItem, ListItemText, useTheme, Select, MenuItem } from '@material-ui/core';

import TitleBar from './Layouts/TitleBar';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';

import './css/styles.css';

export default function Transaction() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [transactionList, setTransactionList] = useState([]);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    console.log("walletList", walletList);
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        setTransactionList([]);
        setTotalPage(0);
        setPage(1);
    }, [wallet]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo) > 0) {
            getUrl(`wallets/${walletInfo.id}/history?page=${page}&per_page=10`).then(response => {
                if(response.status && isMountedRef.current) {
                    const { last_page, data } = response.data;
                    setTotalPage(last_page);
                    setTransactionList(_.concat(transactionList, data));
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [walletInfo, page]);

    const changeWallet = ({ target }) => {
        history.push(`/transaction/${target.value}`);
    }

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    // console.log("transactionList", transactionList);

    return (
        <Box className={classes.root} style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}}>
            <TitleBar height={70} title={t('title.transaction')} displayInfo displayQr currencyButton displayCart linkMall back backtext />
            <div className='fixed-header-padding'>
                <Box style={{ background: theme.palette.primary.main, paddingBottom: 70 }} display="flex" alignItems="center" justifyContent="center" >
                    {/* <Box className={classes.rankWrapperStyle}>
                        <Typography className={ classes.rankTitleStyle }>{ _.size(walletInfo) > 0 ? walletInfo.wallet_name[0] : '-' }</Typography>
                    </Box> */}
                    <Box display="flex" flexDirection="column" >
                        <Typography className={classes.balanceStyle}>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)} <span style={{ fontSize: 16 }}>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'}</span></Typography>
                        <Typography className={classes.infoStyle} style={{ fontSize: 13 }}>{t('transfer.transferableBalance')}</Typography>
                    </Box>
                </Box>
                <Paper elevation={3} classes={{ root: classes.paperContainer }}>
                    <Box className={classes.boxContainer} paddingX={1}>
                        <FormControl className={classes.formControl}>
                            <Select
                                value={wallet}
                                name="wallet"
                                onChange={changeWallet}
                                label={t('transaction.wallet')}
                            >
                                {_.map(walletList, walletItem => (
                                    <MenuItem key={walletItem.code} value={walletItem.code}>{walletItem.wallet_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Paper>
                <Box padding={2}>
                    <List className={classes.listRoot}>
                        {_.map(transactionList, transactionItem => (
                            <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                        ))}
                    </List>
                    {page < totalPage ?
                        <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                        : <Typography style={{ textAlign: 'center' }}>{t('transaction.endOfList')}</Typography>
                    }
                </Box>
                <Dialog
                    open={dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    style={{ width: '547px', maxWidth: '100%', margin: '0 auto' }}
                    onClose={closeDialog}
                >
                    <DialogContent >
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Typography style={{ textTransform: 'uppercase', fontSize: 14 }}>{t('transaction.amount')}</Typography>
                            <Typography style={{ fontWeight: 'bold', fontSize: 24, color: theme.palette.secondary.main, marginLeft: '-15px' }}>{`${dialogInfo.factor > 0 ? "+" : "-"} ${currencyFormat(dialogInfo.amount || 0)}`}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('transaction.transaction')}</Typography>
                            <Typography style={{ fontSize: 14 }}>{_.hasIn(dialogInfo.type_display, i18n.language) ? dialogInfo.type_display[i18n.language] : '-'}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('transaction.remark')}</Typography>
                            <Typography style={{ fontSize: 14 }}>{dialogInfo.remark}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Box display="flex" width="100%">
                                <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.dateTime')}</Typography>
                                <Typography style={{ fontSize: 14, flex: 2 }}>{dialogInfo.created_at}</Typography>
                            </Box>
                            <Box display="flex" width="100%">
                                <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.idNo')}</Typography>
                                <Typography style={{ fontSize: 14, flex: 2 }}>{_.size(dialogInfo.transaction_details) > 0 ? dialogInfo.transaction_details[0].transaction_id : '-'}</Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                    <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={closeDialog}>
                        {t('transaction.done')}
                    </Button>
                </Dialog>
            </div>
        </Box>
    )
}

const TransactionItem = ({ data, openDialog }) => {
    const { amount, factor, remark, created_at, type_display } = data;

    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    let operator = factor > 0 ? "+" : "-";

    return (
        <ListItem divider onClick={() => openDialog(data)} classes={{ root: classes.listItemRoot }} display="flex" alignItems="center" style={{padding:'10px 0'}} >
            <Box className={classes.listItemTextRoot}>
                <ListItemText
                    style={{width:'100%'}}
                    classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
                    primary={ _.hasIn(type_display, i18n.language) ? type_display[i18n.language] : '-' }
                    secondary={
                        <Typography
                            component="p"
                            variant="body2"
                            className={classes.remarkStyle}
                        >
                            { `${t('transaction.remark')}: ${remark || '-'}`}
                        </Typography>
                    }
                />
                <Typography
                    component="span"
                    variant="body2"
                    style={{ color: theme.palette.silver.text, fontSize: 11 }}
                >
                    {created_at}
                </Typography>
            </Box>
            <ListItemText
                primary={ `${operator} ${currencyFormat((amount * 100) / 100)}` }
                classes={{ root: classes.listItemAmountRoot, primary: classes.listItemAmountPrimary }}
                style={{ color: operator === '+' ? "green"/* theme.palette.gold.bg */ : 'red', whiteSpace:'nowrap', width:85 }}
            />
        </ListItem>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -38,
        padding: 10,
        borderRadius: 10
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15, 
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20
    },
    MuiButton: {
        contained:{
            "&:hover":{
                backgroundColor: theme.palette.secondary.main
            }
        }
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    remarkStyle:{
        color: theme.palette.silver.text,
        fontSize: 14,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    balanceStyle: {
        fontSize: 30, 
        color: "#fff",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.white.main, 
        fontSize: 11, 
        letterSpacing: 1 
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    listRoot: {
        width: '100%',
    },
    listItemTextRoot: {
        flex: 4
    },
    listItemAmountRoot: {
        // flex: 1,
        // display: 'flex',
        // justifyContent: 'flex-end',
        //alignSelf: 'center',
        textAlign:'right',
    },
    listItemAmountPrimary: {
        fontWeight: 'bold',
        fontSize: 14
    },
    listItemTextPrimary: {
        fontSize: 14,
    },
    listItemTextSecondary: {
        fontSize: 10,
    },
    listItemRoot: {
        cursor: 'pointer'
    },
}));